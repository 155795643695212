import { UUID } from '../../types';
import { PaginatedResponse } from '../../store/types';

export type UserRole = {
	dateCreated: string;
	dateUpdated: string;
	id: UUID;
	name: string;
	roleType: UserRoleType;
	permissions: string;
};

export enum UserRoleType {
	User = 'RoleTypeUser',
	Project = 'RoleTypeProject',
	PlatformAdmin = 'RoleTypePlatformAdmin',
}

export type UserRolesResponseData = PaginatedResponse<UserRole>;

export type CreateUserRoleRequestBody = {
	name: string;
	roleType: UserRoleType;
	permissions: string;
};

export type EditUserRoleRequestBody = {
	id: UUID;
	name: string;
	roleType: UserRoleType;
	permissions: string;
};

import * as tenantsService from '../../services/user/user-service';
import { addToast } from '../app/app-actions';
import { fetchRequest, isStatusSuccess, createToastError } from '../../services/helpers';
import { AsyncAction } from '../types';
import {
	tenantsCommonEmptyRequest,
	tenantsCommonEmptyError,
	fetchTenantsSuccess,
	createTenantSuccess,
	editTenantSuccess,
	deleteTenantSuccess,
} from './tenants-actions';
import { UUID } from '../../types';
import { omit } from 'lodash';
import {
	TenantsResponseData,
	Tenant,
	CreateTenantRequestBody,
	EditTenantRequestBody,
} from '../../pages/Tenants/tenants-types';
import { FiltersRequestBody2 } from '../../components/Table/types';

export const fetchTenants: AsyncAction<FiltersRequestBody2> = (payload) => async (dispatch) => {
	dispatch(tenantsCommonEmptyRequest());

	const filterBody = payload;

	const request = tenantsService.fetchTenants(filterBody);

	const { data, error } = await fetchRequest<TenantsResponseData>(request);

	if (data) {
		dispatch(fetchTenantsSuccess(data, payload));
	} else {
		dispatch(tenantsCommonEmptyError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data, () => dispatch(fetchTenants(payload)))));
	}
};

export const createTenant: AsyncAction<CreateTenantRequestBody> = (body) => async (dispatch, getState) => {
	dispatch(tenantsCommonEmptyRequest());

	const { tenants } = getState();
	const request = tenantsService.createTenant(body);
	const { data, status, error } = await fetchRequest<Tenant>(request);

	if (isStatusSuccess(status) && data) {
		dispatch(createTenantSuccess(data));
		dispatch(
			fetchTenants({
				limit: tenants.limit,
				offset: tenants.offset,
				filtersAndConditions: tenants.filters,
				orderings: tenants.orderings,
			}),
		);
	} else {
		dispatch(tenantsCommonEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(createTenant(body));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const editTenant: AsyncAction<EditTenantRequestBody> = (tenant) => async (dispatch, getState) => {
	dispatch(tenantsCommonEmptyRequest());

	const { tenants } = getState();
	const body = omit(tenant, ['id']);
	const request = tenantsService.editTenant(body, tenant.id);
	const { data, error } = await fetchRequest<Tenant>(request);

	if (data) {
		dispatch(editTenantSuccess(data));
		dispatch(
			fetchTenants({
				limit: tenants.limit,
				offset: tenants.offset,
				filtersAndConditions: tenants.filters,
				orderings: tenants.orderings,
			}),
		);
	} else {
		dispatch(tenantsCommonEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(editTenant(tenant));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const deleteTenant: AsyncAction<UUID> = (tenantId) => async (dispatch) => {
	dispatch(tenantsCommonEmptyRequest());

	const request = tenantsService.deleteTenant(tenantId);
	const { status, error } = await fetchRequest<void>(request);

	if (isStatusSuccess(status) && tenantId) {
		dispatch(deleteTenantSuccess(tenantId));
	} else {
		dispatch(tenantsCommonEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(deleteTenant(tenantId));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

import React, { useEffect } from 'react';
import { Form } from '../../../components/Form/Form';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray, FieldError, Controller } from 'react-hook-form';
import { makeStyles, Grid, Theme, FormControl, FormControlLabel, Switch, Tooltip, IconButton } from '@material-ui/core';
import { Button } from '../../../components/Button/Button';
import { InputField } from '../../../components/Form/InputField';
import * as yup from 'yup';
import SaveIcon from '@material-ui/icons/Save';
import { LatLngLiteral } from 'leaflet';
import { yupResolver } from '@hookform/resolvers';
import { isEqual } from 'lodash/fp';
import { usePrevious } from '../../../hooks/usePrevious';
import { Alert } from '@material-ui/lab';
import { ModalDialogFormActions } from '../../../components/ModalDialog/ModalDialogFormActions';
import { Add, HighlightOffRounded } from '@material-ui/icons';
import { COLOR_THEME } from '../../../theme';

const useStyles = makeStyles(({ spacing }: Theme) => ({
	iconError: {
		fill: COLOR_THEME.error,
	},
	formActions: {
		marginTop: spacing(2),
	},
}));

export type PolygonFormValues = {
	active: boolean;
	name: string;
	description: string;
	coords: LatLngLiteral[];
};

type Props = {
	values: PolygonFormValues;
	onSubmit: (values: PolygonFormValues) => void;
};

const MIN_COORDINATES = 3;

export const PolygonForm = ({ onSubmit, values }: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const validationSchema = yup.object().shape({
		active: yup.bool(),
		name: yup.string().required(t('validationErrors.required')),
		description: yup.string().required(t('validationErrors.required')),
		coords: yup
			.array()
			.min(MIN_COORDINATES, t('validationErrors.polygon'))
			.of(
				yup.object().shape({
					lat: yup.number().typeError(t('validationErrors.number')).required(t('validationErrors.required')),
					lng: yup.number().typeError(t('validationErrors.number')).required(t('validationErrors.required')),
				}),
			),
	});

	const { handleSubmit, errors, control, reset, clearErrors, watch } = useForm<PolygonFormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: values,
	});

	const { fields, append, remove } = useFieldArray<LatLngLiteral>({
		control,
		name: 'coords',
	});

	const prevValues = usePrevious(values);

	useEffect(() => {
		if (!isEqual(prevValues, values)) {
			reset(values);
		}
	}, [reset, values, prevValues]);

	const handleSubmitForm = (values: PolygonFormValues) => {
		onSubmit(values);
	};

	const isActive = watch('active');
	const coordsError: unknown = errors?.coords;
	const minPolygonCountError = !Array.isArray(coordsError) ? (coordsError as FieldError) : undefined;

	return (
		<Form onSubmit={handleSubmit(handleSubmitForm)} testId="polygonForm">
			{minPolygonCountError && <Alert severity="error">{minPolygonCountError.message}</Alert>}

			<InputField
				autoFocus
				error={Boolean(errors?.name)}
				helperText={errors?.name?.message}
				label={t('name')}
				name="name"
				control={control}
			/>

			<InputField
				error={Boolean(errors?.description)}
				helperText={errors?.description?.message}
				label={t('description')}
				name="description"
				control={control}
			/>

			<FormControl fullWidth margin="dense">
				<FormControlLabel
					control={
						<Controller
							render={(props) => (
								<Switch
									{...props}
									onChange={(event) => props.onChange(event.target.checked)}
									color="primary"
									checked={isActive}
								/>
							)}
							name="active"
							control={control}
						/>
					}
					label={isActive ? t('active') : t('inactive')}
				/>
			</FormControl>

			{fields.map((field, index: number) => {
				const errorLat: FieldError | undefined = errors.coords?.[index]?.lat;
				const errorLng: FieldError | undefined = errors.coords?.[index]?.lng;

				return (
					<Grid key={field.id} container spacing={1} alignItems="center">
						<Grid item xs>
							<InputField
								fullWidth
								error={Boolean(errorLat)}
								helperText={errorLat?.message}
								label={t('latitude')}
								name={`coords[${index}].lat`}
								control={control}
								defaultValue={field.lat}
							/>
						</Grid>
						<Grid item xs>
							<InputField
								fullWidth
								error={Boolean(errorLng)}
								helperText={errorLng?.message}
								label={t('longitude')}
								name={`coords[${index}].lng`}
								control={control}
								defaultValue={field.lng}
							/>
						</Grid>

						<Grid item>
							<Tooltip title={t('remove') as string}>
								<IconButton name={`coords[${index}].remove`} edge="end">
									<HighlightOffRounded
										className={classes.iconError}
										onClick={() => remove(index)}
										data-cy={`btn-remove:coords[${index}]`}
									/>
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
				);
			})}

			<ModalDialogFormActions className={classes.formActions}>
				<Button
					onClick={() => {
						// Clear form errors when adding third coordinate,
						// so that error alert disappears
						if (fields.length === MIN_COORDINATES - 1) {
							clearErrors();
						}

						append({ lat: 0, lng: 0 });
					}}
					startIcon={<Add />}
					variant="outlined"
					data-cy="btn-add:coord"
				>
					{t('add')}
				</Button>

				<Button startIcon={<SaveIcon />} type="submit" data-cy="btn-submit:polygonForm">
					{t('save')}
				</Button>
			</ModalDialogFormActions>
		</Form>
	);
};

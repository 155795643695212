import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';
import { UserRolesTable } from './components/UserRolesTable';
import { fetchRequest } from '../../services/helpers';
import { fetchPermissions } from '../../services/user/user-service';
import { Permission, PermissionsResponseData } from '../../services/user/user-types';

export const UserRoles = () => {
	const [permissions, setPermissions] = useState<Permission[]>();

	/**
	 * Fetching all permissions
	 */
	useEffect(() => {
		const request = fetchPermissions({
			limit: 100,
			offset: 0,
			orderings: [],
			filtersAndConditions: [],
		});

		(async () => {
			const { data, error } = await fetchRequest<PermissionsResponseData>(request);

			if (error) {
				// TODO show error
			} else {
				setPermissions(data?.data);
			}
		})();
	}, []);

	return (
		<Grid container spacing={2} justify="center">
			<Grid item xs={12} lg={10}>
				<Paper>
					<UserRolesTable permissions={permissions || []} />
				</Paper>
			</Grid>
		</Grid>
	);
};

import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { Grid } from '@material-ui/core';
import { fetchDeviceTypes } from '../../store/devices/devices-async-actions';
import { DevicesState } from '../../store/devices/devices-types';
import { DeviceTypesTable } from './components/DeviceTypesTable';

export const DeviceTypes = () => {
	const dispatch = useDispatch();
	const { deviceTypes } = useSelector<RootState, DevicesState>((state) => state.devices);

	useEffect(() => {
		dispatch(
			fetchDeviceTypes({
				limit: deviceTypes.limit,
				offset: deviceTypes.offset,
				orderings: deviceTypes.orderings,
				filtersAndConditions: deviceTypes.filtersAndConditions,
			}),
		);

		// Don't need to react on "config" and "filters" and deep comparision is not necessary
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	return (
		<Grid container spacing={2} justify="center">
			<Grid item xs={12} lg={10}>
				<Paper>
					<DeviceTypesTable
						data={deviceTypes.data}
						total={deviceTypes.total}
						offset={deviceTypes.offset}
						limit={deviceTypes.limit}
						orderings={deviceTypes.orderings}
						filters={deviceTypes.filtersAndConditions}
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};

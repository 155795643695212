import { lastMonth, lastTwoMonths, lastTwoWeeks, lastWeek } from '../../helpers/generalConstants';

export enum SortOrder {
	Ascending = 'ASC',
	Descending = 'DESC',
}

export enum FilterType {
	String,
	Number,
	Active,
	DateSpan,
}

export enum FilterOperators {
	equals = 'eq',
	orEquals = 'or_eq',
	notEquals = 'neq',
	orNotEquals = 'or_neq',
	lessThan = 'lt',
	orLessThan = 'or_lt',
	greaterThan = 'gt',
	orGreaterThan = 'or_gt',
	lessThanEqual = 'lte',
	orLessThanEqual = 'or_lte',
	greaterThanEqual = 'gte',
	orGreaterThanEqual = 'or_gte',
	in = 'in', // in array of values
	orIn = 'or_in', // in array of values
	notIn = 'nin', // in array of values
	orNotIn = 'or_nin', // in array of values
	contains = 'contains',
	orContains = 'or_contains',
	notContains = 'ncontains',
	orNotContains = 'or_ncontains',
	null = 'null',
	orNull = 'or_null',
	orNotNull = 'or_notnull',
	inJSON = 'in_json',
	ignore = 'ignore',
	injoin = 'injoin',
}

export enum SQLOperator {
	equals = '=',
	notEquals = '!=',
	lessThan = '<',
	greaterThan = '>',
	lessThanEqual = '<=',
	greaterThanEqual = '>=',
	in = 'IN',
	notIn = 'NOT IN',
	contains = 'LIKE',
	notContains = 'NOT LIKE',
	is = 'IS',
	isNot = 'IS NOT',
	inJSON = '->',
}

export const operatorSQLMap = new Map<FilterOperators, SQLOperator>([
	[FilterOperators.equals, SQLOperator.equals],
	[FilterOperators.orEquals, SQLOperator.equals],
	[FilterOperators.notEquals, SQLOperator.notEquals],
	[FilterOperators.orNotEquals, SQLOperator.notEquals],
	[FilterOperators.lessThan, SQLOperator.lessThan],
	[FilterOperators.orLessThan, SQLOperator.lessThan],
	[FilterOperators.greaterThan, SQLOperator.greaterThan],
	[FilterOperators.orGreaterThan, SQLOperator.greaterThan],
	[FilterOperators.lessThanEqual, SQLOperator.lessThanEqual],
	[FilterOperators.orLessThanEqual, SQLOperator.lessThanEqual],
	[FilterOperators.greaterThanEqual, SQLOperator.greaterThanEqual],
	[FilterOperators.orGreaterThanEqual, SQLOperator.greaterThanEqual],
	[FilterOperators.in, SQLOperator.in],
	[FilterOperators.orIn, SQLOperator.in],
	[FilterOperators.notIn, SQLOperator.notIn],
	[FilterOperators.orNotIn, SQLOperator.notIn],
	[FilterOperators.contains, SQLOperator.contains],
	[FilterOperators.orContains, SQLOperator.contains],
	[FilterOperators.notContains, SQLOperator.notContains],
	[FilterOperators.orNotContains, SQLOperator.notContains],
	[FilterOperators.null, SQLOperator.is],
	[FilterOperators.orNull, SQLOperator.is],
	[FilterOperators.orNotNull, SQLOperator.is],
	[FilterOperators.inJSON, SQLOperator.inJSON],
]);

export enum TableColumnType {
	STRING,
	NUMBER,
	DATE,
	BOOL,
	TEMPERATURE,
	LIGHT,
	BATTERY,
}

export enum TableRowActionIcon {
	delete = 'delete',
	detail = 'detail',
	edit = 'edit',
	notification = 'notification',
	report = 'report',
	tag = 'tag',
	zoom = 'zoom',
	attach = 'attach',
	info = 'info',
	restore = 'restore',
	summary = 'summary',
	config = 'config',
	show = 'show',
}

export enum TableFilterIcon {
	delete = 'delete',
	detail = 'detail',
	edit = 'edit',
	notification = 'notification',
	report = 'report',
	zoom = 'zoom',
}

export const FILTER_ACTIVE_OPTIONS = [
	{
		name: 'all',
		filter: {
			operator: FilterOperators.equals,
			value: null,
		},
	},
	{
		name: 'active',
		filter: {
			operator: FilterOperators.equals,
			value: true,
		},
	},
	{
		name: 'inactive',
		filter: {
			operator: FilterOperators.equals,
			value: false,
		},
	},
];

export const FILTER_SUPER_ADMIN_OPTIONS = [
	{
		name: 'all',
		filter: {
			operator: FilterOperators.equals,
			value: null,
		},
	},
	{
		name: 'yes',
		filter: {
			operator: FilterOperators.equals,
			value: true,
		},
	},
	{
		name: 'no',
		filter: {
			operator: FilterOperators.equals,
			value: false,
		},
	},
];

export const FILTER_TIME_OPTIONS = [
	{
		name: 'lastWeek',
		filter: { operator: FilterOperators.greaterThanEqual, value: lastWeek },
	},
	{
		name: 'lastTwoWeeks',
		filter: { operator: FilterOperators.greaterThanEqual, value: lastTwoWeeks },
	},
	{
		name: 'lastMonth',
		filter: { operator: FilterOperators.greaterThanEqual, value: lastMonth },
	},
	{
		name: 'lastTwoMonths',
		filter: { operator: FilterOperators.greaterThanEqual, value: lastTwoMonths },
	},
];

export const CLASSIFICATION_SORT_KEYS = ['sn', 'type'];

export const LAST_LOCATION_SORT_KEYS = [
	'accuracy',
	'battery',
	'last_location_time', // last_location -> time
	'lat',
	'light_intensity',
	'lng',
	'max_acceleration',
	'max_acceleration_history',
	'temperature',
	'zones',
];

export const LAST_TELEMETRY_SORT_KEYS = [
	'battery',
	'last_seen', // last_telemetry -> time
	'light_intensity',
	'max_acceleration',
	'max_acceleration_history',
	'temperature',
];

export const LAST_OTHER_SORT_KEYS = [
	'act',
	'act_description',
	'action',
	'biz_step',
	'eventTime',
	'eventType',
	'location_update_details',
	'moved',
	'signal_power',
];

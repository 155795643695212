import { createReducer } from 'typesafe-actions';
import {
	FETCH_USERS_SUCCESS,
	EDIT_USER_SUCCESS,
	CREATE_USER_SUCCESS,
	DELETE_USER_SUCCESS,
	EDIT_USER_PROJECTS_SUCCESS,
	GET_USER_DETAIL,
	FETCH_USER_SUCCESS,
	CHANGE_PASSWORD_SUCCESS,
	USER_MANAGEMENT_COMMON_EMPTY_REQUEST,
	USER_MANAGEMENT_COMMON_EMPTY_ERROR,
	FETCH_USER_ROLES_SUCCESS,
	CREATE_USER_ROLE_SUCCESS,
	EDIT_USER_ROLE_SUCCESS,
	DELETE_USER_ROLE_SUCCESS,
} from './user-management-constants';

import { UserManagementActions, UserManagementState } from './user-management-types';

export const userManagementInitialState: UserManagementState = {
	data: [],
	total: 0,
	offset: 0,
	limit: 10,
	orderings: [],
	filters: [],
	userDetailData: null,
	userRoles: {
		data: [],
		total: 0,
		offset: 0,
		limit: 10,
		orderings: [],
		filters: [],
	},
};

export const userManagementReducer = createReducer<UserManagementState, UserManagementActions>(
	userManagementInitialState,
	{
		[USER_MANAGEMENT_COMMON_EMPTY_REQUEST]: (state) => state,
		[USER_MANAGEMENT_COMMON_EMPTY_ERROR]: (state) => state,
		[FETCH_USERS_SUCCESS]: (state, action) => ({
			...state,
			data: action.payload.data.data,
			total: action.payload.data.total,
			limit: action.payload.options ? action.payload.options.limit : state.limit,
			offset: action.payload.options ? action.payload.options.offset : state.offset,
			orderings: action.payload.options?.orderings || state.orderings,
			filters: action.payload.options?.filtersAndConditions || state.filters,
		}),
		[EDIT_USER_SUCCESS]: (state, action) => {
			return {
				...state,
				userDetailData: {
					...state.userDetailData,
					...action.payload.data,
				},
			};
		},
		[CREATE_USER_SUCCESS]: (state) => state,
		[DELETE_USER_SUCCESS]: (state, action) => {
			const newData = state.data.filter((user) => user.id !== action.payload.id);

			return {
				...state,
				data: newData,
				total: newData.length,
			};
		},
		[GET_USER_DETAIL]: (state, action) => {
			return {
				...state,
				userDetailData: action.payload.user,
			};
		},
		[FETCH_USER_SUCCESS]: (state, action) => {
			return {
				...state,
				userDetailData: action.payload.user,
			};
		},
		[EDIT_USER_PROJECTS_SUCCESS]: (state) => state,
		[CHANGE_PASSWORD_SUCCESS]: (state) => state,
		[FETCH_USER_ROLES_SUCCESS]: (state, action) => ({
			...state,
			userRoles: {
				...state.userRoles,
				data: action.payload.data.data,
				total: action.payload.data.total,
				limit: action.payload.options ? action.payload.options.limit : state.userRoles.limit,
				offset: action.payload.options ? action.payload.options.offset : state.userRoles.offset,
				orderings: action.payload.options?.orderings || state.userRoles.orderings,
				filters: action.payload.options?.filtersAndConditions || state.userRoles.filters,
			},
		}),
		[CREATE_USER_ROLE_SUCCESS]: (state) => state,
		[EDIT_USER_ROLE_SUCCESS]: (state) => state,
		[DELETE_USER_ROLE_SUCCESS]: (state) => state,
	},
);

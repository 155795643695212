import { createReducer } from 'typesafe-actions';
import { UserActions, UserState } from './user-types';
import {
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	LOGOUT_REQUEST,
	LOGOUT_SUCCESS,
	CHANGE_PROJECT_REQUEST,
	CHANGE_PROJECT_ERROR,
	CHANGE_PROJECT_SUCCESS,
	FETCH_USER_PROJECTS_REQUEST,
	FETCH_USER_PROJECTS_SUCCESS,
	FETCH_USER_PROJECTS_ERROR,
	SET_USER_PERMISSIONS,
	SET_MAP_FAVOURITES,
	FETCH_USER_RELATIONS_SUCCESS,
	CREATE_USER_RELATION_SUCCESS,
	EDIT_USER_RELATION_SUCCESS,
	DELETE_USER_RELATION_SUCCESS,
	ADD_USER_PROJECT_RELATIONS_SUCCESS,
} from './user-constants';

import { set } from 'lodash/fp';

export const userInitialState: UserState = {
	jwtToken: '',
	userId: '',
	firstName: '',
	lastName: '',
	superAdmin: false,
	project: undefined,
	tenant: undefined,
	projects: [],
	permissions: '0',
	favourites: [],
	userRelations: {
		data: [],
		total: 0,
		offset: 0,
		limit: 10,
		orderings: [],
		filters: [],
	},
};

export const userReducer = createReducer<UserState, UserActions>(userInitialState, {
	[LOGIN_REQUEST]: (state) => state,
	[LOGIN_SUCCESS]: (state, action) => {
		let tenant = action.payload.data.tenant;

		if (tenant?.id === '00000000-0000-0000-0000-000000000000') {
			tenant = undefined;
		}

		return {
			...state,
			...action.payload.data,
			jwtToken: action.payload.token,
			tenant: tenant,
		};
	},
	[LOGOUT_REQUEST]: (state) => state,
	[LOGOUT_SUCCESS]: () => userInitialState,
	[CHANGE_PROJECT_REQUEST]: (state) => state,
	[CHANGE_PROJECT_SUCCESS]: (state, action) => {
		let tenant = action.payload.data.tenant;

		if (tenant?.id === '00000000-0000-0000-0000-000000000000') {
			tenant = undefined;
		}

		return {
			...state,
			...action.payload.data,
			jwtToken: action.payload.token,
			tenant: tenant,
		};
	},
	[CHANGE_PROJECT_ERROR]: (state) => state,
	[FETCH_USER_PROJECTS_REQUEST]: (state) => state,
	[FETCH_USER_PROJECTS_SUCCESS]: (state, action) => set('projects', action.payload.data.data, state),
	[FETCH_USER_PROJECTS_ERROR]: (state) => state,
	[SET_USER_PERMISSIONS]: (state, action) => set('permissions', action.payload.permissions, state),
	[SET_MAP_FAVOURITES]: (state, action) => set('favourites', action.payload.favourites, state),
	[FETCH_USER_RELATIONS_SUCCESS]: (state, action) => ({
		...state,
		userRelations: {
			data: action.payload.data.data,
			total: action.payload.data.total,
			limit: action.payload.options ? action.payload.options.limit : state.userRelations.limit,
			offset: action.payload.options ? action.payload.options.offset : state.userRelations.offset,
			orderings: action.payload.options?.orderings || state.userRelations.orderings,
			filters: action.payload.options?.filtersAndConditions || state.userRelations.filters,
		},
	}),
	[CREATE_USER_RELATION_SUCCESS]: (state) => state,
	[ADD_USER_PROJECT_RELATIONS_SUCCESS]: (state) => state,
	[EDIT_USER_RELATION_SUCCESS]: (state) => state,
	[DELETE_USER_RELATION_SUCCESS]: (state) => state,
});

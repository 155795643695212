import { Value } from '../types';

/**
 * Removes duplicats from array of primitives
 *
 * @param array - Array of primitive values (string, number, boolean)
 */
export const removeArrayDuplicates = <T extends Value>(array: T[]) => [...new Set(array)];

/**
 * Updates item object in array
 *
 * @param dataItem - Generic item object that needs to be updated
 * @param itemIndex - Index of that item in array
 * @param array - Array of items where the item has to be updated
 */
export const updateObjectInArray = <T>(dataItem: T, itemIndex: number, array: T[]) => {
	return array.map((item, index) => {
		if (index !== itemIndex) {
			// This isn't the item we care about - keep it as-is
			return item;
		}

		// Otherwise, this is the one we want - return an updated value
		return {
			...item,
			...dataItem,
		};
	});
};

/**
 * Merge arrays by unique property
 *
 * @param key - unique array property, e.g.: id
 * @param original - original array
 * @param updated - updated array
 */
export const mergeArrays = <T>(key: keyof T, original: T[] = [], updated: T[] = []) => {
	return [
		...original
			.concat(updated)
			.reduce((acc, item: T) => acc.set(item[key], Object.assign(acc.get(item[key]) || {}, item)), new Map())
			.values(),
	];
};

/**
 * Check if one array contains value from another array
 *
 * @param sourceArray - array
 * @param targetArray - array
 */
export const arrayIntersect = (sourceArray: unknown[], targetArray: unknown[]) =>
	sourceArray.some((sourceItem) => targetArray.some((targetItem) => sourceItem === targetItem));

/**
 * Check type of array values and return true, if all items are the same type
 * @param array - array of primitive values
 * @param type - type of value: 'string' | 'number' | 'boolean'
 */
export const checkArrayOfType = (array: unknown[], type: 'string' | 'number' | 'boolean') =>
	array.every((item) => typeof item === type);

/**
 * Check if array is full of strings
 *
 * @param array - array of values
 */
export const isStringArray = (array: unknown[]) => checkArrayOfType(array, 'string');

/**
 * Removes null, undefined and empty strings from an array of items
 *
 * @param value - unknown value
 */
export const notEmpty = <T extends unknown>(value: T | null | undefined): value is T => {
	return value !== null && value !== undefined && value !== '';
};

/**
 * Returns "true" if array has length, otherwise "false"
 *
 * @param array - array of items
 */
export const hasLength = (array: unknown[]) => {
	if (Array.isArray(array)) {
		return array.length > 0;
	}

	return false;
};

/**
 * Returns "true" if a nested array has length, otherwise "false"
 *
 * @param array - array of array items
 */
export const checkNestedArrayLength = (array: unknown[][]) => array.some(hasLength);

import {
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Tooltip,
} from '@material-ui/core';
import { LinkOffRounded, LinkRounded, LocalOfferRounded } from '@material-ui/icons';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FilterOperators } from '../../../components/Table/constants';
import { TableColumnFilter } from '../../../components/Table/types';
import { hasLength } from '../../../helpers/array';
import { INPUT_DATE_MASK } from '../../../helpers/generalConstants';
import { fetchPlacements } from '../../../services/device-placements/device-placements-service';
import { fetchRequest } from '../../../services/helpers';
import { addModalDialog, removeModalDialog } from '../../../store/app/app-actions';
import {
	attachDeviceToPlacement,
	detachDeviceFromPlacement,
} from '../../../store/device-placements/device-placements-async-actions';
import { RootState } from '../../../store/types';
import { UserState } from '../../../store/user/user-types';
import { UUID } from '../../../types';
import {
	DevicePlacement,
	DevicePlacementsResponseData,
	PlacementRelation,
} from '../../DevicePlacementsManagement/device-placements-types';
import { AttachDeviceForm, AttachDeviceFormValues } from './AttachDeviceForm';
import { DetailCard } from './DetailCard';

type Props = {
	data: PlacementRelation[];
	deviceId: UUID;
};

export const DeviceDetailPlacementProperties = ({ data, deviceId }: Props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const hasPlacement = hasLength(data);
	const { project } = useSelector<RootState, UserState>((state) => state.user);

	const [placements, setPlacements] = useState<DevicePlacement[]>([]);

	/**
	 * Fetching all placements
	 */
	useEffect(() => {
		const filters: TableColumnFilter[] = [];

		if (project) {
			filters.push({
				column: 'projects',
				operator: FilterOperators.injoin,
				value: project.id,
			});
		}

		const request = fetchPlacements({
			limit: 0,
			offset: 0,
			orderings: [],
			filtersAndConditions: filters,
		});

		(async () => {
			// setLoading(true);

			const { data, error } = await fetchRequest<DevicePlacementsResponseData>(request);

			if (error) {
				// TODO show error
			} else {
				// console.log(data);
				data && setPlacements(data.data);
			}

			// setLoading(false);
		})();
		// }
		//// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [project]);

	const handleCloseModalDialog = (modalId: string) => {
		dispatch(removeModalDialog(modalId));
	};

	const handleAttachPlacement = (values: AttachDeviceFormValues) => {
		const payload = {
			...values,
			deviceId,
			attached: new Date().toISOString(),
		};

		handleCloseModalDialog('attachDeviceModal');
		dispatch(attachDeviceToPlacement(payload));
	};

	const handleDetachPlacement = (relationId: UUID) => {
		dispatch(
			detachDeviceFromPlacement({
				detached: new Date().toISOString(),
				relationId,
			}),
		);
	};

	const handleOpenAttachForm = () => {
		dispatch(
			addModalDialog({
				id: 'attachDeviceModal',
				title: t('attachPlacementModalTitle'),
				content: (
					<AttachDeviceForm
						options={placements}
						onSubmit={(values: AttachDeviceFormValues) => handleAttachPlacement(values)}
					/>
				),
			}),
		);
	};

	return (
		<DetailCard
			title={t('placements')}
			value={
				<Tooltip title={t('attach') as string}>
					<IconButton edge="end" aria-label={t('attach')} onClick={handleOpenAttachForm}>
						<LinkRounded />
					</IconButton>
				</Tooltip>
			}
		>
			{hasPlacement && (
				<List>
					{data.map(({ id, attached, detached, placementId }) => {
						const attachedPlacement = placements.find((placement) => placement.id === placementId);

						return (
							<ListItem disabled={detached !== null} style={{ pointerEvents: 'none' }} key={id}>
								<ListItemIcon>
									<LocalOfferRounded />
								</ListItemIcon>
								<ListItemText
									primary={attachedPlacement?.placementDescription?.title1}
									secondary={
										<>
											{attachedPlacement?.placementDescription?.subTitle1}
											<br />
											{attachedPlacement?.placementDescription?.title2}
											<br />
											{attachedPlacement?.placementDescription?.subTitle2}
											<br />
											{`${t('attached')}: `}
											{format(new Date(attached), INPUT_DATE_MASK)}
											{detached && (
												<>
													{`, ${t('detached')}: `}
													{format(new Date(detached), INPUT_DATE_MASK)}
												</>
											)}
										</>
									}
								/>
								{detached === null && (
									<ListItemSecondaryAction>
										<IconButton
											edge="end"
											aria-label={t('detach')}
											onClick={() => handleDetachPlacement(id)}
										>
											<Tooltip title={t('detach') as string}>
												<LinkOffRounded />
											</Tooltip>
										</IconButton>
									</ListItemSecondaryAction>
								)}
							</ListItem>
						);
					})}
				</List>
			)}
		</DetailCard>
	);
};

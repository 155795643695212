import React, { useEffect, useState } from 'react';
import { Table } from '../../../components/Table/Table';
import { useTranslation } from 'react-i18next';
import {
	fetchDevices,
	editDevice,
	createDevice,
	// editDeviceProperties,
} from '../../../store/warehouse/warehouse-async-actions';

import { FilterOperators, FilterType, TableRowActionIcon } from '../../../components/Table/constants';
import { useDispatch, useSelector } from 'react-redux';
import { addModalDialog, addToast, removeModalDialog } from '../../../store/app/app-actions';
import { Tooltip, TableRow, TableCell, CardHeader, IconButton, Divider, Checkbox } from '@material-ui/core';
import {
	TableColumnFilter,
	TableColumnSort,
	TableHeadCell,
	TableRowRenderProps,
} from '../../../components/Table/types';
import { Add, Archive } from '@material-ui/icons';
import { UserState } from '../../../store/user/user-types';
import { PermissionsDotNet } from '../../../helpers/generalConstants';
import { RootState } from '../../../store/types';
import { hasPermission } from '../../../helpers/auth';
import { CreateDevicePayload, DeviceResponseData2, EditDevicePayload } from '../../Devices/devices-types';
import { CreateDeviceForm } from '../../Devices/components/CreateDeviceForm';
import { UUID } from '../../../types';
import { uploadDevices } from '../../../store/devices/devices-async-actions';
import { createToastError, fetchRequest } from '../../../services/helpers';
import { ErrorCodes } from '../../../services/common-types';
import { ExportDevicesForm } from './ExportDevicesForm';
import { exportDevicesCra, exportDevicesXml } from '../../../services/devices/devices-service';
import { UploadDevices } from '../../../components/Upload/UploadDevices';
import { Tenant, TenantsResponseData } from '../../Tenants/tenants-types';
import { fetchTenants } from '../../../services/user/user-service';

type Props = {
	data: DeviceResponseData2[];
	total: number;
	limit: number;
	offset: number;
	orderings: TableColumnSort[];
	filters: TableColumnFilter[];
};

export const WarehouseTable = ({ data, total, offset, limit, orderings, filters }: Props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { permissions, tenant } = useSelector<RootState, UserState>((state) => state.user);
	const canEditWarehouse = hasPermission(PermissionsDotNet.WarehouseWrite, permissions);

	const [selectedDevices, setSelectedDevices] = useState<{ sn: string; id: UUID }[]>([]);

	const [tenants, setTenants] = useState<Tenant[]>();

	/**
	 * Fetching all tenants
	 */
	useEffect(() => {
		const request = fetchTenants({
			// Should be enough to get all zones, even for super admin
			limit: 200,
			offset: 0,
			orderings: [],
			filtersAndConditions: [],
		});

		(async () => {
			const { data, error } = await fetchRequest<TenantsResponseData>(request);

			if (error) {
				// TODO show error
			} else {
				setTenants(data?.data ?? []);
			}
		})();
	}, []);

	const snOrder = orderings?.find((order) => order.column === 'sn');
	const snFilter = filters?.find((filter) => filter.column === 'sn');

	const typeOrder = orderings?.find((order) => order.column === 'deviceType.name');
	const typeFilter = filters?.find((filter) => filter.column === 'deviceType.name');

	const columns: TableHeadCell[] = [
		{ id: 'select', label: t('select'), unorderable: true },
		{
			id: 'sn',
			label: t('sn'),
			filters: snFilter && [
				{
					column: 'sn',
					operator: FilterOperators.contains,
					value: snFilter.value,
				},
			],
			filterType: FilterType.String,
			sort: snOrder?.sortOrder && { column: 'sn', sortOrder: snOrder?.sortOrder },
		},
		{
			id: 'deviceType.name',
			label: t('type'),
			filters: typeFilter && [
				{
					column: 'deviceType.name',
					operator: FilterOperators.contains,
					value: typeFilter.value,
				},
			],
			filterType: FilterType.String,
			sort: typeOrder?.sortOrder && { column: 'deviceType.name', sortOrder: typeOrder?.sortOrder },
		},
		{ id: 'tenant', label: t('tenant'), unorderable: true },
		{ id: 'dateAssigned', label: t('dateAssigned'), unorderable: true },
		{ id: 'dateUnassigned', label: t('dateUnassigned'), unorderable: true },
	];

	const handleCloseModal = (id: string) => {
		dispatch(removeModalDialog(id));
	};

	function handleCreateDevice(data: CreateDevicePayload, modalDialogId: string) {
		handleCloseModal(modalDialogId);
		dispatch(createDevice(data));
	}

	function handleEditDevice(data: EditDevicePayload, modalDialogId: string) {
		handleCloseModal(modalDialogId);
		dispatch(editDevice(data));
	}

	function handleExportDevices(exportType: string, modalDialogId: string) {
		handleCloseModal(modalDialogId);

		const selectedDevicesIds = selectedDevices.map((selectedDevice) => selectedDevice.id);

		const errorHandler = () =>
			dispatch(
				addToast(
					createToastError({
						message: 'Export error',
						name: '',
						errorCode: ErrorCodes['SYS-001'],
						errorType: 'server',
						status: 500,
					}),
				),
			);

		if (exportType === 'xml') {
			exportDevicesXml(selectedDevicesIds).catch(errorHandler);
		} else if (exportType === 'cra') {
			exportDevicesCra(selectedDevicesIds).catch(errorHandler);
		}
	}

	function handleUploadDevices(file: ArrayBuffer) {
		dispatch(uploadDevices(file));
	}

	function onCheckDevice(sn: string, deviceId: UUID, checked: boolean) {
		const newArr = selectedDevices.filter((device) => device.id !== deviceId);

		if (checked) {
			newArr.push({ sn: sn, id: deviceId });
		}

		setSelectedDevices(newArr);
	}

	const rowActions = [
		{
			id: 'btn-edit:device',
			icon: TableRowActionIcon.edit,
			tooltip: t('edit'),
			onClick: (device: DeviceResponseData2) => {
				dispatch(
					addModalDialog({
						id: 'editDeviceModal',
						title: t('editDeviceModalTitle'),
						content: (
							<CreateDeviceForm
								actualUserTenantId={tenant?.id}
								onSubmit={(values) =>
									handleEditDevice({ id: device?.id, ...values }, 'editDeviceModal')
								}
								onCancel={() => handleCloseModal('editDeviceModal')}
								values={{
									id: device.id,
									sn: device.sn,
									deviceTypeId: device.deviceType?.id,
									tenantId: device.tenantId,
									deveui: device.deveui,
									appeui: device.appeui,
									appkey: device.appkey,
									appnkey: device.appnkey,
									appskey: device.appskey,
								}}
							/>
						),
					}),
				);
			},
		},
	];

	const renderRow = ({ row: device, renderActions }: TableRowRenderProps<DeviceResponseData2>) => {
		return (
			<TableRow key={device.id} id={`row-${device.id}`}>
				<TableCell padding="checkbox">
					<Checkbox
						color="primary"
						checked={
							selectedDevices.find((selectedDevice) => selectedDevice.id === device.id) !== undefined
						}
						onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
							onCheckDevice(device.sn, device.id, checked)
						}
					/>
				</TableCell>
				<TableCell>{device.sn}</TableCell>
				<TableCell>{device.deviceType?.name}</TableCell>
				<TableCell>
					{tenants?.find((tenant) => tenant.id === device.tenantId)?.name ?? device.tenantId}
				</TableCell>
				<TableCell>{device.dateAssigned}</TableCell>
				<TableCell>{device.dateUnassigned}</TableCell>
				{canEditWarehouse && (
					<TableCell padding="none" sortDirection={false}>
						{renderActions(rowActions, device)}
					</TableCell>
				)}
			</TableRow>
		);
	};

	return (
		<>
			<CardHeader
				title={t('warehouse')}
				action={
					<>
						<UploadDevices
							onChangeCallback={(file) => handleUploadDevices(file)}
							title={t('uploadDevices')}
						/>
						<Tooltip title={String(t('exportDevices'))}>
							<IconButton
								onClick={() =>
									dispatch(
										addModalDialog({
											id: 'exportDevicesModal',
											title: t('exportDevicesModalTitle'),
											content: (
												<ExportDevicesForm
													devices={selectedDevices}
													onSubmit={(exportType: string) =>
														handleExportDevices(exportType, 'exportDevicesModal')
													}
													onCancel={() => handleCloseModal('exportDevicesModal')}
												/>
											),
										}),
									)
								}
								data-cy="btn-export:device"
							>
								<Archive />
							</IconButton>
						</Tooltip>
						{canEditWarehouse && (
							<Tooltip title={String(t('createDevice'))} aria-label={t('createDevice')}>
								<IconButton
									onClick={() =>
										dispatch(
											addModalDialog({
												id: 'createDeviceModal',
												title: t('createDeviceModalTitle'),
												content: (
													<CreateDeviceForm
														actualUserTenantId={tenant?.id}
														onSubmit={(values) =>
															handleCreateDevice(values, 'createDeviceModal')
														}
														onCancel={() => handleCloseModal('createDeviceModal')}
													/>
												),
											}),
										)
									}
									data-cy="btn-create:device"
								>
									<Add />
								</IconButton>
							</Tooltip>
						)}
					</>
				}
			/>
			<Divider light />
			<Table
				actions={canEditWarehouse ? rowActions : undefined}
				columns={columns}
				orderings={orderings}
				filtersAndConditions={filters}
				data={data}
				dataLimit={limit}
				dataOffset={offset}
				renderTableRow={renderRow}
				numberOfRows={total}
				fetchAction2={fetchDevices}
				selectable={false}
			/>
		</>
	);
};

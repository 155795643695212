import { action } from 'typesafe-actions';
import {
	GENERAL_PLACEMENT_GROUPS_REQUEST,
	GENERAL_PLACEMENT_GROUPS_ERROR,
	FETCH_PLACEMENT_GROUPS_SUCCESS,
	CREATE_PLACEMENT_GROUP_SUCCESS,
	UPDATE_PLACEMENT_GROUP_SUCCESS,
	DELETE_PLACEMENT_GROUP_SUCCESS,
	GET_PLACEMENT_GROUP_DETAIL,
} from './placement-groups-constants';

import { UUID } from '../../types';
import {
	GroupsResponseData,
	GroupResponseData,
	UpdateGroupResponseData,
} from '../../pages/PlacementGroups/placement-groups-types';
import { FiltersRequestBody2 } from '../../components/Table/types';

// general request action with no impact but starting the progress bar
export const generalPlacementGroupsRequest = () => action(GENERAL_PLACEMENT_GROUPS_REQUEST);
// general error action with no impact but stopping the progress bar
export const generalPlacementGroupsError = () => action(GENERAL_PLACEMENT_GROUPS_ERROR);

export const fetchPlacementGroupsSuccess = (data: GroupsResponseData, options?: FiltersRequestBody2) =>
	action(FETCH_PLACEMENT_GROUPS_SUCCESS, { data, options });

export const createPlacementGroupSuccess = () => action(CREATE_PLACEMENT_GROUP_SUCCESS);

export const updatePlacementGroupSuccess = (data: UpdateGroupResponseData) =>
	action(UPDATE_PLACEMENT_GROUP_SUCCESS, { data });

export const deletePlacementGroupSuccess = (placementGroupId: UUID) =>
	action(DELETE_PLACEMENT_GROUP_SUCCESS, { placementGroupId });

export const getPlacementGroupDetail = (data: GroupResponseData) => action(GET_PLACEMENT_GROUP_DETAIL, { data });

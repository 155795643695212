import React from 'react';
import { format } from 'date-fns';
import { isNil } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { INPUT_DATE_TIME_MASK } from '../../../helpers/generalConstants';
import { BatteryIcon } from './BatteryIcon';
import { DetailCard } from './DetailCard';
import { DeviceResponseData2 } from '../devices-types';

type Props = {
	deviceData: DeviceResponseData2;
};

export const DeviceDetailTelemetryProperties = ({ deviceData }: Props) => {
	const { t } = useTranslation();
	const { data } = deviceData;
	const { time, battery, temperature, max_acceleration, max_acceleration_history, light_intensity } = data || {};

	return (
		<DetailCard title={t('telemetry')}>
			<Table size="small">
				<TableBody>
					<TableRow>
						<TableCell>
							<strong>{t('lastSeen')}</strong>
						</TableCell>
						<TableCell align="right">
							{isNil(time) ? t('notAvailable') : format(new Date(time), INPUT_DATE_TIME_MASK)}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<strong>{t('battery')}</strong>
						</TableCell>
						<TableCell align="right">
							{isNil(battery) ? (
								t('notAvailable')
							) : (
								<>
									<BatteryIcon value={battery} />
									{t('percentage', {
										value: battery.toFixed(2),
									})}
								</>
							)}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<strong>{t('temperature')}</strong>
						</TableCell>
						<TableCell align="right">
							{isNil(temperature) ? t('notAvailable') : t('degreesCelsius', { value: temperature })}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<strong>{t('maxAcceleration')}</strong>
						</TableCell>
						<TableCell align="right">
							{isNil(max_acceleration) ? t('notAvailable') : max_acceleration}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<strong>{t('maxAccelerationHistory')}</strong>
						</TableCell>
						<TableCell align="right">
							{isNil(max_acceleration_history) ? t('notAvailable') : max_acceleration_history}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<strong>{t('lightIntensity')}</strong>
						</TableCell>
						<TableCell align="right">
							{isNil(light_intensity) ? t('notAvailable') : t('illuminance', { value: light_intensity })}
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</DetailCard>
	);
};

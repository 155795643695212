import React from 'react';
import { Card, CardActions, CardContent, Divider, makeStyles, Typography } from '@material-ui/core';
import { Popup } from 'react-leaflet';
import { Children } from '../../../types';

type Props = {
	title: string;
	children?: Children;
	action?: React.ReactNode;
};

const useStyles = makeStyles({
	action: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
});

export const MapPopup = ({ title, children, action }: Props) => {
	const classes = useStyles();

	return (
		<Popup>
			<Card>
				<CardContent>
					<Typography component="h4" variant="subtitle2">
						{title}
					</Typography>
					{children}
				</CardContent>
				{action && (
					<>
						<Divider light />
						<CardActions classes={{ root: classes.action }}>{action}</CardActions>
					</>
				)}
			</Card>
		</Popup>
	);
};

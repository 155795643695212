import React from 'react';
import { Form } from '../../../components/Form/Form';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { EndpointMethods, CreateDotNetIngestionEndpointRequestBody } from '../ingestion-endpoints-types';
import { Switch, FormControlLabel, FormControl } from '@material-ui/core';
import { Button } from '../../../components/Button/Button';
import { InputField } from '../../../components/Form/InputField';
import * as yup from 'yup';
import SaveIcon from '@material-ui/icons/Save';
import { ModalDialogFormActions } from '../../../components/ModalDialog/ModalDialogFormActions';
import { InputSelect } from '../../../components/Form/InputSelect';
import { yupResolver } from '@hookform/resolvers';
import { IngestionScript } from '../../IngestionScripts/ingestion-scripts-types';
import { Tenant } from '../../Tenants/tenants-types';

type FormValues = CreateDotNetIngestionEndpointRequestBody;

type Props = {
	onSubmit: (values: FormValues) => void;
	onCancel: () => void;
	ingestPoint?: FormValues;
	scripts: IngestionScript[];
	tenants: Tenant[];
	canEditTenant: boolean;
};

export const IngestionDotNetEndpointForm = ({
	onSubmit,
	onCancel,
	ingestPoint = {
		method: '',
		path: '',
		scriptId: '',
		tenantId: '',
		active: true,
	},
	scripts,
	tenants,
	canEditTenant,
}: Props) => {
	const { t } = useTranslation();

	const defaultValues = {
		...ingestPoint,
		tenantId: tenants.find((tenant) => tenant.id === ingestPoint.tenantId)?.name,
		scriptId: scripts.find((script) => script.id === ingestPoint.scriptId)?.name,
	};

	const validationSchema = yup.object().shape({
		path: yup
			.string()
			.required(t('validationErrors.required'))
			.matches(/^\//, t('validationErrors.invalidPathMissingSlash')),
		method: yup.string(),
		tenantId: yup.string(),
		scriptId: yup.string(),
		active: yup.bool(),
	});

	const { handleSubmit, errors, control, watch } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues,
	});

	const isActive = watch('active');

	const handleSubmitForm = async (values: FormValues) => {
		const tenant = tenants.find((tenant) => tenant.name === values.tenantId);
		const script = scripts.find((script) => script.name === values.scriptId);

		onSubmit({ ...values, tenantId: tenant?.id, scriptId: script?.id });
	};

	return (
		<Form onSubmit={handleSubmit(handleSubmitForm)} testId="ingestionEndpointsForm">
			<FormControl fullWidth margin="normal">
				<InputField
					autoFocus
					error={Boolean(errors.path)}
					helperText={errors.path?.message}
					label={t('url')}
					name="path"
					control={control}
				/>
			</FormControl>
			<FormControl fullWidth margin="normal">
				<InputSelect
					control={control}
					error={Boolean(errors.method)}
					helperText={errors.method?.message}
					label={t('selectEndpointMethod')}
					options={Object.values(EndpointMethods)}
					name="method"
				/>
			</FormControl>
			{canEditTenant && (
				<FormControl fullWidth margin="normal">
					<InputSelect
						control={control}
						error={Boolean(errors.tenantId)}
						helperText={errors.tenantId?.message}
						label={t('selectTenant')}
						options={tenants.map((tenant) => tenant.name)}
						name="tenantId"
					/>
				</FormControl>
			)}
			<FormControl fullWidth margin="normal">
				<InputSelect
					control={control}
					error={Boolean(errors.scriptId)}
					helperText={errors.scriptId?.message}
					label={t('selectScript')}
					options={scripts.map((script) => script.name)}
					name="scriptId"
				/>
			</FormControl>
			<FormControlLabel
				control={
					<Controller
						render={(props) => (
							<Switch
								{...props}
								onChange={(event) => props.onChange(event.target.checked)}
								color="primary"
								checked={isActive}
							/>
						)}
						name="active"
						control={control}
					/>
				}
				label={isActive ? t('active') : t('inactive')}
			/>
			<ModalDialogFormActions>
				<Button startIcon={<SaveIcon />} type="submit" data-cy="btn-submit:endpointForm">
					{t('save')}
				</Button>
				<Button onClick={onCancel} variant="text" data-cy="btn-cancel:endpointForm">
					{t('cancel')}
				</Button>
			</ModalDialogFormActions>
		</Form>
	);
};

import { action } from 'typesafe-actions';
import {
	FETCH_PROJECTS_REQUEST,
	FETCH_PROJECTS_SUCCESS,
	FETCH_PROJECTS_ERROR,
	CREATE_PROJECT_REQUEST,
	CREATE_PROJECT_SUCCESS,
	CREATE_PROJECT_ERROR,
	EDIT_PROJECT_REQUEST,
	EDIT_PROJECT_SUCCESS,
	EDIT_PROJECT_ERROR,
	DELETE_PROJECT_REQUEST,
	DELETE_PROJECT_SUCCESS,
	DELETE_PROJECT_ERROR,
	FETCH_PROJECT_INFO_REQUEST,
	FETCH_PROJECT_INFO_SUCCESS,
	FETCH_PROJECT_INFO_ERROR,
} from './projects-constants';

import { UUID } from '../../types';
import { ProjectsResponseData, ProjectResponseData } from '../../pages/Projects/projects-types';
import { ProjectInfoResponseData } from './projects-types';
import { FiltersRequestBody2 } from '../../components/Table/types';

export const fetchProjectsRequest = () => action(FETCH_PROJECTS_REQUEST);
export const fetchProjectsSuccess = (data: ProjectsResponseData, options?: FiltersRequestBody2) =>
	action(FETCH_PROJECTS_SUCCESS, {
		data,
		options,
	});

export const fetchProjectsError = () => action(FETCH_PROJECTS_ERROR);

export const createProjectRequest = () => action(CREATE_PROJECT_REQUEST);
export const createProjectSuccess = () => action(CREATE_PROJECT_SUCCESS);
export const createProjectError = () => action(CREATE_PROJECT_ERROR);

export const editProjectRequest = () => action(EDIT_PROJECT_REQUEST);
export const editProjectSuccess = (data: ProjectResponseData) =>
	action(EDIT_PROJECT_SUCCESS, {
		data,
	});

export const editProjectError = () => action(EDIT_PROJECT_ERROR);

export const deleteProjectRequest = () => action(DELETE_PROJECT_REQUEST);
export const deleteProjectSuccess = (id: UUID) =>
	action(DELETE_PROJECT_SUCCESS, {
		id,
	});

export const deleteProjectError = () => action(DELETE_PROJECT_ERROR);
export const fetchProjectInfoRequest = () => action(FETCH_PROJECT_INFO_REQUEST);
export const fetchProjectInfoSuccess = (data: ProjectInfoResponseData) => action(FETCH_PROJECT_INFO_SUCCESS, { data });
export const fetchProjectInfoError = () => action(FETCH_PROJECT_INFO_ERROR);

import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { Children } from '../../types';

const useStyles = makeStyles(({ spacing }: Theme) => ({
	container: {
		flex: '0 0 auto',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		'& > :not(:first-child)': {
			marginLeft: spacing(1),
		},
	},
}));

type Props = {
	children: Children;
	className?: string;
};

export const ModalDialogFormActions = ({ children, className }: Props) => {
	const classes = useStyles();

	return <div className={clsx(classes.container, className)}>{children}</div>;
};

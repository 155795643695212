import React from 'react';
import { Table } from '../../../components/Table/Table';
import { useTranslation } from 'react-i18next';

import { FilterOperators, FilterType, TableRowActionIcon } from '../../../components/Table/constants';
import { useDispatch, useSelector } from 'react-redux';
import { addModalDialog, removeModalDialog } from '../../../store/app/app-actions';
import { DeviceTypeForm } from './DeviceTypeForm';
import { UUID } from '../../../types';
import { Tooltip, TableRow, TableCell, CardHeader, IconButton, Divider } from '@material-ui/core';
import {
	TableColumnFilter,
	TableColumnSort,
	TableHeadCell,
	TableRowRenderProps,
} from '../../../components/Table/types';
import { Add, DeleteForever } from '@material-ui/icons';
import { UserState } from '../../../store/user/user-types';
import { PermissionsDotNet } from '../../../helpers/generalConstants';
import { RootState } from '../../../store/types';
import { hasPermission } from '../../../helpers/auth';
import { CreateDeviceTypePayload, DeviceType, EditDeviceTypePayload } from '../../Devices/devices-types';
import {
	createDeviceType,
	deleteDeviceType,
	editDeviceType,
	fetchDeviceTypes,
} from '../../../store/devices/devices-async-actions';

type Props = {
	data: DeviceType[];
	total: number;
	limit: number;
	offset: number;
	orderings: TableColumnSort[];
	filters: TableColumnFilter[];
};

export const DeviceTypesTable = ({ data, total, offset, limit, orderings, filters }: Props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { permissions } = useSelector<RootState, UserState>((state) => state.user);
	const canEditDeviceTypes = hasPermission(PermissionsDotNet.DeviceTypeWrite, permissions);

	const nameOrder = orderings?.find((order) => order.column === 'name');

	const nameFilter = filters?.find((filter) => filter.column === 'name');

	const columns: TableHeadCell[] = [
		{
			id: 'name',
			label: t('name'),
			filters: nameFilter && [
				{
					column: 'name',
					operator: FilterOperators.contains,
					value: nameFilter.value,
				},
			],
			filterType: FilterType.String,
			sort: nameOrder?.sortOrder && { column: 'name', sortOrder: nameOrder?.sortOrder },
		},
	];

	const handleCloseModal = (id: string) => {
		dispatch(removeModalDialog(id));
	};

	const handleCreateDeviceType = (data: CreateDeviceTypePayload, modalDialogId: string) => {
		handleCloseModal(modalDialogId);
		dispatch(createDeviceType(data));
	};

	const handleEditDeviceType = (data: EditDeviceTypePayload, modalId: string) => {
		dispatch(editDeviceType(data));
		handleCloseModal(modalId);
	};

	const handleDeleteDeviceType = (deviceTypeId: UUID, modalDialogId: string) => {
		handleCloseModal(modalDialogId);
		dispatch(deleteDeviceType(deviceTypeId));
	};

	const rowActions = [
		{
			id: 'btn-edit:deviceType',
			icon: TableRowActionIcon.edit,
			tooltip: t('edit'),
			onClick: (deviceType: DeviceType) => {
				dispatch(
					addModalDialog({
						id: 'editDeviceTypeModal',
						title: t('editDeviceTypeModalTitle'),
						content: (
							<DeviceTypeForm
								onSubmit={(values) =>
									handleEditDeviceType({ id: deviceType?.id, ...values }, 'editDeviceTypeModal')
								}
								onCancel={() => handleCloseModal('editDeviceTypeModal')}
								defaultValues={{
									name: deviceType.name,
								}}
							/>
						),
					}),
				);
			},
		},
		{
			id: 'btn-delete:deviceType',
			icon: TableRowActionIcon.delete,
			tooltip: t('delete'),
			onClick: (deviceType: DeviceType) =>
				dispatch(
					addModalDialog({
						id: 'deleteDeviceTypeModal',
						title: t('deleteDeviceTypeModalTitle'),
						contentText: t('deleteDeviceTypeModalDescription', {
							deviceType: deviceType.name,
						}),
						buttons: [
							{
								id: 'deleteDeviceType',
								value: t('delete'),
								onClick: () => handleDeleteDeviceType(deviceType.id, 'deleteDeviceTypeModal'),
								startIcon: <DeleteForever />,
							},
							{
								id: 'cancelDeleteDeviceType',
								value: t('cancel'),
								onClick: () => handleCloseModal('deleteDeviceTypeModal'),
								variant: 'text',
							},
						],
					}),
				),
		},
	];

	const renderRow = ({ row: deviceType, renderActions }: TableRowRenderProps<DeviceType>) => {
		return (
			<TableRow key={deviceType.id} id={`row-${deviceType.id}`}>
				<TableCell>{deviceType.name}</TableCell>
				{canEditDeviceTypes && (
					<TableCell padding="none" sortDirection={false}>
						{renderActions(rowActions, deviceType)}
					</TableCell>
				)}
			</TableRow>
		);
	};

	return (
		<>
			<CardHeader
				title={t('deviceTypes')}
				action={
					<>
						{canEditDeviceTypes && (
							<Tooltip title={String(t('createDeviceType'))} aria-label={t('createDeviceType')}>
								<IconButton
									onClick={() =>
										dispatch(
											addModalDialog({
												id: 'createDeviceTypeModal',
												title: t('createDeviceTypeModalTitle'),
												content: (
													<DeviceTypeForm
														onSubmit={(values) =>
															handleCreateDeviceType(values, 'createDeviceTypeModal')
														}
														onCancel={() => handleCloseModal('createDeviceTypeModal')}
													/>
												),
											}),
										)
									}
									data-cy="btn-create:deviceType"
								>
									<Add />
								</IconButton>
							</Tooltip>
						)}
					</>
				}
			/>
			<Divider light />
			<Table
				actions={canEditDeviceTypes ? rowActions : undefined}
				columns={columns}
				orderings={orderings}
				filtersAndConditions={filters}
				data={data}
				dataLimit={limit}
				dataOffset={offset}
				renderTableRow={renderRow}
				numberOfRows={total}
				fetchAction2={fetchDeviceTypes}
				selectable={false}
			/>
		</>
	);
};

export const USER_MANAGEMENT_COMMON_EMPTY_REQUEST = '[USER_MANAGEMENT_ACTIONS] USER_MANAGEMENT_COMMON_EMPTY_REQUEST';
export const USER_MANAGEMENT_COMMON_EMPTY_ERROR = '[USER_MANAGEMENT_ACTIONS] USER_MANAGEMENT_COMMON_EMPTY_ERROR';
export const FETCH_USERS_SUCCESS = '[USER_MANAGEMENT_ACTIONS] FETCH_USERS_SUCCESS';
export const EDIT_USER_SUCCESS = '[USER_MANAGEMENT_ACTIONS] EDIT_USER_SUCCESS';
export const CREATE_USER_SUCCESS = '[USER_MANAGEMENT_ACTIONS] CREATE_USER_SUCCESS';
export const DELETE_USER_SUCCESS = '[USER_MANAGEMENT_ACTIONS] DELETE_USER_SUCCESS';
export const GET_USER_DETAIL = '[USER_MANAGEMENT_ACTIONS] GET_USER_DETAIL';
export const FETCH_USER_SUCCESS = '[USER_MANAGEMENT_ACTIONS] FETCH_USER_SUCCESS';
export const EDIT_USER_PROJECTS_SUCCESS = '[USER_MANAGEMENT_ACTIONS] EDIT_USER_PROJECTS_SUCCESS';
export const CHANGE_PASSWORD_SUCCESS = '[USER_MANAGEMENT_ACTIONS] CHANGE_PASSWORD_SUCCESS';
export const FETCH_USER_ROLES_SUCCESS = '[USER_MANAGEMENT_ACTIONS] FETCH_USER_ROLES_SUCCESS';
export const CREATE_USER_ROLE_SUCCESS = '[USER_MANAGEMENT_ACTIONS] CREATE_USER_ROLE_SUCCESS';
export const EDIT_USER_ROLE_SUCCESS = '[USER_MANAGEMENT_ACTIONS] EDIT_USER_ROLE_SUCCESS';
export const DELETE_USER_ROLE_SUCCESS = '[USER_MANAGEMENT_ACTIONS] DELETE_USER_ROLE_SUCCESS';

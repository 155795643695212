import { CardHeader, Divider, IconButton, TableCell, TableRow, Tooltip, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TableRowActionIcon } from '../../../components/Table/constants';
import { Table } from '../../../components/Table/Table';
import { TableHeadCell, TableRowAction, TableRowRenderProps } from '../../../components/Table/types';
import { hasPermission } from '../../../helpers/auth';
import { PermissionsDotNet } from '../../../helpers/generalConstants';
import { addModalDialog, removeModalDialog } from '../../../store/app/app-actions';
import {
	createPlacementDefinition,
	fetchDefinitions,
	updatePlacementDefinition,
} from '../../../store/device-placements/device-placements-async-actions';
import { DefinitionState } from '../../../store/device-placements/device-placements-types';
import { RootState } from '../../../store/types';
import { UserState } from '../../../store/user/user-types';
import {
	UpdatePlacementDefinitionRequestBody,
	CreatePlacementDefinitionRequestBody,
	Definition,
} from '../../DevicePlacementsManagement/device-placements-types';
import { PlacementDefinitionForm } from './PlacementDefinitionForm';

export const PlacementDefinitionsTable = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { project } = useSelector<RootState, UserState>((state) => state.user);
	const { permissions } = useSelector<RootState, UserState>((state) => state.user);
	const canEditDefinitions = hasPermission(PermissionsDotNet.PlacementDefinition, permissions);
	const { data: definitions, total, offset, limit, orderings, filtersAndConditions } = useSelector<
		RootState,
		DefinitionState
	>((state) => state.devicePlacements.definitions);

	const definitionIdOrder = orderings?.find((order) => order.column === 'id');
	// const definitionIdFilter = filtersAndConditions?.find((filter) => filter.column === 'id');

	useEffect(() => {
		dispatch(
			fetchDefinitions({
				offset: 0,
				limit: 10,
				orderings: [],
				filtersAndConditions: [],
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, project]);

	const columns: TableHeadCell[] = [
		{
			id: 'id',
			label: t('definitionId'),
			// filters: definitionIdFilter && [
			// 	{
			// 		column: 'id',
			// 		operator: FilterOperators.contains,
			// 		value: definitionIdFilter.value,
			// 	},
			// ],
			// filterType: FilterType.String,
			sort: definitionIdOrder?.sortOrder && { column: 'id', sortOrder: definitionIdOrder?.sortOrder },
		},
		{
			id: 'name',
			label: t('name'),
			unorderable: true,
		},
		{
			id: 'projects',
			label: t('projects'),
			unorderable: true,
		},
	];

	function renderProjects(definition: Definition) {
		return (
			<Tooltip
				title={definition.projects.map((project) => (
					<div key={project.name}>{project.name}</div>
				))}
			>
				<div style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '11rem' }}>
					<Typography variant="inherit" noWrap>
						{definition.projects.map((project) => `${project.name}, `)}
					</Typography>
				</div>
			</Tooltip>
		);
	}

	const renderRow = ({ row: definition, renderActions }: TableRowRenderProps<Definition>) => {
		return (
			<TableRow key={definition.id} id={`row-${definition.id}`}>
				<TableCell>{definition.id}</TableCell>
				<TableCell>{t(definition.name)}</TableCell>
				<TableCell component="td" id={`projects-${definition.projects}`} scope="row">
					{renderProjects(definition)}
				</TableCell>
				{canEditDefinitions && (
					<TableCell padding="none" sortDirection={false}>
						{renderActions(rowActions, definition)}
					</TableCell>
				)}
			</TableRow>
		);
	};

	const handleCloseModal = (dialogId: string) => {
		dispatch(removeModalDialog(dialogId));
	};

	const handleEditDefinition = (data: UpdatePlacementDefinitionRequestBody, modalId: string) => {
		handleCloseModal(modalId);
		dispatch(updatePlacementDefinition(data));

		console.log('handleEditDefinition');
		console.log(data);
	};

	const handleCreateDefinition = (data: CreatePlacementDefinitionRequestBody, modalDialogId: string) => {
		handleCloseModal(modalDialogId);
		dispatch(createPlacementDefinition(data));

		console.log('handleCreateDefinition');
		console.log(data);
	};

	const rowActions: TableRowAction<Definition>[] = [
		{
			id: 'btn-edit:placementDefinition',
			icon: TableRowActionIcon.edit,
			tooltip: t('edit'),
			onClick: (placementDefinition: Definition) => {
				dispatch(
					addModalDialog({
						id: 'editPlacementDefinitionModal',
						title: t('editPlacementDefinitionModalTitle'),
						content: (
							<PlacementDefinitionForm
								onSubmit={(values) =>
									handleEditDefinition(
										{ id: placementDefinition.id, ...values },
										'editPlacementDefinitionModal',
									)
								}
								onCancel={() => handleCloseModal('editPlacementDefinitionModal')}
								defaultValues={placementDefinition}
							/>
						),
					}),
				);
			},
		},
	]; // TODO

	return (
		<>
			<CardHeader
				title={t('definitions')}
				action={
					<>
						{canEditDefinitions && (
							<Tooltip title={String(t('createDefinition'))} aria-label={t('createDefinition')}>
								<IconButton
									onClick={() =>
										dispatch(
											addModalDialog({
												id: 'createDefinitionModal',
												title: t('createDefinitionModalTitle'),
												content: (
													<PlacementDefinitionForm
														onSubmit={(values) =>
															handleCreateDefinition(values, 'createDefinitionModal')
														}
														onCancel={() => handleCloseModal('createDefinitionModal')}
													/>
												),
											}),
										)
									}
									data-cy="btn-create:definition"
								>
									<Add />
								</IconButton>
							</Tooltip>
						)}
					</>
				}
			/>
			<Divider light />
			{definitions !== undefined && (
				<Table
					actions={canEditDefinitions ? rowActions : undefined}
					columns={columns}
					data={definitions}
					dataLimit={limit}
					dataOffset={offset}
					renderTableRow={renderRow}
					numberOfRows={total}
					fetchAction2={fetchDefinitions}
					orderings={orderings}
					filtersAndConditions={filtersAndConditions}
					selectable={false}
				/>
			)}
		</>
	);
};

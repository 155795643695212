import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Children } from '../../types';

const useStyles = makeStyles({
	container: {
		display: 'flex',
	},
});

type Props = {
	children: Children;
};

export const AppContainer = ({ children }: Props) => {
	const classes = useStyles();

	return <div className={classes.container}>{children}</div>;
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { CardHeader, Divider, Icon, IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { TableHeadCell, TableRowAction, TableRowRenderProps } from '../../../components/Table/types';
import { RootState } from '../../../store/types';
import { Table } from '../../../components/Table/Table';
import { FilterOperators, SortOrder, TableRowActionIcon } from '../../../components/Table/constants';
import { useHistory } from 'react-router-dom';
import { RouteEnum } from '../../../router/Routes';
import { fetchRequest } from '../../../services/helpers';
import { UserState } from '../../../store/user/user-types';
import {
	Definition,
	DefinitionResponseData,
	DevicePlacement,
} from '../../DevicePlacementsManagement/device-placements-types';
import { getAssetState, AW_DEF_NAME, WC_FIELD_PERSONAL_NO, WC_SURNAME, WC_FIRST_NAME } from '../asset-watch-types';
import { fetchDefinitions } from '../../../services/device-placements/device-placements-service';
import { INPUT_DATE_TIME_MASK } from '../../../helpers/generalConstants';
import RefreshIcon from '@material-ui/icons/Refresh';
import { VehicleWatchState } from '../../../store/vehicle-watch/vehicle-watch-types';
import { fetchVehiclesWithTelemetry } from '../../../store/vehicle-watch/vehicle-watch-async-actions';
import { InTripIcon } from '../../../components/Icons/InTripIcon';

// const INTRIP_INDEX = 0;
const ZONES_INDEX = 2;
// const TEMPERATURE_INDEX = 3;

export const AssetsTable = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { project } = useSelector<RootState, UserState>((state) => state.user);

	const history = useHistory();

	const [vehicleDefinition, setVehicleDefinition] = useState<Definition | undefined>();

	const { vehicles: assets } = useSelector<RootState, VehicleWatchState>((state) => state.vehicleWatch);

	const allFields = vehicleDefinition?.placementFields;

	const personalNoColumnId = `Fields[Name]=${WC_FIELD_PERSONAL_NO}`;
	const personalNoOrder = assets.orderings?.find((order) => order.column === personalNoColumnId);
	const firstNameId = `Fields[Name]=${WC_FIRST_NAME}`;
	const firstNameOrder = assets.orderings?.find((order) => order.column === firstNameId);
	const surnameColumnId = `Fields[Name]=${WC_SURNAME}`;
	const surnameOrder = assets.orderings?.find((order) => order.column === surnameColumnId);

	useEffect(() => {
		// allFields?.splice(INTRIP_INDEX, 0, {
		// 	name: '',
		// 	type: 'inTrip',
		// });

		allFields?.splice(ZONES_INDEX, 0, {
			name: 'zones',
			type: 'zones',
		});

		// allFields?.splice(TEMPERATURE_INDEX, 0, {
		// 	name: 'temperature',
		// 	type: 'temperature',
		// });
	}, [allFields]);

	const columns = allFields?.map<TableHeadCell>((field) => {
		if (field.name === WC_FIELD_PERSONAL_NO) {
			return {
				id: personalNoColumnId,
				label: t(field.name),
				sort: personalNoOrder?.sortOrder && {
					column: personalNoColumnId,
					sortOrder: personalNoOrder?.sortOrder,
				},
			};
		}

		if (field.name === WC_FIRST_NAME) {
			return {
				id: firstNameId,
				label: t(field.name),
				sort: firstNameOrder?.sortOrder && {
					column: firstNameId,
					sortOrder: firstNameOrder?.sortOrder,
				},
			};
		}

		if (field.name === WC_SURNAME) {
			return {
				id: surnameColumnId,
				label: t(field.name),
				sort: surnameOrder?.sortOrder && {
					column: surnameColumnId,
					sortOrder: surnameOrder?.sortOrder,
				},
			};
		}

		return {
			id: field.name,
			label: t(field.name),
			unorderable: true,
		};
	});

	/**
	 * Fetch vehicle definition
	 */
	useEffect(() => {
		const request = fetchDefinitions({
			// Should be enough to get all definitions
			limit: 0,
			offset: 0,
			orderings: [],
			filtersAndConditions: [
				{
					column: 'name',
					operator: FilterOperators.contains,
					value: AW_DEF_NAME,
				},
			],
		});

		(async () => {
			const { data, error } = await fetchRequest<DefinitionResponseData>(request);

			if (error) {
				console.log(error);
				// TODO show error
			} else {
				if (data && data.data && data.data.length > 0) {
					setVehicleDefinition(data.data[0]);
				}
			}
		})();
	}, [project]);

	useEffect(() => {
		fetchVehiclePlacements();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, vehicleDefinition, project]);

	/**
	 * Fetch vehicle placements
	 */
	function fetchVehiclePlacements() {
		if (vehicleDefinition !== undefined) {
			const filters = [
				{
					column: 'definitionId',
					operator: FilterOperators.equals,
					value: vehicleDefinition.id,
				},
			];

			if (project) {
				filters.push({
					column: 'projects',
					operator: FilterOperators.injoin,
					value: project.id,
				});
			}

			dispatch(
				fetchVehiclesWithTelemetry({
					limit: assets?.limit,
					offset: assets?.offset,
					orderings: [{ column: 'dateUpdated', sortOrder: SortOrder.Descending }],
					filtersAndConditions: filters,
				}),
			);
		}
	}

	function getSemaforIcon(placement: DevicePlacement) {
		const state = getAssetState(placement);

		if (state) {
			return (
				<Tooltip title={state.title ?? 'N/A'}>
					<Icon>
						<InTripIcon fill={state.color} />
					</Icon>
				</Tooltip>
			);
		}
	}

	function onClickRow(placement: DevicePlacement) {
		history.push(`${RouteEnum.ASSET_WATCH}/${placement.id}`);
	}

	const renderRow = ({ row: placement, renderActions }: TableRowRenderProps<DevicePlacement>) => {
		return (
			<TableRow key={placement.id} id={`row-${placement.id}`} onClick={() => onClickRow(placement)}>
				{allFields?.map((field) => {
					if (field.type === 'inTrip') {
						return <TableCell key={field.name}>{getSemaforIcon(placement)}</TableCell>;
					}

					if (field.name === 'zones') {
						return (
							<TableCell key={field.name}>
								{placement.telemetry?.zones && placement.telemetry?.zones.length > 0
									? placement.telemetry?.zones[0].name
									: '-'}
							</TableCell>
						);
					}

					if (field.name === 'temperature') {
						return (
							<TableCell key={field.name}>
								{placement.telemetry?.temperature
									? t('degreesCelsius', {
											value: placement.telemetry.temperature,
									  })
									: '-'}
							</TableCell>
						);
					}

					const entry = Object.entries(placement.fields).find(([key]) => key === field.name);

					if (entry) {
						return <TableCell key={field.name}>{entry[1] as string}</TableCell>;
					}

					return <TableCell key={field.name}></TableCell>;
				})}
				<TableCell padding="none" sortDirection={false}>
					{renderActions(rowActions, placement)}
				</TableCell>
			</TableRow>
		);
	};

	const rowActions: TableRowAction<DevicePlacement>[] = [
		{
			id: 'btn-detail:telemetry',
			icon: TableRowActionIcon.detail,
			tooltip: t('detail'),
			onClick: (placement: DevicePlacement) => onClickRow(placement),
		},
	];

	return (
		<>
			<CardHeader
				title={t(AW_DEF_NAME)}
				subheader={
					assets?.updatedTime &&
					t('lastUpdate', {
						date: format(new Date(assets.updatedTime), INPUT_DATE_TIME_MASK),
					})
				}
				action={
					<Tooltip title={String(t('refresh'))} aria-label={t('refresh')}>
						<IconButton onClick={() => fetchVehiclePlacements()} data-cy="btn-refresh:placements">
							<RefreshIcon />
						</IconButton>
					</Tooltip>
				}
			/>
			<Divider light />

			{assets && columns && (
				<Table
					actions={rowActions}
					columns={columns}
					renderTableRow={renderRow}
					fetchAction2={fetchVehiclesWithTelemetry}
					data={assets.data}
					dataLimit={assets.limit}
					dataOffset={assets.offset}
					numberOfRows={assets.total}
					orderings={assets.orderings}
					filtersAndConditions={assets.filtersAndConditions}
					selectable={false}
				/>
			)}
		</>
	);
};

import * as telemetryService from '../../services/telemetry/telemetry-service';
import { addToast } from '../app/app-actions';
import { fetchRequest, createToastError } from '../../services/helpers';
import { AsyncAction } from '../types';
import { TelemetryResponseData } from '../../pages/Devices/telemetry-types';
import {
	fetchDevicesRequest,
	fetchDevicesSuccess,
	fetchDevicesError,
	fetchCurrentTelemetrySuccess,
	fetchGroupedTelemetrySuccess,
} from './telemetry-actions';
import { FiltersRequestBody2 } from '../../components/Table/types';
import { GroupedTelemetryOptions } from './telemetry-types';
import { omit } from 'lodash';

export const fetchTelemetry: AsyncAction<FiltersRequestBody2> = (telemetryPayload) => async (dispatch) => {
	dispatch(fetchDevicesRequest());

	const filterBody = telemetryPayload;

	// const filterBody = sanitizeEmptyValues<TelemetryRequestBody>({
	// 	...telemetryPayload.config,
	// 	...telemetryPayload.filters,
	// });

	const request = telemetryService.getFilteredTelemetry(filterBody);

	const { data, error } = await fetchRequest<TelemetryResponseData>(request);

	if (data) {
		dispatch(fetchDevicesSuccess(data, telemetryPayload));
	} else {
		dispatch(fetchDevicesError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data, () => dispatch(fetchTelemetry(telemetryPayload)))));
	}
};

export const fetchTelemetryNoProgressBar: AsyncAction<void> = () => async (dispatch, getState) => {
	// dispatch(fetchDevicesRequest());

	const { telemetry } = getState();

	const telemetryPayload = {
		limit: telemetry.limit,
		offset: telemetry.offset,
		filtersAndConditions: telemetry.filters,
		orderings: telemetry.orderings,
	};

	const request = telemetryService.getFilteredTelemetry(telemetryPayload);

	const { data, error } = await fetchRequest<TelemetryResponseData>(request);

	if (data) {
		dispatch(fetchDevicesSuccess(data, telemetryPayload));
	}

	if (error) {
		// dispatch(fetchDevicesError());
		dispatch(addToast(createToastError(error.data)));
	}
};

export const fetchCurrentTelemetryNoModalProgress: AsyncAction<void> = () => async (dispatch) => {
	// dispatch(fetchDevicesRequest());

	// const filterBody = sanitizeEmptyValues<TelemetryRequestBody>({ limit: 10, order: SortOrder.Descending });

	const filterBody = {
		limit: 10,
		offset: 0,
	};

	const request = telemetryService.getFilteredTelemetry(filterBody);
	const { data, error } = await fetchRequest<TelemetryResponseData>(request);

	if (data) {
		dispatch(fetchCurrentTelemetrySuccess(data));
	}

	if (error) {
		// dispatch(fetchDevicesError());
		dispatch(addToast(createToastError(error.data)));
	}
};

export const fetchGroupedTelemetryNoModalProgress: AsyncAction<GroupedTelemetryOptions> = (telemetryPayload) => async (
	dispatch,
) => {
	// dispatch(fetchDevicesRequest());

	const filterBody = telemetryPayload;

	const range = 2097152 / (1 << filterBody.zoom);

	const request = telemetryService.getGroupedFilteredTelemetry(omit(filterBody, ['zoom']), range);

	const { data, error } = await fetchRequest<TelemetryResponseData>(request);

	if (data) {
		dispatch(fetchGroupedTelemetrySuccess(data, telemetryPayload));
	} else {
		// dispatch(fetchDevicesError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data, () => dispatch(fetchTelemetry(telemetryPayload)))));
	}
};

import { action } from 'typesafe-actions';
import {
	FETCH_ZONES_REQUEST,
	FETCH_ZONES_SUCCESS,
	FETCH_ZONES_ERROR,
	CREATE_ZONE_REQUEST,
	CREATE_ZONE_SUCCESS,
	CREATE_ZONE_ERROR,
	EDIT_ZONE_REQUEST,
	EDIT_ZONE_SUCCESS,
	EDIT_ZONE_ERROR,
	DELETE_ZONE_REQUEST,
	DELETE_ZONE_SUCCESS,
	DELETE_ZONE_ERROR,
	FETCH_ZONE_REQUEST,
	FETCH_ZONE_SUCCESS,
	FETCH_ZONE_ERROR,
	GET_ZONE_DETAIL,
	UPLOAD_ZONES_REQUEST,
	UPLOAD_ZONES_SUCCESS,
	UPLOAD_ZONES_ERROR,
	FETCH_ZONES_SUCCESS2,
} from './zones-constants';

import { UUID } from '../../types';
import { ZonesResponseData, ZoneResponseData, Zone, ZonesPayload } from '../../pages/Zones/zones-types';
import { FiltersRequestBody2 } from '../../components/Table/types';

export const fetchZonesRequest = () => action(FETCH_ZONES_REQUEST);
export const fetchZonesSuccess = (data: ZonesResponseData, options?: ZonesPayload) =>
	action(FETCH_ZONES_SUCCESS, {
		data,
		options,
	});

export const fetchZonesSuccess2 = (data?: ZonesResponseData, options?: FiltersRequestBody2) =>
	action(FETCH_ZONES_SUCCESS2, {
		data,
		options,
	});

export const fetchZonesError = () => action(FETCH_ZONES_ERROR);

export const createZoneRequest = () => action(CREATE_ZONE_REQUEST);
export const createZoneSuccess = () => action(CREATE_ZONE_SUCCESS);
export const createZoneError = () => action(CREATE_ZONE_ERROR);

export const editZoneRequest = () => action(EDIT_ZONE_REQUEST);
export const editZoneSuccess = (data: ZoneResponseData) =>
	action(EDIT_ZONE_SUCCESS, {
		data,
	});

export const editZoneError = () => action(EDIT_ZONE_ERROR);

export const deleteZoneRequest = () => action(DELETE_ZONE_REQUEST);
export const deleteZoneSuccess = (id: UUID) =>
	action(DELETE_ZONE_SUCCESS, {
		id,
	});

export const deleteZoneError = () => action(DELETE_ZONE_ERROR);

export const fetchZoneRequest = () => action(FETCH_ZONE_REQUEST);
export const fetchZoneSuccess = (data: ZoneResponseData) =>
	action(FETCH_ZONE_SUCCESS, {
		data,
	});

export const fetchZoneError = () => action(FETCH_ZONE_ERROR);

export const getZoneDetail = (data: Zone) =>
	action(GET_ZONE_DETAIL, {
		data,
	});

export const uploadZonesRequest = () => action(UPLOAD_ZONES_REQUEST);
export const uploadZonesSuccess = () => action(UPLOAD_ZONES_SUCCESS);
export const uploadZonesError = () => action(UPLOAD_ZONES_ERROR);

import { createReducer } from 'typesafe-actions';
import {
	INGESTION_COMMON_EMPTY_REQUEST,
	INGESTION_COMMON_EMPTY_ERROR,
	FETCH_INGESTION_ENDPOINTS_REQUEST,
	FETCH_INGESTION_ENDPOINTS_SUCCESS,
	FETCH_INGESTION_ENDPOINTS_ERROR,
	EDIT_INGESTION_ENDPOINT_REQUEST,
	EDIT_INGESTION_ENDPOINT_SUCCESS,
	EDIT_INGESTION_ENDPOINT_ERROR,
	CREATE_INGESTION_ENDPOINT_REQUEST,
	CREATE_INGESTION_ENDPOINT_SUCCESS,
	CREATE_INGESTION_ENDPOINT_ERROR,
	DELETE_INGESTION_ENDPOINT_REQUEST,
	DELETE_INGESTION_ENDPOINT_SUCCESS,
	DELETE_INGESTION_ENDPOINT_ERROR,
	FETCH_INGESTION_SCRIPTS_SUCCESS,
	CREATE_INGESTION_SCRIPT_SUCCESS,
	EDIT_INGESTION_SCRIPT_SUCCESS,
	DELETE_INGESTION_SCRIPT_SUCCESS,
	//
	FETCH_DOTNET_INGESTION_ENDPOINTS_SUCCESS,
	CREATE_DOTNET_INGESTION_ENDPOINT_SUCCESS,
	EDIT_DOTNET_INGESTION_ENDPOINT_SUCCESS,
	DELETE_DOTNET_INGESTION_ENDPOINT_SUCCESS,
} from './ingestion-endpoints-constants';

import { IngestionEndpointsActions, IngestionEndpointsState } from './ingestion-endpoints-types';
import { updateObjectInArray } from '../../helpers/array';
import { SortOrder } from '../../components/Table/constants';

const defaultOptions = {
	config: {
		offset: 0,
		limit: 10,
		order: SortOrder.Ascending,
		orderBy: 'path',
	},
	filters: {
		path: [],
		active: [],
		method: [],
	},
};

export const endpointsInitialState: IngestionEndpointsState = {
	...defaultOptions,
	data: [],
	total: 0,
	scripts: {
		data: [],
		total: 0,
		offset: 0,
		limit: 25,
		orderings: [],
		filters: [],
	},
	endpoints: {
		data: [],
		total: 0,
		offset: 0,
		limit: 10,
		orderings: [],
		filters: [],
	},
};

export const ingestionEndpointsReducer = createReducer<IngestionEndpointsState, IngestionEndpointsActions>(
	endpointsInitialState,
	{
		[INGESTION_COMMON_EMPTY_REQUEST]: (state) => state,
		[INGESTION_COMMON_EMPTY_ERROR]: (state) => state,
		[FETCH_INGESTION_ENDPOINTS_REQUEST]: (state) => state,
		[FETCH_INGESTION_ENDPOINTS_SUCCESS]: (state, action) => ({
			...state,
			data: action.payload.data.data,
			total: action.payload.data.total,
			config: { ...state.config, ...action.payload.options?.config },
			filters: { ...state.filters, ...action.payload.options?.filters },
		}),
		[FETCH_INGESTION_ENDPOINTS_ERROR]: (state) => state,
		[EDIT_INGESTION_ENDPOINT_REQUEST]: (state) => state,
		[EDIT_INGESTION_ENDPOINT_SUCCESS]: (state, action) => {
			const { data } = action.payload;
			const userIndex = state.data.findIndex((user) => user.id === data.id);

			return {
				...state,
				data: updateObjectInArray(data, userIndex, state.data),
			};
		},
		[EDIT_INGESTION_ENDPOINT_ERROR]: (state) => state,
		[CREATE_INGESTION_ENDPOINT_REQUEST]: (state) => state,
		[CREATE_INGESTION_ENDPOINT_SUCCESS]: (state) => state,
		[CREATE_INGESTION_ENDPOINT_ERROR]: (state) => state,
		[DELETE_INGESTION_ENDPOINT_REQUEST]: (state) => state,
		[DELETE_INGESTION_ENDPOINT_SUCCESS]: (state, action) => {
			return {
				...state,
				data: state.data.filter((endpoint) => endpoint.id !== action.payload.id),
			};
		},
		[DELETE_INGESTION_ENDPOINT_ERROR]: (state) => state,
		[FETCH_INGESTION_SCRIPTS_SUCCESS]: (state, action) => ({
			...state,
			scripts: {
				...state.scripts,
				data: action.payload.data.data,
				total: action.payload.data.total,
				limit: action.payload.options ? action.payload.options.limit : state.scripts.limit,
				offset: action.payload.options ? action.payload.options.offset : state.scripts.offset,
				orderings: action.payload.options?.orderings || state.scripts.orderings,
				filters: action.payload.options?.filtersAndConditions || state.scripts.filters,
			},
		}),
		[CREATE_INGESTION_SCRIPT_SUCCESS]: (state) => state,
		[EDIT_INGESTION_SCRIPT_SUCCESS]: (state) => state,
		[DELETE_INGESTION_SCRIPT_SUCCESS]: (state, action) => {
			return {
				...state,
				scripts: {
					...state.scripts,
					data: state.scripts.data.filter((script) => script.id !== action.payload.id),
				},
			};
		},

		//

		[FETCH_DOTNET_INGESTION_ENDPOINTS_SUCCESS]: (state, action) => ({
			...state,
			endpoints: {
				...state.endpoints,
				data: action.payload.data.data,
				total: action.payload.data.total,
				limit: action.payload.options ? action.payload.options.limit : state.endpoints.limit,
				offset: action.payload.options ? action.payload.options.offset : state.endpoints.offset,
				orderings: action.payload.options?.orderings || state.endpoints.orderings,
				filters: action.payload.options?.filtersAndConditions || state.endpoints.filters,
			},
		}),
		[CREATE_DOTNET_INGESTION_ENDPOINT_SUCCESS]: (state) => state,
		[EDIT_DOTNET_INGESTION_ENDPOINT_SUCCESS]: (state, action) => {
			const { data } = action.payload;
			const endpointIndex = state.endpoints.data.findIndex((endpoint) => endpoint.id === data.id);

			return {
				...state,
				endpoints: {
					...state.endpoints,
					data: updateObjectInArray(data, endpointIndex, state.endpoints.data),
				},
			};
		},
		[DELETE_DOTNET_INGESTION_ENDPOINT_SUCCESS]: (state, action) => {
			return {
				...state,
				endpoints: {
					...state.endpoints,
					data: state.endpoints.data.filter((endpoint) => endpoint.id !== action.payload.id),
				},
			};
		},
	},
);

import { action } from 'typesafe-actions';
import {
	TENANTS_COMMON_EMPTY_REQUEST,
	TENANTS_COMMON_EMPTY_ERROR,
	FETCH_TENANTS_SUCCESS,
	CREATE_TENANT_SUCCESS,
	EDIT_TENANT_SUCCESS,
	DELETE_TENANT_SUCCESS,
} from './tenants-constants';

import { UUID } from '../../types';
import { FiltersRequestBody2 } from '../../components/Table/types';
import { Tenant, TenantsResponseData } from '../../pages/Tenants/tenants-types';

export const tenantsCommonEmptyRequest = () => action(TENANTS_COMMON_EMPTY_REQUEST);
export const tenantsCommonEmptyError = () => action(TENANTS_COMMON_EMPTY_ERROR);

export const fetchTenantsSuccess = (data?: TenantsResponseData, options?: FiltersRequestBody2) =>
	action(FETCH_TENANTS_SUCCESS, {
		data,
		options,
	});

export const createTenantSuccess = (data: Tenant) =>
	action(CREATE_TENANT_SUCCESS, {
		data,
	});

export const editTenantSuccess = (data: Tenant) =>
	action(EDIT_TENANT_SUCCESS, {
		data,
	});

export const deleteTenantSuccess = (id: UUID) =>
	action(DELETE_TENANT_SUCCESS, {
		id,
	});

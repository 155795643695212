import React from 'react';

type Props = {
	fill: string;
};

export const MarkerPlacementIcon = ({ fill }: Props) => {
	return (
		<svg width="48" height="48" xmlns="http://www.w3.org/2000/svg" fill={fill}>
			<path d="m24,4c-7.74,0 -14,6.26 -14,14c0,10.5 14,26 14,26c0,0 14,-15.5 14,-26c0,-7.74 -6.26,-14 -14,-14z" />
			<circle id="svg_2" fill="white" r="11" cy="18" cx="24" />
			<path d="m32.223,18.59206l-7.05406,-7.05406c-0.28216,-0.28216 -0.67405,-0.45459 -1.10514,-0.45459l-5.48649,0c-0.86216,0 -1.56757,0.70541 -1.56757,1.56757l0,5.48649c0,0.43108 0.17243,0.82297 0.46243,1.11297l7.05406,7.05406c0.28216,0.28216 0.67405,0.45459 1.10514,0.45459c0.43108,0 0.82297,-0.17243 1.10514,-0.46243l5.48649,-5.48649c0.29,-0.28216 0.46243,-0.67405 0.46243,-1.10514c0,-0.43108 -0.18027,-0.83081 -0.46243,-1.11297zm-12.47001,-3.58973c-0.65054,0 -1.17568,-0.52514 -1.17568,-1.17568s0.52514,-1.17568 1.17568,-1.17568s1.17568,0.52514 1.17568,1.17568s-0.52514,1.17568 -1.17568,1.17568z" />
		</svg>
	);
};

import {
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { ArrowForwardRounded as ArrowForwardIcon, RouterRounded as RouterIcon } from '@material-ui/icons';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { History } from 'history';
import { hasLength } from '../../../helpers/array';
import { INPUT_DATE_TIME_MASK } from '../../../helpers/generalConstants';
import { RouteEnum } from '../../../router/Routes';
import { removeModalDialog } from '../../../store/app/app-actions';
import { PlacementRelation } from '../../DevicePlacementsManagement/device-placements-types';

type Props = {
	data: PlacementRelation[];
	history: History;
};

export const AttachedDevicesList = ({ data, history }: Props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const handleClickDeviceDetail = (deviceSn: string) => {
		dispatch(removeModalDialog('attachedDevicesModal'));
		history.push(`${RouteEnum.DEVICES}/${deviceSn}`);
	};

	return hasLength(data) ? (
		<List dense>
			{data.map(({ id, deviceId, deviceSn, deviceType, attached, detached }, index) => {
				const hasMoreThanOneItem = index > 0;

				return (
					<React.Fragment key={deviceId}>
						{hasMoreThanOneItem && <Divider variant="inset" component="li" />}
						<ListItem>
							<ListItemIcon>
								<RouterIcon color={detached ? 'disabled' : 'action'} />
							</ListItemIcon>
							<ListItemText primary={deviceSn} secondary={deviceType?.name} />
							<ListItemText
								primary={t('attached')}
								secondary={format(new Date(attached), INPUT_DATE_TIME_MASK)}
							/>
							<ListItemText
								primary={t('detached')}
								secondary={detached ? format(new Date(detached), INPUT_DATE_TIME_MASK) : '-'}
							/>
							<ListItemSecondaryAction>
								<Tooltip title={t('deviceDetail') as string}>
									<IconButton edge="end" onClick={() => handleClickDeviceDetail(deviceSn)}>
										<ArrowForwardIcon />
									</IconButton>
								</Tooltip>
							</ListItemSecondaryAction>
						</ListItem>
					</React.Fragment>
				);
			})}
		</List>
	) : (
		<Typography>{t('noAttachedDevices')}</Typography>
	);
};

import React from 'react';
import { Table } from '../../../components/Table/Table';
import { useTranslation } from 'react-i18next';

import { FilterOperators, FilterType, TableRowActionIcon } from '../../../components/Table/constants';
import { useDispatch, useSelector } from 'react-redux';
import { addModalDialog, removeModalDialog } from '../../../store/app/app-actions';
import { TenantForm, TenantFormValues } from './TenantForm';
import { UUID } from '../../../types';
import { Tooltip, TableRow, TableCell, CardHeader, IconButton, Divider } from '@material-ui/core';
import {
	TableColumnFilter,
	TableColumnSort,
	TableHeadCell,
	TableRowRenderProps,
} from '../../../components/Table/types';
import { Add, DeleteForever } from '@material-ui/icons';
import { UserState } from '../../../store/user/user-types';
import { PermissionsDotNet } from '../../../helpers/generalConstants';
import { RootState } from '../../../store/types';
import { hasPermission } from '../../../helpers/auth';
import { createTenant, deleteTenant, editTenant, fetchTenants } from '../../../store/tenants/tenants-async-actions';
import { Tenant } from '../tenants-types';

type Props = {
	data: Tenant[];
	total: number;
	limit: number;
	offset: number;
	orderings?: TableColumnSort[];
	filters?: TableColumnFilter[];
};

export const TenantsTable = ({ data, total, offset, limit, orderings, filters }: Props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { permissions } = useSelector<RootState, UserState>((state) => state.user);
	const canEditTenants = hasPermission(PermissionsDotNet.TenantWrite, permissions);

	const nameOrder = orderings?.find((order) => order.column === 'name');
	const nameFilter = filters?.find((filter) => filter.column === 'name');

	// const tenantIdOrder = orderings?.find((order) => order.column === 'tenantId');
	// const tenantIdFilter = filters?.find((filter) => filter.column === 'tenantId');

	const columns: TableHeadCell[] = [
		{
			id: 'name',
			label: t('name'),
			filters: nameFilter && [
				{
					column: 'name',
					operator: FilterOperators.contains,
					value: nameFilter.value,
				},
			],
			filterType: FilterType.String,
			sort: nameOrder?.sortOrder && { column: 'name', sortOrder: nameOrder?.sortOrder },
		},
		{
			id: 'tenantId',
			label: t('tenantId'),
			unorderable: true,
			// filters: tenantIdFilter && [
			// 	{
			// 		column: 'tenantId',
			// 		operator: FilterOperators.contains,
			// 		value: tenantIdFilter.value,
			// 	},
			// ],
			// filterType: FilterType.String,
			// sort: tenantIdOrder?.sortOrder && { column: 'tenantId', sortOrder: tenantIdOrder?.sortOrder },
		},
	];

	const handleCloseModal = (id: string) => {
		dispatch(removeModalDialog(id));
	};

	const handleCreateTenant = (data: TenantFormValues, modalDialogId: string) => {
		handleCloseModal(modalDialogId);
		dispatch(createTenant(data));
	};

	const handleEditTenant = (data: TenantFormValues, modalId: string) => {
		if (data.id) {
			dispatch(editTenant({ ...data, id: data.id }));
		}

		handleCloseModal(modalId);
	};

	const handleDeleteTenant = (tenantId: UUID, modalDialogId: string) => {
		handleCloseModal(modalDialogId);
		dispatch(deleteTenant(tenantId));
	};

	const rowActions = [
		{
			id: 'btn-edit:tenant',
			icon: TableRowActionIcon.edit,
			tooltip: t('edit'),
			onClick: (tenant: Tenant) => {
				dispatch(
					addModalDialog({
						id: 'editTenantModal',
						title: t('editTenantModalTitle'),
						content: (
							<TenantForm
								onSubmit={(values) => handleEditTenant(values, 'editTenantModal')}
								onCancel={() => handleCloseModal('editTenantModal')}
								defaultValues={tenant}
							/>
						),
					}),
				);
			},
		},
		{
			id: 'btn-delete:tenant',
			icon: TableRowActionIcon.delete,
			tooltip: t('delete'),
			onClick: (tenant: Tenant) =>
				dispatch(
					addModalDialog({
						id: 'deleteTenantModal',
						title: t('deleteTenantModalTitle'),
						contentText: t('deleteTenantModalDescription', {
							tenant: tenant.name,
						}),
						buttons: [
							{
								id: 'deleteTenant',
								value: t('delete'),
								onClick: () => handleDeleteTenant(tenant.id, 'deleteTenantModal'),
								startIcon: <DeleteForever />,
							},
							{
								id: 'cancelTenantType',
								value: t('cancel'),
								onClick: () => handleCloseModal('deleteTenantModal'),
								variant: 'text',
							},
						],
					}),
				),
		},
	];

	const renderRow = ({ row: tenant, renderActions }: TableRowRenderProps<Tenant>) => {
		return (
			<TableRow key={tenant.id} id={`row-${tenant.id}`}>
				<TableCell>{tenant.name}</TableCell>
				<TableCell>{tenant.id}</TableCell>
				{canEditTenants && (
					<TableCell padding="none" sortDirection={false}>
						{renderActions(rowActions, tenant)}
					</TableCell>
				)}
			</TableRow>
		);
	};

	return (
		<>
			<CardHeader
				title={t('tenants')}
				action={
					<>
						{canEditTenants && (
							<Tooltip title={String(t('createTenant'))} aria-label={t('createTenant')}>
								<IconButton
									onClick={() =>
										dispatch(
											addModalDialog({
												id: 'createTenantModal',
												title: t('createTenantModalTitle'),
												content: (
													<TenantForm
														onSubmit={(values) =>
															handleCreateTenant(values, 'createTenantModal')
														}
														onCancel={() => handleCloseModal('createTenantModal')}
													/>
												),
											}),
										)
									}
									data-cy="btn-create:tenant"
								>
									<Add />
								</IconButton>
							</Tooltip>
						)}
					</>
				}
			/>
			<Divider light />
			<Table
				actions={canEditTenants ? rowActions : undefined}
				columns={columns}
				orderings={orderings}
				filtersAndConditions={filters}
				data={data}
				dataLimit={limit}
				dataOffset={offset}
				renderTableRow={renderRow}
				numberOfRows={total}
				fetchAction2={fetchTenants}
				selectable={false}
			/>
		</>
	);
};

import React from 'react';
import { Tooltip } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import {
	ArrowForwardRounded,
	DeleteRounded,
	EditRounded,
	NotificationsActiveRounded,
	FindInPageRounded,
	AssessmentRounded,
	MyLocation,
	Router,
	LinkRounded,
	Visibility,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ObjectRequiredID } from '../../types';
import { SortOrder, TableRowActionIcon } from './constants';
import { useSelect } from './hooks';
import { TableActionsContainer } from './TableActionsContainer';
import { TableHead } from './TableHead';
import { TableColumnFilter, TableHeadCell, TableProps, TableRowAction } from './types';
import { removeArrayDuplicates } from '../../helpers/array';
import InfoIcon from '@material-ui/icons/Info';
import RestoreIcon from '@material-ui/icons/Restore';
import SummarizeIcon from '@material-ui/icons/BarChart';
import BuildIcon from '@material-ui/icons/Build';

export const Table = <T extends ObjectRequiredID>({
	columns,
	orderings,
	filtersAndConditions,
	data,
	numberOfRows,
	dataLimit,
	dataOffset,
	fetchAction2,
	actions,
	renderTableRow,
	onClickSelectAll,
	selectable = false,
	size = 'medium',
	sortable = true,
}: TableProps<T>) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { selectedRows, setSelectAll, setSelectedRows } = useSelect<T[]>([]);
	const isPagination = typeof dataOffset === 'number' && typeof dataLimit === 'number';
	const actionsColumn = {
		id: 'actions',
		label: t('actions'),
	};

	const tableHeadColumns = actions ? columns.concat([actionsColumn]) : columns;

	const handleSortRows = (_: React.MouseEvent<unknown>, property: string) => {
		if (fetchAction2) {
			dispatch(
				fetchAction2({
					limit: dataLimit,
					offset: dataOffset,
					filtersAndConditions: filtersAndConditions,
					orderings: orderings,
				}),
			);
		}
	};

	const handleCheckSelectAllItems = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectAll(event, data);

		if (typeof onClickSelectAll === 'function') {
			onClickSelectAll(event.target.checked ? data.map((dataItem) => dataItem.id) : []);
		}
	};

	const handleClickRow = (id: string) => {
		if (selectable) {
			setSelectedRows(id);
		}
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (fetchAction2) {
			dispatch(
				fetchAction2({
					limit: Number(event.target.value),
					offset: 0,
					filtersAndConditions: filtersAndConditions,
					orderings: orderings,
				}),
			);
		}
	};

	const handleChangePage = (_: unknown, newPage: number) => {
		if (fetchAction2) {
			dispatch(
				fetchAction2({
					limit: dataLimit,
					offset: newPage * dataLimit,
					filtersAndConditions: filtersAndConditions,
					orderings: orderings,
				}),
			);
		}
	};

	const handleSortChange = (tableHeadCell: TableHeadCell) => {
		console.log(tableHeadCell.sort);

		let tmp = orderings?.find((ordering) => ordering.column === tableHeadCell.id);

		console.log(tmp?.sortOrder);

		if (tmp) {
			if (tmp.sortOrder === SortOrder.Ascending) {
				tmp = { column: tableHeadCell.id, sortOrder: SortOrder.Descending };
			} else {
				tmp = undefined;
			}
		} else {
			tmp = { column: tableHeadCell.id, sortOrder: SortOrder.Ascending };
		}

		const newOrderings = orderings?.filter((ordering) => ordering.column !== tableHeadCell.id) || [];

		if (tmp) {
			newOrderings.push({
				column: tmp.column,
				sortOrder: tmp.sortOrder,
			});
		}

		fetchAction2 &&
			dispatch(
				fetchAction2({
					limit: dataLimit,
					offset: dataOffset,
					filtersAndConditions: filtersAndConditions,
					orderings: newOrderings,
				}),
			);
	};

	const handleFilterChange = (tableHeadCell: TableHeadCell) => {
		const filters: TableColumnFilter[] = [];

		tableHeadColumns.forEach((cell) => {
			cell.filters &&
				cell.filters.forEach((cellFilter) => {
					filters.push(cellFilter);
				});
		});

		fetchAction2 &&
			dispatch(
				fetchAction2({
					limit: dataLimit,
					offset: 0,
					filtersAndConditions: filters,
					orderings: orderings,
				}),
			);
	};

	const getActionIcon = (icon: TableRowActionIcon) => {
		switch (icon) {
			case TableRowActionIcon.edit:
				return <EditRounded />;

			case TableRowActionIcon.delete:
				return <DeleteRounded />;

			case TableRowActionIcon.detail:
				return <ArrowForwardRounded />;

			case TableRowActionIcon.report:
				return <AssessmentRounded />;

			case TableRowActionIcon.notification:
				return <NotificationsActiveRounded />;

			case TableRowActionIcon.zoom:
				return <MyLocation />;

			case TableRowActionIcon.tag:
				return <Router />;

			case TableRowActionIcon.attach:
				return <LinkRounded />;

			case TableRowActionIcon.info:
				return <InfoIcon />;

			case TableRowActionIcon.restore:
				return <RestoreIcon />;

			case TableRowActionIcon.summary:
				return <SummarizeIcon />;

			case TableRowActionIcon.config:
				return <BuildIcon />;

			case TableRowActionIcon.show:
				return <Visibility />;

			default:
				return null;
		}
	};

	const renderActions = (actions: TableRowAction<T>[], dataItem: T) => (
		<TableActionsContainer>
			{actions.map(({ id, icon, onClick, tooltip, disabled }) => {
				const handleClickEditAction = (event: React.SyntheticEvent, dataItem: T) => {
					// Stop propagating click to TableRow, otherwise it will select the row
					event.stopPropagation();
					onClick(dataItem);
				};

				const isDisabled = disabled && disabled(dataItem);

				return (
					<React.Fragment key={tooltip}>
						<Tooltip title={tooltip} aria-label={tooltip} placement="top">
							<IconButton
								data-cy={id}
								disabled={isDisabled}
								onClick={(event) => handleClickEditAction(event, dataItem)}
							>
								{getActionIcon(icon)}
							</IconButton>
						</Tooltip>
					</React.Fragment>
				);
			})}
		</TableActionsContainer>
	);

	// When you need more flexbility in rendering Table rows use the component with children
	// Don't forget to include useSelect hook for selecting rows
	const renderRows = () =>
		data.map((dataItem: T, index: number) => {
			// Some items have ID as a string and some as a number,
			// so we have to convert it to string.
			// Some string IDs cannot be converted to number, because it trasnforms to NaN
			const rowId = String(dataItem.id);
			const isRowSelected = selectedRows.includes(rowId);

			return typeof renderTableRow === 'function' ? (
				renderTableRow({
					onClickRow: handleClickRow,
					renderActions,
					row: dataItem,
					isRowSelected,
					index,
				})
			) : (
				<TableRow
					hover
					onClick={() => handleClickRow(String(rowId))}
					role="checkbox"
					aria-checked={isRowSelected}
					tabIndex={-1}
					key={rowId}
					selected={isRowSelected}
				>
					{Object.entries(dataItem).map((item, index: number) => {
						const key = item[0];
						const value = item[1];
						const addCheckboxColumn = index === 0 && selectable;

						return (
							<React.Fragment key={key}>
								{addCheckboxColumn && (
									<TableCell padding="checkbox">
										<Checkbox color="primary" checked={isRowSelected} />
									</TableCell>
								)}

								{/* Do not render id column */}
								{key !== 'id' && (
									<TableCell component="td" id={String(key)} scope="row">
										{value}
									</TableCell>
								)}
							</React.Fragment>
						);
					})}

					{actions && (
						<TableCell padding="none" sortDirection={false} align="right">
							<TableActionsContainer>{renderActions(actions, dataItem)}</TableActionsContainer>
						</TableCell>
					)}
				</TableRow>
			);
		});

	const renderEmpty = () => (
		<TableRow>
			<TableCell colSpan={tableHeadColumns.length + 1} align="center">
				<FindInPageRounded fontSize="large" />
				<Typography variant="subtitle1" gutterBottom>
					{t('noData')}
				</Typography>
			</TableCell>
		</TableRow>
	);

	return (
		<>
			<TableContainer>
				<MuiTable size={size}>
					<TableHead<T>
						cells={tableHeadColumns}
						data={data}
						onClickSelectAll={handleCheckSelectAllItems}
						onClickSort={handleSortRows}
						selectedRows={selectedRows}
						selectable={selectable}
						sortable={sortable}
						handleFilterChange={handleFilterChange}
						handleSortChange={handleSortChange}
					/>
					<TableBody>{numberOfRows ? renderRows() : renderEmpty()}</TableBody>
				</MuiTable>
			</TableContainer>

			{isPagination ? (
				<TablePagination
					// Just in case there is a custom limit, e.g. telemetry
					rowsPerPageOptions={removeArrayDuplicates([10, 25, 50, 100, dataLimit as number])}
					component="div"
					count={numberOfRows}
					rowsPerPage={dataLimit as number}
					page={(dataOffset as number) / (dataLimit as number)}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			) : null}
		</>
	);
};

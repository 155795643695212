import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { COLORS, COLOR_THEME } from '../../../theme';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(
	createStyles({
		dashboardCard: {
			display: 'flex',
			padding: '0',
			color: COLORS.gray.main,
			'&:hover': {
				boxShadow: 'none',
			},
		},
		item: {
			display: 'flex',
			flexDirection: 'column',
			width: '50%',
		},
		iconWrap: {
			display: 'flex',
			justifyContent: 'flex-end',
			alignContent: 'center',
			width: '100%',
			color: COLOR_THEME.primary,
		},
		icon: {
			fontSize: '2em',
			fill: 'currentColor',
		},
		cardHeader: {
			fontSize: '1.5em',
		},
		count: {
			fontSize: '2em',
		},
		link: {
			textDecoration: 'none',
		},
	}),
);

type CardProps = {
	id: string;
	label: string;
	count: number;
	icon: JSX.Element;
	href: string;
};

export const DashboardCard = ({ id, label, count, icon, href }: CardProps) => {
	const classes = useStyles();

	return (
		<Link className={classes.link} to={href}>
			<Card className={classes.dashboardCard}>
				<CardContent className={classes.item}>
					<Typography component="div" className={classes.cardHeader}>
						{label}
					</Typography>
					<Typography component="div" className={classes.count} data-cy={`div:${id}`}>
						{count > 0 ? <CountUp start={0} end={count} duration={1} delay={0.3} /> : count}
					</Typography>
				</CardContent>
				<CardContent className={classes.iconWrap}>{icon}</CardContent>
			</Card>
		</Link>
	);
};

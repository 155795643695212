import React from 'react';
import { Children } from '../../types';

type Props = {
	value: number;
	index: number;
	children: Children;
};

export const TabPanel = ({ value, index, children }: Props) => {
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
		>
			{value === index && children}
		</div>
	);
};

import * as devicePlacementsService from '../../services/device-placements/device-placements-service';
import { AsyncAction } from '../types';
import {
	generalPlacementRequest,
	generalPlacementError,
	fetchDevicePlacementsSuccess,
	createDevicePlacementSuccess,
	updateDevicePlacementSuccess,
	deleteDevicePlacementSuccess,
	attachDeviceSuccess,
	detachDeviceSuccess,
	fetchDefinitionsSuccess,
	createPlacementDefinitionSuccess,
	updatePlacementDefinitionSuccess,
	fetchPlacementConnectionsSuccess,
} from './device-placements-actions';

import { fetchRequest, createToastError, isStatusSuccess } from '../../services/helpers';
import { addToast } from '../app/app-actions';
import {
	CreateDevicePlacementPayload,
	DevicePlacementsResponseData,
	UpdateDevicePlacementPayload,
	AttachDevicePlacementPayload,
	UpdateDevicePlacementRelationResponseData,
	DetachDevicePlacementPayload,
	DeleteDevicePlacementPayload,
	DefinitionResponseData,
	DevicePlacement,
	Definition,
	CreatePlacementDefinitionRequestBody,
	UpdatePlacementDefinitionRequestBody,
	PlacementConnectionsResponseData,
} from '../../pages/DevicePlacementsManagement/device-placements-types';
import { FiltersRequestBody2 } from '../../components/Table/types';

//* Connections
export const fetchPlacementConnections: AsyncAction<FiltersRequestBody2> = (body) => async (dispatch) => {
	dispatch(generalPlacementRequest());

	const request = devicePlacementsService.fetchPlacementConnections(body);
	const { data, error } = await fetchRequest<PlacementConnectionsResponseData>(request);

	if (data) {
		dispatch(fetchPlacementConnectionsSuccess(data, body));
	} else {
		dispatch(generalPlacementError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(fetchPlacementConnections(body));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

//* Definitions
export const fetchDefinitions: AsyncAction<FiltersRequestBody2> = (body) => async (dispatch) => {
	dispatch(generalPlacementRequest());

	const request = devicePlacementsService.fetchDefinitions(body);
	const { data, error } = await fetchRequest<DefinitionResponseData>(request);

	if (data) {
		dispatch(fetchDefinitionsSuccess(data, body));
	} else {
		dispatch(generalPlacementError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(fetchDefinitions(body));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const createPlacementDefinition: AsyncAction<CreatePlacementDefinitionRequestBody> = (
	createPlacementDefinitionPayload,
) => async (dispatch, getState) => {
	dispatch(generalPlacementRequest());

	const { devicePlacements } = getState();
	const request = devicePlacementsService.createPlacementDefinition(createPlacementDefinitionPayload);
	const { status, error } = await fetchRequest<Definition>(request);

	if (isStatusSuccess(status)) {
		dispatch(createPlacementDefinitionSuccess());

		dispatch(
			fetchDefinitions({
				offset: devicePlacements.definitions.offset,
				limit: devicePlacements.definitions.limit,
				filtersAndConditions: devicePlacements.definitions.filtersAndConditions,
				orderings: devicePlacements.definitions.orderings,
			}),
		);
	} else {
		dispatch(generalPlacementError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data)));
	}
};

export const updatePlacementDefinition: AsyncAction<UpdatePlacementDefinitionRequestBody> = (
	updatePlacementDefinitonPayload,
) => async (dispatch, getState) => {
	dispatch(generalPlacementRequest());

	const { devicePlacements } = getState();
	const requestBody = updatePlacementDefinitonPayload;
	const request = devicePlacementsService.updatePlacementDefinition(requestBody, requestBody.id);
	const { data, status, error } = await fetchRequest<Definition>(request);

	if (isStatusSuccess(status) && data) {
		dispatch(updatePlacementDefinitionSuccess());

		dispatch(
			fetchDefinitions({
				offset: devicePlacements.definitions.offset,
				limit: devicePlacements.definitions.limit,
				filtersAndConditions: devicePlacements.definitions.filtersAndConditions,
				orderings: devicePlacements.definitions.orderings,
			}),
		);
	} else {
		dispatch(generalPlacementError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data)));
	}
};

//* Placements

export const fetchDevicePlacements: AsyncAction<FiltersRequestBody2> = (body) => async (dispatch) => {
	dispatch(generalPlacementRequest());

	const request = devicePlacementsService.fetchPlacements(body);
	const { data, error } = await fetchRequest<DevicePlacementsResponseData>(request);

	if (data) {
		dispatch(fetchDevicePlacementsSuccess(data, body));
	} else {
		dispatch(generalPlacementError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(fetchDevicePlacements(body));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const fetchDevicePlacementsWithTelemetry: AsyncAction<FiltersRequestBody2> = (body) => async (dispatch) => {
	dispatch(generalPlacementRequest());

	const request = devicePlacementsService.fetchPlacementsWithTelemetry(body);
	const { data, error } = await fetchRequest<DevicePlacementsResponseData>(request);

	if (data) {
		dispatch(fetchDevicePlacementsSuccess(data, body));
	} else {
		dispatch(generalPlacementError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(fetchDevicePlacementsWithTelemetry(body));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const createDevicePlacement: AsyncAction<CreateDevicePlacementPayload> = (
	createDevicePlacementPayload,
) => async (dispatch, getState) => {
	dispatch(generalPlacementRequest());

	const { devicePlacements } = getState();
	const request = devicePlacementsService.createPlacement(createDevicePlacementPayload);
	const { status, error } = await fetchRequest<DevicePlacement>(request);

	if (isStatusSuccess(status)) {
		dispatch(createDevicePlacementSuccess());

		dispatch(
			fetchDevicePlacements({
				offset: devicePlacements.placements.offset,
				limit: devicePlacements.placements.limit,
				filtersAndConditions: devicePlacements.placements.filtersAndConditions,
				orderings: devicePlacements.placements.orderings,
			}),
		);
	} else {
		dispatch(generalPlacementError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data)));
	}
};

export const updateDevicePlacement: AsyncAction<UpdateDevicePlacementPayload> = (
	updateDevicePlacementPayload,
) => async (dispatch, getState) => {
	dispatch(generalPlacementRequest());

	const { devicePlacements } = getState();
	const requestBody = updateDevicePlacementPayload;
	const request = devicePlacementsService.updatePlacement(requestBody, requestBody.id);
	const { data, status, error } = await fetchRequest<DevicePlacement>(request);

	if (isStatusSuccess(status) && data) {
		dispatch(updateDevicePlacementSuccess());

		dispatch(
			fetchDevicePlacements({
				offset: devicePlacements.placements.offset,
				limit: devicePlacements.placements.limit,
				filtersAndConditions: devicePlacements.placements.filtersAndConditions,
				orderings: devicePlacements.placements.orderings,
			}),
		);
	} else {
		dispatch(generalPlacementError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data)));
	}
};

export const deleteDevicePlacement: AsyncAction<DeleteDevicePlacementPayload> = (
	deleteDevicePlacementPayload,
) => async (dispatch, getState) => {
	dispatch(generalPlacementRequest());

	const { devicePlacements } = getState();
	const request = devicePlacementsService.deletePlacement(deleteDevicePlacementPayload.placementId);
	const { status, error } = await fetchRequest<DevicePlacement>(request);

	if (isStatusSuccess(status)) {
		dispatch(deleteDevicePlacementSuccess());

		dispatch(
			fetchDevicePlacements({
				offset: devicePlacements.placements.offset,
				limit: devicePlacements.placements.limit,
				filtersAndConditions: devicePlacements.placements.filtersAndConditions,
				orderings: devicePlacements.placements.orderings,
			}),
		);
	} else {
		dispatch(generalPlacementError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data)));
	}
};

//* Device Placement Relations

export const attachDeviceToPlacement: AsyncAction<AttachDevicePlacementPayload> = (
	attachDevicePlacementPayload,
) => async (dispatch, getState) => {
	dispatch(generalPlacementRequest());

	const { devicePlacements } = getState();
	const request = devicePlacementsService.attachDevice(attachDevicePlacementPayload);
	const { data, status, error } = await fetchRequest<UpdateDevicePlacementRelationResponseData>(request);

	if (isStatusSuccess(status)) {
		dispatch(attachDeviceSuccess());

		if (data) {
			dispatch(
				fetchDevicePlacements({
					offset: devicePlacements.placements.offset,
					limit: devicePlacements.placements.limit,
					filtersAndConditions: devicePlacements.placements.filtersAndConditions,
					orderings: devicePlacements.placements.orderings,
				}),
			);
		}
	} else {
		dispatch(generalPlacementError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data)));
	}
};

export const detachDeviceFromPlacement: AsyncAction<DetachDevicePlacementPayload> = (
	detachDevicePlacementPayload,
) => async (dispatch, getState) => {
	dispatch(generalPlacementRequest());

	const { devicePlacements } = getState();
	const { detached, relationId } = detachDevicePlacementPayload;
	const request = devicePlacementsService.detachDevice({ detached }, relationId);
	const { data, status, error } = await fetchRequest<UpdateDevicePlacementRelationResponseData>(request);

	if (isStatusSuccess(status)) {
		dispatch(detachDeviceSuccess());

		if (data) {
			dispatch(
				fetchDevicePlacements({
					offset: devicePlacements.placements.offset,
					limit: devicePlacements.placements.limit,
					filtersAndConditions: devicePlacements.placements.filtersAndConditions,
					orderings: devicePlacements.placements.orderings,
				}),
			);
		}
	} else {
		dispatch(generalPlacementError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data)));
	}
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { format, isValid } from 'date-fns';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { TableRow, TableCell, makeStyles } from '@material-ui/core';
import { Table } from '../../../components/Table/Table';
import { TableRowRenderProps } from '../../../components/Table/types';
import { TableRowActionIcon } from '../../../components/Table/constants';
import { TableActionsContainer } from '../../../components/Table/TableActionsContainer';
import { INPUT_DATE_TIME_MASK } from '../../../helpers/generalConstants';
import { COLORS } from '../../../theme';
import { RouteEnum } from '../../../router/Routes';
import { Notification } from '../reports-types';
import { Label } from '../../../components/Label/Label';

type Props = {
	data: Notification[];
	limit: number;
	offset: number;
};

const useStyles = makeStyles({
	'@keyframes colorPulse': {
		'0%': {
			backgroundColor: COLORS.transparent,
		},
		'25%': {
			backgroundColor: COLORS.white.main,
		},
		'50%': {
			backgroundColor: COLORS.transparent,
		},
		'75%': {
			backgroundColor: COLORS.white.main,
		},
		'100%': {
			backgroundColor: COLORS.transparent,
		},
	},
	colorPulse: {
		backgroundColor: COLORS.transparent,
		animation: '$colorPulse 3s linear',
	},
});

type TableRowData = Notification & {
	id: string;
};

export const NotificationsTable = ({ data, limit, offset }: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const history = useHistory();

	const rowActions = [
		{
			id: 'btn-detail:report',
			icon: TableRowActionIcon.report,
			tooltip: t('report'),
			onClick: (notification: TableRowData) => history.push(`${RouteEnum.REPORT}/${notification.workflowId}`),
		},
	];

	const columns = [
		{ id: 'sn', label: t('sn') },
		{ id: 'time', label: t('time') },
		{ id: 'severity', label: t('severity') },
		{ id: 'message', label: t('message') },
	];

	const tableData = data.map((notification) => ({
		id: `${notification.sn} - ${notification.time} - ${notification.severity}`,
		...notification,
	}));

	const renderTableRow = ({
		onClickRow,
		row: notification,
		renderActions,
		index,
	}: TableRowRenderProps<TableRowData>) => {
		return (
			<TableRow
				key={notification.id}
				id={`row-${notification.id}`}
				hover
				className={clsx(index === 0 && classes.colorPulse)}
				onClick={() => onClickRow(notification.sn)}
			>
				<TableCell component="td" id={notification.sn} scope="row">
					{notification.sn}
				</TableCell>
				<TableCell component="td" id={notification.time} scope="row">
					{isValid(new Date(notification.time)) && format(new Date(notification.time), INPUT_DATE_TIME_MASK)}
				</TableCell>
				<TableCell component="td" id={notification.severity} scope="row">
					<Label type={notification.severity}>{t(notification.severity) as string}</Label>
				</TableCell>
				<TableCell component="td" id={notification.message} scope="row">
					{notification.message}
				</TableCell>
				<TableCell padding="none" sortDirection={false} align="right">
					<TableActionsContainer>{renderActions(rowActions, notification)}</TableActionsContainer>
				</TableCell>
			</TableRow>
		);
	};

	return (
		<Table // TODO
			actions={rowActions}
			columns={columns}
			data={tableData}
			dataLimit={limit}
			dataOffset={offset}
			numberOfRows={tableData.length}
			// fetchAction={fetchNotifications}
			selectable={false}
			renderTableRow={renderTableRow}
		/>
	);
};

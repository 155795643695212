import { DevicePlacementsState, DevicePlacementsActions } from './device-placements-types';
import { createReducer } from 'typesafe-actions';
import {
	GENERAL_PLACEMENT_REQUEST,
	GENERAL_PLACEMENT_ERROR,
	FETCH_PLACEMENTS_SUCCESS,
	CREATE_PLACEMENT_SUCCESS,
	UPDATE_PLACEMENT_SUCCESS,
	DELETE_PLACEMENT_SUCCESS,
	ATTACH_DEVICE_SUCCESS,
	DETACH_DEVICE_SUCCESS,
	FETCH_DEFINITIONS_SUCCESS,
	CREATE_PLACEMENT_DEFINITION_SUCCESS,
	SELECTE_PLACEMENT_DEFINITION_TAB,
	UPDATE_PLACEMENT_DEFINITION_SUCCESS,
	FETCH_PLACEMENT_CONNECTIONS_SUCCESS,
} from './device-placements-constants';
import { set } from 'lodash/fp';

const devicesInitialState: DevicePlacementsState = {
	selectedTab: 0,
	definitions: {
		data: [],
		total: 0,
		offset: 0,
		limit: 10,
		orderings: [],
		filtersAndConditions: [],
	},
	connections: {
		data: [],
		total: 0,
		offset: 0,
		limit: 10,
		orderings: [],
		filtersAndConditions: [],
	},
	placements: {
		data: [],
		total: 0,
		offset: 0,
		limit: 10,
		orderings: [],
		filtersAndConditions: [],
	},
};

export const devicePlacementsReducer = createReducer<DevicePlacementsState, DevicePlacementsActions>(
	devicesInitialState,
	{
		[GENERAL_PLACEMENT_REQUEST]: (state) => state,
		[GENERAL_PLACEMENT_ERROR]: (state) => state,
		[SELECTE_PLACEMENT_DEFINITION_TAB]: (state, action) => set('selectedTab', action.payload.selected, state),
		[FETCH_DEFINITIONS_SUCCESS]: (state, action) => {
			const newState = {
				data: action.payload.data.data,
				total: action.payload.data.total,
				limit: action.payload.options ? action.payload.options.limit : state.definitions.limit,
				offset: action.payload.options ? action.payload.options.offset : state.definitions.offset,
				orderings: action.payload.options?.orderings || state.definitions.orderings,
				filtersAndConditions:
					action.payload.options?.filtersAndConditions || state.definitions.filtersAndConditions,
			};

			return set('definitions', newState, state);
		},
		[FETCH_PLACEMENT_CONNECTIONS_SUCCESS]: (state, action) => {
			const newState = {
				data: action.payload.data.data,
				total: action.payload.data.total,
				limit: action.payload.options ? action.payload.options.limit : state.connections.limit,
				offset: action.payload.options ? action.payload.options.offset : state.connections.offset,
				orderings: action.payload.options?.orderings || state.connections.orderings,
				filtersAndConditions:
					action.payload.options?.filtersAndConditions || state.connections.filtersAndConditions,
			};

			return set('connections', newState, state);
		},
		[CREATE_PLACEMENT_DEFINITION_SUCCESS]: (state) => state,
		[UPDATE_PLACEMENT_DEFINITION_SUCCESS]: (state) => state,
		[FETCH_PLACEMENTS_SUCCESS]: (state, action) => {
			const newState = {
				data: action.payload.data.data,
				total: action.payload.data.total,
				limit: action.payload.options ? action.payload.options.limit : state.placements.limit,
				offset: action.payload.options ? action.payload.options.offset : state.placements.offset,
				orderings: action.payload.options?.orderings || state.placements.orderings,
				filtersAndConditions:
					action.payload.options?.filtersAndConditions || state.placements.filtersAndConditions,
				updatedTime: new Date().toISOString(),
			};

			return set('placements', newState, state);
		},
		[CREATE_PLACEMENT_SUCCESS]: (state) => state,
		[UPDATE_PLACEMENT_SUCCESS]: (state) => state,
		[DELETE_PLACEMENT_SUCCESS]: (state) => state,

		[ATTACH_DEVICE_SUCCESS]: (state) => state,
		[DETACH_DEVICE_SUCCESS]: (state) => state,
	},
);

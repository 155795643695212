import { FormControl, InputAdornment } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import { InputField } from '../Form/InputField';
import { TableFilterHeading } from './TableFilterHeading';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { Value, Values } from '../../types';

type Props = {
	name: string;
	label: string;
	value: Value | Values;
	onChange: (event: ChangeEvent<HTMLInputElement>, name: string) => void;
};

export const TableSearchFilter = ({ name, label, value, onChange }: Props) => {
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		onChange(event, name);
	};

	return (
		<FormControl fullWidth margin="dense">
			<TableFilterHeading text={`${label}:`} />
			<InputField
				autoFocus
				margin="none"
				name={name}
				onChange={handleChange}
				size="small"
				type="search"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchRoundedIcon />
						</InputAdornment>
					),
				}}
				value={value}
			/>
		</FormControl>
	);
};

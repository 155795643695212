import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Button } from '../../../components/Button/Button';
import { InputField } from '../../../components/Form/InputField';
import SaveIcon from '@material-ui/icons/Save';
import { ModalDialogFormActions } from '../../../components/ModalDialog/ModalDialogFormActions';
import { EditTenantRequestBody } from '../tenants-types';
import { UUID } from '../../../types';

// export type TenantFormValues = Omit<EditTenantRequestBody, 'id'> & Partial<Pick<EditTenantRequestBody, 'id'>>;
export type TenantFormValues = {
	id?: UUID;
	name: string;
	active: boolean;
};

type Props = {
	onSubmit: (values: TenantFormValues) => void;
	onCancel: () => void;
	defaultValues?: EditTenantRequestBody;
};

export const TenantForm = ({ onSubmit, onCancel, defaultValues }: Props) => {
	const { t } = useTranslation();

	const [name, setName] = useState<string>(defaultValues?.name ?? '');
	const [nameError, setNameError] = useState<boolean>(false);

	const handleSetName = (event: ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value);
		setNameError(false);
	};

	function handleSubmitForm() {
		let valid = true;

		if (name === '') {
			valid = false;
			setNameError(true);
		}

		if (valid) {
			onSubmit({
				id: defaultValues?.id,
				name: name,
				active: defaultValues?.active ?? true,
			});
		}
	}

	return (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<InputField
					autoFocus
					value={name}
					error={nameError}
					helperText={nameError && t('validationErrors.required')}
					label={t('name')}
					name="name"
					onChange={handleSetName}
				/>
			</Grid>
			<Grid item>
				<ModalDialogFormActions>
					<Button startIcon={<SaveIcon />} onClick={handleSubmitForm} data-cy="btn-submit:deviceTypeForm">
						{t('save')}
					</Button>
					<Button onClick={onCancel} variant="text" data-cy="btn-cancel:deviceTypeForm">
						{t('cancel')}
					</Button>
				</ModalDialogFormActions>
			</Grid>
		</Grid>
	);
};

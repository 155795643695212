import React from 'react';

type Props = {
	fill: string;
};

export const MarkerPointIcon = ({ fill }: Props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill={fill} viewBox="0 0 48 48">
			<path d="M24 4c-7.74 0-14 6.26-14 14 0 10.5 14 26 14 26s14-15.5 14-26c0-7.74-6.26-14-14-14z" />
			<circle cx="24" cy="18" r="11" fill="#fff" />
			<path d="M32.25 15c0 .825-.675 1.5-1.5 1.5-.135 0-.262-.015-.383-.052l-2.67 2.662c.038.12.053.255.053.39 0 .825-.675 1.5-1.5 1.5a1.504 1.504 0 01-1.448-1.89l-1.912-1.912c-.12.037-.255.052-.39.052s-.27-.015-.39-.052l-3.412 3.42c.037.12.052.247.052.382 0 .825-.675 1.5-1.5 1.5s-1.5-.675-1.5-1.5.675-1.5 1.5-1.5c.135 0 .262.015.383.052l3.42-3.412a1.504 1.504 0 011.448-1.89 1.504 1.504 0 011.447 1.89l1.912 1.912c.12-.037.255-.052.39-.052s.27.015.39.052l2.662-2.67A1.275 1.275 0 0129.25 15c0-.825.675-1.5 1.5-1.5s1.5.675 1.5 1.5z" />
		</svg>
	);
};

import React from 'react';
import { Table } from '../../../components/Table/Table';
import { useTranslation } from 'react-i18next';
import { CreateProjectPayload, EditProjectPayload, ProjectResponseData } from '../projects-types';
import {
	fetchProjects,
	editProject,
	createProject,
	deleteProject,
} from '../../../store/projects/projects-async-actions';

import { FilterOperators, FilterType, TableRowActionIcon } from '../../../components/Table/constants';
import { useDispatch, useSelector } from 'react-redux';
import { addModalDialog, removeModalDialog } from '../../../store/app/app-actions';
import { ProjectForm } from './ProjectForm';
import { UUID } from '../../../types';
import { Tooltip, TableRow, TableCell, CardHeader, IconButton, Divider } from '@material-ui/core';
import {
	TableColumnFilter,
	TableColumnSort,
	TableHeadCell,
	TableRowRenderProps,
} from '../../../components/Table/types';
import { CheckRounded, ClearRounded, Add, DeleteForever } from '@material-ui/icons';
import { COLOR_THEME } from '../../../theme';
import { UserState } from '../../../store/user/user-types';
import { PermissionsDotNet } from '../../../helpers/generalConstants';
import { RootState } from '../../../store/types';
import { hasPermission } from '../../../helpers/auth';

type Props = {
	data: ProjectResponseData[];
	total: number;
	limit: number;
	offset: number;
	orderings: TableColumnSort[];
	filters: TableColumnFilter[];
};

export const ProjectsTable = ({ data, total, offset, limit, orderings, filters }: Props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { permissions } = useSelector<RootState, UserState>((state) => state.user);
	const canEditProjects = hasPermission(PermissionsDotNet.ProjectWrite, permissions);

	const nameOrder = orderings?.find((order) => order.column === 'name');

	const nameFilter = filters?.find((filter) => filter.column === 'name');

	const columns: TableHeadCell[] = [
		{
			id: 'name',
			label: t('name'),
			filters: nameFilter && [
				{
					column: 'name',
					operator: FilterOperators.contains,
					value: nameFilter.value,
				},
			],
			filterType: FilterType.String,
			sort: nameOrder?.sortOrder && { column: 'name', sortOrder: nameOrder?.sortOrder },
		},
		{ id: 'active', label: t('active'), unorderable: true },
	];

	const handleCloseModal = (id: string) => {
		dispatch(removeModalDialog(id));
	};

	const handleCreateProject = (data: CreateProjectPayload, modalDialogId: string) => {
		handleCloseModal(modalDialogId);
		dispatch(createProject(data));
	};

	const handleEditProject = (data: EditProjectPayload, modalId: string) => {
		dispatch(editProject(data));
		handleCloseModal(modalId);
	};

	const handleDeleteProject = (projectId: UUID, modalDialogId: string) => {
		handleCloseModal(modalDialogId);
		dispatch(deleteProject(projectId));
	};

	const rowActions = [
		{
			id: 'btn-edit:project',
			icon: TableRowActionIcon.edit,
			tooltip: t('edit'),
			onClick: (project: ProjectResponseData) => {
				dispatch(
					addModalDialog({
						id: 'editProjectModal',
						title: t('editProjectModalTitle'),
						content: (
							<ProjectForm
								onSubmit={(values) =>
									handleEditProject({ id: project?.id, ...values }, 'editProjectModal')
								}
								onCancel={() => handleCloseModal('editProjectModal')}
								defaultValues={{
									name: project.name,
									active: true,
								}}
							/>
						),
					}),
				);
			},
		},
		{
			id: 'btn-delete:project',
			icon: TableRowActionIcon.delete,
			tooltip: t('delete'),
			onClick: (project: ProjectResponseData) =>
				dispatch(
					addModalDialog({
						id: 'deleteProjectModal',
						title: t('deleteProjectModalTitle'),
						contentText: t('deleteProjectModalDescription', {
							project: project.name,
						}),
						buttons: [
							{
								id: 'deleteProject',
								value: t('delete'),
								onClick: () => handleDeleteProject(project.id, 'deleteProjectModal'),
								startIcon: <DeleteForever />,
							},
							{
								id: 'cancelDeleteProject',
								value: t('cancel'),
								onClick: () => handleCloseModal('deleteProjectModal'),
								variant: 'text',
							},
						],
					}),
				),
		},
	];

	const renderRow = ({ row: project, renderActions }: TableRowRenderProps<ProjectResponseData>) => {
		return (
			<TableRow key={project.id} id={`row-${project.id}`}>
				<TableCell>{project.name}</TableCell>
				<TableCell component="td" id={`${project.id}-${project.active}`} scope="row">
					{project.active ? (
						<CheckRounded htmlColor={COLOR_THEME.success} data-cy="check:active" />
					) : (
						<ClearRounded htmlColor={COLOR_THEME.error} data-cy="check:inactive" />
					)}
				</TableCell>
				{canEditProjects && (
					<TableCell padding="none" sortDirection={false}>
						{renderActions(rowActions, project)}
					</TableCell>
				)}
			</TableRow>
		);
	};

	return (
		<>
			<CardHeader
				title={t('projects')}
				action={
					<>
						{canEditProjects && (
							<Tooltip title={String(t('createProject'))} aria-label={t('createProject')}>
								<IconButton
									onClick={() =>
										dispatch(
											addModalDialog({
												id: 'createProjectModal',
												title: t('createProjectModalTitle'),
												content: (
													<ProjectForm
														onSubmit={(values) =>
															handleCreateProject(values, 'createProjectModal')
														}
														onCancel={() => handleCloseModal('createProjectModal')}
													/>
												),
											}),
										)
									}
									data-cy="btn-create:project"
								>
									<Add />
								</IconButton>
							</Tooltip>
						)}
					</>
				}
			/>
			<Divider light />
			<Table
				actions={canEditProjects ? rowActions : undefined}
				columns={columns}
				orderings={orderings}
				filtersAndConditions={filters}
				data={data}
				dataLimit={limit}
				dataOffset={offset}
				renderTableRow={renderRow}
				numberOfRows={total}
				fetchAction2={fetchProjects}
				selectable={false}
			/>
		</>
	);
};

import { action } from 'typesafe-actions';
import {
	FETCH_REPORTS_REQUEST,
	FETCH_REPORTS_SUCCESS,
	FETCH_REPORTS_ERROR,
	FETCH_NOTIFICATIONS_REQUEST,
	FETCH_NOTIFICATIONS_SUCCESS,
	FETCH_NOTIFICATIONS_ERROR,
	UPDATE_CURRENT_NOTIFICATIONS,
	FETCH_CURRENT_REPORTS_SUCCESS,
} from './reports-constants';

import {
	ReportResponseData,
	NotificationsResponseData,
	NotificationSocketData,
} from '../../pages/Reports/reports-types';
import { FiltersRequestBody2 } from '../../components/Table/types';

export const fetchReportsRequest = () => action(FETCH_REPORTS_REQUEST);
export const fetchReportsSuccess = (data: ReportResponseData) =>
	action(FETCH_REPORTS_SUCCESS, {
		data,
	});

export const fetchCurrentReportsSuccess = (data: ReportResponseData) => action(FETCH_CURRENT_REPORTS_SUCCESS, { data });

export const fetchReportsError = () => action(FETCH_REPORTS_ERROR);

export const fetchNotificationsRequest = () => action(FETCH_NOTIFICATIONS_REQUEST);
export const fetchNotificationsSuccess = (data: NotificationsResponseData, options?: FiltersRequestBody2) =>
	action(FETCH_NOTIFICATIONS_SUCCESS, { data, options });

export const fetchNotificationsError = () => action(FETCH_NOTIFICATIONS_ERROR);

export const updateCurrentNotifications = (data: NotificationSocketData) =>
	action(UPDATE_CURRENT_NOTIFICATIONS, { data });

import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Children } from '../../types';
import { BREAKPOINT_KEYS } from '../../helpers/generalConstants';
import { Container } from '@material-ui/core';

export const CONTENT_PADDING = 9;
export const CONTENT_PADDING_SM_UP = 12;

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
	content: {
		height: '100vh',
		overflow: 'auto',
		flexGrow: 1,
		paddingTop: spacing(CONTENT_PADDING),
		paddingBottom: spacing(CONTENT_PADDING),
		[breakpoints.up(BREAKPOINT_KEYS.sm)]: {
			paddingTop: spacing(CONTENT_PADDING_SM_UP),
			paddingBottom: spacing(CONTENT_PADDING_SM_UP),
		},
	},
}));

type Props = {
	children: Children;
};

export const Content = ({ children }: Props) => {
	const classes = useStyles();

	return (
		<main className={classes.content}>
			<Container maxWidth={false}>{children}</Container>
		</main>
	);
};

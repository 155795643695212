import { createStore, applyMiddleware } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { RootActions, RootState } from './types';
import { rootReducer } from './rootReducer';
import { fetchingMiddleware } from './middlewares/fetching-middleware';
import { persistStore } from 'redux-persist';

const composeEnhancers = composeWithDevTools({
	serialize: true,
});

const middlewares = [thunk as ThunkMiddleware<RootState, RootActions>, fetchingMiddleware];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const store = createStore<RootState, RootActions, any, any>(
	// TODO: update redux-persist when this MR is merged, it should fix TS error
	// https://github.com/rt2zz/redux-persist/pull/1085
	// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
	// @ts-ignore
	rootReducer,
	process.env.REACT_APP_APPLICATION_ENV !== 'prod'
		? composeEnhancers(applyMiddleware(...middlewares))
		: applyMiddleware(...middlewares),
);

export const persistor = persistStore(store);

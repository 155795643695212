export const SELECTE_PLACEMENT_DEFINITION_TAB = '[DEVICE_PLACEMENTS_ACTIONS] SELECTE_PLACEMENT_DEFINITION_TAB';

export const GENERAL_PLACEMENT_REQUEST = '[DEVICE_PLACEMENTS_ACTIONS] GENERAL_PLACEMENT_REQUEST';
export const GENERAL_PLACEMENT_ERROR = '[DEVICE_PLACEMENTS_ACTIONS] GENERAL_PLACEMENT_ERROR';

export const FETCH_DEFINITIONS_SUCCESS = '[DEVICE_PLACEMENTS_ACTIONS] FETCH_DEFINITIONS_SUCCESS';

export const FETCH_PLACEMENT_CONNECTIONS_SUCCESS = '[DEVICE_PLACEMENTS_ACTIONS] FETCH_PLACEMENT_CONNECTIONS_SUCCESS';

export const CREATE_PLACEMENT_DEFINITION_SUCCESS = '[DEVICE_PLACEMENTS_ACTIONS] CREATE_PLACEMENT_DEFINITION_SUCCESS';

export const UPDATE_PLACEMENT_DEFINITION_SUCCESS = '[DEVICE_PLACEMENTS_ACTIONS] UPDATE_PLACEMENT_DEFINITION_SUCCESS';

export const FETCH_PLACEMENTS_SUCCESS = '[DEVICE_PLACEMENTS_ACTIONS] FETCH_PLACEMENTS_SUCCESS';

export const CREATE_PLACEMENT_SUCCESS = '[DEVICE_PLACEMENTS_ACTIONS] CREATE_PLACEMENT_SUCCESS';

export const UPDATE_PLACEMENT_SUCCESS = '[DEVICE_PLACEMENTS_ACTIONS] UPDATE_PLACEMENT_SUCCESS';

export const DELETE_PLACEMENT_SUCCESS = '[DEVICE_PLACEMENTS_ACTIONS] DELETE_PLACEMENT_SUCCESS';

export const ATTACH_DEVICE_SUCCESS = '[DEVICE_PLACEMENTS_ACTIONS] ATTACH_DEVICE_SUCCESS';

export const DETACH_DEVICE_SUCCESS = '[DEVICE_PLACEMENTS_ACTIONS] DETACH_DEVICE_SUCCESS';

import React from 'react';
import { Card, CardContent, CardHeader, Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Children } from '../../../types';

type Props = {
	title: string;
	children: Children;
	noSpacing?: boolean;
	withChart?: boolean;
	value?: string | React.ReactNode;
};

const useStyles = makeStyles(({ spacing }: Theme) => ({
	card: {
		height: ({ withChart }: Props) => (withChart ? '100%' : ''),
		maxHeight: ({ withChart }: Props) => (withChart ? spacing(41) : ''),
	},
	noSpacing: {
		padding: `${spacing(0)} !important`,
	},
	action: {
		margin: 0,
	},
	content: {
		height: '100%',
	},
}));

export const DetailCard = (props: Props) => {
	const { title, value, children, noSpacing = true, withChart = false } = props;
	const classes = useStyles(props);

	return (
		<Card className={classes.card}>
			<CardHeader
				classes={{ action: classes.action }}
				title={title}
				action={typeof value === 'string' ? <Typography variant="h5">{value}</Typography> : value}
			/>
			<Divider light />
			<CardContent className={clsx(withChart && classes.content, noSpacing && classes.noSpacing)}>
				{children}
			</CardContent>
		</Card>
	);
};

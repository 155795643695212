import React from 'react';
import { Table } from '../../../components/Table/Table';
import { useTranslation } from 'react-i18next';
import {
	TableColumnFilter,
	TableColumnSort,
	TableHeadCell,
	TableRowRenderProps,
} from '../../../components/Table/types';
import { TableRow, TableCell, CardHeader, Divider, Tooltip, IconButton, Grid } from '@material-ui/core';
import { addModalDialog, removeModalDialog } from '../../../store/app/app-actions';
import { useDispatch, useSelector } from 'react-redux';
import { FilterOperators, FilterType, TableRowActionIcon } from '../../../components/Table/constants';
import { UUID } from '../../../types';
import { TableActionsContainer } from '../../../components/Table/TableActionsContainer';
import { IngestionScript } from '../ingestion-scripts-types';
import {
	deleteIngestionScript,
	fetchIngestionScripts,
} from '../../../store/ingestion-endpoints/ingestion-endpoints-async-actions';
import { RouteEnum } from '../../../router/Routes';
import { useHistory } from 'react-router-dom';
import { Add, DeleteForever } from '@material-ui/icons';
import { hasPermission } from '../../../helpers/auth';
import { PermissionsDotNet } from '../../../helpers/generalConstants';
import { RootState } from '../../../store/types';
import { UserState } from '../../../store/user/user-types';

type Props = {
	data: IngestionScript[];
	total: number;
	limit: number;
	offset: number;
	orderings: TableColumnSort[];
	filters: TableColumnFilter[];
	selected?: string;
	onClickScript?: (script: IngestionScript) => void;
};

export const ScriptsTable = ({ data, total, limit, offset, orderings, filters, selected, onClickScript }: Props) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const history = useHistory();
	const { permissions } = useSelector<RootState, UserState>((state) => state.user);
	const canEditScripts = hasPermission(PermissionsDotNet.ScriptWrite, permissions);

	const nameOrder = orderings?.find((order) => order.column === 'name');
	// const namespaceOrder = orderings?.find((order) => order.column === 'namespace');

	const nameFilter = filters?.find((filter) => filter.column === 'name');
	// const namespaceFilter = filters?.find((filter) => filter.column === 'namespace');

	const columns: TableHeadCell[] = [
		{
			id: 'name',
			label: t('name'),
			filters: nameFilter && [
				{
					column: 'name',
					operator: FilterOperators.contains,
					value: nameFilter.value,
				},
			],
			filterType: FilterType.String,
			sort: nameOrder?.sortOrder && { column: 'name', sortOrder: nameOrder?.sortOrder },
		},
		{
			id: 'namespace',
			label: 'namespace',
			// filter: namespaceFilter && {
			// 	column: 'namespace',
			// 	operator: FilterOperators.contains,
			// 	value: namespaceFilter.value,
			// },
			// filterType: FilterType.String,
			// sort: namespaceOrder?.sortOrder && { column: 'namespace', sortOrder: namespaceOrder?.sortOrder },
			unorderable: true,
		},
		{
			id: 'usingNamespaces',
			label: 'usingNamespaces',
			unorderable: true,
		},
	];

	const handleCloseModalDialog = (id: string) => {
		dispatch(removeModalDialog(id));
	};

	const handleDeleteScript = (scriptId: UUID, modalDialogId: string) => {
		handleCloseModalDialog(modalDialogId);
		dispatch(deleteIngestionScript(scriptId));
	};

	const rowActions = [
		{
			id: 'btn-delete:script',
			icon: TableRowActionIcon.delete,
			tooltip: t('delete'),
			onClick: (script: IngestionScript) =>
				dispatch(
					addModalDialog({
						id: 'deleteScriptModal',
						title: t('deleteScriptModalTitle'),
						contentText: t('deleteScriptModalDescription', {
							script: script.name,
						}),
						buttons: [
							{
								id: 'deleteScript',
								value: t('delete'),
								onClick: () => handleDeleteScript(script.id, 'deleteScriptModal'),
								startIcon: <DeleteForever />,
							},
							{
								id: 'cancelDeleteScript',
								value: t('cancel'),
								onClick: () => handleCloseModalDialog('deleteScriptModal'),
								variant: 'text',
							},
						],
					}),
				),
		},
		{
			id: 'btn-detail:telemetry',
			icon: TableRowActionIcon.detail,
			tooltip: t('detail'),
			onClick: (script: IngestionScript) => history.push(`${RouteEnum.INGESTION_SCRIPTS}/${script.id}`),
		},
	];

	const renderTableRow = ({ onClickRow, row: script, renderActions }: TableRowRenderProps<IngestionScript>) => {
		return (
			<TableRow
				key={script.id}
				id={`row-${script.id}`}
				hover
				onClick={onClickScript ? () => onClickScript(script) : undefined}
				selected={script.id === selected}
			>
				<TableCell component="td" id={script.name} scope="row">
					{script.name}
				</TableCell>
				<TableCell component="td" id={'script.namespace'} scope="row">
					{script.namespace}
				</TableCell>
				<TableCell component="td" id={'script.usingNamespaces'} scope="row">
					{
						<Grid container direction="column">
							{script.usingNamespaces?.map((namespace) => {
								return (
									<Grid key={namespace} item>
										{namespace}
									</Grid>
								);
							})}
						</Grid>
					}
				</TableCell>
				{canEditScripts && (
					<TableCell padding="none" sortDirection={false} align="right">
						<TableActionsContainer>{renderActions(rowActions, script)}</TableActionsContainer>
					</TableCell>
				)}
			</TableRow>
		);
	};

	return (
		<>
			<CardHeader
				title={t('ingestionScripts')}
				action={
					<>
						{canEditScripts && (
							<Tooltip title={String(t('createScript'))} aria-label={t('createScript')}>
								<IconButton onClick={() => history.push(`${RouteEnum.INGESTION_SCRIPTS}/new-script`)}>
									<Add />
								</IconButton>
							</Tooltip>
						)}
					</>
				}
			/>
			<Divider light />
			<Table
				columns={columns}
				orderings={orderings}
				filtersAndConditions={filters}
				data={data}
				dataLimit={limit}
				dataOffset={offset}
				numberOfRows={total}
				fetchAction2={fetchIngestionScripts}
				actions={canEditScripts ? rowActions : undefined}
				renderTableRow={renderTableRow}
			/>
		</>
	);
};

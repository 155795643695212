import { apiClient } from '../client';
import { FiltersRequestBody2 } from '../../components/Table/types';
import { UUID } from '../../types';
import { CreateInDoorMapRequestBody, InDoorMap, InDoorMapsResponseData } from '../../pages/InDoorMaps/indoormaps-types';

const baseUrlForDotNet = '/zone-manager.net/maps';

export const getFilteredInDoorMaps = (body: FiltersRequestBody2) =>
	apiClient.post<InDoorMapsResponseData>(`${baseUrlForDotNet}/filter`, body);

export const createInDoorMap = (body: CreateInDoorMapRequestBody) => apiClient.post<InDoorMap>(baseUrlForDotNet, body);

export const editInDoorMap = (body: CreateInDoorMapRequestBody, inDoorMapId: UUID) =>
	apiClient.put<InDoorMap>(`${baseUrlForDotNet}/${inDoorMapId}`, body);

export const deleteInDoorMap = (inDoorMapId: UUID) => apiClient.delete(`${baseUrlForDotNet}/${inDoorMapId}`);

export const MAIN_MENU_WIDTH_OPEN = {
	xs: '100%',
	sm: '100%',
	md: 240,
	lg: 240,
	xl: 240,
};

export const MAIN_MENU_WIDTH_CLOSED = {
	xs: 0,
	sm: 0,
	md: 56,
	lg: 56,
	xl: 56,
};

import React, { useEffect, useState } from 'react';
import { Table } from '../../../components/Table/Table';
import { useTranslation } from 'react-i18next';
import { TableRowRenderProps } from '../../../components/Table/types';
import { Report } from '../../../pages/Reports/reports-types';
import { TableRow, TableCell, Select, MenuItem, Box } from '@material-ui/core';
import { prepareReportData } from '../../../pages/Reports/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/types';
import { ReportsState } from '../../../store/reports/reports-types';
import { UserState } from '../../../store/user/user-types';
import { WorkflowsState } from '../../../store/workflows/workflows-types';
import { fetchWorkflows } from '../../../store/workflows/workflows-async-actions';

export const DashboardReportsTable = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { project } = useSelector<RootState, UserState>((state) => state.user);
	const [workflowId, setWorkflowId] = useState<string | undefined>();
	const { currentReports } = useSelector<RootState, ReportsState>((state) => state.reports);
	const { data: workflowsData, limit, offset, filtersAndConditions, orderings } = useSelector<
		RootState,
		WorkflowsState
	>((state) => state.workflows);

	useEffect(() => {
		if (project?.id) {
			dispatch(
				fetchWorkflows({
					limit,
					offset,
					orderings,
					filtersAndConditions,
				}),
			);
		}

		// Don't need to react on "config" and "filters" and deep comparision is not necessary
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, project]);

	if (workflowId === undefined && workflowsData && workflowsData.length > 0) {
		setWorkflowId(workflowsData[0].id);
	}

	useEffect(() => {
		if (workflowId) {
			// TODO dispatch(fetchCurrentReports({ workflowId }));
		}
	}, [dispatch, workflowId]);

	const columns = currentReports.map(({ groupName }) => ({
		id: groupName,
		label: groupName,
	}));

	const renderTableRow = ({ row }: TableRowRenderProps<Report>) => {
		return (
			<TableRow key={row.id} id={`row-${row.id}`}>
				{Object.values(row).map((item) => (
					<TableCell key={item as string | number}>{item as string | number}</TableCell>
				))}
			</TableRow>
		);
	};

	const reports = prepareReportData(currentReports);

	return (
		<>
			<Box paddingLeft={2}>
				<Select
					defaultValue={workflowId}
					onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
						// const def = workflowsData.find((workflow) => workflow.id === event.target.value);

						return setWorkflowId(event.target.value as string);
					}}
				>
					{workflowsData.map((workflow) => (
						<MenuItem key={workflow.id} value={workflow.id}>
							{workflow.name}
						</MenuItem>
					))}
				</Select>
			</Box>
			<Table
				columns={[{ id: 'reportings', label: t('reportings') }, ...columns]}
				data={reports}
				dataLimit={limit}
				dataOffset={offset}
				numberOfRows={reports.length}
				selectable={false}
				renderTableRow={renderTableRow}
				filtersAndConditions={filtersAndConditions}
				orderings={orderings}
			/>
		</>
	);
};

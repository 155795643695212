import React from 'react';
import { Redirect, Route, RouteProps, RouteComponentProps } from 'react-router-dom';
import { RouteEnum } from './Routes';
import { DrawerLayout } from '../components/Layout/DrawerLayout';

type OwnProps = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
	isLoggedIn: boolean;
	title: string;
	subTitle?: string;
};

type Props = RouteProps & OwnProps;

export const PrivateRoute = ({ component: Component, isLoggedIn, title, subTitle, ...restProps }: Props) => (
	<Route
		{...restProps}
		render={(props) =>
			isLoggedIn ? (
				<DrawerLayout title={title} subTitle={subTitle}>
					<Component {...props} />
				</DrawerLayout>
			) : (
				<Redirect
					to={{
						pathname: RouteEnum.LOGIN,
						state: { from: props.location },
					}}
				/>
			)
		}
	/>
);

import * as endpointsService from '../../services/ingestion-endpoints/ingestion-endpoints-service';
import { addToast } from '../app/app-actions';
import { fetchRequest, isStatusSuccess, createToastError } from '../../services/helpers';
import { AsyncAction } from '../types';
import {
	DotNetIngestionEndpoint,
	CreateDotNetIngestionEndpointRequestBody,
	EditDotNetIngestionEndpointPayload,
	DotNetIngestionEndpointResponseData,
} from '../../pages/IngestionEndpoints/ingestion-endpoints-types';

import {
	ingestionCommonEmptyRequest,
	ingestionCommonEmptyError,
	fetchIngestionScriptsSuccess,
	fetchDotNetIngestionEndpointsSuccess,
	createDotNetIngestionEndpointSuccess,
	editDotNetIngestionEndpointSuccess,
	deleteDotNetIngestionEndpointSuccess,
	deleteIngestionScriptSuccess,
	createIngestionScriptSuccess,
	editIngestionScriptSuccess,
} from './ingestion-endpoints-actions';

import { UUID } from '../../types';
import { omit } from 'lodash';

import { FiltersRequestBody2 } from '../../components/Table/types';
import {
	CreateIngestionScriptRequestBody,
	EditIngestionScriptPayload,
	IngestionScript,
	IngestionScriptsResponseData,
} from '../../pages/IngestionScripts/ingestion-scripts-types';

export const fetchIngestionScripts: AsyncAction<FiltersRequestBody2> = (ingestScriptsPayload) => async (dispatch) => {
	dispatch(ingestionCommonEmptyRequest());

	const request = endpointsService.getFilteredIngestionScripts(ingestScriptsPayload);
	const { data, error } = await fetchRequest<IngestionScriptsResponseData>(request);

	if (data) {
		dispatch(fetchIngestionScriptsSuccess(data, ingestScriptsPayload));
	} else {
		dispatch(ingestionCommonEmptyError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data, () => dispatch(fetchIngestionScripts(ingestScriptsPayload)))));
	}
};

export const createIngestionScript: AsyncAction<CreateIngestionScriptRequestBody> = (script) => async (
	dispatch,
	getState,
) => {
	dispatch(ingestionCommonEmptyRequest());

	const { ingestionEndpoints } = getState();
	const request = endpointsService.createIngestionScript(script);
	const { data, status, error } = await fetchRequest<IngestionScript>(request);

	if (isStatusSuccess(status) && data) {
		dispatch(createIngestionScriptSuccess());
		dispatch(
			fetchIngestionScripts({
				limit: ingestionEndpoints.scripts.limit,
				offset: ingestionEndpoints.scripts.offset,
				orderings: ingestionEndpoints.scripts.orderings,
				filtersAndConditions: ingestionEndpoints.scripts.filters,
			}),
		);
	} else {
		dispatch(ingestionCommonEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(createIngestionScript(script));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const editIngestionScript: AsyncAction<EditIngestionScriptPayload> = (script) => async (dispatch, getState) => {
	dispatch(ingestionCommonEmptyRequest());

	const body = omit(script, ['id']);
	const { ingestionEndpoints } = getState();
	const request = endpointsService.editIngestionScript(body, script.id);
	const { data, error } = await fetchRequest<IngestionScript>(request);

	if (data) {
		dispatch(editIngestionScriptSuccess(data));
		dispatch(
			fetchIngestionScripts({
				limit: ingestionEndpoints.scripts.limit,
				offset: ingestionEndpoints.scripts.offset,
				orderings: ingestionEndpoints.scripts.orderings,
				filtersAndConditions: ingestionEndpoints.scripts.filters,
			}),
		);
	} else {
		dispatch(ingestionCommonEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(editIngestionScript(script));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const deleteIngestionScript: AsyncAction<UUID> = (endpointId) => async (dispatch) => {
	dispatch(ingestionCommonEmptyRequest());

	const request = endpointsService.deleteIngestionScript(endpointId);
	const { status, error } = await fetchRequest<void>(request);

	if (isStatusSuccess(status) && endpointId) {
		dispatch(deleteIngestionScriptSuccess(endpointId));
	} else {
		dispatch(ingestionCommonEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(deleteIngestionScript(endpointId));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

//

export const fetchDotNetIngestionEndpoints: AsyncAction<FiltersRequestBody2> = (ingestEndpointsPayload) => async (
	dispatch,
) => {
	dispatch(ingestionCommonEmptyRequest());

	const request = endpointsService.getFilteredDotNetIngestionEndpoints(ingestEndpointsPayload);
	const { data, error } = await fetchRequest<DotNetIngestionEndpointResponseData>(request);

	if (data) {
		dispatch(fetchDotNetIngestionEndpointsSuccess(data, ingestEndpointsPayload));
	} else {
		dispatch(ingestionCommonEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(fetchDotNetIngestionEndpoints(ingestEndpointsPayload));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const createDotNetIngestionEndpoint: AsyncAction<CreateDotNetIngestionEndpointRequestBody> = (
	endpoint,
) => async (dispatch, getState) => {
	dispatch(ingestionCommonEmptyRequest());

	const { ingestionEndpoints } = getState();
	const request = endpointsService.createDotNetIngestionEndpoint(endpoint);
	const { data, status, error } = await fetchRequest<DotNetIngestionEndpoint>(request);

	if (isStatusSuccess(status) && data) {
		dispatch(createDotNetIngestionEndpointSuccess());
		dispatch(
			fetchDotNetIngestionEndpoints({
				limit: ingestionEndpoints.endpoints.limit,
				offset: ingestionEndpoints.endpoints.offset,
				orderings: ingestionEndpoints.endpoints.orderings,
				filtersAndConditions: ingestionEndpoints.endpoints.filters,
			}),
		);
	} else {
		dispatch(ingestionCommonEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(createDotNetIngestionEndpoint(endpoint));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const editDotNetIngestionEndpoint: AsyncAction<EditDotNetIngestionEndpointPayload> = (endpoint) => async (
	dispatch,
) => {
	dispatch(ingestionCommonEmptyRequest());

	const body = omit(endpoint, ['id']);
	const request = endpointsService.editDotNetIngestionEndpoint(body, endpoint.id);
	const { data, error } = await fetchRequest<DotNetIngestionEndpoint>(request);

	if (data) {
		dispatch(editDotNetIngestionEndpointSuccess(data));
	} else {
		dispatch(ingestionCommonEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(editDotNetIngestionEndpoint(endpoint));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const deleteDotNetIngestionEndpoint: AsyncAction<UUID> = (endpointId) => async (dispatch) => {
	dispatch(ingestionCommonEmptyRequest());

	const request = endpointsService.deleteDotNetIngestionEndpoint(endpointId);
	const { status, error } = await fetchRequest<void>(request);

	if (isStatusSuccess(status) && endpointId) {
		dispatch(deleteDotNetIngestionEndpointSuccess(endpointId));
	} else {
		dispatch(ingestionCommonEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(deleteDotNetIngestionEndpoint(endpointId));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

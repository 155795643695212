export type TimeSpan = {
	days: number;
	hours: number;
	minutes: number;
	seconds?: number;
};

export type StringTimeSpan = {
	days: string;
	hours: string;
	minutes: string;
	seconds?: string;
};

export function stringTimeSpanToTimeSpanString(stringTimeSpan: StringTimeSpan | undefined): string | undefined {
	const timeSpan = stringTimeSpanToTimeSpan(stringTimeSpan);

	return timeSpanToTimeSpanString(timeSpan);
}

export function timeSpanToTimeSpanString(timeSpan: TimeSpan | undefined): string | undefined {
	if (timeSpan === undefined) {
		return undefined;
	}

	const seconds = timeSpan.seconds ?? 0;

	const hoursString = timeSpan.hours < 10 ? `0${timeSpan.hours}` : `${timeSpan.hours}`;
	const minutesString = timeSpan.minutes < 10 ? `0${timeSpan.minutes}` : `${timeSpan.minutes}`;
	const secondsString = seconds < 10 ? `0${seconds}` : `${seconds}`;

	return `${timeSpan.days}.${hoursString}:${minutesString}:${secondsString}`;
}

export function timeStringToTimeSpan(time: string | undefined): TimeSpan | undefined {
	if (time === undefined || time === null) {
		return undefined;
	}

	const split = time.split('.');

	let days = 0;

	let restIndex = 0;

	if (split.length > 1) {
		days = parseInt(split[0]);

		if (isNaN(days)) {
			return undefined;
		}

		restIndex = 1;
	}

	const rest = split[restIndex].split(':');

	let seconds: number;
	let minutes: number;
	let hours: number;

	if (restIndex === 0) {
		hours = rest.length > 2 ? parseInt(rest[rest.length - 3]) : 0;
		minutes = rest.length > 1 ? parseInt(rest[rest.length - 2]) : 0;
		seconds = parseInt(rest[rest.length - 1]);
	} else {
		hours = parseInt(rest[0]);
		minutes = rest.length > 1 ? parseInt(rest[1]) : 0;
		seconds = rest.length > 2 ? parseInt(rest[2]) : 0;
	}

	if (isNaN(seconds) || isNaN(minutes) || isNaN(hours)) {
		return undefined;
	}

	return {
		days: days,
		hours: hours,
		minutes: minutes,
		seconds: seconds,
	};
}

export function timeStringToStringTimeSpanString(time: string | undefined): StringTimeSpan | undefined {
	const timeSpan = timeStringToTimeSpan(time);

	if (timeSpan === undefined) {
		return undefined;
	}

	return {
		days: timeSpan.days.toString(),
		hours: timeSpan.hours.toString(),
		minutes: timeSpan.minutes.toString(),
		seconds: timeSpan.seconds?.toString(),
	};
}

export function timeStringToStringTimeSpan(time: string | undefined): string | undefined {
	const timeSpan = timeStringToTimeSpan(time);

	if (timeSpan === undefined) {
		return undefined;
	}

	return getTimeSpanStringFromTimeSpan(timeSpan);
}

export function stringTimeSpanToMillis(timeSpan: StringTimeSpan) {
	const days = parseInt(timeSpan.days);

	if (isNaN(days)) {
		return -1;
	}

	const hours = parseInt(timeSpan.hours);

	if (isNaN(hours)) {
		return -1;
	}

	const minutes = parseInt(timeSpan.minutes);

	if (isNaN(minutes)) {
		return -1;
	}

	let seconds = 0;

	if (timeSpan.seconds) {
		seconds = parseInt(timeSpan.seconds);

		if (isNaN(seconds)) {
			return -1;
		}
	}

	return seconds * 1000 + minutes * 60 * 1000 + hours * 60 * 60 * 1000 + days * 24 * 60 * 60 * 1000;
}

export function getTimeSpanStringFromDate(date: Date, now: number): string | undefined {
	const diffInMs = Math.abs(date.getTime() - now);

	return getTimeSpanStringFromMillis(diffInMs);
}

export function getTimeSpanStringFromMillis(milis: number | undefined): string | undefined {
	if (milis === undefined) {
		return undefined;
	}

	const timeSpan = getTimespanFromMillis(milis);

	return getTimeSpanStringFromTimeSpan(timeSpan);
}

export function getTimeSpanStringFromTimeSpan(timeSpan: TimeSpan | undefined): string | undefined {
	if (timeSpan === undefined) {
		return undefined;
	}

	const timeStampSeconds = timeSpan.seconds ?? 0;

	const d = timeSpan.days > 0;
	const h = timeSpan.hours > 0;
	const m = timeSpan.minutes > 0;
	const s = timeStampSeconds > 0;

	const days = d ? `${timeSpan.days}d ` : '';
	const hours = h || (d && (m || s)) ? `${timeSpan.hours}h ` : '';
	const minutes = m || ((d || h) && s) ? `${timeSpan.minutes}m` : '';
	const seconds = s ? ` ${timeSpan.seconds}s` : '';

	return `${days}${hours}${minutes}${seconds}`;
}

export function getStringTimespanFromMillis(milis: number | undefined): StringTimeSpan | undefined {
	if (milis === undefined) {
		return undefined;
	}

	const timeSpan = getTimespanFromMillis(milis);

	return {
		days: timeSpan.days.toString(),
		hours: timeSpan.hours.toString(),
		minutes: timeSpan.minutes.toString(),
		seconds: timeSpan.seconds?.toString(),
	};
}

export function getTimespanFromMillis(milis: number): TimeSpan {
	const seconds = Math.floor(milis / 1000);

	const minutes = Math.floor(seconds / 60);

	const hours = Math.floor(minutes / 60);

	const days = Math.floor(hours / 24);

	const s = seconds - minutes * 60;

	const m = minutes - hours * 60;

	const h = hours - days * 24;

	return {
		days: days,
		hours: h,
		minutes: m,
		seconds: s,
	};
}

export function stringTimeSpanToTimeSpan(stringTimeSpan: StringTimeSpan | undefined): TimeSpan | undefined {
	if (stringTimeSpan === undefined) {
		return undefined;
	}

	const days = parseInt(stringTimeSpan.days);

	if (isNaN(days)) {
		return undefined;
	}

	const hours = parseInt(stringTimeSpan.hours);

	if (isNaN(hours)) {
		return undefined;
	}

	const minutes = parseInt(stringTimeSpan.minutes);

	if (isNaN(minutes)) {
		return undefined;
	}

	const seconds = stringTimeSpan.seconds ? parseInt(stringTimeSpan.seconds) : 0;

	if (isNaN(seconds)) {
		return undefined;
	}

	return {
		days: days,
		hours: hours,
		minutes: minutes,
		seconds: seconds,
	};
}

import { action } from 'typesafe-actions';
import {
	FETCH_USERS_SUCCESS,
	EDIT_USER_SUCCESS,
	CREATE_USER_SUCCESS,
	DELETE_USER_SUCCESS,
	GET_USER_DETAIL,
	EDIT_USER_PROJECTS_SUCCESS,
	FETCH_USER_SUCCESS,
	CHANGE_PASSWORD_SUCCESS,
	USER_MANAGEMENT_COMMON_EMPTY_REQUEST,
	USER_MANAGEMENT_COMMON_EMPTY_ERROR,
	FETCH_USER_ROLES_SUCCESS,
	EDIT_USER_ROLE_SUCCESS,
	CREATE_USER_ROLE_SUCCESS,
	DELETE_USER_ROLE_SUCCESS,
} from './user-management-constants';

import { UserProject, UserResponseData, UsersResponseData } from '../../pages/UserManagement/user-management-types';

import { UUID } from '../../types';
import { UserRolesResponseData } from '../../pages/UserRoles/user-roles-types';
import { FiltersRequestBody2 } from '../../components/Table/types';

export const commonUserManagementEmptyRequest = () => action(USER_MANAGEMENT_COMMON_EMPTY_REQUEST);
export const commonUserManagementEmptyError = () => action(USER_MANAGEMENT_COMMON_EMPTY_ERROR);

export const fetchUsersSuccess = (data: UsersResponseData, options?: FiltersRequestBody2) =>
	action(FETCH_USERS_SUCCESS, {
		data,
		options,
	});

export const editUserSuccess = (data: UserResponseData) =>
	action(EDIT_USER_SUCCESS, {
		data,
	});

export const createUserSuccess = () => action(CREATE_USER_SUCCESS);
export const deleteUserSuccess = (id: UUID) =>
	action(DELETE_USER_SUCCESS, {
		id,
	});

export const getUserDetail = (user: UserResponseData) =>
	action(GET_USER_DETAIL, {
		user,
	});

export const fetchUserSuccess = (user: UserResponseData) =>
	action(FETCH_USER_SUCCESS, {
		user,
	});

export const editUserProjectsSuccess = (data: UserProject[]) =>
	action(EDIT_USER_PROJECTS_SUCCESS, {
		data,
	});

export const changePasswordSuccess = () => action(CHANGE_PASSWORD_SUCCESS);

export const fetchUserRolesSuccess = (data: UserRolesResponseData, options?: FiltersRequestBody2) =>
	action(FETCH_USER_ROLES_SUCCESS, {
		data,
		options,
	});

export const createUserRoleSuccess = () => action(CREATE_USER_ROLE_SUCCESS);

export const editUserRoleSuccess = () => action(EDIT_USER_ROLE_SUCCESS);

export const deleteUserRoleSuccess = () => action(DELETE_USER_ROLE_SUCCESS);

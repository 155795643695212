import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { PlacementDefinitionsTable } from './components/PlacementDefinitionsTable';

export const Definitions = () => {
	return (
		<Grid container spacing={2} justify="center">
			<Grid item xs={12} lg={10}>
				<Paper>
					<PlacementDefinitionsTable />
				</Paper>
			</Grid>
		</Grid>
	);
};

import React from 'react';
import { makeStyles, Tooltip, IconButton, Popover, Theme, Typography, Badge } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Children } from '../../types';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button/Button';
import { ModalDialogFormActions } from '../ModalDialog/ModalDialogFormActions';
import { BREAKPOINT_KEYS } from '../../helpers/generalConstants';

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
	title: {
		marginBottom: spacing(3),
	},
	filter: {
		width: `calc(100vw - ${spacing(2)}px)`,
		padding: spacing(2),
		columns: 'auto',
		columnCount: 'auto',
		columnFill: 'auto',
		[breakpoints.up(BREAKPOINT_KEYS.sm)]: {
			width: '60vw',
		},
		[breakpoints.up(BREAKPOINT_KEYS.md)]: {
			width: spacing(60),
		},
	},
	button: {
		marginTop: spacing(2),
	},
}));

type Props = {
	children: Children;
	onSave?: () => void;
	onReset?: () => void;
	active: boolean;
	name?: string;
};

export const TableFilter = ({ children, onSave, onReset, active, name }: Props) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
	const { t } = useTranslation();
	const classes = useStyles();
	const open = Boolean(anchorEl);

	const handleSave = () => {
		if (typeof onSave === 'function') {
			onSave();
		}

		setAnchorEl(null);
	};

	const handleReset = () => {
		if (typeof onReset === 'function') {
			onReset();
		}

		setAnchorEl(null);
	};

	return (
		<>
			<Tooltip title={t('filters') as string} data-cy={`btn-filter:${name}TableFilter`}>
				<IconButton onClick={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}>
					<Badge invisible={!active} color="primary" variant="dot">
						<FilterListIcon />
					</Badge>
				</IconButton>
			</Tooltip>

			<Popover
				id="table-filters"
				open={open}
				anchorEl={anchorEl}
				classes={{ paper: classes.filter }}
				onClose={(event: React.MouseEvent<HTMLButtonElement>) =>
					setAnchorEl(anchorEl ? null : event.currentTarget)
				}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Typography className={classes.title} component="h3" variant="button">
					{t('filters')}
				</Typography>

				{children}

				<ModalDialogFormActions className={classes.button}>
					{onSave && (
						<Button onClick={handleSave} type="submit" data-cy="btn-submit:tableFilter">
							{t('save')}
						</Button>
					)}
					{onReset && (
						<Button onClick={handleReset} type="button" data-cy="btn-reset:tableFilter">
							{t('reset')}
						</Button>
					)}
				</ModalDialogFormActions>
			</Popover>
		</>
	);
};

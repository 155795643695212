import { createReducer } from 'typesafe-actions';
import {
	TENANTS_COMMON_EMPTY_REQUEST,
	TENANTS_COMMON_EMPTY_ERROR,
	FETCH_TENANTS_SUCCESS,
	CREATE_TENANT_SUCCESS,
	EDIT_TENANT_SUCCESS,
	DELETE_TENANT_SUCCESS,
} from './tenants-constants';

import { TenantsActions, TenantsState } from './tenants-types';
import { updateObjectInArray } from '../../helpers/array';

export const tenantsInitialState: TenantsState = {
	data: [],
	total: 0,
	limit: 10,
	offset: 0,
	orderings: [],
	filters: [],
};

export const tenantsReducer = createReducer<TenantsState, TenantsActions>(tenantsInitialState, {
	[TENANTS_COMMON_EMPTY_REQUEST]: (state) => state,
	[TENANTS_COMMON_EMPTY_ERROR]: (state) => state,
	[FETCH_TENANTS_SUCCESS]: (state, action) => ({
		...state,
		data: action.payload.data ? action.payload.data.data : state.data,
		total: action.payload.data ? action.payload.data.total : state.total,
		limit: action.payload.options ? action.payload.options.limit : state.limit,
		offset: action.payload.options ? action.payload.options.offset : state.offset,
		orderings: action.payload.options?.orderings,
		filters: action.payload.options?.filtersAndConditions,
	}),
	[CREATE_TENANT_SUCCESS]: (state, action) => {
		const { data } = action.payload;
		const tenantIndex = state.data.findIndex((tenant) => tenant.id === data.id);

		return {
			...state,
			data: updateObjectInArray(data, tenantIndex, state.data),
		};
	},
	[EDIT_TENANT_SUCCESS]: (state, action) => {
		const { data } = action.payload;
		const tenantIndex = state.data.findIndex((tenant) => tenant.id === data.id);

		return {
			...state,
			data: updateObjectInArray(data, tenantIndex, state.data),
		};
	},
	[DELETE_TENANT_SUCCESS]: (state, action) => {
		return {
			...state,
			data: state.data.filter((tenant) => tenant.id !== action.payload.id),
		};
	},
});

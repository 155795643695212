import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { useDispatch } from 'react-redux';
import { Grid, List, ListItem, ListItemText } from '@material-ui/core';
import { LogData, LogsResponseData } from '../../services/user/user-types';
import { fetchLogs } from '../../services/user/user-service';
import { fetchRequest } from '../../services/helpers';
import { INPUT_DATE_TIME_MASK } from '../../helpers/generalConstants';
import { format } from 'date-fns';
import { SortOrder } from '../../components/Table/constants';

export const Logs = () => {
	const dispatch = useDispatch();

	const [logs, setLogs] = useState<LogData[]>();

	useEffect(() => {
		const request = fetchLogs({
			limit: 100,
			offset: 0,
			filtersAndConditions: [],
			orderings: [{ column: 'dt', sortOrder: SortOrder.Descending }],
		});

		(async () => {
			const { data, error } = await fetchRequest<LogsResponseData>(request);

			if (error) {
				// TODO show error
			} else {
				if (data) {
					setLogs(data.data);
				}
			}
		})();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	return (
		<Grid container spacing={2} justify="center">
			<Grid item xs={12} lg={10}>
				<Paper>
					<List id="relations">
						{logs?.map((log) => (
							<ListItem key={log.id} button>
								<ListItemText
									primary={`${format(new Date(log.dt), INPUT_DATE_TIME_MASK)}, level =  ${
										log.level
									}, from = ${log.from}`}
									secondary={log.message}
									primaryTypographyProps={{
										variant: 'subtitle2',
										style: {
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
										},
									}}
									secondaryTypographyProps={{
										variant: 'caption',
										style: {
											whiteSpace: 'normal',
											overflow: 'visible',
											textOverflow: 'clip',
											overflowWrap: 'anywhere',
										},
									}}
								/>
							</ListItem>
							// <>
							// 	<Typography variant="h6">
							// 		{log.level}
							// 	</Typography>
							// 	<Typography variant="h6">
							// 		{log.dt}
							// 	</Typography>
							// 	<Typography variant="inherit">
							// 		{log.from}
							// 	</Typography>
							// 	<Typography variant="inherit">
							// 		{log.message}
							// 	</Typography>
							// </>
						))}
					</List>
					{/* <tbody>
						{logs?.map((log) => (
							<tr key={log.id}>
								<td>{format(new Date(log.dt), INPUT_DATE_TIME_MASK)}</td>
								<td>{log.message}</td>
							</tr>
						))}
					</tbody> */}
				</Paper>
			</Grid>
		</Grid>
	);
};

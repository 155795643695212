import { WarehouseState, WarehouseActions } from './warehouse-types';
import { createReducer } from 'typesafe-actions';
import {
	CREATE_DEVICE_SUCCESS,
	FETCH_DEVICES_SUCCESS,
	GENERAL_WAREHOUSE_ERROR,
	GENERAL_WAREHOUSE_REQUEST,
	EDIT_DEVICE_SUCCESS,
	EDIT_DEVICE_PROPERTIES_SUCCESS,
} from './warehouse-constants';

const warehouseInitialState: WarehouseState = {
	offset: 0,
	limit: 10,
	orderings: [],
	filtersAndConditions: [],
	data: [],
	total: 0,
};

export const warehouseReducer = createReducer<WarehouseState, WarehouseActions>(warehouseInitialState, {
	[GENERAL_WAREHOUSE_REQUEST]: (state) => state,
	[GENERAL_WAREHOUSE_ERROR]: (state) => state,
	[CREATE_DEVICE_SUCCESS]: (state) => state,
	[EDIT_DEVICE_SUCCESS]: (state) => state,
	[FETCH_DEVICES_SUCCESS]: (state: WarehouseState, action: any) => ({
		...state,
		data: action.payload.data.data,
		total: action.payload.data.total,
		limit: action.payload.options ? action.payload.options.limit : state.limit,
		offset: action.payload.options ? action.payload.options.offset : state.offset,
		orderings: action.payload.options?.orderings || state.orderings,
		filtersAndConditions: action.payload.options?.filtersAndConditions || state.filtersAndConditions,
	}),
	[EDIT_DEVICE_PROPERTIES_SUCCESS]: (state) => state,
});

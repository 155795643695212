import { PaginatedResponse } from '../../store/types';
import { UUID } from '../../types';
import { Group } from '../ContactGroups/contact-groups-types';
import { Contact } from '../Contacts/contacts-types';

export const severityTypes = [
	{ id: '0', label: 'Trace' },
	{ id: '1', label: 'Notice' },
	{ id: '2', label: 'Info' },
	{ id: '3', label: 'Warn' },
	{ id: '4', label: 'Error' },
	{ id: '5', label: 'Critical' },
];

export const activityTypes = [
	{ id: '1', label: 'Email' },
	{ id: '2', label: 'SMS' },
	{ id: '3', label: 'EmailAndSMS' },
];

export type Pipeline = {
	id: UUID;
	name: string;
	tenantId?: UUID;
	rules?: PipelineRule[];
};

export type PipelineRule = {
	id: UUID;
	pipelineId: UUID;
	severity: string;
	activity: string;
	confirmTo?: string;
	escalationPipelineId?: UUID;
	escalationPipelineName?: string;
	contactGroups?: Group[];
};

export type GroupResponseData = {
	dateCreated: string;
	dateUpdated: string;
	id: UUID;
	name: string;
	tenantId: UUID;
	contacts: Contact[];
};

export type CreatePipelineRequestBody = Omit<Pipeline, 'id'>;
export type PipelinesResponseData = PaginatedResponse<Pipeline>;

export type CreatePipelineRuleRequestBody = Omit<PipelineRule, 'id' | 'contactGroups'>;
export type PipelineRulesResponseData = PaginatedResponse<PipelineRule>;

export type UpdatePipelineRuleRequestBody = CreatePipelineRuleRequestBody & {
	id: UUID;
};

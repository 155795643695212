import { apiClient } from '../client';
import {
	GroupsResponseData,
	GroupResponseData,
	CreateGroupRequestBody,
	UpdateGroupRequestBody,
	UpdateGroupResponseData,
} from '../../pages/ContactGroups/contact-groups-types';
import { UUID } from '../../types';
import { FiltersRequestBody2 } from '../../components/Table/types';

const baseUrlContactGroups = '/dispatcher.net/contact-groups';

export const getFilteredContactGroups = (body: FiltersRequestBody2) =>
	apiClient.post<GroupsResponseData>(`${baseUrlContactGroups}/filter`, body);

export const createContactGroup = (body: CreateGroupRequestBody) =>
	apiClient.post<GroupResponseData>(`${baseUrlContactGroups}`, body);

export const updateContactGroup = (body: UpdateGroupRequestBody, groupId: UUID) =>
	apiClient.put<UpdateGroupResponseData>(`${baseUrlContactGroups}/${groupId}`, body);

export const deleteContactGroup = (groupId: UUID) => apiClient.delete<void>(`${baseUrlContactGroups}/${groupId}`);

import { apiClient } from '../client';
import {
	DevicePlacementsResponseData,
	CreateDevicePlacementRequestBody,
	UpdateDevicePlacementRequestBody,
	AttachDevicePlacementRequestBody,
	DetachDevicePlacementRequestBody,
	UpdateDevicePlacementRelationResponseData,
	DefinitionResponseData,
	DevicePlacement,
	Definition,
	CreatePlacementDefinitionRequestBody,
	PlacementConnectionsResponseData,
} from '../../pages/DevicePlacementsManagement/device-placements-types';
import { UUID } from '../../types';
import { FiltersRequestBody2 } from '../../components/Table/types';
import { TelemetryResponseData } from '../../pages/Devices/telemetry-types';

const baseUrl = '/placement-manager.net';
const definitionsBaseUrl = `${baseUrl}/definition`;
const placementBaseUrl = `${baseUrl}/placement`;
const placementRelationsUrl = `${baseUrl}/placementRelation`;
const placementConnectionsUrl = `${baseUrl}/placementConnection`;

//* Definitions
export const fetchDefinitions = (body: FiltersRequestBody2) =>
	apiClient.post<DefinitionResponseData>(`${definitionsBaseUrl}/filter`, body);

export const createPlacementDefinition = (body: CreatePlacementDefinitionRequestBody) => {
	return apiClient.post<Definition>(definitionsBaseUrl, body);
};

export const updatePlacementDefinition = (body: CreatePlacementDefinitionRequestBody, definitionId: UUID) => {
	return apiClient.put<Definition>(`${definitionsBaseUrl}/${definitionId}`, body);
};

//* Device Placements
export const fetchPlacements = (body: FiltersRequestBody2) =>
	apiClient.post<DevicePlacementsResponseData>(`${placementBaseUrl}/filter`, body);

export const fetchPlacementTelemetry = (body: FiltersRequestBody2, placementId: UUID) =>
	apiClient.post<TelemetryResponseData>(`${placementBaseUrl}/telemetry/${placementId}`, body);

export const fetchPlacementsWithTelemetry = (body: FiltersRequestBody2) =>
	apiClient.post<DevicePlacementsResponseData>(`${placementBaseUrl}/telemetry/filter`, body);

//* Connections
export const fetchPlacementConnections = (body: FiltersRequestBody2) =>
	apiClient.post<PlacementConnectionsResponseData>(`${placementConnectionsUrl}/filter`, body);

// TODO rebase logic to more suitable place
export const createPlacement = (body: CreateDevicePlacementRequestBody) => {
	return apiClient.post<DevicePlacement>(
		placementBaseUrl,
		body.deviceId
			? {
					definitionId: body.definitionId,
					deviceId: body.deviceId,
					fields: body.fields,
			  }
			: {
					definitionId: body.definitionId,
					fields: body.fields,
			  },
	);
};

export const updatePlacement = (body: UpdateDevicePlacementRequestBody, placementId: UUID) => {
	return apiClient.put<DevicePlacement>(`${placementBaseUrl}/${placementId}`, body);
};

export const deletePlacement = (dispositionId: UUID) => apiClient.delete(`${placementBaseUrl}/${dispositionId}`);

//* Placement Relations
export const attachDevice = (body: AttachDevicePlacementRequestBody) =>
	apiClient.post<UpdateDevicePlacementRelationResponseData>(placementRelationsUrl, body);

export const detachDevice = (body: DetachDevicePlacementRequestBody, relationId: UUID) =>
	apiClient.put<UpdateDevicePlacementRelationResponseData>(`${placementRelationsUrl}/${relationId}`, body);

import { action } from 'typesafe-actions';
import {
	FETCH_TELEMETRY_REQUEST,
	FETCH_TELEMETRY_SUCCESS,
	FETCH_TELEMETRY_ERROR,
	UPDATE_CURRENT_TELEMETRY,
	FETCH_CURRENT_TELEMETRY_SUCCESS,
	FETCH_GROUPED_TELEMETRY_SUCCESS,
} from './telemetry-constants';
import { TelemetrySocketData, TelemetryResponseData } from '../../pages/Devices/telemetry-types';
import { Nullable } from '../../types';
import { FiltersRequestBody2 } from '../../components/Table/types';
import { GroupedTelemetryOptions } from './telemetry-types';

export const fetchDevicesRequest = () => action(FETCH_TELEMETRY_REQUEST);
export const fetchDevicesSuccess = (data: TelemetryResponseData, options: Nullable<FiltersRequestBody2>) =>
	action(FETCH_TELEMETRY_SUCCESS, {
		data,
		options,
	});

export const fetchCurrentTelemetrySuccess = (data: TelemetryResponseData) =>
	action(FETCH_CURRENT_TELEMETRY_SUCCESS, {
		data,
	});

export const fetchDevicesError = () => action(FETCH_TELEMETRY_ERROR);

export const updateCurrentTelemetry = (data: TelemetrySocketData) => action(UPDATE_CURRENT_TELEMETRY, { data });

export const fetchGroupedTelemetrySuccess = (data: TelemetryResponseData, options: GroupedTelemetryOptions) =>
	action(FETCH_GROUPED_TELEMETRY_SUCCESS, {
		data,
		options,
	});

import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { Typography, Grid } from '@material-ui/core';
import { GroupsState } from '../../store/groups/groups-types';
import { BackButton } from '../../components/Button/BackButton';
import { GroupsTransferList } from './components/GroupsTransferList';

type RouteParams = {
	groupId: string;
};

export const GroupDetail = () => {
	// const dispatch = useDispatch();
	// const { groupId } = useParams<RouteParams>();
	const { groupDetail } = useSelector<RootState, GroupsState>((state) => state.groups);

	return (
		groupDetail && (
			<>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<BackButton />
					</Grid>
					<Grid item xs={12}>
						<Typography component="h2" variant="h5">
							{groupDetail.name}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<GroupsTransferList currentGroup={groupDetail} />
					</Grid>
				</Grid>
			</>
		)
	);
};

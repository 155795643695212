import * as userManagementService from '../../services/user-management/user-management-service';
import { addToast } from '../app/app-actions';
import { fetchRequest, isStatusSuccess, createToastError } from '../../services/helpers';
import { AsyncAction } from '../types';
import {
	UsersResponseData,
	CreateUserPayload,
	UserResponseData,
	EditUserPayload,
	ChangePasswordPayload,
} from '../../pages/UserManagement/user-management-types';

import {
	fetchUsersSuccess,
	editUserSuccess,
	createUserSuccess,
	deleteUserSuccess,
	fetchUserSuccess,
	changePasswordSuccess,
	commonUserManagementEmptyRequest,
	commonUserManagementEmptyError,
	fetchUserRolesSuccess,
	editUserRoleSuccess,
	createUserRoleSuccess,
	deleteUserRoleSuccess,
} from './user-management-actions';

import { UUID } from '../../types';
import { omit } from 'lodash';
import { FilterOperators } from '../../components/Table/constants';
import { FiltersRequestBody2 } from '../../components/Table/types';
import {
	CreateUserRoleRequestBody,
	EditUserRoleRequestBody,
	UserRole,
	UserRolesResponseData,
} from '../../pages/UserRoles/user-roles-types';

export const fetchUsers: AsyncAction<FiltersRequestBody2> = (usersPayload) => async (dispatch) => {
	dispatch(commonUserManagementEmptyRequest());

	const request = userManagementService.getFilteredUsers(usersPayload);
	const { data, error } = await fetchRequest<UsersResponseData>(request);

	if (data) {
		dispatch(fetchUsersSuccess(data, usersPayload));
	} else {
		dispatch(commonUserManagementEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(fetchUsers(usersPayload));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const editUser: AsyncAction<EditUserPayload> = (user) => async (dispatch) => {
	dispatch(commonUserManagementEmptyRequest());

	const body = omit(user, ['id']);
	const request = userManagementService.editUser(body, user.id);
	const { data, error } = await fetchRequest<UserResponseData>(request);

	if (data) {
		dispatch(editUserSuccess(data));
	}

	if (error) {
		const tryAgainAction = () => dispatch(editUser(user));

		dispatch(commonUserManagementEmptyError());
		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const createUser: AsyncAction<CreateUserPayload> = (user) => async (dispatch, getState) => {
	dispatch(commonUserManagementEmptyRequest());

	const { users } = getState();
	const request = userManagementService.createUser(user);
	const { data, status, error } = await fetchRequest<UserResponseData>(request);

	if (isStatusSuccess(status) && data) {
		dispatch(createUserSuccess());
		dispatch(
			fetchUsers({
				limit: users.limit,
				offset: users.offset,
				orderings: users.orderings,
				filtersAndConditions: users.filters,
			}),
		);
	}

	if (error) {
		const tryAgainAction = () => dispatch(createUser(user));

		dispatch(commonUserManagementEmptyError());
		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const deleteUser: AsyncAction<UUID> = (userId) => async (dispatch) => {
	dispatch(commonUserManagementEmptyRequest());

	const request = userManagementService.deleteUser(userId);
	const { status, error } = await fetchRequest<void>(request);

	if (isStatusSuccess(status) && userId) {
		dispatch(deleteUserSuccess(userId));
	}

	if (error) {
		const tryAgainAction = () => dispatch(deleteUser(userId));

		dispatch(commonUserManagementEmptyError());
		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const fetchUser: AsyncAction<UUID> = (userId) => async (dispatch) => {
	dispatch(commonUserManagementEmptyRequest());

	const request = userManagementService.getFilteredUsers({
		userId: [
			{
				value: userId,
				operator: FilterOperators.equals,
			},
		],
	});

	const { data, error } = await fetchRequest<UsersResponseData>(request);

	if (data) {
		dispatch(fetchUserSuccess(data.data[0]));
	}

	if (error) {
		const tryAgainAction = () => dispatch(fetchUser(userId));

		dispatch(commonUserManagementEmptyError());
		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const changePassword: AsyncAction<ChangePasswordPayload> = (changePasswordPayload) => async (dispatch) => {
	dispatch(commonUserManagementEmptyRequest());

	const { userId, password } = changePasswordPayload;
	const request = userManagementService.changePassword({ password }, userId);
	const { status, error } = await fetchRequest<void>(request);

	if (isStatusSuccess(status)) {
		dispatch(changePasswordSuccess());
	}

	if (error) {
		dispatch(commonUserManagementEmptyError());
		dispatch(addToast(createToastError(error.data)));
	}
};

export const fetchUserRoles: AsyncAction<FiltersRequestBody2> = (userRolesPayload) => async (dispatch) => {
	dispatch(commonUserManagementEmptyRequest());

	const request = userManagementService.getFilteredUserRoles(userRolesPayload);
	const { data, error } = await fetchRequest<UserRolesResponseData>(request);

	if (data) {
		dispatch(fetchUserRolesSuccess(data, userRolesPayload));
	} else {
		dispatch(commonUserManagementEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(fetchUserRoles(userRolesPayload));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const createUserRole: AsyncAction<CreateUserRoleRequestBody> = (userRole) => async (dispatch, getState) => {
	dispatch(commonUserManagementEmptyRequest());

	const { users } = getState();
	const request = userManagementService.createUserRole(userRole);
	const { data, status, error } = await fetchRequest<UserRole>(request);

	if (isStatusSuccess(status) && data) {
		dispatch(createUserRoleSuccess());
		dispatch(
			fetchUserRoles({
				limit: users.userRoles.limit,
				offset: users.userRoles.offset,
				orderings: users.userRoles.orderings,
				filtersAndConditions: users.userRoles.filters,
			}),
		);
	} else {
		dispatch(commonUserManagementEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(createUserRole(userRole));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const editUserRole: AsyncAction<EditUserRoleRequestBody> = (userRole) => async (dispatch, getState) => {
	dispatch(commonUserManagementEmptyRequest());

	const { users } = getState();
	const body = omit(userRole, ['id']);
	const request = userManagementService.editUserRole(body, userRole.id);
	const { data, error } = await fetchRequest<UserRole>(request);

	if (data) {
		dispatch(editUserRoleSuccess());
		dispatch(
			fetchUserRoles({
				limit: users.userRoles.limit,
				offset: users.userRoles.offset,
				orderings: users.userRoles.orderings,
				filtersAndConditions: users.userRoles.filters,
			}),
		);
	} else {
		dispatch(commonUserManagementEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(editUserRole(userRole));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const deleteUserRole: AsyncAction<UUID> = (userRoleId) => async (dispatch, getState) => {
	dispatch(commonUserManagementEmptyRequest());

	const { users } = getState();
	const request = userManagementService.deleteUserRole(userRoleId);
	const { status, error } = await fetchRequest<void>(request);

	if (isStatusSuccess(status) && userRoleId) {
		dispatch(deleteUserRoleSuccess());
		dispatch(
			fetchUserRoles({
				limit: users.userRoles.limit,
				offset: users.userRoles.offset,
				orderings: users.userRoles.orderings,
				filtersAndConditions: users.userRoles.filters,
			}),
		);
	} else {
		dispatch(commonUserManagementEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(deleteUser(userRoleId));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

import { GroupsState, GroupsActions } from './groups-types';
import { createReducer } from 'typesafe-actions';
import {
	FETCH_GROUPS_REQUEST,
	FETCH_GROUPS_SUCCESS,
	FETCH_GROUPS_ERROR,
	CREATE_GROUP_REQUEST,
	CREATE_GROUP_ERROR,
	CREATE_GROUP_SUCCESS,
	DELETE_GROUP_ERROR,
	DELETE_GROUP_REQUEST,
	DELETE_GROUP_SUCCESS,
	UPDATE_GROUP_REQUEST,
	UPDATE_GROUP_SUCCESS,
	UPDATE_GROUP_ERROR,
	GET_GROUP_DETAIL,
} from './groups-constants';

import { set } from 'lodash/fp';

const groupsInitialState: GroupsState = {
	data: [],
	total: 0,
	offset: 0,
	limit: 10,
	orderings: [],
	filtersAndConditions: [],
	groupDetail: null,
};

export const groupsReducer = createReducer<GroupsState, GroupsActions>(groupsInitialState, {
	[FETCH_GROUPS_REQUEST]: (state) => state,
	[FETCH_GROUPS_SUCCESS]: (state, action) => {
		return {
			...state,
			data: action.payload.data.data,
			total: action.payload.data.total,
			limit: action.payload.options ? action.payload.options.limit : state.limit,
			offset: action.payload.options ? action.payload.options.offset : state.offset,
			orderings: action.payload.options?.orderings || state.orderings,
			filtersAndConditions: action.payload.options?.filtersAndConditions || state.filtersAndConditions,
		};
	},
	[FETCH_GROUPS_ERROR]: (state) => state,
	[CREATE_GROUP_REQUEST]: (state) => state,
	[CREATE_GROUP_SUCCESS]: (state) => state,
	[CREATE_GROUP_ERROR]: (state) => state,
	[UPDATE_GROUP_REQUEST]: (state) => state,
	[UPDATE_GROUP_SUCCESS]: (state, action) => {
		const { data } = action.payload;
		const groupIndex = state.data.findIndex((group) => group.id === data.id);

		return set(`data[${groupIndex}]`, data, state);
	},
	[UPDATE_GROUP_ERROR]: (state) => state,
	[DELETE_GROUP_REQUEST]: (state) => state,
	[DELETE_GROUP_SUCCESS]: (state, action) => {
		const newData = state.data.filter((group) => group.id !== action.payload.groupId);

		return {
			...state,
			data: newData,
			total: newData.length,
		};
	},
	[DELETE_GROUP_ERROR]: (state) => state,
	[GET_GROUP_DETAIL]: (state, action) => set('groupDetail', action.payload.data, state),
});

import { Middleware, MiddlewareAPI, Dispatch } from 'redux';
import { RootActions } from '../types';
import { setLoading } from '../app/app-actions';

export const fetchingMiddleware: Middleware = (store: MiddlewareAPI) => (next: Dispatch<RootActions>) => (
	action: RootActions,
) => {
	const state = store.getState();
	const { loading } = state.app;
	const isRequest = action.type.includes('_REQUEST');
	const isSuccess = action.type.includes('_SUCCESS');
	const isError = action.type.includes('_ERROR');

	if (isRequest) {
		next(setLoading(loading + 1));
	}

	if (isSuccess || isError) {
		// prevent negative loading
		next(setLoading(loading === 0 ? 0 : loading - 1));
	}

	next(action);
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectResponseData } from '../../Projects/projects-types';
import { UserRole } from '../../UserRoles/user-roles-types';
import { UserRelation } from '../../../store/user/user-types';
import { useDispatch } from 'react-redux';
import { UUID } from '../../../types';
import {
	createUserRelation,
	deleteUserRelation,
	addUserProjectRelations,
} from '../../../store/user/user-async-actions';
import { Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Tooltip } from '@material-ui/core';
import { Button } from '../../../components/Button/Button';
import { Add, DeleteRounded } from '@material-ui/icons';
import { addModalDialog, removeModalDialog } from '../../../store/app/app-actions';
import { AddProjectForm } from './AddProjectForm';
import _ from 'lodash';
import { AddProjectRoleForm, AddProjectRoleValue } from './AddProjectRoleForm';

type Props = {
	userId: UUID;
	allProjects: ProjectResponseData[];
	userRoles: UserRole[];
	userRelations: UserRelation[];
	canEditUserRoles: boolean;
};

export const ProjectsRoleManagementForm = ({
	userId,
	allProjects,
	userRoles,
	userRelations,
	canEditUserRoles,
}: Props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	function handleAddProjectDialog(groupedUserRelations: _.Dictionary<UserRelation[]>): void {
		dispatch(
			addModalDialog({
				id: 'addProjectModal',
				title: t('addProjectModalTitle'),
				contentText: t('addProjectModalDescription', {
					script: 'standingReportOrder.name', // TODO
				}),
				content: (
					<AddProjectForm
						relations={groupedUserRelations}
						projects={allProjects}
						onSubmit={(projectIds: UUID[]) => handleAddProjects(projectIds, 'addProjectModal')}
						onCancel={() => handleCloseModalDialog('addProjectModal')}
					/>
				),
			}),
		);
	}

	function handleAddProjectRoleDialog(projectId: UUID): void {
		dispatch(
			addModalDialog({
				id: 'addProjectRoleModal',
				title: t('addProjectRoleModalTitle'),
				contentText: t('addProjectRoleModalDescription', {
					script: 'standingReportOrder.name', // TODO
				}),
				content: (
					<AddProjectRoleForm
						projectId={projectId}
						userRoles={userRoles}
						onSubmit={(userRelation: AddProjectRoleValue) =>
							handleAddUserRole(userRelation, 'addProjectRoleModal')
						}
						onCancel={() => handleCloseModalDialog('addProjectRoleModal')}
					/>
				),
			}),
		);
	}

	function handleAddProjects(projectIds: UUID[], dialogId: string) {
		handleCloseModalDialog(dialogId);

		dispatch(addUserProjectRelations(projectIds.map((projectId) => ({ userId: userId, projectId: projectId }))));
	}

	function handleAddUserRole(relation: AddProjectRoleValue, dialogId: string) {
		handleCloseModalDialog(dialogId);

		dispatch(createUserRelation({ userId: userId, roleId: relation.roleId, projectId: relation.projectId }));
	}

	function handleCloseModalDialog(dialogId: string): void {
		dispatch(removeModalDialog(dialogId));
	}

	function handleDeleteUserRole(relationId: UUID) {
		dispatch(deleteUserRelation(relationId));
	}

	const groupedUserRelations = _.groupBy(userRelations, 'projectId');

	return (
		<>
			<Button
				// className={classes.addProjectBtn}
				onClick={() => handleAddProjectDialog(groupedUserRelations)}
				startIcon={<Add />}
				id="addProject"
				data-cy="btn-add:project"
			>
				{t('addProject')}
			</Button>
			<List id="relations">
				{Object.entries(groupedUserRelations)
					.filter((project) => project[0] !== 'null' && project[0] !== 'undefined')
					.map((project) => {
						const projectId = project[0];

						const roles: JSX.Element[] = [];
						let relationId: UUID | undefined = undefined;

						project[1].forEach((relation) => {
							if (relation.roleId !== null) {
								roles.push(
									<ListItem key={`${project[0]}-${relation.id}`} button>
										<ListItemText
											secondary={userRoles.find((role) => role.id === relation.roleId)?.name}
										/>
										{canEditUserRoles && (
											<ListItemSecondaryAction>
												<IconButton
													edge="end"
													onClick={() => handleDeleteUserRole(relation.id)}
												>
													<Tooltip title={String(t('deleteRole') as string)}>
														<DeleteRounded />
													</Tooltip>
												</IconButton>
											</ListItemSecondaryAction>
										)}
									</ListItem>,
								);
							} else {
								relationId = relation.id;
							}
						});

						const arr: JSX.Element[] = [
							<ListItem key={projectId} button>
								<ListItemText primary={allProjects.find((project) => project.id === projectId)?.name} />
								<ListItemSecondaryAction>
									{canEditUserRoles && (
										<IconButton edge="end" onClick={() => handleAddProjectRoleDialog(projectId)}>
											<Tooltip title={String(t('addRole') as string)}>
												<Add />
											</Tooltip>
										</IconButton>
									)}
									{roles.length === 0 && relationId && (
										<IconButton
											edge="end"
											onClick={() => relationId && handleDeleteUserRole(relationId)}
										>
											<Tooltip title={String(t('deleteProject') as string)}>
												<DeleteRounded />
											</Tooltip>
										</IconButton>
									)}
								</ListItemSecondaryAction>
							</ListItem>,
						];

						arr.push(...roles);

						arr.push(<Divider />);

						return arr;
					})}
			</List>
		</>
	);
};

import { withLeaflet, MapControl, MapControlProps } from 'react-leaflet';
import { GeoSearchControl, AlgoliaProvider } from 'leaflet-geosearch';
import 'leaflet-geosearch/dist/geosearch.css';

class GeoSearch extends MapControl {
	constructor(props: Readonly<MapControlProps>, context: unknown) {
		super(props);
	}

	createLeafletElement(_opts: unknown) {
		const provider = new AlgoliaProvider({
			params: {
				type: 'city,country,address',
				countries: 'cz,sk,at,hu,fr,de,pl,ch,nl,lu,be',
				hitsPerPage: 10,
			},
		});

		const searchControl = GeoSearchControl({
			provider: provider,
			style: 'bar',
		});

		return searchControl;
	}

	componentDidMount() {
		if (this.props.leaflet) {
			const { map } = this.props.leaflet;

			map?.addControl(this.leafletElement);
		}
	}
}

export default withLeaflet(GeoSearch);

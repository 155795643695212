import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { ScriptsTable } from './components/ScriptsTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { IngestionEndpointsState } from '../../store/ingestion-endpoints/ingestion-endpoints-types';
import { fetchIngestionScripts } from '../../store/ingestion-endpoints/ingestion-endpoints-async-actions';
import { Grid } from '@material-ui/core';

export const IngestionScripts = () => {
	const dispatch = useDispatch();
	const { scripts } = useSelector<RootState, IngestionEndpointsState>((state) => state.ingestionEndpoints);

	useEffect(() => {
		dispatch(
			fetchIngestionScripts({
				limit: scripts.limit,
				offset: scripts.offset,
				orderings: scripts.orderings,
				filtersAndConditions: scripts.filters,
			}),
		);

		// Don't need to react on "config" and "filters" and deep comparision is not necessary
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	return (
		<Grid container spacing={2} justify="center">
			<Grid item xs={12} lg={8}>
				<Paper>
					<ScriptsTable
						data={scripts.data}
						total={scripts.total}
						limit={scripts.limit}
						offset={scripts.offset}
						orderings={scripts.orderings}
						filters={scripts.filters}
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};

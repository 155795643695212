export const GENERAL_DEVICE_REQUEST = '[DEVICES_ACTIONS] GENERAL_DEVICE_REQUEST';
export const GENERAL_DEVICE_ERROR = '[DEVICES_ACTIONS] GENERAL_DEVICE_ERROR';

export const CREATE_DEVICE_SUCCESS = '[DEVICES_ACTIONS] CREATE_DEVICE_SUCCESS';
export const EDIT_DEVICE_SUCCESS = '[DEVICES_ACTIONS] EDIT_DEVICE_SUCCESS';

export const UPLOAD_DEVICES_SUCCESS = '[DEVICES_ACTIONS] UPLOAD_DEVICES_SUCCESS';

export const FETCH_DEVICES_TELEMETRY_SUCCESS = '[DEVICES_ACTIONS] FETCH_DEVICES_TELEMETRY_SUCCESS';
export const FETCH_DEVICES_TELEMETRY_SUCCESS_NO_PROGRESS_BAR =
	'[DEVICES_ACTIONS] FETCH_DEVICES_TELEMETRY_SUCCESS_NO_PROGRESS_BAR';

export const FETCH_DEVICE_SUCCESS = '[DEVICES_ACTIONS] FETCH_DEVICE_SUCCESS';
export const FETCH_DEVICE_SUCCESS_NO_PROGRESS_BAR = '[DEVICES_ACTIONS] FETCH_DEVICE_SUCCESS_NO_PROGRESS_BAR';

export const SET_DEVICES_TABLE_HIDEN_FLAGS_SUCCESS = '[DEVICES_ACTIONS] SET_DEVICES_TABLE_HIDEN_FLAGS_SUCCESS';
export const SET_TELEMETRY_TABLE_HIDEN_FLAGS_SUCCESS = '[DEVICES_ACTIONS] SET_TELEMETRY_TABLE_HIDEN_FLAGS_SUCCESS';

export const FETCH_DEVICE_TYPES_SUCCESS = '[DEVICES_ACTIONS] FETCH_DEVICE_TYPES_SUCCESS';
export const CREATE_DEVICE_TYPE_SUCCESS = '[DEVICES_ACTIONS] CREATE_DEVICE_TYPE_SUCCESS';
export const EDIT_DEVICE_TYPE_SUCCESS = '[DEVICES_ACTIONS] EDIT_DEVICE_TYPE_SUCCESS';
export const DELETE_DEVICE_TYPE_SUCCESS = '[DEVICES_ACTIONS] DELETE_DEVICE_TYPE_SUCCESS';

export const EDIT_DEVICE_PROPERTIES_SUCCESS = '[DEVICES_ACTIONS] EDIT_DEVICE_PROPERTIES_SUCCESS';

import { apiClient } from '../client';
import { ReportRequestBody, ReportResponseData, NotificationsResponseData } from '../../pages/Reports/reports-types';
import { UUID } from '../../types';
import { FiltersRequestBody2 } from '../../components/Table/types';

const baseUrl = '/workflow.net';

export const getReport = (body: ReportRequestBody) => apiClient.post<ReportResponseData>(`${baseUrl}/filter`, body);

export const getNotifications = (body: FiltersRequestBody2) =>
	apiClient.post<NotificationsResponseData>(`${baseUrl}/notifications/filter`, body);

export const exportDevicesToExcel = (projectId: UUID) =>
	apiClient.get(`/report.net/export/base64`, { responseType: 'text' }).then((response) => {
		const buff = new Buffer(response.data, 'base64');

		const url = window.URL.createObjectURL(new Blob([buff]));

		const link = document.createElement('a');

		link.href = url;
		link.setAttribute('download', 'export.xlsx');
		document.body.appendChild(link);
		link.click();
	});

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import { history } from './router/history';
import { Routes, RouteEnum } from './router/Routes';
import { theme } from './theme';
import { AppContainer } from './components/Layout/AppContainer';
import { RootState } from './store/types';
import { AppState } from './store/app/app-types';
import { UserState } from './store/user/user-types';
import { Toast } from './components/Toast/Toast';
import { setRedirectUrl, removeToast } from './store/app/app-actions';
import { Loader } from './components/Loader/Loader';
import { ModalDialog } from './components/ModalDialog/ModalDialog';
import JwtDecode from 'jwt-decode';
import { UserAuth } from './services/user/user-types';
import { loginSuccess } from './store/user/user-actions';
import { UUID } from './types';

const responsiveTheme = responsiveFontSizes(theme);

export const App = () => {
	const dispatch = useDispatch();
	const { loading, redirectUrl, toasts, modalDialogs } = useSelector<RootState, AppState>((state) => state.app);
	const { jwtToken } = useSelector<RootState, UserState>((state) => state.user);
	const isLoggedIn = Boolean(jwtToken);
	const isLoading = loading > 0;

	/**
	 * Redirect when redirectUrl changed in Redux
	 */
	useEffect(() => {
		if (redirectUrl !== '' && redirectUrl !== history.location.pathname) {
			// redirect and clear URL
			history.push(redirectUrl);
			dispatch(setRedirectUrl(''));
		}
	}, [dispatch, redirectUrl]);

	/**
	 * Keep user signed in after page refresh if JWT_TOKEN exists,
	 * but don't run when user just logged in (duplicate loginSuccess call).
	 */
	useEffect(() => {
		if (jwtToken && !isLoggedIn) {
			const decoded = JwtDecode<UserAuth>(jwtToken);

			dispatch(loginSuccess(jwtToken, decoded));
			dispatch(setRedirectUrl(RouteEnum.DASHBOARD));
		}
	}, [dispatch, jwtToken, isLoggedIn]);

	const handleCloseToast = (id: UUID) => {
		dispatch(removeToast(id));
	};

	return (
		<ThemeProvider theme={responsiveTheme}>
			<CssBaseline />
			<AppContainer>
				<Router history={history}>
					<Routes isLoggedIn={isLoggedIn} />
				</Router>

				{toasts.map((toast, index) => (
					<Toast key={`toast-${toast.id}`} index={index} open onCloseToast={handleCloseToast} {...toast} />
				))}

				{modalDialogs.map((modalDialog) => (
					<ModalDialog key={`dialog-${modalDialog.id}`} {...modalDialog} />
				))}
			</AppContainer>

			{isLoading && <Loader isVisible={isLoading} />}
		</ThemeProvider>
	);
};

import { FormControl, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableFilter } from '../TableFilter';
import { TableFilterHeading } from '../TableFilterHeading';
import { TableHeadCell } from '../types';
import { DateTimePicker } from '../../../components/DatePicker/DateTimePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Nullable } from '../../../types';
import { FilterOperators } from '../../../components/Table/constants';

type Props = {
	tableHead: TableHeadCell;
	onChange: (tableHead: TableHeadCell) => void;
};

export const DateSpanFilter = ({ tableHead, onChange }: Props) => {
	const { t } = useTranslation();

	const fromValue = tableHead.filters?.find(
		(filter) => filter.column === tableHead.id && filter.operator === FilterOperators.greaterThanEqual,
	)?.value;

	const toValue = tableHead.filters?.find(
		(filter) => filter.column === tableHead.id && filter.operator === FilterOperators.lessThanEqual,
	)?.value;

	const [dateFrom, setDateFrom] = useState<Nullable<Date> | undefined>(fromValue ? (fromValue as Date) : null);
	const [dateFromError, setDateFromError] = useState(false);

	function updateDateFrom(date: MaterialUiPickersDate) {
		setDateFromError(false);

		setDateFrom(date);
	}

	const [dateTo, setDateTo] = useState<Nullable<Date> | undefined>(toValue ? (toValue as Date) : null);

	function updateDateTo(date: MaterialUiPickersDate) {
		setDateTo(date);
	}

	const handleSaveFilters = () => {
		tableHead.filters = [];

		if (dateFrom && dateTo && dateFrom != null && dateTo != null) {
			// console.log(dateFrom?.getTime() > dateTo?.getTime());

			if (dateFrom?.getTime() > dateTo?.getTime()) {
				setDateFromError(true);

				return;
			}
		}

		if (dateFrom && dateFrom !== null) {
			tableHead.filters.push({
				column: tableHead.id,
				operator: FilterOperators.greaterThanEqual,
				value: dateFrom.toISOString(),
			});
		}

		if (dateTo && dateTo !== null) {
			tableHead.filters.push({
				column: tableHead.id,
				operator: FilterOperators.lessThanEqual,
				value: dateTo.toISOString(),
			});
		}

		onChange(tableHead);
	};

	const handleResetFilters = () => {
		tableHead.filters = undefined;

		setDateFrom(null);
		setDateTo(null);

		onChange(tableHead);
	};

	const inactive = tableHead.filters === undefined || tableHead.filters.length === 0;

	return (
		<TableFilter onSave={handleSaveFilters} onReset={handleResetFilters} active={!inactive} name={tableHead.id}>
			<Grid container spacing={1} alignItems="center">
				<Grid item xs={12} sm={6}>
					<FormControl fullWidth>
						<TableFilterHeading text={`${t('from')}:`} />
						<DateTimePicker
							error={dateFromError}
							disableFuture
							maxDate={new Date()}
							id="dateFrom"
							value={dateFrom}
							onChange={(date) => updateDateFrom(date)}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl fullWidth>
						<TableFilterHeading text={`${t('to')}:`} />
						<DateTimePicker
							disableFuture
							maxDate={new Date()}
							id="dateTo"
							value={dateTo}
							onChange={(date) => updateDateTo(date)}
						/>
					</FormControl>
				</Grid>
			</Grid>
		</TableFilter>
	);
};

export const GENERAL_CONTACT_GROUPS_REQUEST = '[CONTACT_GROUPS_ACTIONS] GENERAL_CONTACT_GROUPS_REQUEST';
export const GENERAL_CONTACT_GROUPS_ERROR = '[CONTACT_GROUPS_ACTIONS] GENERAL_CONTACT_GROUPS_ERROR';

export const FETCH_CONTACT_GROUPS_SUCCESS = '[CONTACT_GROUPS_ACTIONS] FETCH_CONTACT_GROUPS_SUCCESS';

export const CREATE_CONTACT_GROUP_SUCCESS = '[CONTACT_GROUPS_ACTIONS] CREATE_CONTACT_GROUP_SUCCESS';

export const UPDATE_CONTACT_GROUP_SUCCESS = '[CONTACT_GROUPS_ACTIONS] UPDATE_CONTACT_GROUP_SUCCESS';

export const DELETE_CONTACT_GROUP_SUCCESS = '[CONTACT_GROUPS_ACTIONS] DELETE_CONTACT_GROUP_SUCCESS';

export const GET_CONTACT_GROUP_DETAIL = '[CONTACT_GROUPS_ACTIONS] GET_CONTACT_GROUP_DETAIL';

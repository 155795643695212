import { AxiosResponse } from 'axios';

export enum ErrorCodes {
	'SYS-001' = 'SYS-001',
}

export type ServerError = {
	message: string;
	name: string;
	errorCode: ErrorCodes;
	errorType: string;
	status: number;
};

export type FetchResponse<Response> = {
	data?: Response;
	status: number;
	error?: AxiosResponse<ServerError>;
};

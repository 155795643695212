import { set } from 'lodash/fp';
import { createReducer } from 'typesafe-actions';
import { TelemetrySocketData } from '../../pages/Devices/telemetry-types';
import {
	FETCH_TELEMETRY_REQUEST,
	FETCH_TELEMETRY_SUCCESS,
	FETCH_TELEMETRY_ERROR,
	UPDATE_CURRENT_TELEMETRY,
	FETCH_CURRENT_TELEMETRY_SUCCESS,
	FETCH_GROUPED_TELEMETRY_SUCCESS,
} from './telemetry-constants';
import { TelemetryActions, TelemetryState } from './telemetry-types';

export const telemetryInitialState: TelemetryState = {
	data: [],
	total: 0,
	offset: 0,
	limit: 10,
	// Telemetry from web sockets
	currentTelemetry: [],
	groupedTelemetry: {
		data: [],
		total: 0,
		zoom: 0,
		offset: 0,
		limit: 10,
	},
};

export const telemetryReducer = createReducer<TelemetryState, TelemetryActions>(telemetryInitialState, {
	[FETCH_TELEMETRY_REQUEST]: (state) => state,
	[FETCH_TELEMETRY_SUCCESS]: (state, action) => ({
		...state,
		data: action.payload.data.data,
		total: action.payload.data.total,
		offset: action.payload.options?.offset !== undefined ? action.payload.options.offset : state.offset,
		limit: action.payload.options?.limit !== undefined ? action.payload.options.limit : state.limit,
		orderings: action.payload.options?.orderings,
		filters: action.payload.options?.filtersAndConditions,
	}),
	[FETCH_CURRENT_TELEMETRY_SUCCESS]: (state, action) => {
		// Store only last 5 telemetry data
		const data =
			action.payload.data.data.length >= 5 ? action.payload.data.data.slice(0, 5) : action.payload.data.data;

		const telemetry: TelemetrySocketData[] = data.map((telemetry) => ({
			recipient: { tenantId: telemetry.tenantId, endpoint: 'telemetry.endpoint' },
			sensor: { sn: telemetry.sn },
			telemetry: telemetry,
			location: {
				light: null,
				battery: null,
				light_intensity: null,
				max_acceleration_history: null,
				max_acceleration: null,
				signal_power: null,
				temperature: null,
				time: new Date(telemetry.time),
				accuracy: 0,
				lat: 0,
				lng: 0,
				zones: telemetry.zones ? telemetry.zones : undefined,
				fcnt: null,
			},
		}));

		return set('currentTelemetry', telemetry, state);
	},
	[FETCH_TELEMETRY_ERROR]: (state) => state,
	[UPDATE_CURRENT_TELEMETRY]: (state, action) => {
		// Store only last 5 telemetry data
		const data = state.currentTelemetry.length >= 5 ? state.currentTelemetry.slice(0, 4) : state.currentTelemetry;

		const updated = [action.payload.data, ...data];

		return set('currentTelemetry', updated, state);
	},
	[FETCH_GROUPED_TELEMETRY_SUCCESS]: (state, action) => ({
		...state,
		groupedTelemetry: {
			data: action.payload.data.data,
			total: action.payload.data.total,
			zoom:
				action.payload.options?.zoom !== undefined ? action.payload.options.zoom : state.groupedTelemetry.zoom,
			offset: action.payload.options?.offset !== undefined ? action.payload.options.offset : state.offset,
			limit: action.payload.options?.limit !== undefined ? action.payload.options.limit : state.limit,
			orderings: action.payload.options?.orderings,
			filters: action.payload.options?.filtersAndConditions,
		},
	}),
});

import React from 'react';
import Paper from '@material-ui/core/Paper';

export const Billing = () => {
	return (
		<Paper>
			<h1>Billing</h1>
		</Paper>
	);
};

import { action } from 'typesafe-actions';
import {
	FETCH_GROUPS_ERROR,
	FETCH_GROUPS_REQUEST,
	FETCH_GROUPS_SUCCESS,
	CREATE_GROUP_REQUEST,
	CREATE_GROUP_SUCCESS,
	CREATE_GROUP_ERROR,
	UPDATE_GROUP_REQUEST,
	UPDATE_GROUP_SUCCESS,
	UPDATE_GROUP_ERROR,
	DELETE_GROUP_REQUEST,
	DELETE_GROUP_SUCCESS,
	DELETE_GROUP_ERROR,
	GET_GROUP_DETAIL,
} from './groups-constants';

import { UUID } from '../../types';
import { GroupsResponseData, GroupResponseData, UpdateGroupResponseData } from '../../pages/Groups/groups-types';
import { FiltersRequestBody2 } from '../../components/Table/types';

export const fetchGroupsRequest = () => action(FETCH_GROUPS_REQUEST);
export const fetchGroupsSuccess = (data: GroupsResponseData, options?: FiltersRequestBody2) =>
	action(FETCH_GROUPS_SUCCESS, { data, options });

export const fetchGroupsError = () => action(FETCH_GROUPS_ERROR);

export const createGroupRequest = () => action(CREATE_GROUP_REQUEST);
export const createGroupSuccess = () => action(CREATE_GROUP_SUCCESS);
export const createGroupError = () => action(CREATE_GROUP_ERROR);

export const updateGroupRequest = () => action(UPDATE_GROUP_REQUEST);
export const updateGroupSuccess = (data: UpdateGroupResponseData) => action(UPDATE_GROUP_SUCCESS, { data });
export const updateGroupError = () => action(UPDATE_GROUP_ERROR);

export const deleteGroupRequest = () => action(DELETE_GROUP_REQUEST);
export const deleteGroupSuccess = (groupId: UUID) => action(DELETE_GROUP_SUCCESS, { groupId });
export const deleteGroupError = () => action(DELETE_GROUP_ERROR);

export const getGroupDetail = (data: GroupResponseData) => action(GET_GROUP_DETAIL, { data });

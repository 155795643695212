import React from 'react';
import { Controller, Control } from 'react-hook-form';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { TextField, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FieldName } from 'react-hook-form/dist/types/form';

const useStyles = makeStyles(({ spacing }: Theme) => ({
	select: {
		'& .MuiFormLabel-root': {
			width: '90%',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			paddingRight: spacing(2),

			'&.Mui-focused': {
				width: '100%',
				textOverflow: 'initial',
			},
		},
	},
}));

type MuiSelectProps<T> = Omit<
	AutocompleteProps<T, boolean | undefined, undefined, undefined>,
	'renderInput' | 'options'
>;

type InputSelectProps<T> = MuiSelectProps<T> & {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	control: Control<any>;
	error: boolean;
	helperText?: React.ReactNode;
	id?: string;
	label: string;
	multiple?: boolean;
	name: FieldName<T>;
	options: string[];
};

export const InputSelect = <T extends Record<string, unknown>>({
	defaultValue,
	control,
	error,
	helperText,
	id,
	label,
	multiple = false,
	name,
	options,
}: InputSelectProps<T>) => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<Controller
			render={(props) => (
				<Autocomplete
					{...props}
					className={classes.select}
					ChipProps={{ size: 'small' }}
					color="primary"
					filterSelectedOptions
					getOptionLabel={(option) => t(option)}
					getOptionSelected={(option, value) => option === value}
					id={name || id}
					limitTags={2}
					multiple={multiple}
					noOptionsText={t('noOptions')}
					onChange={(_, values) => props.onChange(values)}
					options={options}
					renderInput={(params) => (
						<TextField
							{...params}
							error={Boolean(error)}
							helperText={helperText}
							label={label}
							variant="outlined"
						/>
					)}
				/>
			)}
			control={control}
			defaultValue={defaultValue}
			name={name}
		/>
	);
};

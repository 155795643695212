import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { ProjectsTable } from './components/ProjectsTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { ProjectsState } from '../../store/projects/projects-types';
import { fetchProjects } from '../../store/projects/projects-async-actions';
import { Grid } from '@material-ui/core';
import { FilterOperators } from '../../components/Table/constants';

export const Projects = () => {
	const dispatch = useDispatch();
	const { data, total, limit, offset, filters, orderings } = useSelector<RootState, ProjectsState>(
		(state) => state.projects,
	);

	useEffect(() => {
		dispatch(
			fetchProjects({
				limit: limit,
				offset: offset,
				orderings: orderings,
				filtersAndConditions: [{ column: 'userId', operator: FilterOperators.ignore, value: 0 }],
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	return (
		<Grid container spacing={2} justify="center">
			<Grid item xs={12} lg={10}>
				<Paper>
					<ProjectsTable
						data={data}
						total={total}
						offset={offset}
						limit={limit}
						orderings={orderings}
						filters={filters}
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};

import React, { useEffect } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/types';
import { GroupsState } from '../../store/groups/groups-types';
import { GroupsTable } from './components/GroupsTable';
import { fetchGroups } from '../../store/groups/groups-async-actions';
import { UserState } from '../../store/user/user-types';

export const Groups = () => {
	const dispatch = useDispatch();
	const { data, total, limit, offset, orderings, filtersAndConditions } = useSelector<RootState, GroupsState>(
		(state) => state.groups,
	);

	const { project } = useSelector<RootState, UserState>((state) => state.user);

	useEffect(() => {
		if (project?.id) {
			dispatch(
				fetchGroups({
					limit: limit,
					offset: offset,
					orderings: orderings,
					filtersAndConditions: filtersAndConditions,
				}),
			);
		}

		// Don't need to react on "config" and "filters" and deep comparision is not necessary
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, project]);

	return (
		<Grid container spacing={2} justify="center">
			<Grid item xs={12} lg={10}>
				<Paper>
					<GroupsTable
						data={data}
						total={total}
						offset={offset}
						limit={limit}
						orderings={orderings}
						filtersAndConditions={filtersAndConditions}
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};

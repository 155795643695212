import React from 'react';
import { useHistory } from 'react-router-dom';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import { Button } from './Button';
import { useTranslation } from 'react-i18next';

export const BackButton = () => {
	const history = useHistory();
	const { t } = useTranslation();

	return (
		<Button
			fullWidth={false}
			onClick={() => history.goBack()}
			size="small"
			startIcon={<ArrowBackRoundedIcon />}
			variant="outlined"
			data-cy="btn-back:backButton"
		>
			{t('back')}
		</Button>
	);
};

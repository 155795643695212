import React, { ChangeEvent } from 'react';
import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import { Controller, Control } from 'react-hook-form';
import { Theme, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(({ typography }: Theme) => ({
	code: {
		'& .MuiInputBase-inputMultiline': {
			fontFamily: 'Fira code, Fira Mono, monospace',
			fontSize: typography.pxToRem(14),
			fontWeight: 500,
		},
	},
}));

type InputFieldProps = BaseTextFieldProps & {
	control?: Control<Record<string, unknown>>;
	InputProps?: Partial<OutlinedInputProps>;
	name: string;
	code?: boolean;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const Textarea = ({
	autoFocus = false,
	className,
	disabled,
	fullWidth = true,
	defaultValue,
	error,
	helperText,
	id,
	label,
	margin = 'normal',
	name,
	required = true,
	control,
	onChange,
	code = false,
}: InputFieldProps) => {
	const classes = useStyles();

	return control ? (
		<Controller
			render={(props) => (
				<TextField
					{...props}
					autoFocus={autoFocus}
					className={clsx(className, code && classes.code)}
					disabled={disabled}
					error={error}
					fullWidth={fullWidth}
					helperText={helperText}
					id={id || name}
					label={label}
					margin={margin}
					multiline
					required={required}
					type={'text'}
					variant="outlined"
				/>
			)}
			control={control}
			name={name}
			defaultValue={defaultValue}
		/>
	) : (
		<TextField
			autoFocus={autoFocus}
			className={clsx(className, code && classes.code)}
			disabled={disabled}
			error={error}
			fullWidth={fullWidth}
			helperText={helperText}
			id={id || name}
			label={label}
			margin={margin}
			multiline
			required={required}
			type={'text'}
			variant="outlined"
			name={name}
			onChange={onChange}
			defaultValue={defaultValue}
		/>
	);
};

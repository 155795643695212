import { LatLngTuple, LatLngLiteral } from 'leaflet';
import { notEmpty } from '../../helpers/array';
import { fromJSONToObject } from '../../helpers/object';
import { DeviceResponseData2 } from '../Devices/devices-types';
import { Zone } from './zones-types';

export const toLatLngExpression = (latLng: LatLngLiteral[]): LatLngTuple[] => latLng.map(({ lat, lng }) => [lat, lng]);

export const toLatLngLiteral = (latLng: LatLngTuple[]): LatLngLiteral[] => latLng.map(([lat, lng]) => ({ lat, lng }));

export const getBoundsFromMultipleZones = (zones: Zone[]) => {
	const bounds = zones
		.map((zone) => {
			const coords = fromJSONToObject<LatLngLiteral[]>(zone.polygon);

			if (!coords) return null;

			return coords;
		})
		.filter(notEmpty)
		.flat(2)
		.reduce<{ lats: number[]; lngs: number[] }>(
			(acc, { lat, lng }) => {
				return {
					...acc,
					lats: [...acc.lats, lat],
					lngs: [...acc.lngs, lng],
				};
			},
			{ lats: [], lngs: [] },
		);

	if (bounds.lats.length === 0 || bounds.lngs.length === 0) {
		return undefined;
	}

	return [
		[Math.min(...bounds.lats), Math.min(...bounds.lngs)],
		[Math.max(...bounds.lats), Math.max(...bounds.lngs)],
	] as LatLngTuple[];
};

export const getBoundsFromMultipleDevices = (devices: DeviceResponseData2[]) => {
	const bounds = devices
		.map((device) => {
			const coords = device.data;

			if (coords && coords !== null && coords.lat && coords.lng) {
				return { lat: coords.lat, lng: coords.lng };
			}

			return null;
		})
		.filter(notEmpty)
		.flat(2)
		.reduce<{ lats: number[]; lngs: number[] }>(
			(acc, { lat, lng }) => {
				return {
					...acc,
					lats: [...acc.lats, lat],
					lngs: [...acc.lngs, lng],
				};
			},
			{ lats: [], lngs: [] },
		);

	if (bounds.lats.length === 0 || bounds.lngs.length === 0) {
		return undefined;
	}

	return [
		[Math.min(...bounds.lats), Math.min(...bounds.lngs)],
		[Math.max(...bounds.lats), Math.max(...bounds.lngs)],
	] as LatLngTuple[];
};

export const getBoundsFromSingleZone = (zones: Zone[]) => {
	const head = zones[0];

	if (head) {
		const coords = fromJSONToObject<LatLngLiteral[]>(head.polygon);

		if (!coords) return [];

		return toLatLngExpression(coords);
	}

	return [];
};

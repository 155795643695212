import React from 'react';
import { makeStyles, MenuItem, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Filter, SelectFilterOption } from './types';
import { TableFilterHeading } from './TableFilterHeading';

const useStyles = makeStyles({
	filterSelect: {
		padding: '10.5px 14px',
	},
});

type Props = {
	label: string;
	name?: string;
	options: SelectFilterOption[];
	onChange: (option: Filter) => void;
	currentFilter: Filter;
};

export const TableSelectFilter = ({ label, name, options, onChange, currentFilter }: Props) => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<>
			<TableFilterHeading text={`${label}:`} />
			<Select
				classes={{ select: classes.filterSelect }}
				fullWidth
				name={name}
				onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
					const option = options.find((option) => option.name === event.target.value);

					if (option) {
						onChange(option.filter);
					}
				}}
				value={options.find((option) => option.filter.value === currentFilter?.value)?.name || options[0].name}
				variant="outlined"
			>
				{options.map((option) => (
					<MenuItem key={option.name} value={option.name}>
						{t(option.name)}
					</MenuItem>
				))}
			</Select>
		</>
	);
};

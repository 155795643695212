import React, { useEffect } from 'react';
import { TableHeadCell, TableRowRenderProps } from '../../../components/Table/types';
import { Table } from '../../../components/Table/Table';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TableRow, TableCell, CardHeader, Divider } from '@material-ui/core';
import { RootState } from '../../../store/types';
import { DevicePlacementsState } from '../../../store/device-placements/device-placements-types';
import { fetchPlacementConnections } from '../../../store/device-placements/device-placements-async-actions';
import { PlacementConnection } from '../../DevicePlacementsManagement/device-placements-types';
import { UUID } from '../../../types';
import { FilterOperators, SortOrder } from '../../../components/Table/constants';
import { VehicleWatchState } from '../../../store/vehicle-watch/vehicle-watch-types';
import { INPUT_DATE_TIME_MASK } from '../../../helpers/generalConstants';
import { format, isValid } from 'date-fns';

type Props = {
	placementId: UUID;
};

export const DriversHistoryTable = ({ placementId }: Props) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { drivers } = useSelector<RootState, VehicleWatchState>((state) => state.vehicleWatch);
	const { connections } = useSelector<RootState, DevicePlacementsState>((state) => state.devicePlacements);

	useEffect(() => {
		dispatch(
			fetchPlacementConnections({
				limit: connections.limit,
				offset: connections.offset,
				orderings: [{ column: 'attached', sortOrder: SortOrder.Descending }],
				filtersAndConditions: [
					{
						column: 'placement1Id',
						operator: FilterOperators.equals,
						value: placementId,
					},
				],
			}),
		);

		// Don't need to react on "config" and "filters" and deep comparision is not necessary
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const columns: TableHeadCell[] = [
		{
			id: 'name',
			label: t('name'),
			unorderable: true,
		},
		{
			id: 'attached',
			label: t('attached'),
			unorderable: true,
		},
		{
			id: 'detached',
			label: t('detached'),
			unorderable: true,
		},
	];

	function getDriverName(driverId: UUID) {
		const driver = drivers.data.find((driver) => driver.id === driverId);

		const description = driver?.placementDescription;

		if (description) {
			return `${description.title2} ${description.subTitle2}`;
		}

		return 'N/A';
	}

	const renderTableRow = ({ row: connection }: TableRowRenderProps<PlacementConnection>) => {
		const dateAttached = new Date(connection.attached);
		const dateAttachedFormatted = isValid(dateAttached) ? format(dateAttached, INPUT_DATE_TIME_MASK) : '-';

		let dateDetachedFormatted = '-';

		if (connection.detached !== null) {
			const dateDetached = new Date(connection.detached);

			if (isValid(dateDetached)) {
				dateDetachedFormatted = format(dateDetached, INPUT_DATE_TIME_MASK);
			}
		}

		return (
			<TableRow key={connection.id} id={`row-${connection.id}`} hover>
				<TableCell component="td" id={`driver.name`} scope="row">
					{getDriverName(connection.placement2Id)}
				</TableCell>
				<TableCell component="td" id={'connection.attached'} scope="row">
					{dateAttachedFormatted}
				</TableCell>
				<TableCell component="td" id={'connection.detached'} scope="row">
					{dateDetachedFormatted}
				</TableCell>
			</TableRow>
		);
	};

	return (
		<>
			<CardHeader title={t('drivers')} />
			<Divider light />
			<Table
				columns={columns}
				orderings={connections.orderings}
				filtersAndConditions={connections.filtersAndConditions}
				data={connections.data}
				dataLimit={connections.limit}
				dataOffset={connections.offset}
				numberOfRows={connections.total}
				fetchAction2={fetchPlacementConnections}
				renderTableRow={renderTableRow}
			/>
		</>
	);
};

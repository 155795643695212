import React from 'react';
import { Route, RouteProps, RouteComponentProps } from 'react-router-dom';
import { SimpleLayout } from '../components/Layout/SimpleLayout';

type OwnProps = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
};

type Props = RouteProps & OwnProps;

export const PublicRoute = ({ component: Component, ...restProps }: Props) => (
	<Route
		{...restProps}
		render={(props) => (
			<SimpleLayout>
				<Component {...props} />
			</SimpleLayout>
		)}
	/>
);

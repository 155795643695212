import { createReducer } from 'typesafe-actions';
import {
	CONTACTS_COMMON_EMPTY_REQUEST,
	CONTACTS_COMMON_EMPTY_ERROR,
	FETCH_CONTACTS_SUCCESS,
	CREATE_CONTACT_SUCCESS,
	EDIT_CONTACT_SUCCESS,
	DELETE_CONTACT_SUCCESS,
} from './contacts-constants';

import { ContactsActions, ContactsState } from './contacts-types';
import { updateObjectInArray } from '../../helpers/array';

export const contactsInitialState: ContactsState = {
	data: [],
	total: 0,
	limit: 10,
	offset: 0,
	orderings: [],
	filters: [],
};

export const contactsReducer = createReducer<ContactsState, ContactsActions>(contactsInitialState, {
	[CONTACTS_COMMON_EMPTY_REQUEST]: (state) => state,
	[CONTACTS_COMMON_EMPTY_ERROR]: (state) => state,
	[FETCH_CONTACTS_SUCCESS]: (state, action) => ({
		...state,
		data: action.payload.data ? action.payload.data.data : state.data,
		total: action.payload.data ? action.payload.data.total : state.total,
		limit: action.payload.options ? action.payload.options.limit : state.limit,
		offset: action.payload.options ? action.payload.options.offset : state.offset,
		orderings: action.payload.options?.orderings,
		filters: action.payload.options?.filtersAndConditions,
	}),
	[CREATE_CONTACT_SUCCESS]: (state, action) => {
		const { data } = action.payload;
		const contactIndex = state.data.findIndex((contact) => contact.id === data.id);

		return {
			...state,
			data: updateObjectInArray(data, contactIndex, state.data),
		};
	},
	[EDIT_CONTACT_SUCCESS]: (state, action) => {
		const { data } = action.payload;
		const contactIndex = state.data.findIndex((contact) => contact.id === data.id);

		return {
			...state,
			data: updateObjectInArray(data, contactIndex, state.data),
		};
	},
	[DELETE_CONTACT_SUCCESS]: (state, action) => {
		return {
			...state,
			data: state.data.filter((contact) => contact.id !== action.payload.id),
		};
	},
});

import { WorkflowsResponseData } from '../../pages/Workflows/workflows-types';
import { action } from 'typesafe-actions';
import {
	FETCH_WORKFLOWS_REQUEST,
	FETCH_WORKFLOWS_SUCCESS,
	FETCH_WORKFLOWS_ERROR,
	DELETE_WORKFLOW_REQUEST,
	DELETE_WORKFLOW_ERROR,
	DELETE_WORKFLOW_SUCCESS,
	SET_CURRENT_WORKFLOW,
	EDIT_WORKFLOW_REQUEST,
	EDIT_WORKFLOW_SUCCESS,
	EDIT_WORKFLOW_ERROR,
	CREATE_WORKFLOW_REQUEST,
	CREATE_WORKFLOW_SUCCESS,
	CREATE_WORKFLOW_ERROR,
} from './workflows-constants';
import { FiltersRequestBody2 } from '../../components/Table/types';

export const fetchWorkflowsRequest = () => action(FETCH_WORKFLOWS_REQUEST);
export const fetchWorkflowsSuccess = (data: WorkflowsResponseData, options?: FiltersRequestBody2) =>
	action(FETCH_WORKFLOWS_SUCCESS, {
		data,
		options,
	});

export const fetchWorkflowsError = () => action(FETCH_WORKFLOWS_ERROR);

export const deleteWorkflowRequest = () => action(DELETE_WORKFLOW_REQUEST);
export const deleteWorkflowSuccess = (workflowId: string) => action(DELETE_WORKFLOW_SUCCESS, { workflowId });
export const deleteWorkflowError = () => action(DELETE_WORKFLOW_ERROR);

export const setCurrentWorkflow = (workflowId: string) => action(SET_CURRENT_WORKFLOW, { workflowId });

export const editWorkflowRequest = () => action(EDIT_WORKFLOW_REQUEST);
export const editWorkflowSuccess = () => action(EDIT_WORKFLOW_SUCCESS);
export const editWorkflowError = () => action(EDIT_WORKFLOW_ERROR);

export const createtWorkflowRequest = () => action(CREATE_WORKFLOW_REQUEST);
export const createWorkflowSuccess = () => action(CREATE_WORKFLOW_SUCCESS);
export const createWorkflowError = () => action(CREATE_WORKFLOW_ERROR);

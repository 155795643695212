import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/Button/Button';
import SaveIcon from '@material-ui/icons/Save';
import { ModalDialogFormActions } from '../../../components/ModalDialog/ModalDialogFormActions';
import { InputSelectUncontrolled } from '../../../components/Form/InputSelectUncontrolled';
import { UserRole, UserRoleType } from '../../UserRoles/user-roles-types';
import { UserRelation } from '../../../store/user/user-types';
import { UUID } from '../../../types';
import { Grid } from '@material-ui/core';

export type AddUserRoleValue = {
	id?: UUID;
	roleId: UUID;
};

type Props = {
	userRelation?: UserRelation;
	userRelations?: UserRelation[];
	userRoles: UserRole[];
	onSubmit: (userRelation: AddUserRoleValue) => void;
	onCancel?: () => void;
	isPlatfromAdmin: boolean;
};

export const AddUserRoleForm = ({
	onSubmit,
	onCancel,
	userRelation,
	userRelations,
	userRoles,
	isPlatfromAdmin,
}: Props) => {
	const { t } = useTranslation();

	const [selectedRole, setSelectedRole] = useState<UUID | undefined>(userRelation?.roleId);

	const [roleError, setRoleError] = useState(false);

	const handleSetRole = (roleId?: UUID) => {
		setSelectedRole(roleId);
		setRoleError(false);
	};

	const handleSubmitForm = () => {
		let valid = true;

		if (selectedRole === undefined || selectedRole === '') {
			valid = false;
			setRoleError(true);
		}

		if (valid && selectedRole) {
			// onSubmit
			onSubmit({ id: userRelation?.id, roleId: selectedRole });
		}
	};

	const defaultUserRole = userRoles.find((userRole) => userRole.id === userRelation?.roleId);

	return (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<InputSelectUncontrolled
					defaultValue={defaultUserRole && { id: defaultUserRole.id, label: defaultUserRole.name }}
					onChanged={(value) => {
						handleSetRole(value?.id);
					}}
					error={roleError}
					options={
						userRoles
							.filter(
								(userRole) =>
									userRole.roleType === UserRoleType.User ||
									(isPlatfromAdmin && userRole.roleType === UserRoleType.PlatformAdmin),
							)
							.map((userRole) => ({
								id: userRole.id,
								label: userRole.name,
							})) ?? []
					}
					label={t('selectUserRole')}
				/>
			</Grid>
			<Grid item>
				<ModalDialogFormActions>
					<Button
						onClick={handleSubmitForm}
						startIcon={<SaveIcon />}
						type="button"
						data-cy="btn-submit:userForm"
					>
						{t('save')}
					</Button>
					{onCancel && (
						<Button onClick={onCancel} variant="text">
							{t('cancel')}
						</Button>
					)}
				</ModalDialogFormActions>
			</Grid>
		</Grid>
	);
};

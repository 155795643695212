import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserResponseData } from '../../UserManagement/user-management-types';
import { Grid } from '@material-ui/core';
import { Button } from '../../../components/Button/Button';
import { InputField } from '../../../components/Form/InputField';
import SaveIcon from '@material-ui/icons/Save';
import { ModalDialogFormActions } from '../../../components/ModalDialog/ModalDialogFormActions';
import { PASSWORD_REGEX } from '../../../helpers/generalConstants';
import { Tenant } from '../../Tenants/tenants-types';
import { UUID } from '../../../types';
import { InputSelectUncontrolled } from '../../../components/Form/InputSelectUncontrolled';

export type CreateUserFormValues = { password: string } & Pick<
	UserResponseData,
	'active' | 'email' | 'firstName' | 'lastName' | 'tenantId'
>;

type Props = {
	values?: CreateUserFormValues;
	tenantId?: UUID;
	tenants: Tenant[];
	onSubmit: (values: CreateUserFormValues) => void;
	onCancel?: () => void;
};

export const CreateUserForm = ({ onSubmit, onCancel, values, tenantId, tenants }: Props) => {
	const { t } = useTranslation();

	const [firstName, setFirstName] = useState<string>('');
	const [firstNameError, setFirstNameError] = useState<boolean>(false);

	const [lastName, setLastName] = useState<UUID>('');
	const [lastNameError, setLastNameError] = useState<boolean>(false);

	const [email, setEmail] = useState<UUID>('');
	const [emailError, setEmailError] = useState<boolean>(false);

	const [password, setPassword] = useState<UUID>('');
	const [passwordError, setPasswordError] = useState<boolean>(false);
	const [passwordErrorTextKey, setPasswordErrorTextKey] = useState<string>('validationErrors.required');

	const [tenant, setTenant] = useState<UUID>();

	const handleSetFirstName = (event: ChangeEvent<HTMLInputElement>) => {
		setFirstName(event.target.value);
		setFirstNameError(false);
	};

	const handleSetLastName = (event: ChangeEvent<HTMLInputElement>) => {
		setLastName(event.target.value);
		setLastNameError(false);
	};

	const handleSetEmail = (event: ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
		setEmailError(false);
	};

	const handleSetPassword = (event: ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
		setPasswordError(false);
	};

	const handleSubmitForm = () => {
		let valid = true;

		if (firstName === '') {
			valid = false;
			setFirstNameError(true);
		}

		if (lastName === '') {
			valid = false;
			setLastNameError(true);
		}

		if (email === '') {
			valid = false;
			setEmailError(true);
		}

		if (password === '') {
			valid = false;
			setPasswordError(true);
			setPasswordErrorTextKey('validationErrors.required');
		} else if (PASSWORD_REGEX.test(password) === false) {
			valid = false;
			setPasswordError(true);
			setPasswordErrorTextKey('validationErrors.invalidPassword');
		}

		if (valid) {
			onSubmit({
				firstName: firstName,
				lastName: lastName,
				email: email,
				password: password,
				tenantId: tenantId ?? tenant,
				active: true,
			});
		}
	};

	return (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<InputField
					autoFocus
					error={firstNameError}
					helperText={firstNameError && t('validationErrors.required')}
					label={t('firstName')}
					name="firstName"
					onChange={handleSetFirstName}
				/>
			</Grid>
			<Grid item>
				<InputField
					error={lastNameError}
					helperText={lastNameError && t('validationErrors.required')}
					label={t('lastName')}
					name="lastName"
					onChange={handleSetLastName}
				/>
			</Grid>
			<Grid item>
				<InputField
					error={emailError}
					helperText={emailError && t('validationErrors.required')}
					label={t('email')}
					name="email"
					onChange={handleSetEmail}
					type="email"
				/>
			</Grid>
			<Grid item>
				<InputField
					error={passwordError}
					helperText={passwordError && t(passwordErrorTextKey)}
					label={t('password')}
					name="password"
					onChange={handleSetPassword}
					type="password"
				/>
			</Grid>
			{tenantId === undefined && (
				<Grid item>
					<InputSelectUncontrolled
						// defaultValue={defaultUserProject && { id: defaultUserProject.id, label: defaultUserProject.name }}
						onChanged={(value) => {
							setTenant(value?.id);
						}}
						error={false}
						options={
							tenants.map((tenant) => ({
								id: tenant.id,
								label: tenant.name,
							})) ?? []
						}
						label={t('selectTenant')}
					/>
				</Grid>
			)}
			<Grid item>
				<ModalDialogFormActions>
					<Button
						onClick={handleSubmitForm}
						startIcon={<SaveIcon />}
						type="submit"
						data-cy="btn-submit:userForm"
					>
						{t('save')}
					</Button>
					{onCancel && (
						<Button onClick={onCancel} variant="text">
							{t('cancel')}
						</Button>
					)}
				</ModalDialogFormActions>
			</Grid>
		</Grid>
	);
};

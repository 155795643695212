import { apiClient, apiClientForConfirmation } from '../client';
import { UUID } from '../../types';
import {
	Pipeline,
	PipelinesResponseData,
	CreatePipelineRequestBody,
	PipelineRule,
	CreatePipelineRuleRequestBody,
	UpdatePipelineRuleRequestBody,
} from '../../pages/Pipelines/pipelines-types';
import { FiltersRequestBody2 } from '../../components/Table/types';
import { JobsResponseData } from '../../pages/Jobs/jobs-types';

const baseUrlJobs = '/dispatcher.net/jobs';
const baseUrlPipelines = '/dispatcher.net/pipelines';
// const baseUrlPipelineRules = '/dispatcher.net/pipeline-rules';

export const fetchPipelines = (body: FiltersRequestBody2) =>
	apiClient.post<PipelinesResponseData>(`${baseUrlPipelines}/filter`, body);

export const createPipeline = (body: CreatePipelineRequestBody) => apiClient.post<Pipeline>(baseUrlPipelines, body);

export const editPipeline = (body: CreatePipelineRequestBody, pipelineId: UUID) =>
	apiClient.put<Pipeline>(`${baseUrlPipelines}/${pipelineId}`, body);

export const deletePipeline = (pipelineId: UUID) => apiClient.delete(`${baseUrlPipelines}/${pipelineId}`);

export const getFilteredJobs = (body: FiltersRequestBody2) =>
	apiClient.post<JobsResponseData>(`${baseUrlJobs}/filter`, body);

export const createPipelineRule = (body: CreatePipelineRuleRequestBody) =>
	apiClient.post<PipelineRule>(`${baseUrlPipelines}/${body.pipelineId}/rules`, body);

export const updatePipelineRule = (body: UpdatePipelineRuleRequestBody, pipelineId: UUID, pipelineRuleId: UUID) =>
	apiClient.put<PipelineRule>(`${baseUrlPipelines}/${pipelineId}/rules/${pipelineRuleId}`, body);

export const updatePipelineRuleContactGroups = (body: UUID[], pipelineId: UUID, pipelineRuleId: UUID) =>
	apiClient.post<PipelineRule>(`${baseUrlPipelines}/${pipelineId}/rules/${pipelineRuleId}/groups`, body);

export const deletePipelineRule = (pipelineId: UUID, pipelineRuleId: UUID) =>
	apiClient.delete<void>(`${baseUrlPipelines}/${pipelineId}/rules/${pipelineRuleId}`);

//

export const jobConfirmation = (jobId: UUID, contactId: UUID) =>
	apiClientForConfirmation.get<string>(`${baseUrlJobs}/confirmation/${jobId}/${contactId}`);

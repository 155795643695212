import React, { useState, useEffect } from 'react';
import { MainMenu } from '../MainMenu/MainMenu';
import { AppBar } from '../AppBar/AppBar';
import { Children } from '../../types';
import { useMediaQuery } from '@material-ui/core';
import { BREAKPOINTS } from '../../helpers/generalConstants';
import { Content } from './Content';

type Props = {
	title: string;
	subTitle?: string;
	children: Children;
};

export const DrawerLayout = ({ children, title, subTitle }: Props) => {
	const [menuOpen, setMenuOpen] = useState(true);
	const matchesXs = useMediaQuery(BREAKPOINTS.xs);
	const matchesMd = useMediaQuery(BREAKPOINTS.md);
	const isOnMobile = matchesXs && !matchesMd;

	useEffect(() => {
		// While resizing, close menu on mobiles and open on desktops
		setMenuOpen(isOnMobile ? false : true);
	}, [isOnMobile]);

	const handleToggleMenu = () => {
		setMenuOpen((prevState) => !prevState);
	};

	return (
		<>
			<AppBar menuOpen={menuOpen} title={title} subTitle={subTitle} toggleMenu={handleToggleMenu} />
			<MainMenu menuOpen={menuOpen} toggleMenu={handleToggleMenu} />
			<Content>{children}</Content>
		</>
	);
};

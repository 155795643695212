import React from 'react';
import { Form } from '../../../components/Form/Form';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { FormControl } from '@material-ui/core';
import { Button } from '../../../components/Button/Button';
import { InputField } from '../../../components/Form/InputField';
import * as yup from 'yup';
import SaveIcon from '@material-ui/icons/Save';
import { ModalDialogFormActions } from '../../../components/ModalDialog/ModalDialogFormActions';
import { yupResolver } from '@hookform/resolvers';
import { CreateDeviceTypePayload } from '../../Devices/devices-types';

type DeviceTypeFormValues = CreateDeviceTypePayload;

type Props = {
	onSubmit: (values: DeviceTypeFormValues) => void;
	onCancel: () => void;
	defaultValues?: DeviceTypeFormValues;
};

export const DeviceTypeForm = ({
	onSubmit,
	onCancel,
	defaultValues = {
		name: '',
	},
}: Props) => {
	const { t } = useTranslation();
	const validationSchema = yup.object().shape({
		name: yup.string().required(t('validationErrors.required')),
	});

	const { handleSubmit, errors, control } = useForm<DeviceTypeFormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues,
	});

	const handleSubmitForm = (values: DeviceTypeFormValues) => {
		onSubmit(values);
	};

	return (
		<Form onSubmit={handleSubmit(handleSubmitForm)} testId="deviceTypeForm">
			<FormControl fullWidth margin="normal">
				<InputField
					autoFocus
					error={Boolean(errors.name)}
					helperText={errors.name?.message}
					label={t('name')}
					name="name"
					control={control}
				/>
			</FormControl>
			<ModalDialogFormActions>
				<Button startIcon={<SaveIcon />} type="submit" data-cy="btn-submit:deviceTypeForm">
					{t('save')}
				</Button>
				<Button onClick={onCancel} variant="text" data-cy="btn-cancel:deviceTypeForm">
					{t('cancel')}
				</Button>
			</ModalDialogFormActions>
		</Form>
	);
};

import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Button } from '../../../components/Button/Button';
import { InputField } from '../../../components/Form/InputField';
import SaveIcon from '@material-ui/icons/Save';
import { ModalDialogFormActions } from '../../../components/ModalDialog/ModalDialogFormActions';
import { EditContactRequestBody } from '../contacts-types';
import { UUID } from '../../../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/types';
import { UserState } from '../../../store/user/user-types';

export type ContactFormValues = {
	id?: UUID;
	tenantId: UUID;
	titleBefore: string;
	titleAfter: string;
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
};

type Props = {
	onSubmit: (values: ContactFormValues) => void;
	onCancel: () => void;
	defaultValues?: EditContactRequestBody;
};

export const ContactForm = ({ onSubmit, onCancel, defaultValues }: Props) => {
	const { t } = useTranslation();

	const { tenant } = useSelector<RootState, UserState>((state) => state.user);

	const [firstName, setFirstName] = useState<string>(defaultValues?.firstName ?? '');
	const [firstNameError, setFirstNameError] = useState<boolean>(false);

	const [lastName, setLastName] = useState<string>(defaultValues?.lastName ?? '');
	const [lastNameError, setLastNameError] = useState<boolean>(false);

	const [titleBefore, setTitleBefore] = useState<string>(defaultValues?.titleBefore ?? '');

	const [titleAfter, setTitleAfter] = useState<string>(defaultValues?.titleAfter ?? '');

	const [email, setEmail] = useState<string>(defaultValues?.email ?? '');
	// const [emailError, setEmailError] = useState<boolean>(false);

	const [phone, setPhone] = useState<string>(defaultValues?.phone ?? '');
	// const [phoneError, setPhoneError] = useState<boolean>(false);

	const handleSetFirstName = (event: ChangeEvent<HTMLInputElement>) => {
		setFirstName(event.target.value);
		setFirstNameError(false);
	};

	const handleSetLastName = (event: ChangeEvent<HTMLInputElement>) => {
		setLastName(event.target.value);
		setLastNameError(false);
	};

	const handleSetEmail = (event: ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
		// setEmailError(false);
	};

	const handleSetPhone = (event: ChangeEvent<HTMLInputElement>) => {
		setPhone(event.target.value);
		// setPhoneError(false);
	};

	function handleSubmitForm() {
		let valid = true;

		if (firstName === '') {
			valid = false;
			setFirstNameError(true);
		}

		if (lastName === '') {
			valid = false;
			setLastNameError(true);
		}

		// if (email === '') {
		// 	valid = false;
		// 	setEmailError(true);
		// }

		// if (phone === '') {
		// 	valid = false;
		// 	setPhoneError(true);
		// }

		if (valid) {
			onSubmit({
				id: defaultValues?.id,
				firstName: firstName,
				lastName: lastName,
				titleBefore: titleBefore,
				titleAfter: titleAfter,
				email: email,
				phone: phone,
				tenantId: tenant?.id ?? '', // TODO
			});
		}
	}

	return (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<InputField
					autoFocus
					value={firstName}
					error={firstNameError}
					helperText={firstNameError && t('validationErrors.required')}
					label={t('firstName')}
					name="firstName"
					onChange={handleSetFirstName}
				/>
			</Grid>
			<Grid item>
				<InputField
					value={lastName}
					error={lastNameError}
					helperText={lastNameError && t('validationErrors.required')}
					label={t('lastName')}
					name="lastName"
					onChange={handleSetLastName}
				/>
			</Grid>
			<Grid item>
				<InputField
					value={titleBefore}
					error={false}
					required={false}
					label={t('titleBefore')}
					name="titleBefore"
					onChange={(event: ChangeEvent<HTMLInputElement>) => setTitleBefore(event.target.value)}
				/>
			</Grid>
			<Grid item>
				<InputField
					value={titleAfter}
					error={false}
					required={false}
					label={t('titleAfter')}
					name="titleAfter"
					onChange={(event: ChangeEvent<HTMLInputElement>) => setTitleAfter(event.target.value)}
				/>
			</Grid>
			<Grid item>
				<InputField
					value={email}
					error={false}
					required={false}
					// helperText={emailError && t('validationErrors.required')}
					label={t('email')}
					name="email"
					onChange={handleSetEmail}
					type="email"
				/>
			</Grid>
			<Grid item>
				<InputField
					value={phone}
					error={false}
					required={false}
					// helperText={phoneError && t('validationErrors.required')}
					label={t('phone')}
					name="phone"
					onChange={handleSetPhone}
					type="tel"
				/>
			</Grid>
			<Grid item>
				<ModalDialogFormActions>
					<Button startIcon={<SaveIcon />} onClick={handleSubmitForm} data-cy="btn-submit:contactForm">
						{t('save')}
					</Button>
					<Button onClick={onCancel} variant="text" data-cy="btn-cancel:contactForm">
						{t('cancel')}
					</Button>
				</ModalDialogFormActions>
			</Grid>
		</Grid>
	);
};

import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';
import { WorkflowsTable } from './components/WorkflowsTable';
import { useDispatch, useSelector } from 'react-redux';
import { WorkflowsState } from '../../store/workflows/workflows-types';
import { RootState } from '../../store/types';
import { fetchWorkflows } from '../../store/workflows/workflows-async-actions';
import { UserState } from '../../store/user/user-types';

export const Workflows = () => {
	const dispatch = useDispatch();
	const { data, total, limit, offset, orderings, filtersAndConditions } = useSelector<RootState, WorkflowsState>(
		(state) => state.workflows,
	);

	const { project } = useSelector<RootState, UserState>((state) => state.user);

	useEffect(() => {
		if (project?.id) {
			dispatch(
				fetchWorkflows({
					limit,
					offset,
					orderings,
					filtersAndConditions,
				}),
			);
		}

		// Don't need to react on "config" and "filters" and deep comparision is not necessary
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, project]);

	const adjustedData = data.map(({ id, active, name, description }) => {
		return {
			id,
			active,
			name,
			description,
		};
	});

	return (
		<Grid container spacing={2} justify="center">
			<Grid item xs={12} lg={10}>
				<Paper>
					<WorkflowsTable
						data={adjustedData}
						total={total}
						offset={offset}
						limit={limit}
						orderings={orderings}
						filters={filtersAndConditions}
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};

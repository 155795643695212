import { apiClient } from '../client';
import { TelemetryResponseData } from '../../pages/Devices/telemetry-types';
import { UUID } from '../../types';
import { FiltersRequestBody2 } from '../../components/Table/types';

export const getTelemetry = (body: { projectId: UUID }) =>
	apiClient.post<TelemetryResponseData>('/telemetry.net/influx', body);

export const getFilteredTelemetry = (body: FiltersRequestBody2) =>
	apiClient.post<TelemetryResponseData>('/telemetry.net/influx/filter', body);

export const getGroupedFilteredTelemetry = (body: FiltersRequestBody2, range: number) =>
	apiClient.post<TelemetryResponseData>(`/telemetry.net/influx/filterGroup/${range}`, body);

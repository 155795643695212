import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '../Button/Button';
import { ModalDialogProps } from './types';
import { useDispatch } from 'react-redux';
import { removeModalDialog } from '../../store/app/app-actions';
import { Typography, makeStyles, IconButton, Tooltip } from '@material-ui/core';
import { BREAKPOINT_KEYS } from '../../helpers/generalConstants';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
	modal: {
		minWidth: `calc(100% - ${spacing(4)}px)`,
		[breakpoints.up(BREAKPOINT_KEYS.sm)]: {
			minWidth: `calc(100% - ${spacing(6)}px)`,
		},
		[breakpoints.up(BREAKPOINT_KEYS.md)]: {
			minWidth: '50%',
		},
		[breakpoints.up(BREAKPOINT_KEYS.lg)]: {
			minWidth: '600px',
		},
	},
	closeButton: {
		position: 'absolute',
		right: spacing(1),
		top: spacing(1),
	},
}));

type Props = ModalDialogProps;

export const ModalDialog = ({
	id,
	title,
	contentText,
	content,
	buttons,
	onClose,
	showCloseIcon = true,
	disableBackdropClick = false,
}: Props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const handleClose = () => {
		if (onClose) {
			onClose(id);
		}

		dispatch(removeModalDialog(id));
	};

	return (
		<Dialog
			classes={{
				paper: classes.modal,
			}}
			id={id}
			open
			onClose={handleClose}
			aria-labelledby={id}
			PaperProps={{ component: 'aside' }}
			disableBackdropClick={disableBackdropClick}
		>
			<DialogTitle id={id} disableTypography>
				{showCloseIcon && (
					<Tooltip title={t('close') as string}>
						<IconButton className={classes.closeButton} onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					</Tooltip>
				)}
				<Typography component="h1" variant="h5">
					{title}
				</Typography>
			</DialogTitle>
			<DialogContent>
				{contentText && <DialogContentText>{contentText}</DialogContentText>}
				{content}
			</DialogContent>

			{buttons && (
				<DialogActions>
					{buttons.map(({ id, value, ...restProps }) => (
						<Button key={id} data-cy={`btn-delete:${id}`} {...restProps}>
							{value}
						</Button>
					))}
				</DialogActions>
			)}
		</Dialog>
	);
};

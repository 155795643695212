import React from 'react';
import { Table } from '../../../components/Table/Table';
import { useTranslation } from 'react-i18next';

import { FilterOperators, FilterType, TableRowActionIcon } from '../../../components/Table/constants';
import { useDispatch, useSelector } from 'react-redux';
import { addModalDialog, removeModalDialog, setRedirectUrl } from '../../../store/app/app-actions';
import { PipelineForm, PipelineFormValues } from './PipelineForm';
import { UUID } from '../../../types';
import { Tooltip, TableRow, TableCell, CardHeader, IconButton, Divider } from '@material-ui/core';
import {
	TableColumnFilter,
	TableColumnSort,
	TableHeadCell,
	TableRowRenderProps,
} from '../../../components/Table/types';
import { Add, DeleteForever } from '@material-ui/icons';
import { UserState } from '../../../store/user/user-types';
import { PermissionsDotNet } from '../../../helpers/generalConstants';
import { RootState } from '../../../store/types';
import { hasPermission } from '../../../helpers/auth';
import {
	createPipeline,
	deletePipeline,
	editPipeline,
	fetchPipelines,
} from '../../../store/pipelines/pipelines-async-actions';
import { Pipeline } from '../pipelines-types';
import { RouteEnum } from '../../../router/Routes';

type Props = {
	data: Pipeline[];
	total: number;
	limit: number;
	offset: number;
	orderings?: TableColumnSort[];
	filters?: TableColumnFilter[];
};

export const PipelinesTable = ({ data, total, offset, limit, orderings, filters }: Props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { permissions } = useSelector<RootState, UserState>((state) => state.user);
	const canEditPipelines = hasPermission(PermissionsDotNet.PipelineWrite, permissions);

	const nameOrder = orderings?.find((order) => order.column === 'name');
	const nameFilter = filters?.find((filter) => filter.column === 'name');

	const columns: TableHeadCell[] = [
		{
			id: 'name',
			label: t('name'),
			filters: nameFilter && [
				{
					column: 'name',
					operator: FilterOperators.contains,
					value: nameFilter.value,
				},
			],
			filterType: FilterType.String,
			sort: nameOrder?.sortOrder && { column: 'name', sortOrder: nameOrder?.sortOrder },
		},
	];

	const handleCloseModal = (id: string) => {
		dispatch(removeModalDialog(id));
	};

	const handleCreatePipeline = (data: PipelineFormValues, modalDialogId: string) => {
		handleCloseModal(modalDialogId);
		dispatch(createPipeline(data));
	};

	const handleEditPipeline = (data: PipelineFormValues, modalId: string) => {
		if (data.id) {
			dispatch(editPipeline({ ...data, id: data.id }));
		}

		handleCloseModal(modalId);
	};

	const handleDeletePipeline = (pipelineId: UUID, modalDialogId: string) => {
		handleCloseModal(modalDialogId);
		dispatch(deletePipeline(pipelineId));
	};

	const rowActions = [
		{
			id: 'btn-edit:pipeline',
			icon: TableRowActionIcon.edit,
			tooltip: t('edit'),
			onClick: (pipeline: Pipeline) => {
				dispatch(
					addModalDialog({
						id: 'editPipelineModal',
						title: t('editPipelineModalTitle'),
						content: (
							<PipelineForm
								onSubmit={(values) => handleEditPipeline(values, 'editPipelineModal')}
								onCancel={() => handleCloseModal('editPipelineModal')}
								defaultValues={pipeline}
							/>
						),
					}),
				);
			},
		},
		{
			id: 'btn-delete:pipeline',
			icon: TableRowActionIcon.delete,
			tooltip: t('delete'),
			onClick: (pipeline: Pipeline) =>
				dispatch(
					addModalDialog({
						id: 'deletePipelineModal',
						title: t('deletePipelineModalTitle'),
						contentText: t('deletePipelineModalDescription', {
							pipeline: pipeline.name,
						}),
						buttons: [
							{
								id: 'deletePipeline',
								value: t('delete'),
								onClick: () => handleDeletePipeline(pipeline.id, 'deletePipelineModal'),
								startIcon: <DeleteForever />,
							},
							{
								id: 'cancelPipeline',
								value: t('cancel'),
								onClick: () => handleCloseModal('deletePipelineModal'),
								variant: 'text',
							},
						],
					}),
				),
		},
		{
			id: 'btn-detail:pipeline',
			icon: TableRowActionIcon.detail,
			tooltip: t('pipelineDetail'),
			onClick: (pipeline: Pipeline) => {
				dispatch(setRedirectUrl(`${RouteEnum.PIPELINES}/${pipeline.id}`));
			},
		},
	];

	const renderRow = ({ row: pipeline, renderActions }: TableRowRenderProps<Pipeline>) => {
		return (
			<TableRow key={pipeline.id} id={`row-${pipeline.id}`}>
				<TableCell>{pipeline.name}</TableCell>
				{canEditPipelines && (
					<TableCell padding="none" sortDirection={false}>
						{renderActions(rowActions, pipeline)}
					</TableCell>
				)}
			</TableRow>
		);
	};

	return (
		<>
			<CardHeader
				title={t('pipelines')}
				action={
					<>
						{canEditPipelines && (
							<Tooltip title={String(t('createPipeline'))} aria-label={t('createPipeline')}>
								<IconButton
									onClick={() =>
										dispatch(
											addModalDialog({
												id: 'createPipelineModal',
												title: t('createPipelineModalTitle'),
												content: (
													<PipelineForm
														onSubmit={(values) =>
															handleCreatePipeline(values, 'createPipelineModal')
														}
														onCancel={() => handleCloseModal('createPipelineModal')}
													/>
												),
											}),
										)
									}
									data-cy="btn-create:pipeline"
								>
									<Add />
								</IconButton>
							</Tooltip>
						)}
					</>
				}
			/>
			<Divider light />
			<Table
				actions={canEditPipelines ? rowActions : undefined}
				columns={columns}
				orderings={orderings}
				filtersAndConditions={filters}
				data={data}
				dataLimit={limit}
				dataOffset={offset}
				renderTableRow={renderRow}
				numberOfRows={total}
				fetchAction2={fetchPipelines}
				selectable={false}
			/>
		</>
	);
};

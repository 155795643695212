import { apiClient } from '../client';
import { UUID } from '../../types';
import { Contact, ContactsResponseData, CreateContactRequestBody } from '../../pages/Contacts/contacts-types';
import { FiltersRequestBody2 } from '../../components/Table/types';

const baseUrlContacts = '/dispatcher.net/contacts';

export const fetchContacts = (body: FiltersRequestBody2) =>
	apiClient.post<ContactsResponseData>(`${baseUrlContacts}/filter`, body);

export const createContact = (body: CreateContactRequestBody) => apiClient.post<Contact>(baseUrlContacts, body);

export const editContact = (body: CreateContactRequestBody, contactId: UUID) =>
	apiClient.put<Contact>(`${baseUrlContacts}/${contactId}`, body);

export const deleteContact = (contactId: UUID) => apiClient.delete(`${baseUrlContacts}/${contactId}`);

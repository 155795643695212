export const INGESTION_COMMON_EMPTY_REQUEST = '[INGESTION_ENDPOINTS_ACTIONS] INGESTION_COMMON_EMPTY_REQUEST';
export const INGESTION_COMMON_EMPTY_ERROR = '[INGESTION_ENDPOINTS_ACTIONS] INGESTION_COMMON_EMPTY_ERROR';
export const FETCH_INGESTION_ENDPOINTS_REQUEST = '[INGESTION_ENDPOINTS_ACTIONS] FETCH_INGESTION_ENDPOINTS_REQUEST';
export const FETCH_INGESTION_ENDPOINTS_SUCCESS = '[INGESTION_ENDPOINTS_ACTIONS] FETCH_INGESTION_ENDPOINTS_SUCCESS';
export const FETCH_INGESTION_ENDPOINTS_ERROR = '[INGESTION_ENDPOINTS_ACTIONS] FETCH_INGESTION_ENDPOINTS_ERROR';
export const EDIT_INGESTION_ENDPOINT_REQUEST = '[INGESTION_ENDPOINTS_ACTIONS] EDIT_INGESTION_ENDPOINT_REQUEST';
export const EDIT_INGESTION_ENDPOINT_SUCCESS = '[INGESTION_ENDPOINTS_ACTIONS] EDIT_INGESTION_ENDPOINT_SUCCESS';
export const EDIT_INGESTION_ENDPOINT_ERROR = '[INGESTION_ENDPOINTS_ACTIONS] EDIT_INGESTION_ENDPOINT_ERROR';
export const CREATE_INGESTION_ENDPOINT_REQUEST = '[INGESTION_ENDPOINTS_ACTIONS] CREATE_INGESTION_ENDPOINT_REQUEST';
export const CREATE_INGESTION_ENDPOINT_SUCCESS = '[INGESTION_ENDPOINTS_ACTIONS] CREATE_INGESTION_ENDPOINT_SUCCESS';
export const CREATE_INGESTION_ENDPOINT_ERROR = '[INGESTION_ENDPOINTS_ACTIONS] CREATE_INGESTION_ENDPOINT_ERROR';
export const DELETE_INGESTION_ENDPOINT_REQUEST = '[INGESTION_ENDPOINTS_ACTIONS] DELETE_INGESTION_ENDPOINT_REQUEST';
export const DELETE_INGESTION_ENDPOINT_SUCCESS = '[INGESTION_ENDPOINTS_ACTIONS] DELETE_INGESTION_ENDPOINT_SUCCESS';
export const DELETE_INGESTION_ENDPOINT_ERROR = '[INGESTION_ENDPOINTS_ACTIONS] DELETE_INGESTION_ENDPOINT_ERROR';
export const FETCH_INGESTION_SCRIPTS_SUCCESS = '[INGESTION_ENDPOINTS_ACTIONS] FETCH_INGESTION_SCRIPTS_SUCCESS';
export const CREATE_INGESTION_SCRIPT_SUCCESS = '[INGESTION_ENDPOINTS_ACTIONS] CREATE_INGESTION_SCRIPT_SUCCESS';
export const EDIT_INGESTION_SCRIPT_SUCCESS = '[INGESTION_ENDPOINTS_ACTIONS] EDIT_INGESTION_SCRIPT_SUCCESS';
export const DELETE_INGESTION_SCRIPT_SUCCESS = '[INGESTION_ENDPOINTS_ACTIONS] DELETE_INGESTION_SCRIPT_SUCCESS';
//
export const FETCH_DOTNET_INGESTION_ENDPOINTS_SUCCESS =
	'[INGESTION_ENDPOINTS_ACTIONS] FETCH_DOTNET_INGESTION_ENDPOINTS_SUCCESS';
export const EDIT_DOTNET_INGESTION_ENDPOINT_SUCCESS =
	'[INGESTION_ENDPOINTS_ACTIONS] EDIT_DOTNET_INGESTION_ENDPOINT_SUCCESS';
export const CREATE_DOTNET_INGESTION_ENDPOINT_SUCCESS =
	'[INGESTION_ENDPOINTS_ACTIONS] CREATE_DOTNET_INGESTION_ENDPOINT_SUCCESS';
export const DELETE_DOTNET_INGESTION_ENDPOINT_SUCCESS =
	'[INGESTION_ENDPOINTS_ACTIONS] DELETE_DOTNET_INGESTION_ENDPOINT_SUCCESS';

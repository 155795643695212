import React, { useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { InfoCard } from '../../components/InfoCard/InfoCard';
import { BackButton } from '../../components/Button/BackButton';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import { UUID } from '../../types';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { ReportOrder, TextResult } from './review-types';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useTranslation } from 'react-i18next';
import { GridApi, GridReadyEvent, ProcessCellForExportParams } from 'ag-grid-community';
import { LanguagesEnum, STORAGE_KEYS } from '../../helpers/generalConstants';
import { BaseWithValueColDefParams } from 'ag-grid-community/dist/lib/entities/colDef';

const useStyles = makeStyles((theme) => ({
	root: {
		whiteSpace: 'pre-line',
	},
	paper: {
		padding: theme.spacing(3), //grid padding
		// textAlign: 'center',
		// color: theme.palette.text.secondary,
		boxShadow: '2px 2px 2px rgba(0,0,0,0.05)',
	},
}));

type RouteParams = {
	reportOrderId: UUID;
};

interface GridApiHash {
	[key: string]: GridApi;
}

export const ReportDetail = () => {
	let lan = localStorage.getItem(STORAGE_KEYS.LANG) || LanguagesEnum.EN;

	if (lan === LanguagesEnum.CZ) {
		lan = 'cs';
	}

	const { reportOrderId } = useParams<RouteParams>();

	const classes = useStyles();
	const { t } = useTranslation();

	const reportOrder = useSelector<RootState, ReportOrder | undefined>((state) =>
		state.review.data?.find((order) => order.id === reportOrderId),
	);

	const [gridApi, setGridApi] = useState<GridApiHash>({});

	let reportOrderJson: TextResult | undefined = undefined;

	try {
		reportOrderJson = reportOrder?.result && JSON.parse(reportOrder.result);

		console.log(reportOrderJson);
	} catch (exception) {
		console.log(exception);
	}

	const drawTextResult = () => {
		const texts: JSX.Element[] = [];

		reportOrderJson && setElements(reportOrderJson, texts);

		return texts;
	};

	const setElements = (textResult: TextResult, texts: JSX.Element[]) => {
		const text = handleText(textResult.text);
		const style = textResult.style;

		// console.log(`text: ${text}`);
		// console.log(`style: ${style}`);

		if (style === 'div') {
			texts.push(<div className={classes.root}>{text}</div>);
		} else if (style === 'header1') {
			texts.push(
				<Typography variant="h4" color="inherit">
					{text}
				</Typography>,
			);
		} else if (style === 'header2') {
			texts.push(
				<Typography variant="h5" color="inherit">
					{text}
				</Typography>,
			);
		} else if (style === 'header3') {
			texts.push(
				<Typography variant="h6" color="inherit">
					{text}
				</Typography>,
			);
		} else if (style === 'paragraph') {
			texts.push(
				<Typography variant="body1" color="inherit">
					{text}
				</Typography>,
			);
		} else if (style === 'table') {
			texts.push(
				<Typography variant="body1" color="inherit">
					{textResult.text}
				</Typography>,
			);

			texts.push(handleTable(textResult));

			return;
		} else if (style === 'tableRow') {
			console.log('Unexpected textReview style: tableRow');

			return;
		} else if (style === 'tableCell') {
			console.log('Unexpected textReview style: tableCell');

			return;
		} else if (style === 'tableColumns') {
			console.log('Unexpected textReview style: tableColumns');

			return;
		} else if (style === 'tableColumn') {
			console.log('Unexpected textReview style: tableColumn');

			return;
		} else {
			texts.push(<div className={classes.root}>{text}</div>);
		}

		// console.log(textResult.contents);

		// if (textResult.contents.$values) {
		// 	textResult.contents.$values.forEach((content) => setElements(content, texts));
		// }

		if (textResult.contents) {
			textResult.contents.forEach((content) => setElements(content, texts));
		}
	};

	const handleTable = (textResult: TextResult) => {
		let columns: { key: string; name: string; frozen: boolean }[] = [];
		const rows: any[] = [];

		textResult.contents &&
			textResult.contents.forEach((content) => {
				if (content.style === 'tableColumns') {
					columns = handleTableColumns(content);
				} else if (content.style === 'tableRow') {
					if (columns) {
						rows.push(handleTableRow(content, columns));
					} else {
						console.log('Columns not available.');
					}
				} else {
					console.log('Unexpected textReview table style.');
				}
			});

		let height = rows.length * 43 + 65;

		if (height > 600) {
			height = 600;
		}

		const onGridReady = (params: GridReadyEvent) => {
			params.columnApi.autoSizeAllColumns();
			gridApi[textResult.text] = params.api;
			setGridApi({ ...gridApi });
		};

		const onBtnExport = () => {
			gridApi[textResult.text]?.exportDataAsCsv({
				// columnSeparator: ',',
				processCellCallback: (params: ProcessCellForExportParams) => {
					return handleText(params.value);
				},
			});
		};

		return (
			<>
				<div style={{ margin: '10px 0' }}>
					<button onClick={() => onBtnExport()}>Export to CSV</button>
				</div>
				<div
					className="ag-theme-alpine"
					style={{
						height: `${height}px`,
					}}
				>
					<AgGridReact
						rowData={rows}
						suppressMenuHide
						suppressExcelExport
						popupParent={document.body}
						onGridReady={onGridReady}
					>
						{columns.map((column) => (
							<AgGridColumn
								key={column.key}
								field={column.key}
								pinned={column.frozen}
								headerName={column.name}
								sortable
								filter
								resizable
								// flex={135}
								// width={135}
								minWidth={59}
								valueFormatter={universalFormatter}
								comparator={universalComparator}
							></AgGridColumn>
						))}
					</AgGridReact>
				</div>
			</>
		);
	};

	const universalComparator = (valueA: string, valueB: string): number => {
		// console.log(`${valueA} : ${valueB}`);

		if (valueA.length >= 5 && valueA[2] === ':') {
			if (valueB.length >= 5 && valueB[2] === ':') {
				const typeA = valueA[1];
				const typeB = valueB[1];

				if (typeA === typeB) {
					const valueAstring = valueA.substring(3, valueA.length - 1);
					const valueAnumber = parseFloat(valueAstring);

					if (!isNaN(valueAnumber)) {
						const valueBstring = valueB.substring(3, valueB.length - 1);
						const valueBnumber = parseFloat(valueBstring);

						if (!isNaN(valueBnumber)) {
							return valueAnumber > valueBnumber ? -1 : valueAnumber < valueBnumber ? 1 : 0;
						}
					}
				}
			}
		}

		return (valueA as string).localeCompare(valueB as string);
	};

	const universalFormatter = function (params: BaseWithValueColDefParams): string {
		return handleText(params.value);
	};

	const handleTableColumns = (textResult: TextResult) => {
		if (textResult.contents) {
			return textResult.contents.map((content, index) => handleTableColumn(content));
		}

		return [];
	};

	const handleTableColumn = (textResult: TextResult) => {
		let fixed = false;

		if (textResult.contents) {
			textResult.contents.forEach((content, index) => {
				if (content.style === 'tableColumnFixed') {
					fixed = true;
				}
			});
		}

		const name = handleText(textResult.text);

		// if (
		// 	textResult.text &&
		// 	textResult.text.length > 2 &&
		// 	textResult.text.startsWith('{') &&
		// 	textResult.text.endsWith('}')
		// ) {
		// 	const length = textResult.text.length;

		// 	name = t(textResult.text.substring(1, length - 1));
		// }

		return {
			key: textResult.text,
			name: name,
			resizable: true,
			// sortable: index === 3,
			frozen: fixed,
		};
	};

	const handleTableRow = (textResult: TextResult, columns: { key: string; name: string }[]) => {
		if (textResult.contents) {
			const result: any = {};

			textResult.contents.forEach((content, index) => {
				const key = columns[index].key;

				result[key] = content.text; // handleText(content.text);
			});

			return result;
		}

		return {};
	};

	const handleText = (text?: string) => {
		if (text === undefined || text === null || text === '') {
			return '';
		}

		let processing = false;
		let searchValue = '';
		const searchValues: string[] = [];

		for (let i = 0; i < text.length; i++) {
			const ch = text[i];

			if (ch === '{') {
				searchValue = '';
				processing = true;
			} else if (processing && ch === '}') {
				searchValue += ch;
				searchValues.push(searchValue);
				processing = false;
			}

			if (processing) {
				searchValue += ch;
			}
		}

		let newText = text;

		searchValues.forEach((searchValue) => {
			const replaceValue = handleSearchValue(searchValue);

			if (replaceValue) {
				newText = newText.replace(searchValue, replaceValue);
			}
		});

		return newText;
	};

	// P:  percento , vystup X% mpriklad {0.25} => 25%
	// L:  vzdialenost vstup metre , vystup X km Y mpriklad {L:2345.6} => 2 km 346m
	// S: timespan vstup minuty vystup Xh Ympriklad {S:987} => 16h 27m
	const handleSearchValue = (searchValue: string) => {
		if (searchValue.length >= 5 && searchValue[2] === ':') {
			if (searchValue[1] === 'P') {
				const percentage = searchValue.substring(3, searchValue.length - 1);

				const percentageNumber = parseFloat(percentage);

				if (isNaN(percentageNumber)) {
					return percentage;
				}

				const perc = Math.round(percentageNumber * 100);

				return `${perc}%`;
			} else if (searchValue[1] === 'L') {
				const length = searchValue.substring(3, searchValue.length - 1);

				const lengthNumber = parseFloat(length);

				if (isNaN(lengthNumber)) {
					return length;
				}

				const km = Math.floor(lengthNumber);
				const m = Math.floor((lengthNumber - km) * 1000);

				return `${km}km ${m}m`;
			} else if (searchValue[1] === 'S') {
				const timeSpan = searchValue.substring(3, searchValue.length - 1);

				const timeSpanNumber = parseFloat(timeSpan);

				if (isNaN(timeSpanNumber)) {
					return timeSpan;
				}

				const h = Math.floor(timeSpanNumber);
				const m = Math.floor((timeSpanNumber - h) * 60);

				return `${h}h ${m}m`;
			} else if (searchValue[1] === 'K') {
				const key = searchValue.substring(3, searchValue.length - 1);

				return t(key);
			} else if (searchValue[1] === 'D') {
				const date = searchValue.substring(3, searchValue.length - 1);

				try {
					return new Date(date).toLocaleDateString(lan);
				} catch (exception) {
					return date;
				}
			} else if (searchValue[1] === 'T') {
				const date = searchValue.substring(3, searchValue.length - 1);

				try {
					return new Date(date).toLocaleString(lan);
				} catch (exception) {
					return date;
				}
			}
		}

		return undefined;
	};

	// const testtext =
	// 	'text {K:type}text {pokracu{j}e {K:attachement} datum:{D:2021-08-01T21:28:00.000Z} time:{T:2021-08-01T21:28:00.000Z}';

	// console.log(testtext);
	// console.log(handleText(testtext));

	return (
		<Grid container spacing={2} justify="center" direction="row">
			<Grid item xs={12}>
				<BackButton />
			</Grid>
			<Grid container item sm={12} lg={10} xl={10} direction="row" spacing={2}>
				<Grid item xs={12}>
					<InfoCard>{drawTextResult()}</InfoCard>
				</Grid>
			</Grid>
		</Grid>
	);
};

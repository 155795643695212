import React, { useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import MapIcon from '@material-ui/icons/Map';
import RouterIcon from '@material-ui/icons/Router';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import AccountTreeRoundedIcon from '@material-ui/icons/AccountTreeRounded';
import PaymentIcon from '@material-ui/icons/Payment';
import PeopleIcon from '@material-ui/icons/People';
import TimelineIcon from '@material-ui/icons/Timeline';
import { useHistory, useLocation } from 'react-router-dom';
import { MAIN_MENU_WIDTH_CLOSED, MAIN_MENU_WIDTH_OPEN } from '../Layout/constants';
import { RouteEnum } from '../../router/Routes';
import { useTranslation } from 'react-i18next';
import Select from '@material-ui/core/Select';
import { MenuItem, useMediaQuery, Hidden, IconButton, Typography, Box, Tooltip } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { UserState } from '../../store/user/user-types';
import { BREAKPOINTS, BREAKPOINT_KEYS, PermissionsDotNet } from '../../helpers/generalConstants';
import CloseIcon from '@material-ui/icons/Close';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import EmojiObjectsRoundedIcon from '@material-ui/icons/EmojiObjectsRounded';
import { changeProject, fetchUserProjects } from '../../store/user/user-async-actions';
import { ProjectResponseData } from '../../pages/Projects/projects-types';
import { Logo } from '../Logo/Logo';
import { LocalOfferRounded, BugReport, Scanner } from '@material-ui/icons';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { hasPermission } from '../../helpers/auth';
import BorderClearIcon from '@material-ui/icons/BorderClear';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import DepartureBoardIcon from '@material-ui/icons/DepartureBoard';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import EventNoteIcon from '@material-ui/icons/EventNote';
import WorkIcon from '@material-ui/icons/Work';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import { WorkerIcon } from '../Icons/WorkerIcon';
import { WC_DEF_NAME } from '../../pages/WorkerCare/worker-care-types';

const MAIN_MENU_ICONS = new Map([
	['dashboard', <DashboardIcon key="dashboard" />],
	['maps', <MapIcon key="maps" />],
	['devices', <RouterIcon key="devices" />],
	['zones', <BubbleChartIcon key="zones" />],
	['workflows', <AccountTreeRoundedIcon key="workflows" />],
	['billing', <PaymentIcon key="billing" />],
	['usersManagement', <PeopleIcon key="usersManagement" />],
	['ingestionEndpoints', <TimelineIcon key="ingestionEndpoints" />],
	['ingestionScripts', <ListAltIcon key="ingestionScripts" />],
	['groups', <GroupWorkIcon key="groups" />],
	['placementGroups', <GroupWorkIcon key="placementGroups" />],
	['projects', <EmojiObjectsRoundedIcon key="projects" />],
	['placements', <LocalOfferRounded key="placements" />],
	['placementsManagement', <LocalOfferRounded key="placementsManagement" />],
	['review', <AssessmentIcon key="review" />],
	['userRoles', <AssignmentIndIcon key="userRoles" />],
	['logs', <BugReport key="logs" />],
	['deviceTypes', <Scanner key="deviceTypes" />],
	['tenants', <BusinessIcon key="tenants" />],
	['inDoorMaps', <BorderClearIcon key="inDoorMaps" />],
	['warehouse', <LocalShippingIcon key="warehouse" />],
	['definitions', <SettingsInputComponentIcon key="definitions" />],
	['assetWatch', <NotListedLocationIcon key="assetWatch" />],
	['vehicleWatch', <DepartureBoardIcon key="vehicleWatch" />],
	['workerCare', <WorkerIcon key="workerCare" />],
	['contacts', <ContactMailIcon key="contacts" />],
	['contactGroups', <FolderSharedIcon key="contactGroups" />],
	['pipelines', <EventNoteIcon key="pipelines" />],
	['jobs', <WorkIcon key="jobs" />],
]);

const useStyles = makeStyles(({ breakpoints, transitions, spacing }: Theme) => ({
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: MAIN_MENU_WIDTH_OPEN.xs,
		height: '100vh',
		border: 0,
		boxShadow: '1px 0px 8px 0px rgba(0,0,0,0.22)',
		[breakpoints.up(BREAKPOINT_KEYS.md)]: {
			width: MAIN_MENU_WIDTH_OPEN.md,
		},
		transition: transitions.create('width', {
			easing: transitions.easing.sharp,
			duration: transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: transitions.create('width', {
			easing: transitions.easing.sharp,
			duration: transitions.duration.leavingScreen,
		}),
		[breakpoints.up(BREAKPOINT_KEYS.md)]: {
			width: MAIN_MENU_WIDTH_CLOSED.md,
		},
	},
	menuHeader: {
		padding: spacing(2, 2, 2, 1),
		height: spacing(8) - spacing(2) * 2,
		display: 'flex',
		alignItems: 'center',
		boxSizing: 'content-box',
		[breakpoints.up(BREAKPOINT_KEYS.md)]: {
			padding: spacing(2),
		},
	},
	closeButton: {
		flex: 'none',
	},
	formControl: {
		margin: spacing(1),
		width: '100%',
	},
}));

type Props = {
	menuOpen: boolean;
	toggleMenu: () => void;
};

export const MainMenu = ({ menuOpen, toggleMenu }: Props) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation();
	const matchesXs = useMediaQuery(BREAKPOINTS.xs);
	const matchesMd = useMediaQuery(BREAKPOINTS.md);
	const { project, projects, permissions, tenant } = useSelector<RootState, UserState>((state) => state.user);

	const MAIN_MENU_ITEMS = [
		{
			shown: true,
			id: 'dashboard',
			label: t('dashboard'),
			url: RouteEnum.DASHBOARD,
		},
		// {
		// 	shown: hasPermission(PermissionsDotNet.PlacementRead, permissions),
		// 	id: 'placements',
		// 	label: t('placements'),
		// 	url: RouteEnum.PLACEMENTS,
		// },
		{
			shown: true,
			id: 'maps',
			label: t('maps'),
			url: RouteEnum.MAPS,
		},
		// Hide until it's ready
		// {
		// 	id: 'billing',
		// 	label: t('billing'),
		// 	url: RouteEnum.BILLING,
		// },
		{
			id: 'management',
			label: t('management'),
		},
		// {
		// 	id: 'placementsManagement',
		// 	label: t('placements'),
		// 	url: RouteEnum.PLACEMENTS,
		// },
		{
			shown: hasPermission(PermissionsDotNet.PlacementRead, permissions),
			id: 'placements',
			label: t('placements'),
			url: RouteEnum.PLACEMENT_MANAGEMENT,
		},
		{
			shown: hasPermission(PermissionsDotNet.PlacementGroupRead, permissions),
			id: 'placementGroups',
			label: t('placementGroups'),
			url: RouteEnum.PLACEMENT_GROUPS,
		},
		{
			shown: hasPermission(PermissionsDotNet.ProjectRead, permissions),
			id: 'projects',
			label: t('projects'),
			url: RouteEnum.PROJECTS,
		},
		{
			shown: hasPermission(PermissionsDotNet.WorkflowRead, permissions),
			id: 'workflows',
			label: t('workflows'),
			url: RouteEnum.WORKFLOWS,
		},
		{
			shown: hasPermission(PermissionsDotNet.ReportRead, permissions),
			id: 'review',
			label: t('reports'),
			url: RouteEnum.REVIEW,
		},
		{
			shown: hasPermission(PermissionsDotNet.ZoneRead, permissions),
			id: 'zones',
			label: t('zones'),
			url: RouteEnum.ZONES,
		},
		{
			shown: hasPermission(PermissionsDotNet.IndoorMapRead, permissions),
			id: 'inDoorMaps',
			label: t('inDoorMaps'),
			url: RouteEnum.INDOOR_MAPS,
		},
		{
			shown: hasPermission(PermissionsDotNet.DeviceRead, permissions),
			id: 'devices',
			label: t('devices'),
			url: RouteEnum.DEVICES,
		},
		{
			shown: hasPermission(PermissionsDotNet.DeviceGroupRead, permissions),
			id: 'groups',
			label: t('groups'),
			url: RouteEnum.GROUPS,
		},
		{
			shown: hasPermission(PermissionsDotNet.DeviceTypeRead, permissions),
			id: 'deviceTypes',
			label: t('deviceTypes'),
			url: RouteEnum.DEVICE_TYPES,
		},
		{
			id: 'configuration',
			label: t('configuration'),
		},
		{
			shown: hasPermission(PermissionsDotNet.WarehouseRead, permissions),
			id: 'warehouse',
			label: t('warehouse'),
			url: RouteEnum.WAREHOUSE,
		},
		{
			shown: hasPermission(PermissionsDotNet.PlacementDefinition, permissions),
			id: 'definitions',
			label: t('definitions'),
			url: RouteEnum.DEFINITIONS,
		},
		{
			shown: hasPermission(PermissionsDotNet.TenantRead, permissions),
			id: 'tenants',
			label: t('tenants'),
			url: RouteEnum.TENANTS,
		},
		{
			shown: hasPermission(PermissionsDotNet.UserRead, permissions),
			id: 'usersManagement',
			label: t('users'),
			url: RouteEnum.USER_MANAGEMENT,
		},
		{
			shown: hasPermission(PermissionsDotNet.RoleRead, permissions),
			id: 'userRoles',
			label: t('userRoles'),
			url: RouteEnum.USER_ROLES,
		},
		{
			shown: hasPermission(PermissionsDotNet.ContactRead, permissions),
			id: 'contacts',
			label: t('contacts'),
			url: RouteEnum.CONTACTS,
		},
		{
			shown: hasPermission(PermissionsDotNet.ContactRead, permissions),
			id: 'contactGroups',
			label: t('contactGroups'),
			url: RouteEnum.CONTACT_GROUPS,
		},
		{
			shown: hasPermission(PermissionsDotNet.PipelineRead, permissions),
			id: 'pipelines',
			label: t('pipelines'),
			url: RouteEnum.PIPELINES,
		},
		{
			shown: hasPermission(PermissionsDotNet.PipelineRead, permissions),
			id: 'jobs',
			label: t('jobs'),
			url: RouteEnum.JOBS,
		},
		{
			shown: hasPermission(PermissionsDotNet.EndpointRead, permissions),
			id: 'ingestionEndpoints',
			label: t('ingestionEndpoints'),
			url: RouteEnum.INGESTION_ENDPOINTS,
		},
		{
			shown: hasPermission(PermissionsDotNet.ScriptRead, permissions),
			id: 'ingestionScripts',
			label: t('ingestionScripts'),
			url: RouteEnum.INGESTION_SCRIPTS,
		},
		{
			shown: true,
			id: 'logs',
			label: t('logs'),
			url: RouteEnum.LOGS,
		},
	];

	if (tenant) {
		MAIN_MENU_ITEMS.splice(2, 0, {
			id: 'carrierApps',
			label: t('carrierApps'),
		});

		if (project?.id === 'e5892f3f-7e04-485d-9c98-871923b6c8cb') {
			MAIN_MENU_ITEMS.splice(3, 0, {
				shown: true,
				id: 'vehicleWatch',
				label: t('vehicleWatch'),
				url: RouteEnum.VEHICLE_WATCH,
			});
		} else if (project?.id === '449dbf74-da4c-4b92-ae95-2b088746a63a') {
			MAIN_MENU_ITEMS.splice(3, 0, {
				shown: true,
				id: 'assetWatch',
				label: t('assetWatch'),
				url: RouteEnum.ASSET_WATCH,
			});
		} else if (tenant.id === '2d0d2f78-ef98-4216-820c-913d113e364b') {
			MAIN_MENU_ITEMS.splice(3, 0, {
				shown: true,
				id: 'workerCare',
				label: t(WC_DEF_NAME),
				url: RouteEnum.WORKER_CARE,
			});
		}
	}

	useEffect(() => {
		dispatch(fetchUserProjects());
	}, [dispatch, project]);

	const handleChangeRoute = (url: string) => {
		const isOnMobile = matchesXs && !matchesMd;

		history.push(url);

		if (isOnMobile) {
			toggleMenu();
		}
	};

	const handleChangeProject = (event: React.ChangeEvent<{ value: unknown }>) => {
		dispatch(changeProject({ projectId: event.target.value as string }));
	};

	return (
		<Drawer
			variant={matchesMd ? 'permanent' : 'temporary'}
			classes={{
				paper: clsx(classes.drawerPaper, !menuOpen && classes.drawerPaperClose),
			}}
			open={menuOpen}
		>
			<div className={classes.menuHeader}>
				<Hidden implementation="css" mdUp>
					<IconButton onClick={toggleMenu}>
						<CloseIcon />
					</IconButton>
				</Hidden>
				<Logo />
			</div>
			<Divider />
			<List id="main-menu">
				{tenant && menuOpen && (
					<ListItem>
						<FormControl className={classes.formControl}>
							<InputLabel>{t('selectedProject')}</InputLabel>
							{projects && (
								<Select value={project?.id} onChange={handleChangeProject}>
									{(projects as ProjectResponseData[]).map(({ id, name }) => (
										<MenuItem key={`project-menu-item-${id}`} value={id}>
											{name}
										</MenuItem>
									))}
								</Select>
							)}
						</FormControl>
					</ListItem>
				)}
				{MAIN_MENU_ITEMS.flatMap(({ id, label, url, shown }) => {
					if (url) {
						if (shown === undefined || shown === false) {
							return undefined;
						} else {
							return (
								<Tooltip title={menuOpen ? '' : label}>
									<ListItem
										key={id}
										button
										onClick={() => handleChangeRoute(url)}
										selected={location.pathname.includes(url)}
										data-cy={`menu-item:${id}`}
									>
										<ListItemIcon>{MAIN_MENU_ICONS.get(id)}</ListItemIcon>
										<ListItemText primary={label} />
									</ListItem>
								</Tooltip>
							);
						}
					} else {
						return menuOpen ? (
							<Box paddingLeft={'16px'} paddingTop={'16px'}>
								<Typography component="h4" variant="inherit" color="primary" noWrap>
									{label}
								</Typography>
							</Box>
						) : (
							<Divider />
						);
					}
				})}
			</List>
		</Drawer>
	);
};

import React from 'react';
import { Paper, makeStyles, Theme, Typography } from '@material-ui/core';
import { Children } from '../../types';
import clsx from 'clsx';

const useStyles = makeStyles(({ spacing }: Theme) => ({
	paper: {
		position: 'relative',
		height: '100%',
		padding: spacing(3),
		boxShadow: '2px 2px 2px rgba(0,0,0,0.05)',
	},
}));

type Props = {
	title?: string;
	children: Children;
	className?: string;
};

export const InfoCard = ({ title, children, className }: Props) => {
	const classes = useStyles();

	return (
		<Paper className={clsx(classes.paper, className)}>
			{title && (
				<Typography component="h2" variant="h6" gutterBottom>
					{title}
				</Typography>
			)}
			{children}
		</Paper>
	);
};

import React from 'react';
import { makeStyles, Tooltip, IconButton, Popover, Theme, Typography } from '@material-ui/core';
import { MoreVertRounded } from '@material-ui/icons';
import { Children } from '../../types';
import { useTranslation } from 'react-i18next';
import { BREAKPOINT_KEYS } from '../../helpers/generalConstants';

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
	title: {
		marginBottom: spacing(3),
	},
	paper: {
		width: `calc(100vw - ${spacing(2)}px)`,
		padding: spacing(2),
		columns: 'auto',
		columnCount: 'auto',
		columnFill: 'auto',
		[breakpoints.up(BREAKPOINT_KEYS.sm)]: {
			width: '60vw',
		},
		[breakpoints.up(BREAKPOINT_KEYS.md)]: {
			width: spacing(60),
		},
	},
}));

type Props = {
	children: Children;
	id: string;
	title?: string;
	icon?: React.ReactNode;
};

export const PopoverOptions = ({ children, icon = <MoreVertRounded />, title, id }: Props) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
	const { t } = useTranslation();
	const classes = useStyles();
	const open = Boolean(anchorEl);
	const defaultTitle = t('moreOption') as string;

	return (
		<>
			<Tooltip title={title || defaultTitle} data-cy={`btn-filter:${id}`}>
				<IconButton edge="end" onClick={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}>
					{icon}
				</IconButton>
			</Tooltip>

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				classes={{ paper: classes.paper }}
				onClose={(event: React.MouseEvent<HTMLButtonElement>) =>
					setAnchorEl(anchorEl ? null : event.currentTarget)
				}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Typography className={classes.title} component="h3" variant="button">
					{title || defaultTitle}
				</Typography>
				{children}
			</Popover>
		</>
	);
};

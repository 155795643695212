import {
	LoginRequestPayload,
	LoginResponseData,
	ChangeProjectPayload,
	TenantsResponseData,
	PermissionsResponseData,
	LogsResponseData,
} from './user-types';
import { apiClient } from '../client';
import { FiltersRequestBody2, HideFlag, TableHeadCell } from '../../components/Table/types';
import { UUID } from '../../types';
import { CreateUserRelationRequestBody, UserRelation, UserRelationsResponseData } from '../../store/user/user-types';
import { CreateTenantRequestBody, Tenant } from '../../pages/Tenants/tenants-types';

const baseUrlForDotNet = '/identity.net';
const baseUrlForDotNetUserRoles = '/identity.net/userroles';

export const logIn = (data: LoginRequestPayload) =>
	apiClient.post<LoginResponseData>(`${baseUrlForDotNet}/login`, data);

export const logOut = () => apiClient.post<void>(`${baseUrlForDotNet}/logout`);

export const changeProject = (data: ChangeProjectPayload) =>
	apiClient.post<string>(`${baseUrlForDotNet}/change-project`, data);

export const fetchDeviceTableHidenColumns = (key: string) =>
	apiClient.get<HideFlag[]>(`${baseUrlForDotNet}/storage/${key}`);

export const setDeviceTableHidenColumns = (key: string, body: HideFlag[]) =>
	apiClient.post(`${baseUrlForDotNet}/storage/${key}`, body);

export const fetchTelemetryTableHidenColumns = (key: string) =>
	apiClient.get<TableHeadCell[]>(`${baseUrlForDotNet}/storage/${key}`);

export const setTelemetryTableHidenColumns = (key: string, body: TableHeadCell[]) =>
	apiClient.post(`${baseUrlForDotNet}/storage/${key}`, body);

export const fetchTenants = (body: FiltersRequestBody2) =>
	apiClient.post<TenantsResponseData>(`${baseUrlForDotNet}/tenants/filter`, body);

export const createTenant = (tenant: CreateTenantRequestBody) =>
	apiClient.post<Tenant>(`${baseUrlForDotNet}/tenants/`, tenant);

export const editTenant = (tenant: CreateTenantRequestBody, tenantId: UUID) =>
	apiClient.put<Tenant>(`${baseUrlForDotNet}/tenants/${tenantId}`, tenant);

export const deleteTenant = (tenantId: UUID) => apiClient.delete(`${baseUrlForDotNet}/tenants/${tenantId}`);

export const fetchPermissions = (body: FiltersRequestBody2) =>
	apiClient.post<PermissionsResponseData>(`${baseUrlForDotNet}/permissions/filter`, body);

export const getFilteredUserRelations = (body: FiltersRequestBody2) =>
	apiClient.post<UserRelationsResponseData>(`${baseUrlForDotNetUserRoles}/filter`, body);

export const createUserRelation = (user: CreateUserRelationRequestBody) =>
	apiClient.post<UserRelation>(baseUrlForDotNetUserRoles, user);

export const addUserProjectRelations = (relations: { userId: UUID; projectId: UUID }[]) =>
	apiClient.post<UserRelation>(`${baseUrlForDotNetUserRoles}/multiple`, relations);

export const editUserRelation = (user: CreateUserRelationRequestBody, userId: UUID) =>
	apiClient.put<UserRelation>(`${baseUrlForDotNetUserRoles}/${userId}`, user);

export const deleteUserRelation = (userId: UUID) => apiClient.delete(`${baseUrlForDotNetUserRoles}/${userId}`);

export const fetchLogs = (body: FiltersRequestBody2) =>
	apiClient.post<LogsResponseData>(`${baseUrlForDotNet}/storage/logs`, body);

import { useRef, useEffect } from 'react';

/**
 * Saves previous value, if you need to compare it to a current value
 * @param value - anything you need to store as a previous value
 */
export const usePrevious = <T>(value: T) => {
	const ref = useRef<T>();

	// Store current value in ref
	useEffect(() => {
		ref.current = value;
	}, [value]);

	// Return previous value (happens before update in useEffect above)
	return ref.current as T;
};

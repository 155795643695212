import { createMuiTheme, Theme } from '@material-ui/core/styles';
import { BREAKPOINT_VALUES } from './helpers/generalConstants';

export const COLORS = {
	transparent: 'transparent',
	black: {
		main: '#535254',
	},
	white: {
		light: '#fff',
		main: '#f4f2f1',
	},
	gray: {
		light: '#e0e0e0',
		main: '#4e4e4e',
	},
	orange: {
		light: '#ffd983',
		main: '#f36e21',
		dark: '#92310b',
	},
	blue: {
		light: '#d7e9ff',
		main: '#6666e2',
		dark: '#0b0c52',
	},
	red: {
		light: '#fdc3c3',
		main: '#f82324',
		dark: '#8b0404',
	},
	green: {
		light: '#90f3c0',
		main: '#14c066',
		dark: '#084a28',
	},
};

export const COLOR_THEME = {
	primary: '#c2b15e',
	secondary: COLORS.blue.main,
	success: COLORS.green.main,
	error: COLORS.red.main,
	warning: COLORS.orange.light,
	info: COLORS.blue.main,
};

export const SPACING = 8;

export const theme: Theme = createMuiTheme({
	overrides: {
		MuiCssBaseline: {
			// Add global styles
			'@global': {
				'*, *::before, *::after': {
					boxSizing: 'border-box',
				},
				'.Mui-disabled': {
					cursor: 'not-allowed',
				},
				// Remove blue background for autofilled inputs
				'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active': {
					'-webkit-background-clip': 'text',
				},
				'.leaflet-popup-content': {
					margin: 0,
				},
				'.CodeMirror': {
					height: 700,
				},
			},
		},
		MuiListItemIcon: {
			root: {
				color: 'inherit',
			},
		},
		MuiIconButton: {
			root: {
				color: 'inherit',
			},
		},
	},
	palette: {
		primary: {
			main: COLOR_THEME.primary,
			light: '',
			dark: '',
			contrastText: COLORS.white.light,
		},
		secondary: {
			main: COLOR_THEME.secondary,
			light: '',
			dark: '',
			contrastText: COLORS.white.light,
		},
		error: {
			main: COLOR_THEME.error,
			light: COLORS.red.light,
			dark: COLORS.red.dark,
		},
		success: {
			main: COLOR_THEME.success,
			light: COLORS.green.light,
			dark: COLORS.green.dark,
		},
		info: {
			main: COLOR_THEME.info,
			light: COLORS.blue.light,
			dark: COLORS.blue.dark,
		},
		warning: {
			main: COLOR_THEME.warning,
			light: COLORS.orange.light,
			dark: COLORS.orange.dark,
		},
		background: {
			default: COLORS.white.main,
		},
		text: {
			primary: COLORS.black.main,
		},
	},
	spacing: SPACING,
	breakpoints: {
		values: BREAKPOINT_VALUES,
	},
});

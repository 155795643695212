import React from 'react';
import { ResponsiveContainer, AreaChart, Area } from 'recharts';
import { COLORS } from '../../../theme';
import { ChartPoint } from '../telemetry-types';

type Props = {
	chartData: ChartPoint[];
	tooltip: JSX.Element;
	dataKey: string;
};

export const DetailChart = ({ chartData, tooltip, dataKey }: Props) => {
	return (
		<ResponsiveContainer width="100%" height="100%">
			<AreaChart data={chartData} margin={{ top: 15, right: 0, left: 0, bottom: 0 }}>
				<Area
					type="monotone"
					dataKey={dataKey}
					stroke={COLORS.orange.main}
					fill={COLORS.orange.light}
					strokeWidth={3}
					dot={false}
				/>
				{tooltip}
			</AreaChart>
		</ResponsiveContainer>
	);
};

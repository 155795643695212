import React from 'react';
import { Form } from '../../../components/Form/Form';
import { InputField } from '../../../components/Form/InputField';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/Button/Button';
import { Save } from '@material-ui/icons';
import { ModalDialogFormActions } from '../../../components/ModalDialog/ModalDialogFormActions';

export type ContactGroupFormValues = {
	name: string;
};

type Props = {
	defaultValues?: ContactGroupFormValues;
	onSubmit: (values: ContactGroupFormValues) => void;
	onCancel: () => void;
};

export const ContactGroupForm = ({
	onSubmit,
	onCancel,
	defaultValues = {
		name: '',
	},
}: Props) => {
	const { t } = useTranslation();
	const validationSchema = yup.object().shape({
		name: yup.string().required(t('validationErrors.required')),
		description: yup.string(),
	});

	const { handleSubmit, errors, control } = useForm<ContactGroupFormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues,
	});

	const handleSubmitForm = (values: ContactGroupFormValues) => {
		onSubmit(values);
	};

	return (
		<Form onSubmit={handleSubmit(handleSubmitForm)} testId="groupForm">
			<InputField
				autoFocus
				error={Boolean(errors.name)}
				helperText={errors.name?.message}
				label={t('name')}
				name="name"
				control={control}
			/>
			<ModalDialogFormActions>
				<Button startIcon={<Save />} type="submit" data-cy="btn-submit:groupForm">
					{t('save')}
				</Button>
				<Button onClick={onCancel} variant="text" data-cy="btn-cancel:groupForm">
					{t('cancel')}
				</Button>
			</ModalDialogFormActions>
		</Form>
	);
};

export const FETCH_PROJECTS_REQUEST = '[PROJECTS_ACTIONS] FETCH_PROJECTS_REQUEST';
export const FETCH_PROJECTS_SUCCESS = '[PROJECTS_ACTIONS] FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_ERROR = '[PROJECTS_ACTIONS] FETCH_PROJECTS_ERROR';
export const CREATE_PROJECT_REQUEST = '[PROJECTS_ACTIONS] CREATE_PROJECT_REQUEST';
export const CREATE_PROJECT_SUCCESS = '[PROJECTS_ACTIONS] CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_ERROR = '[PROJECTS_ACTIONS] CREATE_PROJECT_ERROR';
export const EDIT_PROJECT_REQUEST = '[PROJECTS_ACTIONS] EDIT_PROJECT_REQUEST';
export const EDIT_PROJECT_SUCCESS = '[PROJECTS_ACTIONS] EDIT_PROJECT_SUCCESS';
export const EDIT_PROJECT_ERROR = '[PROJECTS_ACTIONS] EDIT_PROJECT_ERROR';
export const DELETE_PROJECT_REQUEST = '[PROJECTS_ACTIONS] DELETE_PROJECT_REQUEST';
export const DELETE_PROJECT_SUCCESS = '[PROJECTS_ACTIONS] DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_ERROR = '[PROJECTS_ACTIONS] DELETE_PROJECT_ERROR';
export const FETCH_PROJECT_INFO_REQUEST = '[PROJECTS_ACTIONS] FETCH_PROJECT_INFO_REQUEST';
export const FETCH_PROJECT_INFO_SUCCESS = '[PROJECTS_ACTIONS] FETCH_PROJECT_INFO_SUCCESS';
export const FETCH_PROJECT_INFO_ERROR = '[PROJECTS_ACTIONS] FETCH_PROJECT_INFO_ERROR';

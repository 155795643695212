import React, { ChangeEvent, useState } from 'react';
import { Form } from '../../../components/Form/Form';
import { useTranslation } from 'react-i18next';
import { FormControl, TextField, makeStyles, Theme } from '@material-ui/core';
import { Button } from '../../../components/Button/Button';
import { InputField } from '../../../components/Form/InputField';
import SaveIcon from '@material-ui/icons/Save';
import { ModalDialogFormActions } from '../../../components/ModalDialog/ModalDialogFormActions';
import { UserRole, UserRoleType } from '../user-roles-types';
import { Permission } from '../../../services/user/user-types';
import { Autocomplete } from '@material-ui/lab';
import { InputSelectUncontrolled } from '../../../components/Form/InputSelectUncontrolled';

const useStyles = makeStyles(({ spacing }: Theme) => ({
	select: {
		'& .MuiFormLabel-root': {
			width: '90%',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			paddingRight: spacing(2),

			'&.Mui-focused': {
				width: '100%',
				textOverflow: 'initial',
			},
		},
	},
}));

type FormValues = Omit<UserRole, 'dateCreated' | 'dateUpdated'>;

type Props = {
	onSubmit: (values: FormValues) => void;
	onCancel: () => void;
	userRole?: FormValues;
	permissionDefinitions: Permission[];
};

export const UserRolesForm = ({
	onSubmit,
	onCancel,
	userRole = {
		id: '',
		name: '',
		roleType: UserRoleType.User,
		permissions: '0',
	},
	permissionDefinitions,
}: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const [name, setName] = useState<string>(userRole.name);
	const [nameError, setNameError] = useState<boolean>(false);

	const [roleType, setRoleType] = useState<UserRoleType>(userRole.roleType);
	const [roleTypeError, setRoleTypeError] = useState<boolean>(false);

	const [permissions, setPermissions] = useState<string>(userRole.permissions);
	const [permissionsError, setPermissionsError] = useState<boolean>(false);

	const handleSetName = (event: ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value);
		setNameError(false);
	};

	const handleSetType = (roleType?: string) => {
		setRoleType(roleType as UserRoleType);
		setRoleTypeError(false);
	};

	const handlePermissions = (event: unknown, newValue: Permission[]) => {
		const mask: string[] = Array(permissions.length).fill('0');

		newValue.forEach((permission) => (mask[permission.index] = '1'));

		setPermissions(mask.join(''));
		setPermissionsError(false);
	};

	const handleSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
		let valid = true;

		if (name === '') {
			valid = false;
			setNameError(true);
		}

		if (roleType === undefined) {
			valid = false;
			setRoleTypeError(true);
		}

		if (permissions === '') {
			valid = false;
			setPermissionsError(true);
		}

		if (valid) {
			onSubmit({
				id: userRole.id,
				name: name,
				roleType: roleType,
				permissions: permissions,
			});
		}

		event.preventDefault();
	};

	return (
		<Form onSubmit={handleSubmitForm} testId="userRolesForm">
			<FormControl fullWidth margin="normal" variant="outlined">
				<InputField
					autoFocus
					value={name}
					error={nameError}
					helperText={nameError && t('validationErrors.required')}
					label={t('name')}
					name="name"
					onChange={handleSetName}
				/>
			</FormControl>
			<FormControl fullWidth margin="normal" variant="outlined">
				<InputSelectUncontrolled
					defaultValueId={roleType}
					onChanged={(value) => {
						handleSetType(value?.id);
					}}
					error={roleTypeError}
					options={
						Object.values(UserRoleType).map((roleType) => ({
							id: roleType,
							label: t(roleType),
						})) ?? []
					}
					label={t('selectRoleType')}
				/>
			</FormControl>
			<FormControl fullWidth margin="normal" variant="outlined" color="primary">
				<Autocomplete
					className={classes.select}
					color="primary"
					multiple
					options={permissionDefinitions}
					getOptionLabel={(option) => option.name}
					defaultValue={permissionDefinitions.filter((permission) => permissions[permission.index] === '1')}
					filterSelectedOptions
					disableCloseOnSelect
					fullWidth
					renderInput={(params) => (
						<TextField
							{...params}
							label={t('selectPermission')}
							// placeholder="Favorites"
							error={permissionsError}
							helperText={permissionsError && t('validationErrors.required')}
							variant="outlined"
						/>
					)}
					onChange={handlePermissions}
				/>
			</FormControl>
			<ModalDialogFormActions>
				<Button startIcon={<SaveIcon />} type="submit" data-cy="btn-submit:userRoleForm">
					{t('save')}
				</Button>
				<Button onClick={onCancel} variant="text" data-cy="btn-cancel:userRoleForm">
					{t('cancel')}
				</Button>
			</ModalDialogFormActions>
		</Form>
	);
};

export const REVIEW_COMMON_EMPTY_REQUEST = '[REVIEW_ACTIONS] REVIEW_COMMON_EMPTY_REQUEST';
export const REVIEW_COMMON_EMPTY_ERROR = '[REVIEW_ACTIONS] REVIEW_COMMON_EMPTY_ERROR';

export const FETCH_REPORT_ORDERS_SUCCESS = '[REVIEW_ACTIONS] FETCH_REPORT_ORDERS_SUCCESS';

export const CREATE_REPORT_ORDER_SUCCESS = '[REVIEW_ACTIONS] CREATE_REPORT_ORDER_SUCCESS';

export const EDIT_REPORT_ORDER_SUCCESS = '[REVIEW_ACTIONS] EDIT_REPORT_ORDER_SUCCESS';

export const DELETE_REPORT_ORDER_SUCCESS = '[REVIEW_ACTIONS] DELETE_REPORT_ORDER_SUCCESS';

export const GET_REPORT_ORDER_DEFINITION_SUCCESS = '[REVIEW_ACTIONS] GET_REPORT_ORDER_DEFINITION_SUCCESS';

export const GET_REPORT_ORDER_DEFINITIONS_NAMES_SUCCESS = '[REVIEW_ACTIONS] GET_REPORT_ORDER_DEFINITIONS_NAMES_SUCCESS';

export const FETCH_STANDING_REPORT_ORDERS_SUCCESS = '[REVIEW_ACTIONS] FETCH_STANDING_REPORT_ORDERS_SUCCESS';

export const CREATE_STANDING_REPORT_ORDER_SUCCESS = '[REVIEW_ACTIONS] CREATE_STANDING_REPORT_ORDER_SUCCESS';

export const EDIT_STANDING_REPORT_ORDER_SUCCESS = '[REVIEW_ACTIONS] EDIT_STANDING_REPORT_ORDER_SUCCESS';

export const DELETE_STANDING_REPORT_ORDER_SUCCESS = '[REVIEW_ACTIONS] DELETE_STANDING_REPORT_ORDER_SUCCESS';

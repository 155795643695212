import React from 'react';
import { format } from 'date-fns';
import { Translation } from 'react-i18next';
import { compose, map, pick } from 'lodash/fp';
import { INPUT_DATE_MASK } from '../../helpers/generalConstants';
import { Report, ReportGroup, ReportUnit } from './reports-types';

export const getFormattedReportValue = (value: string | number, unit?: ReportUnit) => {
	switch (unit) {
		case ReportUnit.DATE:
			return format(new Date(value), INPUT_DATE_MASK);
		case ReportUnit.DEGREES_CELSIUS:
			return <Translation>{(t) => t('degreesCelsius', { value })}</Translation>;
		case ReportUnit.PERCENTAGE:
			return <Translation>{(t) => t('percentage', { value })}</Translation>;
		case ReportUnit.STRING:
			return typeof value === 'string' ? value : '';

		default:
			return value;
	}
};

export const prepareReportData = (data: ReportGroup[]) =>
	data
		.map((group) => {
			return group.reports.map((report) => {
				return {
					id: report.name,
					[group.groupName]: getFormattedReportValue(report.value, report.unit),
				};
			});
		})
		.flat(2)
		.reduce<Report[]>((acc, report) => {
			const index = acc.findIndex(({ id }) => id === report.id);
			const isNewReport = index === -1;
			const isExistingReport = index > -1;

			if (isNewReport) {
				return [...acc, report];
			}

			if (isExistingReport) {
				return acc.map((accReport, accIndex) => {
					if (accIndex === index) {
						return {
							...accReport,
							...report,
						};
					}

					return accReport;
				});
			}

			return acc;
		}, []);

// TODO: When data change to matrix, use this solution
// const data = {
// 	reportings: ['Average time spent in zone A', 'Highest Temperature', 'Count last week', 'Last error occurence'],
// 	groups: ['GroupA', 'GroupB', 'total'],
// 	values: [
// 		['12H 34M', '14H 34M', '13H 34M'],
// 		[22.1, 23, 22.5],
// 		[77, 77, 154],
// 		[null, '1/1/2020 14:22', '1/1/2020 14:22'],
// 	],
// };

// const prepareReportData = (data) => data.reportings.map((report, reportIndex) => {
// 	return data.groups.reduce((acc, group, groupIndex) => {
// 		return {
// 			...acc,
// 			report,
// 			[group]: data.values[reportIndex][groupIndex],
// 		};
// 	}, {});
// });

export const getNotificationProperties = compose(map(pick(['message', 'severity', 'sn', 'time', 'workflowId'])));

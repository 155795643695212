import * as contactsService from '../../services/contacts/contacts-service';
import { addToast } from '../app/app-actions';
import { fetchRequest, isStatusSuccess, createToastError } from '../../services/helpers';
import { AsyncAction } from '../types';
import {
	contactsCommonEmptyRequest,
	contactsCommonEmptyError,
	fetchContactsSuccess,
	createContactSuccess,
	editContactSuccess,
	deleteContactSuccess,
} from './contacts-actions';
import { UUID } from '../../types';
import { omit } from 'lodash';
import {
	ContactsResponseData,
	Contact,
	CreateContactRequestBody,
	EditContactRequestBody,
} from '../../pages/Contacts/contacts-types';
import { FiltersRequestBody2 } from '../../components/Table/types';

export const fetchContacts: AsyncAction<FiltersRequestBody2> = (payload) => async (dispatch) => {
	dispatch(contactsCommonEmptyRequest());

	const filterBody = payload;

	const request = contactsService.fetchContacts(filterBody);

	const { data, error } = await fetchRequest<ContactsResponseData>(request);

	if (data) {
		dispatch(fetchContactsSuccess(data, payload));
	} else {
		dispatch(contactsCommonEmptyError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data, () => dispatch(fetchContacts(payload)))));
	}
};

export const createContact: AsyncAction<CreateContactRequestBody> = (body) => async (dispatch, getState) => {
	dispatch(contactsCommonEmptyRequest());

	const { contacts } = getState();
	const request = contactsService.createContact(body);
	const { data, status, error } = await fetchRequest<Contact>(request);

	if (isStatusSuccess(status) && data) {
		dispatch(createContactSuccess(data));
		dispatch(
			fetchContacts({
				limit: contacts.limit,
				offset: contacts.offset,
				filtersAndConditions: contacts.filters,
				orderings: contacts.orderings,
			}),
		);
	} else {
		dispatch(contactsCommonEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(createContact(body));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const editContact: AsyncAction<EditContactRequestBody> = (contact) => async (dispatch, getState) => {
	dispatch(contactsCommonEmptyRequest());

	const { contacts } = getState();
	const body = omit(contact, ['id']);
	const request = contactsService.editContact(body, contact.id);
	const { data, error } = await fetchRequest<Contact>(request);

	if (data) {
		dispatch(editContactSuccess(data));
		dispatch(
			fetchContacts({
				limit: contacts.limit,
				offset: contacts.offset,
				filtersAndConditions: contacts.filters,
				orderings: contacts.orderings,
			}),
		);
	} else {
		dispatch(contactsCommonEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(editContact(contact));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const deleteContact: AsyncAction<UUID> = (contactId) => async (dispatch) => {
	dispatch(contactsCommonEmptyRequest());

	const request = contactsService.deleteContact(contactId);
	const { status, error } = await fetchRequest<void>(request);

	if (isStatusSuccess(status) && contactId) {
		dispatch(deleteContactSuccess(contactId));
	} else {
		dispatch(contactsCommonEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(deleteContact(contactId));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

import { UUID } from '../../types';
import { PaginatedResponse } from '../../store/types';

export type ReportOrder = {
	id: UUID;
	dateCreated: string;
	name: string;
	reviewDefinitionId: UUID;
	placementGroups?: { name?: string; id: UUID }[];
	parameters: Parameter[];
	status: string;
	result?: string;
	standingOrder?: boolean;
};

export type StandingReportOrder = {
	id: UUID;
	name: string;
	type: string;
	periodicity: 'daily' | 'weekly' | 'monthly';
	time: string;
};

export type ReviewDefinition = {
	id: UUID;
	name: string;
	formula: string;
	parameterDefinitions: ParameterDefinition[];
};

export type ReviewDefinitionName = {
	id: UUID;
	name: string;
};

export type ParameterDefinition = {
	name: string;
	type: ParameterType;
};

export type Parameter = {
	name: string;
	type: ParameterType;
	value: any;
};

export enum ParameterType {
	unknown = 'unknown',
	guid = 'guid',
	zoneArray = 'zoneArray',
	string = 'string',
	integer = 'integer',
	dateTime = 'dateTime',
	timeSpan = 'timeSpan',
	date = 'date',
}

export type TextResult = {
	id: string;
	style: Style;
	text: string;
	contents: TextResult[];
};

export type Style =
	| 'div'
	| 'header1'
	| 'header2'
	| 'header3'
	| 'paragraph'
	| 'itemList'
	| 'item'
	| 'table'
	| 'tableRow'
	| 'tableCell'
	| 'tableColumns'
	| 'tableColumn'
	| 'tableColumnFixed';

export type ReportOrdersResponseData = PaginatedResponse<ReportOrder>;

export type CreateReportOrderRequestBody = Omit<
	ReportOrder,
	'id' | 'dateCreated' | 'status' | 'result' | 'standingOrder'
> & {
	userId: UUID;
};

export type EditReportOrderRequestBody = Omit<ReportOrder, 'id' | 'dateCreated'>;

export type ReviewDefinitionNamesResponseData = PaginatedResponse<ReviewDefinitionName>;

export type StandingReportOrdersResponseData = PaginatedResponse<StandingReportOrder>;

export type CreateStandingReportOrderRequestBody = Omit<StandingReportOrder, 'id' | 'dateCreated'>;

export type EditStandingReportOrderRequestBody = Omit<StandingReportOrder, 'id' | 'dateCreated'>;

import {
	generalPlacementGroupsRequest,
	generalPlacementGroupsError,
	fetchPlacementGroupsSuccess,
	createPlacementGroupSuccess,
	updatePlacementGroupSuccess,
	deletePlacementGroupSuccess,
} from './placement-groups-actions';

import { AsyncAction } from '../types';
import { UUID } from '../../types';
import { addToast } from '../app/app-actions';
import { fetchRequest, createToastError, isStatusSuccess } from '../../services/helpers';
import * as groupsService from '../../services/placement-groups/placement-groups-service';
import {
	CreateGroupPayload,
	GroupResponseData,
	UpdateGroupPayload,
	GroupsResponseData,
	UpdateGroupResponseData,
} from '../../pages/PlacementGroups/placement-groups-types';

import { omit } from 'lodash/fp';
import { FiltersRequestBody2 } from '../../components/Table/types';

export const fetchPlacementGroups: AsyncAction<FiltersRequestBody2> = (groupsPayload) => async (dispatch) => {
	dispatch(generalPlacementGroupsRequest());

	const request = groupsService.getFilteredPlacementGroups(groupsPayload);
	const { data, error } = await fetchRequest<GroupsResponseData>(request);

	if (data) {
		dispatch(fetchPlacementGroupsSuccess(data, groupsPayload));
	} else {
		dispatch(generalPlacementGroupsError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(fetchPlacementGroups(groupsPayload));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const createPlacementGroup: AsyncAction<CreateGroupPayload> = (createGroupPayload) => async (
	dispatch,
	getState,
) => {
	dispatch(generalPlacementGroupsRequest());

	const { groups } = getState();
	const request = groupsService.createPlacementGroup(createGroupPayload);
	const { data, status, error } = await fetchRequest<GroupResponseData>(request);

	if (isStatusSuccess(status) && data) {
		dispatch(createPlacementGroupSuccess());
		dispatch(
			fetchPlacementGroups({
				limit: groups.limit,
				offset: groups.offset,
				orderings: groups.orderings,
				filtersAndConditions: groups.filtersAndConditions,
			}),
		);
	} else {
		dispatch(generalPlacementGroupsError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(createPlacementGroup(createGroupPayload));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const updatePlacementGroup: AsyncAction<UpdateGroupPayload> = (updateGroupPayload) => async (
	dispatch,
	getState,
) => {
	dispatch(generalPlacementGroupsRequest());

	const body = omit(['id'], updateGroupPayload);
	const request = groupsService.updatePlacementGroup(body, updateGroupPayload.id);
	const { data, error } = await fetchRequest<UpdateGroupResponseData>(request);
	// const { devicesToUpdate, devicesToRemove } = updateGroupPayload;
	// const devicesUpdated = devicesToUpdate || devicesToRemove;

	if (data) {
		dispatch(updatePlacementGroupSuccess(data));
	} else {
		dispatch(generalPlacementGroupsError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(updatePlacementGroup(updateGroupPayload));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const deletePlacementGroup: AsyncAction<UUID> = (groupId) => async (dispatch) => {
	dispatch(generalPlacementGroupsRequest());

	const request = groupsService.deletePlacementGroup(groupId);
	const { status, error } = await fetchRequest<void>(request);

	if (isStatusSuccess(status) && groupId) {
		dispatch(deletePlacementGroupSuccess(groupId));
	} else {
		dispatch(generalPlacementGroupsError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(deletePlacementGroup(groupId));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

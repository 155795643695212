import * as yup from 'yup';
import { TFunction } from 'i18next';
import { Filter, FilterRequestBase, FiltersJSONBase, PagedRequestBase } from './types';
import { getObjectValue } from '../../helpers/object';
import {
	FilterOperators,
	operatorSQLMap,
	CLASSIFICATION_SORT_KEYS,
	LAST_LOCATION_SORT_KEYS,
	LAST_TELEMETRY_SORT_KEYS,
	LAST_OTHER_SORT_KEYS,
} from './constants';
import { isValid } from 'date-fns';
import { snakeCase } from 'lodash/fp';

/**
 * Creates simple validation schema with required fields based on form data type
 * @param data - Form data object
 */
export const createSchema = <T>(data: T, t: TFunction) =>
	yup.object().shape(
		// Can't derive type from generic T, it causes error, because of Object.entries
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		Object.entries(data).reduce((acc: Record<string, any>, item: [string, any]) => {
			const key = item[0];
			const value = item[1];
			const validationType = typeof value;

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			if (!(yup as any)[validationType]) {
				return acc;
			}

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			acc[key] = (yup as any)[validationType]().required(t('validationErrors.required'));

			return acc;
		}, {}),
	);

/**
 * Gets values from filters
 *
 * @param filters - array of range filters
 */
export const getFilterValues = <T extends unknown>(filters: Filter[]) => filters.map(getObjectValue) as T;

/**
 * Gets Date values from filters
 *
 * @param filters - date filters
 */
export const getFilterDateValues = (filters: Filter[]) =>
	getFilterValues<string[]>(filters).map((ISODate: string) => new Date(ISODate));

/**
 * Gets Pair of Dates values from filters (from date, to date)
 *
 * @param filters - date filters
 */
export const getPairOfFilterDateValues = (filters: Filter[]) => {
	if (filters?.length === 2) {
		return [new Date(filters[0].value as string), new Date(filters[1].value as string)];
	}

	if (filters?.length === 1) {
		if (filters[0].operator === FilterOperators.greaterThanEqual) {
			return [new Date(filters[0].value as string), null];
		}

		if (filters[0].operator === FilterOperators.lessThanEqual) {
			return [null, new Date(filters[0].value as string)];
		}
	}

	// fallback
	return [null, null];
};

/**
 * Converts table config to JSON config format
 *
 * @param pagedRequestBase - Partial table config
 * @param orderBy - orderby property
 */
export const toJSONConfig = (pagedRequestBase: PagedRequestBase) => {
	if (pagedRequestBase.orderBy) {
		// HACK -eventType a eventTime are camelTypes also on server
		let orderBy;

		if (pagedRequestBase.orderBy === 'eventType' || pagedRequestBase.orderBy === 'eventTime') {
			orderBy = pagedRequestBase.orderBy;
		} else {
			orderBy = snakeCase(pagedRequestBase.orderBy);
		}

		const isByClassification = CLASSIFICATION_SORT_KEYS.includes(orderBy);
		const isByLastTelemetry = LAST_TELEMETRY_SORT_KEYS.includes(orderBy);
		const isByLastLocation = LAST_LOCATION_SORT_KEYS.includes(orderBy);
		const isByLastOther = LAST_OTHER_SORT_KEYS.includes(orderBy);

		if (isByClassification) {
			return {
				...pagedRequestBase,
				orderBy,
			};
		}

		if (isByLastTelemetry) {
			return {
				...pagedRequestBase,
				orderBy: `last_telemetry -> '${orderBy === 'last_seen' ? 'time' : orderBy}'`,
			};
		}

		if (isByLastLocation) {
			return {
				...pagedRequestBase,
				orderBy: `last_location -> '${orderBy === 'last_location_time' ? 'time' : orderBy}'`,
			};
		}

		if (isByLastOther) {
			return {
				...pagedRequestBase,
				orderBy: `last_other -> '${orderBy}'`,
			};
		}

		return {
			...pagedRequestBase,
			orderBy,
		};
	}

	return pagedRequestBase;
};

export const isDeviceTelemetryData = (filterName: string) =>
	['accuracy', 'battery', 'lightIntensity', 'temperature', 'time'].includes(filterName);

/**
 * Converts table filters to JSON filters format
 *
 * @param filtersBase - table filters
 */
export const toJSONFilters = (filtersBase: FilterRequestBase) => {
	const filters = Object.entries(filtersBase).reduce((acc: FiltersJSONBase, item: [string, Filter[]]) => {
		const [key, filters] = item;

		if (isDeviceTelemetryData(key) && Array.isArray(filters)) {
			const filterName = key === 'accuracy' ? 'lastLocation' : 'lastTelemetry';

			return {
				...acc,
				[filterName]: [
					...(acc[filterName] || []),
					...filters.map((filter: Filter) => {
						const isDate = typeof filter.value === 'string' && isValid(new Date(filter.value));

						return {
							operator: FilterOperators.inJSON as FilterOperators.inJSON,
							value: `'${snakeCase(key)}' ${operatorSQLMap.get(filter.operator)} '${
								// Escape double quotes because of 'in_json' date filters
								// eslint-disable-next-line no-useless-escape
								isDate ? `\"${filter.value}\"` : filter.value
							}'`,
						};
					}),
				],
			};
		}

		return {
			...acc,
			[key]: filters,
		};
	}, {} as FiltersJSONBase);

	return filters;
};

// Allow plural keys like: key_plural, etc.

import { globalTranslations } from './global';
import { ErrorCodes } from '../services/common-types';

export const sk = {
	translation: {
		...globalTranslations,
		error: 'Chyba',
		[ErrorCodes['SYS-001']]: 'Nastala chyba, skúste neskôr.',
		validationErrors: {
			required: 'Toto pole je povinné.',
			emptyEmail: 'Prosím vyplňte emailovú adresu.',
			invalidEmail: 'Neplatná emailová adresa.',
			passwordEmpty: 'Prosím vyplňte heslo.',
			invalidPassword: 'Heslo musí obsahovať aspoň 8 znakov z toho 1 písmeno a 1 číslo bez medzier.',
			newPasswordMatchesOldPassword: 'Nové heslo nesmie byť rovnaké ako súčasné heslo.',
			min0: 'Vyberte aspoň {{count}} možnosť.',
			min1: 'Vyberte aspoň {{count}} možnosti.',
			min2: 'Vyberte aspoň {{count}} možností.',
			number: 'Prosím vyplňte číslo.',
			polygon: 'Polygon musí mať aspoň 3 súradnice.',
			invalidDateMessage: 'Neplatný dátum. Musí byť vo formáte {{format}}.',
			maxDateMessage: 'Dátum nesmie byť po: {{date}}.',
			minDateMessage: 'Dátum nesmie byť pred: {{date}}.',
			bodyParser: 'Parser musí začínať výrazom bodyParser.',
			noOptionalChainingAndArrow: 'Optional chaining (?.) a arrow funkcie (=>) nie sú podporované.',
			bracketMissing: 'Chýbajúca zátvorka.',
			missingFields: 'Musíte vyplniť všetky polia.',
			invalidPathMissingSlash: 'Url endpointu musí začínať lomítkom "/", napr. /endpoint.',
		},
		selectedProject: 'Vybraný projekt',
		dashboard: 'Nástenka (Translation service out)',
		maps: 'Mapy',
		devices: 'Zariadenia',
		zones: 'Zóny',
		workflows: 'Workflowy',
		billing: 'Fakturácia',
		users: 'Užívatelia',
		ingestionEndpoints: 'Ingest endpointy',
		devicesStatus: 'Statusy zariadení',
		noData: 'Neboli nájdené žiadne dáta.',
		tryAgain: 'Skúsiť znova',
		actions: 'Akcie',
		edit: 'Editovať',
		cancel: 'Zrušiť',
		save: 'Uložiť',
		id: 'ID',
		firstName: 'Meno',
		lastName: 'Priezvisko',
		active: 'Aktívny',
		inactive: 'Neaktívny',
		lastLogin: 'Posledné prihlásenie',
		createUser: 'Vytvoriť užívateľa',
		tenantId: 'ID tenanta',
		tenant: 'Tenant',
		superAdmin: 'Super admin',
		createUserModalTitle: 'Vytvorenie užívateľa',
		createUserModalDescription:
			'Pomocou tohto formulára môžete vytvoriť nového užívateľa. Všetky údaje sú povinné.',
		editUserFormTitle: 'Editovať dáta užívateľa',
		editProjectsFormTitle: 'Editovať projekty a role',
		deleteUserModalTitle: 'Vymazať užívateľa',
		deleteUserModalDescription: 'Ste si istý, že chcete vymazať užívateľa {{firstName}} {{lastName}}?',
		delete: 'Zmazať',
		settings: 'Nastavenia',
		logout: 'Odhlásiť sa',
		signIn: 'Prihlásiť sa',
		email: 'Email',
		password: 'Heslo',
		forgotPassword: 'Zabudli ste heslo?',
		newPassword: 'Nové heslo',
		setPassword: 'Nastaviť heslo',
		changePassword: 'Zmena hesla',
		url: 'URL',
		payloadParser: 'Prekladač správ',
		createIngestionEndpoint: 'Vytvoriť endpoint',
		createIngestionEndpointModalTitle: 'Vytvoriť endpoint',
		createIngestionEndpointModalDescription: 'Pomocou tohto formulára môžete vytvoriť nový endpoint.',
		deleteIngestionEndpointModalTitle: 'Vymazať endpoint',
		deleteIngestionEndpointModalDescription: 'Ste si istý, že chcete vymazať endpoint {{endpoint}}?',
		editIngestionEndpointModalTitle: 'Editovať endpoint',
		editIngestionEndpointModalDescription: 'Pomocou tohto formulára môžete editovať tento endpoint.',
		selectUserRoles: 'Vyberte užvateľskú rolu',
		noOptions: 'Neostali žiadne možnosti',
		projectsAndRoles: 'Projekty and roly',
		USER: 'Užívateľ',
		PROJECT_ADMIN: 'Projektový admin',
		WF_WRITE: 'Workflow užívateľ',
		WF_READ: 'Workflow admin',
		INGEST_READ: 'Ingest užívateľ',
		INGEST_WRITE: 'Ingest admin',
		type: 'Typ',
		battery: 'Batéria',
		temperature: 'Teplota',
		accuracy: 'Presnosť polohy',
		time: 'Čas',
		userDetail: 'Detail užívateľa',
		selectEndpointMethod: 'Vyberte metódu endpointu',
		method: 'Metóda',
		projects: 'Projekty',
		name: 'Názov',
		description: 'Popis',
		latitude: 'Zemepisná šírka',
		longitude: 'Zemepisná dĺžka',
		remove: 'Odstrániť',
		add: 'Pridať',
		editZonePolygonModalTitle: 'Editovať zónu',
		createZonePolygonModalTitle: 'Vytvoriť zónu',
		deleteZoneModalTitle: 'Vymazať zónu',
		deleteZoneModalDescription: 'Ste si istý, že chcete vymazať zónu {{zone}}?',
		back: 'Späť',
		createProject: 'Vytvoriť projekt',
		editProjectModalTitle: 'Editovať projekt',
		deleteProjectModalTitle: 'Vymazať projekt',
		deleteProjectModalDescription: 'Ste si istý, že chcete vymazať projekt {{project}}?',
		createProjectModalTitle: 'Vytvoriť projekt',
		groups: 'Skupiny',
		dateCreated: 'Dátum vytvorenia',
		dateUpdated: 'Dátum aktualizácie',
		createGroup: 'Vytvor skupinu',
		deleteGroup: 'Vymaž skupinu',
		createGroupModalTitle: 'Vytvoriť skupinu',
		deleteGroupModalTitle: 'Vymazať skupinu',
		deleteGroupModalDescription: 'Ste si istý, že chcete vymazať skupinu {{name}}?',
		group: 'Skupina',
		editGroupModal: 'Editovať skupinu',
		groupDetail: 'Detail skupiny',
		updateDeviceModalTitle: 'Editovať zariadenie',
		sn: 'Sériové číslo',
		networkNumber: 'Číslo siete',
		removeDevices: 'Odstrániť',
		removeDevicesModalTitle: 'Odstrániť označené zariadenia zo skupiny?',
		otherGroups: 'Ostatné skupiny a nezaradené zariadenia',
		workflowsCount: 'Workflows',
		groupsCount: 'Skupín',
		usersCount: 'Užívateľov',
		devicesCount: 'Zariadení',
		filters: 'Filtre',
		all: 'Všetko',
		reset: 'Resetovať',
		createWorkflow: 'Vytvoriť workflow',
		deleteWorkflowModalTitle: 'Vymazať workflow',
		deleteWorkflowModalDescription: 'Ste si istý, že chcete vymazať workflow {{name}}?',
		addSituation: 'Pridaj situáciu',
		addSituationModalTitle: 'Pridaj situáciu',
		notifications: 'Notifikácie',
		notificationType: 'Typ notifikácie',
		tasks: 'Úlohy',
		editSituationModalTitle: 'Uprav situáciu',
		variables: 'Premenné',
		conditions: 'Podmienky',
		situations: 'Situácie',
		loginFrom: 'Dátum prihlásenia od',
		loginTo: 'Dátum prihlásenia do',
		createZone: 'Vytvoriť zónu',
		groupName: 'Názov skupiny',
		highestTemp: 'Najvyššia teplota',
		countLastWeek: 'Počet za minulý týždeň',
		lastErrorDate: 'Dátum poslednej chyby',
		report: 'Report',
		reports: 'Reporty',
		reportOrder: 'Zadaný report',
		reportOrders: 'Zadané reporty',
		standingReportOrder: 'Trvalé zadanie reportu',
		standingReportOrders: 'Trvalé zadania reportov',
		createReportOrder: 'Zadať report',
		createReportOrderModalTitle: 'Zadať report',
		confirm: 'Potvrdiť',
		notificationModalTitle: 'Notifikácie',
		severity: 'Závažnosť',
		message: 'Správa',
		warning: 'Varovanie',
		success: 'Úspech',
		info: 'Informácia',
		moreItems: '{{first}} + {{count}} ďaľšie',
		reportings: 'Reporty',
		saveWorkflow: 'Ulož workflow',
		lastSeen: 'Naposledy videné',
		lastLocation: 'Posledná poloha',
		lastLocationTime: 'Čas poslednej polohy',
		device: 'Zariadenie',
		detail: 'Detail',
		lastWeek: 'Posledných 7 dní',
		lastTwoWeeks: 'Posledných 14 dní',
		lastMonth: 'Posledný mesiac',
		lastTwoMonths: 'Posledné 2 mesiace',
		useRange: 'Použiť rozmedzie',
		from: 'Od',
		to: 'Do',
		submit: 'Odoslať',
		events: 'Udalosti',
		lightIntensity: 'Intenzita svetla',
		maxAcceleration: 'Maximálne zrýchlenie',
		maxAccelerationHistory: 'Maximálne zrýchlenie (história)',
		yes: 'Áno',
		no: 'Nie',
		query: 'Query',
		selectedZone: 'Vybraná zóna: {{zone}}',
		createDevice: 'Vytvor zariadenie',
		uploadDevices: 'Vytvor zariadenia',
		deviceDetail: 'Detail zariadenia',
		lastSignal: 'Posledný signál',
		telemetryHistory: 'História telemetrie',
		heat: 'Tepelná mapa',
		trail: 'Cestovná mapa',
		location: 'Poloha',
		moreOption: 'Viac možností',
		mapType: 'Typ mapy',
		zoneId: 'ID zóny',
		removeFromGroup: 'Odstrániť zo skupiny',
		addToGroup: 'Pridať do skupiny',
		currentTelemetry: 'Aktuálna telemetria',
		lastUpdate: 'Posledná aktualizácia: {{date}}',
		devicesLastTelemetry: 'Posledná telemetria z každého zariadenia',
		unsavedChanges: 'Neuložené zmeny!\n\nMáte neuložené zmeny, prajete si pokračovať? Vaše zmeny sa potom stratia.',
		zoneEntryCount: 'Počet návštev zóny',
		averageTimeInZone: 'Priemerný strávený čas v zóne',
		deviceCountInZone: 'Počet zariadení v zóne',
		selectQuery: 'Vyberte query',
		selectZone: 'Vyberte zónu',
		currentNotifications: 'Aktuálne reporty',
		currentReports: 'Aktuálne oznámenia',
		currentSummary: 'Aktuálne zhrnutie',
		hasMovedWithoutNewLocation: 'Posunuté bez polohy',
		moved: 'Posunuté',
		locationUpdateDetails: 'Lokalizačná metóda',
		bizStep: 'BIZ_STEP',
		action: 'Akcia',
		eventType: 'Udalosť',
		actDescription: 'ACT',
		signalPower: 'Sila signálu',
		eventTime: 'Čas udalosti',
		showHideColumns: 'Ukázať/skryť stĺpce',
		usersManagement: 'Správa užívateľov',
		noLocation: 'Neznáma poloha',
		zone: 'Zóna',
		missingProjectModalTitle: 'Chýbajúci projekt',
		missingProjectModalDescription:
			'Užívatel {{name}} nemá pridelené práva k žiadnemu projektu! Požiadajte o ne príslušných administrátorov.',
		zoom: 'Priblížiť',
		uploadGeoJSON: 'Nahrať z GeoJSON',
		close: 'Zavrieť',
		refresh: 'Obnoviť',
		telemetry: 'Telemetria',
		placement: 'Umiestnenie',
		placements: 'Umiestnenia',
		attached: 'Pripojené',
		attach: 'Pripojiť',
		detached: 'Odpojené',
		detach: 'Odpojiť',
		charts: 'Grafy',
		attachPlacementModalTitle: 'Pripojiť umiestnenie',
		createPlacement: 'Vytvoriť umiestnenie',
		value: 'Hodnota',
		classificationId: 'ID klasifikátora',
		deletePlacementModalTitle: 'Vymazať umiestnenie',
		deletePlacementModalDescription: 'Ste si istý, že chcete vymazať umiestnenie: {{name}}?',
		createPlacementModalTitle: 'Vytvoriť umiestnenie',
		editPlacementModalTitle: 'Editovať umiestnenie',
		classification: 'Klasifikátor',
		classifications: 'Klasifikátory',
		deleteClassificationModalTitle: 'Vymazať klasifikátor',
		deleteClassificationModalDescription: 'Ste si istý, že chcete vymazať klasifikátor: {{name}}?',
		createClassificationModalTitle: 'Vytvoriť klasifikátor',
		editClassificationModalTitle: 'Editovať klasifikátor',
		createClassification: 'Vytvoriť klasifikátor',
		attachedDevices: 'Pripojené zariadenia',
		attachedDevicesModalTitle: 'Zoznam pripojených zariadení',
		noAttachedDevices: 'K tomuto umiestneniu nie sú pripojené žiadne zariadenia.',
		choosePlacement: 'Vybrať umiestnenie',
		lastMessage: 'Last message', // TODO
		messageCounter: 'Message counter', // TODO
		sinceConnected: 'Since connected', // TODO
		attachDevice: 'Pripojiť zariadenie',
		chooseDevice: 'Vybrať zariadenie',
		//
		manufacturer: 'Výrobca',
		packageNumber: 'Číslo obalu (projektu)',
		serialNumber: 'Por. číslo',
		partDescription: 'Popis dielu (obalu)',
		project: 'Projekt',
		note: 'Poznámka',
		assetDescription: 'Popis assetu',
		assetName:'Názov assetu',
		//
		exportDevicesToExcel: 'Exportovať do Excelu',
		//
		configuration: 'Konfigurácia',
		management: 'Manažment',
		favourites: 'Obľúbené',
		//
		driverid: 'Číslo vodiča',
		carType: 'Typ auta',
		SPZ: 'ŠPZ',
		//
		script: 'Skript',
		ingestionScripts: 'Ingest skripty',
		ingestionScriptDetail: 'Detail ingest skriptu',
		createScript: 'Vytvoriť skript',
		validate: 'Validovať',
		validating: 'Validuje sa ...',
		deleteScriptModalTitle: 'Vymazať skript',
		deleteScriptModalDescription: 'Ste si istý, že chcete vymazať skript: {{script}}?',
		selectScript: 'Vybrať skript',
		testScript: 'Vyskúšať skript',
		testingScript: 'Skúšam skript ...',
		selectGroup: 'Vybrať skupinu',
		reportType: 'Typ reprtu',
		reportStatus: 'Stav',
		parameters: 'Parametere',
		summary: 'Zhrnutie',
		selectTenant: 'Vybrať tenanta',
		// review report state
		startedStatus: 'Spracováva sa',
		errorStatus: 'Chyba',
		finishedStatus: 'Hotovo',
	},
};

import { action } from 'typesafe-actions';
import {
	INGESTION_COMMON_EMPTY_REQUEST,
	INGESTION_COMMON_EMPTY_ERROR,
	FETCH_INGESTION_ENDPOINTS_REQUEST,
	FETCH_INGESTION_ENDPOINTS_SUCCESS,
	FETCH_INGESTION_ENDPOINTS_ERROR,
	EDIT_INGESTION_ENDPOINT_REQUEST,
	EDIT_INGESTION_ENDPOINT_SUCCESS,
	EDIT_INGESTION_ENDPOINT_ERROR,
	CREATE_INGESTION_ENDPOINT_REQUEST,
	CREATE_INGESTION_ENDPOINT_SUCCESS,
	CREATE_INGESTION_ENDPOINT_ERROR,
	DELETE_INGESTION_ENDPOINT_REQUEST,
	DELETE_INGESTION_ENDPOINT_SUCCESS,
	DELETE_INGESTION_ENDPOINT_ERROR,
	FETCH_INGESTION_SCRIPTS_SUCCESS,
	EDIT_INGESTION_SCRIPT_SUCCESS,
	CREATE_INGESTION_SCRIPT_SUCCESS,
	DELETE_INGESTION_SCRIPT_SUCCESS,
	FETCH_DOTNET_INGESTION_ENDPOINTS_SUCCESS,
	EDIT_DOTNET_INGESTION_ENDPOINT_SUCCESS,
	CREATE_DOTNET_INGESTION_ENDPOINT_SUCCESS,
	DELETE_DOTNET_INGESTION_ENDPOINT_SUCCESS,
} from './ingestion-endpoints-constants';

import {
	DotNetIngestionEndpoint,
	DotNetIngestionEndpointResponseData,
	IngestionEndpoint,
	IngestionEndpointResponseData,
	IngestionEndpointsPayload,
} from '../../pages/IngestionEndpoints/ingestion-endpoints-types';

import { UUID } from '../../types';
import { IngestionScript, IngestionScriptsResponseData } from '../../pages/IngestionScripts/ingestion-scripts-types';
import { FiltersRequestBody2 } from '../../components/Table/types';

export const ingestionCommonEmptyRequest = () => action(INGESTION_COMMON_EMPTY_REQUEST);
export const ingestionCommonEmptyError = () => action(INGESTION_COMMON_EMPTY_ERROR);

export const fetchIngestionEndpointsRequest = () => action(FETCH_INGESTION_ENDPOINTS_REQUEST);
export const fetchIngestionEndpointsSuccess = (
	data: IngestionEndpointResponseData,
	options?: IngestionEndpointsPayload,
) =>
	action(FETCH_INGESTION_ENDPOINTS_SUCCESS, {
		data,
		options,
	});

export const fetchIngestionEndpointsError = () => action(FETCH_INGESTION_ENDPOINTS_ERROR);

export const editIngestionEndpointRequest = () => action(EDIT_INGESTION_ENDPOINT_REQUEST);
export const editIngestionEndpointSuccess = (data: IngestionEndpoint) =>
	action(EDIT_INGESTION_ENDPOINT_SUCCESS, {
		data,
	});

export const editIngestionEndpointError = () => action(EDIT_INGESTION_ENDPOINT_ERROR);

export const createIngestionEndpointRequest = () => action(CREATE_INGESTION_ENDPOINT_REQUEST);
export const createIngestionEndpointSuccess = () => action(CREATE_INGESTION_ENDPOINT_SUCCESS);
export const createIngestionEndpointError = () => action(CREATE_INGESTION_ENDPOINT_ERROR);

export const deleteIngestionEndpointRequest = () => action(DELETE_INGESTION_ENDPOINT_REQUEST);
export const deleteIngestionEndpointSuccess = (id: UUID) =>
	action(DELETE_INGESTION_ENDPOINT_SUCCESS, {
		id,
	});

export const deleteIngestionEndpointError = () => action(DELETE_INGESTION_ENDPOINT_ERROR);

export const fetchIngestionScriptsSuccess = (data: IngestionScriptsResponseData, options?: FiltersRequestBody2) =>
	action(FETCH_INGESTION_SCRIPTS_SUCCESS, {
		data,
		options,
	});

export const editIngestionScriptSuccess = (data: IngestionScript) =>
	action(EDIT_INGESTION_SCRIPT_SUCCESS, {
		data,
	});

export const createIngestionScriptSuccess = () => action(CREATE_INGESTION_SCRIPT_SUCCESS);

export const deleteIngestionScriptSuccess = (id: UUID) =>
	action(DELETE_INGESTION_SCRIPT_SUCCESS, {
		id,
	});

//

export const fetchDotNetIngestionEndpointsSuccess = (
	data: DotNetIngestionEndpointResponseData,
	options?: FiltersRequestBody2,
) =>
	action(FETCH_DOTNET_INGESTION_ENDPOINTS_SUCCESS, {
		data,
		options,
	});

export const createDotNetIngestionEndpointSuccess = () => action(CREATE_DOTNET_INGESTION_ENDPOINT_SUCCESS);

export const editDotNetIngestionEndpointSuccess = (data: DotNetIngestionEndpoint) =>
	action(EDIT_DOTNET_INGESTION_ENDPOINT_SUCCESS, {
		data,
	});

export const deleteDotNetIngestionEndpointSuccess = (id: UUID) =>
	action(DELETE_DOTNET_INGESTION_ENDPOINT_SUCCESS, {
		id,
	});

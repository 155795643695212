import {
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Tooltip,
	Typography,
} from '@material-ui/core';
import {
	ArrowForwardRounded as ArrowForwardIcon,
	LinkOffRounded,
	RouterRounded as RouterIcon,
} from '@material-ui/icons';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { History } from 'history';
import { hasLength } from '../../../helpers/array';
import { INPUT_DATE_MASK } from '../../../helpers/generalConstants';
import { RouteEnum } from '../../../router/Routes';
import { removeModalDialog } from '../../../store/app/app-actions';
import { PlacementRelation } from '../device-placements-types';
import { UUID } from '../../../types';
import { detachDeviceFromPlacement } from '../../../store/device-placements/device-placements-async-actions';

type Props = {
	data: PlacementRelation[];
	history: History;
};

export const AttachedDevicesList = ({ data, history }: Props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const handleClickDeviceDetail = (deviceSn: string) => {
		dispatch(removeModalDialog('attachedDevicesModal'));
		history.push(`${RouteEnum.DEVICES}/${deviceSn}`);
	};

	const handleDetachPlacement = (relationId: UUID) => {
		dispatch(
			detachDeviceFromPlacement({
				detached: new Date().toISOString(),
				relationId,
			}),
		);

		dispatch(removeModalDialog('attachedDevicesModal'));
	};

	return hasLength(data) ? (
		<List dense>
			{data.map(({ id, deviceId, deviceSn, deviceType, attached, detached }, index) => {
				const hasMoreThanOneItem = index > 0;

				const deviceTypeLable = deviceType?.name;

				return (
					<React.Fragment key={deviceId}>
						{hasMoreThanOneItem && <Divider variant="inset" component="li" />}
						<ListItem>
							<ListItemIcon>
								<RouterIcon color={detached ? 'disabled' : 'action'} />
							</ListItemIcon>
							<ListItemText
								primary={deviceTypeLable ? `${deviceSn} (${deviceTypeLable})` : deviceSn}
								secondary={
									<div>
										<div>
											{t('attached')}: {format(new Date(attached), INPUT_DATE_MASK)}
										</div>
										{detached && (
											<div>
												{t('detached')}: {format(new Date(detached), INPUT_DATE_MASK)}
											</div>
										)}
									</div>
								}
							/>
							<ListItemSecondaryAction>
								{detached === null && (
									<IconButton
										edge="end"
										aria-label={t('detach')}
										onClick={() => handleDetachPlacement(id)}
									>
										<Tooltip title={t('detach') as string}>
											<LinkOffRounded />
										</Tooltip>
									</IconButton>
								)}
								<Tooltip title={t('deviceDetail') as string}>
									<IconButton edge="end" onClick={() => handleClickDeviceDetail(deviceSn)}>
										<ArrowForwardIcon />
									</IconButton>
								</Tooltip>
							</ListItemSecondaryAction>
						</ListItem>
					</React.Fragment>
				);
			})}
		</List>
	) : (
		<Typography>{t('noAttachedDevices')}</Typography>
	);
};

import prettier from 'prettier/standalone';
import babel from 'prettier/parser-babel';
import { NEW_LINES_REGEX } from './generalConstants';

/**
 * Format string with prettier
 * @param string
 */
export const formatWithPrettier = (string: string) =>
	prettier.format(string, {
		parser: 'babel',
		plugins: [babel],
		quoteProps: 'preserve',
	});

/**
 * Removes new lines and enters from string
 * @param string
 */
export const removeNewLines = (string: string) => string.replace(NEW_LINES_REGEX, '');

/**
 * Remove all empty strings
 * @param value - array of string
 *
 */
export const notEmptyString = (value: string) => value !== '';

import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { Grid } from '@material-ui/core';
import { fetchJobs } from '../../store/pipelines/pipelines-async-actions';
import { JobsTable } from './components/JobsTable';
import { PipelinesState } from '../../store/pipelines/pipelines-types';
import { SortOrder } from '../../components/Table/constants';

export const Jobs = () => {
	const dispatch = useDispatch();
	const { jobs } = useSelector<RootState, PipelinesState>((state) => state.pipelines);

	useEffect(() => {
		dispatch(
			fetchJobs({
				limit: jobs.limit,
				offset: jobs.offset,
				orderings: [{ column: 'time', sortOrder: SortOrder.Descending }],
				filtersAndConditions: jobs.filtersAndConditions,
			}),
		);

		// Don't need to react on "config" and "filters" and deep comparision is not necessary
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	return (
		<Grid container spacing={2} justifyContent="center">
			<Grid item xs={12} lg={10}>
				<Paper>
					<JobsTable
						data={jobs.data}
						total={jobs.total}
						offset={jobs.offset}
						limit={jobs.limit}
						orderings={jobs.orderings}
						filters={jobs.filtersAndConditions}
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};

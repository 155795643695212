import { action } from 'typesafe-actions';
import { FiltersRequestBody2 } from '../../components/Table/types';
import {
	DevicePlacementsResponseData,
	DefinitionResponseData,
	PlacementConnectionsResponseData,
} from '../../pages/DevicePlacementsManagement/device-placements-types';
import {
	GENERAL_PLACEMENT_REQUEST,
	GENERAL_PLACEMENT_ERROR,
	FETCH_PLACEMENTS_SUCCESS,
	CREATE_PLACEMENT_SUCCESS,
	UPDATE_PLACEMENT_SUCCESS,
	DELETE_PLACEMENT_SUCCESS,
	ATTACH_DEVICE_SUCCESS,
	DETACH_DEVICE_SUCCESS,
	FETCH_DEFINITIONS_SUCCESS,
	CREATE_PLACEMENT_DEFINITION_SUCCESS,
	SELECTE_PLACEMENT_DEFINITION_TAB,
	UPDATE_PLACEMENT_DEFINITION_SUCCESS,
	FETCH_PLACEMENT_CONNECTIONS_SUCCESS,
} from './device-placements-constants';

// general request action with no impact but starting the progress bar
export const generalPlacementRequest = () => action(GENERAL_PLACEMENT_REQUEST);
// general error action with no impact but stopping the progress bar
export const generalPlacementError = () => action(GENERAL_PLACEMENT_ERROR);

export const getSelectedPlacementDefinitionTab = (selected: number) =>
	action(SELECTE_PLACEMENT_DEFINITION_TAB, { selected });

export const fetchDefinitionsSuccess = (data: DefinitionResponseData, options?: FiltersRequestBody2) =>
	action(FETCH_DEFINITIONS_SUCCESS, { data, options });

export const fetchPlacementConnectionsSuccess = (
	data: PlacementConnectionsResponseData,
	options?: FiltersRequestBody2,
) => action(FETCH_PLACEMENT_CONNECTIONS_SUCCESS, { data, options });

export const createPlacementDefinitionSuccess = () => action(CREATE_PLACEMENT_DEFINITION_SUCCESS);

export const updatePlacementDefinitionSuccess = () => action(UPDATE_PLACEMENT_DEFINITION_SUCCESS);

export const fetchDevicePlacementsSuccess = (data: DevicePlacementsResponseData, options: FiltersRequestBody2) =>
	action(FETCH_PLACEMENTS_SUCCESS, { data, options });

export const createDevicePlacementSuccess = () => action(CREATE_PLACEMENT_SUCCESS);

export const updateDevicePlacementSuccess = () => action(UPDATE_PLACEMENT_SUCCESS);

export const deleteDevicePlacementSuccess = () => action(DELETE_PLACEMENT_SUCCESS);

export const attachDeviceSuccess = () => action(ATTACH_DEVICE_SUCCESS);

export const detachDeviceSuccess = () => action(DETACH_DEVICE_SUCCESS);

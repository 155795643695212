import {
	fetchReportsRequest,
	fetchReportsSuccess,
	fetchReportsError,
	fetchNotificationsRequest,
	fetchNotificationsSuccess,
	fetchNotificationsError,
	fetchCurrentReportsSuccess,
} from './reports-actions';
import { AsyncAction } from '../types';
import { fetchRequest, createToastError } from '../../services/helpers';
import { addToast } from '../app/app-actions';

import * as reportService from '../../services/reports/reports-service';
import { ReportPayload, ReportResponseData, NotificationsResponseData } from '../../pages/Reports/reports-types';

import { FiltersRequestBody2 } from '../../components/Table/types';

export const fetchReports: AsyncAction<ReportPayload> = (reportPayload) => async (dispatch) => {
	dispatch(fetchReportsRequest());

	const request = reportService.getReport({ workflowId: reportPayload.workflowId });
	const { data, error } = await fetchRequest<ReportResponseData>(request);

	if (data) {
		dispatch(fetchReportsSuccess(data));
	}

	if (error) {
		const tryAgainAction = () => dispatch(fetchReports(reportPayload));

		dispatch(fetchReportsError());
		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const fetchCurrentReports: AsyncAction<ReportPayload> = (reportPayload) => async (dispatch) => {
	dispatch(fetchReportsRequest());

	const request = reportService.getReport({ workflowId: reportPayload.workflowId });
	const { data, error } = await fetchRequest<ReportResponseData>(request);

	if (data) {
		dispatch(fetchCurrentReportsSuccess(data));
	}

	if (error) {
		const tryAgainAction = () => dispatch(fetchReports(reportPayload));

		dispatch(fetchReportsError());
		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const fetchNotifications: AsyncAction<FiltersRequestBody2> = (notificationsPayload) => async (dispatch) => {
	dispatch(fetchNotificationsRequest());

	const request = reportService.getNotifications(notificationsPayload);
	const { data, error } = await fetchRequest<NotificationsResponseData>(request);

	if (data) {
		dispatch(fetchNotificationsSuccess(data, notificationsPayload));
	} else {
		dispatch(fetchNotificationsError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(fetchNotifications(notificationsPayload));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

import { divIcon, LatLngLiteral } from 'leaflet';

const toRadians = (degree: number) => (degree * Math.PI) / 180;
const toDegrees = (radians: number) => (radians * 180) / Math.PI;

export const getCenterOfCoordinates = (coords: LatLngLiteral[]) => {
	const coordsLength = coords.length;

	const cartesianCoordinates = coords.reduce(
		(acc, location) => {
			const lat = toRadians(location.lat);
			const lng = toRadians(location.lng);

			return {
				...acc,
				x: acc.x + Math.cos(lat) * Math.cos(lng),
				y: acc.y + Math.cos(lat) * Math.sin(lng),
				z: acc.z + Math.sin(lat),
			};
		},
		{ x: 0, y: 0, z: 0 },
	);

	const average = {
		x: cartesianCoordinates.x / coordsLength,
		y: cartesianCoordinates.y / coordsLength,
		z: cartesianCoordinates.z / coordsLength,
	};

	const hyp = Math.sqrt(average.x * average.x + average.y * average.y);
	const averageLat = Math.atan2(average.z, hyp);
	const averageLng = Math.atan2(average.y, average.x);
	const lat = toDegrees(averageLat);
	const lng = toDegrees(averageLng);

	return [lat, lng] as [number, number];
};

export const onEachFeature = (feature: any, layer: any) => {
	// feature
	// stroke
	// stroke-opacity
	// stroke-width
	// fill
	// fill-color
	// marker-color

	// layer.options.color = '#cccccc';
	// layer.options.fill = true;
	// layer.options.fillColor = 'orange';

	const type: string | undefined = feature.geometry?.type;

	switch (type) {
		case 'Point':
			// const marker_color = feature.properties['marker-color'];
			// layer.options.icon.options.iconUrl = 'leaf-green.png';

			const marker_label = feature.properties['label'];

			// console.log(marker_label);

			if (marker_label !== undefined && marker_label.length > 0) {
				// layer.options.iconSize = [0, 0];
				// layer.options.shadowSize = [0, 0];

				const icon = divIcon({
					// html: marker_label,
					className: 'dummy', // Specify something to get rid of the default class.
				});

				layer.options.icon = icon;

				layer.options.icon.options.tooltipAnchor = [0, 0];

				layer.bindTooltip(marker_label, { permanent: true, direction: 'center', opacity: 0.4 });
			} else {
				// console.log('SEM TU');
				// layer.options.icon.options.iconSize = [22, 22];
				// layer.options.icon.options.shadowSize = [6, 8];
			}

			// if (marker_color) {
			// 	// layer.options.icon.options.iconSize = [135, 52];
			// 	layer.options.icon.options.iconUrl = 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.pngmarker-icon-2x-green.png';
			// 	// layer.options.title = 'true';
			// }

			// console.log(feature);
			// console.log(layer);

			break;

		case 'Polygon':
			const fill_color = feature.properties.fill;
			const fill_opacity = feature.properties['fill-opacity'];

			let fillOpacity = 0.5;
			let fillColor = '#555555';

			if (fill_opacity !== undefined) {
				fillOpacity = fill_opacity;
			}

			if (fill_color !== undefined) {
				fillColor = fill_color;
			}

			if (fillOpacity !== undefined && fillOpacity > 0) {
				layer.options.fill = true;
				layer.options.fillColor = fillColor;
				layer.options.fillOpacity = fillOpacity;
			} else {
				layer.options.fill = false;
			}

		// eslint-disable-next-line no-fallthrough
		case 'LineString':
			const stroke_color = feature.properties.stroke;
			const stroke_opacity = feature.properties['stroke-opacity'];
			const stroke_width = feature.properties['stroke-width'];

			layer.options.alt = 'text';

			let opacity = 1.0;
			let color = '#555555';
			let weight = 2;

			if (stroke_opacity !== undefined) {
				opacity = stroke_opacity;
			}

			if (stroke_color !== undefined) {
				color = stroke_color;
			}

			if (stroke_width !== undefined) {
				weight = stroke_width;
			}

			if (opacity !== undefined && opacity > 0) {
				layer.options.stroke = true;
				layer.options.color = color;
				layer.options.opacity = opacity;
				layer.options.weight = weight;
			} else {
				layer.options.stroke = false;
			}

			break;
	}

	// layer.options.interactive = false;

	// layer.options.color = 'white';
	// layer.options.fillOpacity = 1
	// layer.options.weight = 2;
	// layer.options.dashArray = 1
	// layer.options.opacity = 1
	// layer.bindPopup(feature.properties.NAMOBJ)
	// layer.on({
	//   mouseover: this.onMouseOver,
	//   mouseout: this.onMouseOut,
	//   click: this.onMouseClick
	// })
	//
	// layer.options.title = '1'; // Point toolbox text

	// console.log(feature);
	// console.log(layer);
};

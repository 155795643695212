import { action } from 'typesafe-actions';
import {
	FETCH_INDOOR_MAPS_SUCCESS,
	CREATE_INDOOR_MAP_SUCCESS,
	EDIT_INDOOR_MAP_SUCCESS,
	DELETE_INDOOR_MAP_SUCCESS,
	INDOOR_MAPS_COMMON_EMPTY_REQUEST,
	INDOOR_MAPS_COMMON_EMPTY_ERROR,
} from './inDoorMaps-constants';

import { UUID } from '../../types';
import { FiltersRequestBody2 } from '../../components/Table/types';

export const commonInDoorMapsEmptyRequest = () => action(INDOOR_MAPS_COMMON_EMPTY_REQUEST);
export const commonInDoorMapsEmptyError = () => action(INDOOR_MAPS_COMMON_EMPTY_ERROR);

export const fetchInDoorMapsSuccess = (data: any /* TODO */, options?: FiltersRequestBody2) =>
	action(FETCH_INDOOR_MAPS_SUCCESS, {
		data,
		options,
	});

export const createInDoorMapSuccess = () => action(CREATE_INDOOR_MAP_SUCCESS);

export const editInDoorMapSuccess = (data: any /* TODO */) =>
	action(EDIT_INDOOR_MAP_SUCCESS, {
		data,
	});

export const deleteInDoorMapSuccess = (id: UUID) =>
	action(DELETE_INDOOR_MAP_SUCCESS, {
		id,
	});

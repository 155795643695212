import React, { useEffect } from 'react';
import { TableHeadCell, TableRowRenderProps } from '../../../components/Table/types';
import { Table } from '../../../components/Table/Table';
import {
	DotNetIngestionEndpoint,
	CreateDotNetIngestionEndpointRequestBody,
	EditDotNetIngestionEndpointPayload,
} from '../ingestion-endpoints-types';

import {
	fetchDotNetIngestionEndpoints,
	editDotNetIngestionEndpoint,
	createDotNetIngestionEndpoint,
	deleteDotNetIngestionEndpoint,
} from '../../../store/ingestion-endpoints/ingestion-endpoints-async-actions';

import { useTranslation } from 'react-i18next';
import { FilterOperators, FilterType, TableRowActionIcon } from '../../../components/Table/constants';
import { addModalDialog, removeModalDialog } from '../../../store/app/app-actions';
import { useDispatch, useSelector } from 'react-redux';
import { IngestionDotNetEndpointForm } from './IngestionDotNetEndpointForm';
import { Tooltip, TableRow, TableCell, IconButton, CardHeader, Divider } from '@material-ui/core';
import { removeNewLines } from '../../../helpers/string';
import { UUID } from '../../../types';
import { pick } from 'lodash/fp';
import { TableActionsContainer } from '../../../components/Table/TableActionsContainer';
import { CheckRounded, Add, DeleteForever, ClearRounded } from '@material-ui/icons';
import { COLOR_THEME } from '../../../theme';
import { RootState } from '../../../store/types';
import { UserState } from '../../../store/user/user-types';
import { PermissionsDotNet } from '../../../helpers/generalConstants';
import { IngestionEndpointsState } from '../../../store/ingestion-endpoints/ingestion-endpoints-types';
import { IngestionScript } from '../../IngestionScripts/ingestion-scripts-types';
import { baseUrl } from '../../../services/client';
import { Tenant } from '../../Tenants/tenants-types';
import { hasPermission } from '../../../helpers/auth';

type Props = {
	tenants: Tenant[];
	scripts: IngestionScript[];
};

export const IngestionDotNetEndpointsTable = ({ tenants, scripts }: Props) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { permissions, tenant } = useSelector<RootState, UserState>((state) => state.user);
	const canEditEndpoints = hasPermission(PermissionsDotNet.EndpointWrite, permissions);
	const canEditTenant = tenant === undefined;
	const { endpoints } = useSelector<RootState, IngestionEndpointsState>((state) => state.ingestionEndpoints);

	useEffect(() => {
		dispatch(
			fetchDotNetIngestionEndpoints({
				limit: endpoints.limit,
				offset: endpoints.offset,
				orderings: endpoints.orderings,
				filtersAndConditions: endpoints.filters,
			}),
		);

		// Don't need to react on "config" and "filters" and deep comparision is not necessary
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const pathOrder = endpoints.orderings?.find((order) => order.column === 'path');
	const methodOrder = endpoints.orderings?.find((order) => order.column === 'method');

	const pathFilter = endpoints.filters?.find((filter) => filter.column === 'path');

	const columns: TableHeadCell[] = [
		{
			id: 'path',
			label: t('url'),
			filters: pathFilter && [
				{
					column: 'path',
					operator: FilterOperators.contains,
					value: pathFilter.value,
				},
			],
			filterType: FilterType.String,
			sort: pathOrder?.sortOrder && { column: 'path', sortOrder: pathOrder?.sortOrder },
		},
		{
			id: 'method',
			label: t('method'),
			sort: methodOrder?.sortOrder && { column: 'method', sortOrder: methodOrder?.sortOrder },
		},
		{ id: 'active', label: t('active'), unorderable: true },
		{
			id: 'script',
			label: t('script'),
			unorderable: true,
		},
	];

	if (canEditTenant) {
		columns.push({ id: 'tenant', label: t('tenant'), unorderable: true });
	}

	const handleCloseModal = (id: string) => {
		dispatch(removeModalDialog(id));
	};

	const handleEditDotNetIngestionEndpoint = (data: EditDotNetIngestionEndpointPayload, modalDialogId: string) => {
		handleCloseModal(modalDialogId);

		if (data.payloadParser) {
			const trimmedParser = removeNewLines(data.payloadParser);

			dispatch(editDotNetIngestionEndpoint({ ...data, payloadParser: trimmedParser }));
		}

		dispatch(editDotNetIngestionEndpoint({ ...data }));
	};

	const handleCreateIngestionEndpoint = (data: CreateDotNetIngestionEndpointRequestBody, modalDialogId: string) => {
		handleCloseModal(modalDialogId);

		const requestBody = pick(['method', 'path', 'active', 'tenantId', 'scriptId'], data);

		dispatch(createDotNetIngestionEndpoint(requestBody));
	};

	const handleDeleteIngestionEndpoint = (endpointId: UUID, modalDialogId: string) => {
		handleCloseModal(modalDialogId);
		dispatch(deleteDotNetIngestionEndpoint(endpointId));
	};

	const rowActions = [
		{
			id: 'btn-edit:endpoint',
			icon: TableRowActionIcon.edit,
			tooltip: t('edit'),
			onClick: (data: DotNetIngestionEndpoint) => {
				dispatch(
					addModalDialog({
						id: 'editIngestionEndpointModal',
						title: t('editIngestionEndpointModalTitle'),
						contentText: t('editIngestionEndpointModalDescription'),
						content: (
							<IngestionDotNetEndpointForm
								ingestPoint={data}
								onSubmit={(values) => {
									const baseData = pick(['id', 'deleted'], data);

									handleEditDotNetIngestionEndpoint(
										{
											...values,
											...baseData,
										},
										'editIngestionEndpointModal',
									);
								}}
								onCancel={() => handleCloseModal('editIngestionEndpointModal')}
								scripts={scripts}
								tenants={tenants}
								canEditTenant={canEditTenant}
							/>
						),
					}),
				);
			},
		},
		{
			id: 'btn-delete:endpoint',
			icon: TableRowActionIcon.delete,
			tooltip: t('delete'),
			onClick: (data: DotNetIngestionEndpoint) =>
				dispatch(
					addModalDialog({
						id: 'deleteIngestionEndpointModal',
						title: t('deleteIngestionEndpointModalTitle'),
						contentText: t('deleteIngestionEndpointModalDescription', {
							path: data.path,
						}),
						buttons: [
							{
								id: 'deleteIngestionEndpoint',
								value: t('delete'),
								onClick: () => handleDeleteIngestionEndpoint(data.id, 'deleteIngestionEndpointModal'),
								startIcon: <DeleteForever />,
							},
							{
								id: 'cancelDeleteIngestionEndpoint',
								value: t('cancel'),
								onClick: () => handleCloseModal('deleteIngestionEndpointModal'),
								variant: 'text',
							},
						],
					}),
				),
		},
	];

	const renderTableRow = ({
		onClickRow,
		row: endpoint,
		renderActions,
	}: TableRowRenderProps<DotNetIngestionEndpoint>) => {
		return (
			<TableRow key={endpoint.id} id={`row-${endpoint.id}`} hover onClick={() => onClickRow(endpoint.id)}>
				<Tooltip
					title={`${baseUrl}ingest${endpoint.payloadParser ? '' : '.net'}/endpoint${endpoint.path}`}
					placement="left"
				>
					<TableCell component="td" id={endpoint.path} scope="row">
						{endpoint.path}
					</TableCell>
				</Tooltip>
				<TableCell component="td" id={String(endpoint.method)} scope="row">
					{endpoint.method}
				</TableCell>
				<TableCell component="td" id={`${endpoint.id}-${endpoint.active}`} scope="row">
					{endpoint.active ? (
						<CheckRounded htmlColor={COLOR_THEME.success} data-cy="check:active" />
					) : (
						<ClearRounded htmlColor={COLOR_THEME.error} data-cy="check:inactive" />
					)}
				</TableCell>
				<TableCell component="td" id={`${endpoint.id}-${endpoint.active}`} scope="row">
					{scripts.find((script) => script.id === endpoint.scriptId)?.name}
				</TableCell>
				{canEditTenant && (
					<TableCell component="td" id={endpoint.tenantId} scope="row">
						{tenants?.find((tenant) => tenant.id === endpoint.tenantId)?.name}
					</TableCell>
				)}

				{canEditEndpoints && (
					<TableCell padding="none" sortDirection={false} align="right">
						<TableActionsContainer>{renderActions(rowActions, endpoint)}</TableActionsContainer>
					</TableCell>
				)}
			</TableRow>
		);
	};

	return (
		<>
			<CardHeader
				title={t('ingestionEndpoints')}
				action={
					<>
						{canEditEndpoints && (
							<Tooltip
								title={String(t('createIngestionEndpoint'))}
								aria-label={t('createIngestionEndpoint')}
							>
								<IconButton
									onClick={() =>
										dispatch(
											addModalDialog({
												id: 'createIngestionEndpointModal',
												title: t('createIngestionEndpointModalTitle'),
												contentText: t('createIngestionEndpointModalDescription'),
												content: (
													<IngestionDotNetEndpointForm
														onSubmit={(values) =>
															handleCreateIngestionEndpoint(
																values,
																'createIngestionEndpointModal',
															)
														}
														onCancel={() =>
															handleCloseModal('createIngestionEndpointModal')
														}
														scripts={scripts}
														tenants={tenants}
														canEditTenant={canEditTenant}
													/>
												),
											}),
										)
									}
									data-cy="btn-create:endpoints"
								>
									<Add />
								</IconButton>
							</Tooltip>
						)}
						{/* <IngestionEndpointsTableFilters
							config={options.config}
							filters={options.filters as IngestionEndpointsFilters}
						/> */}
					</>
				}
			/>
			<Divider light />
			<Table
				columns={columns}
				orderings={endpoints.orderings}
				filtersAndConditions={endpoints.filters}
				data={endpoints.data}
				dataLimit={endpoints.limit}
				dataOffset={endpoints.offset}
				numberOfRows={endpoints.total}
				fetchAction2={fetchDotNetIngestionEndpoints}
				actions={canEditEndpoints ? rowActions : undefined}
				renderTableRow={renderTableRow}
			/>
		</>
	);
};

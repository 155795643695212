import { action } from 'typesafe-actions';
import {
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	LOGIN_ERROR,
	LOGOUT_REQUEST,
	LOGOUT_SUCCESS,
	LOGOUT_ERROR,
	CHANGE_PROJECT_REQUEST,
	CHANGE_PROJECT_ERROR,
	CHANGE_PROJECT_SUCCESS,
	FETCH_USER_PROJECTS_REQUEST,
	FETCH_USER_PROJECTS_SUCCESS,
	FETCH_USER_PROJECTS_ERROR,
	SET_USER_PERMISSIONS,
	SET_MAP_FAVOURITES,
	FETCH_USER_RELATIONS_SUCCESS,
	CREATE_USER_RELATION_SUCCESS,
	EDIT_USER_RELATION_SUCCESS,
	DELETE_USER_RELATION_SUCCESS,
	USER_COMMON_EMPTY_REQUEST,
	USER_COMMON_EMPTY_ERROR,
	ADD_USER_PROJECT_RELATIONS_SUCCESS,
} from './user-constants';

import { UserAuth } from '../../services/user/user-types';
import { ProjectsResponseData } from '../../pages/Projects/projects-types';
import { Favourite } from '../../pages/Devices/components/DevicesAndZonesMap';
import { FiltersRequestBody2 } from '../../components/Table/types';
import { UserRelationsResponseData } from './user-types';

export const commonUserEmptyRequest = () => action(USER_COMMON_EMPTY_REQUEST);
export const commonUserEmptyError = () => action(USER_COMMON_EMPTY_ERROR);

export const loginRequest = () => action(LOGIN_REQUEST);
export const loginSuccess = (token: string, data: UserAuth) =>
	action(LOGIN_SUCCESS, {
		token,
		data,
	});

export const loginError = () => action(LOGIN_ERROR);
export const logoutRequest = () => action(LOGOUT_REQUEST);
export const logoutSuccess = () => action(LOGOUT_SUCCESS);
export const logoutError = () => action(LOGOUT_ERROR);

export const changeProjectRequest = () => action(CHANGE_PROJECT_REQUEST);
export const changeProjectSuccess = (token: string, data: UserAuth) =>
	action(CHANGE_PROJECT_SUCCESS, {
		token,
		data,
	});
export const changeProjectError = () => action(CHANGE_PROJECT_ERROR);

export const fetchUserProjectsRequest = () => action(FETCH_USER_PROJECTS_REQUEST);
export const fetchUserProjectsSuccess = (data: ProjectsResponseData) =>
	action(FETCH_USER_PROJECTS_SUCCESS, {
		data,
	});
export const fetchUserProjectsError = () => action(FETCH_USER_PROJECTS_ERROR);

export const setUserPermissions = (permissions: string) => action(SET_USER_PERMISSIONS, { permissions });

export const setMapFavourites = (favourites: Favourite[]) => action(SET_MAP_FAVOURITES, { favourites });

export const fetchUserRelationsSuccess = (data: UserRelationsResponseData, options?: FiltersRequestBody2) =>
	action(FETCH_USER_RELATIONS_SUCCESS, {
		data,
		options,
	});

export const createUserRelationSuccess = () => action(CREATE_USER_RELATION_SUCCESS);

export const addUserProjectRelationsSuccess = () => action(ADD_USER_PROJECT_RELATIONS_SUCCESS);

export const editUserRelationSuccess = () => action(EDIT_USER_RELATION_SUCCESS);

export const deleteUserRelationSuccess = () => action(DELETE_USER_RELATION_SUCCESS);

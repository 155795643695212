import { action } from 'typesafe-actions';
import {
	REVIEW_COMMON_EMPTY_REQUEST,
	REVIEW_COMMON_EMPTY_ERROR,
	FETCH_REPORT_ORDERS_SUCCESS,
	CREATE_REPORT_ORDER_SUCCESS,
	EDIT_REPORT_ORDER_SUCCESS,
	DELETE_REPORT_ORDER_SUCCESS,
	GET_REPORT_ORDER_DEFINITION_SUCCESS,
	GET_REPORT_ORDER_DEFINITIONS_NAMES_SUCCESS,
	FETCH_STANDING_REPORT_ORDERS_SUCCESS,
	CREATE_STANDING_REPORT_ORDER_SUCCESS,
	DELETE_STANDING_REPORT_ORDER_SUCCESS,
	EDIT_STANDING_REPORT_ORDER_SUCCESS,
} from './review-constants';

import { UUID } from '../../types';
import { FiltersRequestBody2 } from '../../components/Table/types';
import {
	ReportOrder,
	ReviewDefinition,
	ReviewDefinitionName,
	ReportOrdersResponseData,
	StandingReportOrder,
	StandingReportOrdersResponseData,
} from '../../pages/Review/review-types';

export const reviewCommonEmptyRequest = () => action(REVIEW_COMMON_EMPTY_REQUEST);
export const reviewCommonEmptyError = () => action(REVIEW_COMMON_EMPTY_ERROR);

export const fetchReportOrdersSuccess = (data?: ReportOrdersResponseData, options?: FiltersRequestBody2) =>
	action(FETCH_REPORT_ORDERS_SUCCESS, {
		data,
		options,
	});

export const createReportOrderSuccess = (data: ReportOrder) =>
	action(CREATE_REPORT_ORDER_SUCCESS, {
		data,
	});

export const editReportOrderSuccess = (data: ReportOrder) =>
	action(EDIT_REPORT_ORDER_SUCCESS, {
		data,
	});

export const deleteReportOrderSuccess = (id: UUID) =>
	action(DELETE_REPORT_ORDER_SUCCESS, {
		id,
	});

export const getReportOrderDefinitionSuccess = (data: ReviewDefinition) =>
	action(GET_REPORT_ORDER_DEFINITION_SUCCESS, {
		data,
	});

export const getReportOrderDefinitionsNamesSuccess = (data: ReviewDefinitionName[]) =>
	action(GET_REPORT_ORDER_DEFINITIONS_NAMES_SUCCESS, {
		data,
	});

export const fetchStandingReportOrdersSuccess = (
	data?: StandingReportOrdersResponseData,
	options?: FiltersRequestBody2,
) =>
	action(FETCH_STANDING_REPORT_ORDERS_SUCCESS, {
		data,
		options,
	});

export const createStandingReportOrderSuccess = (data: StandingReportOrder) =>
	action(CREATE_STANDING_REPORT_ORDER_SUCCESS, {
		data,
	});

export const editStandingReportOrderSuccess = (data: StandingReportOrder) =>
	action(EDIT_STANDING_REPORT_ORDER_SUCCESS, {
		data,
	});

export const deleteStandingReportOrderSuccess = (id: UUID) =>
	action(DELETE_STANDING_REPORT_ORDER_SUCCESS, {
		id,
	});

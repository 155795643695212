import { action } from 'typesafe-actions';
import {
	GENERAL_CONTACT_GROUPS_REQUEST,
	GENERAL_CONTACT_GROUPS_ERROR,
	FETCH_CONTACT_GROUPS_SUCCESS,
	CREATE_CONTACT_GROUP_SUCCESS,
	UPDATE_CONTACT_GROUP_SUCCESS,
	DELETE_CONTACT_GROUP_SUCCESS,
	GET_CONTACT_GROUP_DETAIL,
} from './contact-groups-constants';

import { UUID } from '../../types';
import {
	GroupsResponseData,
	GroupResponseData,
	UpdateGroupResponseData,
} from '../../pages/ContactGroups/contact-groups-types';
import { FiltersRequestBody2 } from '../../components/Table/types';

// general request action with no impact but starting the progress bar
export const generalContactGroupsRequest = () => action(GENERAL_CONTACT_GROUPS_REQUEST);
// general error action with no impact but stopping the progress bar
export const generalContactGroupsError = () => action(GENERAL_CONTACT_GROUPS_ERROR);

export const fetchContactGroupsSuccess = (data: GroupsResponseData, options?: FiltersRequestBody2) =>
	action(FETCH_CONTACT_GROUPS_SUCCESS, { data, options });

export const createContactGroupSuccess = () => action(CREATE_CONTACT_GROUP_SUCCESS);

export const updateContactGroupSuccess = (data: UpdateGroupResponseData) =>
	action(UPDATE_CONTACT_GROUP_SUCCESS, { data });

export const deleteContactGroupSuccess = (contactGroupId: UUID) =>
	action(DELETE_CONTACT_GROUP_SUCCESS, { contactGroupId });

export const getContactGroupDetail = (data: GroupResponseData) => action(GET_CONTACT_GROUP_DETAIL, { data });

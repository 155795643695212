import React from 'react';
import { format } from 'date-fns';
import { isNil } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { Divider, IconButton, Table, TableBody, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { INPUT_DATE_TIME_MASK } from '../../../helpers/generalConstants';
import { DeviceResponseData2 } from '../devices-types';
import { DetailCard } from './DetailCard';
import { EditRounded } from '@material-ui/icons';
import { DevicePropertiesForm, DevicePropertiesFormValues } from './DevicePropertiesForm';
import { addModalDialog, removeModalDialog } from '../../../store/app/app-actions';
import { useDispatch } from 'react-redux';
import { editDeviceProperties } from '../../../store/devices/devices-async-actions';

type Props = {
	deviceData: DeviceResponseData2;
};

export const DeviceDetailLocationProperties = ({ deviceData }: Props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { data, deviceProperty } = deviceData;
	const { lat, lng, time, accuracy, moved, location_update_details } = data || {};

	function handleDeviceProperties(values: DevicePropertiesFormValues, modalDialogId: string) {
		handleCloseModalDialog(modalDialogId);
		dispatch(editDeviceProperties(values));
	}

	function handleCloseModalDialog(id: string) {
		dispatch(removeModalDialog(id));
	}

	const handleEditDeviceProperties = () => {
		dispatch(
			addModalDialog({
				id: 'devicePropertiesModal',
				title: t('devicePropertiesModalTitle'),
				content: (
					<DevicePropertiesForm
						values={{ deviceId: deviceData.id, deviceProperty: deviceProperty ?? {} }}
						onSubmit={(values: DevicePropertiesFormValues) =>
							handleDeviceProperties(values, 'devicePropertiesModal')
						}
						onCancel={() => handleCloseModalDialog('devicePropertiesModal')}
					/>
				),
			}),
		);
	};

	return (
		<DetailCard
			title={t('lastLocation')}
			value={
				<>
					<Tooltip title={t('devicePropertiesModalTitle') as string}>
						<IconButton
							edge="end"
							aria-label={t('devicePropertiesModal')}
							onClick={handleEditDeviceProperties}
						>
							<EditRounded />
						</IconButton>
					</Tooltip>
				</>
			}
		>
			<Table size="small">
				<TableBody>
					<TableRow>
						<TableCell>
							<strong>{t('lastLocationTime')}</strong>
						</TableCell>
						<TableCell align="right">
							{isNil(time) ? t('notAvailable') : format(new Date(time), INPUT_DATE_TIME_MASK)}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<strong>{t('latitude')}</strong>
						</TableCell>
						<TableCell align="right">{lat ? lat : t('notAvailable')}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<strong>{t('longitude')}</strong>
						</TableCell>
						<TableCell align="right">{lng ? lng : t('notAvailable')}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<strong>{t('accuracy')}</strong>
						</TableCell>
						<TableCell align="right">{isNil(accuracy) ? t('notAvailable') : accuracy}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<strong>{t('moved')}</strong>
						</TableCell>
						<TableCell align="right">{moved ? t('yes') : t('no')}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<strong>{t('locationUpdateDetails')}</strong>
						</TableCell>
						<TableCell align="right">
							{location_update_details ? location_update_details : t('notAvailable')}
						</TableCell>
					</TableRow>
					{deviceProperty && (
						<>
							<Divider light />
							<TableRow>
								<TableCell>
									<strong>{t('staticLatitute')}</strong>
								</TableCell>
								<TableCell align="right">
									{deviceProperty.lat ? deviceProperty.lat : t('notAvailable')}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<strong>{t('staticLongitute')}</strong>
								</TableCell>
								<TableCell align="right">
									{deviceProperty.lng ? deviceProperty.lng : t('notAvailable')}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<strong>{t('staticAccuracy')}</strong>
								</TableCell>
								<TableCell align="right">
									{deviceProperty.accuracy ? deviceProperty.accuracy : t('notAvailable')}
								</TableCell>
							</TableRow>
						</>
					)}
				</TableBody>
			</Table>
		</DetailCard>
	);
};

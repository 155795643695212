import { apiClient } from '../client';
import {
	WorkflowsResponseData,
	Workflow,
	CreateWorkflowBody,
	ValidateWorkflowResponse,
	ValidateWorkflowRequestBody,
	TestWorkflowRequestBody,
	WorkflowPipelines,
} from '../../pages/Workflows/workflows-types';
import { FiltersRequestBody2 } from '../../components/Table/types';
import { UUID } from '../../types';

const baseUrl = '/workflow.net/workflows';

export const getWorkflows = (body: FiltersRequestBody2) =>
	apiClient.post<WorkflowsResponseData>(`${baseUrl}/filter`, body);

export const createWorkflow = (body: CreateWorkflowBody) => apiClient.post<Workflow>(baseUrl, body);

export const editWorkflow = (body: CreateWorkflowBody, id: UUID) => apiClient.put<Workflow>(`${baseUrl}/${id}`, body);

export const deleteWorkflow = (workflowId: UUID) => apiClient.delete<void>(`${baseUrl}/${workflowId}`);

//

export const validateWorkflowScript = (body: ValidateWorkflowRequestBody) =>
	apiClient.post<ValidateWorkflowResponse>(`${baseUrl}/compile`, body);

export const testWorkflowScript = (body: TestWorkflowRequestBody) =>
	apiClient.post<ValidateWorkflowResponse>(`${baseUrl}/test`, body);

//

export const getWorkflowPipelines = (body: FiltersRequestBody2) =>
	apiClient.post<WorkflowPipelines[]>(`${baseUrl}/pipelines/filter`, body);

import React from 'react';
import { Battery20Rounded, Battery50Rounded, Battery80Rounded, BatteryFullRounded } from '@material-ui/icons';

type Props = {
	value: number;
};

export const BatteryIcon = ({ value }: Props) => {
	const style = { verticalAlign: 'sub' };
	const fontSize = 'small';

	if (value >= 90) return <BatteryFullRounded fontSize={fontSize} style={style} />;

	if (value >= 70) return <Battery80Rounded fontSize={fontSize} style={style} />;

	if (value >= 40) return <Battery50Rounded fontSize={fontSize} style={style} />;

	return <Battery20Rounded fontSize={fontSize} style={style} />;
};

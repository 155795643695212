export const USER_COMMON_EMPTY_REQUEST = '[USER_ACTIONS] USER_COMMON_EMPTY_REQUEST';
export const USER_COMMON_EMPTY_ERROR = '[USER_ACTIONS] USER_COMMON_EMPTY_ERROR';

export const LOGIN_REQUEST = '[USER_ACTIONS] LOGIN_REQUEST';
export const LOGIN_SUCCESS = '[USER_ACTIONS] LOGIN_SUCCESS';
export const LOGIN_ERROR = '[USER_ACTIONS] LOGIN_ERROR';

export const LOGOUT_REQUEST = '[USER_ACTIONS] LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = '[USER_ACTIONS] LOGOUT_SUCCESS';
export const LOGOUT_ERROR = '[USER_ACTIONS] LOGOUT_ERROR';

export const CHANGE_PROJECT_REQUEST = '[USER_ACTIONS] CHANGE_PROJECT_REQUEST';
export const CHANGE_PROJECT_SUCCESS = '[USER_ACTIONS] CHANGE_PROJECT_SUCCESS';
export const CHANGE_PROJECT_ERROR = '[USER_ACTIONS] CHANGE_PROJECT_ERROR';

export const FETCH_USER_PROJECTS_REQUEST = '[USER_ACTIONS] FETCH_USER_PROJECTS_REQUEST';
export const FETCH_USER_PROJECTS_SUCCESS = '[USER_ACTIONS] FETCH_USER_PROJECTS_SUCCESS';
export const FETCH_USER_PROJECTS_ERROR = '[USER_ACTIONS] FETCH_USER_PROJECTS_ERROR';

export const SET_USER_PERMISSIONS = '[USER_ACTIONS] SET_USER_PERMISSIONS';

export const SET_MAP_FAVOURITES = '[USER_ACTIONS] SET_MAP_FAVOURITES';

export const FETCH_USER_RELATIONS_SUCCESS = '[USER_ACTIONS] FETCH_USER_RELATIONS_SUCCESS';
export const CREATE_USER_RELATION_SUCCESS = '[USER_ACTIONS] CREATE_USER_RELATION_SUCCESS';
export const EDIT_USER_RELATION_SUCCESS = '[USER_ACTIONS] EDIT_USER_RELATION_SUCCESS';
export const DELETE_USER_RELATION_SUCCESS = '[USER_ACTIONS] DELETE_USER_RELATION_SUCCESS';
export const ADD_USER_PROJECT_RELATIONS_SUCCESS = '[USER_ACTIONS] ADD_USER_PROJECT_RELATIONS_SUCCESS';

import * as userService from '../../services/user/user-service';
import * as projectsService from '../../services/projects/projects-service';
import { LoginRequestPayload, LoginResponseData, UserAuth, ChangeProjectPayload } from '../../services/user/user-types';

import {
	loginRequest,
	loginSuccess,
	logoutRequest,
	logoutSuccess,
	logoutError,
	loginError,
	changeProjectSuccess,
	changeProjectError,
	fetchUserProjectsRequest,
	fetchUserProjectsSuccess,
	fetchUserProjectsError,
	setUserPermissions,
	commonUserEmptyRequest,
	commonUserEmptyError,
	fetchUserRelationsSuccess,
	createUserRelationSuccess,
	editUserRelationSuccess,
	deleteUserRelationSuccess,
	addUserProjectRelationsSuccess,
} from './user-actions';

import { setRedirectUrl, addToast } from '../app/app-actions';
import { fetchRequest, isStatusSuccess, createToastError } from '../../services/helpers';
import { AsyncAction } from '../types';
import { RouteEnum } from '../../router/Routes';
import JwtDecode from 'jwt-decode';
import { ProjectsResponseData } from '../../pages/Projects/projects-types';
import { FiltersRequestBody2 } from '../../components/Table/types';
import {
	CreateUserRelationRequestBody,
	EditUserRelationRequestBody,
	UserRelation,
	UserRelationsResponseData,
} from './user-types';
import { omit } from 'lodash';
import { UUID } from '../../types';

export const logIn: AsyncAction<LoginRequestPayload> = (loginRequestPayload) => async (dispatch) => {
	dispatch(loginRequest());

	const request = userService.logIn(loginRequestPayload as LoginRequestPayload);
	const { data, error } = await fetchRequest<LoginResponseData>(request);

	let decoded;

	if (data?.accessToken) {
		try {
			decoded = JwtDecode<UserAuth>(data.accessToken);
		} catch (exception) {}
	}

	if (data && decoded) {
		const permissions = decoded.permissions;

		dispatch(loginSuccess(data.accessToken, decoded));
		dispatch(setRedirectUrl(RouteEnum.DASHBOARD));
		dispatch(setUserPermissions(permissions));
	}

	if (error) {
		dispatch(loginError());

		// if (status >= 400 && status < 500) {
		// dispatch(logInTsTmp(loginRequestPayload));
		// } else {
		// 	dispatch(addToast(createToastError(error.data)));
		// }
	}
};

export const logOut: AsyncAction<void> = () => async (dispatch) => {
	dispatch(logoutRequest());

	const request = userService.logOut();
	const { status, error } = await fetchRequest<void>(request);

	if (isStatusSuccess(status)) {
		window.localStorage.clear();
		window.sessionStorage.clear();
		dispatch(logoutSuccess());
		dispatch(setRedirectUrl(RouteEnum.LOGIN));
	}

	if (error) {
		dispatch(logoutError());
		dispatch(addToast(createToastError(error.data)));
	}
};

export const changeProject: AsyncAction<ChangeProjectPayload> = (changePayload) => async (dispatch) => {
	dispatch(loginRequest());

	const request = userService.changeProject(changePayload);
	const { data, error } = await fetchRequest<string>(request);

	if (data) {
		const decoded = JwtDecode<UserAuth>(data);
		const permissions = decoded.permissions;

		dispatch(changeProjectSuccess(data, decoded));
		dispatch(setUserPermissions(permissions));
	}

	if (error) {
		dispatch(changeProjectError());
		dispatch(addToast(createToastError(error.data)));
	}
};

// Same API as projects from projects page (table), but for navigation we need all projects and in separate state
// -> so that filtering projects table doesn't affect projects in navigation
export const fetchUserProjects: AsyncAction<void> = () => async (dispatch) => {
	dispatch(fetchUserProjectsRequest());

	const request = projectsService.getProjects({
		// Should be enough to get all zones, even for super admin
		limit: 200,
		offset: 0,
		orderings: [],
		filtersAndConditions: [],
	});

	const { data, error } = await fetchRequest<ProjectsResponseData>(request);

	if (data) {
		dispatch(fetchUserProjectsSuccess(data));
	} else {
		dispatch(fetchUserProjectsError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(fetchUserProjects());

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const fetchUserRelations: AsyncAction<FiltersRequestBody2> = (userRelationsPayload) => async (dispatch) => {
	dispatch(commonUserEmptyRequest());

	const request = userService.getFilteredUserRelations(userRelationsPayload);
	const { data, error } = await fetchRequest<UserRelationsResponseData>(request);

	if (data) {
		dispatch(fetchUserRelationsSuccess(data, userRelationsPayload));
	} else {
		dispatch(commonUserEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(fetchUserRelations(userRelationsPayload));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const createUserRelation: AsyncAction<CreateUserRelationRequestBody> = (userRelation) => async (
	dispatch,
	getState,
) => {
	dispatch(commonUserEmptyRequest());

	const { user } = getState();
	const request = userService.createUserRelation(userRelation);
	const { data, status, error } = await fetchRequest<UserRelation>(request);

	if (isStatusSuccess(status) && data) {
		dispatch(createUserRelationSuccess());
		dispatch(
			fetchUserRelations({
				limit: user.userRelations.limit,
				offset: user.userRelations.offset,
				orderings: user.userRelations.orderings,
				filtersAndConditions: user.userRelations.filters,
			}),
		);
	} else {
		dispatch(commonUserEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(createUserRelation(userRelation));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const addUserProjectRelations: AsyncAction<{ userId: UUID; projectId: UUID }[]> = (relations) => async (
	dispatch,
	getState,
) => {
	dispatch(commonUserEmptyRequest());

	const { user } = getState();
	const request = userService.addUserProjectRelations(relations);

	const { data, status, error } = await fetchRequest<UserRelation>(request);

	if (isStatusSuccess(status) && data) {
		dispatch(addUserProjectRelationsSuccess());
		dispatch(
			fetchUserRelations({
				limit: user.userRelations.limit,
				offset: user.userRelations.offset,
				orderings: user.userRelations.orderings,
				filtersAndConditions: user.userRelations.filters,
			}),
		);
	} else {
		dispatch(commonUserEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(addUserProjectRelations(relations));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const editUserRelation: AsyncAction<EditUserRelationRequestBody> = (userRelation) => async (
	dispatch,
	getState,
) => {
	dispatch(commonUserEmptyRequest());

	const { user } = getState();
	const body = omit(userRelation, ['id']);
	const request = userService.editUserRelation(body, userRelation.id);
	const { data, error } = await fetchRequest<UserRelation>(request);

	if (data) {
		dispatch(editUserRelationSuccess());
		dispatch(
			fetchUserRelations({
				limit: user.userRelations.limit,
				offset: user.userRelations.offset,
				orderings: user.userRelations.orderings,
				filtersAndConditions: user.userRelations.filters,
			}),
		);
	} else {
		dispatch(commonUserEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(editUserRelation(userRelation));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const deleteUserRelation: AsyncAction<UUID> = (userRelationId) => async (dispatch, getState) => {
	dispatch(commonUserEmptyRequest());

	const { user } = getState();
	const request = userService.deleteUserRelation(userRelationId);
	const { status, error } = await fetchRequest<void>(request);

	if (isStatusSuccess(status) && userRelationId) {
		dispatch(deleteUserRelationSuccess());
		dispatch(
			fetchUserRelations({
				limit: user.userRelations.limit,
				offset: user.userRelations.offset,
				orderings: user.userRelations.orderings,
				filtersAndConditions: user.userRelations.filters,
			}),
		);
	} else {
		dispatch(commonUserEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(deleteUserRelation(userRelationId));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

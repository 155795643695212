/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import {
	Card,
	CardActionArea,
	CardContent,
	CardHeader,
	Grid,
	IconButton,
	makeStyles,
	Theme,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/types';
import { UserState } from '../../store/user/user-types';
import { BackButton } from '../../components/Button/BackButton';
import { useParams } from 'react-router-dom';
import {
	activityTypes,
	CreatePipelineRuleRequestBody,
	Pipeline,
	PipelineRule,
	PipelinesResponseData,
	severityTypes,
	UpdatePipelineRuleRequestBody,
} from './pipelines-types';
import { FilterOperators } from '../../components/Table/constants';
import { fetchRequest } from '../../services/helpers';
import {
	createPipelineRule,
	deletePipelineRule,
	fetchPipelines,
	updatePipelineRule,
	updatePipelineRuleContactGroups,
} from '../../services/pipelines/pipelines-service';
import { useTranslation } from 'react-i18next';
import { hasPermission } from '../../helpers/auth';
import { PermissionsDotNet } from '../../helpers/generalConstants';
import { addModalDialog, removeModalDialog } from '../../store/app/app-actions';
import { Add, DeleteRounded, EditRounded } from '@material-ui/icons';
import { PipelineRuleForm, PipelineRuleFormValues } from './components/PipelineRuleForm';
import { PipelineRuleContactGroupsForm } from './components/PipelineRuleContactGroupsForm';
import { UUID } from '../../types';
import { timeStringToStringTimeSpan } from '../helpers';

const useStyles = makeStyles(({ spacing }: Theme) => ({
	marginTop: {
		marginTop: spacing(2),
	},
}));

type RouteParams = {
	pipelineId: string;
};

export const PipelineDetail = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { permissions } = useSelector<RootState, UserState>((state) => state.user);
	const canEditPipelines = hasPermission(PermissionsDotNet.PipelineWrite, permissions);
	const { pipelineId } = useParams<RouteParams>();
	const [pipeline, setPipeline] = useState<Pipeline | undefined>();
	const [update, setUpdate] = useState({});

	useEffect(() => {
		const request = fetchPipelines({
			// Should be enough to get all definitions
			limit: 0,
			offset: 0,
			orderings: [],
			filtersAndConditions: [
				{
					column: 'id',
					operator: FilterOperators.equals,
					value: pipelineId,
				},
			],
		});

		(async () => {
			const { data, error } = await fetchRequest<PipelinesResponseData>(request);

			if (error) {
				console.log(error);
				// TODO show error
			} else {
				if (data && data.data && data.data.length > 0) {
					setPipeline(data.data[0]);
				}
			}
		})();

		/// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pipelineId, update]);

	const handleCloseModalDialog = (id: string) => {
		dispatch(removeModalDialog(id));
	};

	const handleCreateRule = (data: CreatePipelineRuleRequestBody, modalDialogId: string) => {
		handleCloseModalDialog(modalDialogId);

		const request = createPipelineRule(data);

		(async () => {
			const { data, error } = await fetchRequest<PipelineRule>(request);

			if (error) {
				console.log(error);
			} else {
				if (data) {
					setUpdate({});
				}
			}
		})();
	};

	const handleUpdateRuleContactGroups = (data: UUID[], ruleId: UUID, modalDialogId: string) => {
		handleCloseModalDialog(modalDialogId);

		const request = updatePipelineRuleContactGroups(data, pipelineId, ruleId);

		(async () => {
			const { error } = await fetchRequest<PipelineRule>(request);

			if (error) {
				console.log(error);
			} else {
				setUpdate({});
			}
		})();
	};

	const handleUpdateRule = (data: UpdatePipelineRuleRequestBody, modalDialogId: string) => {
		handleCloseModalDialog(modalDialogId);

		const request = updatePipelineRule(data, pipelineId, data.id);

		(async () => {
			const { error } = await fetchRequest<PipelineRule>(request);

			if (error) {
				console.log(error);
			} else {
				setUpdate({});
			}
		})();
	};

	const handleDeleteRule = (ruleId: string, modalDialogId: string) => {
		handleCloseModalDialog(modalDialogId);

		const request = deletePipelineRule(pipelineId, ruleId);

		(async () => {
			const { error } = await fetchRequest<void>(request);

			if (error) {
				console.log(error);
			} else {
				setUpdate({});
			}
		})();
	};

	function drawRule(rule: PipelineRule) {
		return (
			<Grid item xs={10}>
				<Card>
					<CardContent>
						<Grid container xs={12}>
							<Grid item xs={4}>
								<CardContent>
									<Typography variant="body2">{t('severity')}</Typography>
									<Typography variant="subtitle1">
										{severityTypes.find((severity) => severity.id == rule.severity)?.label}
									</Typography>
								</CardContent>
								<CardContent>
									<Typography variant="body2">{t('activity')}</Typography>
									<Typography variant="subtitle1">
										{activityTypes.find((activity) => activity.id == rule.activity)?.label}
									</Typography>
								</CardContent>
							</Grid>
							<Grid item xs={4}>
								<CardContent>
									<Typography variant="body2">{t('escalationPipeline')}</Typography>
									<Typography variant="subtitle1">{rule.escalationPipelineName ?? '-'}</Typography>
								</CardContent>
								<CardContent>
									<Typography variant="body2">{t('confirmTo')}</Typography>
									<Typography variant="subtitle1">
										{timeStringToStringTimeSpan(rule.confirmTo) ?? '-'}
									</Typography>
								</CardContent>
							</Grid>
							<Grid item xs={4}>
								<Card>
									<CardHeader
										// title={t('contactGroups')}
										subheader={t('contactGroups')}
										action={
											<>
												{canEditPipelines && (
													<Tooltip
														title={String(t('editRuleGroup'))}
														aria-label={t('editRuleGroup')}
													>
														<IconButton
															onClick={() =>
																dispatch(
																	addModalDialog({
																		id: 'editPipelineRuleContactGroupsModal',
																		title: t('editPipelineRuleContactGroupsModal'),
																		contentText: '',
																		content: (
																			<PipelineRuleContactGroupsForm
																				defaultValues={rule.contactGroups}
																				onSubmit={(values) =>
																					handleUpdateRuleContactGroups(
																						values,
																						rule.id,
																						'editPipelineRuleContactGroupsModal',
																					)
																				}
																				onCancel={() =>
																					handleCloseModalDialog(
																						'editPipelineRuleContactGroupsModal',
																					)
																				}
																			/>
																		),
																	}),
																)
															}
															data-cy="btn-edit:rule-group"
														>
															<EditRounded />
														</IconButton>
													</Tooltip>
												)}
											</>
										}
									/>
									<CardContent>
										{rule.contactGroups?.map((contactGroup) => {
											return (
												<Typography key={contactGroup.id} variant="body2">
													{contactGroup.name}
												</Typography>
											);
										})}
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</CardContent>
					<CardActionArea>
						{canEditPipelines && (
							<>
								<Tooltip title={String(t('editRule'))} aria-label={t('editRule')}>
									<IconButton
										onClick={() =>
											dispatch(
												addModalDialog({
													id: 'editPipelineRuleModal',
													title: t('editPipelineRuleModal'),
													contentText: '',
													content: (
														<PipelineRuleForm
															pipelineId={pipelineId}
															defaultValues={rule}
															onSubmit={(values) =>
																handleUpdateRule(
																	{ ...values, id: rule.id },
																	'editPipelineRuleModal',
																)
															}
															onCancel={() =>
																handleCloseModalDialog('editPipelineRuleModal')
															}
														/>
													),
												}),
											)
										}
										data-cy="btn-edit:rule"
									>
										<EditRounded />
									</IconButton>
								</Tooltip>
								<Tooltip title={String(t('deleteRule'))} aria-label={t('deleteRule')}>
									<IconButton
										onClick={() =>
											dispatch(
												addModalDialog({
													id: 'deletePipelineRuleModal',
													title: t('deletePipelineRuleModalTitle'),
													contentText: t('deletePipelineRuleModalDescription', {
														name: rule.id, // TODO
													}),
													buttons: [
														{
															id: 'deletePipelineRule',
															value: t('delete'),
															onClick: () =>
																handleDeleteRule(rule.id, 'deletePipelineRuleModal'),
															startIcon: <DeleteRounded />,
														},
														{
															id: 'cancelDeletePipelineRule',
															value: t('cancel'),
															onClick: () =>
																handleCloseModalDialog('deletePipelineRuleModal'),
															variant: 'text',
														},
													],
												}),
											)
										}
										data-cy="btn-delete:rule"
									>
										<DeleteRounded />
									</IconButton>
								</Tooltip>
							</>
						)}
					</CardActionArea>
				</Card>
			</Grid>
		);
	}

	return (
		<Grid container spacing={2} justifyContent="center">
			<Grid item xs={12}>
				<BackButton />
			</Grid>
			<Grid container spacing={2} justifyContent="center" className={classes.marginTop}>
				<Grid item xs={10}>
					<Card>
						<CardHeader
							title={pipeline?.name}
							action={
								<>
									{canEditPipelines && (
										<Tooltip title={String(t('createRule'))} aria-label={t('createRule')}>
											<IconButton
												onClick={() =>
													dispatch(
														addModalDialog({
															id: 'createRuleModal',
															title: t('createRuleModalTitle'),
															contentText: '',
															content: (
																<PipelineRuleForm
																	pipelineId={pipelineId}
																	onSubmit={(values: PipelineRuleFormValues) =>
																		handleCreateRule(values, 'createRuleModal')
																	}
																	onCancel={() =>
																		handleCloseModalDialog('createRuleModal')
																	}
																/>
															),
														}),
													)
												}
												data-cy="btn-create:group"
											>
												<Add />
											</IconButton>
										</Tooltip>
									)}
								</>
							}
						/>
					</Card>
				</Grid>
				{pipeline?.rules?.map((rule) => drawRule(rule))}
			</Grid>
		</Grid>
	);
};

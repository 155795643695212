import React from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { Box } from '@material-ui/core';
import { Form } from '../../../components/Form/Form';
import { InputField } from '../../../components/Form/InputField';
import { Button } from '../../../components/Button/Button';
import { PASSWORD_REGEX } from '../../../helpers/generalConstants';

type FormValues = {
	password: string;
	newPassword: string;
};

type Props = {
	onSubmit: (password: string) => void;
};

export const ChangePasswordForm = ({ onSubmit }: Props) => {
	const { t } = useTranslation();
	const validationSchema = yup.object().shape({
		password: yup.string().required(t('validationErrors.required')),
		newPassword: yup
			.string()
			.matches(PASSWORD_REGEX, t('validationErrors.invalidPassword'))
			.notOneOf([yup.ref('password')], t('validationErrors.newPasswordMatchesOldPassword'))
			.required(t('validationErrors.required')),
	});

	const { control, handleSubmit, errors, reset } = useForm<FormValues>({
		mode: 'onBlur',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			password: '',
			newPassword: '',
		},
	});

	const handleSubmitForm = (values: FormValues) => {
		onSubmit(values.newPassword);
		reset();
	};

	return (
		<Form onSubmit={handleSubmit(handleSubmitForm)} testId="changePasswordForm">
			<InputField
				autoFocus
				error={Boolean(errors.password)}
				helperText={errors.password?.message}
				label={t('password')}
				name="password"
				control={control}
				type="password"
			/>
			<InputField
				error={Boolean(errors.newPassword)}
				helperText={errors.newPassword?.message}
				label={t('newPassword')}
				name="newPassword"
				control={control}
				type="password"
			/>
			<Box mt={2}>
				<Button type="submit">{t('setPassword')}</Button>
			</Box>
		</Form>
	);
};

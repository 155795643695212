import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { COLORS } from '../../../theme';

const useStyles = makeStyles(({ spacing }: Theme) => ({
	tooltip: {
		borderRadius: spacing(2),
		border: `2px solid ${COLORS.gray.light}`,
		padding: spacing(3),
		backgroundColor: COLORS.white.main,
	},
}));

type Props = {
	value: number | string;
	time: string;
};

export const ChartTooltip = ({ value, time }: Props) => {
	const classes = useStyles();

	return (
		<div className={classes.tooltip}>
			<div>{value}</div>
			<div>{time}</div>
		</div>
	);
};

import React, { FormEvent } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Children } from '../../types';

const useStyles = makeStyles((theme: Theme) => ({
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
}));

type Props = {
	children: Children;
	onSubmit: (event: FormEvent<HTMLFormElement>) => void;
	testId: string;
};

export const Form = ({ children, onSubmit, testId }: Props) => {
	const classes = useStyles();

	return (
		<form className={classes.form} noValidate onSubmit={onSubmit} data-cy={testId}>
			{children}
		</form>
	);
};

import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import logo from '../../static/logo.png';

const useStyles = makeStyles(({ spacing }: Theme) => ({
	logo: {
		flex: 1,
		height: '100%',
		marginLeft: spacing(1),
	},
	image: {
		maxWidth: '100%',
		maxHeight: '100%',
	},
}));

export const Logo = () => {
	const classes = useStyles();

	return (
		<div className={classes.logo}>
			<img className={classes.image} src={logo} alt="Lucid Solutions logo" />
		</div>
	);
};

import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { UsersTable } from './components/UsersTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { UserManagementState } from '../../store/user-management/user-management-types';
import { fetchUsers } from '../../store/user-management/user-management-async-actions';
import { Grid } from '@material-ui/core';

export const UserManagement = () => {
	const dispatch = useDispatch();
	const { data, total, limit, offset, orderings, filters } = useSelector<RootState, UserManagementState>(
		(state) => state.users,
	);

	useEffect(() => {
		dispatch(
			fetchUsers({
				limit: limit,
				offset: offset,
				orderings: orderings,
				filtersAndConditions: filters,
			}),
		);

		// Don't need to react on "config" and "filters" and deep comparision is not necessary
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	return (
		<Grid container spacing={2} justify="center">
			{/* <Grid item xs={12} lg={10}>
				<Button
					// disabled={reviewDefinitionNames === undefined}
					startIcon={<EditRounded />}
					onClick={() => dispatch(setRedirectUrl(RouteEnum.USER_ROLES))}
				>
					{t('userRoles')}
				</Button>
			</Grid> */}
			<Grid item xs={12} lg={10}>
				<Paper>
					<UsersTable
						data={data}
						total={total}
						limit={limit}
						offset={offset}
						orderings={orderings}
						filters={filters}
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};

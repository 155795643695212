import React from 'react';
import { Form } from '../../../components/Form/Form';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { UserResponseData } from '../../UserManagement/user-management-types';
import { Switch, FormControlLabel, FormControl } from '@material-ui/core';
import { Button } from '../../../components/Button/Button';
import { InputField } from '../../../components/Form/InputField';
import * as yup from 'yup';
import SaveIcon from '@material-ui/icons/Save';
import { ModalDialogFormActions } from '../../../components/ModalDialog/ModalDialogFormActions';
import { yupResolver } from '@hookform/resolvers';
import { InputSelect } from '../../../components/Form/InputSelect';
import { Tenant } from '../../Tenants/tenants-types';
import { UUID } from '../../../types';

export type EditUserFormValues = Pick<UserResponseData, 'firstName' | 'lastName' | 'active' | 'email' | 'tenantId'>;

type Props = {
	email: string;
	values?: EditUserFormValues;
	onSubmit: (values: EditUserFormValues) => void;
	onCancel?: () => void;
	tenants: Tenant[];
	actualUserTenantId?: UUID;
};

export const EditUserForm = ({ actualUserTenantId, email, onSubmit, onCancel, values, tenants }: Props) => {
	const { t } = useTranslation();

	const validationSchema = yup.object().shape({
		firstName: yup.string().required(t('validationErrors.required')),
		lastName: yup.string().required(t('validationErrors.required')),
		tenantId: yup.string().notRequired().nullable(),
		active: yup.bool(),
	});

	const defaultValues = {
		...values,
		tenantId: tenants.find((tenant) => tenant.id === values?.tenantId)?.name,
	};

	const { handleSubmit, errors, control, watch } = useForm<EditUserFormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues,
	});

	const isActive = watch('active');

	const handleSubmitForm = (values: EditUserFormValues) => {
		const tenant = tenants.find((tenant) => tenant.name === values.tenantId);

		onSubmit({ ...values, email: email, tenantId: actualUserTenantId ?? tenant?.id });
	};

	return (
		<Form onSubmit={handleSubmit(handleSubmitForm)} testId="editUserForm">
			<InputField
				autoFocus
				error={Boolean(errors.firstName)}
				helperText={errors.firstName?.message}
				label={t('firstName')}
				name="firstName"
				control={control}
			/>
			<InputField
				error={Boolean(errors.lastName)}
				helperText={errors.lastName?.message}
				label={t('lastName')}
				name="lastName"
				control={control}
			/>
			{actualUserTenantId === undefined && (
				<FormControl fullWidth margin="normal">
					<InputSelect
						control={control}
						error={Boolean(errors.tenantId)}
						helperText={errors.tenantId?.message}
						label={t('selectTenant')}
						options={tenants.map((tenant) => tenant.name)}
						name="tenantId"
					/>
				</FormControl>
			)}
			<FormControl fullWidth margin="dense">
				<FormControlLabel
					control={
						<Controller
							render={(props) => (
								<Switch
									{...props}
									onChange={(event) => props.onChange(event.target.checked)}
									color="primary"
									checked={isActive}
								/>
							)}
							name="active"
							control={control}
						/>
					}
					label={isActive ? t('active') : t('inactive')}
				/>
			</FormControl>
			<ModalDialogFormActions>
				<Button data-cy="btn-edit:user" startIcon={<SaveIcon />} type="submit">
					{t('save')}
				</Button>
				{onCancel && (
					<Button onClick={onCancel} variant="text">
						{t('cancel')}
					</Button>
				)}
			</ModalDialogFormActions>
		</Form>
	);
};

import { DevicePlacement } from '../DevicePlacementsManagement/device-placements-types';
import { Telemetry } from '../Devices/telemetry-types';

// TODO
export const WC_DEF_NAME = 'pName_worker_care';
export const WC_FIELD_PERSONAL_NO = 'fName_wc_PersonalNo.';
export const WC_FIRST_NAME = 'fName_wc_FirstName';
export const WC_SURNAME = 'fName_wc_Surname';
export const INDOOR_MAP_ID = '2ec82731-3716-4840-9926-ce93aabe212a';
export const DI_FIELD_LOAD_HANDLING_DEPTH = 'fName_mhv_loadHandlingDepth';

export const DEFAULT_MAP_CENTER: [number, number] = [24.73346400783449, 46.66879692634813]; // Mobily - Riyadh

export type WORKER_STATE = 'notPresent' | 'inactive' | 'stopped' | 'moving';

export function getWorkerState(
	placement?: DevicePlacement,
	deviceTelemetry?: Telemetry,
): { state: WORKER_STATE; color: string; title?: string } | undefined {
	if (placement === undefined) {
		return undefined;
	}

	const isAttached = placement.placementRelations?.find((relation) => relation.detached === null) ? true : false;

	if (!isAttached) {
		return {
			state: 'notPresent',
			color: '#404040',
			title: 'Data source not present/ detached (Black)',
		};
	}

	const telemetry = placement.telemetry ?? deviceTelemetry;

	if (telemetry === undefined || telemetry === null) {
		return { state: 'inactive', title: 'No response 24 hours+ (Yellow)', color: '#d0d040' };
	}

	const timeString = telemetry.time;

	if (timeString) {
		const nowMinus24h = Date.now() - 24 * 60 * 60 * 1000;
		const time = new Date(timeString);

		if (time.getTime() < nowMinus24h) {
			return { state: 'inactive', title: 'No response 24 hours+ (Yellow)', color: '#e0e040' };
		}
	}

	const inTrip = telemetry.intrip;

	if (inTrip === 0) {
		return { state: 'stopped', title: 'Stopped (Magenta)', color: '#f760f7' };
	}

	if (inTrip === 1) {
		return { state: 'moving', title: 'Moving (Green)', color: '#40e045' };
	}
}

import { apiClient, apiClientForExport, apiClientForImport } from '../client';
import {
	CreateDevicePayload,
	CreateDeviceTypePayload,
	DeviceProperties,
	DeviceResponseData2,
	DevicesResponseData2,
	DeviceType,
	DeviceTypesResponseData,
} from '../../pages/Devices/devices-types';
import { FiltersRequestBody2 } from '../../components/Table/types';
import { UUID } from '../../types';
import { ValidateImportResponse } from './devices-types';

const baseUrlDevices = '/device-manager.net/devices';
const baseUrlDeviceTypes = '/device-manager.net/deviceTypes';

// export const getFilteredDevicesWithPlacements = (body: FiltersRequestBody2) =>
// 	apiClient.post<DevicesResponseData2>(`${baseUrlDevices}/placement/filter`, body);

export const getFilteredGroupDevicesWithPlacements = (body: FiltersRequestBody2) =>
	apiClient.post<DevicesResponseData2>(`${baseUrlDevices}/placement/filter`, body);

export const getFilteredDevices = (body: FiltersRequestBody2) =>
	apiClient.post<DevicesResponseData2>(`${baseUrlDevices}/filter`, body);

export const createDevice = (body: CreateDevicePayload) => apiClient.post<DeviceResponseData2>(baseUrlDevices, body);
export const editDevice = (body: CreateDevicePayload, deviceId: UUID) =>
	apiClient.put<DeviceResponseData2>(`${baseUrlDevices}/${deviceId}`, body);

export const uploadDevices = (body: ArrayBuffer) =>
	apiClientForImport.post<ValidateImportResponse>(`${baseUrlDevices}/import`, body);

export const fetchDeviceTypes = (body: FiltersRequestBody2) =>
	apiClient.post<DeviceTypesResponseData>(`${baseUrlDeviceTypes}/filter`, body);
export const createDeviceType = (body: CreateDeviceTypePayload) => apiClient.post<DeviceType>(baseUrlDeviceTypes, body);
export const editDeviceType = (body: CreateDeviceTypePayload, deviceTypeId: UUID) =>
	apiClient.put<DeviceType>(`${baseUrlDeviceTypes}/${deviceTypeId}`, body);
export const deleteDeviceType = (deviceTypeId: UUID) => apiClient.delete(`${baseUrlDeviceTypes}/${deviceTypeId}`);

export const editDeviceProperties = (body: DeviceProperties, deviceId: UUID) =>
	apiClient.put<DeviceProperties>(`${baseUrlDevices}/deviceProperty/${deviceId}`, body);

export const exportDevicesXml = (deviceIds: UUID[]) =>
	apiClientForExport
		.post(`${baseUrlDevices}/export`, {
			format: 0,
			fileName: 'export.xlsx',
			contentType: 'application/json',
			deviceIds: deviceIds,
			responseType: 'arraybuffer',
		})
		.then((response) => {
			const type = response.headers['content-type'];

			const blob = new Blob([response.data], { type: type });
			const link = document.createElement('a');

			link.href = window.URL.createObjectURL(blob);
			link.download = 'export.xlsx';
			link.click();
		});

export const exportDevicesCra = (deviceIds: UUID[]) =>
	apiClientForExport
		.post(`${baseUrlDevices}/export`, {
			format: 1,
			fileName: 'cra.csv',
			contentType: 'application/json',
			deviceIds: deviceIds,
			responseType: 'arraybuffer',
		})
		.then((response) => {
			const type = response.headers['content-type'];

			const blob = new Blob(['\ufeff', response.data], { type: type });
			const link = document.createElement('a');

			link.href = window.URL.createObjectURL(blob);
			link.download = 'cra.csv';
			link.click();
		});

import React from 'react';
import { Table } from '../../../components/Table/Table';
import { useTranslation } from 'react-i18next';

import { FilterOperators, FilterType, TableRowActionIcon } from '../../../components/Table/constants';
import { useDispatch, useSelector } from 'react-redux';
import { addModalDialog, removeModalDialog } from '../../../store/app/app-actions';
import { ContactForm, ContactFormValues } from './ContactForm';
import { UUID } from '../../../types';
import { Tooltip, TableRow, TableCell, CardHeader, IconButton, Divider } from '@material-ui/core';
import {
	TableColumnFilter,
	TableColumnSort,
	TableHeadCell,
	TableRowRenderProps,
} from '../../../components/Table/types';
import { Add, DeleteForever } from '@material-ui/icons';
import { UserState } from '../../../store/user/user-types';
import { PermissionsDotNet } from '../../../helpers/generalConstants';
import { RootState } from '../../../store/types';
import { hasPermission } from '../../../helpers/auth';
import {
	createContact,
	deleteContact,
	editContact,
	fetchContacts,
} from '../../../store/contacts/contacts-async-actions';
import { Contact } from '../contacts-types';

type Props = {
	data: Contact[];
	total: number;
	limit: number;
	offset: number;
	orderings?: TableColumnSort[];
	filters?: TableColumnFilter[];
};

export const ContactsTable = ({ data, total, offset, limit, orderings, filters }: Props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { permissions } = useSelector<RootState, UserState>((state) => state.user);
	const canEditContacts = hasPermission(PermissionsDotNet.ContactWrite, permissions);

	const firstNameOrder = orderings?.find((order) => order.column === 'firstName');
	const firstNameFilter = filters?.find((filter) => filter.column === 'firstName');

	const lastNameOrder = orderings?.find((order) => order.column === 'lastName');
	const lastNameFilter = filters?.find((filter) => filter.column === 'lastName');

	const emailOrder = orderings?.find((order) => order.column === 'email');
	const emailFilter = filters?.find((filter) => filter.column === 'email');

	const phoneOrder = orderings?.find((order) => order.column === 'phone');
	const phoneFilter = filters?.find((filter) => filter.column === 'phone');

	const columns: TableHeadCell[] = [
		{
			id: 'titleBefore',
			label: '',
			unorderable: true,
		},
		{
			id: 'firstName',
			label: t('firstName'),
			filters: firstNameFilter && [
				{
					column: 'firstName',
					operator: FilterOperators.contains,
					value: firstNameFilter.value,
				},
			],
			filterType: FilterType.String,
			sort: firstNameOrder?.sortOrder && { column: 'firstName', sortOrder: firstNameOrder?.sortOrder },
		},
		{
			id: 'lastName',
			label: t('lastName'),
			filters: lastNameFilter && [
				{
					column: 'lastName',
					operator: FilterOperators.contains,
					value: lastNameFilter.value,
				},
			],
			filterType: FilterType.String,
			sort: lastNameOrder?.sortOrder && { column: 'lastName', sortOrder: lastNameOrder?.sortOrder },
		},
		{
			id: 'titleAfter',
			label: '',
			unorderable: true,
		},
		{
			id: 'email',
			label: t('email'),
			filters: emailFilter && [
				{
					column: 'email',
					operator: FilterOperators.contains,
					value: emailFilter.value,
				},
			],
			filterType: FilterType.String,
			sort: emailOrder?.sortOrder && { column: 'emailName', sortOrder: emailOrder?.sortOrder },
		},
		{
			id: 'phone',
			label: t('phone'),
			filters: phoneFilter && [
				{
					column: 'phone',
					operator: FilterOperators.contains,
					value: phoneFilter.value,
				},
			],
			filterType: FilterType.String,
			sort: phoneOrder?.sortOrder && { column: 'phone', sortOrder: phoneOrder?.sortOrder },
		},
	];

	const handleCloseModal = (id: string) => {
		dispatch(removeModalDialog(id));
	};

	const handleCreateContact = (data: ContactFormValues, modalDialogId: string) => {
		handleCloseModal(modalDialogId);
		dispatch(createContact(data));
	};

	const handleEditContact = (data: ContactFormValues, modalId: string) => {
		if (data.id) {
			dispatch(editContact({ ...data, id: data.id }));
		}

		handleCloseModal(modalId);
	};

	const handleDeleteContact = (contactId: UUID, modalDialogId: string) => {
		handleCloseModal(modalDialogId);
		dispatch(deleteContact(contactId));
	};

	const rowActions = [
		{
			id: 'btn-edit:contact',
			icon: TableRowActionIcon.edit,
			tooltip: t('edit'),
			onClick: (contact: Contact) => {
				dispatch(
					addModalDialog({
						id: 'editContactModal',
						title: t('editContactModalTitle'),
						content: (
							<ContactForm
								onSubmit={(values) => handleEditContact(values, 'editContactModal')}
								onCancel={() => handleCloseModal('editContactModal')}
								defaultValues={contact}
							/>
						),
					}),
				);
			},
		},
		{
			id: 'btn-delete:contact',
			icon: TableRowActionIcon.delete,
			tooltip: t('delete'),
			onClick: (contact: Contact) =>
				dispatch(
					addModalDialog({
						id: 'deleteContactModal',
						title: t('deleteContactModalTitle'),
						contentText: t('deleteContactModalDescription', {
							contact: `${contact.firstName} ${contact.lastName}`,
						}),
						buttons: [
							{
								id: 'deleteContact',
								value: t('delete'),
								onClick: () => handleDeleteContact(contact.id, 'deleteContactModal'),
								startIcon: <DeleteForever />,
							},
							{
								id: 'cancelContact',
								value: t('cancel'),
								onClick: () => handleCloseModal('deleteContactModal'),
								variant: 'text',
							},
						],
					}),
				),
		},
	];

	const renderRow = ({ row: contact, renderActions }: TableRowRenderProps<Contact>) => {
		return (
			<TableRow key={contact.id} id={`row-${contact.id}`}>
				<TableCell>{contact.titleBefore}</TableCell>
				<TableCell>{contact.firstName}</TableCell>
				<TableCell>{contact.lastName}</TableCell>
				<TableCell>{contact.titleAfter}</TableCell>
				<TableCell>{contact.email}</TableCell>
				<TableCell>{contact.phone}</TableCell>
				{canEditContacts && (
					<TableCell padding="none" sortDirection={false}>
						{renderActions(rowActions, contact)}
					</TableCell>
				)}
			</TableRow>
		);
	};

	return (
		<>
			<CardHeader
				title={t('contacts')}
				action={
					<>
						{canEditContacts && (
							<Tooltip title={String(t('createContact'))} aria-label={t('createContact')}>
								<IconButton
									onClick={() =>
										dispatch(
											addModalDialog({
												id: 'createContactModal',
												title: t('createContactModalTitle'),
												content: (
													<ContactForm
														onSubmit={(values) =>
															handleCreateContact(values, 'createContactModal')
														}
														onCancel={() => handleCloseModal('createContactModal')}
													/>
												),
											}),
										)
									}
									data-cy="btn-create:contact"
								>
									<Add />
								</IconButton>
							</Tooltip>
						)}
					</>
				}
			/>
			<Divider light />
			<Table
				actions={canEditContacts ? rowActions : undefined}
				columns={columns}
				orderings={orderings}
				filtersAndConditions={filters}
				data={data}
				dataLimit={limit}
				dataOffset={offset}
				renderTableRow={renderRow}
				numberOfRows={total}
				fetchAction2={fetchContacts}
				selectable={false}
			/>
		</>
	);
};

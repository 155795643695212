import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) => ({
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
	},
}));

type Props = {
	isVisible: boolean;
};

export const Loader = ({ isVisible }: Props) => {
	const classes = useStyles();

	return (
		<Backdrop className={classes.backdrop} open={isVisible}>
			<CircularProgress />
		</Backdrop>
	);
};

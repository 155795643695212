import { apiClient } from '../client';
import {
	GroupsResponseData,
	GroupResponseData,
	CreateGroupRequestBody,
	UpdateGroupRequestBody,
	UpdateGroupResponseData,
} from '../../pages/PlacementGroups/placement-groups-types';
import { UUID } from '../../types';
import { FiltersRequestBody2 } from '../../components/Table/types';

// TODO redirect to placement service

const baseUrl = '/placement-manager.net';
const groupsUrl = `${baseUrl}/placementgroup`;

export const getFilteredPlacementGroups = (body: FiltersRequestBody2) =>
	apiClient.post<GroupsResponseData>(`${groupsUrl}/filter`, body);

export const createPlacementGroup = (body: CreateGroupRequestBody) =>
	apiClient.post<GroupResponseData>(`${groupsUrl}`, body);

export const updatePlacementGroup = (body: UpdateGroupRequestBody, groupId: UUID) =>
	apiClient.put<UpdateGroupResponseData>(`${groupsUrl}/${groupId}`, body);

export const deletePlacementGroup = (groupId: UUID) => apiClient.delete<void>(`${groupsUrl}/${groupId}`);

import React from 'react';
import { Box, FormControl, Grid, Slider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DateTimePicker } from '../../../components/DatePicker/DateTimePicker';
import { useDateFilter2, useRangeFilter2 } from '../../../components/Table/hooks';
import { TableFilter } from '../../../components/Table/TableFilter';
import { TableFilterHeading } from '../../../components/Table/TableFilterHeading';
import { RangeValue } from '../../../types';
import { FilterOperators } from '../../../components/Table/constants';
import { getFilterValues } from '../../../components/Table/helpers';
import { TEMPERATURE_MIN, TEMPERATURE_MAX, BATTERY_MAX, BATTERY_MIN } from '../constants';
import { TableColumnFilter } from '../../../components/Table/types';

type Props = {
	filters?: TableColumnFilter[];
	deviceSn: string;
	handleFilters: (filters: TableColumnFilter[]) => void;
};

export const DeviceDetailTelemetryTableFilter = ({ filters, deviceSn, handleFilters }: Props) => {
	const { t } = useTranslation();

	const initialFilters: TableColumnFilter[] = [
		{
			column: 'sn',
			operator: FilterOperators.equals,
			value: deviceSn,
		},
	];

	// Time filter
	// const defaultDate = getPairOfFilterDateValues(filters.time);
	const defaultFromDateValue = filters?.find(
		(filter) => filter.column === 'time' && filter.operator === FilterOperators.greaterThanEqual,
	)?.value;

	const defaultFromDate = defaultFromDateValue !== undefined ? new Date(defaultFromDateValue as string) : null;
	const defaultToDateValue = filters?.find(
		(filter) => filter.column === 'time' && filter.operator === FilterOperators.lessThanEqual,
	)?.value;

	const defaultToDate = defaultToDateValue !== undefined ? new Date(defaultToDateValue as string) : null;
	const { dates, updateDateFilter, dateFilter } = useDateFilter2('time', defaultFromDate, defaultToDate);

	// Accuracy filter
	const initialAccuracyRange = [0, 10000] as RangeValue;
	const defaultFromAccuracyValue = filters?.find(
		(filter) => filter.column === 'accuracy' && filter.operator === FilterOperators.greaterThanEqual,
	)?.value;

	const defaultToAccuracyValue = filters?.find(
		(filter) => filter.column === 'accuracy' && filter.operator === FilterOperators.lessThanEqual,
	)?.value;

	const defaultAccuracyRange: RangeValue = [
		defaultFromAccuracyValue !== undefined ? (defaultFromAccuracyValue as number) : initialAccuracyRange[0],
		defaultToAccuracyValue !== undefined ? (defaultToAccuracyValue as number) : initialAccuracyRange[1],
	];

	const {
		filter: accuracyFilter,
		getFilter: getAccuracyFilter,
		updateFilter: updateAccuracyFilter,
		resetFilter: resetAccuracyFilter,
	} = useRangeFilter2('accuracy', defaultAccuracyRange);

	// Temperature filter
	const initialTemperatureRange = [TEMPERATURE_MIN, TEMPERATURE_MAX] as RangeValue;
	const defaultFromTemperatureValue = filters?.find(
		(filter) => filter.column === 'temperature' && filter.operator === FilterOperators.greaterThanEqual,
	)?.value;

	const defaultToTemperatureValue = filters?.find(
		(filter) => filter.column === 'temperature' && filter.operator === FilterOperators.lessThanEqual,
	)?.value;

	const defaultTemperatureRange: RangeValue = [
		defaultFromTemperatureValue !== undefined
			? (defaultFromTemperatureValue as number)
			: initialTemperatureRange[0],
		defaultToTemperatureValue !== undefined ? (defaultToTemperatureValue as number) : initialTemperatureRange[1],
	];

	const {
		filter: temperatureFilter,
		getFilter: getTemperatureFilter,
		updateFilter: updateTemperatureFilter,
		resetFilter: resetTemperatureFilter,
	} = useRangeFilter2('temperature', defaultTemperatureRange);

	// Battery filter
	const initialBatteryRange = [BATTERY_MIN, BATTERY_MAX] as RangeValue;
	const defaultFromBatteryValue = filters?.find(
		(filter) => filter.column === 'battery' && filter.operator === FilterOperators.greaterThanEqual,
	)?.value;

	const defaultToBatteryValue = filters?.find(
		(filter) => filter.column === 'battery' && filter.operator === FilterOperators.lessThanEqual,
	)?.value;

	const defaultBatteryRange: RangeValue = [
		defaultFromBatteryValue !== undefined ? (defaultFromBatteryValue as number) : initialBatteryRange[0],
		defaultToBatteryValue !== undefined ? (defaultToBatteryValue as number) : initialBatteryRange[1],
	];

	const {
		filter: batteryFilter,
		getFilter: getBatteryFilter,
		updateFilter: updateBatteryFilter,
		resetFilter: resetBatteryFilter,
	} = useRangeFilter2('battery', defaultBatteryRange);

	const handleSaveFilters = () => {
		const payload: TableColumnFilter[] = [
			...initialFilters,
			...dateFilter,
			...getAccuracyFilter(initialAccuracyRange),
			...getTemperatureFilter(initialTemperatureRange),
			...getBatteryFilter(initialBatteryRange),
		];

		handleFilters(payload);
	};

	const handleResetFilters = () => {
		updateDateFilter(null, null);
		resetAccuracyFilter(initialAccuracyRange);
		resetTemperatureFilter(initialTemperatureRange);
		resetBatteryFilter(initialBatteryRange);

		handleFilters([...initialFilters]);
	};

	// accuracy: [
	// 	{ operator: FilterOperators.greaterThanEqual, value: 0 },
	// 	{ operator: FilterOperators.lessThanEqual, value: 10000 },
	// ],

	const filterActive =
		dateFilter.length > 0 ||
		getAccuracyFilter(initialAccuracyRange).length > 0 ||
		getTemperatureFilter(initialTemperatureRange).length > 0 ||
		getBatteryFilter(initialBatteryRange).length > 0;

	return (
		<TableFilter
			onSave={handleSaveFilters}
			onReset={handleResetFilters}
			active={filterActive}
			name="telemetryTable"
		>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6}>
					<FormControl fullWidth>
						<TableFilterHeading text={`${t('from')}:`} />
						<DateTimePicker
							disableFuture
							maxDate={new Date()}
							id="dateFrom"
							value={dates[0]}
							onChange={(date) => updateDateFilter(date, dates[1])}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl fullWidth>
						<TableFilterHeading text={`${t('to')}:`} />
						<DateTimePicker
							disableFuture
							maxDate={new Date()}
							id="dateTo"
							value={dates[1]}
							onChange={(date) => updateDateFilter(dates[0], date)}
						/>
					</FormControl>
				</Grid>

				<Grid item xs={12}>
					<TableFilterHeading text={t('accuracy')} />
					<Box paddingX={2}>
						<Slider
							value={getFilterValues<RangeValue>(accuracyFilter).map((member) => Math.sqrt(member))}
							onChange={(_: React.ChangeEvent<{}>, value: number | number[]) => {
								updateAccuracyFilter((value as RangeValue).map((member) => member * member));
							}}
							scale={(x) => x * x}
							valueLabelDisplay="auto"
							min={0}
							max={100}
							aria-labelledby="non-linear-slider"
							marks={[
								{
									value: 0,
									label: 0,
								},
								{
									value: 10,
									label: 100,
								},
								{
									value: 40,
									label: 1600,
								},
								{
									value: 71,
									label: 5000,
								},
								{
									value: 100,
									label: 10000,
								},
							]}
							step={1}
							data-cy="accuracy"
						/>
					</Box>
				</Grid>

				<Grid item xs={12}>
					<TableFilterHeading text={t('temperature')} />
					<Box paddingX={2}>
						<Slider
							value={getFilterValues<RangeValue>(temperatureFilter)}
							onChange={(_: React.ChangeEvent<{}>, value: number | number[]) =>
								updateTemperatureFilter(value as RangeValue)
							}
							valueLabelDisplay="auto"
							getAriaValueText={(value: number) => t('degreesCelsius', { value })}
							min={initialTemperatureRange[0]}
							max={initialTemperatureRange[1]}
							marks={[
								{
									value: initialTemperatureRange[0],
									label: t('degreesCelsius', { value: initialTemperatureRange[0] }),
								},
								{
									value: 0,
									label: t('degreesCelsius', { value: 0 }),
								},
								{
									value: initialTemperatureRange[1],
									label: t('degreesCelsius', { value: initialTemperatureRange[1] }),
								},
							]}
							step={1}
							data-cy="temperature"
						/>
					</Box>
				</Grid>

				<Grid item xs={12}>
					<TableFilterHeading text={t('battery')} />
					<Box paddingX={2}>
						<Slider
							value={getFilterValues<RangeValue>(batteryFilter)}
							onChange={(_: React.ChangeEvent<{}>, value: number | number[]) =>
								updateBatteryFilter(value as RangeValue)
							}
							valueLabelDisplay="auto"
							getAriaValueText={(value: number) => t('percentage', { value })}
							min={initialBatteryRange[0]}
							max={initialBatteryRange[1]}
							marks={[
								{
									value: initialBatteryRange[0],
									label: t('percentage', { value: initialBatteryRange[0] }),
								},
								{
									value: initialBatteryRange[1],
									label: t('percentage', { value: initialBatteryRange[1] }),
								},
							]}
							step={1}
							data-cy="battery"
						/>
					</Box>
				</Grid>
			</Grid>
		</TableFilter>
	);
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { TableRow, TableCell, makeStyles } from '@material-ui/core';
import { Table } from '../../../components/Table/Table';
import { TableRowRenderProps } from '../../../components/Table/types';
import { TableRowActionIcon } from '../../../components/Table/constants';
import { TableActionsContainer } from '../../../components/Table/TableActionsContainer';
import { INPUT_DATE_TIME_MASK } from '../../../helpers/generalConstants';
import { fetchDevicesWithTelemetry } from '../../../store/devices/devices-async-actions';
import { Telemetry, TelemetrySocketData } from '../../Devices/telemetry-types';
import { COLORS } from '../../../theme';
import { RouteEnum } from '../../../router/Routes';

type Props = {
	data: TelemetrySocketData[];
	limit: number;
	offset: number;
};

const useStyles = makeStyles({
	'@keyframes colorPulse': {
		'0%': {
			backgroundColor: COLORS.transparent,
		},
		'25%': {
			backgroundColor: COLORS.white.main,
		},
		'50%': {
			backgroundColor: COLORS.transparent,
		},
		'75%': {
			backgroundColor: COLORS.white.main,
		},
		'100%': {
			backgroundColor: COLORS.transparent,
		},
	},
	colorPulse: {
		backgroundColor: COLORS.transparent,
		animation: '$colorPulse 3s linear',
	},
});

type TableRowData = Telemetry & {
	id: string;
};

export const DashboardTelemetryTable = ({ data, limit, offset }: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const history = useHistory();

	const rowActions = [
		{
			id: 'telemetryDetail',
			icon: TableRowActionIcon.detail,
			tooltip: t('detail'),
			onClick: (telemetry: TableRowData) => history.push(`${RouteEnum.DEVICES}/${telemetry.sn}`),
		},
	];

	const columns = [
		{ id: 'sn', label: t('sn'), new: true },
		{ id: 'battery', label: t('battery'), new: true },
		{ id: 'temperature', label: t('temperature'), new: true },
		{ id: 'accuracy', label: t('accuracy'), new: true },
		{ id: 'lightIntensity', label: t('lightIntensity'), new: true },
		{ id: 'time', label: t('time'), new: true },
	];

	const tableData: TableRowData[] = data.map(({ sensor, telemetry }) => {
		return {
			id: `${sensor.sn}-${telemetry.time}`,
			...sensor,
			...telemetry,
		};
	});

	const renderTableRow = ({
		onClickRow,
		row: telemetry,
		renderActions,
		index,
	}: TableRowRenderProps<TableRowData>) => {
		return (
			<TableRow
				key={telemetry.id}
				id={`row-${telemetry.id}`}
				hover
				className={clsx(index === 0 && classes.colorPulse)}
				onClick={() => onClickRow(String(telemetry.id))}
			>
				<TableCell component="td" id={telemetry.sn} scope="row">
					{telemetry.sn}
				</TableCell>
				<TableCell component="td" id={String(telemetry.battery)} scope="row">
					{telemetry.battery && t('percentage', { value: telemetry.battery.toFixed(2) })}
				</TableCell>
				<TableCell component="td" id={String(telemetry.temperature)} scope="row">
					{telemetry.temperature && t('degreesCelsius', { value: telemetry.temperature.toFixed(2) })}
				</TableCell>
				<TableCell component="td" id={String(telemetry.accuracy)} scope="row">
					{telemetry.accuracy}
				</TableCell>
				<TableCell component="td" id={String(telemetry.light_intensity)} scope="row">
					{telemetry.light_intensity && t('illuminance', { value: telemetry.light_intensity })}
				</TableCell>
				<TableCell component="td" id={telemetry.time} scope="row">
					{telemetry.time && format(new Date(telemetry.time), INPUT_DATE_TIME_MASK)}
				</TableCell>
				<TableCell padding="none" sortDirection={false} align="right">
					<TableActionsContainer>{renderActions(rowActions, telemetry)}</TableActionsContainer>
				</TableCell>
			</TableRow>
		);
	};

	return (
		<Table
			actions={rowActions}
			columns={columns}
			data={tableData}
			dataLimit={limit}
			dataOffset={offset}
			numberOfRows={tableData.length}
			fetchAction2={fetchDevicesWithTelemetry}
			selectable={false}
			renderTableRow={renderTableRow}
		/>
	);
};

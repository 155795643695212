import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CardHeader, CircularProgress, Divider, TableCell, TableRow } from '@material-ui/core';
import { TableRowRenderProps } from '../../../components/Table/types';
import { RootState } from '../../../store/types';
import { Table } from '../../../components/Table/Table';
import { fetchDevicePlacements } from '../../../store/device-placements/device-placements-async-actions';
import { FilterOperators } from '../../../components/Table/constants';
import { PlacementState } from '../../../store/device-placements/device-placements-types';
import { fetchRequest } from '../../../services/helpers';
import { UserState } from '../../../store/user/user-types';
import {
	Definition,
	DefinitionResponseData,
	DevicePlacement,
} from '../../DevicePlacementsManagement/device-placements-types';
import {
	DI_DEF_NAME,
	DI_FIELD_COST_CENTRE,
	DI_FIELD_FIRST_NAME,
	DI_FIELD_LAST_NAME,
	DI_FIELD_PERSONAL_NO,
} from '../vehicle-watch-types';
import { fetchDefinitions } from '../../../services/device-placements/device-placements-service';

export const DriversTable = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { project } = useSelector<RootState, UserState>((state) => state.user);

	const placements = useSelector<RootState, PlacementState | undefined>((state) => state.devicePlacements.placements);

	const [definition, setDefinition] = useState<Definition | undefined>();

	const allFields = definition?.placementFields;

	const personalNoColumnId = `Fields[Name]=${DI_FIELD_PERSONAL_NO}`;
	const personalNoOrder = placements?.orderings?.find((order) => order.column === personalNoColumnId);
	const firstNameColumnId = `Fields[Name]=${DI_FIELD_FIRST_NAME}`;
	const firstNameOrder = placements?.orderings?.find((order) => order.column === firstNameColumnId);
	const lastNameColumnId = `Fields[Name]=${DI_FIELD_LAST_NAME}`;
	const lastNameOrder = placements?.orderings?.find((order) => order.column === lastNameColumnId);
	const costCentreColumnId = `Fields[Name]=${DI_FIELD_COST_CENTRE}`;
	const costCentreOrder = placements?.orderings?.find((order) => order.column === costCentreColumnId);

	const columns = allFields?.map<{ id: string; label: string }>((field) => {
		if (field.name === DI_FIELD_PERSONAL_NO) {
			return {
				id: personalNoColumnId,
				label: t(field.name),
				sort: personalNoOrder?.sortOrder && {
					column: personalNoColumnId,
					sortOrder: personalNoOrder?.sortOrder,
				},
			};
		}

		if (field.name === DI_FIELD_FIRST_NAME) {
			return {
				id: firstNameColumnId,
				label: t(field.name),
				sort: firstNameOrder?.sortOrder && {
					column: firstNameColumnId,
					sortOrder: firstNameOrder?.sortOrder,
				},
			};
		}

		if (field.name === DI_FIELD_LAST_NAME) {
			return {
				id: lastNameColumnId,
				label: t(field.name),
				sort: lastNameOrder?.sortOrder && {
					column: lastNameColumnId,
					sortOrder: lastNameOrder?.sortOrder,
				},
			};
		}

		if (field.name === DI_FIELD_COST_CENTRE) {
			return {
				id: costCentreColumnId,
				label: t(field.name),
				sort: costCentreOrder?.sortOrder && {
					column: costCentreColumnId,
					sortOrder: costCentreOrder?.sortOrder,
				},
			};
		}

		return {
			id: t(field.name),
			label: t(field.name),
			unorderable: true,
		};
	});

	useEffect(() => {
		const request = fetchDefinitions({
			// Should be enough to get all definitions
			limit: 0,
			offset: 0,
			orderings: [],
			filtersAndConditions: [
				{
					column: 'name',
					operator: FilterOperators.contains,
					value: DI_DEF_NAME,
				},
			],
		});

		(async () => {
			const { data, error } = await fetchRequest<DefinitionResponseData>(request);

			if (error) {
				console.log(error);
				// TODO show error
			} else {
				if (data && data.data && data.data.length > 0) {
					setDefinition(data.data[0]);
				}
			}
		})();

		/// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (definition !== undefined) {
			const filters = [
				{
					column: 'definitionId',
					operator: FilterOperators.equals,
					value: definition.id,
				},
			];

			if (project) {
				filters.push({
					column: 'projects',
					operator: FilterOperators.injoin,
					value: project.id,
				});
			}

			dispatch(
				fetchDevicePlacements({
					limit: 10,
					offset: 0,
					orderings: [],
					filtersAndConditions: filters,
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, definition, project]);

	const renderRow = ({ row: placement, renderActions }: TableRowRenderProps<DevicePlacement>) => {
		return (
			<TableRow key={placement.id} id={`row-${placement.id}`}>
				{allFields?.map((field) => {
					const entry = Object.entries(placement.fields).find(([key]) => key === field.name);

					if (entry) {
						return <TableCell key={field.name}>{entry[1] as string}</TableCell>;
					}

					return (
						<TableCell key={field.name}>
							<CircularProgress size={12} />
						</TableCell>
					);
				})}
				{/* <TableCell padding="none" sortDirection={false}>
					{renderActions(rowActions, placement)}
				</TableCell> */}
			</TableRow>
		);
	};

	// const rowActions: TableRowAction<DevicePlacement>[] = [];

	return (
		<>
			<CardHeader title={t(DI_DEF_NAME)} action={<></>} />
			<Divider light />

			{placements && columns && (
				<Table
					// actions={}
					columns={columns}
					renderTableRow={renderRow}
					fetchAction2={fetchDevicePlacements}
					data={placements.data}
					dataLimit={placements.limit}
					dataOffset={placements.offset}
					numberOfRows={placements.total}
					orderings={placements.orderings}
					filtersAndConditions={placements.filtersAndConditions}
					selectable={false}
				/>
			)}
		</>
	);
};

import { FormControl } from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/Button/Button';
import { Form } from '../../../components/Form/Form';
import { InputField } from '../../../components/Form/InputField';
import { ModalDialogFormActions } from '../../../components/ModalDialog/ModalDialogFormActions';
import { Nullable } from '../../../types';
import { CreateDevicePlacementRequestBody, Definition, Field } from '../device-placements-types';
import { DefinitionFieldFlag } from '../../constants';
import { InputSelectUncontrolled } from '../../../components/Form/InputSelectUncontrolled';
import { DeviceResponseData2 } from '../../Devices/devices-types';

type DevicePlacmentFormValues = CreateDevicePlacementRequestBody;

type Props = {
	devices: DeviceResponseData2[] | undefined;
	definition: Definition;
	onSubmit: (values: DevicePlacmentFormValues) => void;
	onCancel: () => void;
	defaultValues?: DevicePlacmentFormValues;
};

export const DevicePlacementForm = ({
	devices,
	definition,
	onSubmit,
	onCancel,
	defaultValues = {
		definitionId: '',
		id: '',
		fields: {},
		placementRelations: [],
	},
}: Props) => {
	const { t } = useTranslation();

	const [errors, setError] = useState<{ [key: string]: boolean }>({});

	const [value, setValue] = useState<Nullable<{ id: string; label: string }>>({
		id: '',
		label: '',
	});

	const handleSubmitForm = (event: any) => {
		const errs: { [key: string]: boolean } = {};

		if (definition) {
			const formData = new FormData(event.target);

			console.log(value?.id);

			let updated = false;
			const formFields = {};

			for (const [key, value] of formData.entries()) {
				const field = definition.placementFields.find((field) => field.name === key);

				if (!value && field && field.flags && field.flags & DefinitionFieldFlag.Mandatory) {
					errs[key] = true;
				} else {
					const defaultField = Object.entries(defaultValues.fields).find(
						([defaultKey]) => defaultKey === key,
					);

					if (!defaultField || defaultField[1] !== value) {
						updated = true;
					}

					Object.assign(formFields, { [key]: value });
				}
			}

			if (Object.keys(errs).length) {
				if (Object.keys(errs).length) {
					setError(errs);
				} else {
					setError({});
				}
			} else {
				if (updated) {
					defaultValues.fields = formFields;
					defaultValues.definitionId = definition.id;

					if (value) {
						console.log(value.id);
						defaultValues.deviceId = value.id;
					}

					onSubmit(defaultValues);
				} else {
					onCancel();
				}
			}
		} else {
			// TODO handle no definition (should not happen though)
		}

		event.preventDefault();
	};

	const initField = (field: Field) => {
		const entry = Object.entries(defaultValues.fields).find(([key, value]) => key === field.name);

		return entry ? entry[1] : undefined;
	};

	return (
		<Form onSubmit={handleSubmitForm} testId="projectForm">
			{devices && defaultValues.id === '' && (
				<FormControl fullWidth margin="normal" variant="outlined">
					<InputSelectUncontrolled
						onChanged={(value) => setValue(value)}
						error={false}
						options={devices.flatMap((device) => {
							return { id: device.id, label: device.sn };
						})}
						label={t('device')}
					/>
				</FormControl>
			)}
			{definition?.placementFields.map((field) => (
				<FormControl key={field.name} fullWidth margin="normal" variant="outlined">
					<InputField
						error={errors[field.name]}
						helperText={errors[field.name] && t('validationErrors.required')}
						label={t(field.name)}
						margin="none"
						name={field.name}
						defaultValue={initField(field)}
						required={field.flags && field.flags & DefinitionFieldFlag.Mandatory ? true : false}
					/>
				</FormControl>
			))}
			<ModalDialogFormActions>
				<Button startIcon={<SaveIcon />} type="submit" data-cy="btn-submit:devicePlacementForm">
					{t('save')}
				</Button>
				<Button onClick={onCancel} variant="text" data-cy="btn-cancel:devicePlacementForm">
					{t('cancel')}
				</Button>
			</ModalDialogFormActions>
		</Form>
	);
};

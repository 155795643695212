import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import { Children } from '../../../types';
import { BREAKPOINT_KEYS } from '../../../helpers/generalConstants';

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: spacing(8),
		padding: spacing(2, 4),
		[breakpoints.up(BREAKPOINT_KEYS.sm)]: {
			padding: spacing(8, 12),
		},
	},
}));

type Props = {
	children: Children;
};

export const LoginContainer = ({ children }: Props) => {
	const classes = useStyles();

	return (
		<Grow in>
			<Container component="main" maxWidth="sm">
				<Paper elevation={20} className={classes.paper}>
					{children}
				</Paper>
			</Container>
		</Grow>
	);
};

import React from 'react';
import Snackbar, { SnackbarCloseReason } from '@material-ui/core/Snackbar';
import { Alert, AlertProps, AlertTitleProps, AlertTitle } from '@material-ui/lab';
import { SnackbarProps } from '@material-ui/core/Snackbar/Snackbar';
import Grow from '@material-ui/core/Grow';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { UUID } from '../../types';

type OwnProps = {
	id: string;
	index: number;
	title?: string;
	content: string;
	timeout?: number;
	onCloseToast: (id: UUID) => void;
	button?: {
		text: string;
		action: () => void;
	};
};

export type ToastProps = SnackbarProps & AlertProps & AlertTitleProps & OwnProps;

const useStyles = (index: number) => {
	const styles = makeStyles({
		alert: {
			minWidth: 300,
		},
		snackbar: {
			marginBottom: index * 100,
		},
		action: {
			marginRight: 0,
		},
	});

	return styles();
};

export const Toast = ({
	anchorOrigin = { vertical: 'bottom', horizontal: 'right' },
	content,
	onCloseToast,
	open,
	id,
	index,
	severity = 'info',
	timeout,
	title,
	button,
}: ToastProps) => {
	const classes = useStyles(index);

	const handleClose = (event: React.SyntheticEvent, reason?: SnackbarCloseReason) => {
		if (reason === 'clickaway') {
			return;
		}

		if (typeof onCloseToast === 'function') {
			onCloseToast(id);
		}
	};

	const handleClickActionButton = (event: React.SyntheticEvent) => {
		handleClose(event);

		if (button) {
			button.action();
		}
	};

	const renderActionButton = button && (
		<Button onClick={(event) => handleClickActionButton(event)} color="inherit" size="small">
			{button.text}
		</Button>
	);

	return (
		<Snackbar
			className={classes.snackbar}
			TransitionComponent={Grow}
			anchorOrigin={anchorOrigin}
			autoHideDuration={timeout}
			onClose={handleClose}
			open={open}
		>
			<Alert
				action={renderActionButton}
				className={classes.alert}
				classes={{ action: classes.action }}
				elevation={6}
				variant="filled"
				onClose={handleClose}
				severity={severity}
			>
				{title && <AlertTitle>{title}</AlertTitle>}
				{content}
			</Alert>
		</Snackbar>
	);
};

import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import MuiTableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/core';
import { TableHeadCell, TableHeadProps } from './types';
import { ObjectRequiredID } from '../../types';
import { FilterType, SortOrder } from './constants';
import clsx from 'clsx';
import { StringFilter } from './filters/StringFilter';
import { ActiveFilter } from './filters/ActiveFilter';
import { DateSpanFilter } from './filters/DateSpanFilter';

const useStyles = makeStyles({
	tableHead: {
		display: 'inline-flex',
		alignItems: 'center',
	},
	filter: {
		maxWidth: '60vw',
		padding: '16px',
		columns: 'auto',
		columnCount: 'auto',
		columnFill: 'auto',
	},
	hidden: {
		display: 'none',
	},
});

export const TableHead = <T extends ObjectRequiredID>({
	cells,
	data,
	onClickSelectAll,
	handleSortChange,
	handleFilterChange,
	selectedRows,
	selectable,
	sortable,
}: TableHeadProps<T>) => {
	const classes = useStyles();

	const rowCount = data.length;
	const numSelectedRows = selectedRows.length;

	const getFilter = (tableHeadCell: TableHeadCell) => {
		switch (tableHeadCell.filterType) {
			case FilterType.String:
				return (
					<StringFilter
						tableHead={tableHeadCell}
						onChange={() => {
							handleFilterChange && handleFilterChange(tableHeadCell);
						}}
					/>
				);
			case FilterType.Active:
				return (
					<ActiveFilter
						tableHead={tableHeadCell}
						onChange={() => {
							handleFilterChange && handleFilterChange(tableHeadCell);
						}}
					/>
				);
			case FilterType.DateSpan:
				return (
					<DateSpanFilter
						tableHead={tableHeadCell}
						onChange={() => {
							handleFilterChange && handleFilterChange(tableHeadCell);
						}}
					/>
				);
		}

		// case FilterOperators.STRING:
		// 	return <StringFilter tableHead={tableHeadCell} />;
		// case TableColumnType.NUMBER:
		// 	return <DevicesTableFilter config={config} filters={filters as DevicesTelemetryFilters} />;
		// case TableColumnType.BOOL:
		// 	return <DevicesTableFilter config={config} filters={filters as DevicesTelemetryFilters} />;
		// case TableColumnType.DATE:
		// 	return <DeviceDetailMapFilter config={config} filters={filters as TelemetryMapFilters} />;
		// case TableColumnType.TEMPERATURE:
		// 	return <GroupsTableFilters config={config} filters={filters as GroupsFilters} />;
		// case TableColumnType.LIGHT:
		// 	return <DevicesTableFilter config={config} filters={filters as DevicesTelemetryFilters} />;
		// case TableColumnType.BATTERY:
		// 	return <DevicesTableFilter config={config} filters={filters as DevicesTelemetryFilters} />;
		// default:
		// return null;
	};

	return (
		<MuiTableHead>
			<TableRow>
				{selectable && (
					<TableCell padding="checkbox">
						<Checkbox
							color="primary"
							indeterminate={numSelectedRows > 0 && numSelectedRows < rowCount}
							checked={rowCount > 0 && numSelectedRows === rowCount}
							onChange={onClickSelectAll}
						/>
					</TableCell>
				)}

				{cells.map((cell) => {
					return (
						<TableCell
							key={cell.label}
							className={clsx(cell.hidden && classes.hidden)}
							padding={cell.disablePadding ? 'none' : 'default'}
							align={cell.id === 'actions' ? 'right' : 'left'}
						>
							<div className={classes.tableHead}>
								{sortable && !cell.unorderable && cell.id !== 'actions' ? (
									<>
										<TableSortLabel
											active={cell.sort?.sortOrder !== undefined}
											direction={cell.sort?.sortOrder === SortOrder.Descending ? 'desc' : 'asc'}
											onClick={handleSortChange && (() => handleSortChange(cell))}
										>
											{cell.label}
										</TableSortLabel>
										{getFilter(cell)}
									</>
								) : (
									<>{cell.label}</>
								)}
							</div>
						</TableCell>
					);
				})}
			</TableRow>
		</MuiTableHead>
	);
};

import { createReducer } from 'typesafe-actions';
import { ReportsState, ReportActions } from './reports-types';
import {
	FETCH_REPORTS_ERROR,
	FETCH_REPORTS_REQUEST,
	FETCH_REPORTS_SUCCESS,
	FETCH_NOTIFICATIONS_REQUEST,
	FETCH_NOTIFICATIONS_SUCCESS,
	FETCH_NOTIFICATIONS_ERROR,
	UPDATE_CURRENT_NOTIFICATIONS,
	FETCH_CURRENT_REPORTS_SUCCESS,
} from './reports-constants';
import { SortOrder } from '../../components/Table/constants';
import { set } from 'lodash/fp';

export const reportsInitialState: ReportsState = {
	config: {
		offset: 0,
		limit: 10,
		order: SortOrder.Ascending,
		orderBy: 'name',
	},
	filters: {
		name: [],
	},
	notifications: {
		data: [],
		total: 0,
		offset: 0,
		limit: 10,
		orderings: [],
		filters: [],
	},
	currentNotifications: [],
	currentReports: [],
	data: [],
	total: 0,
};

export const reportsReducer = createReducer<ReportsState, ReportActions>(reportsInitialState, {
	[FETCH_REPORTS_REQUEST]: (state) => state,
	[FETCH_REPORTS_SUCCESS]: (state, action) => {
		return {
			...state,
			data: action.payload.data.data,
			total: action.payload.data.total,
		};
	},
	[FETCH_REPORTS_ERROR]: (state) => state,
	[FETCH_NOTIFICATIONS_REQUEST]: (state) => state,
	[FETCH_NOTIFICATIONS_SUCCESS]: (state, action) => {
		const newState = {
			data: action.payload.data.data,
			total: action.payload.data.total,
			limit: action.payload.options ? action.payload.options.limit : state.notifications.limit,
			offset: action.payload.options ? action.payload.options.offset : state.notifications.offset,
			orderings: action.payload.options?.orderings || state.notifications.orderings,
			filtersAndConditions: action.payload.options?.filtersAndConditions || state.notifications.filters,
		};

		return set('notifications', newState, state);
	},
	[FETCH_NOTIFICATIONS_ERROR]: (state) => state,
	[UPDATE_CURRENT_NOTIFICATIONS]: (state, action) => {
		// Store only last 5 notifications data
		const data =
			state.currentNotifications.length === 5
				? state.currentNotifications.slice(0, state.currentNotifications.length - 1)
				: state.currentNotifications;

		const updated = [action.payload.data, ...data];

		return set('currentNotifications', updated, state);
	},
	[FETCH_CURRENT_REPORTS_SUCCESS]: (state, action) => {
		return set('currentReports', action.payload.data.data, state);
	},
});

import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { BackButton } from '../../components/Button/BackButton';
import { DriversTable } from './components/DriversTable';

export const Drivers = () => {
	// const { t } = useTranslation();

	return (
		<Grid container spacing={2} justify="center">
			<Grid item xs={12}>
				<BackButton />
			</Grid>
			<Grid item xs={12} lg={10}>
				<Paper>
					<DriversTable />
				</Paper>
			</Grid>
		</Grid>
	);
};

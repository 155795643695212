import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en } from './i18n/en';
import { sk } from './i18n/sk';
import { cz } from './i18n/cz';
import { STORAGE_KEYS, LanguagesEnum } from './helpers/generalConstants';

//import {fetch} from 'sync-fetch';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const sfetch = require('sync-fetch');

/*
 * Initialization for i18next module
 */

const TOKEN = 'Token VTWNhAhKyCZYAqEnlfHDKZodq8yV2S3Nkczo628L';
const WEBLATE_URL = 'https://app.gorebit.com';

function getLng(lng: string): any {
	try {
		const data = sfetch(`${WEBLATE_URL}/translations/asset-tracking-platform/common/${lng}/file/`, {
			headers: { Authorization: TOKEN },
		});

		return data.json();
	} catch (error) {
		console.error(`WEBLATE method: ${error}`);
	}
}

i18n.use(initReactI18next).init({
	resources: {
		en: { translation: { ...en.translation, ...getLng('en') } },
		sk: { translation: { ...sk.translation, ...getLng('sk') } },
		cz: { translation: { ...cz.translation, ...getLng('cs') } },
	},
	fallbackLng: LanguagesEnum.EN,
	debug: true,
	lng: localStorage.getItem(STORAGE_KEYS.LANG) || LanguagesEnum.EN,
});

import { action } from 'typesafe-actions';
import {
	CREATE_DEVICE_SUCCESS,
	UPLOAD_DEVICES_SUCCESS,
	FETCH_DEVICES_TELEMETRY_SUCCESS,
	FETCH_DEVICE_SUCCESS,
	SET_DEVICES_TABLE_HIDEN_FLAGS_SUCCESS,
	SET_TELEMETRY_TABLE_HIDEN_FLAGS_SUCCESS,
	GENERAL_DEVICE_ERROR,
	GENERAL_DEVICE_REQUEST,
	FETCH_DEVICES_TELEMETRY_SUCCESS_NO_PROGRESS_BAR,
	FETCH_DEVICE_SUCCESS_NO_PROGRESS_BAR,
	FETCH_DEVICE_TYPES_SUCCESS,
	CREATE_DEVICE_TYPE_SUCCESS,
	EDIT_DEVICE_TYPE_SUCCESS,
	DELETE_DEVICE_TYPE_SUCCESS,
	EDIT_DEVICE_SUCCESS,
	EDIT_DEVICE_PROPERTIES_SUCCESS,
} from './devices-constants';

import {
	DeviceResponseData2,
	DevicesResponseData2,
	DeviceType,
	DeviceTypesResponseData,
} from '../../pages/Devices/devices-types';
import { FiltersRequestBody2, HideFlag, TableHeadCell } from '../../components/Table/types';
import { UUID } from '../../types';

// general request action with no impact but starting the progress bar
export const generalDeviceRequest = () => action(GENERAL_DEVICE_REQUEST);
// general error action with no impact but stopping the progress bar
export const generalDeviceError = () => action(GENERAL_DEVICE_ERROR);

export const createDeviceSuccess = () => action(CREATE_DEVICE_SUCCESS);

export const editDeviceSuccess = () => action(EDIT_DEVICE_SUCCESS);

export const uploadDevicesSuccess = () => action(UPLOAD_DEVICES_SUCCESS);

export const fetchDevicesTelemetrySuccess = (data: DevicesResponseData2, options?: FiltersRequestBody2) =>
	action(FETCH_DEVICES_TELEMETRY_SUCCESS, { data, options });
export const fetchDevicesTelemetrySuccessNoProgressBar = (data: DevicesResponseData2, options?: FiltersRequestBody2) =>
	action(FETCH_DEVICES_TELEMETRY_SUCCESS_NO_PROGRESS_BAR, { data, options });

export const fetchDeviceSuccess = (data: DeviceResponseData2) => action(FETCH_DEVICE_SUCCESS, { data });
export const fetchDeviceSuccessNoProgressBar = (data: DeviceResponseData2) =>
	action(FETCH_DEVICE_SUCCESS_NO_PROGRESS_BAR, { data });

export const setDeviceTableHidenFlags = (data: HideFlag[]) => action(SET_DEVICES_TABLE_HIDEN_FLAGS_SUCCESS, { data });
export const setTelemetryTableHidenFlags = (data: TableHeadCell[]) =>
	action(SET_TELEMETRY_TABLE_HIDEN_FLAGS_SUCCESS, { data });

export const fetchDeviceTypesSuccess = (data: DeviceTypesResponseData, options?: FiltersRequestBody2) =>
	action(FETCH_DEVICE_TYPES_SUCCESS, { data, options });

export const createDeviceTypeSuccess = () => action(CREATE_DEVICE_TYPE_SUCCESS);

export const editDeviceTypeSuccess = (data: DeviceType) =>
	action(EDIT_DEVICE_TYPE_SUCCESS, {
		data,
	});

export const deleteDeviceTypeSuccess = (id: UUID) =>
	action(DELETE_DEVICE_TYPE_SUCCESS, {
		id,
	});

export const editDevicePropertiesSuccess = () => action(EDIT_DEVICE_PROPERTIES_SUCCESS);

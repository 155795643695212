import {
	commonVehicleWatchEmptyRequest,
	commonVehicleWatchEmptyError,
	fetchDriversSuccess,
	fetchVehiclesSuccess,
} from './vehicle-watch-actions';
import { AsyncAction } from '../types';
import { addToast } from '../app/app-actions';
import { fetchRequest, createToastError } from '../../services/helpers';
import * as devicePlacementsService from '../../services/device-placements/device-placements-service';
import { FiltersRequestBody2 } from '../../components/Table/types';
import { DevicePlacementsResponseData } from '../../pages/DevicePlacementsManagement/device-placements-types';

export const fetchDrivers: AsyncAction<FiltersRequestBody2> = (driversPayload) => async (dispatch) => {
	dispatch(commonVehicleWatchEmptyRequest());

	const request = devicePlacementsService.fetchPlacements(driversPayload);
	const { data, error } = await fetchRequest<DevicePlacementsResponseData>(request);

	if (data) {
		dispatch(fetchDriversSuccess(data, driversPayload));
	} else {
		dispatch(commonVehicleWatchEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(fetchDrivers(driversPayload));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const fetchVehiclesWithTelemetry: AsyncAction<FiltersRequestBody2> = (body) => async (dispatch) => {
	dispatch(commonVehicleWatchEmptyRequest());

	const request = devicePlacementsService.fetchPlacementsWithTelemetry(body);
	const { data, error } = await fetchRequest<DevicePlacementsResponseData>(request);

	if (data) {
		dispatch(fetchVehiclesSuccess(data, body));
	} else {
		dispatch(commonVehicleWatchEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(fetchVehiclesWithTelemetry(body));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { WarehouseTable } from './components/WarehouseTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { Grid } from '@material-ui/core';
import { fetchDevices } from '../../store/warehouse/warehouse-async-actions';
import { WarehouseState } from '../../store/warehouse/warehouse-types';

export const Warehouse = () => {
	const dispatch = useDispatch();
	const { data, total, offset, limit, orderings, filtersAndConditions } = useSelector<RootState, WarehouseState>(
		(state) => state.warehouse,
	);

	useEffect(() => {
		dispatch(
			fetchDevices({
				offset: offset,
				limit: limit,
				filtersAndConditions: filtersAndConditions,
				orderings: orderings,
			}),
		);

		// Don't need to react on "options", deep comparision is not necessary
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	return (
		<Grid container spacing={2} justify="center">
			<Grid item xs={12} lg={10}>
				<Paper>
					<WarehouseTable
						data={data}
						total={total}
						offset={offset}
						limit={limit}
						orderings={orderings}
						filters={filtersAndConditions}
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};

import { Filter, FiltersPayload, FiltersRequestBody } from '../../components/Table/types';
import { PaginatedResponse } from '../../store/types';
import { Nullable, UUID } from '../../types';
import { NotificationSeverity } from '../Workflows/constants';

export enum ReportUnit {
	DEGREES_CELSIUS = 'degreesCelsius',
	DATE = 'date',
	PERCENTAGE = 'percentage',
	STRING = 'string',
}

export type ReportItem = {
	name: string;
	value: string | number;
	unit?: ReportUnit;
};

export type ReportGroup = {
	id: UUID;
	groupName: string;
	reports: ReportItem[];
};

// Modified Report object for table
export type Report = {
	id: string;
	[group: string]: unknown;
};

export type ReportPayload = FiltersPayload & { workflowId: string };
export type ReportRequestBody = { workflowId: string };
export type ReportResponseData = PaginatedResponse<ReportGroup>;

export type NotificationResponseData = {
	dateCreated: string;
	dateUpdated: string;
	deletedAt: Nullable<string>;
	id: UUID;
	message: string;
	severity: NotificationSeverity;
	sn: string;
	time: string;
	workflowId: UUID;
	placementId: UUID;
};

export type NotificationsPayload = FiltersPayload;
export type NotificationsRequestBody = FiltersRequestBody;
export type NotificationsResponseData = PaginatedResponse<NotificationResponseData>;
export type NotificationsFilters = {
	sn: Filter[];
	time: Filter[];
	severity: Filter[];
	message: Filter[];
};

export type NotificationSocketData = {
	message: string;
	severity: NotificationSeverity;
	sn: string;
	tenantId: UUID;
	time: string;
	workflowId: UUID;
};

export type Notification = Omit<NotificationSocketData, 'tenantId'>;

import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

type LabelType = 'info' | 'success' | 'error' | 'warning' | 'default';

type Props = {
	className?: string;
	children: string | number;
	type?: LabelType;
};

const useStyles = makeStyles(({ spacing, typography, palette }: Theme) => {
	const setBgColor = (type: LabelType) => (type === 'default' ? palette.grey[200] : palette[type].light);
	const setColor = (type: LabelType) =>
		type === 'default' ? palette.getContrastText(palette.grey[200]) : palette[type].dark;

	return {
		label: {
			padding: spacing(0.5, 1),
			backgroundColor: ({ type = 'default' }: Props) => setBgColor(type),
			color: ({ type = 'default' }: Props) => setColor(type),
			textTransform: 'uppercase',
			fontSize: typography.pxToRem(11),
			fontWeight: 500,
			borderRadius: spacing(0.25),
		},
	};
});

export const Label = (props: Props) => {
	const { children, className } = props;
	const classes = useStyles(props);

	return <span className={clsx(classes.label, className)}>{children}</span>;
};

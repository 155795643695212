import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { fetchReports } from '../../store/reports/reports-async-actions';
import { useParams } from 'react-router-dom';
import { ReportsState } from '../../store/reports/reports-types';
import { RootState } from '../../store/types';
import { ReportsTable } from './components/ReportsTable';
import { BackButton } from '../../components/Button/BackButton';

type RouteParams = {
	workflowId: string;
};

export const Reports = () => {
	const dispatch = useDispatch();
	const { workflowId } = useParams<RouteParams>();
	const { data, total, config } = useSelector<RootState, ReportsState>((state) => state.reports);

	useEffect(() => {
		dispatch(fetchReports({ workflowId }));
	}, [dispatch, workflowId]);

	return (
		<Grid container spacing={2} justify="center">
			<Grid item xs={12}>
				<BackButton />
			</Grid>
			<Grid item xs={12} lg={10}>
				<Paper>
					<ReportsTable data={data} total={total} limit={config.limit} offset={config.offset} />
				</Paper>
			</Grid>
		</Grid>
	);
};

import { AxiosResponse, AxiosError } from 'axios';
import { FetchResponse, ServerError } from './common-types';
import { v4 as uuidv4 } from 'uuid';
import i18next from 'i18next';
import { Toast } from '../store/app/app-types';

/**
 * Returns true if HTTP status is a Success
 *
 * @param status - HTTP status code
 */
export const isStatusSuccess = (status: number) => status >= 200 && status < 300;

/**
 * Fetch request helper that returns HTTP status code, if success data or an error
 *
 * @param request
 */
export const fetchRequest = async <Response>(
	request: Promise<AxiosResponse<Response>>,
): Promise<FetchResponse<Response>> => {
	try {
		const response: AxiosResponse<Response> = await request;

		return {
			data: response.data,
			status: response.status,
		};
	} catch (error) {
		const serverError = error as AxiosError<ServerError>;

		if (serverError && serverError.response) {
			return {
				status: serverError.response.status,
				error: serverError.response,
			};
		}

		throw error;
	}
};

/**
 * Creates data for error toast message
 *
 * @param error - Server Error object from response
 * @param action - Try again action (optional)
 */
export const createToastError = (serverError: ServerError, action?: () => void): Toast => {
	const errorTranslation = i18next.t(serverError?.errorCode);
	const missingErrorCode = errorTranslation === serverError?.errorCode;

	return {
		id: uuidv4(),
		title: i18next.t('error'),
		content: missingErrorCode ? serverError.message : errorTranslation,
		severity: 'error',
		timeout: 6000,
		button: action
			? {
					text: i18next.t('tryAgain'),
					action,
			  }
			: undefined,
	};
};

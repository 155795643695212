import {
	generalContactGroupsRequest,
	generalContactGroupsError,
	fetchContactGroupsSuccess,
	createContactGroupSuccess,
	updateContactGroupSuccess,
	deleteContactGroupSuccess,
} from './contact-groups-actions';

import { AsyncAction } from '../types';
import { UUID } from '../../types';
import { addToast } from '../app/app-actions';
import { fetchRequest, createToastError, isStatusSuccess } from '../../services/helpers';
import * as groupsService from '../../services/contact-groups/contact-groups-service';
import {
	CreateGroupPayload,
	GroupResponseData,
	UpdateGroupPayload,
	GroupsResponseData,
	UpdateGroupResponseData,
} from '../../pages/ContactGroups/contact-groups-types';

import { omit } from 'lodash/fp';
import { FiltersRequestBody2 } from '../../components/Table/types';

export const fetchContactGroups: AsyncAction<FiltersRequestBody2> = (groupsPayload) => async (dispatch) => {
	dispatch(generalContactGroupsRequest());

	const request = groupsService.getFilteredContactGroups(groupsPayload);
	const { data, error } = await fetchRequest<GroupsResponseData>(request);

	if (data) {
		dispatch(fetchContactGroupsSuccess(data, groupsPayload));
	} else {
		dispatch(generalContactGroupsError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(fetchContactGroups(groupsPayload));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const createContactGroup: AsyncAction<CreateGroupPayload> = (createGroupPayload) => async (
	dispatch,
	getState,
) => {
	dispatch(generalContactGroupsRequest());

	const { groups } = getState();
	const request = groupsService.createContactGroup(createGroupPayload);
	const { data, status, error } = await fetchRequest<GroupResponseData>(request);

	if (isStatusSuccess(status) && data) {
		dispatch(createContactGroupSuccess());
		dispatch(
			fetchContactGroups({
				limit: groups.limit,
				offset: groups.offset,
				orderings: groups.orderings,
				filtersAndConditions: groups.filtersAndConditions,
			}),
		);
	} else {
		dispatch(generalContactGroupsError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(createContactGroup(createGroupPayload));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const updateContactGroup: AsyncAction<UpdateGroupPayload> = (updateGroupPayload) => async (
	dispatch,
	getState,
) => {
	dispatch(generalContactGroupsRequest());

	const body = omit(['id'], updateGroupPayload);
	const request = groupsService.updateContactGroup(body, updateGroupPayload.id);
	const { data, error } = await fetchRequest<UpdateGroupResponseData>(request);
	// const { devicesToUpdate, devicesToRemove } = updateGroupPayload;
	// const devicesUpdated = devicesToUpdate || devicesToRemove;

	if (data) {
		dispatch(updateContactGroupSuccess(data));
	} else {
		dispatch(generalContactGroupsError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(updateContactGroup(updateGroupPayload));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const deleteContactGroup: AsyncAction<UUID> = (groupId) => async (dispatch) => {
	dispatch(generalContactGroupsRequest());

	const request = groupsService.deleteContactGroup(groupId);
	const { status, error } = await fetchRequest<void>(request);

	if (isStatusSuccess(status) && groupId) {
		dispatch(deleteContactGroupSuccess(groupId));
	} else {
		dispatch(generalContactGroupsError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(deleteContactGroup(groupId));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { Button } from '../../../components/Button/Button';
import { InputField } from '../../../components/Form/InputField';
import SaveIcon from '@material-ui/icons/Save';
import { ModalDialogFormActions } from '../../../components/ModalDialog/ModalDialogFormActions';
import { Pipeline } from '../pipelines-types';
import { UUID } from '../../../types';
import { useDispatch } from 'react-redux';
import { getWorkflowPipelines } from '../../../services/workflows/workflows-service';
import { fetchRequest } from '../../../services/helpers';
import { WorkflowPipelines } from '../../Workflows/workflows-types';
import { InputSelectUncontrolled } from '../../../components/Form/InputSelectUncontrolled';

export type PipelineFormValues = {
	id?: UUID;
	name: string;
};

type Props = {
	onSubmit: (values: PipelineFormValues) => void;
	onCancel: () => void;
	defaultValues?: Pipeline;
};

type ttt = { id: string; label: string };

export const PipelineForm = ({ onSubmit, onCancel, defaultValues }: Props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	// const { tenant } = useSelector<RootState, UserState>((state) => state.user);

	// const [allPipelines, setAllPipelines] = useState<Pipeline[]>();
	const [workflowPipelines, setWorkflowPipelines] = useState<ttt[]>();

	const [name, setName] = useState<string>(defaultValues?.name ?? '');
	const [nameError, setNameError] = useState<boolean>(false);

	useEffect(() => {
		const request = getWorkflowPipelines({
			limit: -1,
			offset: 0,
			filtersAndConditions: [],
			orderings: [],
		});

		(async () => {
			const { data, error } = await fetchRequest<WorkflowPipelines[]>(request);

			if (error) {
				// TODO show error
			} else {
				setWorkflowPipelines(
					data
						?.filter((_) => _.pipelineNames != null)
						.flatMap<ttt>((_) =>
							_.pipelineNames!.filter((_) => _ !== undefined).map<ttt>((pip) => ({
								id: pip,
								label: _.name,
							})),
						),
				);
			}
		})();
	}, [dispatch]);

	// useEffect(() => {
	// 	const request = fetchPipelines({
	// 		limit: -1,
	// 		offset: 0,
	// 		filtersAndConditions: [],
	// 		orderings: [],
	// 	});

	// 	(async () => {
	// 		const { data, error } = await fetchRequest<PipelinesResponseData>(request);

	// 		if (error) {
	// 			// TODO show error
	// 		} else {
	// 			setAllPipelines(data?.data);
	// 		}
	// 	})();
	// }, [dispatch]);

	const handleSetName = (event: ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value);
		setNameError(false);
	};

	function handleSubmitForm() {
		let valid = true;

		if (name === '') {
			valid = false;
			setNameError(true);
		}

		if (valid) {
			onSubmit({
				id: defaultValues?.id,
				name: name,
				// tenantId: tenant?.id ?? '', // TODO
			});
		}
	}

	return (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<InputField
					autoFocus
					value={name}
					error={nameError}
					helperText={nameError && t('validationErrors.required')}
					label={t('name')}
					name="name"
					onChange={handleSetName}
				/>
			</Grid>
			<Grid item>
				{workflowPipelines && (
					<InputSelectUncontrolled
						// eslint-disable-next-line @typescript-eslint/no-empty-function
						optionLabel={(option) => option.id}
						// defaultValueId={'0'}
						onChanged={(value) => {
							// setSeverity(value?.id ?? '0');
							// console.log(value);
							if (value) {
								setName(value.id);
							}
						}}
						error={false}
						options={workflowPipelines}
						label={t('pickPipelineName')}
						renderOpt={(params) => (
							<Grid container direction="column">
								<Grid item>
									<Typography variant="subtitle1">{params.id}</Typography>
								</Grid>
								<Grid item>
									<Typography variant="body2">{params.label}</Typography>
								</Grid>
							</Grid>
						)}
					/>
				)}
			</Grid>
			<Grid item>
				<ModalDialogFormActions>
					<Button startIcon={<SaveIcon />} onClick={handleSubmitForm} data-cy="btn-submit:pipelineForm">
						{t('save')}
					</Button>
					<Button onClick={onCancel} variant="text" data-cy="btn-cancel:pipelineForm">
						{t('cancel')}
					</Button>
				</ModalDialogFormActions>
			</Grid>
		</Grid>
	);
};

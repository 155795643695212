import { apiClient } from '../client';
import { FiltersRequestBody, FiltersRequestBody2 } from '../../components/Table/types';
import { UUID } from '../../types';
import {
	UsersResponseData,
	UserResponseData,
	CreateUserRequestBody,
	EditUserRequestBody,
	ChangePasswordRequestBody,
} from '../../pages/UserManagement/user-management-types';
import { UserRolesResponseData, CreateUserRoleRequestBody, UserRole } from '../../pages/UserRoles/user-roles-types';

const baseUrlForDotNet = '/identity.net/users';
const baseUrlRoles = '/identity.net/roles';

export const getFilteredUsers = (body: FiltersRequestBody) =>
	apiClient.post<UsersResponseData>(`${baseUrlForDotNet}/filter`, body);

export const editUser = (user: EditUserRequestBody, userId: UUID) =>
	apiClient.put<UserResponseData>(`${baseUrlForDotNet}/${userId}`, user);

export const createUser = (user: CreateUserRequestBody) => apiClient.post<UserResponseData>(baseUrlForDotNet, user);
export const deleteUser = (userId: UUID) => apiClient.delete(`${baseUrlForDotNet}/${userId}`);

export const changePassword = (body: ChangePasswordRequestBody, userId: UUID) =>
	apiClient.put(`${baseUrlForDotNet}/password-change/${userId}`, body);

export const getFilteredUserRoles = (body: FiltersRequestBody2) =>
	apiClient.post<UserRolesResponseData>(`${baseUrlRoles}/filter`, body);

export const createUserRole = (user: CreateUserRoleRequestBody) => apiClient.post<UserRole>(baseUrlRoles, user);

export const editUserRole = (user: CreateUserRoleRequestBody, userId: UUID) =>
	apiClient.put<UserRole>(`${baseUrlRoles}/${userId}`, user);

export const deleteUserRole = (userId: UUID) => apiClient.delete(`${baseUrlRoles}/${userId}`);

import React, { ChangeEvent, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { InfoCard } from '../../components/InfoCard/InfoCard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { BackButton } from '../../components/Button/BackButton';
import { useParams } from 'react-router-dom';
import { IngestionScript, ValidateIngestionScriptResponse } from './ingestion-scripts-types';
import { InputField } from '../../components/Form/InputField';
import { useTranslation } from 'react-i18next';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import SaveIcon from '@material-ui/icons/Save';
import { fetchRequest } from '../../services/helpers';
import {
	testIngestionScript,
	validateIngestionScript,
} from '../../services/ingestion-endpoints/ingestion-endpoints-service';
import {
	createIngestionScript,
	editIngestionScript,
} from '../../store/ingestion-endpoints/ingestion-endpoints-async-actions';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import { Button } from '../../components/Button/Button';
import { Textarea } from '../../components/Form/Textarea';
import { formatWithPrettier } from '../../helpers/string';
import { setRedirectUrl } from '../../store/app/app-actions';
import { RouteEnum } from '../../router/Routes';

require('codemirror/mode/dart/dart.js');

type RouteParams = {
	scriptId: string;
};

const defaultValues = {
	id: '',
	name: '',
	script1:
		'using Common.Contracts.Ingest;\nusing System;\n\nnamespace Payload\n{\n\tpublic class Parser\n\t{\n\t\tpublic IEnumerable<TelemetryPayload> Parse(Guid tenantId, object input)\n\t\t{\n\t\t\tyield return new TelemetryPayload() { time = DateTime.Now };\n\t\t}\n\t}\n}',
};

export const IngestionScriptDetail = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { scriptId } = useParams<RouteParams>();

	const scripts = useSelector<RootState, IngestionScript[]>((state) => state.ingestionEndpoints.scripts.data);

	const [script, setScript] = useState(scripts.find((script) => script.id === scriptId) || defaultValues);

	const [validationState, setValidationState] = useState<'validate' | 'validating'>('validate');

	const [testState, setTestState] = useState<'testScript' | 'testingScript'>('testScript');

	const [validation, setValidation] = useState<ValidateIngestionScriptResponse>();

	const [nameEmpty, setNameEmpty] = useState<boolean>(false);

	const [testDataEmpty, setTestDataEmpty] = useState<boolean>(false);

	const [testResult, setTestResult] = useState<ValidateIngestionScriptResponse>();

	const [testData, setTestData] = useState<string>();

	// const [logs, setLogs] = useState<LogData[]>();

	const testResultJson = JSON.stringify(testResult?.result);

	let formattedTestResultJson;

	try {
		formattedTestResultJson = testResultJson && formatWithPrettier(testResultJson);
	} catch (exception) {
		formattedTestResultJson = testResultJson;
	}

	// useEffect(() => {
	// 	const request = fetchLogs({
	// 		limit: 100,
	// 		offset: 0,
	// 		filtersAndConditions: [],
	// 		orderings: [{ column: 'dt', sortOrder: SortOrder.Descending }],
	// 	});

	// 	setValidationState('validating');

	// 	(async () => {
	// 		const { data, error } = await fetchRequest<LogsResponseData>(request);

	// 		setValidationState('validate');

	// 		if (error) {
	// 			// TODO show error
	// 		} else {
	// 			if (data) {
	// 				setLogs(data.data);
	// 			}
	// 		}
	// 	})();

	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [dispatch]);

	const handleNameChange = (event: any) => {
		setScript({ ...script, name: event.target.value });
		setNameEmpty(false);
	};

	const handleScriptChange = (value: string) => {
		setScript({ ...script, script1: value });
	};

	const handleTestDataChange = (event: ChangeEvent<HTMLInputElement>) => {
		setTestData(event.currentTarget.value);
		setTestDataEmpty(false);
	};

	const handleSubmitForm = () => {
		let valid = true;

		if (script.name === '') {
			setNameEmpty(true);
			valid = false;
		}

		if (valid) {
			if (script.id === undefined || script.id === '') {
				dispatch(createIngestionScript(script));
			} else {
				dispatch(editIngestionScript(script));
			}

			dispatch(setRedirectUrl(RouteEnum.INGESTION_SCRIPTS));
		}
	};

	const handleValidateForm = () => {
		const request = validateIngestionScript({
			...script,
		});

		setValidationState('validating');

		(async () => {
			const { data, error } = await fetchRequest<ValidateIngestionScriptResponse>(request);

			setValidationState('validate');

			if (error) {
				// TODO show error
			} else {
				if (data) {
					setValidation(data);
				}
			}
		})();
	};

	const handleTestForm = () => {
		if (testData === undefined || testData === '') {
			setTestDataEmpty(true);
		} else {
			const request = testIngestionScript({
				...script,
				testData: testData,
			});

			setTestState('testingScript');

			(async () => {
				const { data, error } = await fetchRequest<ValidateIngestionScriptResponse>(request);

				setTestState('testScript');

				if (error) {
					// TODO show error
				} else {
					if (data) {
						setTestResult(data);
					}
				}
			})();
		}
	};

	return (
		<Grid container spacing={2} direction="column">
			<Grid item xs={12}>
				<BackButton />
			</Grid>
			<Grid item sm={12} lg={10} xl={10}>
				<InfoCard>
					<>
						<>
							<InputField
								autoFocus
								error={nameEmpty}
								helperText={nameEmpty && t('validationErrors.required')}
								label={t('name')}
								name="name"
								onChange={handleNameChange}
								defaultValue={script.name}
							/>
							<CodeMirror
								autoCursor={false}
								value={script.script1}
								options={{
									mode: 'dart',
									theme: 'material',
									lineNumbers: true,
								}}
								onChange={(editor, data, value) => {
									handleScriptChange(value);
								}}
							/>
						</>
						<Button
							disabled={validationState !== 'validate'}
							startIcon={<PlaylistAddCheckIcon />}
							onClick={() => handleValidateForm()}
							type="button"
							variant="text"
							data-cy="btn-submit-validate:scriptForm"
						>
							{t(validationState)}
						</Button>
						<TextField
							fullWidth
							multiline
							disabled
							style={{ pointerEvents: 'none' }}
							name="test data"
							value={validation?.message}
							type={'text'}
							variant="standard"
						/>
						<Textarea
							code
							name="test data"
							label="test data"
							error={testDataEmpty}
							helperText={testDataEmpty && t('validationErrors.required')}
							onChange={handleTestDataChange}
						/>
						<Button
							disabled={testState !== 'testScript'}
							startIcon={<PlaylistAddCheckIcon />}
							onClick={() => handleTestForm()}
							type="button"
							variant="text"
							data-cy="btn-submit-validate:scriptForm"
						>
							{t(testState)}
						</Button>
						<TextField
							fullWidth
							multiline
							disabled
							style={{ pointerEvents: 'none' }}
							name="test data"
							value={testResult?.message}
							type={'text'}
							variant="standard"
						/>
						<TextField
							fullWidth
							multiline
							disabled
							style={{ pointerEvents: 'none' }}
							name="test result"
							value={formattedTestResultJson}
							type={'text'}
							variant="standard"
						/>
					</>
				</InfoCard>
			</Grid>
			<Grid item>
				<Button startIcon={<SaveIcon />} onClick={() => handleSubmitForm()} data-cy="btn-submit:scriptForm">
					{t('save')}
				</Button>
			</Grid>
		</Grid>
	);
};

import React, { SyntheticEvent, useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Button } from '../Button/Button';
import { STORAGE_KEYS, LanguagesEnum } from '../../helpers/generalConstants';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
	btnLang: {
		color: '#fff',
	},
}));

const languages = [
	{
		id: LanguagesEnum.EN,
		localeKey: 'english',
	},
	{
		id: LanguagesEnum.SK,
		localeKey: 'slovak',
	},
	{
		id: LanguagesEnum.CZ,
		localeKey: 'czech',
	},
];

type Language = {
	id: LanguagesEnum;
	localeKey: string;
};

export const LanguageMenu = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const defaultLanguage = languages.find(({ id }) => id === localStorage.getItem(STORAGE_KEYS.LANG)) || languages[0];
	const [language, setLanguage] = useState<Language>(defaultLanguage);

	const handleClick = (event: SyntheticEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleChooseLanguage = async (language: Language) => {
		await i18n.changeLanguage(language.id);
		setLanguage(language);
		localStorage.setItem(STORAGE_KEYS.LANG, language.id);
		handleClose();
	};

	return (
		<>
			<Button
				aria-controls="simple-menu"
				aria-haspopup="true"
				color="default"
				onClick={handleClick}
				size="small"
				variant="text"
				className={classes.btnLang}
			>
				{language && t(language.localeKey)}
				<KeyboardArrowDownIcon />
			</Button>
			<Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
				{languages.map((language) => (
					<MenuItem key={language.id} onClick={() => handleChooseLanguage(language)}>
						{t(language.localeKey)}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

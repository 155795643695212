import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/Button/Button';
import SaveIcon from '@material-ui/icons/Save';
import { ModalDialogFormActions } from '../../../components/ModalDialog/ModalDialogFormActions';
import { Grid } from '@material-ui/core';
import { InputSelectUncontrolled } from '../../../components/Form/InputSelectUncontrolled';
import { UUID } from '../../../types';

type Props = {
	devices: { sn: string; id: UUID }[];
	onSubmit: (eportType: string) => void;
	onCancel?: () => void;
};

const types = ['xml', 'cra'];

export const ExportDevicesForm = ({ devices, onSubmit, onCancel }: Props) => {
	const { t } = useTranslation();

	const [selectedExportType, setSelectedExportType] = useState(types[0]);

	const handleSubmitForm = () => {
		// let valid = true;

		// if (devices.length === 0) {
		// 	valid = false;
		// 	// setSnError(true);
		// }

		// if (valid) {
		onSubmit(selectedExportType);
		// }
	};

	// const selectedDeviceType = deviceTypes.find((deviceType) => deviceType.id === values?.deviceTypeId);

	// console.log(selectedDeviceType);

	return (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<InputSelectUncontrolled
					defaultValueId={types[0]}
					onChanged={(value) => {
						value && setSelectedExportType(value.id);
					}}
					error={false}
					options={
						types.map((exportType) => ({
							id: exportType,
							label: exportType,
						})) ?? []
					}
					label={t('selectExportType')}
				/>
			</Grid>
			<Grid item>{`selected devices: ${devices.map((device) => ` ${device.sn}`)}`}</Grid>
			<Grid item>
				<ModalDialogFormActions>
					<Button onClick={handleSubmitForm} startIcon={<SaveIcon />} data-cy="btn-submit:exportDevicesForm">
						{t('save')}
					</Button>
					<Button onClick={onCancel} variant="text" data-cy="btn-cancel:exportDevicesForm">
						{t('cancel')}
					</Button>
				</ModalDialogFormActions>
			</Grid>
		</Grid>
	);
};

import React, { useEffect } from 'react';
import {
	Card,
	CardHeader,
	Checkbox,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	Theme,
	makeStyles,
	ListItemText,
	Grid,
	Typography,
	useMediaQuery,
	Tooltip,
} from '@material-ui/core';

import { Button } from '../../../components/Button/Button';
import { useTranslation } from 'react-i18next';
import { GroupResponseData, GroupsResponseData, UpdateGroupResponseData } from '../contact-groups-types';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useSelector } from 'react-redux';
import { BREAKPOINTS, PermissionsDotNet } from '../../../helpers/generalConstants';
import { fetchRequest } from '../../../services/helpers';
import { FilterOperators } from '../../../components/Table/constants';
import { getFilteredContactGroups, updateContactGroup } from '../../../services/contact-groups/contact-groups-service';
import { RootState } from '../../../store/types';
import { UserState } from '../../../store/user/user-types';
import { hasPermission } from '../../../helpers/auth';
import { Contact, ContactsResponseData } from '../../Contacts/contacts-types';
import { fetchContacts } from '../../../services/contacts/contacts-service';

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
	card: {
		height: '100%',
	},
	list: {
		width: '100%',
		maxHeight: 600,
		backgroundColor: palette.background.paper,
		overflow: 'auto',
	},
	button: {
		margin: spacing(0.5, 0),
	},
	buttons: {
		alignSelf: 'center',
	},
	label: {
		'& + &': {
			marginLeft: spacing(1),
		},
	},
}));

type Props = {
	currentGroup: GroupResponseData;
};

export const ContactGroupsTransferList = ({ currentGroup }: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { permissions } = useSelector<RootState, UserState>((state) => state.user);
	const canEditContacts = hasPermission(PermissionsDotNet.ContactWrite, permissions);
	const matchesMd = useMediaQuery(BREAKPOINTS.md);

	const defaultValue = currentGroup.contacts;

	const [currentGroupItems, setCurrentGroupItems] = React.useState<Contact[]>(defaultValue);

	const [allContacts, setOthersGroupItems] = React.useState<Contact[]>(defaultValue);

	const [update, setUpdate] = React.useState({});

	const [toRemove, setToRemove] = React.useState<Contact[]>([]);
	const [toAdd, setToAdd] = React.useState<Contact[]>([]);

	useEffect(() => {
		const filterBody = {
			offset: 0,
			limit: 1,
			orderings: [],
			filtersAndConditions: [{ column: 'id', operator: FilterOperators.equals, value: currentGroup.id }],
		};

		const request = getFilteredContactGroups(filterBody);

		(async () => {
			const { data, error } = await fetchRequest<GroupsResponseData>(request);

			if (error) {
				// TODO show error
			} else {
				if (data?.data && data?.data.length > 0) {
					const contactGroup = data?.data[0];

					if (contactGroup) {
						setCurrentGroupItems(contactGroup.contacts ?? []);
					}
				}
			}
		})();
	}, [currentGroup.id, update]);

	useEffect(() => {
		const filterBody = {
			offset: 0,
			limit: -1,
			orderings: [],
			filtersAndConditions: [], // othersGroupFilters,
		};

		const request = fetchContacts(filterBody);

		(async () => {
			const { data, error } = await fetchRequest<ContactsResponseData>(request);

			if (error) {
				// TODO show error
			} else {
				setOthersGroupItems(data?.data ?? []);
			}
		})();
	}, []);

	const handleToggleAll = (side: 'left' | 'right') => {
		if (side === 'left') {
			if (toRemove.length === 0) {
				setToRemove(currentGroupItems);

				return;
			}

			setToRemove([]);
		} else {
			if (toAdd.length === 0) {
				setToAdd(getRemainingItems());

				return;
			}

			setToAdd([]);
		}
	};

	const handleToggle = (item: Contact, side: 'left' | 'right') => {
		if (!canEditContacts) {
			return;
		}

		if (side === 'left') {
			const isSelected = Boolean(toRemove.find(({ id }) => id === item.id));

			if (isSelected) {
				setToRemove(toRemove.filter(({ id }) => id !== item.id));

				return;
			}

			setToRemove([...toRemove, item]);
		} else {
			const isSelected = Boolean(toAdd.find(({ id }) => id === item.id));

			if (isSelected) {
				setToAdd(toAdd.filter(({ id }) => id !== item.id));

				return;
			}

			setToAdd([...toAdd, item]);
		}
	};

	const handleAddToGroup = () => {
		if (currentGroup) {
			const contactsToUpdate = toAdd.map(({ id }) => id);

			const request = updateContactGroup(
				{
					name: currentGroup.name,
					contactsToUpdate,
					contactsToRemove: [],
				},
				currentGroup.id,
			);

			(async () => {
				const { error } = await fetchRequest<UpdateGroupResponseData>(request);

				if (error) {
					// TODO show error
				} else {
					setUpdate({});

					setToRemove([]);
					setToAdd([]);
				}
			})();
		}
	};

	const handleRemoveFromGroup = () => {
		const contactsToRemove = toRemove.map(({ id }) => id);

		const request = updateContactGroup(
			{
				name: currentGroup.name,
				contactsToRemove,
				contactsToUpdate: [],
			},
			currentGroup.id,
		);

		(async () => {
			const { error } = await fetchRequest<UpdateGroupResponseData>(request);

			if (error) {
				// TODO show error
			} else {
				setUpdate({});

				setToRemove([]);
				setToAdd([]);
			}
		})();
	};

	function getRemainingItems() {
		return allContacts.filter((contact) =>
			currentGroupItems.find((currentContact) => contact.id === currentContact.id) === undefined ? true : false,
		);
	}

	function getName(contact: Contact) {
		return `${contact.titleBefore} ${contact.firstName} ${contact.lastName} ${contact.titleAfter}`;
	}

	const renderList = (title: string, items: Contact[], side: 'left' | 'right') => {
		const selectedItems = side === 'left' ? toRemove : toAdd;
		const numOfSelected = selectedItems.length;

		return (
			<Card className={classes.card}>
				<CardHeader
					action={side === 'left' ? <></> : <></>}
					avatar={
						<Checkbox
							disabled={!canEditContacts || items.length === 0}
							indeterminate={numOfSelected !== items.length && numOfSelected !== 0}
							inputProps={{ 'aria-label': 'all items selected' }}
							checked={numOfSelected === items.length && items.length !== 0}
							color="primary"
							name={`${side}Groups`}
							onClick={() => handleToggleAll(side)}
						/>
					}
					title={title}
					subheader={`${numOfSelected}/${items.length} selected`}
				/>
				<Divider />
				<List className={classes.list} dense component="div" role="list" data-cy={`div:${side}ItemList`}>
					{items.map((contact: Contact) => {
						return (
							<ListItem
								key={contact.id}
								role="listitem"
								button
								onClick={() => handleToggle(contact, side)}
								disabled={!canEditContacts}
							>
								<ListItemIcon>
									<Checkbox
										checked={Boolean(selectedItems.find((item: Contact) => item.id === contact.id))}
										color="primary"
										// inputProps={{ 'aria-labelledby': value.sn }}
										// name={`sn-${value.sn}`}
										// tabIndex={-1}
									/>
								</ListItemIcon>

								<ListItemText
									// id={value.sn}
									primary={
										<>
											<Typography component="div" variant="body1">
												{getName(contact)}
											</Typography>
										</>
									}
									secondary={
										<>
											{contact.email}
											{contact.email && <br />}
											{contact.phone}
										</>
									}
								/>
							</ListItem>
						);
					})}
					<ListItem />
				</List>
			</Card>
		);
	};

	return (
		<Grid container spacing={2} justify="center" direction={matchesMd ? 'row' : 'column'}>
			<Grid item xs={12} md={5}>
				{renderList(currentGroup?.name, currentGroupItems, 'left')}
			</Grid>
			<Grid className={classes.buttons} item md={2} lg="auto">
				{canEditContacts && (
					<Grid container direction="column" alignItems="center">
						<Tooltip title={t('removeFromGroup') as string}>
							<Button
								variant="outlined"
								size="small"
								className={classes.button}
								onClick={() => handleRemoveFromGroup()}
								disabled={toRemove.length === 0}
								data-cy="btn-icon:forward"
							>
								<ArrowForwardIcon fontSize="small" />
							</Button>
						</Tooltip>
						<Tooltip title={t('addToGroup') as string}>
							<Button
								variant="outlined"
								size="small"
								className={classes.button}
								onClick={() => handleAddToGroup()}
								disabled={toAdd.length === 0}
								data-cy="btn-icon:back"
							>
								<ArrowBackIcon fontSize="small" />
							</Button>
						</Tooltip>
					</Grid>
				)}
			</Grid>
			<Grid item xs={12} md={5}>
				{renderList(t('otherContacts'), getRemainingItems(), 'right')}
			</Grid>
		</Grid>
	);
};

import * as devicesService from '../../services/devices/devices-service';
import * as userService from '../../services/user/user-service';
import { AsyncAction } from '../types';
import {
	createDeviceSuccess,
	uploadDevicesSuccess,
	fetchDevicesTelemetrySuccess,
	fetchDeviceSuccess,
	setDeviceTableHidenFlags,
	generalDeviceRequest,
	generalDeviceError,
	setTelemetryTableHidenFlags,
	fetchDevicesTelemetrySuccessNoProgressBar,
	fetchDeviceSuccessNoProgressBar,
	fetchDeviceTypesSuccess,
	createDeviceTypeSuccess,
	editDeviceTypeSuccess,
	deleteDeviceTypeSuccess,
	editDeviceSuccess,
	editDevicePropertiesSuccess,
} from './devices-actions';

import { fetchRequest, createToastError, isStatusSuccess } from '../../services/helpers';
import {
	CreateDevicePayload,
	CreateDeviceTypePayload,
	DeviceProperties,
	DeviceResponseData2,
	DevicesHidenFlagsPayload,
	DevicesResponseData2,
	DeviceType,
	DeviceTypesResponseData,
	EditDevicePayload,
	EditDeviceProperties,
	EditDeviceTypePayload,
	TelemetryHidenFlagsPayload,
} from '../../pages/Devices/devices-types';

import { addModalDialog, addToast, removeModalDialog } from '../app/app-actions';
import { FilterOperators } from '../../components/Table/constants';
import { FiltersRequestBody2, HideFlag, TableHeadCell } from '../../components/Table/types';
import { omit } from 'lodash';
import { UUID } from '../../types';
import { ValidateImportResponse } from '../../services/devices/devices-types';

// export const fetchGroupDevices: AsyncAction<DevicesFilterPayload> = (devicesFilterPayload) => async (dispatch) => {
// 	dispatch(fetchGroupDevicesRequest());

// 	const filterBody = sanitizeEmptyValues<DevicesFilterRequestBody>({
// 		...devicesFilterPayload.config,
// 		...devicesFilterPayload.filters,
// 	});

// 	const request = devicesService.getFilteredGroupDevices(filterBody);
// 	const { data, error } = await fetchRequest<DevicesResponseData>(request);

// 	if (data) {
// 		dispatch(fetchGroupDevicesSuccess(data, devicesFilterPayload));
// 	} else {
// 		dispatch(fetchGroupDevicesError());
// 	}

// 	if (error) {
// 		const tryAgainAction = () => dispatch(fetchGroupDevices(devicesFilterPayload));

// 		dispatch(addToast(createToastError(error.data, tryAgainAction)));
// 	}
// };

// export const fetchOtherGroupsDevices: AsyncAction<DevicesFilterPayload> = (devicesFilterPayload) => async (
// 	dispatch,
// ) => {
// 	dispatch(fetchOtherGroupsDevicesRequest());

// 	const filterBody = sanitizeEmptyValues<DevicesFilterRequestBody>({
// 		...devicesFilterPayload.config,
// 		...devicesFilterPayload.filters,
// 		deviceGroupId: [
// 			{
// 				value: true,
// 				operator: FilterOperators.null,
// 			},
// 			{
// 				value: true,
// 				operator: FilterOperators.orNotNull,
// 			},
// 		],
// 	});

// 	const request = devicesService.getFilteredGroupDevices(filterBody);
// 	const { data, error } = await fetchRequest<DevicesResponseData>(request);

// 	if (data) {
// 		dispatch(fetchOtherGroupsDevicesSuccess(data, devicesFilterPayload));
// 	}

// 	if (error) {
// 		const tryAgainAction = () => dispatch(fetchGroupDevices(devicesFilterPayload));

// 		dispatch(fetchOtherGroupsDevicesError());
// 		dispatch(addToast(createToastError(error.data, tryAgainAction)));
// 	}
// };

export const createDevice: AsyncAction<CreateDevicePayload> = (createPayload) => async (dispatch, getState) => {
	dispatch(generalDeviceRequest());

	const request = devicesService.createDevice(createPayload);
	const { data, status, error } = await fetchRequest<DeviceResponseData2>(request);

	if (isStatusSuccess(status) && data) {
		const { devices } = getState();

		dispatch(createDeviceSuccess());
		dispatch(
			fetchDevicesWithPlacementAndTelemetry({
				offset: devices.offset,
				limit: devices.limit,
				orderings: devices.orderings,
				filtersAndConditions: devices.filtersAndConditions,
			}),
		);
	} else {
		dispatch(generalDeviceError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data)));
	}
};

export const editDevice: AsyncAction<EditDevicePayload> = (editPayload) => async (dispatch, getState) => {
	dispatch(generalDeviceRequest());

	const request = devicesService.editDevice(omit(editPayload, ['id']), editPayload.id);
	const { data, status, error } = await fetchRequest<DeviceResponseData2>(request);

	if (isStatusSuccess(status) && data) {
		const { devices } = getState();

		dispatch(editDeviceSuccess());
		dispatch(
			fetchDevicesWithPlacementAndTelemetry({
				offset: devices.offset,
				limit: devices.limit,
				orderings: devices.orderings,
				filtersAndConditions: devices.filtersAndConditions,
			}),
		);
	} else {
		dispatch(generalDeviceError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data)));
	}
};

export const uploadDevices: AsyncAction<ArrayBuffer> = (file) => async (dispatch, getState) => {
	dispatch(generalDeviceRequest());

	// const { devices } = getState();
	const request = devicesService.uploadDevices(file);
	const { data, status, error } = await fetchRequest<ValidateImportResponse>(request);

	if (isStatusSuccess(status) && data) {
		dispatch(uploadDevicesSuccess());
		dispatch(
			addModalDialog({
				id: 'uploadeDeviceResultModal',
				title: 'uploadeDeviceResultModalTitle',
				contentText: data.message,
				buttons: [
					{
						id: 'canceluploadeDevice',
						value: 'cancel',
						onClick: () => dispatch(removeModalDialog('uploadeDeviceResultModal')),
						variant: 'text',
					},
				],
			}),
		);
		// TODO
		// dispatch(
		// 	fetchGroupDevices({
		// 		config: devices.config,
		// 		filters: devices.filters,
		// 	}),
		// );
	} else {
		dispatch(generalDeviceError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data)));
	}
};

export const fetchDevicesWithTelemetry: AsyncAction<FiltersRequestBody2> = (devicesFilterPayload) => async (
	dispatch,
) => {
	dispatch(generalDeviceRequest());

	if (devicesFilterPayload.filtersAndConditions === undefined) {
		devicesFilterPayload.filtersAndConditions = [];
	}

	if (devicesFilterPayload.filtersAndConditions.find((filter) => filter.column === 'includeData') === undefined) {
		devicesFilterPayload.filtersAndConditions.push({
			column: 'includeData',
			operator: FilterOperators.equals,
			value: 'true',
		});
	}

	const request = devicesService.getFilteredDevices(devicesFilterPayload);
	const { data, error } = await fetchRequest<DevicesResponseData2>(request);

	if (data) {
		dispatch(fetchDevicesTelemetrySuccess(data, devicesFilterPayload));
	} else {
		dispatch(generalDeviceError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(fetchDevicesWithPlacementAndTelemetry(devicesFilterPayload));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const fetchDevicesWithPlacementAndTelemetry: AsyncAction<FiltersRequestBody2> = (devicesFilterPayload) => async (
	dispatch,
) => {
	dispatch(generalDeviceRequest());

	if (devicesFilterPayload.filtersAndConditions === undefined) {
		devicesFilterPayload.filtersAndConditions = [];
	}

	if (devicesFilterPayload.filtersAndConditions.find((filter) => filter.column === 'includeData') === undefined) {
		devicesFilterPayload.filtersAndConditions.push({
			column: 'includeData',
			operator: FilterOperators.equals,
			value: 'true',
		});
	}

	const request = devicesService.getFilteredGroupDevicesWithPlacements(devicesFilterPayload);
	const { data, error } = await fetchRequest<DevicesResponseData2>(request);

	if (data) {
		dispatch(fetchDevicesTelemetrySuccess(data, devicesFilterPayload));
	} else {
		dispatch(generalDeviceError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(fetchDevicesWithPlacementAndTelemetry(devicesFilterPayload));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const fetchDevicesTelemetryNoProgressBar: AsyncAction<void> = () => async (dispatch, getState) => {
	// dispatch(fetchDevicesTelemetryRequest());

	const { devices } = getState();

	const filterBody = {
		offset: devices.offset,
		limit: devices.limit,
		orderings: devices.orderings,
		filtersAndConditions: devices.filtersAndConditions,
	};

	if (filterBody.filtersAndConditions === undefined) {
		filterBody.filtersAndConditions = [];
	}

	if (filterBody.filtersAndConditions.find((filter) => filter.column === 'includeData') === undefined) {
		filterBody.filtersAndConditions.push({
			column: 'includeData',
			operator: FilterOperators.equals,
			value: 'true',
		});
	}

	const request = devicesService.getFilteredGroupDevicesWithPlacements(filterBody);
	const { data } = await fetchRequest<DevicesResponseData2>(request);

	if (data) {
		dispatch(fetchDevicesTelemetrySuccessNoProgressBar(data, filterBody));
	}

	// if (error) {
	// 	const tryAgainAction = () => dispatch(fetchGroupDevices(devicesFilterPayload));

	// 	dispatch(fetchDevicesTelemetryError());
	// 	dispatch(addToast(createToastError(error.data, tryAgainAction)));
	// }
};

export const fetchDevice: AsyncAction<string> = (deviceSn) => async (dispatch) => {
	dispatch(generalDeviceRequest());

	const request = devicesService.getFilteredGroupDevicesWithPlacements({
		limit: 10,
		offset: 0,
		orderings: [],
		filtersAndConditions: [
			{
				column: 'sn',
				operator: FilterOperators.equals,
				value: deviceSn,
			},
			{
				column: 'includeData',
				operator: FilterOperators.equals,
				value: 'true',
			},
			{
				column: 'includeProperties',
				operator: FilterOperators.equals,
				value: 'true',
			},
		],
	});

	const { data, error } = await fetchRequest<DevicesResponseData2>(request);

	if (data) {
		const deviceData = data.data[0];

		dispatch(fetchDeviceSuccess(deviceData));
	} else {
		dispatch(generalDeviceError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data)));
	}
};

export const fetchDeviceById: AsyncAction<string> = (deviceId) => async (dispatch) => {
	dispatch(generalDeviceRequest());

	const request = devicesService.getFilteredGroupDevicesWithPlacements({
		limit: 10,
		offset: 0,
		orderings: [],
		filtersAndConditions: [
			{
				column: 'id',
				operator: FilterOperators.equals,
				value: deviceId,
			},
			{
				column: 'includeData',
				operator: FilterOperators.equals,
				value: 'true',
			},
			{
				column: 'includeProperties',
				operator: FilterOperators.equals,
				value: 'true',
			},
		],
	});

	const { data, error } = await fetchRequest<DevicesResponseData2>(request);

	if (data) {
		const deviceData = data.data[0];

		dispatch(fetchDeviceSuccess(deviceData));
	} else {
		dispatch(generalDeviceError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data)));
	}
};

export const fetchDeviceNoProgressBar: AsyncAction<string> = (deviceSn) => async (dispatch) => {
	// dispatch(fetchDeviceRequest());

	const request = devicesService.getFilteredGroupDevicesWithPlacements({
		limit: 10,
		offset: 0,
		orderings: [],
		filtersAndConditions: [
			{
				column: 'sn',
				operator: FilterOperators.equals,
				value: deviceSn,
			},
			{
				column: 'includeData',
				operator: FilterOperators.equals,
				value: 'true',
			},
		],
	});

	const { data, error } = await fetchRequest<DevicesResponseData2>(request);

	if (data) {
		const deviceData = data.data[0];

		dispatch(fetchDeviceSuccessNoProgressBar(deviceData));
	} else {
		// dispatch(fetchDeviceError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data)));
	}
};

export const fetchDeviceTableHidenColumns: AsyncAction<string> = (key) => async (dispatch) => {
	dispatch(generalDeviceRequest());

	const request = userService.fetchDeviceTableHidenColumns(key);

	const { data, error } = await fetchRequest<HideFlag[]>(request);

	if (data) {
		dispatch(setDeviceTableHidenFlags(data));
	} else {
		dispatch(generalDeviceError());
	}

	if (error) {
		// TODO uncomment the line below when server returns propper error
		// dispatch(addToast(createToastError(error.data)));
	}
};

export const setDeviceTableHidenColumns: AsyncAction<DevicesHidenFlagsPayload> = (payload) => async (dispatch) => {
	dispatch(generalDeviceRequest());

	const request = userService.setDeviceTableHidenColumns(payload.key, payload.body);

	const { status, error } = await fetchRequest<HideFlag[]>(request);

	if (status === 200 || status === 201 || status === 204) {
		dispatch(setDeviceTableHidenFlags(payload.body));
	} else {
		dispatch(generalDeviceError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data)));
	}
};

export const fetchTelemetryTableHidenColumns: AsyncAction<string> = (key) => async (dispatch) => {
	dispatch(generalDeviceRequest());

	const request = userService.fetchTelemetryTableHidenColumns(key);

	const { data, error } = await fetchRequest<TableHeadCell[]>(request);

	if (data) {
		dispatch(setTelemetryTableHidenFlags(data));
	} else {
		dispatch(generalDeviceError());
	}

	if (error) {
		// TODO uncomment the line below when server returns propper error
		// dispatch(addToast(createToastError(error.data)));
	}
};

export const setTelemetryTableHidenColumns: AsyncAction<TelemetryHidenFlagsPayload> = (payload) => async (dispatch) => {
	dispatch(generalDeviceRequest());

	const request = userService.setTelemetryTableHidenColumns(payload.key, payload.body);

	const { status, error } = await fetchRequest<TableHeadCell[]>(request);

	if (status === 200 || status === 201 || status === 204) {
		dispatch(setTelemetryTableHidenFlags(payload.body));
	} else {
		dispatch(generalDeviceError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data)));
	}
};

export const fetchDeviceTypes: AsyncAction<FiltersRequestBody2> = (deviceTypesPayload) => async (dispatch) => {
	dispatch(generalDeviceRequest());

	const request = devicesService.fetchDeviceTypes(deviceTypesPayload);
	const { data, error } = await fetchRequest<DeviceTypesResponseData>(request);

	if (data) {
		dispatch(fetchDeviceTypesSuccess(data, deviceTypesPayload));
	} else {
		dispatch(generalDeviceError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(fetchDeviceTypes(deviceTypesPayload));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const createDeviceType: AsyncAction<CreateDeviceTypePayload> = (deviceType) => async (dispatch, getState) => {
	dispatch(generalDeviceRequest());

	const { devices } = getState();
	const request = devicesService.createDeviceType(deviceType);
	const { data, error } = await fetchRequest<DeviceType>(request);

	if (data) {
		dispatch(createDeviceTypeSuccess());
		dispatch(
			fetchDeviceTypes({
				limit: devices.deviceTypes.limit,
				offset: devices.deviceTypes.offset,
				orderings: devices.deviceTypes.orderings,
				filtersAndConditions: devices.deviceTypes.filtersAndConditions,
			}),
		);
	} else {
		dispatch(generalDeviceError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(createDeviceType(deviceType));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const editDeviceType: AsyncAction<EditDeviceTypePayload> = (deviceType) => async (dispatch) => {
	dispatch(generalDeviceRequest());

	const body = omit(deviceType, ['id']);
	const request = devicesService.editDeviceType(body, deviceType.id);
	const { data, error } = await fetchRequest<DeviceType>(request);

	if (data) {
		dispatch(editDeviceTypeSuccess(data));
	} else {
		dispatch(generalDeviceError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(editDeviceType(deviceType));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const deleteDeviceType: AsyncAction<UUID> = (deviceTypeId) => async (dispatch) => {
	dispatch(generalDeviceRequest());

	const request = devicesService.deleteDeviceType(deviceTypeId);
	const { status, error } = await fetchRequest<void>(request);

	if (isStatusSuccess(status) && deviceTypeId) {
		dispatch(deleteDeviceTypeSuccess(deviceTypeId));
	} else {
		dispatch(generalDeviceError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(deleteDeviceType(deviceTypeId));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const editDeviceProperties: AsyncAction<EditDeviceProperties> = (editPayload) => async (dispatch) => {
	dispatch(generalDeviceRequest());

	const request = devicesService.editDeviceProperties(editPayload.deviceProperty, editPayload.deviceId);
	const { data, status, error } = await fetchRequest<DeviceProperties>(request);

	if (isStatusSuccess(status) && data) {
		dispatch(editDevicePropertiesSuccess());
		dispatch(fetchDeviceById(editPayload.deviceId));
	} else {
		dispatch(generalDeviceError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data)));
	}
};

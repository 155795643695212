import { PermissionsDotNet } from './generalConstants';

export function hasPermission(roleAndPermission: PermissionsDotNet, permissions?: string): boolean {
	if (permissions === undefined) {
		return false;
	}

	const permissionIndex = roleAndPermission.valueOf();

	if (permissionIndex >= permissions.length) {
		return false;
	}

	return permissions[permissionIndex] === '1';
}

import { Grid } from '@material-ui/core';
import React, { ChangeEvent, useState } from 'react';
import { FilterOperators } from '../constants';
import { TableFilter } from '../TableFilter';
import { TableSearchFilter } from '../TableSearchFilter';
import { TableHeadCell } from '../types';

type Props = {
	tableHead: TableHeadCell;
	onChange: (tableHead: TableHeadCell) => void;
};

export const StringFilter = ({ tableHead, onChange }: Props) => {
	const filter = tableHead.filters && tableHead.filters.length > 0 ? tableHead.filters[0] : undefined;

	const [value1, setValue] = useState(filter?.value);

	const handleSaveFilters = () => {
		if (value1 === undefined || value1 === '') {
			tableHead.filters = undefined;
		} else {
			tableHead.filters = [
				{
					column: tableHead.id,
					operator: FilterOperators.contains,
					value: value1,
				},
			];
		}

		onChange(tableHead);
	};

	const handleResetFilters = () => {
		tableHead.filters = undefined;

		onChange(tableHead);
	};

	const updateFilter = (value: string) => {
		setValue(value);
	};

	const inactive = tableHead.filters === undefined;

	return (
		<TableFilter onSave={handleSaveFilters} onReset={handleResetFilters} active={!inactive} name={tableHead.id}>
			<Grid container spacing={1} alignItems="center">
				<Grid item sm={12}>
					<TableSearchFilter
						name={tableHead.id}
						label={tableHead.label}
						value={value1 ? value1 : ''}
						onChange={(event: ChangeEvent<HTMLInputElement>) => updateFilter(event.target.value)}
					/>
				</Grid>
			</Grid>
		</TableFilter>
	);
};

import React from 'react';
import * as yup from 'yup';
import isEmail from 'validator/lib/isEmail';
import { useDispatch } from 'react-redux';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { logIn } from '../../store/user/user-async-actions';
import { LoginRequestPayload } from '../../services/user/user-types';
import { LoginContainer } from './components/LoginContainer';
import { InputField } from '../../components/Form/InputField';
import { Form } from '../../components/Form/Form';
import { Button } from '../../components/Button/Button';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers';

const useStyles = makeStyles((theme: Theme) => ({
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

type FormData = LoginRequestPayload;

export const Login = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.required(t('validationErrors.emptyEmail'))
			.test('invalidEmail', t('validationErrors.invalidEmail'), function (value) {
				if (typeof value === 'string') {
					return isEmail(value);
				}

				return false;
			}),
		password: yup.string().required(t('validationErrors.passwordEmpty')),
	});

	const { control, handleSubmit, errors } = useForm<FormData>({
		mode: 'onBlur',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			email: '',
			password: '',
		},
	});

	const onSubmit = (data: FormData) => {
		dispatch(logIn(data));
	};

	return (
		<LoginContainer>
			<Typography component="h1" variant="h2">
				{t('signIn')}
			</Typography>
			<Form onSubmit={handleSubmit(onSubmit)} testId="loginForm">
				<InputField
					autoComplete="email"
					error={Boolean(errors.email)}
					helperText={errors.email?.message}
					label={t('email')}
					name="email"
					control={control}
				/>
				<InputField
					autoComplete="password"
					error={Boolean(errors.password)}
					helperText={errors.password?.message}
					label={t('password')}
					name="password"
					control={control}
					type="password"
				/>
				<Button fullWidth className={classes.submit} type="submit">
					{t('signIn')}
				</Button>
				<Link href="#" variant="body2">
					{t('forgotPassword')}
				</Link>
			</Form>
		</LoginContainer>
	);
};

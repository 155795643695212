import { action } from 'typesafe-actions';
import {
	PIPELINES_COMMON_EMPTY_REQUEST,
	PIPELINES_COMMON_EMPTY_ERROR,
	FETCH_PIPELINES_SUCCESS,
	CREATE_PIPELINE_SUCCESS,
	EDIT_PIPELINE_SUCCESS,
	DELETE_PIPELINE_SUCCESS,
	FETCH_JOBS_SUCCESS,
} from './pipelines-constants';

import { UUID } from '../../types';
import { FiltersRequestBody2 } from '../../components/Table/types';
import { Pipeline, PipelinesResponseData } from '../../pages/Pipelines/pipelines-types';
import { JobsResponseData } from '../../pages/Jobs/jobs-types';

export const pipelinesCommonEmptyRequest = () => action(PIPELINES_COMMON_EMPTY_REQUEST);
export const pipelinesCommonEmptyError = () => action(PIPELINES_COMMON_EMPTY_ERROR);

export const fetchPipelinesSuccess = (data?: PipelinesResponseData, options?: FiltersRequestBody2) =>
	action(FETCH_PIPELINES_SUCCESS, {
		data,
		options,
	});

export const createPipelineSuccess = (data: Pipeline) =>
	action(CREATE_PIPELINE_SUCCESS, {
		data,
	});

export const editPipelineSuccess = (data: Pipeline) =>
	action(EDIT_PIPELINE_SUCCESS, {
		data,
	});

export const deletePipelineSuccess = (id: UUID) =>
	action(DELETE_PIPELINE_SUCCESS, {
		id,
	});

export const fetchJobsSuccess = (data: JobsResponseData, options?: FiltersRequestBody2) =>
	action(FETCH_JOBS_SUCCESS, { data, options });

// export const createPipelineRuleSuccess = () => action(CREATE_PIPELINE_RULE_SUCCESS);

// export const updatePipelineRuleSuccess = (data: PipelineRule) => action(UPDATE_PIPELINE_RULE_SUCCESS, { data });

// export const deletePipelineRuleSuccess = (pipelineRuleId: UUID) =>
// 	action(DELETE_PIPELINE_RULE_SUCCESS, { pipelineRuleId });

// export const getPipelineRuleDetail = (data: PipelineRule) => action(GET_PIPELINE_RULE_DETAIL, { data });

import React, { useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { DashboardCard } from './DashboardCard';
import AccountTreeRoundedIcon from '@material-ui/icons/AccountTreeRounded';
import PeopleIcon from '@material-ui/icons/People';
import GroupIcon from '@material-ui/icons/GroupWork';
import DevicesIcon from '@material-ui/icons/Devices';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/types';
import { ProjectsState } from '../../../store/projects/projects-types';
import { fetchProjectInfo } from '../../../store/projects/projects-async-actions';
import { useTranslation } from 'react-i18next';
import { RouteEnum } from '../../../router/Routes';

type CardsProps = {
	projectId: string;
};

export const Cards = ({ projectId }: CardsProps) => {
	const dispatch = useDispatch();
	const { projectInfo } = useSelector<RootState, ProjectsState>((state) => state.projects);
	const useStyles = makeStyles({
		cardWrap: {
			display: 'flex',
			justifyContent: 'space-between',
		},
		icon: {
			fontSize: '3em',
			alignSelf: 'center',
		},
	});

	useEffect(() => {
		projectId && dispatch(fetchProjectInfo(projectId));
	}, [dispatch, projectId]);

	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Grid container spacing={3} className={classes.cardWrap}>
			<Grid item xs={12} sm={6} lg={3}>
				<DashboardCard
					id="workflowsCount"
					label={t('workflowsCount')}
					count={projectInfo.workflowsCount}
					icon={<AccountTreeRoundedIcon className={classes.icon} />}
					href={RouteEnum.WORKFLOWS}
				/>
			</Grid>
			<Grid item xs={12} sm={6} lg={3}>
				<DashboardCard
					id="groupsCount"
					label={t('groupsCount')}
					count={projectInfo.groupsCount}
					icon={<GroupIcon className={classes.icon} />}
					href={RouteEnum.GROUPS}
				/>
			</Grid>
			<Grid item xs={12} sm={6} lg={3}>
				<DashboardCard
					id="usersCount"
					label={t('usersCount')}
					count={projectInfo.usersCount}
					icon={<PeopleIcon className={classes.icon} />}
					href={RouteEnum.USER_MANAGEMENT}
				/>
			</Grid>
			<Grid item xs={12} sm={6} lg={3}>
				<DashboardCard
					id="devicesCount"
					label={t('devicesCount')}
					count={projectInfo.devicesCount}
					icon={<DevicesIcon className={classes.icon} />}
					href={RouteEnum.DEVICES}
				/>
			</Grid>
		</Grid>
	);
};

import { action } from 'typesafe-actions';
import {
	CONTACTS_COMMON_EMPTY_REQUEST,
	CONTACTS_COMMON_EMPTY_ERROR,
	FETCH_CONTACTS_SUCCESS,
	CREATE_CONTACT_SUCCESS,
	EDIT_CONTACT_SUCCESS,
	DELETE_CONTACT_SUCCESS,
} from './contacts-constants';

import { UUID } from '../../types';
import { FiltersRequestBody2 } from '../../components/Table/types';
import { Contact, ContactsResponseData } from '../../pages/Contacts/contacts-types';

export const contactsCommonEmptyRequest = () => action(CONTACTS_COMMON_EMPTY_REQUEST);
export const contactsCommonEmptyError = () => action(CONTACTS_COMMON_EMPTY_ERROR);

export const fetchContactsSuccess = (data?: ContactsResponseData, options?: FiltersRequestBody2) =>
	action(FETCH_CONTACTS_SUCCESS, {
		data,
		options,
	});

export const createContactSuccess = (data: Contact) =>
	action(CREATE_CONTACT_SUCCESS, {
		data,
	});

export const editContactSuccess = (data: Contact) =>
	action(EDIT_CONTACT_SUCCESS, {
		data,
	});

export const deleteContactSuccess = (id: UUID) =>
	action(DELETE_CONTACT_SUCCESS, {
		id,
	});

import React, { useEffect } from 'react';
import {
	Card,
	CardHeader,
	Checkbox,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	Theme,
	makeStyles,
	ListItemText,
	Grid,
	Typography,
	TablePagination,
	useMediaQuery,
	Tooltip,
} from '@material-ui/core';

import { Button } from '../../../components/Button/Button';
import { useTranslation } from 'react-i18next';
import { Device, DevicesResponseData2 } from '../../Devices/devices-types';
import { GroupResponseData, UpdateGroupResponseData } from '../../Groups/groups-types';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useSelector } from 'react-redux';
import { BREAKPOINTS, PermissionsDotNet } from '../../../helpers/generalConstants';
import { FiltersRequestBody2, TableColumnFilter } from '../../../components/Table/types';
import { getFilteredDevices } from '../../../services/devices/devices-service';
import { fetchRequest } from '../../../services/helpers';
import { FilterOperators } from '../../../components/Table/constants';
import { updateGroup } from '../../../services/groups/groups-service';
import { RootState } from '../../../store/types';
import { UserState } from '../../../store/user/user-types';
import { hasPermission } from '../../../helpers/auth';

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
	card: {
		height: '100%',
	},
	list: {
		width: '100%',
		maxHeight: 400,
		backgroundColor: palette.background.paper,
		overflow: 'auto',
	},
	button: {
		margin: spacing(0.5, 0),
	},
	buttons: {
		alignSelf: 'center',
	},
	label: {
		'& + &': {
			marginLeft: spacing(1),
		},
	},
}));

type Props = {
	currentGroup: GroupResponseData;
};

export const GroupsTransferList = ({ currentGroup }: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { permissions } = useSelector<RootState, UserState>((state) => state.user);
	const matchesMd = useMediaQuery(BREAKPOINTS.md);

	const defaultValue = { devices: [], total: 0 };

	const [currentGroupItems, setCurrentGroupItems] = React.useState<{
		devices: Device[];
		total: number;
	}>(defaultValue);

	const [currentGroupFilters] = React.useState<TableColumnFilter[]>([]);
	const [currentGroupOffset, setCurrentGroupOffset] = React.useState(0);
	const [currentGroupLimit, setCurrentGroupLimit] = React.useState(10);

	const [othersGroupItems, setOthersGroupItems] = React.useState<{
		devices: Device[];
		total: number;
	}>(defaultValue);

	const [othersGroupFilters] = React.useState<TableColumnFilter[]>([]);
	const [othersGroupOffset, setOthersGroupOffset] = React.useState(0);
	const [othersGroupLimit, setOthersGroupLimit] = React.useState(10);

	const [update, setUpdate] = React.useState({});

	const [selected, setSelected] = React.useState<Device[]>([]);

	const canEditDeviceGroups = hasPermission(PermissionsDotNet.DeviceGroupWrite, permissions);

	const intersection = (devicesA: Device[], devicesB: Device[]) =>
		devicesA.filter(({ id }) => devicesB.findIndex((device) => device.id === id) !== -1);

	const numberOfSelected = (items: Device[]) => intersection(selected, items).length;
	const toRemove = intersection(selected, currentGroupItems.devices);
	const toAdd = intersection(selected, othersGroupItems.devices);

	const handleToggleAll = (items: Device[]) => {
		if (numberOfSelected(items) === 0) {
			setSelected(items);

			return;
		}

		setSelected([]);
	};

	useEffect(() => {
		fetchCurrentDevices({
			limit: currentGroupLimit,
			offset: currentGroupOffset,
			orderings: [],
			filtersAndConditions: [{ column: 'deviceGroupId', operator: FilterOperators.in, value: currentGroup.id }], // currentGroupFilters,
		});
	}, [currentGroup.id, currentGroupFilters, currentGroupLimit, currentGroupOffset, update]);

	useEffect(() => {
		fetchOthersDevices({
			limit: othersGroupLimit,
			offset: othersGroupOffset,
			orderings: [],
			filtersAndConditions: [
				{ column: 'deviceGroupId', operator: FilterOperators.notIn, value: currentGroup.id },
			], // othersGroupFilters,
		});
	}, [currentGroup.id, othersGroupFilters, othersGroupLimit, othersGroupOffset, update]);

	function fetchCurrentDevices(filterBody: FiltersRequestBody2) {
		const request = getFilteredDevices(filterBody);

		(async () => {
			const { data, error } = await fetchRequest<DevicesResponseData2>(request);

			if (error) {
				// TODO show error
			} else {
				setCurrentGroupItems({
					devices: data?.data ?? [],
					total: data?.total ?? 0,
				});
			}
		})();
	}

	function fetchOthersDevices(filterBody: FiltersRequestBody2) {
		const request = getFilteredDevices(filterBody);

		(async () => {
			const { data, error } = await fetchRequest<DevicesResponseData2>(request);

			if (error) {
				// TODO show error
			} else {
				setOthersGroupItems({
					devices: data?.data ?? [],
					total: data?.total ?? 0,
				});
			}
		})();
	}

	const handleToggle = (item: Device) => {
		if (!canEditDeviceGroups) {
			return;
		}

		const isSelected = Boolean(selected.find(({ id }) => id === item.id));

		if (isSelected) {
			setSelected(selected.filter(({ id }) => id !== item.id));

			return;
		}

		setSelected([...selected, item]);
	};

	const handleAddToGroup = (items: Device[]) => {
		if (currentGroup) {
			const devicesToUpdate = items.map(({ id }) => id);

			const request = updateGroup(
				{
					name: currentGroup.name,
					active: currentGroup.active,
					devicesToUpdate,
					devicesToRemove: [],
				},
				currentGroup.id,
			);

			(async () => {
				const { error } = await fetchRequest<UpdateGroupResponseData>(request);

				if (error) {
					// TODO show error
				} else {
					setUpdate({});

					setSelected([]);
				}
			})();
		}
	};

	const handleRemoveFromGroup = (items: Device[]) => {
		const devicesToRemove = items.map(({ id }) => id);

		const request = updateGroup(
			{
				name: currentGroup.name,
				active: currentGroup.active,
				devicesToRemove,
				devicesToUpdate: [],
			},
			currentGroup.id,
		);

		(async () => {
			const { error } = await fetchRequest<UpdateGroupResponseData>(request);

			if (error) {
				// TODO show error
			} else {
				setUpdate({});

				setSelected([]);
			}
		})();
	};

	const handleCurrentChangePage = (nextPage: number) => {
		setCurrentGroupOffset(nextPage * currentGroupLimit);
	};

	const handleOthersChangePage = (nextPage: number) => {
		setOthersGroupOffset(nextPage * othersGroupLimit);
	};

	const handleCurrentChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCurrentGroupOffset(0);
		setCurrentGroupLimit(Number(event.target.value));
	};

	const handleOthersChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setOthersGroupOffset(0);
		setOthersGroupLimit(Number(event.target.value));
	};

	// const renderGroups = (groups: DeviceGroup[]) =>
	// 	groups.map((group) => {
	// 		return (
	// 			<Label className={classes.label} key={group.name}>
	// 				{group.name}
	// 			</Label>
	// 		);
	// 	});

	const renderList = (title: string, items: Device[], side: 'left' | 'right') => {
		const numOfSelected = numberOfSelected(items);

		return (
			<Card className={classes.card}>
				<CardHeader
					action={
						side === 'left' ? (
							<></>
						) : (
							// <CurrentGroupFilters
							// 	config={currentGroupConfig}
							// 	filters={currentGroupFilters as CurrentGroupDevicesFilter}
							// />
							<></>
						)
					}
					avatar={
						<Checkbox
							disabled={!canEditDeviceGroups || items.length === 0}
							indeterminate={numOfSelected !== items.length && numOfSelected !== 0}
							inputProps={{ 'aria-label': 'all items selected' }}
							checked={numOfSelected === items.length && items.length !== 0}
							color="primary"
							name={`${side}Groups`}
							onClick={() => handleToggleAll(items)}
						/>
					}
					title={title}
					subheader={`${numOfSelected}/${items.length} selected`}
				/>
				<Divider />
				<List className={classes.list} dense component="div" role="list" data-cy={`div:${side}ItemList`}>
					{items.map((value: Device) => {
						return (
							<ListItem
								key={value.id}
								role="listitem"
								button
								onClick={() => handleToggle(value)}
								disableRipple={!canEditDeviceGroups}
							>
								<ListItemIcon>
									<Checkbox
										disabled={!canEditDeviceGroups}
										checked={Boolean(selected.find((item: Device) => item.id === value.id))}
										color="primary"
										inputProps={{ 'aria-labelledby': value.sn }}
										name={`sn-${value.sn}`}
										tabIndex={-1}
									/>
								</ListItemIcon>

								<ListItemText
									id={value.sn}
									primary={
										<>
											<Typography component="div" variant="body1">
												{value.deviceType?.name}
											</Typography>
											<Typography component="div" variant="body2" gutterBottom>
												{t('sn')}: {value.sn}
											</Typography>
										</>
									}
									// secondary={renderGroups(value.deviceGroups)}
								/>
							</ListItem>
						);
					})}
					<ListItem />
				</List>
			</Card>
		);
	};

	return (
		<Grid container spacing={2} justify="center" direction={matchesMd ? 'row' : 'column'}>
			<Grid item xs={12} md={5}>
				{renderList(currentGroup?.name, currentGroupItems.devices, 'left')}
				<TablePagination
					rowsPerPageOptions={[5, 10, 25, 100]}
					component="div"
					count={currentGroupItems.total}
					rowsPerPage={currentGroupLimit}
					page={currentGroupOffset / currentGroupLimit}
					onPageChange={(_: unknown, nextPage: number) => handleCurrentChangePage(nextPage)}
					onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						handleCurrentChangeRowsPerPage(event)
					}
				/>
			</Grid>
			<Grid className={classes.buttons} item md={2} lg="auto">
				{canEditDeviceGroups && (
					<Grid container direction="column" alignItems="center">
						<Tooltip title={t('removeFromGroup') as string}>
							<Button
								variant="outlined"
								size="small"
								className={classes.button}
								onClick={() => handleRemoveFromGroup(selected)}
								disabled={toRemove.length === 0}
								data-cy="btn-icon:forward"
							>
								<ArrowForwardIcon fontSize="small" />
							</Button>
						</Tooltip>
						<Tooltip title={t('addToGroup') as string}>
							<Button
								variant="outlined"
								size="small"
								className={classes.button}
								onClick={() => handleAddToGroup(selected)}
								disabled={toAdd.length === 0}
								data-cy="btn-icon:back"
							>
								<ArrowBackIcon fontSize="small" />
							</Button>
						</Tooltip>
					</Grid>
				)}
			</Grid>
			<Grid item xs={12} md={5}>
				{renderList(t('otherGroups'), othersGroupItems.devices, 'right')}
				<TablePagination
					rowsPerPageOptions={[5, 10, 25, 100]}
					component="div"
					count={othersGroupItems.total}
					rowsPerPage={othersGroupLimit}
					page={othersGroupOffset / othersGroupLimit}
					onPageChange={(_: unknown, nextPage: number) => handleOthersChangePage(nextPage)}
					onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						handleOthersChangeRowsPerPage(event)
					}
				/>
			</Grid>
		</Grid>
	);
};

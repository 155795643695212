import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { FilterOperators, FILTER_ACTIVE_OPTIONS } from '../constants';
import { TableFilter } from '../TableFilter';
import { TableSelectFilter } from '../TableSelectFilter';
import { Filter, TableHeadCell } from '../types';

type Props = {
	tableHead: TableHeadCell;
	onChange: (tableHead: TableHeadCell) => void;
};

export const ActiveFilter = ({ tableHead, onChange }: Props) => {
	const [value1, setValue] = useState<Filter>(FILTER_ACTIVE_OPTIONS[0].filter);

	const handleSaveFilters = () => {
		if (value1 === undefined || value1.value === null) {
			tableHead.filters = undefined;
		} else {
			tableHead.filters = [
				{
					column: tableHead.id,
					operator: FilterOperators.equals,
					value: value1.value as boolean,
				},
			];
		}

		onChange(tableHead);
	};

	const handleResetFilters = () => {
		tableHead.filters = undefined;

		onChange(tableHead);
	};

	const updateFilter = (filter: Filter) => {
		filter && setValue(filter);
	};

	const inactive = true; // TODO tableHead.filter?.value === undefined || tableHead.filter?.value === '';

	return (
		<TableFilter onSave={handleSaveFilters} onReset={handleResetFilters} active={!inactive} name={tableHead.id}>
			<Grid container spacing={1} alignItems="center">
				<Grid item sm={12}>
					<TableSelectFilter
						name={tableHead.id}
						label={tableHead.label}
						options={FILTER_ACTIVE_OPTIONS}
						onChange={updateFilter}
						currentFilter={value1}
					/>
				</Grid>
			</Grid>
		</TableFilter>
	);
};

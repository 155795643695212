import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format, isValid } from 'date-fns';
import { TableRow, TableCell, CardHeader, Divider } from '@material-ui/core';
import { Table } from '../../../components/Table/Table';
import { TableColumnFilter, TableColumnSort, TableRowRenderProps } from '../../../components/Table/types';
import { TableRowActionIcon } from '../../../components/Table/constants';
import { TableActionsContainer } from '../../../components/Table/TableActionsContainer';
import { INPUT_DATE_TIME_MASK } from '../../../helpers/generalConstants';
import { RouteEnum } from '../../../router/Routes';
import { NotificationResponseData } from '../../Reports/reports-types';
import { Label } from '../../../components/Label/Label';
import { UUID } from '../../../types';

type Props = {
	data: NotificationResponseData[];
	total: number;
	limit: number;
	offset: number;
	orderings: TableColumnSort[];
	filters: TableColumnFilter[];
	workflowId: UUID;
};

type TableRowData = Pick<NotificationResponseData, 'id' | 'sn' | 'time' | 'severity' | 'message'>;

export const NotificationsTable = ({ data, total, limit, offset, orderings, filters, workflowId }: Props) => {
	const { t } = useTranslation();
	const history = useHistory();

	const rowActions = [
		{
			id: 'btn-detail:device',
			icon: TableRowActionIcon.detail,
			tooltip: t('deviceDetail'),
			onClick: (notification: TableRowData) => history.push(`${RouteEnum.DEVICES}/${notification.sn}`),
		},
	];

	const columns = [
		{ id: 'sn', label: t('sn') },
		{ id: 'time', label: t('time') },
		{ id: 'severity', label: t('notificationType') },
		{ id: 'message', label: t('message') },
	];

	const tableData: TableRowData[] = data.map(({ id, sn, time, severity, message }: NotificationResponseData) => ({
		id,
		sn,
		time,
		severity,
		message,
	}));

	const renderTableRow = ({ onClickRow, row: notification, renderActions }: TableRowRenderProps<TableRowData>) => (
		<TableRow key={notification.id} id={`row-${notification.sn}`} hover onClick={() => onClickRow(notification.id)}>
			<TableCell component="td" id={notification.sn} scope="row">
				{notification.sn}
			</TableCell>
			<TableCell component="td" id={notification.time} scope="row">
				{isValid(new Date(notification.time)) && format(new Date(notification.time), INPUT_DATE_TIME_MASK)}
			</TableCell>
			<TableCell component="td" id={notification.severity} scope="row">
				<Label type={notification.severity}>{t(notification.severity) as string}</Label>
			</TableCell>
			<TableCell component="td" id={notification.message} scope="row">
				{notification.message}
			</TableCell>
			<TableCell padding="none" sortDirection={false} align="right">
				<TableActionsContainer>{renderActions(rowActions, notification)}</TableActionsContainer>
			</TableCell>
		</TableRow>
	);

	return (
		<>
			<CardHeader title={t('notifications')} />
			<Divider light />
			<Table // TODO
				actions={rowActions}
				columns={columns}
				data={tableData}
				dataLimit={limit}
				dataOffset={offset}
				numberOfRows={total}
				//fetchAction={fetchNotifications}
				selectable={false}
				renderTableRow={renderTableRow}
			/>
		</>
	);
};

import { Grid, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TabPanel } from '../../components/Tabs/TabPanel';
import { RootState } from '../../store/types';
import { DevicePlacementsTable } from './components/DevicePlacementsTable';
import { FindInPageRounded } from '@material-ui/icons';
import { getSelectedPlacementDefinitionTab } from '../../store/device-placements/device-placements-actions';
import { Definition, DefinitionResponseData } from './device-placements-types';
import { fetchDefinitions } from '../../services/device-placements/device-placements-service';
import { fetchRequest } from '../../services/helpers';
import { FilterOperators } from '../../components/Table/constants';
import { UserState } from '../../store/user/user-types';

export const ProjectBased = true;

export const DevicePlacementManagement = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { project } = useSelector<RootState, UserState>((state) => state.user);
	const [definitions, setDefinitions] = useState<Definition[]>([]);

	const tabIndex = useSelector<RootState, number>((state) => state.devicePlacements.selectedTab);

	useEffect(() => {
		const filters = [];

		if (project) {
			filters.push({
				column: 'projects',
				operator: FilterOperators.injoin,
				value: project.id,
			});
		}

		const request = fetchDefinitions({
			// Should be enough to get all definitions
			limit: 100,
			offset: 0,
			orderings: [],
			filtersAndConditions: filters,
		});

		(async () => {
			const { data, error } = await fetchRequest<DefinitionResponseData>(request);

			if (error) {
				console.log(error);
				// TODO show error
			} else {
				if (data) {
					setDefinitions(data.data);
				}
			}
		})();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, project]);

	const handleChangeTab = (_: React.ChangeEvent<{}>, newTabIndex: number) => {
		dispatch(getSelectedPlacementDefinitionTab(newTabIndex));
	};

	return (
		<Grid container spacing={2} justify="center">
			<Grid item xs={12} lg={10}>
				<Tabs value={tabIndex} onChange={handleChangeTab} indicatorColor="primary" textColor="primary">
					{definitions.map((definition, index) => (
						<Tab value={index} key={definition.id} label={t(definition.name)} />
					))}
				</Tabs>

				{definitions.length > 0 ? (
					<TabPanel value={tabIndex} index={tabIndex}>
						<Paper>
							<DevicePlacementsTable definition={definitions[tabIndex]} />
						</Paper>
					</TabPanel>
				) : (
					<Grid container justify="center">
						<Grid item>
							<Grid container justify="center">
								<FindInPageRounded fontSize="large" />
							</Grid>
							<Typography variant="subtitle1" gutterBottom>
								{t('noData')}
							</Typography>
						</Grid>
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};

import { globalTranslations } from './global';
import { ErrorCodes } from '../services/common-types';

export const cz = {
	translation: {
		...globalTranslations,
		error: 'Chyba',
		[ErrorCodes['SYS-001']]: 'Chyba serveru, zkuste to znovu později.',
		validationErrors: {
			required: 'Povinné pole.',
			emptyEmail: 'Vyplňte prosím svou e-mailovou adresu.',
			invalidEmail: 'Neplatná emailová adresa.',
			passwordEmpty: 'Vyplňte prosím své heslo.',
			invalidPassword: 'Heslo musí obsahovat alespoň 1 písmeno, 1 číslo a musí mít minimálně 8 znaků bez mezer.',
			newPasswordMatchesOldPassword: 'Nové heslo se musí lišit od starého hesla.',
			min0: 'Vyberte alespoň {{count}} položku.',
			min1: 'Vyberte alespoň {{count}} položky.',
			min2: 'Vyberte alespoň {{count}} položek.',
			number: 'Vyplňte prosím číslo.',
			polygon: 'Polygon potřebuje alespoň 3 souřadnice.',
			invalidDateMessage: 'Neplatný formát data. Měl by být {{format}}.',
			maxDateMessage: 'Dátum by neměl být po: {{date}}.',
			minDateMessage: 'Dátum by neměl být před: {{date}}.',
			bodyParser: 'Parser musí začínat příkazem bodyParser.',
			noOptionalChainingAndArrow: 'Volitelné řetězení (?.) A funkce šipek (=>) nejsou povoleny.',
			bracketMissing: 'Chybějící závorka.',
			missingFields: 'Musíte vyplnit všechna pole.',
			invalidPathMissingSlash: 'Url endpointu musí začínat lomítkem "/", např. /endpoint.',
		},
		selectedProject: 'Stávající projekt',
		dashboard: 'Nástěnky a přehledy (Translation service out)',
		maps: 'Mapy',
		devices: 'Zařízení',
		zones: 'Zóny',
		workflows: 'Postupy/ Toky', // nedokonalý preklad
		billing: 'Fakturace',
		users: 'Uživatelé',
		ingestionEndpoints: 'Vstupní uzly dat',
		devicesStatus: 'Stav zařízení',
		noData: 'Nenalezena žádná data.',
		tryAgain: 'Zkus to znovu',
		actions: 'Akce',
		edit: 'Upravit',
		cancel: 'Zrušit',
		save: 'Uložit',
		id: 'ID',
		firstName: 'Jméno',
		lastName: 'Příjmení',
		active: 'Aktivní',
		inactive: 'Neaktivní',
		lastLogin: 'Poslední přihlášení',
		createUser: 'Vytvořit uživatele',
		tenantId: 'Vlastník ID',
		tenant: 'Vlastník',
		superAdmin: 'Super admin',
		createUserModalTitle: 'Vytvořit uživatele',
		createUserModalDescription:
			'Pomocí tohoto formuláře můžete vytvořit nového uživatele. Všechna pole jsou povinná.',
		editUserFormTitle: 'Upravit údaje o uživateli',
		editProjectsFormTitle: 'Úpravy projektů a rolí',
		deleteUserModalTitle: 'Odstranit uživatele',
		deleteUserModalDescription: 'Opravdu chcete smazat uživatele {{firstName}} {{lastName}}?',
		delete: 'Vymazat',
		settings: 'Nastavení',
		logout: 'Odhlásit se',
		signIn: 'Přihlásit se',
		email: 'Email',
		password: 'Heslo',
		forgotPassword: 'Zapomenuté heslo?',
		newPassword: 'Nové heslo',
		setPassword: 'Nastavit heslo',
		changePassword: 'Změnit heslo',
		ingestionEndpointPath: 'URL pro vstupní uzly dat',
		ingestionEndpointPayloadParser: 'Payload parser', // nepreložené
		createIngestionEndpoint: 'Vytvořit ingestion endpoint', // čiastočný preklad
		createIngestionEndpointModalTitle: 'Vytvořit ingestion endpoint název', // čiastočný preklad
		createIngestionEndpointModalDescription: 'Můžete vytvořit nový ingestion endpoint pomocí tohoto formuláře.', // čiastočný preklad
		deleteIngestionEndpointModalTitle: 'Vymazat ingestion endpoint', // čiastočný preklad
		deleteIngestionEndpointModalDescription: 'Jste si jistí, že chcete smazat ingestion endpoint {{endpoint}}?', // čiastočný preklad
		editIngestionEndpointModalTitle: 'Upravit ingestion endpoint', // čiastočný preklad
		editIngestionEndpointModalDescription: 'Můžete upravit ingestion endpoint pomocí tohoto formuláře.', // čiastočný preklad
		selectUserRoles: 'Vyberte uživatelské role',
		noOptions: 'Nezbývají žádné možnosti',
		projectsAndRoles: 'Projekty a role',
		USER: 'Uživatel',
		PROJECT_ADMIN: 'Správce projektu',
		WF_WRITE: 'Workflow uživatel', // čiastočný preklad
		WF_READ: 'Správce workflow', // čiastočný preklad
		INGEST_READ: 'Ingest uživatel', // čiastočný preklad
		INGEST_WRITE: 'Správce ingest', // čiastočný preklad
		type: 'Typ',
		battery: 'Baterie',
		temperature: 'Teplota',
		accuracy: 'Přesnost polohy',
		time: 'Čas',
		userDetail: 'Podrobnosti o uživateli',
		selectEndpointMethod: 'Vyberte metodu koncového bodu',
		method: 'Metoda',
		projects: 'Projekty',
		name: 'Název',
		description: 'Popis',
		latitude: 'Zeměpisná šířka',
		longitude: 'Zeměpisná délka',
		remove: 'Odstranit',
		add: 'Přidat',
		editZonePolygonModalTitle: 'Upravit zónu',
		createZonePolygonModalTitle: 'Vytvořit zónu',
		deleteZoneModalTitle: 'Smazat zónu',
		deleteZoneModalDescription: 'Opravdu chcete smazat zónu {{zone}}?',
		back: 'Zpět',
		createProject: 'Vytvořit projekt',
		editProjectModalTitle: 'Upravit projekt',
		deleteProjectModalTitle: 'Smazat projekt',
		deleteProjectModalDescription: 'Opravdu chcete projekt smazat {{project}}?',
		createProjectModalTitle: 'Vytvořit projekt',
		groups: 'Skupiny',
		dateCreated: 'Datum vytvoření',
		dateUpdated: 'Datum aktualizace',
		createGroup: 'Create Group',
		deleteGroup: 'Vytvořit skupinu',
		createGroupModalTitle: 'Vytvořit skupinu',
		deleteGroupModalTitle: 'Odstranit skupinu',
		deleteGroupModalDescription: 'Jste si jistí, že to chcete smazat {{name}}?',
		group: 'Skupina',
		editGroupModal: 'Upravit skupinu',
		groupDetail: 'Detail skupiny',
		updateDeviceModalTitle: 'Upravit zařízení',
		sn: 'Sériové číslo',
		networkNumber: 'Číslo sítě',
		removeDevices: 'Odeberte zařízení',
		removeDevicesModalTitle: 'Odebrat vybraná zařízení z této skupiny?',
		otherGroups: 'Další skupiny a nezařazená zařízení',
		workflowsCount: 'Workflows', // nie je preložené
		groupsCount: 'Skupiny',
		usersCount: 'Uživatelé',
		devicesCount: 'Zařízení',
		filters: 'Filtry',
		all: 'Všechno',
		reset: 'Resetovat',
		createWorkflow: 'Vytvořit workflow', // čiastočný preklad
		deleteWorkflowModalTitle: 'Vymazat workflow', // čiastočný preklad
		deleteWorkflowModalDescription: 'Jste si jistí, že to chcete smazat {{name}}?',
		addSituation: 'Přidejte situaci',
		addSituationModalTitle: 'Přidat situaci název',
		notifications: 'Oznámení',
		notificationType: 'Typ oznámení',
		tasks: 'Úkoly',
		editSituationModalTitle: 'Upravit situaci',
		variables: 'Proměnné',
		conditions: 'Podmínky',
		situations: 'Situace',
		loginFrom: 'Datum přihlášení od',
		loginTo: 'Datum přihlášení do',
		createZone: 'Vytvořit zónu',
		groupName: 'Skupinové jméno',
		highestTemp: 'Nejvyšší teplota',
		countLastWeek: 'Počítejte od minulého týdne',
		lastErrorDate: 'Datum poslední chyby',
		report: 'Výkaz',
		reports: 'Výkazy',
		reportOrder: 'Zadaný výkaz',
		reportOrders: 'Zadané výkazy',
		standingReportOrder: 'Trvalé zadání výkazu',
		standingReportOrders: 'Trvalé zadání výkazů',
		createReportOrder: 'Zadat výkaz',
		createReportOrderModalTitle: 'Zadat výkaz',
		confirm: 'Potvrdit',
		notificationModalTitle: 'Oznámení',
		severity: 'Závažnost',
		message: 'Zpráva',
		warning: 'Varování',
		success: 'Úspěch',
		info: 'Informace',
		moreItems: '{{first}} + {{count}} více',
		reportings: 'Zprávy',
		saveWorkflow: 'Uložit workflow', // čiastočný preklad
		lastSeen: 'Naposledy viděn',
		lastLocation: 'Poslední umístění',
		lastLocationTime: 'Čas posledního umístění',
		device: 'Zařízení',
		detail: 'Detail',
		lastWeek: 'Posledních 7 dní',
		lastTwoWeeks: 'Posledních 14 dní',
		lastMonth: 'Poslední měsíc',
		lastTwoMonths: 'Poslední 2 měsíce',
		useRange: 'Použij datový rozsah',
		from: 'Od',
		to: 'Do',
		submit: 'Odeslat',
		events: 'Události',
		lightIntensity: 'Intenzita světla',
		maxAcceleration: 'Maximální zrychlení',
		maxAccelerationHistory: 'Maximální zrychlení (historie)',
		yes: 'Ano',
		no: 'Ne',
		query: 'Dotaz',
		selectedZone: 'Vybraná zóna: {{zone}}',
		createDevice: 'Vytvořit zařízení',
		uploadDevices: 'Nahrajte zařízení',
		deviceDetail: 'Podrobnosti o zařízení',
		lastSignal: 'Poslední signál',
		telemetryHistory: 'Historie telemetrie',
		heat: 'Teplotní mapa',
		trail: 'Cestovní mapa',
		location: 'Umístění',
		moreOption: 'Více možností',
		mapType: 'Typ mapy',
		zoneId: 'ID zóny',
		removeFromGroup: 'Odebrat ze skupiny',
		addToGroup: 'Přidat do skupiny',
		currentTelemetry: 'Aktuální telemetrie',
		lastUpdate: 'Poslední aktualizace: {{date}}',
		devicesLastTelemetry: 'Poslední telemetrická data z každého zařízení',
		unsavedChanges: 'Neuložené změny!\n\nMáte neuložené změny, chcete pokračovat? Vaše změny budou ztraceny.',
		zoneEntryCount: 'Počet vstupů do zóny',
		averageTimeInZone: 'Průměrný čas v zóně',
		selectQuery: 'Vyberte dotaz',
		selectZone: 'Vyberte zónu',
		currentNotifications: 'Aktuální oznámení',
		currentReports: 'Aktuální reporty',
		currentSummary: 'Aktuální souhrn',
		hasMovedWithoutNewLocation: 'Přesunuto bez umístění',
		moved: 'Přesunuto',
		locationUpdateDetails: 'Lokalizační metoda',
		bizStep: 'BIZ_STEP',
		action: 'Akce',
		eventType: 'Událost',
		actDescription: 'ACT',
		signalPower: 'Síla signálu',
		eventTime: 'Čas události',
		showHideColumns: 'Zobrazit/skrýt sloupce',
		usersManagement: 'Správa uživatelů',
		noLocation: 'Neznáme umístění',
		zone: 'Zóna',
		missingProjectModalTitle: 'Chýbějící projekt',
		missingProjectModalDescription:
			'Uživatel name}} nemá přidělená práva k žádnému projektu! Požádejte o ně příslušných administrátorů.',
		zoom: 'Priblížit',
		uploadGeoJSON: 'Nahrát z GeoJSON',
		close: 'Zavřít',
		refresh: 'Obnovit',
		telemetry: 'Telemetrie',
		placement: 'Umístění',
		placements: 'Umístění',
		attached: 'Připojené',
		attach: 'Připojit',
		detached: 'Odpojené',
		detach: 'Odpojit',
		charts: 'Grafy',
		attachPlacementModalTitle: 'Připojit umístění',
		createPlacement: 'Vytvořit umístění',
		value: 'Hodnota',
		classificationId: 'ID klasifikátora',
		deletePlacementModalTitle: 'Odstranit umístění',
		deletePlacementModalDescription: 'Jste si jistí, že chcete smazat umístění: {{name}}?',
		createPlacementModalTitle: 'Vytvořit umístění',
		editPlacementModalTitle: 'Editovat umístění',
		classification: 'Klasifikátor',
		classifications: 'Klasifikátory',
		deleteClassificationModalTitle: 'Odstranit klasifikátor',
		deleteClassificationModalDescription: 'Jste si jistí, že chcete smazat klasifikátor: {{name}}?',
		createClassificationModalTitle: 'Vytvořit klasifikátor',
		editClassificationModalTitle: 'Editovat identifikátor',
		createClassification: 'Vytvořit klasifikátor',
		attachedDevices: 'Připojená zařízení',
		attachedDevicesModalTitle: 'Seznam připojených zařízení',
		noAttachedDevices: 'K tomuto umístění nejsou připojena žádná zařízení.',
		choosePlacement: 'Vybrat umístění',
		lastMessage: 'Last message', // TODO
		messageCounter: 'Message counter', // TODO
		sinceConnected: 'Since connected', // TODO
		attachDevice: 'Připojit zařízení',
		chooseDevice: 'Vybrat zařízení',
		//
		manufacturer: 'Výrobce',
		packageNumber: 'Číslo obalu (projektu)',
		serialNumber: 'Poř. číslo',
		partDescription: 'Popis dílu (obalu)',
		project: 'Projekt',
		note: 'Poznámka',
		assetDescription: 'Popis assetu',
		assetName:'Název assetu',
		//
		exportDevicesToExcel: 'Exportovat do Excelu',
		//
		configuration: 'Konfigurace',
		management: 'Manažment',
		favourites: 'Oblíbené',
		//
		driverid: 'Číslo řidiče',
		carType: 'Typ auta',
		SPZ: 'SPZ',
		//
		script: 'Skript',
		ingestionScripts: 'Vstupní skripty',
		ingestionScriptDetail: 'Detail vstupního skriptu',
		createScript: 'Vytvořit skript',
		validate: 'Validovat',
		validating: 'Validuje se ...',
		deleteScriptModalTitle: 'Odstranit skript',
		deleteScriptModalDescription: 'Jste si jistí, že chcete smazat skript: {{script}}?',
		selectScript: 'Vyberte skript',
		testScript: 'Vyskoušet skript',
		testingScript: 'Skouším skript ...',
		selectGroup: 'Vybrat skupinu',
		reportType: 'Typ výkazu',
		reportStatus: 'Stav',
		parameters: 'Parametery',
		summary: 'Souhrn',
		selectTenant: 'Vyberte vlastníka',
		// review report state
		startedStatus: 'Zpracovává se',
		errorStatus: 'Chyba',
		finishedStatus: 'Hotovo',
	},
};

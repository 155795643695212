import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { InfoCard } from '../../components/InfoCard/InfoCard';
import { ChangePasswordForm } from './components/ChangePasswordForm';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { UserState } from '../../store/user/user-types';
import { changePassword } from '../../store/user-management/user-management-async-actions';
import { BackButton } from '../../components/Button/BackButton';

export const Settings = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { userId } = useSelector<RootState, UserState>((state) => state.user);

	const handleChangePassword = (password: string) => {
		dispatch(changePassword({ userId, password }));
	};

	// TODO Version should not be hardcoded

	return (
		<Grid container spacing={2} direction="column">
			<Grid item xs={12}>
				<BackButton />
			</Grid>
			<Typography component="h4" variant="subtitle2">
				Version 4.1.1
			</Typography>
			<Grid item sm={7} lg={5} xl={4}>
				<InfoCard>
					<Typography component="h2" variant="h6">
						{t('changePassword')}
					</Typography>
					<ChangePasswordForm onSubmit={handleChangePassword} />
				</InfoCard>
			</Grid>
		</Grid>
	);
};

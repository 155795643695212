import React from 'react';
import { Typography } from '@material-ui/core';

type Props = {
	text: string;
};

export const TableFilterHeading = ({ text }: Props) => {
	return (
		<Typography component="h4" variant="subtitle2" gutterBottom>
			{text}
		</Typography>
	);
};

import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/Button/Button';
import { InputField } from '../../../components/Form/InputField';
import SaveIcon from '@material-ui/icons/Save';
import { ModalDialogFormActions } from '../../../components/ModalDialog/ModalDialogFormActions';
import { Grid, Typography } from '@material-ui/core';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import { tryFeatureCollection } from 'pure-geojson-validation';

require('codemirror/mode/javascript/javascript.js');

export type InDoorMapFormValues = {
	name: string;
	geoJson: string;
};

type Props = {
	values?: InDoorMapFormValues;
	onSubmit: (values: InDoorMapFormValues) => void;
	onCancel?: () => void;
};

export const InDoorMapForm = ({ onSubmit, onCancel, values }: Props) => {
	const { t } = useTranslation();

	const [name, setName] = useState<string>(values?.name ?? '');
	const [nameError, setNameError] = useState<boolean>(false);

	const [geoJson, setGeoJson] = useState<string>(values?.geoJson ?? '');
	const [geoJsonError, setGeoJsonError] = useState<boolean>(false);

	const handleSetName = (event: ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value);
		setNameError(false);
	};

	function handleSetGeoJson(value: string) {
		setGeoJson(value);
		setGeoJsonError(false);
	}

	// React.useEffect(() => {
	// 	const current = (codemirrorRef?.current.editor.display.wrapper.style.height = '1000px');
	// }, []);

	const handleSubmitForm = () => {
		let valid = true;

		if (name === '') {
			valid = false;
			setNameError(true);
		}

		let geoJsonToSave: any = '';

		try {
			// const geojson: unknown = JSON.parse(inDoorMap.layers[0].image);
			// console.log('-----------------');
			// console.log(inDoorMap.layers[0].image);

			const unsafeFC = tryFeatureCollection(geoJson as string);

			console.info('successfully parsed feature collection');

			geoJsonToSave = JSON.stringify(unsafeFC);
			// console.log(geojson.features);
			// console.log('++++++++++++++++++');

			// setGeo({ key: inDoorMap.id, image: unsafeFC });

			// return tmp.push(geojson);
		} catch (exception) {
			valid = false;
			setGeoJsonError(true);
		}

		if (geoJsonToSave === '') {
			valid = false;
			setGeoJsonError(true);
		}

		if (valid) {
			onSubmit({
				name: name,
				geoJson: geoJsonToSave,
			});
		}
	};

	// const selectedDeviceType = deviceTypes.find((deviceType) => deviceType.id === values?.deviceTypeId);

	// console.log(selectedDeviceType);

	return (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<InputField
					autoFocus
					value={name}
					error={nameError}
					helperText={nameError && t('validationErrors.required')}
					label={t('name')}
					name="name"
					onChange={handleSetName}
				/>
			</Grid>
			<Grid item>
				<CodeMirror
					autoCursor={false}
					value={geoJson}
					options={{
						mode: {
							name: 'javascript',
							json: true,
							statementIndent: 2,
						},
						theme: 'material',
						lineNumbers: true,
						lineWrapping: true,
						indentWithTabs: false,
					}}
					onChange={(editor, data, value) => {
						handleSetGeoJson(value);
					}}
				/>
				{geoJsonError && (
					<Typography component="h2" variant="h6" color="error">
						{'Bad geojson'}
					</Typography>
				)}
			</Grid>
			<Grid item>
				<ModalDialogFormActions>
					<Button onClick={handleSubmitForm} startIcon={<SaveIcon />} data-cy="btn-submit:inDoorMapForm">
						{t('save')}
					</Button>
					<Button onClick={onCancel} variant="text" data-cy="btn-cancel:inDoorMapForm">
						{t('cancel')}
					</Button>
				</ModalDialogFormActions>
			</Grid>
		</Grid>
	);
};

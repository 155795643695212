import React, { useEffect } from 'react';
import { Table } from '../../../components/Table/Table';
import { Telemetry } from '../telemetry-types';
import { fetchTelemetry } from '../../../store/telemetry/telemetry-async-actions';
import {
	TableColumnFilter,
	TableColumnSort,
	TableHeadCell,
	TableRowAction,
	TableRowRenderProps,
} from '../../../components/Table/types';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import {
	TableRow,
	TableCell,
	CardHeader,
	Divider,
	makeStyles,
	FormControlLabel,
	Checkbox,
	Tooltip,
	IconButton,
} from '@material-ui/core';
import { INPUT_DATE_TIME_MASK } from '../../../helpers/generalConstants';
import { DeviceDetailTelemetryTableFilter } from './DeviceDetailTelemetryTableFilter';
import clsx from 'clsx';
import { PopoverOptions } from '../../../components/Table/PopoverOptions';
import { ViewColumnRounded, Visibility } from '@material-ui/icons';
import { RootState } from '../../../store/types';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchTelemetryTableHidenColumns,
	setTelemetryTableHidenColumns,
} from '../../../store/devices/devices-async-actions';
import { UUID } from '../../../types';
import { TableRowActionIcon } from '../../../components/Table/constants';
import { addModalDialog } from '../../../store/app/app-actions';

type Props = {
	userId: UUID;
	data: Telemetry[];
	total: number;
	offset: number;
	limit: number;
	orderings?: TableColumnSort[];
	filters?: TableColumnFilter[];
	deviceSn: string;
	deviceType?: string;
};

type TableRowData = Telemetry & {
	id: string;
};

const useStyles = makeStyles({
	hidden: {
		display: 'none',
	},
});

export const DeviceDetailTelemetryTable = ({
	userId,
	data,
	total,
	offset,
	limit,
	orderings,
	filters,
	deviceSn,
	deviceType,
}: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const TELEMETRY_HEAD_CELLS_KEY = `TelemetryHideKey${userId}`;

	const defaultColumns: TableHeadCell[] = [
		{ id: 'sn', label: 'sn', unorderable: true, hidden: true },
		{ id: 'battery', label: 'battery', unorderable: true },
		{ id: 'temperature', label: 'temperature', unorderable: true },
		{ id: 'lightIntensity', label: 'lightIntensity', unorderable: true },
		{ id: 'accuracy', label: 'accuracy', unorderable: true },
		{ id: 'maxAcceleration', label: 'maxAcceleration', unorderable: true },
		{ id: 'maxAccelerationHistory', label: 'maxAccelerationHistory', unorderable: true },
		{ id: 'time', label: 'time', unorderable: true },
		{ id: 'moved', label: 'moved', unorderable: true },
		{ id: 'zones', label: 'zones', unorderable: true },
		{ id: 'latitude', label: 'latitude', hidden: true, unorderable: true },
		{ id: 'longitude', label: 'longitude', hidden: true, unorderable: true },
		{ id: 'locationUpdateDetails', label: 'locationUpdateDetails', unorderable: true, hidden: true },
		{ id: 'bizStep', label: 'bizStep', hidden: true, unorderable: true },
		{ id: 'action', label: 'action', hidden: true, unorderable: true },
		{ id: 'eventType', label: 'eventType', hidden: true, unorderable: true },
		{ id: 'actDescription', label: 'actDescription', hidden: true, unorderable: true },
		{ id: 'signalPower', label: 'signalPower', hidden: true, unorderable: true },
		{ id: 'eventTime', label: 'eventTime', hidden: true, unorderable: true },
	];

	const columns = useSelector<RootState, typeof defaultColumns>((state) => {
		const hidenFlags = state.devices.telemetryTableHidenFlags;

		const isValid = Array.isArray(hidenFlags) && hidenFlags.length > 0;

		return isValid ? hidenFlags : defaultColumns;
	});

	useEffect(() => {
		console.log('FETCH HIDE FLAGS');
		// dispatch(fetchDeviceTableHidenColumns(DEVICES_HEAD_CELLS_KEY));
		dispatch(fetchTelemetryTableHidenColumns(TELEMETRY_HEAD_CELLS_KEY));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const tableData: TableRowData[] = data.map((telemetry) => {
		return {
			id: `${telemetry.sn}-${telemetry.time}`,
			...telemetry,
		};
	});

	const shouldHideColumn = (columnId: string) => columns.some(({ id, hidden }) => columnId === id && hidden);

	const renderTableRow = ({ row: telemetry, renderActions }: TableRowRenderProps<TableRowData>) => {
		return (
			<TableRow key={telemetry.id} id={`row-${telemetry.id}`}>
				<TableCell
					className={clsx(shouldHideColumn('sn') && classes.hidden)}
					component="td"
					id="sn"
					scope="row"
				>
					{telemetry.sn}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('battery') && classes.hidden)}
					component="td"
					id="battery"
					scope="row"
				>
					{telemetry.battery && t('percentage', { value: telemetry.battery.toFixed(2) })}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('temperature') && classes.hidden)}
					component="td"
					id="temperature"
					scope="row"
				>
					{telemetry.temperature && t('degreesCelsius', { value: telemetry.temperature.toFixed(2) })}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('lightIntensity') && classes.hidden)}
					component="td"
					id="lightIntensity"
					scope="row"
				>
					{telemetry.light_intensity && t('illuminance', { value: telemetry.light_intensity })}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('accuracy') && classes.hidden)}
					component="td"
					id="accuracy"
					scope="row"
				>
					{telemetry.accuracy}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('maxAcceleration') && classes.hidden)}
					component="td"
					id="maxAcceleration"
					scope="row"
				>
					{telemetry.max_acceleration}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('maxAccelerationHistory') && classes.hidden)}
					component="td"
					id="maxAccelerationHistory"
					scope="row"
				>
					{telemetry.max_acceleration_history}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('time') && classes.hidden)}
					component="td"
					id="time"
					scope="row"
				>
					{telemetry.time && format(new Date(telemetry.time), INPUT_DATE_TIME_MASK)}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('moved') && classes.hidden)}
					component="td"
					id="moved"
					scope="row"
				>
					{telemetry.moved ? t(telemetry.moved === 'true' ? 'yes' : 'no') : 'N/A'}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('zones') && classes.hidden)}
					component="td"
					id="zones"
					scope="row"
				>
					{Array.isArray(telemetry.zones) &&
						telemetry.zones.map((zone, index: number) => `${index > 0 ? ', ' : ''}${zone.name}`)}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('latitude') && classes.hidden)}
					component="td"
					id="latitude"
					scope="row"
				>
					{telemetry.lat}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('longitude') && classes.hidden)}
					component="td"
					id="longitude"
					scope="row"
				>
					{telemetry.lng}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('locationUpdateDetails') && classes.hidden)}
					component="td"
					id="locationUpdateDetails"
					scope="row"
				>
					{telemetry.location_update_details}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('bizStep') && classes.hidden)}
					component="td"
					id="bizStep"
					scope="row"
				>
					{telemetry.biz_step}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('action') && classes.hidden)}
					component="td"
					id="action"
					scope="row"
				>
					{telemetry.action}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('eventType') && classes.hidden)}
					component="td"
					id="eventType"
					scope="row"
				>
					{telemetry.eventType}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('actDescription') && classes.hidden)}
					component="td"
					id="actDescription"
					scope="row"
				>
					{telemetry.act_description}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('signalPower') && classes.hidden)}
					component="td"
					id="signalPower"
					scope="row"
				>
					{telemetry.signal_power}
				</TableCell>
				<TableCell
					className={clsx(shouldHideColumn('eventTime') && classes.hidden)}
					component="td"
					id="eventTime"
					scope="row"
				>
					{telemetry.eventTime}
				</TableCell>
				<TableCell padding="none" sortDirection={false}>
					{renderActions(rowActions, telemetry)}
				</TableCell>
			</TableRow>
		);
	};

	const rowActions: TableRowAction<TableRowData>[] = [
		{
			id: 'btn-detail:telemetry',
			icon: TableRowActionIcon.show,
			tooltip: t('json'),
			onClick: (telemetry: TableRowData) =>
				dispatch(
					addModalDialog({
						id: 'telemetryJson',
						title: 'Telemetry JSON',
						content: <pre>{JSON.stringify(telemetry, null, 2)}</pre>,
					}),
				),
		},
	];

	function handleSaveFilters(filters: TableColumnFilter[]) {
		dispatch(
			fetchTelemetry({
				offset: 0,
				limit: limit,
				orderings: orderings,
				filtersAndConditions: filters,
			}),
		);
	}

	return (
		<>
			<CardHeader
				title={deviceSn}
				subheader={deviceType}
				action={
					<>
						<Tooltip title={'Telemetries JSON'} aria-label={t('JSON')}>
							<IconButton
								onClick={() =>
									dispatch(
										addModalDialog({
											id: 'telemetriesJson',
											title: 'Telemetries JSON',
											content: <pre>{JSON.stringify(data, null, 2)}</pre>,
										}),
									)
								}
								data-cy="btn-create:definition"
							>
								<Visibility />
							</IconButton>
						</Tooltip>
						<PopoverOptions
							id="telemetryHistoryTable-columnsSettings"
							icon={<ViewColumnRounded />}
							title={t('showHideColumns')}
						>
							{defaultColumns.map(({ id }) => {
								const isChecked = columns.some((column) => column.id === id && !column.hidden);

								return (
									<FormControlLabel
										key={id}
										control={
											<Checkbox
												checked={isChecked}
												color="default"
												name={id}
												onChange={() => {
													const changedColumns = columns.map((column) =>
														column.id === id
															? {
																	...column,
																	hidden: !column.hidden,
															  }
															: column,
													);

													dispatch(
														setTelemetryTableHidenColumns({
															key: TELEMETRY_HEAD_CELLS_KEY,
															body: changedColumns,
														}),
													);
												}}
											/>
										}
										label={t(id)}
									/>
								);
							})}
						</PopoverOptions>
						<DeviceDetailTelemetryTableFilter
							filters={filters}
							deviceSn={deviceSn}
							handleFilters={handleSaveFilters}
						/>
					</>
				}
			/>
			<Divider light />
			<Table
				actions={rowActions}
				columns={columns.flatMap((column) => {
					return { ...column, label: t(column.label) };
				})}
				data={tableData}
				dataLimit={limit}
				dataOffset={offset}
				orderings={orderings}
				filtersAndConditions={filters}
				numberOfRows={total}
				fetchAction2={fetchTelemetry}
				selectable={false}
				size="small"
				sortable={false}
				renderTableRow={renderTableRow}
			/>
		</>
	);
};

import React, { useEffect, useState } from 'react';
import {
	Card,
	CardContent,
	CardHeader,
	Checkbox,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	makeStyles,
	Tab,
	Tabs,
	Theme,
	Typography,
} from '@material-ui/core';
import { TabPanel } from '../../components/Tabs/TabPanel';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RootState } from '../../store/types';
import { BackButton } from '../../components/Button/BackButton';
import { UserState } from '../../store/user/user-types';
import { FilterOperators, SortOrder } from '../../components/Table/constants';
import { fetchRequest } from '../../services/helpers';
import { DateTimePicker } from '../../components/DatePicker/DateTimePicker';
import { DevicePlacement, DevicePlacementsResponseData } from '../DevicePlacementsManagement/device-placements-types';
import {
	fetchPlacementsWithTelemetry,
	fetchPlacementTelemetry,
} from '../../services/device-placements/device-placements-service';
import { MapContainer } from '../../components/Maps/MapContainer';
import { MapMarker } from '../Maps/components/MapMarker';
import { MapPopup } from '../Maps/components/MapPopup';
import { Circle, FeatureGroup, GeoJSON, Polyline } from 'react-leaflet';
import { INPUT_DATE_TIME_MASK, PermissionsDotNet } from '../../helpers/generalConstants';
import { format } from 'date-fns';
import { DEFAULT_MAP_CENTER, INDOOR_MAP_ID } from './worker-care-types';
import { InDoorMapsResponseData } from '../InDoorMaps/indoormaps-types';
import { getFilteredInDoorMaps } from '../../services/inDoorMaps/inDoorMaps-service';
import { FeatureCollection } from 'geojson';
import { uniqueId } from 'lodash';
import { onEachFeature } from '../../components/Maps/helpers';
import { tryFeatureCollection } from 'pure-geojson-validation';
import { TableColumnFilter } from '../../components/Table/types';
import { Telemetry, TelemetryResponseData } from '../Devices/telemetry-types';
import { LatLngTuple } from 'leaflet';
import { notEmpty } from '../../helpers/array';
import { getFilteredZones2 } from '../../services/zones/zones-service';
import { ZoneResponseData, ZonesResponseData } from '../Zones/zones-types';
import { DeviceDetailZones } from '../Devices/components/DeviceDetailZones';
import { Nullable, UUID } from '../../types';
import { Sidebar, Tab as SidebarTab } from 'react-leaflet-sidebarv2';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import SettingsIcon from '@material-ui/icons/Settings';
import TimelineIcon from '@material-ui/icons/Timeline';
import { RouterRounded } from '@material-ui/icons';
import { RouteEnum } from '../../router/Routes';
import { hasPermission } from '../../helpers/auth';
import { AttachedDevicesList } from './components/AttachedDevicesList';
import HeatmapLayer from 'react-leaflet-heatmap-layer';

const useStyles = makeStyles(({ spacing }: Theme) => ({
	paper: {
		padding: spacing(2, 3, 3, 3),
	},
	marginTop: {
		marginTop: spacing(3),
	},
	marginTop2: {
		marginTop: spacing(3),
	},
	marginBottom: {
		marginBottom: spacing(-3),
	},
	center: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		padding: spacing(2),
	},
}));

type RouteParams = {
	placementId: UUID;
};

export const WorkerCareDetail = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const history = useHistory();
	const { placementId } = useParams<RouteParams>();
	const { project, permissions } = useSelector<RootState, UserState>((state) => state.user);
	const canReadDevices = hasPermission(PermissionsDotNet.DeviceRead, permissions);
	const [tab, setTab] = useState(0);
	const [worker, setWorker] = useState<DevicePlacement>();
	const [inDoorMap, setInDoorMap] = useState<{ image: FeatureCollection; key: string }>();
	const [telemetryHistory, setTelemetryHistory] = useState<Telemetry[]>([]);
	const [zones, setZones] = useState<ZoneResponseData[]>();
	const [trailMapCenter, setTrailMapCenter] = useState<LatLngTuple>(DEFAULT_MAP_CENTER);
	const [sidebarColabsed, setSidebarColabsed] = useState(true);
	const [sidebarSelected, setSidebarSelected] = useState('home');

	const [showHeatMap, setShowHeatMap] = useState(false);

	// Time spane
	const [dateFrom, setDateFrom] = useState<Nullable<Date>>(null);
	const [dateTo, setDateTo] = useState<Nullable<Date>>(new Date());

	const [trailPoints, setTrailPoints] = useState<Telemetry[]>([]);
	const [trailLines, setTrailLines] = useState<
		(
			| {
					positions: LatLngTuple[];
					color: string;
			  }
			| undefined
		)[]
	>([]);

	/**
	 * Fetch vehicle
	 */
	useEffect(() => {
		if (placementId !== undefined) {
			const filters = [
				{
					column: 'id',
					operator: FilterOperators.equals,
					value: placementId,
				},
			];

			if (project) {
				filters.push({
					column: 'projects',
					operator: FilterOperators.injoin,
					value: project.id,
				});
			}

			const request = fetchPlacementsWithTelemetry({
				limit: 10,
				offset: 0,
				orderings: [],
				filtersAndConditions: filters,
			});

			(async () => {
				const { data, error } = await fetchRequest<DevicePlacementsResponseData>(request);

				if (error) {
					// TODO show error
				} else {
					if (data?.data && data?.data.length > 0) {
						setWorker(data.data[0]);
					}
				}
			})();
		}
	}, [placementId, project]);

	/**
	 * Fetch vehicle telemetry history
	 */
	useEffect(() => {
		if (placementId !== undefined) {
			const filters: TableColumnFilter[] = [];

			const request = fetchPlacementTelemetry(
				{
					limit: 10000,
					offset: 0,
					orderings: [{ column: 'time', sortOrder: SortOrder.Descending }],
					filtersAndConditions: filters,
				},
				placementId,
			);

			(async () => {
				const { data, error } = await fetchRequest<TelemetryResponseData>(request);

				if (error) {
					// TODO show error
				} else {
					if (data?.data && data?.data.length > 0) {
						setTelemetryHistory(data.data);
					}
				}
			})();
		}
	}, [placementId, project]);

	useEffect(() => {
		const request = getFilteredInDoorMaps({
			limit: 1,
			offset: 0,
			orderings: [],
			filtersAndConditions: [
				{
					column: 'id',
					operator: FilterOperators.equals,
					value: INDOOR_MAP_ID,
				},
			],
		});

		(async () => {
			const { data, error } = await fetchRequest<InDoorMapsResponseData>(request);

			if (error) {
				// TODO show error
			} else {
				if (data?.data && data?.data.length > 0) {
					let geojson: FeatureCollection | undefined = undefined;

					try {
						const geoj: unknown = JSON.parse(data.data[0].layers[0].image); // TODO make for all floors

						const unsafeFC = tryFeatureCollection(geoj as string);

						geojson = unsafeFC;
					} catch (exception) {}

					if (geojson !== undefined) {
						setInDoorMap({ image: geojson, key: uniqueId() });
					}
				}
			}
		})();

		// Don't need to react on "config" and "filters" and deep comparision is not necessary
		/// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Fetching all zones
	 */
	useEffect(() => {
		if (!zones) {
			const request = getFilteredZones2({
				limit: -1,
				offset: 0,
				orderings: [],
				filtersAndConditions: [],
			});

			(async () => {
				const { data, error } = await fetchRequest<ZonesResponseData>(request);

				if (error) {
					console.log(error);
					// TODO show error
				} else {
					if (data) {
						setZones(data.data);
					}
				}
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [project]);

	useEffect(() => {
		if (telemetryHistory && telemetryHistory.length > 0) {
			const lastTelemetryTime = new Date(telemetryHistory[0].time);

			if (!isNaN(lastTelemetryTime.getTime())) {
				lastTelemetryTime.setMinutes(lastTelemetryTime.getMinutes() - 30);

				setDateFrom(lastTelemetryTime);
			}
		}
	}, [telemetryHistory]);

	useEffect(() => {
		setTrailPoints(
			telemetryHistory
				.filter((telemetry) => {
					let fitIn = false;
					const time = new Date(telemetry.time);

					if (!isNaN(time.getTime())) {
						if (dateFrom !== null && time > dateFrom) {
							if (dateTo === null || time < dateTo) {
								fitIn = true;
							}
						}
					}

					return fitIn;
				})
				.map((telemetry) => {
					if (telemetry.lat && telemetry.lng) {
						return telemetry;
					}

					return null;
				})
				.filter(notEmpty),
		);
	}, [telemetryHistory, dateFrom, dateTo]);

	useEffect(() => {
		setTrailLines(
			trailPoints.map<{ positions: LatLngTuple[]; color: string } | undefined>((point, i, arr) => {
				let previousPointPosition: LatLngTuple | undefined = undefined;

				const previousTelemetry = arr[i - 1];

				if (previousTelemetry && previousTelemetry.lat && previousTelemetry.lng) {
					previousPointPosition = [previousTelemetry.lat, previousTelemetry.lng];
				}

				const R = Math.floor((i * 256) / trailPoints.length);
				const G = 255 - R;

				const color = (0x1000000 + R * 0x10000 + G * 0x100).toString(16).toUpperCase().substring(1);

				const colorString = `#${color}`;

				return previousPointPosition
					? { positions: [previousPointPosition, [point.lat, point.lng] as LatLngTuple], color: colorString }
					: undefined;
			}),
		);
	}, [trailPoints]);

	function getFieldValue(fieldName: string, placement?: DevicePlacement): string {
		if (placement) {
			const entry = Object.entries(placement.fields).find(([key]) => key === fieldName);

			if (entry) {
				const value = entry[1] as string;

				if (value && value !== '') {
					return value;
				}
			}
		}

		return '-';
	}

	const handleClickTelemetry = (telemetry: Telemetry) => {
		if (telemetry.lat !== undefined && telemetry.lng !== undefined) {
			setTrailMapCenter([telemetry.lat, telemetry.lng]);
			// handleSetSelectedItem(device.id, 'device', 'handleClickDevice', false);
		}
	};

	const onClose = () => {
		setSidebarColabsed(true);
	};

	const onOpen = (id: string) => {
		setSidebarColabsed(false);
		setSidebarSelected(id);
	};

	function getCenter(center?: [number | undefined, number | undefined]): [number, number] {
		if (center && center[0] && center[1]) {
			return center as [number, number];
		}

		return DEFAULT_MAP_CENTER;
	}

	return (
		<>
			<BackButton />

			<Tabs
				className={classes.marginTop}
				value={tab}
				onChange={(_: React.ChangeEvent<{}>, newTab: number) => setTab(newTab)}
				indicatorColor="primary"
				textColor="primary"
			>
				<Tab label={'Worker info'} />
				<Tab label={'Trip history'} />
				<Tab label={'Graphs'} disabled />
				{/* <Tab label={'Logs'} disabled /> */}
				<Tab label={'Settings'} />
				<Tab label={<RouterRounded />} />
			</Tabs>

			<Divider light />

			<TabPanel value={tab} index={0}>
				<Grid container spacing={3} className={classes.marginTop2}>
					<Grid item xs={12} sm={4} lg={4}>
						<Card>
							<CardContent>
								<Typography variant="body2">{t('fName_wc_PersonalNo.')}</Typography>
								<Typography variant="subtitle1">
									{getFieldValue('fName_wc_PersonalNo.', worker)}
								</Typography>
							</CardContent>
							<CardContent>
								<Typography variant="body2">{t('fName_wc_FirstName')}</Typography>
								<Typography variant="subtitle1">
									{getFieldValue('fName_wc_FirstName', worker)}
								</Typography>
							</CardContent>
							<CardContent>
								<Typography variant="body2">{t('fName_wc_Surname')}</Typography>
								<Typography variant="subtitle1">{getFieldValue('fName_wc_Surname', worker)}</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} sm={4} lg={4}>
						<Card>
							<CardContent>
								<Typography variant="body2">{t('fName_wc_Department')}</Typography>
								<Typography variant="subtitle1">
									{getFieldValue('fName_wc_Department', worker)}
								</Typography>
							</CardContent>
							<CardContent>
								<Typography variant="body2">{t('fName_wc_Supervisor')}</Typography>
								<Typography variant="subtitle1">
									{getFieldValue('fName_wc_Supervisor', worker)}
								</Typography>
							</CardContent>
							<CardContent>
								<Typography variant="body2">{t('fName_wc_Occupation')}</Typography>
								<Typography variant="subtitle1">
									{getFieldValue('fName_wc_Occupation', worker)}
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} sm={4} lg={4}>
						<Card>
							<CardContent>
								<Typography variant="body2">{t('fName_wc_DrivingLicense')}</Typography>
								<Typography variant="subtitle1">
									{getFieldValue('fName_wc_DrivingLicense', worker)}
								</Typography>
							</CardContent>
							<CardContent>
								<Typography variant="body2">.</Typography>
								<Typography variant="subtitle1">.</Typography>
							</CardContent>
							<CardContent>
								<Typography variant="body2">.</Typography>
								<Typography variant="subtitle1">.</Typography>
							</CardContent>
						</Card>
					</Grid>

					<Grid item xs={12} sm={7} lg={7}>
						<MapContainer
							center={getCenter([worker?.telemetry?.lat, worker?.telemetry?.lng])}
							height={820}
							zoom={10}
						>
							<FeatureGroup>
								{inDoorMap && (
									<GeoJSON key={inDoorMap.key} data={inDoorMap.image} onEachFeature={onEachFeature} />
								)}
							</FeatureGroup>
							{zones && (
								<DeviceDetailZones
									// onClick={handleClickZone}
									zones={zones}
									// selectedZoneId={selectedItem}
									onSelected={(id: string) => null}
								/>
							)}
							{worker?.telemetry && worker.telemetry.lat && worker.telemetry.lng && (
								<>
									{worker.telemetry.accuracy && worker.telemetry.accuracy < 1000 && (
										<Circle
											center={[worker.telemetry.lat, worker.telemetry.lng]}
											fillColor="blue"
											radius={worker.telemetry.accuracy}
											stroke={false}
										/>
									)}
									<MapMarker type="device" position={[worker.telemetry.lat, worker.telemetry.lng]}>
										<MapPopup
											title={`${t('placement')}: ${worker.placementDescription?.title1} ${
												worker.placementDescription?.subTitle1
											}`}
										>
											<List dense disablePadding>
												<ListItem key="sn" disableGutters>
													{`${t('location')}: ${worker.telemetry.lat}, ${
														worker.telemetry.lng
													}`}
												</ListItem>
												{worker.telemetry.time && (
													<ListItem key="time" disableGutters>
														{`${t('time')}: `}
														{format(new Date(worker.telemetry.time), INPUT_DATE_TIME_MASK)}
													</ListItem>
												)}
											</List>
										</MapPopup>
									</MapMarker>
								</>
							)}
						</MapContainer>
					</Grid>
					<Grid item xs={12} sm={5} lg={5}>
						<Card>
							<CardHeader
								title={'CURRENT STATUS'}
								subheader={
									worker?.telemetry?.time
										? format(new Date(worker.telemetry.time), INPUT_DATE_TIME_MASK)
										: '-'
								}
								action={
									canReadDevices && (
										<>
											<IconButton
												onClick={() => {
													if (worker?.telemetry?.sn) {
														history.push(`${RouteEnum.DEVICES}/${worker.telemetry.sn}`);
													}
												}}
												disabled={worker?.telemetry?.sn === undefined}
											>
												<RouterRounded />
											</IconButton>
											<Typography variant="subtitle1">{worker?.telemetry?.sn}</Typography>
										</>
									)
								}
							/>
							<Divider light />
							<CardContent>
								<Typography variant="body2">{t('battery')}</Typography>
								<Typography variant="subtitle1">
									{worker?.telemetry?.battery ? `${worker?.telemetry?.battery} %` : '-'}
								</Typography>
							</CardContent>
							<CardContent>
								<Typography variant="body2">{t('location')}</Typography>
								<Typography variant="subtitle1">
									{worker?.telemetry?.zones?.map((zone) => zone.name) ?? '-'}
								</Typography>
							</CardContent>
							<CardContent>
								<Typography variant="body2">{t('temperature')}</Typography>
								<Typography variant="subtitle1">
									{worker?.telemetry?.temperature
										? t('degreesCelsius', {
												value: worker.telemetry.temperature,
										  })
										: '-'}
								</Typography>
							</CardContent>
							<CardContent>
								<Typography variant="body2">{'Distance traveled'}</Typography>
								<Typography variant="subtitle1">
									{worker?.telemetry?.odo ? `${worker.telemetry.odo.toFixed(1)} km` : '-'}
								</Typography>
							</CardContent>
							<CardContent>
								<Typography variant="body2">{'Movement time'}</Typography>
								<Typography variant="subtitle1">
									{worker?.telemetry?.rh !== undefined && worker.telemetry.rh !== null
										? `${worker.telemetry.rh.toFixed(1)} h`
										: '-'}
								</Typography>
							</CardContent>
							<CardContent>
								<Typography variant="body2">{'In trip'}</Typography>
								<Typography variant="subtitle1">
									{worker?.telemetry?.intrip !== undefined
										? worker.telemetry.intrip === 0
											? t('no')
											: worker.telemetry.intrip === 1
											? t('yes')
											: '-'
										: '-'}
								</Typography>
							</CardContent>
							<CardContent>
								<Typography variant="body2">{'Accident'}</Typography>
								<Typography variant="subtitle1">
									{
										// eslint-disable-next-line eqeqeq
										worker?.telemetry?.eventType == 'High-G Event' ? t('yes') : t('no')
									}
								</Typography>
							</CardContent>
							<CardContent>
								<Typography variant="body2">{'Tip Detected'}</Typography>
								<Typography variant="subtitle1">
									{
										// eslint-disable-next-line eqeqeq
										worker?.telemetry?.eventType == 'Tilt detection' ? t('yes') : t('no')
									}
								</Typography>
							</CardContent>
							<CardContent>
								<Typography variant="body2">{t('eventType')}</Typography>
								<Typography variant="subtitle1">
									{worker?.telemetry?.eventType ? worker.telemetry.eventType : '-'}
								</Typography>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</TabPanel>
			<TabPanel value={tab} index={1}>
				<Grid container className={classes.marginTop2}>
					<Grid item xs={12} className={classes.marginBottom}>
						<Grid container direction="row" spacing={3}>
							<Grid item>
								<DateTimePicker
									label={t('from')}
									disableFuture
									maxDate={new Date()}
									id="dateFrom"
									value={dateFrom}
									onChange={(date) => setDateFrom(date)}
								/>
							</Grid>
							<Grid item>
								<DateTimePicker
									label={t('to')}
									disableFuture
									maxDate={new Date()}
									id="dateTo"
									value={dateTo}
									onChange={(date) => setDateTo(date)}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={12} lg={12}>
						<Grid item>
							<Grid container alignItems="center" direction="row" justifyContent="flex-end">
								<Grid item>
									<Checkbox
										color="primary"
										onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
											setShowHeatMap(checked)
										}
									/>
								</Grid>
								<Grid item>
									<Typography variant="body2" color="inherit">
										{t('showHeatMap')}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<MapContainer
							center={trailMapCenter}
							height={'58vh'}
							zoom={10}
							sidebar={
								<Sidebar
									id="sidebar"
									collapsed={sidebarColabsed}
									selected={sidebarSelected}
									onOpen={(id) => onOpen(id)}
									onClose={onClose}
									position={'right'}
									closeIcon={<ArrowForwardIosIcon />}
									// ref={stageCanvasRef}
								>
									<SidebarTab id="telemetries" header={t('telemetries')} icon={<TimelineIcon />}>
										{/* <InputField
										autoFocus
										label={'search'}
										margin="normal"
										name={'search placements'}
										onChange={(event: ChangeEvent<HTMLInputElement>) =>
											setPlacementsSearchKey(event.target.value)
										}
									/> */}
										<List
											id="main-menu"
											style={{
												height: '47vh',
												overflow: 'auto',
											}}
										>
											{trailPoints
												// ?.filter((telemetry) => {
												// 	const key = placementsSearchKey?.toLowerCase();

												// 	return (
												// 		!key ||
												// 		placement.placementDescription?.title1
												// 			.toLowerCase()
												// 			.includes(key) ||
												// 		placement.placementDescription?.subTitle1
												// 			.toLowerCase()
												// 			.includes(key)
												// 	);
												// })
												.map((telemetry) => {
													// const attachedDeviceId = placement.placementRelations.find(
													// 	(relation) => relation.detached === null,
													// )?.deviceId;

													// const attachedDevice = devices.find(
													// 	(device) => device.id === attachedDeviceId,
													// );

													return (
														<ListItem
															// disabled={
															// 	attachedDevice === undefined ||
															// 	attachedDevice.data === undefined ||
															// 	attachedDevice.data === null
															// }
															key={telemetry.time}
															button
															onClick={() => {
																handleClickTelemetry(telemetry);
															}}
															// selected={
															// 	attachedDevice && attachedDeviceId === selectedItem
															// }
														>
															<ListItemIcon>
																<TimelineIcon />
															</ListItemIcon>
															<ListItemText
																primary={
																	telemetry.time
																		? format(
																				new Date(telemetry.time),
																				INPUT_DATE_TIME_MASK,
																		  )
																		: '-'
																}
																secondary={telemetry.action}
															/>
														</ListItem>
													);
												})}
										</List>
									</SidebarTab>
									<SidebarTab id="settings" header="Settings" icon={<SettingsIcon />} anchor="bottom">
										<p>Settings dialogue.</p>
									</SidebarTab>
								</Sidebar>
							}
							// onZoomed={(zoom) => handleZoomChange(zoom, groupedTelemetry.filters)}
						>
							<FeatureGroup>
								{inDoorMap && (
									<GeoJSON key={inDoorMap.key} data={inDoorMap.image} onEachFeature={onEachFeature} />
								)}
							</FeatureGroup>
							{showHeatMap && (
								<HeatmapLayer
									points={trailPoints}
									latitudeExtractor={(coords: Telemetry) => coords?.lat}
									longitudeExtractor={(coords: Telemetry) => coords?.lng}
									intensityExtractor={(coords: Telemetry) => 10}
									radius={30}
									max={16}
								/>
							)}
							{!showHeatMap &&
								trailLines.map((line) => {
									return (
										line && (
											<Polyline positions={line.positions} color={line.color} key={line.color} />
										)
									);
								})}
							{!showHeatMap &&
								trailPoints.map(({ location_update_details, lat, lng, time }) => {
									// const [lat, lng] = location;
									if (lat && lng) {
										return (
											<MapMarker key={`${time}-${lat}-${lng}`} type="point" position={[lat, lng]}>
												<MapPopup title={`${t('sn')}: ${worker?.placementDescription?.title1}`}>
													<List dense disablePadding>
														<ListItem key="location" disableGutters>
															{`${t('location')}: ${lat}, ${lng}`}
														</ListItem>
														<ListItem key="locationUpdateDetails" disableGutters>
															{`${t(
																'locationUpdateDetails',
															)}: ${location_update_details}`}
														</ListItem>
														{time && (
															<ListItem key="time" disableGutters>
																{`${t('time')}: `}
																{format(new Date(time), INPUT_DATE_TIME_MASK)}
															</ListItem>
														)}
													</List>
												</MapPopup>
											</MapMarker>
										);
									}

									return undefined;
								})}
						</MapContainer>
					</Grid>
				</Grid>
			</TabPanel>
			<TabPanel value={tab} index={3}>
				<Grid container spacing={3} className={classes.marginTop2}>
					<Grid item xs={12} sm={12} lg={12}>
						<Card>
							<CardHeader title={'WorkerCare App'} subheader={'version 0.1.0'} action={<></>} />
						</Card>
					</Grid>
				</Grid>
			</TabPanel>
			<TabPanel value={tab} index={4}>
				<Grid container spacing={3} className={classes.marginTop2}>
					<Grid item xs={12} sm={12} lg={12}>
						<Card>
							<CardHeader title={t('attachedDevices')} />
							<CardContent>
								{worker && <AttachedDevicesList data={worker.placementRelations} history={history} />}
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</TabPanel>
		</>
	);
};

import { apiClient } from '../client';
import {
	EditDotNetIngestionEndpointRequestBody,
	CreateDotNetIngestionEndpointRequestBody,
	DotNetIngestionEndpoint,
	DotNetIngestionEndpointResponseData,
} from '../../pages/IngestionEndpoints/ingestion-endpoints-types';

import { FiltersRequestBody2 } from '../../components/Table/types';
import { UUID } from '../../types';
import {
	IngestionScript,
	IngestionScriptRequestBody,
	IngestionScriptsResponseData,
	TestIngestionScriptRequestBody,
	ValidateIngestionScriptResponse,
} from '../../pages/IngestionScripts/ingestion-scripts-types';

const baseUrlForDotNet = '/ingest.net';

export const getFilteredIngestionScripts = (body: FiltersRequestBody2) =>
	apiClient.post<IngestionScriptsResponseData>(`${baseUrlForDotNet}/script/filter`, body);

export const createIngestionScript = (body: IngestionScriptRequestBody) =>
	apiClient.post<IngestionScript>(`${baseUrlForDotNet}/script`, body);

export const editIngestionScript = (body: IngestionScriptRequestBody, scriptId: UUID) =>
	apiClient.put<IngestionScript>(`${baseUrlForDotNet}/script/${scriptId}`, body);

export const deleteIngestionScript = (scriptId: UUID) => apiClient.delete(`${baseUrlForDotNet}/script/${scriptId}`);

//

export const validateIngestionScript = (body: IngestionScriptRequestBody) =>
	apiClient.post<ValidateIngestionScriptResponse>(`${baseUrlForDotNet}/script/compile`, body);

export const testIngestionScript = (body: TestIngestionScriptRequestBody) =>
	apiClient.post<ValidateIngestionScriptResponse>(`${baseUrlForDotNet}/script/test`, body);

//

export const getFilteredDotNetIngestionEndpoints = (body: FiltersRequestBody2) =>
	apiClient.post<DotNetIngestionEndpointResponseData>(`${baseUrlForDotNet}/management/filter`, body);

export const editDotNetIngestionEndpoint = (endpoint: EditDotNetIngestionEndpointRequestBody, endpointId: UUID) =>
	apiClient.put<DotNetIngestionEndpoint>(`${baseUrlForDotNet}/management/${endpointId}`, endpoint);

export const createDotNetIngestionEndpoint = (endpoint: CreateDotNetIngestionEndpointRequestBody) =>
	apiClient.post<DotNetIngestionEndpoint>(`${baseUrlForDotNet}/management`, endpoint);

export const deleteDotNetIngestionEndpoint = (endpointId: UUID) =>
	apiClient.delete(`${baseUrlForDotNet}/management/${endpointId}`);

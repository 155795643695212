import { PlacementGroupsState, PlacementGroupsActions } from './placement-groups-types';
import { createReducer } from 'typesafe-actions';
import {
	GENERAL_PLACEMENT_GROUPS_REQUEST,
	GENERAL_PLACEMENT_GROUPS_ERROR,
	FETCH_PLACEMENT_GROUPS_SUCCESS,
	CREATE_PLACEMENT_GROUP_SUCCESS,
	UPDATE_PLACEMENT_GROUP_SUCCESS,
	DELETE_PLACEMENT_GROUP_SUCCESS,
	GET_PLACEMENT_GROUP_DETAIL,
} from './placement-groups-constants';

import { set } from 'lodash/fp';

const groupsInitialState: PlacementGroupsState = {
	data: [],
	total: 0,
	offset: 0,
	limit: 10,
	orderings: [],
	filtersAndConditions: [],
	groupDetail: null,
};

export const placementGroupsReducer = createReducer<PlacementGroupsState, PlacementGroupsActions>(groupsInitialState, {
	[GENERAL_PLACEMENT_GROUPS_REQUEST]: (state) => state,
	[GENERAL_PLACEMENT_GROUPS_ERROR]: (state) => state,
	[FETCH_PLACEMENT_GROUPS_SUCCESS]: (state, action) => {
		return {
			...state,
			data: action.payload.data.data,
			total: action.payload.data.total,
			limit: action.payload.options ? action.payload.options.limit : state.limit,
			offset: action.payload.options ? action.payload.options.offset : state.offset,
			orderings: action.payload.options?.orderings || state.orderings,
			filtersAndConditions: action.payload.options?.filtersAndConditions || state.filtersAndConditions,
		};
	},
	[CREATE_PLACEMENT_GROUP_SUCCESS]: (state) => state,
	[UPDATE_PLACEMENT_GROUP_SUCCESS]: (state, action) => {
		const { data } = action.payload;
		const groupIndex = state.data.findIndex((group) => group.id === data.id);

		return set(`data[${groupIndex}]`, data, state);
	},
	[DELETE_PLACEMENT_GROUP_SUCCESS]: (state, action) => {
		const newData = state.data.filter((group) => group.id !== action.payload.placementGroupId);

		return {
			...state,
			data: newData,
			total: newData.length,
		};
	},
	[GET_PLACEMENT_GROUP_DETAIL]: (state, action) => set('groupDetail', action.payload.data, state),
});

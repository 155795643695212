export const CONTACTS_COMMON_EMPTY_REQUEST = '[CONTACTS_ACTIONS] CONTACTS_COMMON_EMPTY_REQUEST';
export const CONTACTS_COMMON_EMPTY_ERROR = '[CONTACTS_ACTIONS] CONTACTS_COMMON_EMPTY_ERROR';

export const FETCH_CONTACTS_SUCCESS = '[CONTACTS_ACTIONS] FETCH_CONTACTS_SUCCESS';

export const CREATE_CONTACT_SUCCESS = '[CONTACTS_ACTIONS] CREATE_CONTACT_SUCCESS';

export const EDIT_CONTACT_SUCCESS = '[CONTACTS_ACTIONS] EDIT_CONTACT_SUCCESS';

export const DELETE_CONTACT_SUCCESS = '[CONTACTS_ACTIONS] DELETE_CONTACT_SUCCESS';

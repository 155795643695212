import { Grid, Paper } from '@material-ui/core';
import React from 'react';
import { WorkersTable } from './components/WorkersTable';

export const ProjectBased = true;

export const WorkerCare = () => {
	return (
		<Grid container spacing={2} justify="center">
			<Grid item xs={12} lg={10}>
				<Paper>
					<WorkersTable />
				</Paper>
			</Grid>
		</Grid>
	);
};

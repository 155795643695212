import React from 'react';

type Props = {
	fill: string;
};

export const InTripIcon = ({ fill }: Props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill={fill} viewBox="0 0 28 28">
			<circle cx="14" cy="14" r="14" fill="#777" />
			<circle cx="14" cy="14" r="11" fill={fill} />
		</svg>
	);
};

import React, { ChangeEvent, useEffect, useState } from 'react';
import { Form } from '../../../components/Form/Form';
import { useTranslation } from 'react-i18next';
import {
	Grid,
	Divider,
	Select,
	MenuItem,
	makeStyles,
	Theme,
	FormControl,
	InputLabel,
	TextField,
} from '@material-ui/core';
import { Button } from '../../../components/Button/Button';
import SaveIcon from '@material-ui/icons/Save';
import { ModalDialogFormActions } from '../../../components/ModalDialog/ModalDialogFormActions';
import { DateTimePicker } from '../../../components/DatePicker/DateTimePicker';
import { InputSelectUncontrolled } from '../../../components/Form/InputSelectUncontrolled';
import { Parameter, ParameterDefinition, ParameterType, ReviewDefinition, ReviewDefinitionName } from '../review-types';
import { Nullable, UUID } from '../../../types';
import { fetchRequest } from '../../../services/helpers';
import { getReviewDefinition } from '../../../services/review/review-service';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/types';
import { ZoneResponseData, ZonesResponseData } from '../../Zones/zones-types';
import { UserState } from '../../../store/user/user-types';
import { getFilteredZones2 } from '../../../services/zones/zones-service';
//import { getFilteredGroups } from '../../../services/groups/groups-service';
//import { GroupResponseData, GroupsResponseData } from '../../Groups/groups-types';
import { InputField } from '../../../components/Form/InputField';
import { DatePicker } from '../../../components/DatePicker/DatePicker';
import { Autocomplete } from '@material-ui/lab';
import { FiltersRequestBody2 } from '../../../components/Table/types';
import { getFilteredPlacementGroups } from '../../../services/placement-groups/placement-groups-service';
import { GroupResponseData, GroupsResponseData } from '../../PlacementGroups/placement-groups-types';

const useStyles = makeStyles(({ spacing }: Theme) => ({
	select: {
		'& .MuiFormLabel-root': {
			width: '90%',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			paddingRight: spacing(2),

			'&.Mui-focused': {
				width: '100%',
				textOverflow: 'initial',
			},
		},
	},
}));

export type ReportOrderFormValues = {
	userId: UUID;
	name: string;
	placementGroups: { id: UUID }[];
	projectId?: UUID;
	reviewDefinitionId: UUID;
	parameters: Parameter[];
};

type Props = {
	reviewDefinitionNames: ReviewDefinitionName[];
	onSubmit: (values: ReportOrderFormValues) => void;
	onCancel: () => void;
};

interface Value {
	[key: string]: { name: string; type: ParameterType; value?: number | string | string[]; error: boolean };
}

export const CreateReportOrderForm = ({ reviewDefinitionNames, onSubmit, onCancel }: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const [isStandingOrder] = useState(false);

	const [reviewDefinitionId, setReviewDefinitionId] = useState<UUID>();

	const [reviewDefinition, setReviewDefinition] = useState<ReviewDefinition>();
	const [reviewDefinitionError, setReviewDefinitionError] = useState<boolean>(false);

	const { project, userId } = useSelector<RootState, UserState>((state) => state.user);

	const [name, setName] = useState<string>('');
	const [nameError, setNameError] = useState<boolean>(false);

	const [zones, setZones] = useState<ZoneResponseData[]>();

	const [groups, setGroups] = useState<GroupResponseData[]>([]);

	const [placementGroupIds, setPlacementGroupIds] = useState<UUID[]>([]);
	const [placementGroupIdsError, setPlacementGroupIdsError] = useState<boolean>(false);

	const [values, setValues] = useState<Value>({});

	const handleSetValues = (
		name: string,
		type: ParameterType,
		value: number | string | string[] | undefined,
		error?: boolean,
	) => {
		values[name] = { name, type, value, error: error !== undefined && error };

		setValues({ ...values });
	};

	/**
	 * Fetching all groups
	 */
	useEffect(() => {
		if (project?.id) {
			const filterBody: FiltersRequestBody2 = {
				limit: 500,
				offset: 0,
				orderings: [],
				filtersAndConditions: [],
			};

			const request = getFilteredPlacementGroups(filterBody);

			(async () => {
				const { data, error } = await fetchRequest<GroupsResponseData>(request);

				if (error) {
					// TODO show error
				} else {
					setGroups(data?.data ?? []);
				}
			})();
		}

		// Don't need to react on "config" and "filters" and deep comparision is not necessary
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [project]);

	/**
	 * Fetching all zones
	 */
	useEffect(() => {
		if (project?.id) {
			const request = getFilteredZones2({
				// Should be enough to get all zones, even for super admin
				limit: 200,
				offset: 0,
				orderings: [],
				filtersAndConditions: [],
			});

			(async () => {
				const { data, error } = await fetchRequest<ZonesResponseData>(request);

				if (error) {
					// TODO show error
				} else {
					setZones(data?.data);
				}
			})();
		}
	}, [project]);

	useEffect(() => {
		if (reviewDefinitionId) {
			const request = getReviewDefinition(reviewDefinitionId);

			(async () => {
				// setLoading(true);

				const { data, error } = await fetchRequest<ReviewDefinition>(request);

				if (error) {
					// TODO show error
				} else {
					// console.log(data);
					data && setReviewDefinition(data);
				}
			})();
		}
		//// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reviewDefinitionId]);

	const handleSetName = (event: ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value);
		setNameError(false);
	};

	const getDateValue = (name: string): Nullable<Date> => {
		const param = values[name];

		if (param) {
			console.log(param.value);

			return new Date(param.value as string);
		}

		return null;
	};

	const handleSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
		let valid = true;

		if (name === '') {
			valid = false;
			setNameError(true);
		}

		if (placementGroupIds === undefined || placementGroupIds.length < 1) {
			valid = false;
			setPlacementGroupIdsError(true);
		}

		if (reviewDefinitionId === undefined) {
			valid = false;
			setReviewDefinitionError(true);
		} else {
			const newValues: Value = {};
			// let valuesValid = true;

			reviewDefinition?.parameterDefinitions.forEach((definition) => {
				const value = values[definition.name];

				let valueNotValid = false;

				if (value?.value === undefined || value.value === '') {
					valueNotValid = true;
					valid = false;
				}

				newValues[definition.name] = {
					name: definition.name,
					type: definition.type,
					value: value?.value,
					error: valueNotValid,
				};
			});

			// if (valuesValid === false) {
			// valid = false;
			setValues(newValues);
			// }
		}

		if (valid && reviewDefinitionId) {
			onSubmit({
				userId: userId,
				name: name,
				reviewDefinitionId: reviewDefinitionId,
				projectId: project?.id,
				placementGroups: placementGroupIds.map((placementGroupId) => ({
					id: placementGroupId,
				})),
				parameters: Object.values(values).map(({ name, type, value }) => ({
					name: name,
					type: type,
					value: value,
				})),
			});
		}

		event.preventDefault();
	};

	const parameterComponent = (parameter: ParameterDefinition) => {
		const actualValue = values[parameter.name];

		switch (parameter.type) {
			case ParameterType.guid:
				return <>Guid TODO</>;
			case ParameterType.string:
				return <>String TODO</>;
			case ParameterType.integer:
				return <>Integer TODO</>;
			case ParameterType.dateTime:
				return (
					<DateTimePicker
						label={parameter.name}
						disableFuture
						maxDate={new Date()}
						id={parameter.name}
						value={getDateValue(parameter.name)}
						error={actualValue?.error}
						onChange={(date) => handleSetValues(parameter.name, parameter.type, date?.toISOString())}
						fullWidth
					/>
				);
			case ParameterType.timeSpan:
				return <>TimeSpan TODO</>;
			case ParameterType.date:
				return (
					<DatePicker
						label={parameter.name}
						disableFuture
						maxDate={new Date()}
						id={parameter.name}
						value={getDateValue(parameter.name)}
						error={actualValue?.error}
						onChange={(date) => handleSetValues(parameter.name, parameter.type, date?.toISOString())}
						fullWidth
					/>
				);
			case ParameterType.zoneArray:
				return (
					<Select
						defaultValue={[]}
						multiple
						name={parameter.name}
						className={classes.select}
						variant="outlined"
						error={actualValue?.error}
						fullWidth
						disabled={zones === undefined}
						onChange={(
							event: React.ChangeEvent<{
								name?: string | undefined;
								value: unknown;
							}>,
							child: React.ReactNode,
						) => handleSetValues(parameter.name, parameter.type, event.target.value as string[])}
					>
						{zones?.map((zone) => (
							<MenuItem key={zone.id} value={zone.id}>
								{zone.name}
							</MenuItem>
						))}
					</Select>
				);
			default:
				return <>Unknown</>;
		}
	};

	return (
		<Form onSubmit={handleSubmitForm} testId="createReportOrderForm">
			<FormControl fullWidth margin="normal" variant="outlined">
				<InputField
					autoFocus
					error={nameError}
					helperText={nameError && t('validationErrors.required')}
					label={t('name')}
					name="name"
					onChange={handleSetName}
				/>
			</FormControl>
			{/* <Grid item>
						<Checkbox
							checked={isStandingOrder}
							disabled
							color="primary"
							name={`standingReportOrder`}
							onChange={(event) => setStandingOrder(event.target.checked)}
						/>
					</Grid>
					<Grid item>
						<Typography variant="subtitle1" gutterBottom>
							{t('standingReportOrder')}
						</Typography>
					</Grid> */}
			{isStandingOrder && (
				<Grid container direction="column" spacing={2}>
					<Grid item>
						<DateTimePicker
							disableFuture
							maxDate={new Date()}
							id="dateFrom"
							value={undefined}
							// eslint-disable-next-line @typescript-eslint/no-empty-function
							onChange={(date) => {}}
						/>
					</Grid>

					<Grid item>
						<InputSelectUncontrolled
							// eslint-disable-next-line @typescript-eslint/no-empty-function
							onChanged={(value) => {}}
							error={false}
							options={[
								{ id: 'daily', label: 'daily' },
								{ id: 'monthly', label: 'monthly' },
							]}
							label={t('periodicity')}
						/>
					</Grid>

					<Grid item>
						<Divider />
					</Grid>
				</Grid>
			)}
			<FormControl fullWidth margin="normal" variant="outlined" color="primary">
				<Autocomplete
					className={classes.select}
					color="primary"
					multiple
					options={groups}
					getOptionLabel={(option) => option.name}
					defaultValue={[]}
					filterSelectedOptions
					// disableCloseOnSelect
					fullWidth
					renderInput={(params) => (
						<TextField
							{...params}
							label={t('selectGroup')}
							// placeholder="Favorites"
							error={placementGroupIdsError}
							helperText={placementGroupIdsError && t('validationErrors.required')}
							variant="outlined"
						/>
					)}
					disabled={groups === undefined}
					onChange={(event: unknown, newValue: GroupResponseData[]) => {
						setPlacementGroupIds(newValue.map((value) => value.id));
						setPlacementGroupIdsError(false);
					}}
					// renderValue={(selected) => (
					// 	<Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
					// 		{(selected as string[]).map((value) => (
					// 			<Chip key={value} label={value} />
					// 		))}
					// 	</Box>
					// )}
				/>
			</FormControl>

			<Divider />

			<FormControl fullWidth margin="normal" variant="outlined">
				<InputLabel id="reviewDefinition-label">{t('reportType')}</InputLabel>
				<Select
					label={t('reportType')}
					labelId="reviewDefinition-label"
					name="reviewDefinitionNames"
					className={classes.select}
					variant="outlined"
					error={reviewDefinitionError}
					fullWidth
					onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
						setValues({});
						setReviewDefinitionId(event.target.value as string);
						setReviewDefinitionError(false);
					}}
				>
					{reviewDefinitionNames.map((reviewDefinitionName) => (
						<MenuItem key={reviewDefinitionName.id} value={reviewDefinitionName.id}>
							{reviewDefinitionName.name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			{reviewDefinition && (
				<>
					<Grid item>{reviewDefinition.formula}</Grid>
					{reviewDefinition.parameterDefinitions.map((parameterDefinition) => {
						return (
							<FormControl key={parameterDefinition.name} fullWidth margin="normal" variant="outlined">
								{parameterComponent(parameterDefinition)}
							</FormControl>
						);
					})}
				</>
			)}
			<ModalDialogFormActions>
				<Button startIcon={<SaveIcon />} type="submit" data-cy="btn-submit:createReportOrderForm">
					{t('save')}
				</Button>
				<Button onClick={onCancel} variant="text" data-cy="btn-cancel:createReportOrderForm">
					{t('cancel')}
				</Button>
			</ModalDialogFormActions>
		</Form>
	);
};

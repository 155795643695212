import { GeoJSON } from 'geojson';
import { apiClient } from '../client';
import { FiltersRequestBody, FiltersRequestBody2 } from '../../components/Table/types';
import { UUID } from '../../types';
import {
	ZonesResponseData,
	ZoneResponseData,
	CreateZonePayload,
	EditZoneRequestBody,
} from '../../pages/Zones/zones-types';

const baseUrl = '/zone-manager';
const baseUrlForDotNet = '/zone-manager.net';

export const getFilteredZones = (body: FiltersRequestBody) =>
	apiClient.post<ZonesResponseData>(`${baseUrl}/filter`, body);

export const getFilteredZones2 = (body: FiltersRequestBody2) =>
	apiClient.post<ZonesResponseData>(`${baseUrlForDotNet}/filter`, body);

export const createZone = (body: CreateZonePayload) => apiClient.post<ZoneResponseData>(baseUrlForDotNet, body);
export const editZone = (body: EditZoneRequestBody, zoneId: UUID) =>
	apiClient.put<ZoneResponseData>(`${baseUrlForDotNet}/${zoneId}`, body);

export const deleteZone = (zoneId: UUID) => apiClient.delete(`${baseUrlForDotNet}/${zoneId}`);
export const uploadZones = (body: GeoJSON) => apiClient.post<ZoneResponseData>(`${baseUrlForDotNet}/geojson`, body);

import React, { useState, ChangeEvent, FocusEvent } from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Controller, Control } from 'react-hook-form';
import { FieldName } from 'react-hook-form/dist/types/form';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

type InputFieldProps<T> = TextFieldProps & {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	control?: Control<any>;
	InputProps?: Partial<OutlinedInputProps>;
	name: FieldName<T>;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
};

export const InputField = <T extends Record<string, unknown>>({
	autoFocus = false,
	// https://developers.google.com/web/fundamentals/design-and-ux/input/forms/#recommended_input_name_and_autocomplete_attribute_values
	autoComplete,
	className,
	control,
	defaultValue,
	disabled,
	error,
	fullWidth = true,
	helperText,
	id,
	inputProps,
	InputProps,
	label,
	margin = 'normal',
	name,
	onBlur,
	onChange,
	required = true,
	size = 'medium',
	type = 'text',
	value,
}: InputFieldProps<T>) => {
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword((prevShowPassword: boolean) => !prevShowPassword);

	const passwordAdornment = {
		endAdornment: (
			<InputAdornment position="end">
				<IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
					{showPassword ? <Visibility /> : <VisibilityOff />}
				</IconButton>
			</InputAdornment>
		),
	};

	const searchAdornment = {
		startAdornment: (
			<InputAdornment position="start">
				<SearchRoundedIcon />
			</InputAdornment>
		),
	};

	const inputPropsMap = new Map<string, Record<string, JSX.Element>>([
		['search', searchAdornment],
		['password', passwordAdornment],
	]);

	const TextFieldProps = { ...inputPropsMap.get(type), ...InputProps };

	return control ? (
		<Controller
			render={(props) => (
				<TextField
					{...props}
					autoComplete={autoComplete}
					autoFocus={autoFocus}
					className={className}
					disabled={disabled}
					error={error}
					fullWidth={fullWidth}
					helperText={helperText}
					id={id || name}
					inputProps={inputProps}
					InputProps={TextFieldProps}
					label={label}
					margin={margin}
					required={required}
					size={size}
					type={showPassword ? 'text' : type}
					variant="outlined"
				/>
			)}
			control={control}
			defaultValue={defaultValue}
			name={name}
		/>
	) : (
		<TextField
			autoComplete={autoComplete}
			autoFocus={autoFocus}
			className={className}
			defaultValue={defaultValue}
			disabled={disabled}
			error={error}
			fullWidth={fullWidth}
			helperText={helperText}
			id={id || name}
			inputProps={inputProps}
			InputProps={TextFieldProps}
			label={label}
			margin={margin}
			name={name}
			onBlur={onBlur}
			onChange={onChange}
			required={required}
			size={size}
			type={showPassword ? 'text' : type}
			variant="outlined"
			value={value}
		/>
	);
};

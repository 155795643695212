import { apiClient } from '../client';
import { UUID } from '../../types';
import {
	ProjectsResponseData,
	ProjectResponseData,
	CreateProjectRequestBody,
	EditProjectRequestBody,
} from '../../pages/Projects/projects-types';
import { FiltersRequestBody2 } from '../../components/Table/types';

const baseUrlForDotNet = '/identity.net/projects';

export const getProjects = (body: FiltersRequestBody2) =>
	apiClient.post<ProjectsResponseData>(`${baseUrlForDotNet}/filter`, body);

export const createProject = (body: CreateProjectRequestBody) =>
	apiClient.post<ProjectResponseData>(baseUrlForDotNet, body);

export const editProject = (body: EditProjectRequestBody, projectId: UUID) =>
	apiClient.put<ProjectResponseData>(`${baseUrlForDotNet}/${projectId}`, body);

export const deleteProject = (projectId: UUID) => apiClient.delete(`${baseUrlForDotNet}/${projectId}`);
export const getProjectInfo = (projectId: UUID) => apiClient.get(`${baseUrlForDotNet}/${projectId}/info`);

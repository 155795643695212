export const TENANTS_COMMON_EMPTY_REQUEST = '[TENANTS_ACTIONS] TENANTS_COMMON_EMPTY_REQUEST';
export const TENANTS_COMMON_EMPTY_ERROR = '[TENANTS_ACTIONS] TENANTS_COMMON_EMPTY_ERROR';

export const FETCH_TENANTS_SUCCESS = '[TENANTS_ACTIONS] FETCH_TENANTS_SUCCESS';

export const CREATE_TENANT_SUCCESS = '[TENANTS_ACTIONS] CREATE_TENANT_SUCCESS';

export const EDIT_TENANT_SUCCESS = '[TENANTS_ACTIONS] EDIT_TENANT_SUCCESS';

export const DELETE_TENANT_SUCCESS = '[TENANTS_ACTIONS] DELETE_TENANT_SUCCESS';

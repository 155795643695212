import React from 'react';

type Props = {
	fill: string;
};

export const MarkerZoneIcon = ({ fill }: Props) => {
	return (
		<svg width="48" height="48" viewBox="0 0 48 48" fill={fill} xmlns="http://www.w3.org/2000/svg">
			<path d="M24 4C16.26 4 10 10.26 10 18C10 28.5 24 44 24 44C24 44 38 28.5 38 18C38 10.26 31.74 4 24 4Z" />
			<circle cx="24" cy="18" r="11" fill="white" />
			<path d="M19.2 23.6C20.9673 23.6 22.4 22.1673 22.4 20.4C22.4 18.6326 20.9673 17.2 19.2 17.2C17.4327 17.2 16 18.6326 16 20.4C16 22.1673 17.4327 23.6 19.2 23.6Z" />
			<path d="M26.8 26C27.9046 26 28.8 25.1046 28.8 24C28.8 22.8954 27.9046 22 26.8 22C25.6954 22 24.8 22.8954 24.8 24C24.8 25.1046 25.6954 26 26.8 26Z" />
			<path d="M27.2 19.6C29.851 19.6 32 17.451 32 14.8C32 12.149 29.851 10 27.2 10C24.549 10 22.4 12.149 22.4 14.8C22.4 17.451 24.549 19.6 27.2 19.6Z" />
		</svg>
	);
};

import {
	fetchGroupsSuccess,
	fetchGroupsRequest,
	fetchGroupsError,
	createGroupSuccess,
	createGroupRequest,
	createGroupError,
	deleteGroupRequest,
	deleteGroupSuccess,
	deleteGroupError,
	updateGroupSuccess,
	updateGroupError,
	updateGroupRequest,
} from './groups-actions';

import { AsyncAction } from '../types';
import { UUID } from '../../types';
import { addToast } from '../app/app-actions';
import { fetchRequest, createToastError, isStatusSuccess } from '../../services/helpers';
import * as groupsService from '../../services/groups/groups-service';
import {
	CreateGroupPayload,
	GroupResponseData,
	UpdateGroupPayload,
	GroupsResponseData,
	UpdateGroupResponseData,
} from '../../pages/Groups/groups-types';

import { omit } from 'lodash/fp';
import { FiltersRequestBody2 } from '../../components/Table/types';

export const fetchGroups: AsyncAction<FiltersRequestBody2> = (groupsPayload) => async (dispatch) => {
	dispatch(fetchGroupsRequest());

	const request = groupsService.getFilteredGroups(groupsPayload);
	const { data, error } = await fetchRequest<GroupsResponseData>(request);

	if (data) {
		dispatch(fetchGroupsSuccess(data, groupsPayload));
	} else {
		dispatch(fetchGroupsError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(fetchGroups(groupsPayload));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const createGroup: AsyncAction<CreateGroupPayload> = (createGroupPayload) => async (dispatch, getState) => {
	dispatch(createGroupRequest());

	const { groups } = getState();
	const request = groupsService.createGroup(createGroupPayload);
	const { data, status, error } = await fetchRequest<GroupResponseData>(request);

	if (isStatusSuccess(status) && data) {
		dispatch(createGroupSuccess());
		dispatch(
			fetchGroups({
				limit: groups.limit,
				offset: groups.offset,
				orderings: groups.orderings,
				filtersAndConditions: groups.filtersAndConditions,
			}),
		);
	} else {
		dispatch(createGroupError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(createGroup(createGroupPayload));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const updateGroup: AsyncAction<UpdateGroupPayload> = (updateGroupPayload) => async (dispatch, getState) => {
	dispatch(updateGroupRequest());

	const body = omit(['id'], updateGroupPayload);
	const request = groupsService.updateGroup(body, updateGroupPayload.id);
	const { data, error } = await fetchRequest<UpdateGroupResponseData>(request);
	// const { devicesToUpdate, devicesToRemove } = updateGroupPayload;
	// const devicesUpdated = devicesToUpdate || devicesToRemove;

	if (data) {
		dispatch(updateGroupSuccess(data));
	} else {
		dispatch(updateGroupError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(updateGroup(updateGroupPayload));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const deleteGroup: AsyncAction<UUID> = (groupId) => async (dispatch) => {
	dispatch(deleteGroupRequest());

	const request = groupsService.deleteGroup(groupId);
	const { status, error } = await fetchRequest<void>(request);

	if (isStatusSuccess(status) && groupId) {
		dispatch(deleteGroupSuccess(groupId));
	} else {
		dispatch(deleteGroupError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(deleteGroup(groupId));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

import { IconButton, Tooltip } from '@material-ui/core';
// import { trim } from 'lodash/fp';
import React from 'react';
// import { Nullable } from '../../types';
import { CloudUploadRounded } from '@material-ui/icons';
import { Nullable } from '../../types';

type Props = {
	onChangeCallback: (file: ArrayBuffer) => void;
	title: string;
};

export const UploadDevices = ({ onChangeCallback, title }: Props) => {
	const handleInputChange = (files: Nullable<FileList>) => {
		if (files && files.length === 1) {
			(async () => {
				const arrayBuffer = await files[0].arrayBuffer();

				onChangeCallback(arrayBuffer);
			})();
		}
	};

	return (
		<span>
			<input
				id="uploadFile"
				name="uploadFile"
				type="file"
				accept=".xlsx, .xls, .csv"
				onChange={(event) => handleInputChange(event.target.files)}
				style={{ display: 'none' }}
			/>
			<label htmlFor="uploadFile">
				<Tooltip title={title}>
					<IconButton component="span" data-cy="btn-upload:devices">
						<CloudUploadRounded />
					</IconButton>
				</Tooltip>
			</label>
		</span>
	);
};

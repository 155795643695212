import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { LatLngTuple } from 'leaflet';
import { usePrevious } from '../../hooks/usePrevious';
import { getBoundsFromMultipleZones, getBoundsFromSingleZone } from './helpers';
import { Zone } from './zones-types';

export const useZonesBounds = (zones: Zone[] | undefined) => {
	const bounds = zones
		? zones.length > 1
			? getBoundsFromMultipleZones(zones)
			: getBoundsFromSingleZone(zones)
		: undefined;

	const [mapBounds, setMapBounds] = useState<LatLngTuple[] | undefined>(bounds);
	const prevBounds = usePrevious(bounds);
	const hasBoundsChanged = !isEqual(prevBounds, bounds);

	useEffect(() => {
		if (hasBoundsChanged && bounds && bounds.length) {
			setMapBounds(bounds);
		}
	}, [bounds, hasBoundsChanged]);

	return { mapBounds, setMapBounds };
};

import { createReducer } from 'typesafe-actions';
import {
	PIPELINES_COMMON_EMPTY_REQUEST,
	PIPELINES_COMMON_EMPTY_ERROR,
	FETCH_PIPELINES_SUCCESS,
	CREATE_PIPELINE_SUCCESS,
	EDIT_PIPELINE_SUCCESS,
	DELETE_PIPELINE_SUCCESS,
	FETCH_JOBS_SUCCESS,
} from './pipelines-constants';

import { PipelinesActions, PipelinesState } from './pipelines-types';
import { updateObjectInArray } from '../../helpers/array';

export const pipelinesInitialState: PipelinesState = {
	data: [],
	total: 0,
	limit: 10,
	offset: 0,
	orderings: [],
	filtersAndConditions: [],
	jobs: {
		data: [],
		total: 0,
		offset: 0,
		limit: 10,
		orderings: [],
		filtersAndConditions: [],
	},
};

export const pipelinesReducer = createReducer<PipelinesState, PipelinesActions>(pipelinesInitialState, {
	[PIPELINES_COMMON_EMPTY_REQUEST]: (state) => state,
	[PIPELINES_COMMON_EMPTY_ERROR]: (state) => state,
	[FETCH_PIPELINES_SUCCESS]: (state, action) => ({
		...state,
		data: action.payload.data ? action.payload.data.data : state.data,
		total: action.payload.data ? action.payload.data.total : state.total,
		limit: action.payload.options ? action.payload.options.limit : state.limit,
		offset: action.payload.options ? action.payload.options.offset : state.offset,
		orderings: action.payload.options?.orderings,
		filtersAndConditions: action.payload.options?.filtersAndConditions,
	}),
	[CREATE_PIPELINE_SUCCESS]: (state, action) => {
		const { data } = action.payload;
		const pipelineIndex = state.data.findIndex((pipeline) => pipeline.id === data.id);

		return {
			...state,
			data: updateObjectInArray(data, pipelineIndex, state.data),
		};
	},
	[EDIT_PIPELINE_SUCCESS]: (state, action) => {
		const { data } = action.payload;
		const pipelineIndex = state.data.findIndex((pipeline) => pipeline.id === data.id);

		return {
			...state,
			data: updateObjectInArray(data, pipelineIndex, state.data),
		};
	},
	[DELETE_PIPELINE_SUCCESS]: (state, action) => {
		const newData = state.data.filter((pipeline) => pipeline.id !== action.payload.id);

		return {
			...state,
			data: newData,
			total: newData.length,
		};
	},
	[FETCH_JOBS_SUCCESS]: (state, action) => {
		return {
			...state,
			jobs: {
				data: action.payload.data.data,
				total: action.payload.data.total,
				limit: action.payload.options ? action.payload.options.limit : state.jobs.limit,
				offset: action.payload.options ? action.payload.options.offset : state.jobs.offset,
				orderings: action.payload.options?.orderings || state.jobs.orderings,
				filtersAndConditions: action.payload.options?.filtersAndConditions || state.jobs.filtersAndConditions,
			},
		};
	},
});

import React, { useEffect } from 'react';
import {
	Card,
	CardHeader,
	Checkbox,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	Theme,
	makeStyles,
	ListItemText,
	Grid,
	Typography,
	TablePagination,
	useMediaQuery,
	Tooltip,
} from '@material-ui/core';

import { Button } from '../../../components/Button/Button';
import { useTranslation } from 'react-i18next';
import { Group, GroupResponseData, UpdateGroupResponseData } from '../placement-groups-types';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useSelector } from 'react-redux';
import { BREAKPOINTS, PermissionsDotNet } from '../../../helpers/generalConstants';
import { TableColumnFilter } from '../../../components/Table/types';
import { fetchRequest } from '../../../services/helpers';
import { FilterOperators, SortOrder } from '../../../components/Table/constants';
import { updatePlacementGroup } from '../../../services/placement-groups/placement-groups-service';
import { RootState } from '../../../store/types';
import { UserState } from '../../../store/user/user-types';
import { hasPermission } from '../../../helpers/auth';
import {
	Definition,
	DefinitionResponseData,
	DevicePlacement,
	DevicePlacementsResponseData,
} from '../../DevicePlacementsManagement/device-placements-types';
import { fetchDefinitions, fetchPlacements } from '../../../services/device-placements/device-placements-service';
import { InputSelectUncontrolled } from '../../../components/Form/InputSelectUncontrolled';
import { UUID } from '../../../types';

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
	card: {
		height: '100%',
	},
	list: {
		width: '100%',
		maxHeight: 500,
		backgroundColor: palette.background.paper,
		overflow: 'auto',
	},
	button: {
		margin: spacing(0.5, 0),
	},
	buttons: {
		alignSelf: 'center',
	},
	label: {
		'& + &': {
			marginLeft: spacing(1),
		},
	},
}));

type Props = {
	currentGroup: GroupResponseData;
};

export const PlacementGroupsTransferList = ({ currentGroup }: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { permissions, project } = useSelector<RootState, UserState>((state) => state.user);
	const matchesMd = useMediaQuery(BREAKPOINTS.md);

	const defaultValue = { placements: [], total: 0 };

	const [currentGroupItems, setCurrentGroupItems] = React.useState<{
		placements: DevicePlacement[];
		total: number;
	}>(defaultValue);

	const [currentGroupFilters] = React.useState<TableColumnFilter[]>([]);
	const [currentGroupOffset, setCurrentGroupOffset] = React.useState(0);
	const [currentGroupLimit, setCurrentGroupLimit] = React.useState(10);

	const [othersGroupItems, setOthersGroupItems] = React.useState<{
		placements: DevicePlacement[];
		total: number;
	}>(defaultValue);

	// const [othersGroupFilters] = React.useState<TableColumnFilter[]>([]);
	const [othersGroupOffset, setOthersGroupOffset] = React.useState(0);
	const [othersGroupLimit, setOthersGroupLimit] = React.useState(10);

	const [update, setUpdate] = React.useState({});

	const [selected, setSelected] = React.useState<DevicePlacement[]>([]);
	// const { permissions } = useSelector<RootState, UserState>((state) => state.user);
	const canEditPlacements = hasPermission(PermissionsDotNet.PlacementWrite, permissions);

	const intersection = (placementsA: DevicePlacement[], placementsB: DevicePlacement[]) =>
		placementsA.filter(({ id }) => placementsB.findIndex((placement) => placement.id === id) !== -1);

	const numberOfSelected = (items: DevicePlacement[]) => intersection(selected, items).length;
	const toRemove = intersection(selected, currentGroupItems.placements);
	const toAdd = intersection(selected, othersGroupItems.placements);
	const [definitions, setDefinitions] = React.useState<Definition[]>([]);
	const [selectedDefinitionId, setSelectedDefinitionId] = React.useState<UUID | undefined>();

	const handleToggleAll = (items: DevicePlacement[]) => {
		if (numberOfSelected(items) === 0) {
			setSelected(items);

			return;
		}

		setSelected([]);
	};

	useEffect(() => {
		const filters = [];

		if (project?.id) {
			filters.push({
				column: 'projects',
				operator: FilterOperators.injoin,
				value: project?.id,
			});
		}

		const request = fetchDefinitions({
			limit: 100,
			offset: 0,
			orderings: [{ column: 'dateUpdated', sortOrder: SortOrder.Descending }],
			filtersAndConditions: filters,
		});

		(async () => {
			const { data, error } = await fetchRequest<DefinitionResponseData>(request);

			if (error) {
				console.log(error);
				// TODO show error
			} else {
				if (data) {
					setDefinitions(data.data);
				}
			}
		})();

		/// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [project]);

	useEffect(() => {
		const filterBody = {
			limit: currentGroupLimit,
			offset: currentGroupOffset,
			orderings: [],
			filtersAndConditions: [
				{ column: 'placementGroups', operator: FilterOperators.injoin, value: currentGroup.id },
				// {
				// 	column: 'projects',
				// 	operator: FilterOperators.injoin,
				// 	value: project?.id,
				// },
			], // currentGroupFilters,
		};

		const request = fetchPlacements(filterBody);

		(async () => {
			const { data, error } = await fetchRequest<DevicePlacementsResponseData>(request);

			if (error) {
				// TODO show error
			} else {
				setCurrentGroupItems({
					placements: data?.data ?? [],
					total: data?.total ?? 0,
				});
			}
		})();
	}, [currentGroup.id, currentGroupFilters, currentGroupLimit, currentGroupOffset, update]);

	useEffect(() => {
		const filters = [];

		if (project?.id) {
			filters.push({
				column: 'projects',
				operator: FilterOperators.injoin,
				value: project.id,
			});
		}

		if (selectedDefinitionId) {
			filters.push({
				column: 'definitionId',
				operator: FilterOperators.equals,
				value: selectedDefinitionId,
			});
		}

		const filterBody = {
			limit: othersGroupLimit,
			offset: othersGroupOffset,
			orderings: [{ column: 'dateUpdated', sortOrder: SortOrder.Descending }],
			filtersAndConditions: filters, // othersGroupFilters,
		};

		const request = fetchPlacements(filterBody);

		(async () => {
			const { data, error } = await fetchRequest<DevicePlacementsResponseData>(request);

			if (error) {
				// TODO show error
			} else {
				setOthersGroupItems({
					placements: data?.data ?? [],
					total: data?.total ?? 0,
				});
			}
		})();
	}, [currentGroup.id, othersGroupLimit, othersGroupOffset, project, selectedDefinitionId, update]);

	const handleToggle = (item: DevicePlacement) => {
		if (!canEditPlacements) {
			return;
		}

		const isSelected = Boolean(selected.find(({ id }) => id === item.id));

		if (isSelected) {
			setSelected(selected.filter(({ id }) => id !== item.id));

			return;
		}

		setSelected([...selected, item]);
	};

	const handleAddToGroup = (items: DevicePlacement[]) => {
		if (currentGroup) {
			const placementsToUpdate = items.map(({ id }) => id);

			const request = updatePlacementGroup(
				{
					name: currentGroup.name,
					description: currentGroup.description,
					placementsToUpdate,
					placementsToRemove: [],
				},
				currentGroup.id,
			);

			(async () => {
				const { error } = await fetchRequest<UpdateGroupResponseData>(request);

				if (error) {
					// TODO show error
				} else {
					setUpdate({});

					setSelected([]);
				}
			})();
		}
	};

	const handleRemoveFromGroup = (items: DevicePlacement[]) => {
		const placementsToRemove = items.map(({ id }) => id);

		const request = updatePlacementGroup(
			{
				name: currentGroup.name,
				description: currentGroup.description,
				placementsToRemove,
				placementsToUpdate: [],
			},
			currentGroup.id,
		);

		(async () => {
			const { error } = await fetchRequest<UpdateGroupResponseData>(request);

			if (error) {
				// TODO show error
			} else {
				setUpdate({});

				setSelected([]);
			}
		})();
	};

	const handleCurrentChangePage = (nextPage: number) => {
		setCurrentGroupOffset(nextPage * currentGroupLimit);
	};

	const handleOthersChangePage = (nextPage: number) => {
		setOthersGroupOffset(nextPage * othersGroupLimit);
	};

	const handleCurrentChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCurrentGroupOffset(0);
		setCurrentGroupLimit(Number(event.target.value));
	};

	const handleOthersChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setOthersGroupOffset(0);
		setOthersGroupLimit(Number(event.target.value));
	};

	function notInCurrentGroup(side: 'left' | 'right', groups?: Group[]) {
		return side === 'right' && groups?.find((group) => group.id === currentGroup.id) !== undefined;
	}

	// TODO const handleUploadDevices = (newDevices: CreateDevicePayload[]) => {
	// 	dispatch(uploadDevices(newDevices));
	// };

	// const renderGroups = (groups: DeviceGroup[]) =>
	// 	groups.map((group) => {
	// 		return (
	// 			<Label className={classes.label} key={group.name}>
	// 				{group.name}
	// 			</Label>
	// 		);
	// 	});

	function getName(placement: DevicePlacement) {
		const title1 = placement.placementDescription?.title1;
		const subtitle1 = placement.placementDescription?.subTitle1;

		let name = placement.id;

		if (title1 && title1.length > 0) {
			if (subtitle1 && subtitle1.length > 0) {
				name = `${title1} (${subtitle1})`;
			} else {
				name = title1;
			}
		}

		return name;
	}

	const renderList = (title: string, items: DevicePlacement[], side: 'left' | 'right') => {
		const numOfSelected = numberOfSelected(items);

		return (
			<Card className={classes.card}>
				<CardHeader
					action={
						side === 'left' ? (
							<></>
						) : (
							<InputSelectUncontrolled
								// defaultValueId={types[0]}
								onChanged={(value) => setSelectedDefinitionId(value ? value.id : undefined)}
								error={false}
								options={
									definitions.map((definition) => ({
										id: definition.id,
										label: t(definition.name),
									})) ?? []
								}
								label={t('selectType')}
							/>
						)
					}
					avatar={
						<Checkbox
							disabled={!canEditPlacements || items.length === 0}
							indeterminate={numOfSelected !== items.length && numOfSelected !== 0}
							inputProps={{ 'aria-label': 'all items selected' }}
							checked={numOfSelected === items.length && items.length !== 0}
							color="primary"
							name={`${side}Groups`}
							onClick={() => handleToggleAll(items)}
						/>
					}
					title={title}
					subheader={`${numOfSelected}/${items.length} selected`}
				/>
				<Divider />
				<List className={classes.list} dense component="div" role="list" data-cy={`div:${side}ItemList`}>
					{items.map((placement: DevicePlacement) => {
						return (
							<ListItem
								key={placement.id}
								role="listitem"
								button
								onClick={() => handleToggle(placement)}
								disabled={!canEditPlacements || notInCurrentGroup(side, placement.placementGroups)}
							>
								<ListItemIcon>
									<Checkbox
										// disabled={!canEditPlacements || notInCurrentGroup(placement.placementGroups)}
										checked={Boolean(
											selected.find((item: DevicePlacement) => item.id === placement.id),
										)}
										color="primary"
										// inputProps={{ 'aria-labelledby': value.sn }}
										// name={`sn-${value.sn}`}
										// tabIndex={-1}
									/>
								</ListItemIcon>

								<ListItemText
									// id={value.sn}
									primary={
										<>
											<Typography component="div" variant="body1">
												{getName(placement)}
											</Typography>
											<Typography component="div" variant="body2" gutterBottom>
												{`${t('type')}: ${
													placement.definitionName
														? t(placement.definitionName)
														: placement.definitionName
												}`}
											</Typography>
											<Typography component="div" variant="body2" gutterBottom>
												{`${t('groups')}`}:
												{placement.placementGroups?.map((group) => ` ${group.name},`)}
											</Typography>
										</>
									}
									// secondary={renderGroups(value.deviceGroups)}
								/>
							</ListItem>
						);
					})}
					<ListItem />
				</List>
			</Card>
		);
	};

	return (
		<Grid container spacing={2} justify="center" direction={matchesMd ? 'row' : 'column'}>
			<Grid item xs={12} md={5}>
				{renderList(currentGroup?.name, currentGroupItems.placements, 'left')}
				<TablePagination
					rowsPerPageOptions={[5, 10, 25, 100]}
					component="div"
					count={currentGroupItems.total}
					rowsPerPage={currentGroupLimit}
					page={currentGroupOffset / currentGroupLimit}
					onPageChange={(_: unknown, nextPage: number) => handleCurrentChangePage(nextPage)}
					onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						handleCurrentChangeRowsPerPage(event)
					}
				/>
			</Grid>
			<Grid className={classes.buttons} item md={2} lg="auto">
				{canEditPlacements && (
					<Grid container direction="column" alignItems="center">
						<Tooltip title={t('removeFromGroup') as string}>
							<Button
								variant="outlined"
								size="small"
								className={classes.button}
								onClick={() => handleRemoveFromGroup(selected)}
								disabled={toRemove.length === 0}
								data-cy="btn-icon:forward"
							>
								<ArrowForwardIcon fontSize="small" />
							</Button>
						</Tooltip>
						<Tooltip title={t('addToGroup') as string}>
							<Button
								variant="outlined"
								size="small"
								className={classes.button}
								onClick={() => handleAddToGroup(selected)}
								disabled={toAdd.length === 0}
								data-cy="btn-icon:back"
							>
								<ArrowBackIcon fontSize="small" />
							</Button>
						</Tooltip>
					</Grid>
				)}
			</Grid>
			<Grid item xs={12} md={5}>
				{renderList(t('otherGroups'), othersGroupItems.placements, 'right')}
				<TablePagination
					rowsPerPageOptions={[5, 10, 25, 100]}
					component="div"
					count={othersGroupItems.total}
					rowsPerPage={othersGroupLimit}
					page={othersGroupOffset / othersGroupLimit}
					onPageChange={(_: unknown, nextPage: number) => handleOthersChangePage(nextPage)}
					onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						handleOthersChangeRowsPerPage(event)
					}
				/>
			</Grid>
		</Grid>
	);
};

import React from 'react';

type Props = {
	fill: string;
};

export const MarkerDeviceIcon = ({ fill }: Props) => {
	return (
		<svg width="48" height="48" viewBox="0 0 48 48" fill={fill} xmlns="http://www.w3.org/2000/svg">
			<path d="M24 4C16.26 4 10 10.26 10 18C10 28.5 24 44 24 44C24 44 38 28.5 38 18C38 10.26 31.74 4 24 4Z" />
			<circle cx="24" cy="18" r="11" fill="white" />
			<path d="M23.1028 11.8417C23.24 11.9789 23.4639 11.9933 23.6228 11.8706C24.4389 11.2206 25.4139 10.8667 26.3889 10.8667C27.3639 10.8667 28.3389 11.2206 29.1622 11.8706C29.3139 11.9933 29.5378 11.9789 29.6822 11.8417L29.7111 11.8056C29.87 11.6467 29.8628 11.3794 29.6894 11.2278C28.7289 10.4117 27.5589 10 26.3889 10C25.2189 10 24.0489 10.4117 23.0883 11.2278C22.915 11.3794 22.9006 11.6394 23.0667 11.8056L23.1028 11.8417V11.8417ZM24.3306 12.3906C24.15 12.535 24.1428 12.8094 24.3017 12.9683L24.3306 12.9972C24.475 13.1417 24.6917 13.1417 24.8506 13.0261C25.3056 12.6794 25.8472 12.5278 26.3889 12.5278C26.9306 12.5278 27.4722 12.6794 27.9272 13.0189C28.0861 13.1417 28.31 13.1344 28.4472 12.99L28.4761 12.9611C28.6422 12.795 28.6278 12.5278 28.4472 12.3833C27.8478 11.9211 27.1183 11.6611 26.3889 11.6611C25.6594 11.6611 24.93 11.9211 24.3306 12.3906ZM28.5556 17.2222H27.1111V15.0556C27.1111 14.6583 26.7861 14.3333 26.3889 14.3333C25.9917 14.3333 25.6667 14.6583 25.6667 15.0556V17.2222H18.4444C17.65 17.2222 17 17.8722 17 18.6667V21.5556C17 22.35 17.65 23 18.4444 23H28.5556C29.35 23 30 22.35 30 21.5556V18.6667C30 17.8722 29.35 17.2222 28.5556 17.2222ZM20.6111 20.8333H19.1667V19.3889H20.6111V20.8333ZM23.1389 20.8333H21.6944V19.3889H23.1389V20.8333ZM25.6667 20.8333H24.2222V19.3889H25.6667V20.8333Z" />
		</svg>
	);
};

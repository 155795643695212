export const PIPELINES_COMMON_EMPTY_REQUEST = '[PIPELINES_ACTIONS] PIPELINES_COMMON_EMPTY_REQUEST';
export const PIPELINES_COMMON_EMPTY_ERROR = '[PIPELINES_ACTIONS] PIPELINES_COMMON_EMPTY_ERROR';

export const FETCH_PIPELINES_SUCCESS = '[PIPELINES_ACTIONS] FETCH_PIPELINES_SUCCESS';

export const CREATE_PIPELINE_SUCCESS = '[PIPELINES_ACTIONS] CREATE_PIPELINE_SUCCESS';

export const EDIT_PIPELINE_SUCCESS = '[PIPELINES_ACTIONS] EDIT_PIPELINE_SUCCESS';

export const DELETE_PIPELINE_SUCCESS = '[PIPELINES_ACTIONS] DELETE_PIPELINE_SUCCESS';

export const FETCH_JOBS_SUCCESS = '[PIPELINES_ACTIONS] FETCH_JOBS_SUCCESS';

// export const CREATE_PIPELINE_RULE_SUCCESS = '[PIPELINES_ACTIONS] CREATE_PIPELINE_RULE_SUCCESS';

// export const UPDATE_PIPELINE_RULE_SUCCESS = '[PIPELINES_ACTIONS] UPDATE_PIPELINE_RULE_SUCCESS';

// export const DELETE_PIPELINE_RULE_SUCCESS = '[PIPELINES_ACTIONS] DELETE_PIPELINE_RULE_SUCCESS';

// export const GET_PIPELINE_RULE_DETAIL = '[PIPELINES_ACTIONS] GET_PIPELINE_RULE_DETAIL';

import { FormControl } from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/Button/Button';
import { Form } from '../../../components/Form/Form';
import { InputSelectUncontrolled } from '../../../components/Form/InputSelectUncontrolled';
import { ModalDialogFormActions } from '../../../components/ModalDialog/ModalDialogFormActions';
import { UUID } from '../../../types';
import { DevicePlacement } from '../../DevicePlacementsManagement/device-placements-types';

export type AttachDeviceFormValues = {
	placementId: UUID;
};

type Props = {
	options: DevicePlacement[];
	onSubmit: (values: AttachDeviceFormValues) => void;
	onCancel?: () => void;
};

export const AttachDeviceForm = ({ onSubmit, onCancel, options }: Props) => {
	const { t } = useTranslation();

	const [placementId, setPlacementId] = useState<string>();

	const [error, setError] = useState(false);

	const handleSubmit = (event: any) => {
		if (placementId === undefined) {
			setError(true);
		} else {
			onSubmit({ placementId: placementId });
		}

		event.preventDefault();
	};

	const handleChangedPlacementId = (placementId?: string) => {
		setPlacementId(placementId);
		setError(false);
	};

	const renderPlacement = (placement: DevicePlacement) => {
		const title1 = placement.placementDescription?.title1;
		const title2 = placement.placementDescription?.title2;
		const subtitle1 = placement.placementDescription?.subTitle1;

		if (title1 || title2 || subtitle1) {
			return `${title1 ? `${title1} , ` : ''}${title2 ? `${title2} , ` : ''}
				${subtitle1 ? subtitle1 : ''}`;
		} else {
			return placement.id; // Fallback (should not happen)
		}
	};

	return (
		<Form onSubmit={handleSubmit} testId="attacheDeviceForm">
			{/* <FormControl fullWidth margin="normal" variant="outlined">
				<InputLabel id="choosePlacement-label">{t('choosePlacement')}</InputLabel>
				<Controller
					as={
						<Select>
							{options?.map((option) => (
								<MenuItem key={option.id} value={option.id}>
									{renderPlacement(option)}
								</MenuItem>
							))}
						</Select>
					}
					control={control}
					error={Boolean(errors.placementId)}
					helperText={errors.placementId?.message}
					label={t('choosePlacement')}
					labelId="choosePlacement-label"
					name="placement"
				/>
			</FormControl> */}

			<FormControl fullWidth margin="normal" variant="outlined">
				<InputSelectUncontrolled
					error={Boolean(error)}
					helperText={error && t('validationErrors.required')}
					options={options.flatMap((option) => {
						return { id: option.id, label: renderPlacement(option) };
					})}
					label={t('choosePlacement')}
					onChanged={(value) => handleChangedPlacementId(value?.id)}
				/>
			</FormControl>

			<ModalDialogFormActions>
				<Button startIcon={<SaveIcon />} type="submit" data-cy="btn-submit:attachDeviceForm">
					{t('save')}
				</Button>
				{onCancel && (
					<Button onClick={onCancel} variant="text" data-cy="btn-cancel:attachDeviceForm">
						{t('cancel')}
					</Button>
				)}
			</ModalDialogFormActions>
		</Form>
	);
};

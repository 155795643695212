import React from 'react';
import { TableCell, TableRow, Tooltip, Divider, CardHeader, IconButton, Typography } from '@material-ui/core';
import { Add, DeleteForever } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FilterOperators, FilterType, TableRowActionIcon } from '../../../components/Table/constants';
import { Table } from '../../../components/Table/Table';
import {
	TableColumnFilter,
	TableColumnSort,
	TableHeadCell,
	TableRowRenderProps,
} from '../../../components/Table/types';
import { RouteEnum } from '../../../router/Routes';
import { addModalDialog, removeModalDialog, setRedirectUrl } from '../../../store/app/app-actions';
import { getContactGroupDetail } from '../../../store/contact-groups/contact-groups-actions';
import {
	createContactGroup,
	deleteContactGroup,
	fetchContactGroups,
	updateContactGroup,
} from '../../../store/contact-groups/contact-groups-async-actions';
import { CreateGroupPayload, Group, GroupResponseData, UpdateGroupPayload } from '../contact-groups-types';
import { ContactGroupForm, ContactGroupFormValues } from './ContactGroupForm';
import { PermissionsDotNet } from '../../../helpers/generalConstants';
import { hasPermission } from '../../../helpers/auth';
import { RootState } from '../../../store/types';
import { UserState } from '../../../store/user/user-types';

type Props = {
	data: Group[];
	total: number;
	limit: number;
	offset: number;
	orderings: TableColumnSort[];
	filtersAndConditions: TableColumnFilter[];
};

export const ContactGroupsTable = ({ data, total, offset, limit, orderings, filtersAndConditions }: Props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { permissions } = useSelector<RootState, UserState>((state) => state.user);
	const canEditGroups = hasPermission(PermissionsDotNet.ContactWrite, permissions);

	const nameOrder = orderings?.find((order) => order.column === 'name');
	const nameFilter = filtersAndConditions?.find((filter) => filter.column === 'name');

	const columns: TableHeadCell[] = [
		{
			id: 'name',
			label: t('name'),
			filters: nameFilter && [
				{
					column: 'name',
					operator: FilterOperators.contains,
					value: nameFilter.value,
				},
			],
			filterType: FilterType.String,
			sort: nameOrder?.sortOrder && { column: 'name', sortOrder: nameOrder?.sortOrder },
		},
		{
			id: 'contacts',
			label: t('contacts'),
			unorderable: true,
		},
	];

	const handleCloseModalDialog = (id: string) => {
		dispatch(removeModalDialog(id));
	};

	const handleCreateGroup = (data: CreateGroupPayload, modalDialogId: string) => {
		handleCloseModalDialog(modalDialogId);
		dispatch(createContactGroup(data));
	};

	const handleUpdateGroup = (data: UpdateGroupPayload, modalDialogId: string) => {
		handleCloseModalDialog(modalDialogId);
		dispatch(updateContactGroup(data));
	};

	const handleDeleteGroup = (groupId: string, modalDialogId: string) => {
		handleCloseModalDialog(modalDialogId);
		dispatch(deleteContactGroup(groupId));
	};

	const rowActions = [
		...(canEditGroups
			? [
					{
						id: 'btn-edit:group',
						icon: TableRowActionIcon.edit,
						tooltip: t('edit'),
						onClick: (group: Group) => {
							dispatch(
								addModalDialog({
									id: 'editGroupModal',
									title: t('editGroupModal'),
									contentText: '',
									content: (
										<ContactGroupForm
											defaultValues={{
												name: group.name,
											}}
											onSubmit={(values) =>
												handleUpdateGroup({ ...values, id: group.id }, 'editGroupModal')
											}
											onCancel={() => handleCloseModalDialog('editGroupModal')}
										/>
									),
								}),
							);
						},
					},
					{
						id: 'btn-delete:group',
						icon: TableRowActionIcon.delete,
						tooltip: t('delete'),
						onClick: (group: Group) =>
							dispatch(
								addModalDialog({
									id: 'deleteGroupModal',
									title: t('deleteGroupModalTitle'),
									contentText: t('deleteGroupModalDescription', {
										name: group.name,
									}),
									buttons: [
										{
											id: 'deleteGroup',
											value: t('delete'),
											onClick: () => handleDeleteGroup(group.id, 'deleteGroupModal'),
											startIcon: <DeleteForever />,
										},
										{
											id: 'cancelDeleteGroup',
											value: t('cancel'),
											onClick: () => handleCloseModalDialog('deleteGroupModal'),
											variant: 'text',
										},
									],
								}),
							),
					},
			  ]
			: []),
		{
			id: 'btn-detail:group',
			icon: TableRowActionIcon.detail,
			tooltip: t('groupDetail'),
			onClick: (group: Group) => {
				dispatch(setRedirectUrl(`${RouteEnum.CONTACT_GROUPS}/${group.id}`));
				dispatch(getContactGroupDetail(group));
			},
		},
	];

	function renderContacts(group: GroupResponseData) {
		const contacts = group.contacts;

		if (contacts && contacts.length > 0) {
			return (
				<Tooltip
					title={contacts.map((contact) => (
						<div key={contact.id}>{`${contact.firstName} ${contact.lastName}`}</div>
					))}
				>
					<div style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '11rem' }}>
						<Typography variant="inherit" noWrap>
							{contacts.map((contact) => `${contact.firstName} ${contact.lastName}, `)}
						</Typography>
					</div>
				</Tooltip>
			);
		}
	}

	const renderRow = ({ row: group, renderActions }: TableRowRenderProps<Group>) => {
		// const dateCreated = new Date(group.dateCreated);
		// const dateCreatedFormatted = isValid(dateCreated) ? format(dateCreated, INPUT_DATE_MASK) : '-';

		// const dateUpdated = new Date(group.dateUpdated);
		// const dateUpdatedFormatted = isValid(dateUpdated) ? format(dateUpdated, INPUT_DATE_MASK) : '-';

		return (
			<TableRow key={group.id} id={`row-${group.id}`}>
				<TableCell>{group.name}</TableCell>
				<TableCell>{renderContacts(group)}</TableCell>
				{canEditGroups && (
					<TableCell padding="none" sortDirection={false}>
						{renderActions(rowActions, group)}
					</TableCell>
				)}
			</TableRow>
		);
	};

	return (
		<>
			<CardHeader
				title={t('contactGroups')}
				action={
					<>
						{canEditGroups && (
							<Tooltip title={String(t('createGroup'))} aria-label={t('createGroup')}>
								<IconButton
									onClick={() =>
										dispatch(
											addModalDialog({
												id: 'createGroupModal',
												title: t('createGroupModalTitle'),
												contentText: '',
												content: (
													<ContactGroupForm
														onSubmit={(values: ContactGroupFormValues) =>
															handleCreateGroup(values, 'createGroupModal')
														}
														onCancel={() => handleCloseModalDialog('createGroupModal')}
													/>
												),
											}),
										)
									}
									data-cy="btn-create:group"
								>
									<Add />
								</IconButton>
							</Tooltip>
						)}
					</>
				}
			/>
			<Divider light />
			<Table
				actions={canEditGroups ? rowActions : undefined}
				columns={columns}
				orderings={orderings}
				filtersAndConditions={filtersAndConditions}
				data={data}
				dataLimit={limit}
				dataOffset={offset}
				renderTableRow={renderRow}
				numberOfRows={total}
				fetchAction2={fetchContactGroups}
				selectable={false}
			/>
		</>
	);
};

import * as devicesService from '../../services/devices/devices-service';
import { AsyncAction } from '../types';
import {
	createDeviceSuccess,
	fetchDevicesSuccess,
	generalWarehouseRequest,
	generalWarehouseError,
	editDeviceSuccess,
	editDevicePropertiesSuccess,
} from './warehouse-actions';

import { fetchRequest, createToastError, isStatusSuccess } from '../../services/helpers';
import {
	CreateDevicePayload,
	DeviceProperties,
	DeviceResponseData2,
	DevicesResponseData2,
	EditDevicePayload,
	EditDeviceProperties,
} from '../../pages/Devices/devices-types';

import { addToast } from '../app/app-actions';
import { FiltersRequestBody2 } from '../../components/Table/types';
import { omit } from 'lodash';

export const fetchDevices: AsyncAction<FiltersRequestBody2> = (devicesFilterPayload) => async (dispatch) => {
	dispatch(generalWarehouseRequest());

	if (devicesFilterPayload.filtersAndConditions === undefined) {
		devicesFilterPayload.filtersAndConditions = [];
	}

	const request = devicesService.getFilteredDevices(devicesFilterPayload);
	const { data, error } = await fetchRequest<DevicesResponseData2>(request);

	if (data) {
		dispatch(fetchDevicesSuccess(data, devicesFilterPayload));
	} else {
		dispatch(generalWarehouseError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(fetchDevices(devicesFilterPayload));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const createDevice: AsyncAction<CreateDevicePayload> = (createPayload) => async (dispatch, getState) => {
	dispatch(generalWarehouseRequest());

	const request = devicesService.createDevice(createPayload);
	const { data, status, error } = await fetchRequest<DeviceResponseData2>(request);

	if (isStatusSuccess(status) && data) {
		const { warehouse } = getState();

		dispatch(createDeviceSuccess());
		dispatch(
			fetchDevices({
				offset: warehouse.offset,
				limit: warehouse.limit,
				orderings: warehouse.orderings,
				filtersAndConditions: warehouse.filtersAndConditions,
			}),
		);
	} else {
		dispatch(generalWarehouseError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data)));
	}
};

export const editDevice: AsyncAction<EditDevicePayload> = (editPayload) => async (dispatch, getState) => {
	dispatch(generalWarehouseRequest());

	const request = devicesService.editDevice(omit(editPayload, ['id']), editPayload.id);
	const { data, status, error } = await fetchRequest<DeviceResponseData2>(request);

	if (isStatusSuccess(status) && data) {
		const { warehouse } = getState();

		dispatch(editDeviceSuccess());
		dispatch(
			fetchDevices({
				offset: warehouse.offset,
				limit: warehouse.limit,
				orderings: warehouse.orderings,
				filtersAndConditions: warehouse.filtersAndConditions,
			}),
		);
	} else {
		dispatch(generalWarehouseError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data)));
	}
};

export const editDeviceProperties: AsyncAction<EditDeviceProperties> = (editPayload) => async (dispatch, getState) => {
	dispatch(generalWarehouseRequest());

	const request = devicesService.editDeviceProperties(editPayload.deviceProperty, editPayload.deviceId);
	const { data, status, error } = await fetchRequest<DeviceProperties>(request);

	if (isStatusSuccess(status) && data) {
		const { warehouse } = getState();

		dispatch(editDevicePropertiesSuccess());
		dispatch(
			fetchDevices({
				offset: warehouse.offset,
				limit: warehouse.limit,
				orderings: warehouse.orderings,
				filtersAndConditions: warehouse.filtersAndConditions,
			}),
		);
	} else {
		dispatch(generalWarehouseError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data)));
	}
};

import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { DevicesTable } from './components/DevicesTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { UserState } from '../../store/user/user-types';
import {
	fetchDevicesWithTelemetry,
	fetchDevicesTelemetryNoProgressBar,
} from '../../store/devices/devices-async-actions';
import { DevicesState } from '../../store/devices/devices-types';

export const Devices = () => {
	const dispatch = useDispatch();
	const { project } = useSelector<RootState, UserState>((state) => state.user);
	const { data, total, offset, limit, orderings, filtersAndConditions, updatedTime } = useSelector<
		RootState,
		DevicesState
	>((state) => state.devices);

	const [refreshInterval, setRefreshInterval] = useState(60000);

	const fetchDevices = () => {
		dispatch(fetchDevicesTelemetryNoProgressBar());

		let ref = refreshInterval * 1.5;

		if (ref > 10 * 60000) {
			ref = 10 * 60000;
		}

		setRefreshInterval(ref);
	};

	useEffect(() => {
		if (refreshInterval && refreshInterval > 0) {
			const interval = setInterval(fetchDevices, refreshInterval);

			return () => clearInterval(interval);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshInterval]);

	useEffect(() => {
		// if (project?.id) {
		dispatch(
			fetchDevicesWithTelemetry({
				offset: offset,
				limit: limit,
				filtersAndConditions: filtersAndConditions,
				orderings: orderings,
			}),
		);
		// }

		// Don't need to react on "options", deep comparision is not necessary
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, project]);

	return (
		<Paper>
			<DevicesTable
				data={data}
				total={total}
				offset={offset}
				limit={limit}
				orderings={orderings}
				filters={filtersAndConditions}
				updatedTime={updatedTime}
			/>
		</Paper>
	);
};

import { isValid } from 'date-fns';
import { set } from 'lodash/fp';
import React, { useState } from 'react';
import { isStringArray } from '../../helpers/array';
import { Nullable, ObjectRequiredID, RangeValue, UUID } from '../../types';
import { FilterOperators } from './constants';
import { Filter, TableColumnFilter, UpdateCheckboxFilterPayload, UpdateSelectFilterPayload } from './types';

/**
 * Hook for selecting table rows
 * @param initialState - Array of IDs of selected table rows
 */
export const useSelect = <T extends ObjectRequiredID[]>(initialState: string[] = []) => {
	const [selectedRows, selectItems] = useState<string[]>(initialState);

	const setSelectAll = (event: React.ChangeEvent<HTMLInputElement>, data: T) => {
		if (event.target.checked) {
			const newSelectedItems = data.map((row) => String(row.id));

			selectItems(newSelectedItems);

			return;
		}

		selectItems([]);
	};

	const setSelectedRows = (id: UUID) => {
		const selectedIndex = selectedRows.indexOf(id);
		const isNewItem = selectedIndex === -1;

		// add item
		if (isNewItem) {
			selectItems([...selectedRows, id]);

			return;
		}

		// remove item
		selectItems(selectedRows.filter((item) => item !== id));
	};

	return { selectedRows, setSelectAll, setSelectedRows };
};

/**
 * Hook for setting checkbox filters
 *
 * @param defaultFilters - array of checkbox filters
 */
export const useCheckboxFilter = (defaultFilters: Filter[]) => {
	const [filters, setFilters] = useState(defaultFilters);

	const updateFilters = (currentFilter: UpdateCheckboxFilterPayload) => {
		const { value, operator } = currentFilter;
		const existingFilter = filters?.find((filter) => filter.operator === operator);
		const existingFilterIndex = filters?.findIndex((filter) => filter.operator === operator);

		// Update existing filter value if operator is the same
		// "value" in checkbox filter is always string
		if (existingFilter && Array.isArray(existingFilter.value) && isStringArray(existingFilter.value)) {
			const existingFilterValue = existingFilter.value as string[];
			const currentFilterValue = currentFilter.value as string;

			const updatedFilter = {
				...existingFilter,
				value: (existingFilterValue.includes(currentFilterValue)
					? existingFilterValue.filter((value: string) => value !== currentFilterValue)
					: [...existingFilterValue, value]) as string[],
			};

			const updatedFilters = set(`${existingFilterIndex}`, updatedFilter, filters);

			setFilters(updatedFilters);

			return;
		}

		const updatedFilters = [...(filters || []), { value: [value as string], operator }];

		setFilters(updatedFilters);
	};

	const resetFilters = (filters: Filter[]) => setFilters(filters);

	return { filters, updateFilters, resetFilters };
};

/**
 * Hook for setting select filters
 *
 * @param defaultFilters - array of search filters
 */
export const useSelectFilter = (defaultFilters: Filter[]) => {
	const [filters, setFilters] = useState(defaultFilters);

	const updateFilters = (currentFilter: UpdateSelectFilterPayload) => {
		const existingFilterIndex = filters.findIndex((filter) => filter.operator === currentFilter.operator);
		const isExistedFilter = existingFilterIndex > -1;

		if (isExistedFilter) {
			setFilters(set(`${existingFilterIndex}`, currentFilter, filters));

			return;
		}

		setFilters([...filters, currentFilter]);
	};

	const resetFilters = (filter: Filter) => setFilters([filter]);

	return { filters, updateFilters, resetFilters };
};

/**
 * Hook for setting search filters
 *
 * @param defaultFilters - array of search filters
 */
export const useSearchFilter = (defaultFilter: string) => {
	const [filter, setFilter] = useState({ operator: FilterOperators.contains, value: defaultFilter });

	const updateFilter = (query: string) => {
		setFilter({ ...filter, value: query });
	};

	const resetFilter = () => setFilter({ operator: FilterOperators.contains, value: '' });

	return { filter, updateFilter, resetFilter };
};

/**
 * Hook for setting date filters
 *
 * @param dateFrom - Date
 * @param dateTo - Date
 */
export const useDateFilter = (dateFrom: Nullable<Date>, dateTo: Nullable<Date>) => {
	const [dates, setDates] = useState<[Nullable<Date>, Nullable<Date>]>([dateFrom, dateTo]);

	const updateDateFilter = (dateFrom: Nullable<Date>, dateTo: Nullable<Date>) => {
		setDates([dateFrom, dateTo]);
	};

	const dateFilter: Filter[] = [];

	if (dates) {
		if (dates[0] && isValid(dates[0])) {
			dateFilter.push({ operator: FilterOperators.greaterThanEqual, value: dates[0].toISOString() });
		}

		if (dates[1] && isValid(dates[1])) {
			dateFilter.push({ operator: FilterOperators.lessThanEqual, value: dates[1].toISOString() });
		}
	}

	return { dates, updateDateFilter, dateFilter };
};

/**
 * Hook for setting date filters
 *
 * @param dateFrom - Date
 * @param dateTo - Date
 */
export const useDateFilter2 = (column: string, dateFrom: Nullable<Date>, dateTo: Nullable<Date>) => {
	const [dates, setDates] = useState<[Nullable<Date>, Nullable<Date>]>([dateFrom, dateTo]);

	const updateDateFilter = (dateFrom: Nullable<Date>, dateTo: Nullable<Date>) => {
		setDates([dateFrom, dateTo]);
	};

	const dateFilter: TableColumnFilter[] = [];

	if (dates) {
		if (dates[0] && isValid(dates[0])) {
			dateFilter.push({
				column: column,
				operator: FilterOperators.greaterThanEqual,
				value: dates[0].toISOString(),
			});
		}

		if (dates[1] && isValid(dates[1])) {
			dateFilter.push({
				column: column,
				operator: FilterOperators.lessThanEqual,
				value: dates[1].toISOString(),
			});
		}
	}

	return { dates, updateDateFilter, dateFilter };
};

/**
 * Hook for setting search filters
 *
 * @param defaultValues - array of two numbers [0, 100]
 */
export const useRangeFilter = (defaultValues: RangeValue) => {
	const initialState = [
		{ operator: FilterOperators.greaterThanEqual, value: defaultValues[0] },
		{ operator: FilterOperators.lessThanEqual, value: defaultValues[1] },
	];

	const [filter, setFilter] = useState(initialState);

	const updateFilter = (range: number[]) => {
		setFilter([
			{ operator: FilterOperators.greaterThanEqual, value: range[0] },
			{ operator: FilterOperators.lessThanEqual, value: range[1] },
		]);
	};

	const getFilter = (maxRange: number[]) => {
		if (filter.length > 0) {
			if (maxRange[0] >= filter[0].value && maxRange[1] <= filter[1].value) {
				return [];
			}
		}

		return filter;
	};

	const resetFilter = (maxRange: number[]) => {
		setFilter([
			{ operator: FilterOperators.greaterThanEqual, value: maxRange[0] },
			{ operator: FilterOperators.lessThanEqual, value: maxRange[1] },
		]);
	};

	return { filter, getFilter, updateFilter, resetFilter };
};

/**
 * Hook for setting search filters
 *
 * @param defaultValues - array of two numbers [0, 100]
 */
export const useRangeFilter2 = (column: string, defaultValues: RangeValue) => {
	const initialState: TableColumnFilter[] = [
		{ column: column, operator: FilterOperators.greaterThanEqual, value: defaultValues[0] },
		{ column: column, operator: FilterOperators.lessThanEqual, value: defaultValues[1] },
	];

	const [filter, setFilter] = useState(initialState);

	const updateFilter = (range: number[]) => {
		setFilter([
			{ column: column, operator: FilterOperators.greaterThanEqual, value: range[0] },
			{ column: column, operator: FilterOperators.lessThanEqual, value: range[1] },
		]);
	};

	const getFilter = (maxRange: number[]) => {
		if (filter.length > 0) {
			if (maxRange[0] >= filter[0].value && maxRange[1] <= filter[1].value) {
				return [];
			}
		}

		return filter;
	};

	const resetFilter = (maxRange: number[]) => {
		setFilter([
			{ column: column, operator: FilterOperators.greaterThanEqual, value: maxRange[0] },
			{ column: column, operator: FilterOperators.lessThanEqual, value: maxRange[1] },
		]);
	};

	return { filter, getFilter, updateFilter, resetFilter };
};

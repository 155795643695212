import { VehicleWatchState, VehicleWatchActions } from './vehicle-watch-types';
import { createReducer } from 'typesafe-actions';
import {
	GENERAL_VEHICLE_WATCH_REQUEST,
	GENERAL_VEHICLE_WATCH_ERROR,
	FETCH_DRIVERS_SUCCESS,
	FETCH_VEHICLES_SUCCESS,
} from './vehicle-watch-constants';

import { set } from 'lodash/fp';

const vehicleWatchInitialState: VehicleWatchState = {
	drivers: {
		data: [],
		total: 0,
		offset: 0,
		limit: 10,
		orderings: [],
		filtersAndConditions: [],
	},
	vehicles: {
		data: [],
		total: 0,
		offset: 0,
		limit: 10,
		orderings: [],
		filtersAndConditions: [],
	},
};

export const vehicleWatchReducer = createReducer<VehicleWatchState, VehicleWatchActions>(vehicleWatchInitialState, {
	[GENERAL_VEHICLE_WATCH_REQUEST]: (state) => state,
	[GENERAL_VEHICLE_WATCH_ERROR]: (state) => state,
	[FETCH_DRIVERS_SUCCESS]: (state, action) => {
		const newState = {
			data: action.payload.data.data,
			total: action.payload.data.total,
			limit: action.payload.options ? action.payload.options.limit : state.drivers.limit,
			offset: action.payload.options ? action.payload.options.offset : state.drivers.offset,
			orderings: action.payload.options?.orderings || state.drivers.orderings,
			filtersAndConditions: action.payload.options?.filtersAndConditions || state.drivers.filtersAndConditions,
		};

		return set('drivers', newState, state);
	},
	[FETCH_VEHICLES_SUCCESS]: (state, action) => {
		const newState = {
			data: action.payload.data.data,
			total: action.payload.data.total,
			limit: action.payload.options ? action.payload.options.limit : state.vehicles.limit,
			offset: action.payload.options ? action.payload.options.offset : state.vehicles.offset,
			orderings: action.payload.options?.orderings || state.vehicles.orderings,
			filtersAndConditions: action.payload.options?.filtersAndConditions || state.vehicles.filtersAndConditions,
			updatedTime: new Date().toISOString(),
		};

		return set('vehicles', newState, state);
	},
});

import { Grid, Paper } from '@material-ui/core';
import { Button } from '../../components/Button/Button';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MaterialHandlingVehicleTable } from './components/MaterialHandlingVehicleTable';
import PersonIcon from '@material-ui/icons/Person';
import { RouteEnum } from '../../router/Routes';
import { useHistory } from 'react-router-dom';
import { FilterOperators, SortOrder } from '../../components/Table/constants';
import { fetchDefinitions } from '../../services/device-placements/device-placements-service';
import { DI_DEF_NAME } from './vehicle-watch-types';
import { fetchRequest } from '../../services/helpers';
import { Definition, DefinitionResponseData } from '../DevicePlacementsManagement/device-placements-types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { UserState } from '../../store/user/user-types';
import { fetchDrivers } from '../../store/vehicle-watch/vehicle-watch-async-actions';

export const ProjectBased = true;

export const VehicleWatch = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const { project } = useSelector<RootState, UserState>((state) => state.user);
	const [driverDefinition, setDriverDefinition] = useState<Definition | undefined>();

	useEffect(() => {
		const request = fetchDefinitions({
			// Should be enough to get all definitions
			limit: 0,
			offset: 0,
			orderings: [],
			filtersAndConditions: [
				{
					column: 'name',
					operator: FilterOperators.contains,
					value: DI_DEF_NAME,
				},
			],
		});

		(async () => {
			const { data, error } = await fetchRequest<DefinitionResponseData>(request);

			if (error) {
				console.log(error);
				// TODO show error
			} else {
				if (data && data.data && data.data.length > 0) {
					setDriverDefinition(data.data[0]);
				}
			}
		})();

		/// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Fetch all drivers
	 */
	useEffect(() => {
		if (driverDefinition !== undefined) {
			const filters = [
				{
					column: 'definitionId',
					operator: FilterOperators.equals,
					value: driverDefinition.id,
				},
			];

			if (project) {
				filters.push({
					column: 'projects',
					operator: FilterOperators.injoin,
					value: project.id,
				});
			}

			dispatch(
				fetchDrivers({
					limit: -1,
					offset: 0,
					orderings: [{ column: 'dateUpdated', sortOrder: SortOrder.Descending }],
					filtersAndConditions: filters,
				}),
			);
		}

		/// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, driverDefinition, project]);

	return (
		<Grid container spacing={2} justify="center">
			<Grid item xs={12} lg={10}>
				<Button
					// disabled={reviewDefinitionNames === undefined}
					startIcon={<PersonIcon />}
					onClick={() => history.push(`${RouteEnum.VEHICLE_WATCH}/drivers`)}
					data-cy="btn-open:drivers"
				>
					{t('drivers')}
				</Button>
			</Grid>
			<Grid item xs={12} lg={10}>
				<Paper>
					<MaterialHandlingVehicleTable />
				</Paper>
			</Grid>
		</Grid>
	);
};

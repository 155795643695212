import React, { useEffect, useState } from 'react';
import { TableColumnFilter, TableColumnSort, TableRowRenderProps } from '../../../components/Table/types';
import { Table } from '../../../components/Table/Table';
import { UserResponseData, CreateUserPayload } from '../user-management-types';
import { fetchUsers, createUser, deleteUser } from '../../../store/user-management/user-management-async-actions';
import { useTranslation } from 'react-i18next';
import { FilterOperators, FilterType, TableRowActionIcon } from '../../../components/Table/constants';
import { addModalDialog, removeModalDialog, setRedirectUrl } from '../../../store/app/app-actions';
import { useDispatch, useSelector } from 'react-redux';
import { CreateUserForm, CreateUserFormValues } from '../../UserDetail/components/CreateUserForm';
import { Tooltip, TableRow, TableCell, CardHeader, Divider, IconButton } from '@material-ui/core';
import { UUID } from '../../../types';
import { format } from 'date-fns';
import { COLOR_THEME } from '../../../theme';
import { getUserDetail } from '../../../store/user-management/user-management-actions';
import { RouteEnum } from '../../../router/Routes';
import { TableActionsContainer } from '../../../components/Table/TableActionsContainer';
import { INPUT_DATE_MASK, PermissionsDotNet } from '../../../helpers/generalConstants';
import { CheckRounded, ClearRounded, Add, DeleteForever } from '@material-ui/icons';
import { RootState } from '../../../store/types';
import { UserState } from '../../../store/user/user-types';
import { Tenant } from '../../Tenants/tenants-types';
import { fetchRequest } from '../../../services/helpers';
import { TenantsResponseData } from '../../../services/user/user-types';
import { fetchTenants } from '../../../services/user/user-service';
import { hasPermission } from '../../../helpers/auth';

type Props = {
	data: UserResponseData[];
	total: number;
	limit: number;
	offset: number;
	orderings: TableColumnSort[];
	filters: TableColumnFilter[];
};

export const UsersTable = ({ data, total, limit, offset, orderings, filters }: Props) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { permissions, tenant } = useSelector<RootState, UserState>((state) => state.user);
	const canEditUsers = hasPermission(PermissionsDotNet.UserWrite, permissions);

	const [tenants, setTenants] = useState<Tenant[]>();

	/**
	 * Fetching all tenants
	 */
	useEffect(() => {
		const request = fetchTenants({
			// Should be enough to get all zones, even for super admin
			limit: 200,
			offset: 0,
			orderings: [],
			filtersAndConditions: [],
		});

		(async () => {
			const { data, error } = await fetchRequest<TenantsResponseData>(request);

			if (error) {
				// TODO show error
			} else {
				setTenants(data?.data);
			}
		})();
	}, []);

	const firstNameOrder = orderings?.find((order) => order.column === 'firstName');
	const firstNameFilter = filters?.find((filter) => filter.column === 'firstName');

	const lastNameOrder = orderings?.find((order) => order.column === 'lastName');
	const lastNameFilter = filters?.find((filter) => filter.column === 'lastName');

	const emailOrder = orderings?.find((order) => order.column === 'email');
	const emailFilter = filters?.find((filter) => filter.column === 'email');

	const columns = [
		{
			id: 'firstName',
			label: t('firstName'),
			filter: firstNameFilter && {
				column: 'firstName',
				operator: FilterOperators.contains,
				value: firstNameFilter.value,
			},
			filterType: FilterType.String,
			sort: firstNameOrder?.sortOrder && { column: 'firstName', sortOrder: firstNameOrder?.sortOrder },
			new: true,
		},
		{
			id: 'lastName',
			label: t('lastName'),
			filter: lastNameFilter && {
				column: 'lastName',
				operator: FilterOperators.contains,
				value: lastNameFilter.value,
			},
			filterType: FilterType.String,
			sort: lastNameOrder?.sortOrder && { column: 'lastName', sortOrder: lastNameOrder?.sortOrder },
			new: true,
		},
		{
			id: 'email',
			label: t('email'),
			filter: emailFilter && {
				column: 'email',
				operator: FilterOperators.contains,
				value: emailFilter.value,
			},
			filterType: FilterType.String,
			sort: emailOrder?.sortOrder && { column: 'email', sortOrder: emailOrder?.sortOrder },
			new: true,
		},
		{ id: 'active', label: t('active'), unorderable: true, new: true },
		{ id: 'lastLogin', label: t('lastLogin'), unorderable: true, new: true },
	];

	if (tenant === undefined) {
		columns.push({ id: 'tenant', label: t('tenant'), unorderable: true, new: true });
	}

	const handleCloseModalDialog = (id: string) => {
		dispatch(removeModalDialog(id));
	};

	const handleCreateUser = (data: CreateUserPayload, modalDialogId: string) => {
		handleCloseModalDialog(modalDialogId);
		dispatch(createUser(data));
	};

	const handleDeleteUser = (userId: UUID, modalDialogId: string) => {
		handleCloseModalDialog(modalDialogId);
		dispatch(deleteUser(userId));
	};

	const rowActions = [
		{
			id: 'btn-edit:user',
			icon: TableRowActionIcon.edit,
			tooltip: t('edit'),
			onClick: (user: UserResponseData) => {
				dispatch(setRedirectUrl(`${RouteEnum.USER_MANAGEMENT}/${user.id}`));
				dispatch(getUserDetail(user));
			},
		},
		{
			id: 'btn-delete:user',
			icon: TableRowActionIcon.delete,
			tooltip: t('delete'),
			onClick: (data: UserResponseData) =>
				dispatch(
					addModalDialog({
						id: 'deleteUserModal',
						title: t('deleteUserModalTitle'),
						contentText: t('deleteUserModalDescription', {
							firstName: data.firstName,
							lastName: data.lastName,
						}),
						buttons: [
							{
								id: 'deleteUser',
								value: t('delete'),
								onClick: () => handleDeleteUser(data.id, 'deleteUserModal'),
								startIcon: <DeleteForever />,
							},
							{
								id: 'cancelDeleteUser',
								value: t('cancel'),
								onClick: () => handleCloseModalDialog('deleteUserModal'),
								variant: 'text',
							},
						],
					}),
				),
		},
	];

	const renderTableRow = ({ onClickRow, row: user, renderActions }: TableRowRenderProps<UserResponseData>) => {
		return (
			<TableRow key={user.id} id={`row-${user.id}`} hover onClick={() => onClickRow(user.id)}>
				<TableCell component="td" id={user.firstName} scope="row">
					{user.firstName}
				</TableCell>
				<TableCell component="td" id={user.lastName} scope="row">
					{user.lastName}
				</TableCell>
				<TableCell component="td" id={user.email} scope="row">
					{user.email}
				</TableCell>
				<TableCell component="td" id={`${user.id}-${user.active}`} scope="row">
					{user.active ? (
						<CheckRounded htmlColor={COLOR_THEME.success} data-cy="check:active" />
					) : (
						<ClearRounded htmlColor={COLOR_THEME.error} data-cy="check:inactive" />
					)}
				</TableCell>
				<TableCell component="td" id={user.lastLogin || 'lastLogin-empty'} scope="row">
					{user.lastLogin && format(new Date(user.lastLogin), INPUT_DATE_MASK)}
				</TableCell>
				{tenant === undefined && (
					<TableCell component="td" id="tenant" scope="row">
						{tenants?.find((tenant) => tenant.id === user.tenantId)?.name}
					</TableCell>
				)}

				{canEditUsers && (
					<TableCell padding="none" sortDirection={false} align="right">
						<TableActionsContainer>{renderActions(rowActions, user)}</TableActionsContainer>
					</TableCell>
				)}
			</TableRow>
		);
	};

	return (
		<>
			<CardHeader
				title={t('users')}
				action={
					<>
						{canEditUsers && (
							<Tooltip title={String(t('createUser'))} aria-label={t('createUser')}>
								<IconButton
									onClick={() =>
										dispatch(
											addModalDialog({
												id: 'createUserModal',
												title: t('createUserModalTitle'),
												contentText: t('createUserModalDescription'),
												content: (
													<CreateUserForm
														onSubmit={(values: CreateUserFormValues) =>
															handleCreateUser(values, 'createUserModal')
														}
														onCancel={() => handleCloseModalDialog('createUserModal')}
														tenantId={tenant?.id}
														tenants={tenants || []}
													/>
												),
											}),
										)
									}
									data-cy="btn-create:user"
								>
									<Add />
								</IconButton>
							</Tooltip>
						)}
						{/* <UsersTableFilters config={options.config} filters={options.filters as UsersFilters} /> */}
					</>
				}
			/>
			<Divider light />
			<Table
				columns={columns}
				orderings={orderings}
				filtersAndConditions={filters}
				data={data}
				dataLimit={limit}
				dataOffset={offset}
				numberOfRows={total}
				fetchAction2={fetchUsers}
				actions={canEditUsers ? rowActions : undefined}
				renderTableRow={renderTableRow}
			/>
		</>
	);
};

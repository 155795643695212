export const globalTranslations = {
	percentage: '{{value}}%',
	degreesCelsius: '{{value}}°C',
	illuminance: '{{value}}lux',
	GET: 'GET',
	POST: 'POST',
	PUT: 'PUT',
	PATCH: 'PATCH',
	DELETE: 'DELETE',
	notAvailable: 'N/A',
	english: 'English',
	slovak: 'Slovenčina',
	czech: 'Čeština',
};

import React, { useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { MapContainer } from '../../components/Maps/MapContainer';
import { FeatureGroup, GeoJSON } from 'react-leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { COLOR_THEME, SPACING } from '../../theme';
import { addModalDialog, addToast, removeModalDialog } from '../../store/app/app-actions';
import {
	DeleteRounded,
	EditRounded,
	CheckRounded,
	ClearRounded,
	Add,
	ArrowForwardIos,
	Settings,
	InfoOutlined,
} from '@material-ui/icons';
import {
	Box,
	Card,
	CardHeader,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { UserState } from '../../store/user/user-types';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import { UUID } from '../../types';
import { Sidebar, Tab } from 'react-leaflet-sidebarv2';
import { InputField } from '../../components/Form/InputField';
import { CONTENT_PADDING_SM_UP } from '../../components/Layout/Content';
import { InDoorMapsState } from '../../store/inDoorMaps/inDoorMaps-types';
import { CreateInDoorMapRequestBody, EditInDoorMapRequestBody, InDoorMap } from './indoormaps-types';
import {
	createInDoorMap,
	deleteInDoorMap,
	editInDoorMap,
	fetchInDoorMaps,
} from '../../store/inDoorMaps/inDoorMaps-async-actions';
import { InDoorMapForm, InDoorMapFormValues } from './components/InDoorMapForm';
import { tryFeatureCollection } from 'pure-geojson-validation';
import { v4 as uuidv4 } from 'uuid';
import BorderClearIcon from '@material-ui/icons/BorderClear';
import { FeatureCollection } from 'geojson';
import { getBoundsFromGeoJson } from './helpers';
import { LatLngTuple, LeafletMouseEvent } from 'leaflet';
import { onEachFeature } from '../../components/Maps/helpers';
import { hasPermission } from '../../helpers/auth';
import { PermissionsDotNet } from '../../helpers/generalConstants';

// const GeoJSON3 = require('geojson');

// const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
// 	zonesTable: {
// 		marginTop: spacing(3),
// 	},
// 	deleteBtn: {
// 		marginLeft: spacing(2),
// 		backgroundColor: palette.error.main,

// 		'&:hover, &:focus': {
// 			backgroundColor: palette.error.dark,
// 		},
// 	},
// 	closeButton: {
// 		position: 'relative',
// 		right: spacing(1),
// 		top: spacing(1),
// 	},
// }));

export const InDoorMaps = () => {
	// const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { data: inDoorMaps } = useSelector<RootState, InDoorMapsState>((state) => state.inDoorMaps);

	const [longitude, setLongitude] = useState<number | undefined>();
	const [latitude, setLatitude] = useState<number | undefined>();

	const { project, permissions } = useSelector<RootState, UserState>((state) => state.user);
	const canEditInDoorMaps = hasPermission(PermissionsDotNet.IndoorMapWrite, permissions);

	// const [editingPopup, setEditingPopup] = useState(false);
	// Don't know this type from leaflet-draw
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	// const editControlRef = useRef<any>(null);
	// const { mapBounds, setMapBounds } = useZonesBounds(zones);

	const [sidebarColabsed, setSidebarColabsed] = useState(false);
	const [sidebarSelected, setSidebarSelected] = useState('inDoorMaps');
	const [inDoorMapsSearchKey, setInDoorMapsSearchKey] = useState<string>();

	const [geo, setGeo] = useState<{ image: FeatureCollection; key: string }>();

	const [mapBounds, setMapBounds] = useState<LatLngTuple[]>(); // todo

	// const pageRef = useRef<any>(null);

	// const stageCanvasRef = useRef(null);

	useEffect(() => {
		if (project?.id) {
			dispatch(
				fetchInDoorMaps({
					// Should be enough to get all zones, even for super admin
					limit: 1000,
					offset: 0,
					orderings: [],
					filtersAndConditions: [],
				}),
			);
		}

		// Don't need to react on "config" and "filters" and deep comparision is not necessary
		/// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, project]);

	const handleCloseModalDialog = (id: string) => {
		dispatch(removeModalDialog(id));
	};

	// const handleClickInDoorMap = (inDoorMap: InDoorMap) => {
	// 	const coords = fromJSONToObject<LatLngLiteral[]>(inDoorMap.polygon);

	// 	if (coords) {
	// 		const bounds = toLatLngExpression(coords);

	// 		setMapBounds(bounds);
	// 	}

	// 	setSelectedInDoorMapId(inDoorMap.id);
	// 	setEditingPopup(true);

	// 	pageRef.current?.scrollIntoView({
	// 		behavior: 'smooth',
	// 		block: 'start',
	// 	});
	// };

	// const handleCreated = (event: LeafletEvent) => {
	// 	const coords = fromJSONToObject<LatLngLiteral[][]>(JSON.stringify(event.layer.getLatLngs()))?.[0];

	// 	if (coords) {
	// 		dispatch(
	// 			addModalDialog({
	// 				id: 'createInDoorMapPolygonModal',
	// 				title: t('createInDoorMapPolygonModalTitle'),
	// 				content: (
	// 					<PolygonForm
	// 						onSubmit={(values: PolygonFormValues) =>
	// 							handleCreateInDoorMap(values, 'createInDoorMapPolygonModal')
	// 						}
	// 						values={{ coords, active: true, name: '', description: '' }}
	// 					/>
	// 				),
	// 			}),
	// 		);
	// 	}
	// };

	// const handleEdited = useCallback(
	// 	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	// 	(event: any, inDoorMap: InDoorMap) => {
	// 		let polygon: unknown = '';

	// 		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	// 		event.layers.eachLayer((layer: any) => {
	// 			polygon = layer.editing?.latlngs[0][0];
	// 		});

	// 		dispatch(
	// 			editInDoorMap({
	// 				name: inDoorMap.name,
	// 				active: inDoorMap.active,
	// 				id: inDoorMap.id,
	// 				projectId: inDoorMap.projectId,
	// 				polygon: JSON.stringify(polygon as LatLngLiteral[]),
	// 			}),
	// 		);
	// 	},
	// 	[dispatch],
	// );

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	// const handleEditStart = () => {
	// 	setEditingPopup(false);
	// };

	function handleClickMap(event: LeafletMouseEvent) {
		setLatitude(event.latlng.lat);
		setLongitude(event.latlng.lng);
	}

	function handleClickCreate() {
		dispatch(
			addModalDialog({
				id: 'createInDoorMapModal',
				title: t('createInDoorMapModalTitle'),
				content: (
					<InDoorMapForm
						onSubmit={(values: InDoorMapFormValues) =>
							handleCreateInDoorMap(values, 'createInDoorMapModal')
						}
						onCancel={() => handleCloseModalDialog('createInDoorMapModal')}
					/>
				),
			}),
		);
	}

	function handleClickEdit(inDoorMap: InDoorMap) {
		// const coords = fromJSONToObject<LatLngLiteral[]>(inDoorMap.polygon);

		// if (coords) {
		dispatch(
			addModalDialog({
				id: 'editInDoorMapModal',
				title: t('editInDoorMapModalTitle'),
				content: (
					<InDoorMapForm
						onSubmit={(values: InDoorMapFormValues) =>
							handleEditInDoorMap(values, inDoorMap, 'editInDoorMapModal')
						}
						onCancel={() => handleCloseModalDialog('editInDoorMapModal')}
						values={{
							name: inDoorMap.name,
							geoJson: inDoorMap.layers[0].image,
						}}
					/>
				),
			}),
		);
		// }
	}

	function handleClickDelete(inDoorMap: InDoorMap) {
		// setEditingPopup(false);
		dispatch(
			addModalDialog({
				id: 'deleteInDoorMapModal',
				title: t('deleteInDoorMapModalTitle'),
				contentText: t('deleteInDoorMapModalDescription', {
					zone: inDoorMap.name,
				}),
				buttons: [
					{
						id: 'deleteInDoorMap',
						value: t('delete'),
						onClick: () => handleDeleteInDoorMap(inDoorMap.id, 'deleteInDoorMapModal'),
						startIcon: <DeleteForeverRoundedIcon />,
					},
				],
			}),
		);
	}

	const handleCreateInDoorMap = ({ name, geoJson }: InDoorMapFormValues, modalDialogId: string) => {
		const payload: CreateInDoorMapRequestBody = {
			name: name,
			active: true,
			layers: [
				{
					name: 'level',
					image: geoJson,
				},
			],
			// polygon: JSON.stringify(coords),
		};

		handleCloseModalDialog(modalDialogId);
		dispatch(createInDoorMap(payload));
	};

	const handleEditInDoorMap = (
		{ name, geoJson }: InDoorMapFormValues,
		inDoorMap: Omit<InDoorMap, 'polygon'>,
		modalDialogId: string,
	) => {
		const payload: EditInDoorMapRequestBody = {
			id: inDoorMap.id,
			name: name,
			active: inDoorMap.active,
			layers: [
				{
					name: 'level',
					image: geoJson,
				},
			],
			// polygon: JSON.stringify(coords),
		};

		handleCloseModalDialog(modalDialogId);
		dispatch(editInDoorMap(payload));
	};

	function handleDeleteInDoorMap(inDoorMapId: UUID, modalId: string) {
		dispatch(deleteInDoorMap(inDoorMapId));
		dispatch(removeModalDialog(modalId));

		// setSelectedInDoorMapId(undefined);
	}

	const onClose = () => {
		setSidebarColabsed(true);
	};

	const onOpen = (id: string) => {
		setSidebarColabsed(false);
		setSidebarSelected(id);
	};

	function handleClickIndoorMap(inDoorMap: InDoorMap) {
		// console.log(inDoorMap);
		// setSelectedMapId(inDoorMap.id);
		// setSelectedMapIndex(selectedMapIndex === 0 ? 1 : 0);

		try {
			const geojson: unknown = JSON.parse(inDoorMap.layers[0].image);
			// console.log('-----------------');
			// console.log(inDoorMap.layers[0].image);

			const unsafeFC = tryFeatureCollection(geojson as string);

			// console.info('successfully parsed feature collection');

			// console.log(unsafeFC);
			// console.log(geojson.features);
			// console.log('++++++++++++++++++');

			setGeo({ key: inDoorMap.id, image: unsafeFC });

			const bounds = getBoundsFromGeoJson(unsafeFC);

			// console.log(bounds);

			setMapBounds(bounds);

			// return tmp.push(geojson);
		} catch (exception) {
			console.log(exception);

			setGeo(undefined);

			dispatch(
				addToast({
					id: uuidv4(),
					title: t('info'),
					content: t('wrongGeojson'),
					severity: 'info',
					timeout: 6000,
				}),
			);
		}
	}

	// const selectedGeojson = inDoorMaps.find((map) => map.id === selectedMapId)?.layers[0].image;

	// console.log(selectedGeojson);
	// console.log(inDoorMaps[1].layers[0].image);

	const drawInDoorMapsTab = () => {
		return (
			<>
				{canEditInDoorMaps && (
					<>
						<Box height={SPACING} />
						<Tooltip title={String(t('createInDoorMap'))} aria-label={t('createInDoorMap')}>
							<IconButton onClick={() => handleClickCreate()}>
								<Add />
							</IconButton>
						</Tooltip>
					</>
				)}
				<InputField
					autoFocus
					label={'search'}
					margin="normal"
					name={'search maps'}
					onChange={(event: ChangeEvent<HTMLInputElement>) => setInDoorMapsSearchKey(event.target.value)}
				/>
				<List
					id="main-menu"
					style={{
						height: `calc(100vh - ${CONTENT_PADDING_SM_UP * SPACING * 2 + 210}px)`,
						overflow: 'auto',
					}}
				>
					{inDoorMaps
						?.filter((inDoorMap) => {
							const key = inDoorMapsSearchKey?.toLowerCase();

							return !key || inDoorMap.name.toLowerCase().includes(key);
						})
						.map((inDoorMap) => (
							<ListItem
								key={inDoorMap.id}
								button
								onClick={() => handleClickIndoorMap(inDoorMap)}
								selected={inDoorMap.id === geo?.key}
							>
								<ListItemText primary={inDoorMap.name} />
								<ListItemIcon>
									{inDoorMap.active ? (
										<CheckRounded htmlColor={COLOR_THEME.success} data-cy="check:active" />
									) : (
										<ClearRounded htmlColor={COLOR_THEME.error} data-cy="check:inactive" />
									)}
								</ListItemIcon>
								{canEditInDoorMaps && (
									<ListItemSecondaryAction>
										<IconButton edge="end" onClick={() => handleClickEdit(inDoorMap)}>
											<EditRounded />
										</IconButton>
										<IconButton edge="end" onClick={() => handleClickDelete(inDoorMap)}>
											<DeleteRounded />
										</IconButton>
									</ListItemSecondaryAction>
								)}
							</ListItem>
						))}
				</List>
			</>
		);
	};

	return (
		<>
			<MapContainer
				bounds={mapBounds}
				zoom={7}
				height={`calc(100vh - ${CONTENT_PADDING_SM_UP * SPACING * 2}px)`}
				boxShadow
				onClicked={(event) => handleClickMap(event)}
				sidebar={
					<Sidebar
						id="sidebar"
						collapsed={sidebarColabsed}
						selected={sidebarSelected}
						onOpen={(id) => onOpen(id)}
						onClose={onClose}
						position={'right'}
						closeIcon={<ArrowForwardIos />}
					>
						<Tab id="home" header="Info" icon={<InfoOutlined />} disabled>
							<Box height={SPACING * 3} />
							<Card>
								<CardHeader
									title={
										<Typography component="h2" variant="h6">
											{'help'}
										</Typography>
									}
								/>
								<p>info (TODO)</p>
							</Card>
							{/* <Box height={SPACING * 3} />
                <Card>
                    <CardHeader
                        title={
                            <Typography component="h2" variant="h6">
                                {t('')}
                            </Typography>
                        }
                    />
                </Card> */}
						</Tab>
						<Tab id="inDoorMaps" header={t('inDoorMaps')} icon={<BorderClearIcon />}>
							{drawInDoorMapsTab()}
						</Tab>
						<Tab id="settings" header="Settings" icon={<Settings />} anchor="bottom" disabled>
							<p>Settings dialogue.</p>
						</Tab>
					</Sidebar>
				}
			>
				<FeatureGroup>
					{geo && <GeoJSON key={geo.key} data={geo.image} onEachFeature={onEachFeature} />}
				</FeatureGroup>
			</MapContainer>
			<>
				lng = {longitude ?? '?'} | lat = {latitude ?? '?'}
			</>
		</>
	);
};

import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';
import { IngestionDotNetEndpointsTable } from './components/IngestionDotNetEndpointsTable';
import { IngestionScript, IngestionScriptsResponseData } from '../IngestionScripts/ingestion-scripts-types';
import { fetchRequest } from '../../services/helpers';
import { getFilteredIngestionScripts } from '../../services/ingestion-endpoints/ingestion-endpoints-service';
import { FilterOperators, SortOrder } from '../../components/Table/constants';
import { fetchTenants } from '../../services/user/user-service';
import { TenantsResponseData } from '../../services/user/user-types';
import { Tenant } from '../Tenants/tenants-types';

export const IngestionEndpoints = () => {
	const [allScripts, setAllScripts] = useState<IngestionScript[]>();

	const [tenants, setTenants] = useState<Tenant[]>();

	/**
	 * Fetching all tenants
	 */
	useEffect(() => {
		const request = fetchTenants({
			// Should be enough to get all zones, even for super admin
			limit: 200,
			offset: 0,
			orderings: [],
			filtersAndConditions: [],
		});

		(async () => {
			const { data, error } = await fetchRequest<TenantsResponseData>(request);

			if (error) {
				// TODO show error
			} else {
				setTenants(data?.data);
			}
		})();
	}, []);

	useEffect(() => {
		if (allScripts === undefined) {
			const request = getFilteredIngestionScripts({
				limit: 100,
				offset: 0,
				filtersAndConditions: [
					{
						column: 'OnlyParsers',
						operator: FilterOperators.equals,
						value: true,
					},
				],
				orderings: [
					{
						column: 'name',
						sortOrder: SortOrder.Descending,
					},
				],
			});

			(async () => {
				const { data, error } = await fetchRequest<IngestionScriptsResponseData>(request);

				if (error) {
					// TODO show error
				} else {
					if (data) {
						setAllScripts(data?.data);
					}
				}
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Grid container spacing={2} justify="center">
			<Grid item xs={12} lg={10}>
				<Paper>
					<IngestionDotNetEndpointsTable tenants={tenants || []} scripts={allScripts || []} />
				</Paper>
			</Grid>
		</Grid>
	);
};

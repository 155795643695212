export const FETCH_ZONES_REQUEST = '[ZONES_ACTIONS] FETCH_ZONES_REQUEST';
export const FETCH_ZONES_SUCCESS = '[ZONES_ACTIONS] FETCH_ZONES_SUCCESS';
export const FETCH_ZONES_SUCCESS2 = '[ZONES_ACTIONS] FETCH_ZONES_SUCCESS2';
export const FETCH_ZONES_ERROR = '[ZONES_ACTIONS] FETCH_ZONES_ERROR';

export const CREATE_ZONE_REQUEST = '[ZONES_ACTIONS] CREATE_ZONE_REQUEST';
export const CREATE_ZONE_SUCCESS = '[ZONES_ACTIONS] CREATE_ZONE_SUCCESS';
export const CREATE_ZONE_ERROR = '[ZONES_ACTIONS] CREATE_ZONE_ERROR';

export const EDIT_ZONE_REQUEST = '[ZONES_ACTIONS] EDIT_ZONE_REQUEST';
export const EDIT_ZONE_SUCCESS = '[ZONES_ACTIONS] EDIT_ZONE_SUCCESS';
export const EDIT_ZONE_ERROR = '[ZONES_ACTIONS] EDIT_ZONE_ERROR';

export const DELETE_ZONE_REQUEST = '[ZONES_ACTIONS] DELETE_ZONE_REQUEST';
export const DELETE_ZONE_SUCCESS = '[ZONES_ACTIONS] DELETE_ZONE_SUCCESS';
export const DELETE_ZONE_ERROR = '[ZONES_ACTIONS] DELETE_ZONE_ERROR';

export const FETCH_ZONE_REQUEST = '[ZONES_ACTIONS] FETCH_ZONE_REQUEST';
export const FETCH_ZONE_SUCCESS = '[ZONES_ACTIONS] FETCH_ZONE_SUCCESS';
export const FETCH_ZONE_ERROR = '[ZONES_ACTIONS] FETCH_ZONE_ERROR';

export const GET_ZONE_DETAIL = '[ZONES_ACTIONS] GET_ZONE_DETAIL';

export const UPLOAD_ZONES_REQUEST = '[ZONES_ACTIONS] UPLOAD_ZONES_REQUEST';
export const UPLOAD_ZONES_SUCCESS = '[ZONES_ACTIONS] UPLOAD_ZONES_SUCCESS';
export const UPLOAD_ZONES_ERROR = '[ZONES_ACTIONS] UPLOAD_ZONES_ERROR';

import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { ReportOrdersTable } from './components/ReportOrdersTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { UserState } from '../../store/user/user-types';
import { Grid } from '@material-ui/core';
import {
	createReportOrder,
	fetchReportOrders,
	getReportOrderDefinitionsNames,
} from '../../store/review/review-async-actions';
import { ReviewState } from '../../store/review/review-types';
import { useTranslation } from 'react-i18next';
import { addModalDialog, removeModalDialog } from '../../store/app/app-actions';
import { CreateReportOrderForm, ReportOrderFormValues } from './components/CreateReportOrderForm';
import { Add } from '@material-ui/icons';
import { Button } from '../../components/Button/Button';
import { hasPermission } from '../../helpers/auth';
import { PermissionsDotNet } from '../../helpers/generalConstants';

export const Review = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { data, total, limit, offset, orderings, filters } = useSelector<RootState, ReviewState>(
		(state) => state.review,
	);

	const { project, permissions } = useSelector<RootState, UserState>((state) => state.user);
	const canEditReports = hasPermission(PermissionsDotNet.ReportWrite, permissions);

	const { definitionsNames: reviewDefinitionNames } = useSelector<RootState, ReviewState>((state) => state.review);

	useEffect(() => {
		if (project?.id) {
			dispatch(
				fetchReportOrders({
					limit: limit,
					offset: offset,
					orderings: orderings,
					filtersAndConditions: filters,
				}),
			);
		}

		// Don't need to react on "config" and "filters" and deep comparision is not necessary
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, project]);

	useEffect(() => {
		dispatch(getReportOrderDefinitionsNames());
	}, [dispatch]);

	const handleCloseModal = (dialogId: string) => {
		dispatch(removeModalDialog(dialogId));
	};

	const handleOpenCreateReportOrderForm = () => {
		reviewDefinitionNames &&
			dispatch(
				addModalDialog({
					id: 'createReportOrderModal',
					title: t('createReportOrderModalTitle'),
					content: (
						<CreateReportOrderForm
							reviewDefinitionNames={reviewDefinitionNames}
							onSubmit={(values: ReportOrderFormValues) =>
								handleCreateReportOrder(values, 'createReportOrderModal')
							}
							onCancel={() => handleCloseModal('createReportOrderModal')}
						/>
					),
				}),
			);
	};

	const handleCreateReportOrder = (reportOrder: ReportOrderFormValues, dialogId: string) => {
		handleCloseModal(dialogId);
		dispatch(createReportOrder(reportOrder));
	};

	return (
		<Grid container spacing={4} justify="center" direction="row">
			{canEditReports && (
				<Grid item xs={12} lg={10}>
					<Button
						disabled={reviewDefinitionNames === undefined}
						startIcon={<Add />}
						onClick={() => handleOpenCreateReportOrderForm()}
						data-cy="btn-submit:scriptForm"
					>
						{t('createReportOrder')}
					</Button>
				</Grid>
			)}
			{/* <Grid item xs={12} lg={10}>
				<Paper>
					<StandingReportOrdersTable
						data={standingOrders.data}
						total={standingOrders.total}
						limit={standingOrders.limit}
						offset={standingOrders.offset}
						orderings={standingOrders.orderings}
						filters={standingOrders.filters}
					/>
				</Paper>
			</Grid> */}
			<Grid item xs={12} lg={10}>
				<Paper>
					<ReportOrdersTable
						data={data}
						total={total}
						limit={limit}
						offset={offset}
						orderings={orderings}
						filters={filters}
						reviewDefinitionNames={reviewDefinitionNames}
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};

import React from 'react';
import { Map, ScaleControl, TileLayer } from 'react-leaflet';
import { makeStyles } from '@material-ui/core';
import { Children } from '../../types';
import { LatLngBoundsLiteral, LatLngTuple, LeafletMouseEvent } from 'leaflet';
import GeoSearch from './GeoSearch';
import { Sidebar } from 'react-leaflet-sidebarv2';
import './sidebar.css';

type Props = {
	height: number | string;
	children: Children;
	// If bounds is present, center and zoom are not necessary
	bounds?: LatLngBoundsLiteral;
	center?: [number, number];
	zoom?: number;
	scrollWheelZoom?: boolean;
	boxShadow?: boolean;
	sidebar?: React.ReactElement<Sidebar>;
	onZoomed?: (zoom: number) => void;
	onClicked?: (event: LeafletMouseEvent) => void;
};

const useStyles = makeStyles({
	'leaflet-container': {
		height: ({ height }: Props) => height,
		boxShadow: ({ boxShadow }: Props) => (boxShadow ? '0 0 16px rgba(0,0,0,0.2)' : 'none'),
	},
});

export const MapContainer = (props: Props) => {
	const classes = useStyles(props);
	const { children, bounds, center, zoom = 15, scrollWheelZoom = true, sidebar, onZoomed, onClicked } = props;
	const defaultBounds = [49.49667452747045, 16.885986328125] as LatLngTuple;
	const hasBounds = Array.isArray(bounds) && bounds.length > 0;
	const mapCenter = center || defaultBounds;

	return (
		<div style={{ position: 'relative' }}>
			{sidebar}
			<Map
				className={`${'sidebar-map'} ${classes['leaflet-container']}`}
				bounds={hasBounds ? bounds : undefined}
				center={hasBounds ? undefined : mapCenter}
				zoom={hasBounds ? undefined : zoom}
				scrollWheelZoom={scrollWheelZoom}
				maxZoom={23}
				onzoomend={(event) => onZoomed && onZoomed(event.target._zoom)}
				onclick={(event) => onClicked && onClicked(event)}
			>
				<TileLayer
					attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					maxNativeZoom={19}
				/>
				<GeoSearch />
				<ScaleControl />
				{children}
			</Map>
		</div>
	);
};

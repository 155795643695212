import React from 'react';
import { Children } from '../../types';
import { Content } from './Content';

type Props = {
	children: Children;
};

export const SimpleLayout = ({ children }: Props) => {
	return (
		<>
			<Content>{children}</Content>
		</>
	);
};

import { action } from 'typesafe-actions';
import {
	GENERAL_VEHICLE_WATCH_REQUEST,
	GENERAL_VEHICLE_WATCH_ERROR,
	FETCH_DRIVERS_SUCCESS,
	FETCH_VEHICLES_SUCCESS,
} from './vehicle-watch-constants';
import { FiltersRequestBody2 } from '../../components/Table/types';
import { DevicePlacementsResponseData } from '../../pages/DevicePlacementsManagement/device-placements-types';

export const commonVehicleWatchEmptyRequest = () => action(GENERAL_VEHICLE_WATCH_REQUEST);
export const commonVehicleWatchEmptyError = () => action(GENERAL_VEHICLE_WATCH_ERROR);

export const fetchDriversSuccess = (data: DevicePlacementsResponseData, options?: FiltersRequestBody2) =>
	action(FETCH_DRIVERS_SUCCESS, { data, options });

export const fetchVehiclesSuccess = (data: DevicePlacementsResponseData, options?: FiltersRequestBody2) =>
	action(FETCH_VEHICLES_SUCCESS, { data, options });

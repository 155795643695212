import { createReducer } from 'typesafe-actions';
import {
	FETCH_INDOOR_MAPS_SUCCESS,
	CREATE_INDOOR_MAP_SUCCESS,
	EDIT_INDOOR_MAP_SUCCESS,
	DELETE_INDOOR_MAP_SUCCESS,
	INDOOR_MAPS_COMMON_EMPTY_REQUEST,
	INDOOR_MAPS_COMMON_EMPTY_ERROR,
} from './inDoorMaps-constants';

import { InDoorMapsActions, InDoorMapsState } from './inDoorMaps-types';
import { updateObjectInArray } from '../../helpers/array';

export const inDoorMapsInitialState: InDoorMapsState = {
	data: [],
	total: 0,
	offset: 0,
	limit: 10,
	orderings: [],
	filtersAndConditions: [],
};

export const inDoorMapsReducer = createReducer<InDoorMapsState, InDoorMapsActions>(inDoorMapsInitialState, {
	[INDOOR_MAPS_COMMON_EMPTY_REQUEST]: (state) => state,
	[INDOOR_MAPS_COMMON_EMPTY_ERROR]: (state) => state,
	[FETCH_INDOOR_MAPS_SUCCESS]: (state, action) => ({
		...state,
		data: action.payload.data.data,
		total: action.payload.data.total,
		limit: action.payload.options ? action.payload.options.limit : state.limit,
		offset: action.payload.options ? action.payload.options.offset : state.offset,
		orderings: action.payload.options?.orderings || state.orderings,
		filtersAndConditions: action.payload.options?.filtersAndConditions || state.filtersAndConditions,
	}),
	[CREATE_INDOOR_MAP_SUCCESS]: (state) => state,
	[EDIT_INDOOR_MAP_SUCCESS]: (state, action) => {
		const { data } = action.payload;
		const zoneIndex = state.data.findIndex((zone) => zone.id === data.id);

		return {
			...state,
			data: updateObjectInArray(data, zoneIndex, state.data),
		};
	},
	[DELETE_INDOOR_MAP_SUCCESS]: (state, action) => {
		return {
			...state,
			data: state.data.filter((zone) => zone.id !== action.payload.id),
		};
	},
});

import { isEmpty, isArray, has } from 'lodash/fp';
import { Nullable, Values, Value } from '../types';
import { Filter } from '../components/Table/types';

/**
 * check if nested value is valid
 * Keeps: booleans, not empty string
 */

const isValidFilterValue = (value: Values | Value | null) => {
	if (typeof value === 'boolean') {
		return true;
	}

	if (typeof value === 'string' && value !== '') {
		return true;
	}

	if (typeof value === 'number') {
		return true;
	}

	if (Array.isArray(value) && value.length) {
		return true;
	}

	return false;
};

/**
 * Removes empty, null, undefined values from an object
 * Values to be removed: [], {}, null, undefined, ''
 * Keeps: 0, false
 *
 * @param object - Object with empty, null, undefined values to be sanitized
 */

export const sanitizeEmptyValues = <T extends Record<string, unknown>>(object: T) => {
	const sanitized = Object.entries(object).reduce((acc, [key, value]) => {
		const emptyObject = typeof value === 'object' && isEmpty(value);
		const isEmptyValue = typeof value !== 'number' && typeof value !== 'boolean' && !value;

		if (!emptyObject && !isEmptyValue) {
			// Advanced filters with more possible values e.g.: date ranges, integers etc
			if (isArray(value)) {
				const filters = value;
				let result: Filter[] = [];

				result = filters.filter(
					(filter) => has('operator', filter) && has('value', filter) && isValidFilterValue(filter.value),
				);

				if (result.length > 0) {
					acc[key] = result;
				}
			}

			// Simple paging and sorting parameters
			else {
				acc[key] = value;
			}
		}

		return acc;
	}, {} as Record<string, unknown>);

	return sanitized as T;
};

/**
 * Converts JSON to Object, returning null if error occures
 *
 * @param json - JSON string to be parsed
 */
export const fromJSONToObject = <T>(json: string): Nullable<T> => {
	try {
		const obj = JSON.parse(json);

		return obj;
	} catch (error) {
		console.error(`An error occured while parsing JSON: ${error}`);

		return null;
	}
};

/**
 * Gets value from object
 *
 * @param obj - object with "value" key
 */
export const getObjectValue = <T extends { value: unknown }>(obj: T) => obj.value;

import * as pipelinesService from '../../services/pipelines/pipelines-service';
import { addToast } from '../app/app-actions';
import { fetchRequest, isStatusSuccess, createToastError } from '../../services/helpers';
import { AsyncAction } from '../types';
import {
	pipelinesCommonEmptyRequest,
	pipelinesCommonEmptyError,
	fetchPipelinesSuccess,
	createPipelineSuccess,
	editPipelineSuccess,
	deletePipelineSuccess,
	fetchJobsSuccess,
} from './pipelines-actions';
import { UUID } from '../../types';
import { omit } from 'lodash';
import { PipelinesResponseData, Pipeline, CreatePipelineRequestBody } from '../../pages/Pipelines/pipelines-types';
import { FiltersRequestBody2 } from '../../components/Table/types';
import { JobsResponseData } from '../../pages/Jobs/jobs-types';

export const fetchPipelines: AsyncAction<FiltersRequestBody2> = (payload) => async (dispatch) => {
	dispatch(pipelinesCommonEmptyRequest());

	const filterBody = payload;

	const request = pipelinesService.fetchPipelines(filterBody);

	const { data, error } = await fetchRequest<PipelinesResponseData>(request);

	if (data) {
		dispatch(fetchPipelinesSuccess(data, payload));
	} else {
		dispatch(pipelinesCommonEmptyError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data, () => dispatch(fetchPipelines(payload)))));
	}
};

export const createPipeline: AsyncAction<CreatePipelineRequestBody> = (body) => async (dispatch, getState) => {
	dispatch(pipelinesCommonEmptyRequest());

	const { pipelines } = getState();
	const request = pipelinesService.createPipeline(body);
	const { data, status, error } = await fetchRequest<Pipeline>(request);

	if (isStatusSuccess(status) && data) {
		dispatch(createPipelineSuccess(data));
		dispatch(
			fetchPipelines({
				limit: pipelines.limit,
				offset: pipelines.offset,
				filtersAndConditions: pipelines.filtersAndConditions,
				orderings: pipelines.orderings,
			}),
		);
	} else {
		dispatch(pipelinesCommonEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(createPipeline(body));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const editPipeline: AsyncAction<Pipeline> = (pipeline) => async (dispatch, getState) => {
	dispatch(pipelinesCommonEmptyRequest());

	const { pipelines } = getState();
	const body = omit(pipeline, ['id']);
	const request = pipelinesService.editPipeline(body, pipeline.id);
	const { data, error } = await fetchRequest<Pipeline>(request);

	if (data) {
		dispatch(editPipelineSuccess(data));
		dispatch(
			fetchPipelines({
				limit: pipelines.limit,
				offset: pipelines.offset,
				filtersAndConditions: pipelines.filtersAndConditions,
				orderings: pipelines.orderings,
			}),
		);
	} else {
		dispatch(pipelinesCommonEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(editPipeline(pipeline));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const deletePipeline: AsyncAction<UUID> = (pipelineId) => async (dispatch) => {
	dispatch(pipelinesCommonEmptyRequest());

	const request = pipelinesService.deletePipeline(pipelineId);
	const { status, error } = await fetchRequest<void>(request);

	if (isStatusSuccess(status) && pipelineId) {
		dispatch(deletePipelineSuccess(pipelineId));
	} else {
		dispatch(pipelinesCommonEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(deletePipeline(pipelineId));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const fetchJobs: AsyncAction<FiltersRequestBody2> = (payload) => async (dispatch) => {
	dispatch(pipelinesCommonEmptyRequest());

	const request = pipelinesService.getFilteredJobs(payload);
	const { data, error } = await fetchRequest<JobsResponseData>(request);

	if (data) {
		dispatch(fetchJobsSuccess(data, payload));
	} else {
		dispatch(pipelinesCommonEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(fetchJobs(payload));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

// export const createPipelineRule: AsyncAction<CreatePipelineRuleRequestBody> = (payload) => async (dispatch) => {
// 	dispatch(pipelinesCommonEmptyRequest());

// 	// const { pipelines } = getState();
// 	const request = pipelinesService.createPipelineRule(payload);
// 	const { data, status, error } = await fetchRequest<PipelineRule>(request);

// 	if (isStatusSuccess(status) && data) {
// 		dispatch(createPipelineRuleSuccess());
// 		// dispatch(
// 		// 	fetchPipelineRules({
// 		// 		limit: pipelines.rules.limit,
// 		// 		offset: pipelines.rules.offset,
// 		// 		orderings: pipelines.rules.orderings,
// 		// 		filtersAndConditions: pipelines.rules.filtersAndConditions,
// 		// 	}),
// 		// );
// 	} else {
// 		dispatch(pipelinesCommonEmptyError());
// 	}

// 	if (error) {
// 		const tryAgainAction = () => dispatch(createPipelineRule(payload));

// 		dispatch(addToast(createToastError(error.data, tryAgainAction)));
// 	}
// };

// export const updatePipelineRule: AsyncAction<UpdatePipelineRuleRequestBody> = (payload) => async (
// 	dispatch,
// 	getState,
// ) => {
// 	dispatch(pipelinesCommonEmptyRequest());

// 	const request = pipelinesService.updatePipelineRule(payload, payload.id);
// 	const { data, error } = await fetchRequest<PipelineRule>(request);

// 	if (data) {
// 		dispatch(updatePipelineRuleSuccess(data));
// 	} else {
// 		dispatch(pipelinesCommonEmptyError());
// 	}

// 	if (error) {
// 		const tryAgainAction = () => dispatch(updatePipelineRule(payload));

// 		dispatch(addToast(createToastError(error.data, tryAgainAction)));
// 	}
// };

// export const deletePipelineRule: AsyncAction<UUID> = (pipelineRuleId) => async (dispatch) => {
// 	dispatch(pipelinesCommonEmptyRequest());

// 	const request = pipelinesService.deletePipelineRule(pipelineRuleId);
// 	const { status, error } = await fetchRequest<void>(request);

// 	if (isStatusSuccess(status) && pipelineRuleId) {
// 		dispatch(deletePipelineRuleSuccess(pipelineRuleId));
// 	} else {
// 		dispatch(pipelinesCommonEmptyError());
// 	}

// 	if (error) {
// 		const tryAgainAction = () => dispatch(deletePipelineRule(pipelineRuleId));

// 		dispatch(addToast(createToastError(error.data, tryAgainAction)));
// 	}
// };

// Allow plural keys like: key_plural, etc.

import { globalTranslations } from './global';
import { ErrorCodes } from '../services/common-types';

export const en = {
	translation: {
		...globalTranslations,
		error: 'Error',
		[ErrorCodes['SYS-001']]: 'Server error, try again later.',
		validationErrors: {
			required: 'This field is required.',
			emptyEmail: 'Please fill in your email address.',
			invalidEmail: 'Invalid email address.',
			passwordEmpty: 'Please fill in your password.',
			invalidPassword:
				'Password must contain at least 1 letter, 1 number and has to be minimum 8 characters long with no spaces.',
			newPasswordMatchesOldPassword: 'New password has to be different from old password.',
			min: 'Choose at least {{count}} item.',
			minPlural: 'Choose at least {{count}} items.',
			number: 'Please fill in a number.',
			polygon: 'Polygon needs at least 3 coordinates.',
			invalidDateMessage: 'Invalid date format. Should be {{format}}.',
			maxDateMessage: 'Date should not be after: {{date}}.',
			minDateMessage: 'Date should not be before: {{date}}.',
			bodyParser: 'Parser has to start with bodyParser statement.',
			noOptionalChainingAndArrow: 'Optional chaining (?.) and arrow functions (=>) not allowed.',
			bracketMissing: 'Bracket missing.',
			missingFields: 'You have to fill all fields.',
			invalidPathMissingSlash: 'Path has to start with slash "/", e.g: /endpoint.',
		},
		selectedProject: 'Current project',
		dashboard: 'Dashboard (Translation service out)',
		maps: 'Maps',
		devices: 'Devices',
		zones: 'Zones',
		workflows: 'Workflows',
		billing: 'Billing',
		users: 'Users',
		ingestionEndpoints: 'Ingestion Endpoints',
		devicesStatus: 'Devices Status',
		noData: 'No data found.',
		tryAgain: 'Try again',
		actions: 'Actions',
		edit: 'Edit',
		cancel: 'Cancel',
		save: 'Save',
		id: 'ID',
		firstName: 'First name',
		lastName: 'Last name',
		active: 'Active',
		inactive: 'Inactive',
		lastLogin: 'Last login',
		createUser: 'Create user',
		tenantId: 'Tenant ID',
		tenant: 'Tenant',
		superAdmin: 'Super admin',
		createUserModalTitle: 'Create user',
		createUserModalDescription: 'You can create a new user using this form. All fields are required.',
		editUserFormTitle: 'Edit user data',
		editProjectsFormTitle: 'Edit projects and roles',
		deleteUserModalTitle: 'Delete user',
		deleteUserModalDescription: 'Are you sure you want to delete user {{firstName}} {{lastName}}?',
		delete: 'Delete',
		settings: 'Settings',
		logout: 'Log out',
		signIn: 'Sign in',
		email: 'Email',
		password: 'Password',
		forgotPassword: 'Forgot password?',
		newPassword: 'New password',
		setPassword: 'Set password',
		changePassword: 'Change password',
		url: 'URL',
		payloadParser: 'Payload parser',
		createIngestionEndpoint: 'Create ingestion endpoint',
		createIngestionEndpointModalTitle: 'Create ingestion endpoint',
		createIngestionEndpointModalDescription: 'You can create a new ingestion endpoint using this form.',
		deleteIngestionEndpointModalTitle: 'Delete ingestion endpoint',
		deleteIngestionEndpointModalDescription: 'Are you sure you want to delete ingestion endpoint {{endpoint}}?',
		editIngestionEndpointModalTitle: 'Edit ingestion endpoint',
		editIngestionEndpointModalDescription: "You can edit this ingestion endpoint's data using this form.",
		selectUserRoles: 'Select user roles',
		noOptions: 'No options left',
		projectsAndRoles: 'Projects and roles',
		USER: 'User',
		PROJECT_ADMIN: 'Project admin',
		WF_WRITE: 'Workflow user',
		WF_READ: 'Workflow admin',
		INGEST_READ: 'Ingest user',
		INGEST_WRITE: 'Ingest admin',
		type: 'Type',
		battery: 'Battery',
		temperature: 'Temperature',
		accuracy: 'Position Accuracy',
		time: 'Time',
		userDetail: 'User detail',
		selectEndpointMethod: 'Select endpoint method',
		method: 'Method',
		projects: 'Projects',
		name: 'Name',
		description: 'Description',
		latitude: 'Latitude',
		longitude: 'Longitude',
		remove: 'Remove',
		add: 'Add',
		editZonePolygonModalTitle: 'Edit zone',
		createZonePolygonModalTitle: 'Create zone',
		deleteZoneModalTitle: 'Delete zone',
		deleteZoneModalDescription: 'Are you sure you want to delete zone {{zone}}?',
		back: 'Back',
		createProject: 'Create project',
		editProjectModalTitle: 'Edit project',
		deleteProjectModalTitle: 'Delete project',
		deleteProjectModalDescription: 'Are you sure you want to delete project {{project}}?',
		createProjectModalTitle: 'Create project',
		groups: 'Groups',
		dateCreated: 'Date created',
		dateUpdated: 'Date updated',
		createGroup: 'Create Group',
		deleteGroup: 'Delete group',
		createGroupModalTitle: 'Create Group',
		deleteGroupModalTitle: 'Delete Group',
		deleteGroupModalDescription: 'Are you sure you want to delete {{name}}?',
		group: 'Group',
		editGroupModal: 'Edit group',
		groupDetail: 'Group detail',
		updateDeviceModalTitle: 'Edit device',
		sn: 'Serial number',
		networkNumber: 'Network number',
		removeDevices: 'Remove devices',
		removeDevicesModalTitle: 'Remove selected devices from this group?',
		otherGroups: 'Other groups and uncategorized devices',
		workflowsCount: 'Workflows',
		groupsCount: 'Groups',
		usersCount: 'Users',
		devicesCount: 'Devices',
		filters: 'Filters',
		all: 'All',
		reset: 'Reset',
		createWorkflow: 'Create workflow',
		deleteWorkflowModalTitle: 'Delete workflow',
		deleteWorkflowModalDescription: 'Are you sure you want to delete {{name}}?',
		addSituation: 'Add situation',
		addSituationModalTitle: 'Add situation',
		notifications: 'Notifications',
		notificationType: 'Notification type',
		tasks: 'Tasks',
		editSituationModalTitle: 'Edit situation',
		variables: 'Variables',
		conditions: 'Conditions',
		situations: 'Situations',
		loginFrom: 'Login date from',
		loginTo: 'Login date to',
		createZone: 'Create zone',
		groupName: 'Group name',
		highestTemp: 'Higest temperature',
		countLastWeek: 'Count since last week',
		lastErrorDate: 'Last error date',
		report: 'Report',
		reports: 'Reports',
		reportOrder: 'Report Order',
		reportOrders: 'Report Orders',
		standingReportOrder: 'Standing Report Order',
		standingReportOrders: 'Standing Report Orders',
		createReportOrder: 'Report Order',
		createReportOrderModalTitle: 'Report Order',
		confirm: 'Confirm',
		notificationModalTitle: 'Notifications',
		severity: 'Severity',
		message: 'Message',
		warning: 'Warning',
		success: 'Success',
		info: 'Info',
		moreItems: '{{first}} + {{count}} more',
		reportings: 'Reportings',
		saveWorkflow: 'Save workflow',
		lastSeen: 'Last seen',
		lastLocation: 'Last location',
		lastLocationTime: 'Last location time',
		device: 'Device',
		detail: 'Detail',
		lastWeek: 'Last 7 days',
		lastTwoWeeks: 'Last 14 days',
		lastMonth: 'Last month',
		lastTwoMonths: 'Last 2 months',
		useRange: 'Use date range',
		from: 'From',
		to: 'To',
		submit: 'Submit',
		events: 'Events',
		lightIntensity: 'Light intensity',
		maxAcceleration: 'Maximum acceleration',
		maxAccelerationHistory: 'Maximum acceleration (history)',
		yes: 'Yes',
		no: 'No',
		query: 'Query',
		selectedZone: 'Selected zone: {{zone}}',
		createDevice: 'Create device',
		uploadDevices: 'Upload devices',
		deviceDetail: 'Device detail',
		lastSignal: 'Last signal',
		telemetryHistory: 'Telemetry history',
		heat: 'Heat',
		trail: 'Trail',
		location: 'Location',
		moreOption: 'More options',
		mapType: 'Map type',
		zoneId: 'Zone ID',
		removeFromGroup: 'Remove from group',
		addToGroup: 'Add to group',
		currentTelemetry: 'Current telemetry',
		lastUpdate: 'Last update: {{date}}',
		devicesLastTelemetry: 'Last telemetry data from each device',
		unsavedChanges:
			'Unsaved changes!\n\nYou have unsaved changes, do you wish to continue? Your changes will be lost.',
		zoneEntryCount: 'Zone entry count',
		averageTimeInZone: 'Average time in zone',
		deviceCountInZone: 'Device count in the zone',
		selectQuery: 'Select query',
		selectZone: 'Select zone',
		currentNotifications: 'Current notifications',
		currentReports: 'Current reports',
		currentSummary: 'Current Summary',
		hasMovedWithoutNewLocation: 'Moved with no location',
		moved: 'Moved',
		locationUpdateDetails: 'Localization Method',
		bizStep: 'BIZ_STEP',
		action: 'Action',
		eventType: 'Event type',
		actDescription: 'ACT',
		signalPower: 'Signal power',
		eventTime: 'Event time',
		showHideColumns: 'Show/hide columns',
		usersManagement: 'Users management',
		noLocation: 'Unknown location',
		zone: 'Zone',
		missingProjectModalTitle: 'Missing project',
		missingProjectModalDescription:
			"User {{name}} doesn't have access rights to any project! Please ask the appropriate administrators for them.",
		zoom: 'Zoom',
		uploadGeoJSON: 'Upload from GeoJSON',
		close: 'Close',
		refresh: 'Refresh',
		telemetry: 'Telemetry',
		placement: 'Placement',
		placements: 'Placements',
		attached: 'Attached',
		attach: 'Attach',
		detached: 'Detached',
		detach: 'Detach',
		charts: 'Charts',
		attachPlacementModalTitle: 'Attach Placement',
		createPlacement: 'Create placement',
		value: 'Value',
		classificationId: 'Classification ID',
		deletePlacementModalTitle: 'Delete placement',
		deletePlacementModalDescription: 'Are you sure you want to delete placement: {{name}}?',
		createPlacementModalTitle: 'Create placement',
		editPlacementModalTitle: 'Edit placement',
		classification: 'Classification',
		classifications: 'Classifications',
		deleteClassificationModalTitle: 'Delete classification',
		deleteClassificationModalDescription: 'Are you sure you want to delete classification: {{name}}?',
		createClassificationModalTitle: 'Create classification',
		editClassificationModalTitle: 'Edit classification',
		createClassification: 'Create classification',
		attachedDevices: 'Attached devices',
		attachedDevicesModalTitle: 'List of attached devices',
		noAttachedDevices: 'There are no attached devices to this placement.',
		choosePlacement: 'Choose placement',
		lastMessage: 'Last message',
		messageCounter: 'Message counter',
		sinceConnected: 'Since connected',
		attachDevice: 'Attach Device',
		chooseDevice: 'Choose Device',
		//
		manufacturer: 'Manufacturer',
		packageNumber: 'Packaging Number',
		serialNumber: 'Serial Number',
		partDescription: 'Part description (packaging)',
		project: 'Project',
		note: 'Note',
		assetDescription: 'Asset Description',
		assetName:'Asset Name',
		//
		exportDevicesToExcel: 'Export to Excel',
		//
		configuration: 'Configuration',
		management: 'Management',
		favourites: 'Favourites',
		//
		driverid: 'Driver ID',
		carType: 'Car Type',
		SPZ: 'Car ID',
		//
		script: 'Script',
		ingestionScripts: 'Ingestion Scripts',
		ingestionScriptDetail: 'Ingestion Script Detail',
		createScript: 'Create Script',
		validate: 'Validate',
		validating: 'Validating ...',
		deleteScriptModalTitle: 'Delete script',
		deleteScriptModalDescription: 'Are you sure you want to delete script: {{script}}?',
		selectScript: 'Select Script',
		testScript: 'Test Script',
		testingScript: 'Testing Script ...',
		selectGroup: 'Select Group',
		reportType: 'Report Type',
		reportStatus: 'Status',
		parameters: 'Parameters',
		summary: 'Summary',
		selectTenant: 'Select Tenant',
		// review report state
		startedStatus: 'Processing',
		errorStatus: 'Error',
		finishedStatus: 'Finished',
	},
};

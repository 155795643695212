import React, { useCallback } from 'react';
import { LayerEvent, LeafletEvent } from 'leaflet';
import { FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { Zone } from '../../pages/Zones/zones-types';
import { Children } from '../../types';
import { EditControlProps } from './types';

type LeafletDrawControl = Partial<EditControlProps>;
type OwnProps = {
	children: Children;
	showDrawControl: boolean;
	showEditButton: boolean;
	zone: Zone;
	onCreated: (event: LeafletEvent) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onEdited: (event: any, zone: Zone) => void;
	onEditStart: () => void;
};

type Props = LeafletDrawControl & OwnProps;

// TODO: now can be used only with Zone, refactor for general or move to Zones folder
export const EditableLayer = React.forwardRef(
	(
		{
			children,
			draw = {
				polygon: true,
				circle: false,
				polyline: false,
				rectangle: false,
				circlemarker: false,
				marker: false,
			},
			onCreated,
			onEdited,
			onEditStart,
			showDrawControl,
			showEditButton,
			zone,
		}: Props,
		ref,
	) => {
		const handleEdit = useCallback(
			(event: LayerEvent) => onEdited(event, zone),
			// zone cannot be in dependencies, because I cannot do a deep comparision check
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[onEdited],
		);

		return (
			<FeatureGroup>
				{showDrawControl && (
					<EditControl
						ref={ref}
						position="topright"
						onCreated={onCreated}
						onEdited={handleEdit}
						onEditStart={onEditStart}
						draw={draw}
						edit={{
							remove: false,
							edit: showEditButton,
						}}
					/>
				)}
				{children}
			</FeatureGroup>
		);
	},
);

// import { GeoJSON } from 'geojson';
import * as inDoorMapsService from '../../services/inDoorMaps/inDoorMaps-service';
import { addToast } from '../app/app-actions';
import { fetchRequest, isStatusSuccess, createToastError } from '../../services/helpers';
import { AsyncAction, RootState } from '../types';

import {
	commonInDoorMapsEmptyRequest,
	commonInDoorMapsEmptyError,
	fetchInDoorMapsSuccess,
	createInDoorMapSuccess,
	editInDoorMapSuccess,
	deleteInDoorMapSuccess,
} from './inDoorMaps-actions';

import { UUID } from '../../types';
import { omit } from 'lodash';
import { FiltersRequestBody2 } from '../../components/Table/types';
import {
	CreateInDoorMapRequestBody,
	EditInDoorMapRequestBody,
	InDoorMap,
	InDoorMapsResponseData,
} from '../../pages/InDoorMaps/indoormaps-types';

export const fetchInDoorMaps: AsyncAction<FiltersRequestBody2> = (inDoorMapsPayload) => async (dispatch) => {
	dispatch(commonInDoorMapsEmptyRequest());

	const filterBody = inDoorMapsPayload;

	// const filterBody = sanitizeEmptyValues<ZonesRequestBody>({
	// 	...zonesPayload.config,
	// 	...zonesPayload.filters,
	// });

	const request = inDoorMapsService.getFilteredInDoorMaps(filterBody);

	const { data, error } = await fetchRequest<InDoorMapsResponseData>(request);

	if (data) {
		dispatch(fetchInDoorMapsSuccess(data, inDoorMapsPayload));
	} else {
		dispatch(commonInDoorMapsEmptyError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data, () => dispatch(fetchInDoorMaps(inDoorMapsPayload)))));
	}
};

export const createInDoorMap: AsyncAction<CreateInDoorMapRequestBody> = (inDoorMap) => async (dispatch, getState) => {
	dispatch(commonInDoorMapsEmptyRequest());

	const { inDoorMaps } = getState();
	const request = inDoorMapsService.createInDoorMap(inDoorMap);
	const { data, status, error } = await fetchRequest<InDoorMap>(request);

	if (isStatusSuccess(status) && data) {
		dispatch(createInDoorMapSuccess());
		dispatch(
			fetchInDoorMaps({
				limit: inDoorMaps.limit,
				offset: inDoorMaps.offset,
				filtersAndConditions: inDoorMaps.filtersAndConditions,
				orderings: inDoorMaps.orderings,
			}),
		);
	} else {
		dispatch(commonInDoorMapsEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(createInDoorMap(inDoorMap));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const editInDoorMap: AsyncAction<EditInDoorMapRequestBody> = (inDoorMap) => async (dispatch) => {
	dispatch(commonInDoorMapsEmptyRequest());

	const body = omit(inDoorMap, ['id']);
	const request = inDoorMapsService.editInDoorMap(body, inDoorMap.id);
	const { data, error } = await fetchRequest<InDoorMap>(request);

	if (data) {
		dispatch(editInDoorMapSuccess(data));
	} else {
		dispatch(commonInDoorMapsEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(editInDoorMap(inDoorMap));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const deleteInDoorMap: AsyncAction<UUID> = (inDoorMapId) => async (dispatch, getState) => {
	dispatch(commonInDoorMapsEmptyRequest());

	const { inDoorMaps }: RootState = getState();
	const request = inDoorMapsService.deleteInDoorMap(inDoorMapId);
	const { status, error } = await fetchRequest<void>(request);

	if (isStatusSuccess(status) && inDoorMapId) {
		dispatch(deleteInDoorMapSuccess(inDoorMapId));
		dispatch(
			fetchInDoorMaps({
				limit: inDoorMaps.limit,
				offset: inDoorMaps.offset,
				filtersAndConditions: inDoorMaps.filtersAndConditions,
				orderings: inDoorMaps.orderings,
			}),
		);
	} else {
		dispatch(commonInDoorMapsEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(deleteInDoorMap(inDoorMapId));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

import React from 'react';
import { Table } from '../../../components/Table/Table';
import { useTranslation } from 'react-i18next';
import { TableRowRenderProps } from '../../../components/Table/types';
import { Report, ReportGroup } from '../reports-types';
import { TableRow, TableCell } from '@material-ui/core';
import { prepareReportData } from '../helpers';

type Props = {
	data: ReportGroup[];
	limit: number;
	offset: number;
	total: number;
};

export const ReportsTable = ({ data, total, limit, offset }: Props) => {
	const { t } = useTranslation();

	const columns = data.map(({ groupName }) => ({
		id: groupName,
		label: groupName,
	}));

	const renderTableRow = ({ row }: TableRowRenderProps<Report>) => {
		return (
			<TableRow key={row.id} id={`row-${row.id}`}>
				{Object.values(row).map((item) => (
					<TableCell key={item as string | number}>{item as string | number}</TableCell>
				))}
			</TableRow>
		);
	};

	const reports = prepareReportData(data);

	return (
		<>
			<Table // TODO
				columns={[{ id: 'reportings', label: t('reportings') }, ...columns]}
				data={reports}
				dataLimit={limit}
				dataOffset={offset}
				numberOfRows={total}
				// fetchAction={fetchReports}
				selectable={false}
				renderTableRow={renderTableRow}
			/>
		</>
	);
};

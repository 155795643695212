import { GeoJSON } from 'geojson';
import * as zonesService from '../../services/zones/zones-service';
import { addToast } from '../app/app-actions';
import { fetchRequest, isStatusSuccess, createToastError } from '../../services/helpers';
import { AsyncAction, RootState } from '../types';

import {
	fetchZonesRequest,
	fetchZonesSuccess,
	fetchZonesError,
	createZoneRequest,
	createZoneSuccess,
	createZoneError,
	editZoneRequest,
	editZoneSuccess,
	editZoneError,
	deleteZoneRequest,
	deleteZoneSuccess,
	deleteZoneError,
	uploadZonesRequest,
	uploadZonesSuccess,
	uploadZonesError,
	fetchZonesSuccess2,
} from './zones-actions';

import { UUID } from '../../types';
import { omit } from 'lodash';
import {
	ZonesResponseData,
	EditZonePayload,
	ZoneResponseData,
	CreateZonePayload,
	ZonesRequestBody,
	ZonesPayload,
} from '../../pages/Zones/zones-types';
import { sanitizeEmptyValues } from '../../helpers/object';
import { FiltersRequestBody2 } from '../../components/Table/types';

export const fetchZones: AsyncAction<ZonesPayload> = (zonesPayload) => async (dispatch) => {
	dispatch(fetchZonesRequest());

	const filterBody = sanitizeEmptyValues<ZonesRequestBody>({
		...zonesPayload.config,
		...zonesPayload.filters,
	});

	const request = zonesService.getFilteredZones(filterBody);
	const { data, error } = await fetchRequest<ZonesResponseData>(request);

	if (data) {
		dispatch(fetchZonesSuccess(data, zonesPayload));
	}

	if (error) {
		dispatch(fetchZonesError());
		dispatch(addToast(createToastError(error.data, () => dispatch(fetchZones(zonesPayload)))));
	}
};

export const fetchZones2: AsyncAction<FiltersRequestBody2> = (zonesPayload) => async (dispatch) => {
	dispatch(fetchZonesRequest());

	const filterBody = zonesPayload;

	// const filterBody = sanitizeEmptyValues<ZonesRequestBody>({
	// 	...zonesPayload.config,
	// 	...zonesPayload.filters,
	// });

	const request = zonesService.getFilteredZones2(filterBody);

	const { data, error } = await fetchRequest<ZonesResponseData>(request);

	if (data) {
		dispatch(fetchZonesSuccess2(data, zonesPayload));
	} else {
		dispatch(fetchZonesError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data, () => dispatch(fetchZones2(zonesPayload)))));
	}
};

export const editZone: AsyncAction<EditZonePayload> = (zone) => async (dispatch) => {
	dispatch(editZoneRequest());

	const body = omit(zone, ['id']);
	const request = zonesService.editZone(body, zone.id);
	const { data, error } = await fetchRequest<ZoneResponseData>(request);

	if (data) {
		dispatch(editZoneSuccess(data));
	}

	if (error) {
		const tryAgainAction = () => dispatch(editZone(zone));

		dispatch(editZoneError());
		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const createZone: AsyncAction<CreateZonePayload> = (zone) => async (dispatch, getState) => {
	dispatch(createZoneRequest());

	const { zones } = getState();
	const request = zonesService.createZone(zone);
	const { data, status, error } = await fetchRequest<ZoneResponseData>(request);

	if (isStatusSuccess(status) && data) {
		dispatch(createZoneSuccess());
		dispatch(
			fetchZones2({
				limit: zones.config.limit,
				offset: zones.config.offset,
				filtersAndConditions: zones.filters2,
				orderings: zones.orderings,
			}),
		);
	}

	if (error) {
		const tryAgainAction = () => dispatch(createZone(zone));

		dispatch(createZoneError());
		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const deleteZone: AsyncAction<UUID> = (zoneId) => async (dispatch, getState) => {
	dispatch(deleteZoneRequest());

	const { zones }: RootState = getState();
	const request = zonesService.deleteZone(zoneId);
	const { status, error } = await fetchRequest<void>(request);

	if (isStatusSuccess(status) && zoneId) {
		dispatch(deleteZoneSuccess(zoneId));
		dispatch(
			fetchZones2({
				limit: zones.config.limit,
				offset: zones.config.offset,
				filtersAndConditions: zones.filters2,
				orderings: zones.orderings,
			}),
		);
	}

	if (error) {
		const tryAgainAction = () => dispatch(deleteZone(zoneId));

		dispatch(deleteZoneError());
		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

// export const fetchZone: AsyncAction<UUID> = (zoneId) => async (dispatch) => {
// 	dispatch(fetchZoneRequest());

// 	const request = zonesService.getFilteredZones({
// 		id: [
// 			{
// 				value: zoneId,
// 				operator: FilterOperators.equals,
// 			},
// 		],
// 	});

// 	const { data, error } = await fetchRequest<ZonesResponseData>(request);

// 	if (data) {
// 		dispatch(fetchZoneSuccess(data.data[0]));
// 	}

// 	if (error) {
// 		const tryAgainAction = () => dispatch(fetchZone(zoneId));

// 		dispatch(fetchZoneError());
// 		dispatch(addToast(createToastError(error.data, tryAgainAction)));
// 	}
// };

export const uploadZones: AsyncAction<GeoJSON> = (uploadZonesPayload) => async (dispatch, getState) => {
	dispatch(uploadZonesRequest());

	const { zones } = getState();
	const request = zonesService.uploadZones(uploadZonesPayload);
	const { data, status, error } = await fetchRequest<ZoneResponseData>(request);

	if (isStatusSuccess(status) && data) {
		dispatch(uploadZonesSuccess());
		dispatch(
			fetchZones2({
				limit: zones.config.limit,
				offset: zones.config.offset,
				filtersAndConditions: zones.filters2,
				orderings: zones.orderings,
			}),
		);
	}

	if (error) {
		const tryAgainAction = () => dispatch(uploadZones(uploadZonesPayload));

		dispatch(uploadZonesError());
		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

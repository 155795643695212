import React, { useEffect } from 'react';
import { TableHeadCell, TableRowRenderProps } from '../../../components/Table/types';
import { Table } from '../../../components/Table/Table';
import { useTranslation } from 'react-i18next';
import { FilterOperators, FilterType, TableRowActionIcon } from '../../../components/Table/constants';
import { addModalDialog, removeModalDialog } from '../../../store/app/app-actions';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip, TableRow, TableCell, IconButton, CardHeader, Divider, Typography } from '@material-ui/core';
import { UUID } from '../../../types';
import { pick } from 'lodash/fp';
import { TableActionsContainer } from '../../../components/Table/TableActionsContainer';
import { RootState } from '../../../store/types';
import {
	createUserRole,
	deleteUserRole,
	editUserRole,
	fetchUserRoles,
} from '../../../store/user-management/user-management-async-actions';
import { UserManagementState } from '../../../store/user-management/user-management-types';
import { CreateUserRoleRequestBody, EditUserRoleRequestBody, UserRole } from '../user-roles-types';
import { UserRolesForm } from './UserRolesForm';
import { Add, DeleteForever } from '@material-ui/icons';
import { Permission } from '../../../services/user/user-types';
import { hasPermission } from '../../../helpers/auth';
import { PermissionsDotNet } from '../../../helpers/generalConstants';
import { UserState } from '../../../store/user/user-types';

type Props = {
	permissions: Permission[];
};

export const UserRolesTable = ({ permissions }: Props) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { permissions: userPermissions } = useSelector<RootState, UserState>((state) => state.user);
	const canEditRoles = hasPermission(PermissionsDotNet.RoleWrite, userPermissions);
	const { userRoles } = useSelector<RootState, UserManagementState>((state) => state.users);

	useEffect(() => {
		dispatch(
			fetchUserRoles({
				limit: userRoles.limit,
				offset: userRoles.offset,
				orderings: userRoles.orderings,
				filtersAndConditions: userRoles.filters,
			}),
		);

		// Don't need to react on "config" and "filters" and deep comparision is not necessary
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const nameOrder = userRoles.orderings?.find((order) => order.column === 'name');
	const nameFilter = userRoles.filters?.find((filter) => filter.column === 'name');

	const roleTypeOrder = userRoles.orderings?.find((order) => order.column === 'roleType');

	const columns: TableHeadCell[] = [
		{
			id: 'name',
			label: t('name'),
			filters: nameFilter && [
				{
					column: 'name',
					operator: FilterOperators.contains,
					value: nameFilter.value,
				},
			],
			filterType: FilterType.String,
			sort: nameOrder?.sortOrder && { column: 'name', sortOrder: nameOrder?.sortOrder },
		},
		{
			id: 'roleType',
			label: t('type'),
			sort: roleTypeOrder?.sortOrder && { column: 'roleType', sortOrder: roleTypeOrder?.sortOrder },
		},
		{
			id: 'permissions',
			label: t('permissions'),
			unorderable: true,
		},
	];

	const handleCloseModal = (id: string) => {
		dispatch(removeModalDialog(id));
	};

	const handleCreateUserRole = (data: CreateUserRoleRequestBody, modalDialogId: string) => {
		handleCloseModal(modalDialogId);

		dispatch(createUserRole(pick(['name', 'roleType', 'permissions'], data)));
	};

	const handleEditUserRole = (data: EditUserRoleRequestBody, modalDialogId: string) => {
		handleCloseModal(modalDialogId);

		dispatch(editUserRole(pick(['id', 'name', 'roleType', 'permissions'], data)));
	};

	const handleDeleteUserRole = (userRoleId: UUID, modalDialogId: string) => {
		handleCloseModal(modalDialogId);
		dispatch(deleteUserRole(userRoleId));
	};

	const rowActions = [
		{
			id: 'btn-edit:userrole',
			icon: TableRowActionIcon.edit,
			tooltip: t('edit'),
			onClick: (data: UserRole) => {
				dispatch(
					addModalDialog({
						id: 'editUserRoleModal',
						title: t('editUserRoleModalTitle'),
						contentText: t('editUserRoleModalDescription'),
						content: (
							<UserRolesForm
								userRole={data}
								onSubmit={(values) => {
									handleEditUserRole(values, 'editUserRoleModal');
								}}
								onCancel={() => handleCloseModal('editUserRoleModal')}
								permissionDefinitions={permissions}
							/>
						),
					}),
				);
			},
		},
		{
			id: 'btn-delete:userrole',
			icon: TableRowActionIcon.delete,
			tooltip: t('delete'),
			onClick: (data: UserRole) =>
				dispatch(
					addModalDialog({
						id: 'deleteUserRoleModal',
						title: t('deleteUserRoleModalTitle'),
						contentText: t('deleteUserRoleModalDescription', {
							path: data.name,
						}),
						buttons: [
							{
								id: 'deleteUserRole',
								value: t('delete'),
								onClick: () => handleDeleteUserRole(data.id, 'deleteUserRoleModal'),
								startIcon: <DeleteForever />,
							},
							{
								id: 'cancelDeleteUserRole',
								value: t('cancel'),
								onClick: () => handleCloseModal('deleteUserRoleModal'),
								variant: 'text',
							},
						],
					}),
				),
		},
	];

	function renderPermissions(role: UserRole) {
		return (
			<Tooltip
				title={permissions
					.filter((permission) => role.permissions[permission.index] === '1')
					.map((permission) => (
						<div key={permission.name}>{permission.name}</div>
					))}
			>
				<div style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '11rem' }}>
					<Typography variant="inherit" noWrap>
						{permissions
							.filter((permission) => role.permissions[permission.index] === '1')
							.map((permission) => `${permission.name}, `)}
					</Typography>
				</div>
			</Tooltip>
		);
	}

	const renderTableRow = ({ onClickRow, row: role, renderActions }: TableRowRenderProps<UserRole>) => {
		return (
			<TableRow key={role.id} id={`row-${role.id}`} hover onClick={() => onClickRow(role.id)}>
				<TableCell component="td" id={role.name} scope="row">
					{role.name}
				</TableCell>
				<TableCell component="td" id={role.name} scope="row">
					{t(role.roleType)}
				</TableCell>
				<TableCell component="td" id={`permissions-${role.permissions}`} scope="row">
					{renderPermissions(role)}
				</TableCell>

				{canEditRoles && (
					<TableCell padding="none" sortDirection={false} align="right">
						<TableActionsContainer>{renderActions(rowActions, role)}</TableActionsContainer>
					</TableCell>
				)}
			</TableRow>
		);
	};

	return (
		<>
			<CardHeader
				title={t('userRoles')}
				action={
					<>
						{canEditRoles && (
							<Tooltip title={String(t('createUserRole'))} aria-label={t('createUserRole')}>
								<IconButton
									onClick={() =>
										dispatch(
											addModalDialog({
												id: 'createUserRoleModal',
												title: t('createUserRoleModalTitle'),
												contentText: t('createUserRoleModalDescription'),
												content: (
													<UserRolesForm
														onSubmit={(values) =>
															handleCreateUserRole(values, 'createUserRoleModal')
														}
														onCancel={() => handleCloseModal('createUserRoleModal')}
														permissionDefinitions={permissions}
													/>
												),
											}),
										)
									}
									data-cy="btn-create:userroles"
								>
									<Add />
								</IconButton>
							</Tooltip>
						)}
					</>
				}
			/>
			<Divider light />
			<Table
				columns={columns}
				orderings={userRoles.orderings}
				filtersAndConditions={userRoles.filters}
				data={userRoles.data}
				dataLimit={userRoles.limit}
				dataOffset={userRoles.offset}
				numberOfRows={userRoles.total}
				fetchAction2={fetchUserRoles}
				actions={canEditRoles ? rowActions : undefined}
				renderTableRow={renderTableRow}
			/>
		</>
	);
};

import React, { useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Marker, MarkerProps } from 'react-leaflet';
import { divIcon } from 'leaflet';
import { Children } from '../../../types';
import { MarkerDeviceIcon } from '../../../components/Icons/MarkerDeviceIcon';
import { MarkerZoneIcon } from '../../../components/Icons/MarkerZoneIcon';
import { MarkerPointIcon } from '../../../components/Icons/MarkerPointIcon';
import { MarkerPlacementIcon } from '../../../components/Icons/MarkePlacementIcon';
import { MarkeMHVIcon } from '../../../components/Icons/MarkeMHVIcon';

export type MarkerIcon =
	| 'device'
	| 'deviceStatic'
	| 'deviceBlue'
	| 'deviceNoPlacement'
	| 'deviceNoPlacementStatic'
	| 'zone'
	| 'point';

type Props = {
	type?: MarkerIcon;
	children: Children;
	onClick?: () => void;
	popupOpend?: boolean;
} & MarkerProps;

const markerOptions = {
	iconSize: [48, 48] as [number, number],
	iconAnchor: [22, 42] as [number, number],
	popupAnchor: [0, -24] as [number, number],
};

export function getMarkerMHVIcon(fill = '#0700b5') {
	return divIcon({
		className: 'custom-marker-device',
		html: ReactDOMServer.renderToString(<MarkeMHVIcon color="#0700b5" fill={fill} />),
		...markerOptions,
	});
}

const markerMap = new Map([
	[
		'device',
		divIcon({
			className: 'custom-marker-device',
			html: ReactDOMServer.renderToString(<MarkerPlacementIcon fill="#0700b5" />),
			...markerOptions,
		}),
	],
	[
		'deviceStatic',
		divIcon({
			className: 'custom-marker-deviceStatic',
			html: ReactDOMServer.renderToString(<MarkerPlacementIcon fill="#b0b030" />),
			...markerOptions,
		}),
	],
	[
		'deviceNoPlacementStatic',
		divIcon({
			className: 'custom-marker-deviceNoPlacementStatic',
			html: ReactDOMServer.renderToString(<MarkerDeviceIcon fill="#b0b030" />),
			...markerOptions,
		}),
	],
	[
		'deviceBlue',
		divIcon({
			className: 'custom-marker-deviceNoPlacement',
			html: ReactDOMServer.renderToString(<MarkerDeviceIcon fill="#0700b5" />),
			...markerOptions,
		}),
	],
	[
		'deviceNoPlacement',
		divIcon({
			className: 'custom-marker-deviceNoPlacement',
			html: ReactDOMServer.renderToString(<MarkerDeviceIcon fill="#707070" />),
			...markerOptions,
		}),
	],
	[
		'zone',
		divIcon({
			className: 'custom-marker-zone',
			html: ReactDOMServer.renderToString(<MarkerZoneIcon fill="#b90084" />),
			...markerOptions,
		}),
	],
	[
		'point',
		divIcon({
			className: 'custom-marker-point',
			html: ReactDOMServer.renderToString(<MarkerPointIcon fill="#0700b5" />),
			...markerOptions,
			iconAnchor: [18, 32] as [number, number],
			popupAnchor: [0, -16] as [number, number],
		}),
	],
]);

function MarkerWithOpenPopup(props: any) {
	const markerRef = useRef<any>();

	useEffect(() => {
		if (!markerRef.current) return;

		markerRef.current.leafletElement.openPopup();
	}, []);

	return <Marker ref={markerRef} {...props} />;
}

export const MapMarker = ({
	icon,
	type,
	position,
	children,
	onClick,
	popupOpend,
	onpopupopen,
	onpopupclose,
}: Props) => {
	if (popupOpend) {
		return (
			<MarkerWithOpenPopup
				icon={type ? markerMap.get(type) : icon}
				position={position}
				onClick={onClick}
				onpopupopen={onpopupopen}
				onpopupclose={onpopupclose}
			>
				{children}
			</MarkerWithOpenPopup>
		);
	}

	return (
		<Marker
			icon={type ? markerMap.get(type) : icon}
			position={position}
			onClick={onClick}
			onpopupopen={onpopupopen}
			onpopupclose={onpopupclose}
		>
			{children}
		</Marker>
	);
};

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { LanguageMenu } from '../LanguageMenu/LanguageMenu';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { MAIN_MENU_WIDTH_OPEN } from '../Layout/constants';
import { logOut } from '../../store/user/user-async-actions';
import { BREAKPOINT_KEYS } from '../../helpers/generalConstants';
import PowerSettingsNewRoundedIcon from '@material-ui/icons/PowerSettingsNewRounded';
import { RouteEnum } from '../../router/Routes';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../store/types';
import { UserState } from '../../store/user/user-types';

const useStyles = makeStyles(({ breakpoints, zIndex, transitions, spacing }: Theme) => ({
	appBar: {
		zIndex: zIndex.drawer + 1,
		color: '#fff',
		boxShadow: '3px 4px 5px -1px rgba(0,0,0,0.2)',
		transition: transitions.create(['width', 'margin'], {
			easing: transitions.easing.sharp,
			duration: transitions.duration.leavingScreen,
		}),
	},
	appBarOpen: {
		marginLeft: 0,
		width: '100%',
		[breakpoints.up(BREAKPOINT_KEYS.md)]: {
			marginLeft: MAIN_MENU_WIDTH_OPEN.md,
			width: `calc(100% - ${MAIN_MENU_WIDTH_OPEN.md}px)`,
		},
		transition: transitions.create(['width', 'margin'], {
			easing: transitions.easing.sharp,
			duration: transitions.duration.enteringScreen,
		}),
	},
	title: {
		flexGrow: 1,
		paddingRight: spacing(3),
	},
	menuButton: {
		marginRight: spacing(3),
	},
	menuSecondary: {
		display: 'flex',
		alignItems: 'center',
	},
}));

type Props = {
	menuOpen: boolean;
	title: string;
	subTitle?: string;
	toggleMenu: () => void;
};

export const AppBar = ({ menuOpen, title, toggleMenu, subTitle }: Props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const { t } = useTranslation();
	const { firstName, lastName } = useSelector<RootState, UserState>((state) => state.user);

	const handleLogout = () => {
		dispatch(logOut());
	};

	const handleChangeRoute = (url: string) => {
		history.push(url);
	};

	return (
		<MuiAppBar position="absolute" className={clsx(classes.appBar, menuOpen && classes.appBarOpen)}>
			<Toolbar>
				<IconButton
					edge="start"
					color="inherit"
					aria-label="open drawer"
					onClick={toggleMenu}
					className={classes.menuButton}
				>
					<MenuIcon />
				</IconButton>
				<Grid container direction="column">
					<Grid item>
						<Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
							{title}
						</Typography>
					</Grid>
					{subTitle && (
						<Grid item>
							<Typography variant="body2" color="inherit" noWrap>
								{subTitle}
							</Typography>
						</Grid>
					)}
				</Grid>
				<div className={classes.menuSecondary}>
					{/* <Tooltip title={String(t('settings'))}>
						<IconButton color="inherit" onClick={() => handleChangeRoute(RouteEnum.SETTINGS)}>
							<SettingsRoundedIcon />
						</IconButton>
					</Tooltip> */}
					<LanguageMenu />
					<Tooltip title={String(t('logout'))}>
						<IconButton color="inherit" onClick={handleLogout}>
							<PowerSettingsNewRoundedIcon />
						</IconButton>
					</Tooltip>
					<Button
						// aria-haspopup="true"
						style={{ textTransform: 'none' }}
						color="default"
						onClick={() => handleChangeRoute(RouteEnum.SETTINGS)}
						size="small"
						variant="contained"
					>
						<Typography variant="body2" color="inherit" noWrap>
							{`${firstName} ${lastName}`}
						</Typography>
					</Button>
				</div>
			</Toolbar>
		</MuiAppBar>
	);
};

import { FormControl } from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/Button/Button';
import { Form } from '../../../components/Form/Form';
import { InputSelectUncontrolled } from '../../../components/Form/InputSelectUncontrolled';
import { ModalDialogFormActions } from '../../../components/ModalDialog/ModalDialogFormActions';
import { UUID } from '../../../types';
import { DeviceResponseData2 } from '../../Devices/devices-types';

export type AttachDeviceFormValues = {
	deviceId: UUID;
};

type Props = {
	options: DeviceResponseData2[];
	onSubmit: (values: AttachDeviceFormValues) => void;
	onCancel?: () => void;
};

export const AttachDeviceForm = ({ onSubmit, onCancel, options }: Props) => {
	const { t } = useTranslation();
	// const dispatch = useDispatch();
	// const selectedProjectId = useSelector<RootState, string>((state) => state.user.selectedProjectId);
	// const definitions = useSelector<RootState, DefinitionState[]>((state) => state.devicePlacements.definitions);

	const [deviceId, setDeviceId] = useState<string>();

	const [error, setError] = useState(false);

	const handleSubmit = (event: any) => {
		if (deviceId === undefined) {
			setError(true);
		} else {
			onSubmit({ deviceId: deviceId });
		}

		event.preventDefault();
	};

	const handleChangedDeviceId = (deviceId?: string) => {
		setDeviceId(deviceId);
		setError(false);
	};

	// const renderPlacement = (option: DevicePlacement) => {
	// 	const definition = definitions.find((definition) => definition.data.id === option.definitionId)?.data;

	// 	const title1Key = definition?.placementFields.find(
	// 		(field) => field.flags && field.flags & DefinitionFieldFlag.Title1,
	// 	)?.name;

	// 	const title2Key = definition?.placementFields.find(
	// 		(field) => field.flags && field.flags & DefinitionFieldFlag.Title2,
	// 	)?.name;

	// 	const subTitle1Key = definition?.placementFields.find(
	// 		(field) => field.flags && field.flags & DefinitionFieldFlag.SubTitle1,
	// 	)?.name;

	// 	const entry1 = Object.entries(option.fields).find(([key]) => key === title1Key);
	// 	const entry2 = Object.entries(option.fields).find(([key]) => key === title2Key);
	// 	const entry3 = Object.entries(option.fields).find(([key]) => key === subTitle1Key);

	// 	if (entry1 || entry2 || entry3) {
	// 		return `${entry1 ? `${entry1[1]} , ` : ''}${entry2 ? `${entry2[1]} , ` : ''}
	// 			${entry3 ? entry3[1] : ''}`;
	// 	} else {
	// 		return option.id; // Fallback (should not happen)
	// 	}
	// };

	// useEffect(() => {
	// 	if ((!definitions || definitions.length === 0) && selectedProjectId) {
	// 		dispatch(fetchDefinitions());
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [dispatch, selectedProjectId]);

	return (
		<Form onSubmit={handleSubmit} testId="attacheDeviceToPlacementForm">
			{/* <FormControl fullWidth margin="normal" variant="outlined">
				<InputLabel id="choosePlacement-label">{t('choosePlacement')}</InputLabel>
				<Controller
					as={
						<Select>
							{options?.map((option) => (
								<MenuItem key={option.id} value={option.id}>
									{renderPlacement(option)}
								</MenuItem>
							))}
						</Select>
					}
					control={control}
					error={Boolean(errors.placementId)}
					helperText={errors.placementId?.message}
					label={t('choosePlacement')}
					labelId="choosePlacement-label"
					name="placement"
				/>
			</FormControl> */}

			<FormControl fullWidth margin="normal" variant="outlined">
				<InputSelectUncontrolled
					error={Boolean(error)}
					helperText={error && t('validationErrors.required')}
					options={options.flatMap((option) => {
						return { id: option.id, label: option.sn };
					})}
					label={t('chooseDevice')}
					onChanged={(value) => handleChangedDeviceId(value?.id)}
				/>
			</FormControl>

			<ModalDialogFormActions>
				<Button startIcon={<SaveIcon />} type="submit" data-cy="btn-submit:attachDeviceFormToPlacement">
					{t('save')}
				</Button>
				{onCancel && (
					<Button onClick={onCancel} variant="text" data-cy="btn-cancel:attachDeviceFormToPlacement">
						{t('cancel')}
					</Button>
				)}
			</ModalDialogFormActions>
		</Form>
	);
};

import React from 'react';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { TextField, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(({ spacing }: Theme) => ({
	select: {
		'& .MuiFormLabel-root': {
			width: '90%',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			paddingRight: spacing(2),

			'&.Mui-focused': {
				width: '100%',
				textOverflow: 'initial',
			},
		},
	},
}));

type MuiSelectProps<T> = Omit<
	AutocompleteProps<T, boolean | undefined, undefined, undefined>,
	'renderInput' | 'options'
>;

type InputSelectProps<T> = MuiSelectProps<T> & {
	optionLabel?: (option: { id: string; label: string }) => string;
	renderOpt?: (params: { id: string; label: string }) => React.ReactNode;
	dis?: boolean;
	defaultValueId?: string;
	error: boolean;
	helperText?: React.ReactNode;
	unclearable?: boolean;
	id?: string;
	label: string;
	options: { id: string; label: string }[];
	onChanged?: (value: { id: string; label: string } | null) => void;
};

export const InputSelectUncontrolled = <T extends Record<string, unknown>>({
	optionLabel,
	renderOpt,
	dis,
	defaultValueId,
	error,
	helperText,
	id,
	label,
	unclearable,
	options,
	onChanged,
}: InputSelectProps<T>) => {
	const { t } = useTranslation();
	const classes = useStyles();

	if (optionLabel === undefined) {
		optionLabel = (option) => option.label;
	}

	return (
		<Autocomplete
			disabled={dis}
			// eslint-disable-next-line eqeqeq
			defaultValue={options.find((option) => option.id == defaultValueId)}
			className={classes.select}
			ChipProps={{ size: 'small' }}
			color="primary"
			filterSelectedOptions
			getOptionLabel={optionLabel}
			getOptionSelected={(option, value) => option.id === value.id}
			id={id}
			limitTags={2}
			multiple={false}
			disableClearable={unclearable}
			noOptionsText={t('noOptions')}
			options={options}
			onChange={(_, values) => onChanged && onChanged(values)}
			renderInput={(params) => (
				<TextField
					{...params}
					error={Boolean(error)}
					helperText={helperText}
					label={label}
					variant="outlined"
				/>
			)}
			renderOption={renderOpt}
		/>
	);
};

import { action } from 'typesafe-actions';
import {
	SET_LOADING,
	SET_REDIRECT_URL,
	ADD_TOAST,
	REMOVE_TOAST,
	ADD_MODAL_DIALOG,
	REMOVE_MODAL_DIALOG,
} from './app-constants';

import { Toast } from './app-types';
import { ModalDialogProps } from '../../components/ModalDialog/types';
import { UUID } from '../../types';

export const setLoading = (value: number) =>
	action(SET_LOADING, {
		value,
	});

export const addToast = (toast: Toast) =>
	action(ADD_TOAST, {
		toast,
	});

export const removeToast = (id: UUID) =>
	action(REMOVE_TOAST, {
		id,
	});

export const setRedirectUrl = (redirectUrl: string) =>
	action(SET_REDIRECT_URL, {
		redirectUrl,
	});

export const addModalDialog = (modalDialog: ModalDialogProps) =>
	action(ADD_MODAL_DIALOG, {
		modalDialog,
	});

export const removeModalDialog = (id: string) =>
	action(REMOVE_MODAL_DIALOG, {
		id,
	});

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { fetchRequest } from '../../services/helpers';
import { jobConfirmation } from '../../services/pipelines/pipelines-service';
import { Logo } from '../../components/Logo/Logo';

// const useStyles = makeStyles((theme: Theme) => ({
// 	submit: {
// 		margin: theme.spacing(3, 0, 2),
// 	},
// }));

type RouteParams = {
	jobId: string;
	contactId: string;
};

export const Confirmation = () => {
	// const classes = useStyles();
	const { t } = useTranslation();
	const { jobId, contactId } = useParams<RouteParams>();

	const [result, setResult] = React.useState('...');

	useEffect(() => {
		const request = jobConfirmation(jobId, contactId);

		(async () => {
			const { data, error } = await fetchRequest<string>(request);

			if (error) {
				setResult(error.status.toString());
			} else {
				setResult(data ?? 'N/A');
			}
		})();
	}, [contactId, jobId]);

	return (
		<Grid
			container
			spacing={2}
			justifyContent="center"
			alignItems="center"
			direction="column"
			style={{ minHeight: '60vh' }}
		>
			<Grid item xs={2}>
				<Logo />
			</Grid>
			<Grid item>
				<Typography variant="h5" color="inherit">
					{t('jobConfirmationTitle')}
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant="inherit" color="inherit">
					{t('jobConfirmationDescription')}
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant="h5">{`status: ${result}`}</Typography>
			</Grid>
		</Grid>
	);
};

export const FETCH_TELEMETRY_REQUEST = '[DEVICES_ACTIONS] FETCH_TELEMETRY_REQUEST';
export const FETCH_TELEMETRY_SUCCESS = '[DEVICES_ACTIONS] FETCH_TELEMETRY_SUCCESS';
export const FETCH_TELEMETRY_ERROR = '[DEVICES_ACTIONS] FETCH_TELEMETRY_ERROR';

export const FETCH_TELEMETRY_DETAIL_REQUEST = '[DEVICES_ACTIONS] FETCH_TELEMETRY_DETAIL_REQUEST';
export const FETCH_TELEMETRY_DETAIL_SUCCESS = '[DEVICES_ACTIONS] FETCH_TELEMETRY_DETAIL_SUCCESS';
export const FETCH_TELEMETRY_DETAIL_ERROR = '[DEVICES_ACTIONS] FETCH_TELEMETRY_DETAIL_ERROR';

export const UPDATE_CURRENT_TELEMETRY = '[DEVICES_ACTIONS] UPDATE_CURRENT_TELEMETRY';

export const FETCH_CURRENT_TELEMETRY_SUCCESS = '[DEVICES_ACTIONS] FETCH_CURRENT_TELEMETRY_SUCCESS_NO_PROGRESS_BAR';

export const FETCH_GROUPED_TELEMETRY_SUCCESS = '[DEVICES_ACTIONS] FETCH_GROUPED_TELEMETRY_SUCCESS';

import React, { useState, useEffect, useCallback, useRef, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { MapContainer } from '../../components/Maps/MapContainer';
import { Polygon } from 'react-leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import { LatLngLiteral, LeafletEvent } from 'leaflet';
import { PolygonForm, PolygonFormValues } from './components/PolygonForm';
import { editZone, createZone, deleteZone, fetchZones2, uploadZones } from '../../store/zones/zones-async-actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { ZonesState } from '../../store/zones/zones-types';
import { Zone, ZoneResponseData } from './zones-types';
import { fromJSONToObject } from '../../helpers/object';
import { COLORS, COLOR_THEME, SPACING } from '../../theme';
import { addModalDialog, removeModalDialog } from '../../store/app/app-actions';
import { omit } from 'lodash';
import { GeoJSON } from 'geojson';
import { PermissionsDotNet } from '../../helpers/generalConstants';
import {
	DeleteRounded,
	EditRounded,
	CheckRounded,
	ClearRounded,
	Add,
	ArrowForwardIos,
	Settings,
	InfoOutlined,
	BubbleChart,
} from '@material-ui/icons';
import {
	Box,
	Card,
	CardHeader,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	makeStyles,
	Theme,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { UserState } from '../../store/user/user-types';
import { EditableLayer } from '../../components/Maps/EditableLayer';
import { toLatLngExpression } from './helpers';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import { UUID } from '../../types';
import { Button } from '../../components/Button/Button';
import { MapPopup } from '../Maps/components/MapPopup';
import { getCenterOfCoordinates } from '../../components/Maps/helpers';
import { useZonesBounds } from './hooks';
import { MapMarker } from '../Maps/components/MapMarker';
import { Sidebar, Tab } from 'react-leaflet-sidebarv2';
import { InputField } from '../../components/Form/InputField';
import { CONTENT_PADDING_SM_UP } from '../../components/Layout/Content';
import { UploadGeoJSON } from '../../components/Upload/UploadGeoJSON';
import { hasPermission } from '../../helpers/auth';

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
	zonesTable: {
		marginTop: spacing(3),
	},
	deleteBtn: {
		marginLeft: spacing(2),
		backgroundColor: palette.error.main,

		'&:hover, &:focus': {
			backgroundColor: palette.error.dark,
		},
	},
	closeButton: {
		position: 'relative',
		right: spacing(1),
		top: spacing(1),
	},
}));

const defaultZone: ZoneResponseData = {
	id: '',
	active: false,
	dateCreated: '',
	dateUpdated: '',
	name: 'default',
	polygon: '',
	projectId: '',
	description: '',
};

export const Zones = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { data: zones } = useSelector<RootState, ZonesState>((state) => state.zones);

	const [selectedZoneId, setSelectedZoneId] = useState<string | undefined>(undefined);
	const { project, permissions } = useSelector<RootState, UserState>((state) => state.user);
	const canEditZones = project?.id ? hasPermission(PermissionsDotNet.ZoneWrite, permissions) : false;
	const [editingPopup, setEditingPopup] = useState(false);
	// Don't know this type from leaflet-draw
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const editControlRef = useRef<any>(null);
	const { mapBounds, setMapBounds } = useZonesBounds(zones);

	const [sidebarColabsed, setSidebarColabsed] = useState(false);
	const [sidebarSelected, setSidebarSelected] = useState('zones');
	const [zonesSearchKey, setZonesSearchKey] = useState<string>();

	const pageRef = useRef<any>(null);

	const stageCanvasRef = useRef(null);

	useEffect(() => {
		if (project?.id) {
			dispatch(
				fetchZones2({
					// Should be enough to get all zones, even for super admin
					limit: 1000,
					offset: 0,
					orderings: [],
					filtersAndConditions: [],
				}),
			);
		}

		// Don't need to react on "config" and "filters" and deep comparision is not necessary
		/// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, project]);

	const handleCloseModalDialog = (id: string) => {
		dispatch(removeModalDialog(id));
	};

	const handleClickZone = (zone: Zone) => {
		const coords = fromJSONToObject<LatLngLiteral[]>(zone.polygon);

		if (coords) {
			const bounds = toLatLngExpression(coords);

			setMapBounds(bounds);
		}

		setSelectedZoneId(zone.id);
		setEditingPopup(true);

		pageRef.current?.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
		});
	};

	const handleCreated = (event: LeafletEvent) => {
		const coords = fromJSONToObject<LatLngLiteral[][]>(JSON.stringify(event.layer.getLatLngs()))?.[0];

		if (coords) {
			dispatch(
				addModalDialog({
					id: 'createZonePolygonModal',
					title: t('createZonePolygonModalTitle'),
					content: (
						<PolygonForm
							onSubmit={(values: PolygonFormValues) => handleCreateZone(values, 'createZonePolygonModal')}
							values={{ coords, active: true, name: '', description: '' }}
						/>
					),
				}),
			);
		}
	};

	const handleEdited = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(event: any, zone: Zone) => {
			let polygon: unknown = '';

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			event.layers.eachLayer((layer: any) => {
				polygon = layer.editing?.latlngs[0][0];
			});

			dispatch(
				editZone({
					name: zone.name,
					description: zone.description,
					active: zone.active,
					id: zone.id,
					projectId: zone.projectId,
					polygon: JSON.stringify(polygon as LatLngLiteral[]),
				}),
			);
		},
		[dispatch],
	);

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const handleEditStart = () => {
		setEditingPopup(false);
	};

	function handleClickCreate() {
		dispatch(
			addModalDialog({
				id: 'createZonePolygonModal',
				title: t('createZonePolygonModalTitle'),
				content: (
					<PolygonForm
						onSubmit={(values: PolygonFormValues) => handleCreateZone(values, 'createZonePolygonModal')}
						values={{
							coords: [{ lat: 0, lng: 0 }],
							active: true,
							name: '',
							description: '',
						}}
					/>
				),
			}),
		);
	}

	function handleClickEdit(zone: Zone) {
		const coords = fromJSONToObject<LatLngLiteral[]>(zone.polygon);

		if (coords) {
			dispatch(
				addModalDialog({
					id: 'editZonePolygonModal',
					title: t('editZonePolygonModalTitle'),
					content: (
						<PolygonForm
							onSubmit={(values: PolygonFormValues) =>
								handleEditZone(values, zone, 'editZonePolygonModal')
							}
							values={{
								coords,
								active: zone.active,
								name: zone.name,
								description: zone.description,
							}}
						/>
					),
				}),
			);
		}
	}

	function handleClickDelete(zone: Zone) {
		setEditingPopup(false);
		dispatch(
			addModalDialog({
				id: 'deleteZoneModal',
				title: t('deleteZoneModalTitle'),
				contentText: t('deleteZoneModalDescription', {
					zone: zone.name,
				}),
				buttons: [
					{
						id: 'deleteZone',
						value: t('delete'),
						onClick: () => handleDeleteZone(zone.id, 'deleteZoneModal'),
						startIcon: <DeleteForeverRoundedIcon />,
					},
				],
			}),
		);
	}

	const handleClickPopupEdit = () =>
		editControlRef.current?.leafletElement._toolbars.edit._modes.edit.handler.enable();

	const handleEditZone = (
		{ name, description, coords, active }: PolygonFormValues,
		zone: Omit<Zone, 'polygon'>,
		modalDialogId: string,
	) => {
		const payload = {
			name,
			description,
			active,
			id: zone.id,
			polygon: JSON.stringify(coords),
			projectId: zone.projectId,
		};

		handleCloseModalDialog(modalDialogId);
		dispatch(editZone(payload));
	};

	const handleCreateZone = ({ coords, ...rest }: PolygonFormValues, modalDialogId: string) => {
		const payload = {
			...rest,
			polygon: JSON.stringify(coords),
		};

		handleCloseModalDialog(modalDialogId);
		dispatch(createZone(payload));
	};

	function handleDeleteZone(zoneId: UUID, modalId: string) {
		dispatch(deleteZone(zoneId));
		dispatch(removeModalDialog(modalId));

		setSelectedZoneId(undefined);
	}

	const onClose = () => {
		setSidebarColabsed(true);
	};

	const onOpen = (id: string) => {
		setSidebarColabsed(false);
		setSidebarSelected(id);
	};

	const handleUploadGeoJSON = (payload: GeoJSON) => {
		dispatch(uploadZones(payload));
	};

	const drawZonesTab = () => {
		return (
			<>
				{canEditZones && (
					<>
						<Box height={SPACING} />
						<Tooltip title={String(t('createZone'))} aria-label={t('createZone')}>
							<IconButton onClick={() => handleClickCreate()}>
								<Add />
							</IconButton>
						</Tooltip>
						<UploadGeoJSON onUpload={handleUploadGeoJSON} title={t('uploadGeoJSON')} />
					</>
				)}
				<InputField
					autoFocus
					label={'search'}
					margin="normal"
					name={'search zones'}
					onChange={(event: ChangeEvent<HTMLInputElement>) => setZonesSearchKey(event.target.value)}
				/>
				<List
					id="main-menu"
					style={{
						height: `calc(100vh - ${CONTENT_PADDING_SM_UP * SPACING * 2 + 210}px)`,
						overflow: 'auto',
					}}
				>
					{zones
						?.filter((zoneResponseData) => {
							const key = zonesSearchKey?.toLowerCase();

							return (
								!key ||
								zoneResponseData.name.toLowerCase().includes(key) ||
								zoneResponseData.description.toLowerCase().includes(key)
							);
						})
						.map((zoneResponseData) => (
							<ListItem
								key={zoneResponseData.id}
								button
								onClick={() => handleClickZone(zoneResponseData)}
								selected={zoneResponseData.id === selectedZoneId}
							>
								<ListItemText
									primary={zoneResponseData.name}
									secondary={zoneResponseData.description}
								/>
								<ListItemIcon>
									{zoneResponseData.active ? (
										<CheckRounded htmlColor={COLOR_THEME.success} data-cy="check:active" />
									) : (
										<ClearRounded htmlColor={COLOR_THEME.error} data-cy="check:inactive" />
									)}
								</ListItemIcon>
								{canEditZones && (
									<ListItemSecondaryAction>
										<IconButton edge="end" onClick={() => handleClickEdit(zoneResponseData)}>
											<EditRounded />
										</IconButton>
										<IconButton edge="end" onClick={() => handleClickDelete(zoneResponseData)}>
											<DeleteRounded />
										</IconButton>
									</ListItemSecondaryAction>
								)}
							</ListItem>
						))}
				</List>
			</>
		);
	};

	return (
		<div ref={pageRef}>
			<MapContainer
				bounds={mapBounds}
				height={`calc(100vh - ${CONTENT_PADDING_SM_UP * SPACING * 2}px)`}
				boxShadow
				sidebar={
					<Sidebar
						id="sidebar"
						collapsed={sidebarColabsed}
						selected={sidebarSelected}
						onOpen={(id) => onOpen(id)}
						onClose={onClose}
						position={'right'}
						closeIcon={<ArrowForwardIos />}
						ref={stageCanvasRef}
					>
						<Tab id="home" header="Info" icon={<InfoOutlined />} disabled>
							<Box height={SPACING * 3} />
							<Card>
								<CardHeader
									title={
										<Typography component="h2" variant="h6">
											{'help'}
										</Typography>
									}
								/>
								<p>info (TODO)</p>
							</Card>
							{/* <Box height={SPACING * 3} />
							<Card>
								<CardHeader
									title={
										<Typography component="h2" variant="h6">
											{t('')}
										</Typography>
									}
								/>
							</Card> */}
						</Tab>
						<Tab id="zones" header={t('zones')} icon={<BubbleChart />}>
							{drawZonesTab()}
						</Tab>
						<Tab id="settings" header="Settings" icon={<Settings />} anchor="bottom" disabled>
							<p>Settings dialogue.</p>
						</Tab>
					</Sidebar>
				}
			>
				{/* Fallback create button shown when list of zones is empty or no zone is selected */}
				{canEditZones &&
					(zones.length === 0 || zones.find((zone) => zone.id === selectedZoneId) === undefined) && (
						<EditableLayer
							key={'zone.id'}
							onCreated={handleCreated}
							onEdited={handleEdited}
							onEditStart={handleEditStart}
							showDrawControl
							showEditButton={false}
							zone={defaultZone}
						>
							<></>
						</EditableLayer>
					)}
				{zones?.map((zoneItem) => {
					const coords = fromJSONToObject<LatLngLiteral[]>(zoneItem.polygon);

					if (!coords) return null;

					const markerLocation = getCenterOfCoordinates(coords);
					const positions = toLatLngExpression(coords);
					const zone = omit(zoneItem, ['dateCreated', 'dateUpdated']);
					const showDrawControl = zone.id === selectedZoneId;
					const shouldShowEditingPopup = showDrawControl && editingPopup;
					const zoneColor = showDrawControl
						? COLORS.blue.main
						: zone.active
						? COLORS.orange.main
						: COLORS.gray.main;

					return (
						<>
							<MapMarker type="zone" position={markerLocation} onClick={() => handleClickZone(zone)}>
								{shouldShowEditingPopup && (
									<MapPopup
										title={t('selectedZone', { zone: zone.name })}
										action={
											canEditZones && (
												<>
													<Button
														onClick={handleClickPopupEdit}
														size="small"
														startIcon={<EditRounded />}
														data-cy="btn-edit:mapZone"
													>
														{t('edit')}
													</Button>
													<Button
														className={classes.deleteBtn}
														onClick={() => handleClickDelete(zone)}
														size="small"
														startIcon={<DeleteRounded />}
														data-cy="btn-delete:mapZone"
													>
														{t('delete')}
													</Button>
												</>
											)
										}
									/>
								)}
							</MapMarker>
							<EditableLayer
								key={zone.id}
								onCreated={handleCreated}
								onEdited={handleEdited}
								onEditStart={handleEditStart}
								showDrawControl={canEditZones ? showDrawControl : false}
								showEditButton
								zone={zone}
								ref={editControlRef}
							>
								{shouldShowEditingPopup && (
									<MapPopup
										title={t('selectedZone', { zone: zone.name })}
										action={
											canEditZones && (
												<>
													<Button
														onClick={handleClickPopupEdit}
														size="small"
														startIcon={<EditRounded />}
														data-cy="btn-edit:mapZone"
													>
														{t('edit')}
													</Button>
													<Button
														className={classes.deleteBtn}
														onClick={() => handleClickDelete(zone)}
														size="small"
														startIcon={<DeleteRounded />}
														data-cy="btn-delete:mapZone"
													>
														{t('delete')}
													</Button>
												</>
											)
										}
									/>
								)}
								<Polygon
									key={zone.name}
									onClick={() => handleClickZone(zone)}
									color={zoneColor}
									positions={positions}
								/>
							</EditableLayer>
						</>
					);
				})}
			</MapContainer>
		</div>
	);
};

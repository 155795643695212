import { action } from 'typesafe-actions';
import {
	CREATE_DEVICE_SUCCESS,
	FETCH_DEVICES_SUCCESS,
	GENERAL_WAREHOUSE_ERROR,
	GENERAL_WAREHOUSE_REQUEST,
	EDIT_DEVICE_SUCCESS,
	EDIT_DEVICE_PROPERTIES_SUCCESS,
} from './warehouse-constants';

import { DevicesResponseData2 } from '../../pages/Devices/devices-types';
import { FiltersRequestBody2 } from '../../components/Table/types';

// general request action with no impact but starting the progress bar
export const generalWarehouseRequest = () => action(GENERAL_WAREHOUSE_REQUEST);
// general error action with no impact but stopping the progress bar
export const generalWarehouseError = () => action(GENERAL_WAREHOUSE_ERROR);

export const createDeviceSuccess = () => action(CREATE_DEVICE_SUCCESS);

export const editDeviceSuccess = () => action(EDIT_DEVICE_SUCCESS);

export const fetchDevicesSuccess = (data: DevicesResponseData2, options?: FiltersRequestBody2) =>
	action(FETCH_DEVICES_SUCCESS, { data, options });

export const editDevicePropertiesSuccess = () => action(EDIT_DEVICE_PROPERTIES_SUCCESS);

import React from 'react';
import { Table } from '../../../components/Table/Table';
import { useTranslation } from 'react-i18next';

import { TableRow, TableCell, CardHeader, Divider, Button } from '@material-ui/core';
import {
	TableColumnFilter,
	TableColumnSort,
	TableHeadCell,
	TableRowRenderProps,
} from '../../../components/Table/types';
import { Job } from '../jobs-types';
import { fetchJobs } from '../../../store/pipelines/pipelines-async-actions';
import { INPUT_DATE_TIME_MASK } from '../../../helpers/generalConstants';
import { format } from 'date-fns';
import { setCurrentWorkflow } from '../../../store/workflows/workflows-actions';
import { setRedirectUrl } from '../../../store/app/app-actions';
import { RouteEnum } from '../../../router/Routes';
import { useDispatch } from 'react-redux';

type Props = {
	data: Job[];
	total: number;
	limit: number;
	offset: number;
	orderings?: TableColumnSort[];
	filters?: TableColumnFilter[];
};

export const JobsTable = ({ data, total, offset, limit, orderings, filters }: Props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const idOrder = orderings?.find((order) => order.column === 'id');
	// const idFilter = filters?.find((filter) => filter.column === 'id');

	const timeOrder = orderings?.find((order) => order.column === 'time');
	// const nameFilter = filters?.find((filter) => filter.column === 'name');

	const columns: TableHeadCell[] = [
		{
			id: 'id',
			label: 'id',
			sort: idOrder?.sortOrder && { column: 'id', sortOrder: idOrder?.sortOrder },
		},
		{
			id: 'workflowId',
			label: 'workflowId',
			unorderable: true,
		},
		{
			id: 'placementId',
			label: 'placementId',
			unorderable: true,
		},
		{
			id: 'time',
			label: 'time',
			sort: timeOrder?.sortOrder && { column: 'time', sortOrder: timeOrder?.sortOrder },
		},
		{
			id: 'severity',
			label: 'severity',
			unorderable: true,
		},
		{
			id: 'header',
			label: 'header',
			unorderable: true,
		},
		{
			id: 'message',
			label: 'message',
			unorderable: true,
		},
		{
			id: 'pipeline',
			label: 'pipeline',
			unorderable: true,
		},
		{
			id: 'confirmTo',
			label: 'confirmTo',
			unorderable: true,
		},
		{
			id: 'confirmTime',
			label: 'confirmTime',
			unorderable: true,
		},
		{
			id: 'confirmContactId',
			label: 'confirmContactId',
			unorderable: true,
		},
	];

	const renderRow = ({ row: job }: TableRowRenderProps<Job>) => {
		return (
			<TableRow key={job.id} id={`row-${job.id}`}>
				<TableCell>{job.id}</TableCell>
				<TableCell>
					{job.workflowId ? (
						<Button
							variant="text"
							onClick={() => {
								dispatch(setCurrentWorkflow(job.workflowId));
								dispatch(setRedirectUrl(`${RouteEnum.WORKFLOWS}/${job.workflowId}`));
							}}
						>
							{job.workflowId}
						</Button>
					) : undefined}
				</TableCell>
				<TableCell>{job.placementId}</TableCell>
				<TableCell>{format(new Date(job.time), INPUT_DATE_TIME_MASK)}</TableCell>
				<TableCell>{job.severity}</TableCell>
				<TableCell>{job.header}</TableCell>
				<TableCell>{job.message}</TableCell>
				<TableCell>{job.pipeline}</TableCell>
				<TableCell>
					{job.confirmTo == null ? undefined : format(new Date(job.confirmTo), INPUT_DATE_TIME_MASK)}
				</TableCell>
				<TableCell>
					{job.confirmTime == null ? undefined : format(new Date(job.confirmTime), INPUT_DATE_TIME_MASK)}
				</TableCell>
				<TableCell>{job.confirmContactId}</TableCell>
			</TableRow>
		);
	};

	return (
		<>
			<CardHeader title={t('jobs')} action={<></>} />
			<Divider light />
			<Table
				// actions={canEditPipelines ? rowActions : undefined}
				columns={columns}
				orderings={orderings}
				filtersAndConditions={filters}
				data={data}
				dataLimit={limit}
				dataOffset={offset}
				renderTableRow={renderRow}
				numberOfRows={total}
				fetchAction2={fetchJobs}
				selectable={false}
			/>
		</>
	);
};

import React, { useEffect } from 'react';
import { Box, Card, CardHeader, Divider, Grid, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// import io from 'socket.io-client';
import { INPUT_DATE_TIME_MASK } from '../../helpers/generalConstants';
// import { RouteEnum } from '../../router/Routes';
// import { socketUrl } from '../../services/client';
// import { setRedirectUrl } from '../../store/app/app-actions';
// import { updateCurrentNotifications } from '../../store/reports/reports-actions';
// import { updateCurrentTelemetry } from '../../store/telemetry/telemetry-actions';
import { RootState } from '../../store/types';
import { UserState } from '../../store/user/user-types';
import { TelemetrySocketData } from '../Devices/telemetry-types';
import { NotificationSocketData } from '../Reports/reports-types';
import { Cards } from './components/Cards';
import { NotificationsTable } from '../Reports/components/NotificationsTable';
import { DashboardTelemetryTable } from './components/DashboardTelemetryTable';
import { getNotificationProperties } from '../Reports/helpers';
import { DashboardReportsTable } from './components/DashboardReportsTable';
import { fetchCurrentTelemetryNoModalProgress } from '../../store/telemetry/telemetry-async-actions';
import { SPACING } from '../../theme';

export const Dashboard = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { project /*, jwtToken*/ } = useSelector<RootState, UserState>((state) => state.user);

	const currentTelemetry = useSelector<RootState, TelemetrySocketData[]>((state) => state.telemetry.currentTelemetry);
	const currentNotifications = useSelector<RootState, NotificationSocketData[]>(
		(state) => state.reports.currentNotifications,
	);

	// const socketRef = useRef<SocketIOClient.Socket | undefined>();

	// useEffect(() => {
	// 	socketRef.current = io(socketUrl, {
	// 		query: {
	// 			accessToken: jwtToken,
	// 			services: ['telemetry', 'report'],
	// 		},
	// 	});

	// 	socketRef.current.on('event:telemetry', (data?: TelemetrySocketData) => {
	// 		if (data?.telemetry) {
	// 			dispatch(updateCurrentTelemetry(data));
	// 		}
	// 	});

	// 	socketRef.current.on('event:report', (data?: NotificationSocketData) => {
	// 		if (data?.sn) {
	// 			dispatch(updateCurrentNotifications(data));
	// 		}
	// 	});

	// 	socketRef.current.on('event:unauthorized', () => {
	// 		socketRef.current?.off('event:telemetry');
	// 		socketRef.current?.off('event:report');
	// 		socketRef.current?.disconnect();
	// 		window.localStorage.clear();
	// 		window.sessionStorage.clear();
	// 		dispatch(setRedirectUrl(RouteEnum.LOGIN));
	// 	});

	// 	return () => {
	// 		socketRef.current?.off('event:telemetry');
	// 		socketRef.current?.off('event:report');
	// 		socketRef.current?.disconnect();
	// 	};
	// }, [dispatch, jwtToken]);

	useEffect(() => {
		if (project?.id && currentTelemetry.length === 0) {
			dispatch(fetchCurrentTelemetryNoModalProgress());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, project]);

	// useEffect(() => {
	// 	if (!selectedProjectId) {
	// 		dispatch(
	// 			addModalDialog({
	// 				id: 'missingProjectModal',
	// 				title: t('missingProjectModalTitle'),
	// 				contentText: t('missingProjectModalDescription', {
	// 					name: `${firstName} ${lastName}`,
	// 				}),
	// 				buttons: [
	// 					{
	// 						id: 'missingProject',
	// 						value: t('close'),
	// 						onClick: () => {
	// 							dispatch(logOut());
	// 							dispatch(removeModalDialog('missingProjectModal'));
	// 						},
	// 					},
	// 				],
	// 				onClose: () => {
	// 					dispatch(logOut());
	// 					dispatch(removeModalDialog('missingProjectModal'));
	// 				},
	// 				disableBackdropClick: true,
	// 			}),
	// 		);
	// 	}
	// }, [dispatch, selectedProjectId, firstName, lastName, t]);

	const notificiations = getNotificationProperties(currentNotifications);

	return (
		<Grid container spacing={3}>
			{project?.id && (
				<Grid item xs={12}>
					<Cards projectId={project.id} />
				</Grid>
			)}
			<Grid item xs md={12} lg={6}>
				<Card>
					<CardHeader
						title={
							<Typography component="h2" variant="h6">
								{t('currentTelemetry')}
							</Typography>
						}
						subheader={
							currentTelemetry[0]?.telemetry.time &&
							t('lastUpdate', {
								date: format(new Date(currentTelemetry[0].telemetry.time), INPUT_DATE_TIME_MASK),
							})
						}
					/>
					<Divider light />
					<DashboardTelemetryTable data={currentTelemetry} limit={5} offset={0} />
				</Card>
			</Grid>
			<Grid item container xs md={12} lg={6} direction="column">
				<Grid item>
					<Card>
						<CardHeader
							title={
								<Typography component="h2" variant="h6">
									{t('currentNotifications')}
								</Typography>
							}
							subheader={
								notificiations[0]?.time &&
								t('lastUpdate', {
									date: format(new Date(notificiations[0].time), INPUT_DATE_TIME_MASK),
								})
							}
						/>
						<Divider light />
						<NotificationsTable data={notificiations} limit={5} offset={0} />
					</Card>
				</Grid>
				<Box height={SPACING * 3} />
				<Grid item>
					<Card>
						<CardHeader
							title={
								<Typography component="h2" variant="h6">
									{t('currentSummary')}
								</Typography>
							}
							subheader={
								notificiations[0]?.time &&
								t('lastUpdate', {
									date: format(new Date(notificiations[0].time), INPUT_DATE_TIME_MASK),
								})
							}
						/>
						<Divider light />
						<DashboardReportsTable />
					</Card>
				</Grid>
			</Grid>
		</Grid>
	);
};

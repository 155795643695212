import React, { useEffect, useMemo, useState } from 'react';
import {
	ButtonGroup,
	Button,
	Divider,
	Grid,
	makeStyles,
	Paper,
	Tab,
	Tabs,
	Theme,
	CardHeader,
	Typography,
	List,
	ListItem,
	Card,
} from '@material-ui/core';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import HeatmapLayer from 'react-leaflet-heatmap-layer';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MapContainer } from '../../components/Maps/MapContainer';
import { fetchGroupedTelemetryNoModalProgress, fetchTelemetry } from '../../store/telemetry/telemetry-async-actions';
import { TelemetryState } from '../../store/telemetry/telemetry-types';
import { RootState } from '../../store/types';
import { ChartPoint, Telemetry, TelemetryResponseData } from './telemetry-types';
import { BackButton } from '../../components/Button/BackButton';
import { INPUT_DATE_TIME_MASK, MapType } from '../../helpers/generalConstants';
import { notEmpty } from '../../helpers/array';
import { DeviceDetailTelemetryTable } from './components/DeviceDetailTelemetryTable';
import { TabPanel } from '../../components/Tabs/TabPanel';
import { PopoverOptions } from '../../components/Table/PopoverOptions';
import { FilterOperators, SortOrder } from '../../components/Table/constants';
import { fetchDevice } from '../../store/devices/devices-async-actions';
import { LocationOffRounded, TuneRounded } from '@material-ui/icons';
import { MapPopup } from '../Maps/components/MapPopup';
import { MapMarker } from '../Maps/components/MapMarker';
import { Circle, FeatureGroup, Polyline, GeoJSON } from 'react-leaflet';
import { LatLngTuple } from 'leaflet';
import { DeviceDetailTelemetryProperties } from './components/DeviceDetailTelemetryProperties';
import { DeviceDetailLocationProperties } from './components/DeviceDetailLocationProperties';
import { DetailCard } from './components/DetailCard';
import { DetailChart } from './components/DetailChart';
import { ChartTooltip } from './components/ChartTooltip';
import { isNil } from 'lodash/fp';
import { Tooltip, TooltipProps } from 'recharts';
import { NotificationsTable } from '../Reports/components/NotificationsTable';
import { fetchNotifications } from '../../store/reports/reports-async-actions';
import { Notification, NotificationResponseData } from '../../pages/Reports/reports-types';
import { DeviceResponseData2 } from './devices-types';
import { getNotificationProperties } from '../Reports/helpers';
import { UserState } from '../../store/user/user-types';
import { DeviceDetailTelemetryTableFilter } from './components/DeviceDetailTelemetryTableFilter';
import { DeviceDetailZones } from './components/DeviceDetailZones';
import { Zone } from '../Zones/zones-types';
import { fetchZones2 } from '../../store/zones/zones-async-actions';
import { FiltersRequestBody2, TableColumnFilter } from '../../components/Table/types';
import { fetchRequest } from '../../services/helpers';
import { getFilteredTelemetry } from '../../services/telemetry/telemetry-service';
import { DeviceDetailPlacementProperties } from './components/DeviceDetailPlacementProperties';
import { DeviceDetailInformation } from './components/DeviceDetailInformation';
import { InDoorMapsState } from '../../store/inDoorMaps/inDoorMaps-types';
import { fetchInDoorMaps } from '../../store/inDoorMaps/inDoorMaps-async-actions';
import { FeatureCollection } from 'geojson';
import { InDoorMapItem } from '../InDoorMaps/indoormaps-types';
import { tryFeatureCollection } from 'pure-geojson-validation';
import { onEachFeature } from '../../components/Maps/helpers';
import { uniqueId } from 'lodash';

// [lat, lng, value] - the higher the value the bigger the heat :)
type Coordinates = [number, number, number];

type MapTelemetry = Telemetry & { timeStamp?: number };

const useStyles = makeStyles(({ spacing }: Theme) => ({
	paper: {
		padding: spacing(2, 3, 3, 3),
	},
	marginTop: {
		marginTop: spacing(3),
	},
	center: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		padding: spacing(2),
	},
}));

type RouteParams = {
	deviceSn: string;
};

export const DeviceDetail = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { deviceSn } = useParams<RouteParams>();
	const dispatch = useDispatch();
	const [tab, setTab] = useState(0);
	const isDetailTab = tab === 0;
	const isTelemetryTab = tab === 1;
	const isChartsTab = tab === 2;
	const [mapType, setMapType] = useState(MapType.LOCATION);
	const isHeatMap = mapType === MapType.HEAT;
	const isLocationMap = mapType === MapType.LOCATION;
	const isTrailMap = mapType === MapType.TRAIL;
	const zones = useSelector<RootState, Zone[]>((state) => state.zones.data);
	const { userId, project } = useSelector<RootState, UserState>((state) => state.user);
	const telemetryState = useSelector<RootState, TelemetryState>((state) => state.telemetry);
	const {
		data: telemetry,
		total: telemetryTotal,
		offset: telemetryOffset,
		limit: telemetryLimit,
		filters: telemetryFilters,
		groupedTelemetry,
	} = telemetryState;

	const [chartTelemetry, setChartTelemetry] = useState<Telemetry[]>([]);

	const deviceDetail = useSelector<RootState, DeviceResponseData2 | undefined>((state) => state.devices.deviceDetail);
	// const [mapBounds, setMapBounds] = useState<LatLngTuple[]>([]);
	const notifications = useSelector<RootState, NotificationResponseData[]>(
		(state) => state.reports.notifications.data,
	);

	const { data: inDoorMapsData } = useSelector<RootState, InDoorMapsState>((state) => state.inDoorMaps);
	const [inDoorMaps, setInDoorMaps] = useState<InDoorMapItem[]>([]);
	const [geojsonsKey, setGeojsonsKey] = useState<string>('');

	// const [trailFirstLoad, setTrailFirstLoad] = useState(true);

	const notificationsData = getNotificationProperties(notifications) as Notification[];

	// const attachedPlacementName = deviceDetail?.placements.find((placement) => placement.detached === null)?.line1;

	// const [refreshInterval, setRefreshInterval] = useState(60000 * 5);

	// const fetchData = () => {
	// 	dispatch(fetchTelemetryNoProgressBar());
	// 	dispatch(fetchDeviceNoProgressBar(deviceSn));

	// 	let ref = refreshInterval * 1.2;

	// 	if (ref > 10 * 60000) {
	// 		ref = 10 * 60000;
	// 	}

	// 	setRefreshInterval(ref);
	// };

	// useEffect(() => {
	// 	if (refreshInterval && refreshInterval > 0) {
	// 		const interval = setInterval(fetchData, refreshInterval);

	// 		return () => clearInterval(interval);
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [refreshInterval]);

	// fetch all inDoormaps
	useEffect(() => {
		if (project?.id) {
			dispatch(
				fetchInDoorMaps({
					// Should be enough to get all zones, even for super admin
					limit: 1000,
					offset: 0,
					orderings: [],
					filtersAndConditions: [],
				}),
			);
		}

		// Don't need to react on "config" and "filters" and deep comparision is not necessary
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, project]);

	/**
	 * Fetching zones for table
	 */
	useEffect(() => {
		if (inDoorMaps === undefined && project?.id) {
			dispatch(
				fetchZones2({
					// Should be enough to get all zones, even for super admin
					limit: 1000,
					offset: 0,
					orderings: [],
					filtersAndConditions: [],
				}),
			);
		}
	}, [dispatch, inDoorMaps, project]);

	useEffect(() => {
		setInDoorMaps(
			inDoorMapsData
				.map((inDoorMap) => {
					let geojson: FeatureCollection | undefined = undefined;

					try {
						const geoj: unknown = JSON.parse(inDoorMap.layers[0].image); // TODO make for all floors

						const unsafeFC = tryFeatureCollection(geoj as string);

						geojson = unsafeFC;
					} catch (exception) {}

					return { name: inDoorMap.name, image: geojson } as InDoorMapItem;
				})
				.filter((inDoorMap) => inDoorMap.image !== undefined),
		);
	}, [inDoorMapsData]);

	useEffect(() => {
		setGeojsonsKey(uniqueId());
	}, [inDoorMaps]);

	/**
	 * Fetching telemetry history for charts and table
	 */
	useEffect(() => {
		if (deviceSn) {
			if (isDetailTab) {
				if (isHeatMap || isTrailMap) {
					const filtersAndConditions: TableColumnFilter[] =
						telemetryFilters !== undefined ? [...telemetryFilters] : [];

					const actualSnEqualsFilter = filtersAndConditions.find(
						(filter) => filter.column === 'sn' && filter.operator === FilterOperators.equals,
					);

					if (actualSnEqualsFilter === undefined) {
						filtersAndConditions.push({
							column: 'sn',
							operator: FilterOperators.equals,
							value: deviceSn,
						});
					} else {
						actualSnEqualsFilter.value = deviceSn;
					}

					dispatch(
						fetchGroupedTelemetryNoModalProgress({
							offset: 0,
							limit: groupedTelemetry.limit,
							zoom: groupedTelemetry.zoom || 19,
							orderings: groupedTelemetry.orderings,
							filtersAndConditions: filtersAndConditions,
						}),
					);
				}
			} else {
				if (isTelemetryTab) {
					const filtersAndConditions: TableColumnFilter[] =
						telemetryFilters !== undefined ? [...telemetryFilters] : [];

					const actualSnEqualsFilter = filtersAndConditions.find(
						(filter) => filter.column === 'sn' && filter.operator === FilterOperators.equals,
					);

					if (actualSnEqualsFilter === undefined) {
						filtersAndConditions.push({
							column: 'sn',
							operator: FilterOperators.equals,
							value: deviceSn,
						});
					} else {
						actualSnEqualsFilter.value = deviceSn;
					}

					const telemetryHistoryPayload: FiltersRequestBody2 = {
						offset: 0,
						limit: telemetryLimit,
						filtersAndConditions: filtersAndConditions,
					};

					dispatch(fetchTelemetry(telemetryHistoryPayload));
				} else if (isChartsTab) {
					const request = getFilteredTelemetry({
						offset: 0,
						limit: 100,
						filtersAndConditions: [{ column: 'sn', operator: FilterOperators.equals, value: deviceSn }],
					});

					(async () => {
						const { data, error } = await fetchRequest<TelemetryResponseData>(request);

						if (error) {
							// TODO show error
						} else {
							if (data?.data) {
								setChartTelemetry(data.data);
							}
						}
					})();
				}
			}
		}
		// Don't need to react on "config and filters", deep comparision is not necessary
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deviceSn, dispatch, isLocationMap, tab]);

	/**
	 * Fetch device detail for properties card
	 */
	useEffect(() => {
		dispatch(fetchDevice(deviceSn));
	}, [dispatch, deviceSn]);

	groupedTelemetry.data.forEach(
		(telemetry: MapTelemetry) => (telemetry.timeStamp = new Date(telemetry.time)?.getTime() / 10000),
	);

	const trailPoints = useMemo(() => {
		return (groupedTelemetry.data as MapTelemetry[])
			.map((telemetry, index, array) => {
				if (telemetry.lat && telemetry.lng) {
					const nextTelemetry = array[index + 1];

					let delta = 0;

					if (telemetry.timeStamp && nextTelemetry?.timeStamp) {
						delta = telemetry.timeStamp - nextTelemetry.timeStamp;
					}

					if (delta < 5) {
						delta = 5;
					}

					if (delta > 200) {
						delta = 200;
					}

					return {
						locationUpdateDetails: telemetry.location_update_details,
						location: [telemetry.lat, telemetry.lng] as LatLngTuple,
						time: telemetry.time,
						delta: delta,
					};
				}

				return null;
			})
			.filter(notEmpty);
	}, [groupedTelemetry]);

	// const trailCoordinates = useMemo(
	// 	() =>
	// 		trailPoints.map((point, index, array) => {
	// 			const time = point.timeStamp;
	// 			const nextTime = array[index + 1]?.timeStamp;

	// 			// console.log(`${time}  ${nextTime} ${time - nextTime}`);

	// 			return [...point.location, time - nextTime];
	// 		}),
	// 	[trailPoints],
	// );

	// if (trailFirstLoad && trailCoordinates && trailCoordinates.length > 0) {
	// 	setTrailFirstLoad(false);
	// 	setMapBounds(trailCoordinates);
	// }

	const trailLines = useMemo(
		() =>
			trailPoints.map<{ positions: LatLngTuple[]; color: string } | undefined>((point, i, arr) => {
				const previousPointPosition = arr[i - 1]?.location;

				const R = Math.floor((i * 256) / trailPoints.length);
				const G = 255 - R;

				const color = (0x1000000 + R * 0x10000 + G * 0x100).toString(16).toUpperCase().substring(1);

				const colorString = `#${color}`;

				return previousPointPosition
					? { positions: [previousPointPosition, point.location], color: colorString }
					: undefined;
			}),
		[trailPoints],
	);

	// const heatCoordinates: Coordinates[] = trailPoints.map((point, index, array) => {
	// 	const time = point.timeStamp;
	// 	const nextTime = array[index + 1]?.timeStamp;

	// 	// console.log(`${time}  ${nextTime} ${time - nextTime}`);

	// 	return [...point.location, time - nextTime];
	// });

	const chartPoints: ChartPoint[] = useMemo(
		() =>
			chartTelemetry
				.map((data) => ({
					time: data.time ? new Date(data.time) : undefined,
					accuracy: data.accuracy,
					battery: data.battery,
					temperature: data.temperature,
					lightIntensity: data.light_intensity,
					maxAcceleration: data.max_acceleration,
					maxAccelerationHistory: data.max_acceleration_history,
				}))
				.reverse(),
		[chartTelemetry],
	);

	useEffect(() => {
		dispatch(
			fetchNotifications({
				limit: 10,
				offset: 0,
				orderings: [],
				filtersAndConditions: [], // TODO filter as bellow
				// config: {
				// 	limit: 10,
				// 	order: SortOrder.Descending,
				// 	orderBy: 'time',
				// },
				// filters: {
				// 	sn: [{ operator: FilterOperators.equals, value: deviceSn }],
				// },
			}),
		);
	}, [dispatch, deviceSn]);

	const handleChangeTab = (_: React.ChangeEvent<{}>, newTab: number) => {
		setTab(newTab);
	};

	// Get current device zones to render on the map
	const deviceZones = Array.isArray(deviceDetail?.data?.zones) ? deviceDetail?.data?.zones ?? [] : [];
	const deviceZoneIDs = deviceZones.map((zone) => zone.id);
	const currentDeviceZones = zones.filter((zone) => deviceZoneIDs.includes(zone.id));

	function drawEmptyMap(): JSX.Element {
		return (
			<div className={classes.center}>
				<LocationOffRounded fontSize="large" />
				<Typography variant="subtitle1" gutterBottom>
					{t('noLocation')}
				</Typography>
			</div>
		);
	}

	let lastCall = Date.now();

	function handleZoomChange(zoom: number, filtersAndConditions?: TableColumnFilter[]) {
		lastCall = Date.now();

		setTimeout(() => {
			const now = Date.now();

			if (now - lastCall > 950) {
				dispatch(
					fetchGroupedTelemetryNoModalProgress({
						offset: 0,
						limit: 1000,
						zoom: zoom,
						orderings: [{ column: 'time', sortOrder: SortOrder.Descending }],
						filtersAndConditions: filtersAndConditions,
					}),
				);
			}
		}, 1000);
	}

	function handleSaveFilters(filters: TableColumnFilter[]) {
		dispatch(
			fetchGroupedTelemetryNoModalProgress({
				offset: 0,
				limit: groupedTelemetry.limit,
				zoom: groupedTelemetry.zoom,
				orderings: groupedTelemetry.orderings,
				filtersAndConditions: filters,
			}),
		);
	}

	return (
		<>
			<BackButton />
			<Tabs
				className={classes.marginTop}
				value={tab}
				onChange={handleChangeTab}
				indicatorColor="primary"
				textColor="primary"
			>
				<Tab label={t('detail')} />
				<Tab label={t('telemetryHistory')} />
				<Tab label={t('charts')} />
			</Tabs>
			<Divider light />

			<TabPanel value={tab} index={0}>
				<Paper>
					<CardHeader
						title={deviceDetail?.sn}
						subheader={
							<Grid container spacing={3}>
								<Grid item>{`${t('type')}: ${deviceDetail?.deviceType?.name}`}</Grid>
								{/* {attachedPlacementName && (
									<Grid item>{`${t('attached')} ${t('placement')}: ${attachedPlacementName}`}</Grid>
								)} */}
							</Grid>
						}
						action={
							<>
								<PopoverOptions id="devicesMap-mapTypes" icon={<TuneRounded />} title={t('mapType')}>
									<ButtonGroup color="primary" disableElevation size="small">
										<Button
											onClick={() => setMapType(MapType.LOCATION)}
											variant={isLocationMap ? 'contained' : 'outlined'}
										>
											{t('location')}
										</Button>
										<Button
											onClick={() => setMapType(MapType.HEAT)}
											variant={isHeatMap ? 'contained' : 'outlined'}
										>
											{t('heat')}
										</Button>
										<Button
											onClick={() => setMapType(MapType.TRAIL)}
											variant={isTrailMap ? 'contained' : 'outlined'}
										>
											{t('trail')}
										</Button>
									</ButtonGroup>
								</PopoverOptions>
								<DeviceDetailTelemetryTableFilter
									filters={groupedTelemetry.filters}
									deviceSn={deviceSn}
									handleFilters={handleSaveFilters}
								/>
							</>
						}
					/>
					<Divider light />
					{deviceDetail && (
						<>
							{isHeatMap &&
								(trailPoints.length > 0 ? (
									<MapContainer
										height={500}
										zoom={8}
										// bounds={mapBounds}
										onZoomed={(zoom) => handleZoomChange(zoom, groupedTelemetry.filters)}
									>
										<FeatureGroup>
											{inDoorMaps && (
												<GeoJSON
													key={geojsonsKey}
													data={inDoorMaps.map((inDoorMap) => inDoorMap.image)}
													onEachFeature={onEachFeature}
												/>
											)}
										</FeatureGroup>
										<HeatmapLayer
											fitBoundsOnLoad
											points={trailPoints}
											latitudeExtractor={(coords: any) => coords.location[0]}
											longitudeExtractor={(coords: any) => coords.location[1]}
											intensityExtractor={(coords: any) => coords.delta}
											radius={35}
											max={12}
										/>
									</MapContainer>
								) : (
									drawEmptyMap()
								))}

							{isLocationMap &&
								(deviceDetail?.data && deviceDetail.data.lat && deviceDetail.data.lng ? (
									<MapContainer center={[deviceDetail.data.lat, deviceDetail.data.lng]} height={500}>
										<FeatureGroup>
											{inDoorMaps && (
												<GeoJSON
													key={geojsonsKey}
													data={inDoorMaps.map((inDoorMap) => inDoorMap.image)}
													onEachFeature={onEachFeature}
												/>
											)}
										</FeatureGroup>
										{deviceDetail.data.accuracy && deviceDetail.data.accuracy < 1000 && (
											<Circle
												center={[deviceDetail.data.lat, deviceDetail.data.lng]}
												fillColor="blue"
												radius={deviceDetail.data.accuracy}
												stroke={false}
											/>
										)}
										<MapMarker
											type="deviceBlue"
											position={[deviceDetail.data.lat, deviceDetail.data.lng]}
										>
											<MapPopup title={`${t('sn')}: ${deviceDetail.sn}`}>
												<List dense disablePadding>
													<ListItem key="sn" disableGutters>
														{`${t('location')}: ${deviceDetail.data.lat}, ${
															deviceDetail.data.lng
														}`}
													</ListItem>
													{deviceDetail.data.time && (
														<ListItem key="time" disableGutters>
															{`${t('time')}: `}
															{format(
																new Date(deviceDetail.data.time),
																INPUT_DATE_TIME_MASK,
															)}
														</ListItem>
													)}
												</List>
											</MapPopup>
										</MapMarker>
										<DeviceDetailZones zones={currentDeviceZones} />
									</MapContainer>
								) : (
									drawEmptyMap()
								))}

							{isTrailMap &&
								(trailPoints.length > 0 ? (
									<MapContainer
										height={500}
										zoom={7}
										onZoomed={(zoom) => handleZoomChange(zoom, groupedTelemetry.filters)}
									>
										<FeatureGroup>
											{inDoorMaps && (
												<GeoJSON
													key={geojsonsKey}
													data={inDoorMaps.map((inDoorMap) => inDoorMap.image)}
													onEachFeature={onEachFeature}
												/>
											)}
										</FeatureGroup>
										{trailLines.map((line) => {
											return line && <Polyline positions={line.positions} color={line.color} />;
										})}
										{trailPoints.map(({ locationUpdateDetails, location, time }) => {
											const [lat, lng] = location;

											return (
												<MapMarker
													key={`${time}-${lat}-${lng}`}
													type="point"
													position={[lat, lng]}
												>
													<MapPopup title={`${t('sn')}: ${deviceDetail.sn}`}>
														<List dense disablePadding>
															<ListItem key="location" disableGutters>
																{`${t('location')}: ${lat}, ${lng}`}
															</ListItem>
															<ListItem key="locationUpdateDetails" disableGutters>
																{`${t(
																	'locationUpdateDetails',
																)}: ${locationUpdateDetails}`}
															</ListItem>
															{time && (
																<ListItem key="time" disableGutters>
																	{`${t('time')}: `}
																	{format(new Date(time), INPUT_DATE_TIME_MASK)}
																</ListItem>
															)}
														</List>
													</MapPopup>
												</MapMarker>
											);
										})}
									</MapContainer>
								) : (
									drawEmptyMap()
								))}
						</>
					)}
				</Paper>
				{deviceDetail && (
					<Grid container spacing={3} className={classes.marginTop}>
						<Grid item xs={12} sm={6} lg={4}>
							<DeviceDetailInformation deviceData={deviceDetail} />
						</Grid>
						<Grid item xs={12} sm={6} lg={4}>
							<DeviceDetailTelemetryProperties deviceData={deviceDetail} />
						</Grid>
						<Grid item xs={12} sm={6} lg={4}>
							<DeviceDetailLocationProperties deviceData={deviceDetail} />
						</Grid>
						<Grid item xs={12} sm={6} lg={4}>
							<DeviceDetailPlacementProperties
								data={deviceDetail.placementRelations ?? []}
								deviceId={deviceDetail.id}
							/>
						</Grid>
						<Grid item xs={12} sm={12} lg={8}>
							<Card>
								<CardHeader
									title={
										<Typography component="h2" variant="h6">
											{t('notifications')}
										</Typography>
									}
									subheader={
										notificationsData[0]?.time &&
										t('lastUpdate', {
											date: format(new Date(notificationsData[0].time), INPUT_DATE_TIME_MASK),
										})
									}
								/>
								<Divider light />
								<NotificationsTable data={notificationsData} limit={5} offset={0} />
							</Card>
						</Grid>
					</Grid>
				)}
			</TabPanel>

			<TabPanel value={tab} index={1}>
				<Paper>
					{deviceDetail && (
						<DeviceDetailTelemetryTable
							userId={userId}
							data={telemetry}
							total={telemetryTotal}
							offset={telemetryOffset}
							limit={telemetryLimit}
							filters={telemetryFilters}
							deviceSn={deviceSn}
							deviceType={deviceDetail.deviceType?.name}
						/>
					)}
				</Paper>
			</TabPanel>

			<TabPanel value={tab} index={2}>
				{deviceDetail?.data ? (
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} lg={4}>
							<DetailCard
								withChart
								title={t('battery')}
								value={
									isNil(deviceDetail.data.battery)
										? t('notAvailable')
										: t('percentage', {
												value: deviceDetail.data.battery.toFixed(2),
										  })
								}
							>
								{!isNil(deviceDetail.data.battery) && (
									<DetailChart
										chartData={chartPoints}
										dataKey="battery"
										tooltip={
											<Tooltip
												content={({ payload }: TooltipProps) => {
													const data: ChartPoint = payload?.[0]?.payload;
													const value = isNil(data?.battery)
														? ''
														: t('percentage', { value: data?.battery.toFixed(2) });

													const time = data?.time
														? format(data?.time, INPUT_DATE_TIME_MASK)
														: '';

													return <ChartTooltip value={value} time={time} />;
												}}
											/>
										}
									/>
								)}
							</DetailCard>
						</Grid>
						<Grid item xs={12} sm={6} lg={4}>
							<DetailCard
								withChart
								title={t('temperature')}
								value={
									isNil(deviceDetail.data.temperature)
										? t('notAvailable')
										: t('degreesCelsius', { value: deviceDetail.data.temperature })
								}
							>
								{!isNil(deviceDetail.data.temperature) && (
									<DetailChart
										chartData={chartPoints}
										dataKey="temperature"
										tooltip={
											<Tooltip
												content={({ payload }: TooltipProps) => {
													const data: ChartPoint = payload?.[0]?.payload;
													const value = isNil(data?.temperature)
														? t('notAvailable')
														: t('degreesCelsius', { value: data?.temperature });

													const time = data?.time
														? format(data?.time, INPUT_DATE_TIME_MASK)
														: t('notAvailable');

													return <ChartTooltip value={value} time={time} />;
												}}
											/>
										}
									/>
								)}
							</DetailCard>
						</Grid>
						<Grid item xs={12} sm={6} lg={4}>
							<DetailCard
								withChart
								title={t('lightIntensity')}
								value={
									isNil(deviceDetail.data.light_intensity)
										? t('notAvailable')
										: t('illuminance', {
												value: deviceDetail.data.light_intensity,
										  })
								}
							>
								{!isNil(deviceDetail.data.light_intensity) && (
									<DetailChart
										chartData={chartPoints}
										dataKey="lightIntensity"
										tooltip={
											<Tooltip
												content={({ payload }: TooltipProps) => {
													const data: ChartPoint = payload?.[0]?.payload;
													const value = isNil(data?.lightIntensity)
														? t('notAvailable')
														: data?.lightIntensity.toFixed(2);

													const time = data?.time
														? format(data?.time, INPUT_DATE_TIME_MASK)
														: t('notAvailable');

													return <ChartTooltip value={value} time={time} />;
												}}
											/>
										}
									/>
								)}
							</DetailCard>
						</Grid>
					</Grid>
				) : (
					<Typography>{t('notAvailable')}</Typography>
				)}
			</TabPanel>
		</>
	);
};

export const FETCH_WORKFLOWS_REQUEST = '[WORKFLOWS_ACTIONS] FETCH_WORKFLOWS_REQUEST';
export const FETCH_WORKFLOWS_SUCCESS = '[WORKFLOWS_ACTIONS] FETCH_WORKFLOWS_SUCCESS';
export const FETCH_WORKFLOWS_ERROR = '[WORKFLOWS_ACTIONS] FETCH_WORKFLOWS_ERROR';

export const DELETE_WORKFLOW_REQUEST = '[WORKFLOWS_ACTIONS] DELETE_WORKFLOW_REQUEST';
export const DELETE_WORKFLOW_SUCCESS = '[WORKFLOWS_ACTIONS] DELETE_WORKFLOW_SUCCESS';
export const DELETE_WORKFLOW_ERROR = '[WORKFLOWS_ACTIONS] DELETE_WORKFLOW_ERROR';

export const SET_CURRENT_WORKFLOW = '[WORKFLOWS_ACTIONS] SET_CURRENT_WORKFLOW';

export const EDIT_WORKFLOW_REQUEST = '[WORKFLOWS_ACTIONS] EDIT_WORKFLOW_REQUEST';
export const EDIT_WORKFLOW_SUCCESS = '[WORKFLOWS_ACTIONS] EDIT_WORKFLOW_SUCCESS';
export const EDIT_WORKFLOW_ERROR = '[WORKFLOWS_ACTIONS] EDIT_WORKFLOW_ERROR';

export const CREATE_WORKFLOW_REQUEST = '[WORKFLOWS_ACTIONS] CREATE_WORKFLOW_REQUEST';
export const CREATE_WORKFLOW_SUCCESS = '[WORKFLOWS_ACTIONS] CREATE_WORKFLOW_SUCCESS';
export const CREATE_WORKFLOW_ERROR = '[WORKFLOWS_ACTIONS] CREATE_WORKFLOW_ERROR';

import React from 'react';
import { LatLngLiteral } from 'leaflet';
import { Polygon } from 'react-leaflet';
import { fromJSONToObject } from '../../../helpers/object';
import { Zone } from '../../Zones/zones-types';
import { toLatLngExpression } from '../../Zones/helpers';
import { COLORS } from '../../../theme';
import { MapPopup } from '../../Maps/components/MapPopup';
import { useTranslation } from 'react-i18next';
import { getCenterOfCoordinates } from '../../../components/Maps/helpers';
import { MapMarker } from '../../Maps/components/MapMarker';

type Props = {
	zones: Zone[];
	onClick?: (zone: Zone) => void;
	selectedZoneId?: string;
	onSelected?: any;
};

export const DeviceDetailZones = ({ zones, onClick, selectedZoneId, onSelected }: Props) => {
	const { t } = useTranslation();

	const handleClick = (zone: Zone) => {
		if (typeof onClick === 'function') {
			onClick(zone);
		}
	};

	return (
		<>
			{zones.map((zone: Zone) => {
				const coords = fromJSONToObject<LatLngLiteral[]>(zone.polygon);

				if (!coords) return null;

				const positions = toLatLngExpression(coords);
				const markerLocation = getCenterOfCoordinates(coords);

				return (
					<React.Fragment key={zone.id}>
						<MapMarker
							type="zone"
							onClick={() => handleClick(zone)}
							position={markerLocation}
							popupOpend={zone.id === selectedZoneId}
							onpopupopen={() => onSelected(zone.id)}
							onpopupclose={() => onSelected(undefined)}
						>
							<MapPopup title={`${t('zone')}: ${zone.name}`} />
						</MapMarker>
						<Polygon
							onClick={() => handleClick(zone)}
							key={zone.id}
							color={COLORS.orange.main}
							positions={positions}
						>
							<MapPopup title={`${t('zone')}: ${zone.name}`} />
						</Polygon>
					</React.Fragment>
				);
			})}
		</>
	);
};

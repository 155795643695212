import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserRole } from '../../UserRoles/user-roles-types';
import { UserRelation } from '../../../store/user/user-types';
import { useDispatch } from 'react-redux';
import { UUID } from '../../../types';
import { editUserRelation, createUserRelation, deleteUserRelation } from '../../../store/user/user-async-actions';
import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { Button } from '../../../components/Button/Button';
import { Add, DeleteRounded } from '@material-ui/icons';
import { addModalDialog, removeModalDialog } from '../../../store/app/app-actions';
import { AddUserRoleValue, AddUserRoleForm } from './AddUserRoleForm';

type Props = {
	userId: UUID;
	userRoles: UserRole[];
	userRelations: UserRelation[];
	canEditUserRoles: boolean;
	isPlatformAdmin: boolean;
};

export const UsersRoleManagementForm = ({
	userId,
	userRoles,
	userRelations,
	canEditUserRoles,
	isPlatformAdmin,
}: Props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	function handleAddRoleDialog(relation?: UserRelation): void {
		dispatch(
			addModalDialog({
				id: 'addUserRoleModal',
				title: t('addUserRoleModalTitle'),
				contentText: t('addUserRoleModalDescription', {
					script: 'standingReportOrder.name',
				}),
				content: (
					<AddUserRoleForm
						userRelation={relation}
						userRoles={userRoles}
						userRelations={userRelations}
						onSubmit={(userRelation: AddUserRoleValue) =>
							handleAddUserRole(userRelation, 'addUserRoleModal')
						}
						onCancel={() => handleCloseModalDialog('addUserRoleModal')}
						isPlatfromAdmin={isPlatformAdmin}
					/>
				),
			}),
		);
	}

	function handleAddUserRole(relation: AddUserRoleValue, dialogId: string) {
		handleCloseModalDialog(dialogId);

		if (relation.id) {
			dispatch(
				editUserRelation({
					id: relation.id,
					userId: userId,
					roleId: relation.roleId,
					projectId: undefined,
				}),
			);
		} else {
			dispatch(createUserRelation({ userId: userId, roleId: relation.roleId, projectId: undefined }));
		}
	}

	function handleCloseModalDialog(dialogId: string): void {
		dispatch(removeModalDialog(dialogId));
	}

	function handleDeleteUserRole(relationId: UUID) {
		dispatch(deleteUserRelation(relationId));
	}

	return (
		<>
			{canEditUserRoles && (
				<Button
					// className={classes.addProjectBtn}
					onClick={() => handleAddRoleDialog()}
					startIcon={<Add />}
					id="addRole"
					data-cy="btn-add:role"
				>
					{t('addRole')}
				</Button>
			)}
			<List id="relations">
				{userRelations
					.filter((relation) => relation.projectId === null)
					.map((relation) => (
						<ListItem key={relation.id} button>
							<ListItemText primary={userRoles.find((role) => role.id === relation.roleId)?.name} />
							{canEditUserRoles && (
								<ListItemSecondaryAction>
									<IconButton edge="end" onClick={() => handleDeleteUserRole(relation.id)}>
										<DeleteRounded />
									</IconButton>
								</ListItemSecondaryAction>
							)}
						</ListItem>
					))}
			</List>
		</>
	);
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditUserForm, EditUserFormValues } from './components/EditUserForm';
import { Grid } from '@material-ui/core';
import { UserManagementState } from '../../store/user-management/user-management-types';
import { RootState } from '../../store/types';
import { editUser, fetchUser } from '../../store/user-management/user-management-async-actions';
import { InfoCard } from '../../components/InfoCard/InfoCard';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BackButton } from '../../components/Button/BackButton';
import { UserState } from '../../store/user/user-types';
import { ProjectsRoleManagementForm } from './components/ProjectsRoleManagementForm';
import { fetchUserRelations } from '../../store/user/user-async-actions';
import { FilterOperators } from '../../components/Table/constants';
import { UsersRoleManagementForm } from './components/UsersRoleManagementForm';
import { Tenant } from '../Tenants/tenants-types';
import { fetchTenants } from '../../services/user/user-service';
import { fetchRequest } from '../../services/helpers';
import { TenantsResponseData } from '../../services/user/user-types';
import { ProjectResponseData, ProjectsResponseData } from '../Projects/projects-types';
import { getProjects } from '../../services/projects/projects-service';
import { getFilteredUserRoles } from '../../services/user-management/user-management-service';
import { UserRole, UserRolesResponseData } from '../UserRoles/user-roles-types';
import { hasPermission } from '../../helpers/auth';
import { PermissionsDotNet } from '../../helpers/generalConstants';
import { TableColumnFilter } from '../../components/Table/types';

type RouteParams = {
	userId: string;
};

export const UserDetail = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { userDetailData } = useSelector<RootState, UserManagementState>((state) => state.users);
	const { userRelations, tenant, permissions } = useSelector<RootState, UserState>((state) => state.user);
	const params = useParams<RouteParams>();
	const userId = userDetailData?.id || '';
	const [tenants, setTenants] = useState<Tenant[]>();
	const [allProjects, setAllProjects] = useState<ProjectResponseData[]>([]);
	const [allRoles, setAllRoles] = useState<UserRole[]>([]);
	const canEditUserRoles = hasPermission(PermissionsDotNet.UserRoleWrite, permissions);

	/**
	 * Fetching all tenants
	 */
	useEffect(() => {
		const request = fetchTenants({
			// Should be enough to get all zones, even for super admin
			limit: 200,
			offset: 0,
			orderings: [],
			filtersAndConditions: [],
		});

		(async () => {
			const { data, error } = await fetchRequest<TenantsResponseData>(request);

			if (error) {
				// TODO show error
			} else {
				setTenants(data?.data);
			}
		})();
	}, []);

	/**
	 * Fetching all projects
	 */
	useEffect(() => {
		const filters: TableColumnFilter[] = [];

		if (tenant?.id) {
			filters.push({ column: 'userId', operator: FilterOperators.ignore, value: 0 });
		} else {
			if (userDetailData?.tenantId) {
				filters.push({ column: 'tenantId', operator: FilterOperators.equals, value: userDetailData?.tenantId });
			}
		}
		// }

		const request = getProjects({
			// Should be enough to get all zones, even for super admin
			limit: 0,
			offset: 0,
			orderings: [],
			filtersAndConditions: filters,
		});

		(async () => {
			const { data, error } = await fetchRequest<ProjectsResponseData>(request);

			if (error) {
				// TODO show error
			} else {
				setAllProjects(data?.data ?? []);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userDetailData?.tenantId]);

	/**
	 * Fetching all roles
	 */
	useEffect(() => {
		const request = getFilteredUserRoles({
			// Should be enough to get all zones, even for super admin
			limit: 200,
			offset: 0,
			orderings: [],
			filtersAndConditions: [],
		});

		(async () => {
			const { data, error } = await fetchRequest<UserRolesResponseData>(request);

			if (error) {
				// TODO show error
			} else {
				setAllRoles(data?.data ?? []);
			}
		})();
	}, []);

	/**
	 * Fetch user data if missing in state
	 */
	useEffect(() => {
		if (!userId) {
			dispatch(fetchUser(params.userId));
		}
	}, [dispatch, userId, params.userId]);

	useEffect(() => {
		dispatch(
			fetchUserRelations({
				limit: 1000,
				offset: 0,
				orderings: [],
				filtersAndConditions: [
					{
						column: 'userId',
						operator: FilterOperators.equals,
						value: userId,
					},
				],
			}),
		);

		// Don't need to react on "config" and "filters" and deep comparision is not necessary
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const formValues: EditUserFormValues = {
		email: userDetailData?.email || '',
		firstName: userDetailData?.firstName || '',
		lastName: userDetailData?.lastName || '',
		active: userDetailData?.active || false,
		tenantId: userDetailData?.tenantId,
	};

	const handleEditUser = (values: EditUserFormValues) => {
		dispatch(editUser({ id: userId, ...values }));
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<BackButton />
			</Grid>

			<Grid item xs={12} sm={12} lg={4}>
				{userDetailData && tenants && (
					<InfoCard title={t('editUserFormTitle')}>
						<EditUserForm
							email={userDetailData.email}
							values={formValues}
							onSubmit={(values: EditUserFormValues) => {
								handleEditUser(values);
							}}
							tenants={tenants}
							actualUserTenantId={tenant?.id}
						/>
					</InfoCard>
				)}
			</Grid>
			<Grid item xs={12} sm={12} lg={4}>
				<InfoCard title={t('editRolesFormTitle')}>
					<UsersRoleManagementForm
						userId={userId}
						userRoles={allRoles}
						userRelations={userRelations.data}
						canEditUserRoles={canEditUserRoles}
						isPlatformAdmin={tenant === undefined}
					/>
				</InfoCard>
			</Grid>
			<Grid item xs={12} sm={12} lg={4}>
				<InfoCard title={t('editProjectsFormTitle')}>
					<ProjectsRoleManagementForm
						userId={userId}
						allProjects={allProjects}
						userRoles={allRoles}
						userRelations={userRelations.data}
						canEditUserRoles={canEditUserRoles}
					/>
				</InfoCard>
			</Grid>
		</Grid>
	);
};

// String. because text field value is a string, even when used as a input[type="number"]
export const LIGHT_INTENSITY_MIN = '0';
export const LIGHT_INTENSITY_MAX = '200000';

// Number, because range input value is a number
export const TEMPERATURE_MIN = -100;
export const TEMPERATURE_MAX = 100;
export const BATTERY_MIN = 0;
export const BATTERY_MAX = 100;

export enum LocalizationMethod {
	NONE = 'none',
	TDOA = 'tdoa',
	WIFI = 'wifi',
	GNSS = 'gnss',
	GPS = 'gps',
	NOFIX = 'nofix',
	STATIC = 'static',
	GTW_L = 'gtw_l',
	CELL = 'cell',
	BLUETOOTH = 'bluetooth',
	UNKNOWN = 'unknown',
}

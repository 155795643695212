import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { GeoJSON } from 'geojson';
import { Nullable } from '../../types';
import { CloudUploadRounded } from '@material-ui/icons';

type Props = {
	title: string;
	onUpload: (geoJSON: GeoJSON) => void;
};

export const UploadGeoJSON = ({ onUpload, title }: Props) => {
	const handleInputChange = (files: Nullable<FileList>) => {
		if (files && files.length === 1) {
			const reader = new FileReader();

			reader.readAsText(files[0]);
			reader.addEventListener('load', async (event) => {
				const content = event.target?.result as string;
				const payload: GeoJSON = await JSON.parse(content);

				onUpload(payload);
			});
		}
	};

	return (
		<span>
			<input
				id="uploadGeoJSON"
				name="uploadGeoJSON"
				type="file"
				accept=".json"
				onChange={(event) => handleInputChange(event.target.files)}
				style={{ display: 'none' }}
			/>
			<label htmlFor="uploadGeoJSON">
				<Tooltip title={title}>
					<IconButton component="span" data-cy="btn-upload:geoJSON">
						<CloudUploadRounded />
					</IconButton>
				</Tooltip>
			</label>
		</span>
	);
};

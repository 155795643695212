import { FeatureCollection } from 'geojson';
import { LatLngTuple, LatLngLiteral } from 'leaflet';
import { notEmpty } from '../../helpers/array';
import { DeviceResponseData2 } from '../Devices/devices-types';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const st = require('geojson-bounds');

export const toLatLngExpression = (latLng: LatLngLiteral[]): LatLngTuple[] => latLng.map(({ lat, lng }) => [lat, lng]);

export const toLatLngLiteral = (latLng: LatLngTuple[]): LatLngLiteral[] => latLng.map(([lat, lng]) => ({ lat, lng }));

export const getBoundsFromMultipleDevicesWithActualBounds = (
	devices: DeviceResponseData2[],
	actualBounds?: LatLngTuple[],
) => {
	const bounds = devices
		.map((device) => {
			const coords = device.data;

			if (coords && coords !== null && coords.lat && coords.lng) {
				return { lat: coords.lat, lng: coords.lng };
			}

			return null;
		})
		.filter(notEmpty)
		.flat(2)
		.reduce<{ lats: number[]; lngs: number[] }>(
			(acc, { lat, lng }) => {
				return {
					...acc,
					lats: [...acc.lats, lat],
					lngs: [...acc.lngs, lng],
				};
			},
			{ lats: [], lngs: [] },
		);

	if (actualBounds) {
		return [
			[Math.min(...bounds.lats, actualBounds[0][0]), Math.min(...bounds.lngs, actualBounds[0][1])],
			[Math.max(...bounds.lats, actualBounds[1][0]), Math.max(...bounds.lngs, actualBounds[1][1])],
		] as LatLngTuple[];
	}

	Math.min(...bounds.lats);

	return [
		[Math.min(...bounds.lats, 0), Math.min(...bounds.lngs, 0)],
		[Math.max(...bounds.lats, 0), Math.max(...bounds.lngs, 0)],
	] as LatLngTuple[];
};

export const getBoundsFromGeoJson = (geoJson: FeatureCollection | undefined) => {
	if (geoJson === undefined) {
		return undefined;
	}

	try {
		const tmp = st.extent(geoJson);

		return [
			[tmp[1], tmp[0]],
			[tmp[3], tmp[2]],
		] as LatLngTuple[];
		// }
	} catch (exception) {
		console.log(exception);
	}

	return undefined;
};

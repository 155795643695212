import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/Button/Button';
import SaveIcon from '@material-ui/icons/Save';
import { ModalDialogFormActions } from '../../../components/ModalDialog/ModalDialogFormActions';
import { UserRelation } from '../../../store/user/user-types';
import { UUID } from '../../../types';
import { ProjectResponseData } from '../../Projects/projects-types';
import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

// const useStyles = makeStyles(({ spacing }: Theme) => ({
// 	select: {
// 		'& .MuiFormLabel-root': {
// 			width: '90%',
// 			textOverflow: 'ellipsis',
// 			overflow: 'hidden',
// 			whiteSpace: 'nowrap',
// 			paddingRight: spacing(2),

// 			'&.Mui-focused': {
// 				width: '100%',
// 				textOverflow: 'initial',
// 			},
// 		},
// 	},
// }));

type Props = {
	relations?: _.Dictionary<UserRelation[]>;
	projects: ProjectResponseData[];
	onSubmit: (projectIds: UUID[]) => void;
	onCancel?: () => void;
};

export const AddProjectForm = ({ onSubmit, onCancel, relations, projects }: Props) => {
	// const classes = useStyles();
	const { t } = useTranslation();

	const [selectedProjects, setSelectedProjects] = useState<UUID[]>([]);

	const [projectsError, setProjectsError] = useState(false);

	const handleProjects = (event: unknown, newValue: ProjectResponseData[]) => {
		setSelectedProjects(newValue.map((project) => project.id));
		setProjectsError(false);
	};

	const handleSubmitForm = () => {
		let valid = true;

		if (selectedProjects === undefined || selectedProjects?.length === 0) {
			valid = false;
			setProjectsError(true);
		}

		if (valid) {
			// onSubmit
			onSubmit(selectedProjects);
		}
	};

	let filteredProjects = projects;

	if (relations) {
		filteredProjects = projects.filter((project) => {
			return Object.entries(relations).find((relation) => relation[0] === project.id) === undefined;
		});
	}

	return (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<Autocomplete
					// className={classes.select}
					color="primary"
					multiple
					options={filteredProjects}
					getOptionLabel={(option) => option.name}
					filterSelectedOptions
					disableCloseOnSelect
					fullWidth
					renderInput={(params) => (
						<TextField
							{...params}
							label={t('selectProject')}
							// placeholder="Favorites"
							error={projectsError}
							helperText={projectsError && t('validationErrors.required')}
							variant="outlined"
						/>
					)}
					onChange={handleProjects}
				/>
			</Grid>
			<Grid item>
				<ModalDialogFormActions>
					<Button
						onClick={handleSubmitForm}
						startIcon={<SaveIcon />}
						type="button"
						data-cy="btn-submit:userForm"
					>
						{t('save')}
					</Button>
					{onCancel && (
						<Button onClick={onCancel} variant="text">
							{t('cancel')}
						</Button>
					)}
				</ModalDialogFormActions>
			</Grid>
		</Grid>
	);
};

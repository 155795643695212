import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import { Button } from '../../../components/Button/Button';
import SaveIcon from '@material-ui/icons/Save';
import { ModalDialogFormActions } from '../../../components/ModalDialog/ModalDialogFormActions';
import { GroupResponseData } from '../pipelines-types';
import { UUID } from '../../../types';
import { Autocomplete } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import { fetchRequest } from '../../../services/helpers';
import { getFilteredContactGroups } from '../../../services/contact-groups/contact-groups-service';
import { GroupsResponseData } from '../../ContactGroups/contact-groups-types';

const useStyles = makeStyles(({ spacing }: Theme) => ({
	select: {
		'& .MuiFormLabel-root': {
			width: '90%',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			paddingRight: spacing(2),

			'&.Mui-focused': {
				width: '100%',
				textOverflow: 'initial',
			},
		},
	},
}));

type Props = {
	onSubmit: (values: UUID[]) => void;
	onCancel: () => void;
	defaultValues?: GroupResponseData[];
};

export const PipelineRuleContactGroupsForm = ({ onSubmit, onCancel, defaultValues }: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [allContactGroups, setAllContactGroups] = useState<GroupResponseData[]>([]);

	const [selectedContactGroups, setSelectedContactGroups] = useState(defaultValues ?? []);

	useEffect(() => {
		const request = getFilteredContactGroups({
			limit: -1,
			offset: 0,
			filtersAndConditions: [],
			orderings: [],
		});

		(async () => {
			const { data, error } = await fetchRequest<GroupsResponseData>(request);

			if (error) {
				// TODO show error
			} else {
				if (data) {
					setAllContactGroups(data.data);
				}
			}
		})();
	}, [dispatch]);

	function handleSubmitForm() {
		onSubmit(selectedContactGroups.map((selectedContactGroup) => selectedContactGroup.id));
	}

	function getSelectedContactGroups() {
		return allContactGroups.filter((contactGroup) =>
			selectedContactGroups.find((selectedContactGroup) => selectedContactGroup.id === contactGroup.id),
		);
	}

	return (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<Autocomplete
					className={classes.select}
					color="primary"
					multiple
					options={allContactGroups}
					getOptionLabel={(option) => option.name}
					value={getSelectedContactGroups()}
					filterSelectedOptions
					disableCloseOnSelect
					fullWidth
					renderInput={(params) => (
						<TextField
							{...params}
							label={t('selectPlacementGroup')}
							// placeholder="Favorites"
							// error={permissionsError}
							// helperText={permissionsError && t('validationErrors.required')}
							variant="outlined"
						/>
					)}
					onChange={(event: any, newValue: GroupResponseData[]) => setSelectedContactGroups(newValue)}
				/>
			</Grid>
			<Grid item>
				<ModalDialogFormActions>
					<Button startIcon={<SaveIcon />} onClick={handleSubmitForm} data-cy="btn-submit:pipelineForm">
						{t('save')}
					</Button>
					<Button onClick={onCancel} variant="text" data-cy="btn-cancel:pipelineForm">
						{t('cancel')}
					</Button>
				</ModalDialogFormActions>
			</Grid>
		</Grid>
	);
};

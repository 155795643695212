import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Table, TableBody, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { DeviceResponseData2, EditDevicePayload } from '../devices-types';
import { DetailCard } from './DetailCard';
import { EditRounded } from '@material-ui/icons';
import { CreateDeviceForm, CreateDeviceFormValues } from './CreateDeviceForm';
import { addModalDialog, removeModalDialog } from '../../../store/app/app-actions';
import { useDispatch, useSelector } from 'react-redux';
import { editDevice } from '../../../store/devices/devices-async-actions';
import { UserState } from '../../../store/user/user-types';
import { RootState } from '../../../store/types';

type Props = {
	deviceData: DeviceResponseData2;
};

export const DeviceDetailInformation = ({ deviceData }: Props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { tenant } = useSelector<RootState, UserState>((state) => state.user);
	const { id, sn, tenantId, deviceType, deveui, appeui, appkey, appnkey, appskey /*, deviceGroups*/ } = deviceData;

	function handleEditDeviceInformation() {
		dispatch(
			addModalDialog({
				id: 'editDeviceModal',
				title: t('editDeviceModalTitle'),
				content: (
					<CreateDeviceForm
						actualUserTenantId={tenant?.id}
						onSubmit={(values: CreateDeviceFormValues) => handleEditDevice(values)}
						onCancel={() => dispatch(removeModalDialog('editDeviceModal'))}
						values={{
							id: id,
							sn: sn,
							deviceTypeId: deviceType?.id,
							tenantId: tenantId,
							deveui: deveui,
							appeui: appeui,
							appkey: appkey,
							appnkey: appnkey,
							appskey: appskey,
						}}
					/>
				),
			}),
		);
	}

	const handleEditDevice = (values: CreateDeviceFormValues) => {
		dispatch(editDevice(values as EditDevicePayload));
		dispatch(removeModalDialog('editDeviceModal'));
	};

	return (
		<DetailCard
			title={t('deviceDetail')}
			value={
				<>
					<Tooltip title={t('devicePropertiesModalTitle') as string}>
						<IconButton
							edge="end"
							aria-label={t('devicePropertiesModal')}
							onClick={handleEditDeviceInformation}
						>
							<EditRounded />
						</IconButton>
					</Tooltip>
				</>
			}
		>
			<Table size="small">
				<TableBody>
					<TableRow>
						<TableCell>
							<strong>{t('sn')}</strong>
						</TableCell>
						<TableCell align="right">{sn ? sn : '-'}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<strong>{t('deviceType')}</strong>
						</TableCell>
						<TableCell align="right">{deviceType ? deviceType.name : t('notAvailable')}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<strong>{t('deveui')}</strong>
						</TableCell>
						<TableCell align="right">{deveui ? deveui : t('notAvailable')}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<strong>{t('appeui')}</strong>
						</TableCell>
						<TableCell align="right">{appeui ? appeui : t('notAvailable')}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<strong>{t('appkey')}</strong>
						</TableCell>
						<TableCell align="right">{appkey ? appkey : t('notAvailable')}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<strong>{t('appnkey')}</strong>
						</TableCell>
						<TableCell align="right">{appnkey ? appnkey : t('notAvailable')}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<strong>{t('appskey')}</strong>
						</TableCell>
						<TableCell align="right">{appskey ? appskey : t('notAvailable')}</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</DetailCard>
	);
};

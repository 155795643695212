export const GENERAL_PLACEMENT_GROUPS_REQUEST = '[PLACEMENT_GROUPS_ACTIONS] GENERAL_PLACEMENT_GROUPS_REQUEST';
export const GENERAL_PLACEMENT_GROUPS_ERROR = '[PLACEMENT_GROUPS_ACTIONS] GENERAL_PLACEMENT_GROUPS_ERROR';

export const FETCH_PLACEMENT_GROUPS_SUCCESS = '[PLACEMENT_GROUPS_ACTIONS] FETCH_PLACEMENT_GROUPS_SUCCESS';

export const CREATE_PLACEMENT_GROUP_SUCCESS = '[PLACEMENT_GROUPS_ACTIONS] CREATE_PLACEMENT_GROUP_SUCCESS';

export const UPDATE_PLACEMENT_GROUP_SUCCESS = '[PLACEMENT_GROUPS_ACTIONS] UPDATE_PLACEMENT_GROUP_SUCCESS';

export const DELETE_PLACEMENT_GROUP_SUCCESS = '[PLACEMENT_GROUPS_ACTIONS] DELETE_PLACEMENT_GROUP_SUCCESS';

export const GET_PLACEMENT_GROUP_DETAIL = '[PLACEMENT_GROUPS_ACTIONS] GET_PLACEMENT_GROUP_DETAIL';

export const FETCH_REPORTS_REQUEST = '[REPORTS_ACTIONS] FETCH_REPORTS_REQUEST';
export const FETCH_REPORTS_SUCCESS = '[REPORTS_ACTIONS] FETCH_REPORTS_SUCCESS';
export const FETCH_REPORTS_ERROR = '[REPORTS_ACTIONS] FETCH_REPORTS_ERROR';
export const FETCH_CURRENT_REPORTS_SUCCESS = '[REPORTS_ACTIONS] FETCH_CURRENT_REPORTS_SUCCESS';

export const FETCH_NOTIFICATIONS_REQUEST = '[REPORTS_ACTIONS] FETCH_NOTIFICATIONS_REQUEST';
export const FETCH_NOTIFICATIONS_SUCCESS = '[REPORTS_ACTIONS] FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_ERROR = '[REPORTS_ACTIONS] FETCH_NOTIFICATIONS_ERROR';

export const UPDATE_CURRENT_NOTIFICATIONS = '[REPORTS_ACTIONS] UPDATE_CURRENT_NOTIFICATIONS';

import React from 'react';

export const WorkerIcon = () => {
	return (
		<svg viewBox="0 0 1024 1024" width="30" height="30" version="1.1" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M512 640c-188.586667 0-341.333333 76.373333-341.333333 170.666667v85.333333h682.666666v-85.333333c0-94.293333-152.746667-170.666667-341.333333-170.666667M341.333333 384a170.666667 170.666667 0 0 0 170.666667 170.666667 170.666667 170.666667 0 0 0 170.666667-170.666667m-192-298.666667c-12.8 0-21.333333 8.96-21.333334 21.333334v128h-42.666666V128s-96 36.693333-96 160c0 0-32 5.973333-32 53.333333h426.666666c-2.133333-47.36-32-53.333333-32-53.333333C693.333333 164.693333 597.333333 128 597.333333 128v106.666667h-42.666666v-128c0-12.373333-8.106667-21.333333-21.333334-21.333334h-42.666666z"
				fill="#555"
			/>
		</svg>
	);
};

import * as projectsService from '../../services/projects/projects-service';
import { addToast } from '../app/app-actions';
import { fetchRequest, isStatusSuccess, createToastError } from '../../services/helpers';
import { AsyncAction } from '../types';

import {
	fetchProjectsRequest,
	fetchProjectsSuccess,
	fetchProjectsError,
	createProjectRequest,
	createProjectSuccess,
	createProjectError,
	editProjectRequest,
	editProjectSuccess,
	editProjectError,
	deleteProjectRequest,
	deleteProjectSuccess,
	deleteProjectError,
	fetchProjectInfoRequest,
	fetchProjectInfoSuccess,
	fetchProjectInfoError,
} from './projects-actions';

import { UUID } from '../../types';
import { omit } from 'lodash';
import {
	ProjectsResponseData,
	EditProjectPayload,
	ProjectResponseData,
	CreateProjectPayload,
} from '../../pages/Projects/projects-types';
import { ProjectInfoResponseData } from './projects-types';
import { fetchUserProjects } from '../user/user-async-actions';
import { FiltersRequestBody2 } from '../../components/Table/types';

export const fetchProjects: AsyncAction<FiltersRequestBody2> = (projectsPayload) => async (dispatch) => {
	dispatch(fetchProjectsRequest());

	// const filterBody = sanitizeEmptyValues<ProjectsRequestBody>({
	// 	...projectsPayload.config,
	// 	...projectsPayload.filters,
	// });

	const request = projectsService.getProjects(projectsPayload);
	const { data, error } = await fetchRequest<ProjectsResponseData>(request);

	if (data) {
		dispatch(fetchProjectsSuccess(data, projectsPayload));
	} else {
		dispatch(fetchProjectsError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(fetchProjects(projectsPayload));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const createProject: AsyncAction<CreateProjectPayload> = (project) => async (dispatch, getState) => {
	dispatch(createProjectRequest());

	const { projects } = getState();
	const request = projectsService.createProject(project);
	const { data, error } = await fetchRequest<ProjectResponseData>(request);

	if (data) {
		dispatch(createProjectSuccess());
		dispatch(
			fetchProjects({
				limit: projects.limit,
				offset: projects.offset,
				orderings: projects.orderings,
				filtersAndConditions: projects.filters,
			}),
		);

		// Need to update also projects in the main menu
		dispatch(fetchUserProjects());
	} else {
		dispatch(createProjectError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(createProject(project));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const editProject: AsyncAction<EditProjectPayload> = (project) => async (dispatch) => {
	dispatch(editProjectRequest());

	const body = omit(project, ['id']);
	const request = projectsService.editProject(body, project.id);
	const { data, error } = await fetchRequest<ProjectResponseData>(request);

	if (data) {
		dispatch(editProjectSuccess(data));

		// Need to update also projects in the main menu
		dispatch(fetchUserProjects());
	}

	if (error) {
		const tryAgainAction = () => dispatch(editProject(project));

		dispatch(editProjectError());
		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const deleteProject: AsyncAction<UUID> = (projectId) => async (dispatch) => {
	dispatch(deleteProjectRequest());

	const request = projectsService.deleteProject(projectId);
	const { status, error } = await fetchRequest<void>(request);

	if (isStatusSuccess(status) && projectId) {
		dispatch(deleteProjectSuccess(projectId));
	}

	if (error) {
		const tryAgainAction = () => dispatch(deleteProject(projectId));

		dispatch(deleteProjectError());
		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const fetchProjectInfo: AsyncAction<UUID> = (projectId) => async (dispatch) => {
	dispatch(fetchProjectInfoRequest());

	const request = projectsService.getProjectInfo(projectId);
	const { data, error } = await fetchRequest<ProjectInfoResponseData>(request);

	if (data) {
		dispatch(fetchProjectInfoSuccess(data));
	}

	if (error) {
		const tryAgainAction = () => dispatch(fetchProjectInfo(projectId));

		dispatch(fetchProjectInfoError());
		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { Grid } from '@material-ui/core';
import { fetchContacts } from '../../store/contacts/contacts-async-actions';
import { ContactsTable } from './components/ContactsTable';
import { ContactsState } from '../../store/contacts/contacts-types';

export const Contacts = () => {
	const dispatch = useDispatch();
	const { data, total, limit, offset, orderings, filters } = useSelector<RootState, ContactsState>(
		(state) => state.contacts,
	);

	useEffect(() => {
		dispatch(
			fetchContacts({
				limit: limit,
				offset: offset,
				orderings: orderings,
				filtersAndConditions: filters,
			}),
		);

		// Don't need to react on "config" and "filters" and deep comparision is not necessary
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	return (
		<Grid container spacing={2} justify="center">
			<Grid item xs={12} lg={10}>
				<Paper>
					<ContactsTable
						data={data}
						total={total}
						offset={offset}
						limit={limit}
						orderings={orderings}
						filters={filters}
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};

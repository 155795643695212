import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Children } from '../../types';

const useStyles = makeStyles({
	container: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
});

type Props = {
	children: Children;
};

export const TableActionsContainer = ({ children }: Props) => {
	const classes = useStyles();

	return <div className={classes.container}>{children}</div>;
};

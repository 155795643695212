import { UUID } from '../../types';
import { PaginatedResponse } from '../../store/types';
import { Filter, FiltersPayload, FiltersRequestBody } from '../../components/Table/types';

export enum EndpointMethods {
	GET = 'GET',
	POST = 'POST',
	PUT = 'PUT',
	PATCH = 'PATCH',
	DELETE = 'DELETE',
}

export type IngestionEndpoint = {
	active: boolean;
	dateCreated: string;
	dateUpdated: string;
	deleted: boolean;
	id: UUID;
	path: string;
	payloadParser: string;
	tenantId: string;
	method: string;
};

export type DotNetIngestionEndpoint = {
	active: boolean;
	dateCreated: string;
	dateUpdated: string;
	deleted: boolean;
	id: UUID;
	path: string;
	payloadParser?: string;
	tenantId?: string;
	method: string;
	scriptId?: UUID;
};

export type IngestionEndpointResponseData = PaginatedResponse<IngestionEndpoint>;

export type DotNetIngestionEndpointResponseData = PaginatedResponse<DotNetIngestionEndpoint>;

export type CreateIngestionEndpointRequestBody = Pick<
	IngestionEndpoint,
	'method' | 'path' | 'active' | 'payloadParser'
>;

export type EditIngestionEndpointPayload = Omit<IngestionEndpoint, 'dateCreated' | 'dateUpdated' | 'tenantId'>;
export type EditIngestionEndpointRequestBody = Omit<EditIngestionEndpointPayload, 'id'>;
export type IngestionEndpointsPayload = FiltersPayload;
export type IngestionEndpointsRequestBody = FiltersRequestBody;

export type EditDotNetIngestionEndpointPayload = Omit<DotNetIngestionEndpoint, 'dateCreated' | 'dateUpdated'>;
export type EditDotNetIngestionEndpointRequestBody = Omit<EditDotNetIngestionEndpointPayload, 'id'>;
export type CreateDotNetIngestionEndpointRequestBody = Pick<
	DotNetIngestionEndpoint,
	'method' | 'path' | 'active' | 'tenantId' | 'scriptId'
>;

export type IngestionEndpointsFilters = {
	path: Filter[];
	active: Filter[];
	method: Filter[];
};

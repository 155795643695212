import {
	fetchWorkflowsRequest,
	fetchWorkflowsSuccess,
	fetchWorkflowsError,
	deleteWorkflowSuccess,
	deleteWorkflowError,
	editWorkflowRequest,
	editWorkflowSuccess,
	editWorkflowError,
	createtWorkflowRequest,
	createWorkflowSuccess,
	createWorkflowError,
} from './workflows-actions';
import { AsyncAction } from '../types';
import { fetchRequest, createToastError, isStatusSuccess } from '../../services/helpers';
import { addToast } from '../app/app-actions';
import {
	WorkflowsResponseData,
	Workflow,
	CreateWorkflowBody,
	EditWorkflowBody,
} from '../../pages/Workflows/workflows-types';
import * as workflowsService from '../../services/workflows/workflows-service';
import { omit } from 'lodash';
import { FiltersRequestBody2 } from '../../components/Table/types';

export const fetchWorkflows: AsyncAction<FiltersRequestBody2> = (workflowsPayload) => async (dispatch) => {
	dispatch(fetchWorkflowsRequest());

	const request = workflowsService.getWorkflows(workflowsPayload);
	const { data, error } = await fetchRequest<WorkflowsResponseData>(request);

	if (data) {
		dispatch(fetchWorkflowsSuccess(data, workflowsPayload));
	} else {
		dispatch(fetchWorkflowsError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(fetchWorkflows(workflowsPayload));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

// CREATE

export const createWorkflow: AsyncAction<CreateWorkflowBody> = (workflow) => async (dispatch, getState) => {
	dispatch(createtWorkflowRequest());

	const { workflows } = getState();
	const request = workflowsService.createWorkflow(workflow);
	const { data, status, error } = await fetchRequest<Workflow>(request);

	if (isStatusSuccess(status) && data) {
		dispatch(createWorkflowSuccess());
		dispatch(
			fetchWorkflows({
				limit: workflows.limit,
				offset: workflows.offset,
				orderings: workflows.orderings,
				filtersAndConditions: workflows.filtersAndConditions,
			}),
		);
	} else {
		dispatch(createWorkflowError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(createWorkflow(workflow));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

// EDIT

export const editWorkflow: AsyncAction<EditWorkflowBody> = (workflow) => async (dispatch, getState) => {
	dispatch(editWorkflowRequest());

	const body = omit(workflow, ['id']);
	const { workflows } = getState();
	const request = workflowsService.editWorkflow(body, workflow.id);
	const { data, error } = await fetchRequest<Workflow>(request);

	if (data) {
		dispatch(editWorkflowSuccess());
		dispatch(
			fetchWorkflows({
				limit: workflows.limit,
				offset: workflows.offset,
				orderings: workflows.orderings,
				filtersAndConditions: workflows.filtersAndConditions,
			}),
		);
	} else {
		dispatch(editWorkflowError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(editWorkflow(workflow));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

// DELETE

export const deleteWorkflow: AsyncAction<string> = (workflowId: string) => async (dispatch) => {
	dispatch(fetchWorkflowsRequest());

	const request = workflowsService.deleteWorkflow(workflowId);
	const { status, error } = await fetchRequest<void>(request);

	if (isStatusSuccess(status) && workflowId) {
		dispatch(deleteWorkflowSuccess(workflowId));
	}

	if (error) {
		const tryAgainAction = () => dispatch(deleteWorkflow(workflowId));

		dispatch(deleteWorkflowError());
		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

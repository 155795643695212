import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CardHeader, CircularProgress, Divider, IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { Add, DeleteForeverRounded } from '@material-ui/icons';
import { TableRowAction, TableRowRenderProps } from '../../../components/Table/types';
import { PermissionsDotNet } from '../../../helpers/generalConstants';
import { addModalDialog, removeModalDialog } from '../../../store/app/app-actions';
import { RootState } from '../../../store/types';
import {
	CreateDevicePlacementPayload,
	DevicePlacement,
	Definition,
	UpdateDevicePlacementPayload,
} from '../device-placements-types';
import { Table } from '../../../components/Table/Table';
import {
	attachDeviceToPlacement,
	createDevicePlacement,
	deleteDevicePlacement,
	fetchDevicePlacements,
	updateDevicePlacement,
} from '../../../store/device-placements/device-placements-async-actions';
import { FilterOperators, SortOrder, TableRowActionIcon } from '../../../components/Table/constants';
import { UUID } from '../../../types';
import { DevicePlacementForm } from './DevicePlacementForm';
import { AttachedDevicesList } from './AttachedDevicesList';
import { useHistory } from 'react-router-dom';
import { PlacementState } from '../../../store/device-placements/device-placements-types';
import { fetchRequest } from '../../../services/helpers';
import { DefinitionFieldFlag } from '../../constants';
import { DeviceResponseData2, DevicesResponseData2 } from '../../Devices/devices-types';
import { AttachDeviceForm, AttachDeviceFormValues } from './AttachDeviceForm';
import { getFilteredDevices } from '../../../services/devices/devices-service';
import { hasPermission } from '../../../helpers/auth';
import { UserState } from '../../../store/user/user-types';

type Props = {
	definition: Definition;
};

export const DevicePlacementsTable = ({ definition }: Props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { permissions, project } = useSelector<RootState, UserState>((state) => state.user);
	const canEditPlacements = hasPermission(PermissionsDotNet.PlacementWrite, permissions);

	const history = useHistory();

	const placements = useSelector<RootState, PlacementState | undefined>((state) => state.devicePlacements.placements);

	const [devices, setDevices] = useState<DeviceResponseData2[] | undefined>();

	// TODO implement
	const [loading] = useState(false);

	const allFields = definition.placementFields;

	const columns = allFields.map<{ id: string; label: string }>((field) => ({
		id: t(field.name),
		label: `${t(field.name)} - (${field.flags})`,
	}));

	useEffect(() => {
		if (definition !== undefined) {
			const filters = [
				{
					column: 'definitionId',
					operator: FilterOperators.equals,
					value: definition.id,
				},
			];

			if (project) {
				filters.push({
					column: 'projects',
					operator: FilterOperators.injoin,
					value: project.id,
				});
			}

			dispatch(
				fetchDevicePlacements({
					limit: 10,
					offset: 0,
					orderings: [],
					filtersAndConditions: filters,
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, definition, project]);

	useEffect(() => {
		if (!devices) {
			const filterBody = {
				offset: 0,
				limit: 1000,
				orderings: [{ column: 'sn', sortOrder: SortOrder.Ascending }],
				filtersAndConditions: [
					{
						column: 'includeData',
						operator: FilterOperators.equals,
						value: 'true',
					},
				],
			};

			const request = getFilteredDevices(filterBody);

			(async () => {
				const { data, error } = await fetchRequest<DevicesResponseData2>(request);

				if (error) {
					console.log(error);
					// TODO show error
				} else {
					// console.log(data);
					setDevices(data?.data);
				}
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [devices]);

	const renderRow = ({ row: placement, renderActions }: TableRowRenderProps<DevicePlacement>) => {
		return (
			<TableRow key={placement.id} id={`row-${placement.id}`}>
				{allFields.map((field) => {
					const entry = Object.entries(placement.fields).find(([key]) => key === field.name);

					if (entry) {
						return <TableCell key={field.name}>{entry[1] as string}</TableCell>;
					}

					return <TableCell key={field.name}>{loading ? <CircularProgress size={12} /> : 'N/A'}</TableCell>;
				})}
				<TableCell padding="none" sortDirection={false}>
					{renderActions(rowActions, placement)}
				</TableCell>
			</TableRow>
		);
	};

	const handleCloseModal = (dialogId: string) => {
		dispatch(removeModalDialog(dialogId));
	};

	const handleDeletePlacement = (placementId: UUID, modalDialogId: string) => {
		handleCloseModal(modalDialogId);
		dispatch(deleteDevicePlacement({ placementId: placementId, definitionId: definition.id }));
	};

	const handleCreatePlacement = (data: CreateDevicePlacementPayload, modalDialogId: string) => {
		handleCloseModal(modalDialogId);
		dispatch(createDevicePlacement(data));
	};

	const handleEditPlacement = (data: UpdateDevicePlacementPayload, modalDialogId: string) => {
		handleCloseModal(modalDialogId);
		dispatch(updateDevicePlacement(data));
	};

	const handleAttachDevice = (placementId: UUID, values: AttachDeviceFormValues) => {
		const payload = {
			placementId: placementId,
			deviceId: values.deviceId,
			attached: new Date().toISOString(),
		};

		handleCloseModal('attachDeviceModal');
		dispatch(attachDeviceToPlacement(payload));
	};

	const renderDeleteDescription = (placement: DevicePlacement) => {
		const fieldName = definition.placementFields.find(
			(field) => field.flagsValue && field.flagsValue & DefinitionFieldFlag.Title1,
		)?.name;

		const entry = Object.entries(placement.fields).find(([key]) => key === fieldName);

		return t('deletePlacementModalDescription', {
			name: (entry && entry[1]) ?? placement.id,
		});
	};

	const rowActions: TableRowAction<DevicePlacement>[] = [];

	if (canEditPlacements) {
		rowActions.unshift(
			{
				id: 'btn-detail:placement',
				icon: TableRowActionIcon.tag,
				tooltip: t('attachedDevices'),
				onClick: (placement: DevicePlacement) => {
					dispatch(
						addModalDialog({
							id: 'attachedDevicesModal',
							title: t('attachedDevicesModalTitle'),
							content: <AttachedDevicesList data={placement.placementRelations} history={history} />,
						}),
					);
				},
			},
			{
				id: 'btn-attache:placement',
				icon: TableRowActionIcon.attach,
				tooltip: t('attachDevice'),
				onClick: (placement: DevicePlacement) => {
					devices &&
						dispatch(
							addModalDialog({
								id: 'attachDeviceModal',
								title: t('attachDevice'),
								content: (
									<AttachDeviceForm
										options={devices}
										onSubmit={(values: AttachDeviceFormValues) =>
											handleAttachDevice(placement.id, values)
										}
									/>
								),
							}),
						);
				},
			},
			{
				id: 'btn-edit:placement',
				icon: TableRowActionIcon.edit,
				tooltip: t('edit'),
				onClick: (placement: DevicePlacement) => {
					dispatch(
						addModalDialog({
							id: 'editPlacementModal',
							title: t('editPlacementModalTitle'),
							content: (
								<DevicePlacementForm
									devices={devices}
									definition={definition}
									onSubmit={(values) => handleEditPlacement({ ...values }, 'editPlacementModal')}
									onCancel={() => handleCloseModal('editPlacementModal')}
									defaultValues={{
										definitionId: definition.id,
										id: placement.id,
										fields: placement.fields,
										placementRelations: [],
									}}
								/>
							),
						}),
					);
				},
			},
			{
				id: 'btn-delete:placement',
				icon: TableRowActionIcon.delete,
				tooltip: t('delete'),
				onClick: (placement: DevicePlacement) =>
					dispatch(
						addModalDialog({
							id: 'deletePlacementModal',
							title: t('deletePlacementModalTitle'),
							contentText: renderDeleteDescription(placement),
							buttons: [
								{
									id: 'deletePlacement',
									value: t('delete'),
									onClick: () => handleDeletePlacement(placement.id, 'deletePlacementModal'),
									startIcon: <DeleteForeverRounded />,
								},
								{
									id: 'cancelDeletePlacement',
									value: t('cancel'),
									onClick: () => handleCloseModal('deletePlacementModal'),
									variant: 'text',
								},
							],
						}),
					),
			},
		);
	}

	return (
		<>
			<CardHeader
				action={
					<>
						{canEditPlacements && (
							<Tooltip title={String(t('createPlacement'))} aria-label={t('createPlacement')}>
								<IconButton
									onClick={() =>
										dispatch(
											addModalDialog({
												id: 'createPlacementModal',
												title: t('createPlacementModalTitle'),
												content: (
													<DevicePlacementForm
														devices={devices}
														definition={definition}
														onSubmit={(values) =>
															handleCreatePlacement(values, 'createPlacementModal')
														}
														onCancel={() => handleCloseModal('createPlacementModal')}
													/>
												),
											}),
										)
									}
									data-cy="btn-create:placement"
								>
									<Add />
								</IconButton>
							</Tooltip>
						)}
					</>
				}
			/>
			<Divider light />

			{placements && (
				<Table
					actions={canEditPlacements ? rowActions : undefined}
					columns={columns}
					renderTableRow={renderRow}
					fetchAction2={fetchDevicePlacements}
					data={placements.data}
					dataLimit={placements.limit}
					dataOffset={placements.offset}
					numberOfRows={placements.total}
					orderings={placements.orderings}
					filtersAndConditions={placements.filtersAndConditions}
					selectable={false}
					sortable={false}
				/>
			)}
		</>
	);
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Paper } from '@material-ui/core';
import { RootState } from '../../store/types';
import { UserState } from '../../store/user/user-types';
import { NotificationsTable } from './components/NotificationsTable';
import { useParams } from 'react-router-dom';
import { ReportsState } from '../../store/reports/reports-types';
import { fetchNotifications } from '../../store/reports/reports-async-actions';
import { BackButton } from '../../components/Button/BackButton';

type RouteParams = {
	workflowId: string;
};

export const Notifications = () => {
	const dispatch = useDispatch();
	const { workflowId } = useParams<RouteParams>();
	const { project } = useSelector<RootState, UserState>((state) => state.user);
	const { notifications } = useSelector<RootState, ReportsState>((state) => state.reports);

	useEffect(() => {
		if (project?.id) {
			dispatch(
				fetchNotifications({
					limit: notifications.limit,
					offset: notifications.offset,
					orderings: notifications.orderings,
					filtersAndConditions: notifications.filters,
				}),
			);
		}

		// Don't need to react on "config" and "filters" and deep comparision is not necessary
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, project]);

	return (
		<Grid container spacing={2} justify="center">
			<Grid item xs={12}>
				<BackButton />
			</Grid>

			<Grid item xs={12} lg={10}>
				<Paper>
					<NotificationsTable
						data={notifications.data}
						total={notifications.total}
						limit={notifications.limit}
						offset={notifications.offset}
						orderings={notifications.orderings}
						filters={notifications.filters}
						workflowId={workflowId}
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};

import { apiClient } from '../client';
import {
	GroupsResponseData,
	GroupResponseData,
	CreateGroupRequestBody,
	UpdateGroupRequestBody,
	UpdateGroupResponseData,
} from '../../pages/Groups/groups-types';
import { UUID } from '../../types';
import { FiltersRequestBody2 } from '../../components/Table/types';

const baseUrl = '/device-manager.net';
const groupsUrl = `${baseUrl}/device-groups`;

export const getFilteredGroups = (body: FiltersRequestBody2) =>
	apiClient.post<GroupsResponseData>(`${groupsUrl}/filter`, body);

export const createGroup = (body: CreateGroupRequestBody) => apiClient.post<GroupResponseData>(`${groupsUrl}`, body);

export const updateGroup = (body: UpdateGroupRequestBody, groupId: UUID) =>
	apiClient.put<UpdateGroupResponseData>(`${groupsUrl}/${groupId}`, body);

export const deleteGroup = (groupId: UUID) => apiClient.delete<void>(`${groupsUrl}/${groupId}`);

import { createReducer } from 'typesafe-actions';
import { WorkflowsState, WorkflowActions } from './workflows-types';
import {
	FETCH_WORKFLOWS_ERROR,
	FETCH_WORKFLOWS_REQUEST,
	FETCH_WORKFLOWS_SUCCESS,
	DELETE_WORKFLOW_SUCCESS,
	DELETE_WORKFLOW_REQUEST,
	DELETE_WORKFLOW_ERROR,
	SET_CURRENT_WORKFLOW,
	EDIT_WORKFLOW_REQUEST,
	EDIT_WORKFLOW_SUCCESS,
	EDIT_WORKFLOW_ERROR,
	CREATE_WORKFLOW_SUCCESS,
	CREATE_WORKFLOW_REQUEST,
	CREATE_WORKFLOW_ERROR,
} from './workflows-constants';

export const workflowsInitialState: WorkflowsState = {
	data: [],
	total: 0,
	offset: 0,
	limit: 10,
	orderings: [],
	filtersAndConditions: [],
	currentWorkflow: null,
};

export const workflowsReducer = createReducer<WorkflowsState, WorkflowActions>(workflowsInitialState, {
	[FETCH_WORKFLOWS_REQUEST]: (state) => state,
	[FETCH_WORKFLOWS_SUCCESS]: (state, action) => {
		return {
			...state,
			data: action.payload.data.data,
			total: action.payload.data.total,
			limit: action.payload.options ? action.payload.options.limit : state.limit,
			offset: action.payload.options ? action.payload.options.offset : state.offset,
			orderings: action.payload.options?.orderings || state.orderings,
			filters: action.payload.options?.filtersAndConditions || state.filtersAndConditions,
		};
	},
	[FETCH_WORKFLOWS_ERROR]: (state) => state,
	[DELETE_WORKFLOW_REQUEST]: (state) => state,
	[DELETE_WORKFLOW_SUCCESS]: (state, action) => {
		const newData = state.data.filter((workflow) => workflow.id !== action.payload.workflowId);

		return {
			...state,
			data: newData,
			total: newData.length,
		};
	},
	[DELETE_WORKFLOW_ERROR]: (state) => state,
	[SET_CURRENT_WORKFLOW]: (state, action) => {
		return {
			...state,
			currentWorkflow: state.data.find((workflow) => workflow.id === action.payload.workflowId),
		};
	},
	[EDIT_WORKFLOW_REQUEST]: (state) => state,
	[EDIT_WORKFLOW_SUCCESS]: (state) => state,
	[EDIT_WORKFLOW_ERROR]: (state) => state,
	[CREATE_WORKFLOW_REQUEST]: (state) => state,
	[CREATE_WORKFLOW_SUCCESS]: (state) => state,
	[CREATE_WORKFLOW_ERROR]: (state) => state,
});

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Button } from '../../../components/Button/Button';
import SaveIcon from '@material-ui/icons/Save';
import { ModalDialogFormActions } from '../../../components/ModalDialog/ModalDialogFormActions';
import {
	activityTypes,
	CreatePipelineRuleRequestBody,
	PipelineRule,
	PipelinesResponseData,
	severityTypes,
} from '../pipelines-types';
import { UUID } from '../../../types';
import { InputSelectUncontrolled } from '../../../components/Form/InputSelectUncontrolled';
import { useDispatch } from 'react-redux';
import { fetchRequest } from '../../../services/helpers';
import { fetchPipelines } from '../../../services/pipelines/pipelines-service';
import {
	StringTimeSpan,
	stringTimeSpanToMillis,
	stringTimeSpanToTimeSpanString,
	timeStringToStringTimeSpanString,
} from '../../helpers';

const daysOptions = [
	{ id: '0', label: '0' },
	{ id: '1', label: '1' },
	{ id: '2', label: '2' },
	{ id: '3', label: '3' },
	{ id: '4', label: '4' },
	{ id: '5', label: '5' },
	{ id: '6', label: '6' },
	{ id: '7', label: '7' },
	{ id: '8', label: '8' },
	{ id: '9', label: '9' },
	{ id: '10', label: '10' },
	{ id: '11', label: '11' },
	{ id: '12', label: '12' },
	{ id: '13', label: '13' },
	{ id: '14', label: '14' },
	{ id: '15', label: '15' },
	{ id: '16', label: '16' },
	{ id: '17', label: '17' },
	{ id: '18', label: '18' },
	{ id: '19', label: '19' },
	{ id: '20', label: '20' },
	{ id: '21', label: '21' },
	{ id: '22', label: '22' },
	{ id: '23', label: '23' },
	{ id: '24', label: '24' },
	{ id: '25', label: '25' },
	{ id: '26', label: '26' },
	{ id: '27', label: '27' },
	{ id: '28', label: '28' },
	{ id: '29', label: '29' },
	{ id: '30', label: '30' },
	{ id: '30', label: '30' },
	{ id: '31', label: '31' },
	{ id: '32', label: '32' },
	{ id: '33', label: '33' },
	{ id: '34', label: '34' },
	{ id: '35', label: '35' },
	{ id: '36', label: '36' },
	{ id: '37', label: '37' },
	{ id: '38', label: '38' },
	{ id: '39', label: '39' },
	{ id: '40', label: '40' },
];

const hoursOptions = [
	{ id: '0', label: '0' },
	{ id: '1', label: '1' },
	{ id: '2', label: '2' },
	{ id: '3', label: '3' },
	{ id: '4', label: '4' },
	{ id: '5', label: '5' },
	{ id: '6', label: '6' },
	{ id: '7', label: '7' },
	{ id: '8', label: '8' },
	{ id: '9', label: '9' },
	{ id: '10', label: '10' },
	{ id: '11', label: '11' },
	{ id: '12', label: '12' },
	{ id: '13', label: '13' },
	{ id: '14', label: '14' },
	{ id: '15', label: '15' },
	{ id: '16', label: '16' },
	{ id: '17', label: '17' },
	{ id: '18', label: '18' },
	{ id: '19', label: '19' },
	{ id: '20', label: '20' },
	{ id: '21', label: '21' },
	{ id: '22', label: '22' },
	{ id: '23', label: '23' },
];

const minutesOptions = [
	{ id: '0', label: '0' },
	{ id: '5', label: '5' },
	{ id: '10', label: '10' },
	{ id: '15', label: '15' },
	{ id: '20', label: '20' },
	{ id: '25', label: '25' },
	{ id: '30', label: '30' },
	{ id: '35', label: '35' },
	{ id: '40', label: '40' },
	{ id: '45', label: '45' },
	{ id: '50', label: '50' },
	{ id: '55', label: '55' },
];

export type PipelineRuleFormValues = CreatePipelineRuleRequestBody & {
	id?: UUID;
};

type Props = {
	pipelineId: UUID;
	onSubmit: (values: PipelineRuleFormValues) => void;
	onCancel: () => void;
	defaultValues?: PipelineRule;
};

export const PipelineRuleForm = ({ pipelineId, onSubmit, onCancel, defaultValues }: Props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	// const { tenant } = useSelector<RootState, UserState>((state) => state.user);

	const [severity, setSeverity] = useState(defaultValues?.severity ?? '0');
	// const [nameError, setNameError] = useState<boolean>(false);

	const [activity, setActivity] = useState(defaultValues?.activity ?? '1');

	const [confirmTo, setConfirmTo] = useState<StringTimeSpan>(
		timeStringToStringTimeSpanString(defaultValues?.confirmTo) ?? { days: '0', hours: '0', minutes: '0' },
	);

	const [confirmToError, setConfirmToError] = useState<boolean>(false);

	const [allPipelines, setAllPipelines] = useState<{ id: string; label: string }[]>();

	const [escalationPipelineId, setEscalationPipelineId] = useState(defaultValues?.escalationPipelineId);

	useEffect(() => {
		const request = fetchPipelines({
			limit: -1,
			offset: 0,
			filtersAndConditions: [],
			orderings: [],
		});

		(async () => {
			const { data, error } = await fetchRequest<PipelinesResponseData>(request);

			if (error) {
				// TODO show error
			} else {
				if (data) {
					setAllPipelines(data.data.map((pipeline) => ({ id: pipeline.id, label: pipeline.name })));
				}
			}
		})();
	}, [dispatch]);

	function handleSubmitForm() {
		let valid = true;
		// if (name === '') {
		// 	valid = false;
		// 	setNameError(true);
		// }

		if (escalationPipelineId) {
			const confirmeToMillis = stringTimeSpanToMillis(confirmTo);

			if (confirmeToMillis <= 0) {
				valid = false;
				setConfirmToError(true);
			}
		}

		if (valid) {
			onSubmit({
				id: defaultValues?.id,
				pipelineId: pipelineId,
				severity: severity,
				activity: activity,
				escalationPipelineId: escalationPipelineId,
				confirmTo: escalationPipelineId ? stringTimeSpanToTimeSpanString(confirmTo) : undefined,
			});
		}
	}

	function handleEscalationPipelineId(escalationPipelineId: UUID | undefined) {
		setEscalationPipelineId(escalationPipelineId);
	}

	function handleConfirmTo(days: string | undefined, hours: string | undefined, minutes: string | undefined) {
		// console.log(
		// 	timeSpanToMillis({
		// 		days: days ?? confirmTo?.days,
		// 		hours: hours ?? confirmTo?.days,
		// 		minutes: minutes ?? confirmTo?.days,
		// 	}),
		// );

		setConfirmTo({
			days: days ?? confirmTo?.days,
			hours: hours ?? confirmTo?.hours,
			minutes: minutes ?? confirmTo?.minutes,
		});

		setConfirmToError(false);
	}

	return (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<InputSelectUncontrolled
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					defaultValueId={severity}
					unclearable
					onChanged={(value) => {
						setSeverity(value?.id ?? '0');
					}}
					error={false}
					options={severityTypes}
					label={t('ruleSeverity')}
				/>
			</Grid>
			<Grid item>
				<InputSelectUncontrolled
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					defaultValueId={activity}
					unclearable
					onChanged={(value) => {
						setActivity(value?.id ?? '1');
					}}
					error={false}
					options={activityTypes}
					label={t('ruleActiviti')}
				/>
			</Grid>
			<Grid item>
				{allPipelines && (
					<InputSelectUncontrolled
						defaultValueId={escalationPipelineId}
						onChanged={(value) => {
							handleEscalationPipelineId(value?.id);
						}}
						error={false}
						options={allPipelines}
						label={t('escalationPipeline')}
					/>
				)}
			</Grid>
			<Grid item container direction="row" spacing={2}>
				<Grid item>
					<InputSelectUncontrolled
						dis={escalationPipelineId == null}
						defaultValueId={confirmTo.days}
						unclearable
						onChanged={(value) => {
							handleConfirmTo(value?.id, undefined, undefined);
						}}
						error={false}
						options={daysOptions}
						label={t('days')}
					/>
				</Grid>
				<Grid item>
					<InputSelectUncontrolled
						dis={escalationPipelineId == null}
						defaultValueId={confirmTo.hours}
						unclearable
						onChanged={(value) => {
							handleConfirmTo(undefined, value?.id, undefined);
						}}
						error={false}
						options={hoursOptions}
						label={t('hours')}
					/>
				</Grid>
				<Grid item>
					<InputSelectUncontrolled
						dis={escalationPipelineId == null}
						defaultValueId={confirmTo.minutes}
						unclearable
						onChanged={(value) => {
							handleConfirmTo(undefined, undefined, value?.id);
						}}
						error={confirmToError}
						helperText={confirmToError && t('validationErrors.required')}
						options={minutesOptions}
						label={t('minutes')}
					/>
				</Grid>
			</Grid>
			<Grid item>
				<ModalDialogFormActions>
					<Button startIcon={<SaveIcon />} onClick={handleSubmitForm} data-cy="btn-submit:pipelineForm">
						{t('save')}
					</Button>
					<Button onClick={onCancel} variant="text" data-cy="btn-cancel:pipelineForm">
						{t('cancel')}
					</Button>
				</ModalDialogFormActions>
			</Grid>
		</Grid>
	);
};

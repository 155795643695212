import { ContactGroupsState, ContactGroupsActions } from './contact-groups-types';
import { createReducer } from 'typesafe-actions';
import {
	GENERAL_CONTACT_GROUPS_REQUEST,
	GENERAL_CONTACT_GROUPS_ERROR,
	FETCH_CONTACT_GROUPS_SUCCESS,
	CREATE_CONTACT_GROUP_SUCCESS,
	UPDATE_CONTACT_GROUP_SUCCESS,
	DELETE_CONTACT_GROUP_SUCCESS,
	GET_CONTACT_GROUP_DETAIL,
} from './contact-groups-constants';

import { set } from 'lodash/fp';

const groupsInitialState: ContactGroupsState = {
	data: [],
	total: 0,
	offset: 0,
	limit: 10,
	orderings: [],
	filtersAndConditions: [],
	groupDetail: null,
};

export const contactGroupsReducer = createReducer<ContactGroupsState, ContactGroupsActions>(groupsInitialState, {
	[GENERAL_CONTACT_GROUPS_REQUEST]: (state) => state,
	[GENERAL_CONTACT_GROUPS_ERROR]: (state) => state,
	[FETCH_CONTACT_GROUPS_SUCCESS]: (state, action) => {
		return {
			...state,
			data: action.payload.data.data,
			total: action.payload.data.total,
			limit: action.payload.options ? action.payload.options.limit : state.limit,
			offset: action.payload.options ? action.payload.options.offset : state.offset,
			orderings: action.payload.options?.orderings || state.orderings,
			filtersAndConditions: action.payload.options?.filtersAndConditions || state.filtersAndConditions,
		};
	},
	[CREATE_CONTACT_GROUP_SUCCESS]: (state) => state,
	[UPDATE_CONTACT_GROUP_SUCCESS]: (state, action) => {
		const { data } = action.payload;
		const groupIndex = state.data.findIndex((group) => group.id === data.id);

		return set(`data[${groupIndex}]`, data, state);
	},
	[DELETE_CONTACT_GROUP_SUCCESS]: (state, action) => {
		const newData = state.data.filter((group) => group.id !== action.payload.contactGroupId);

		return {
			...state,
			data: newData,
			total: newData.length,
		};
	},
	[GET_CONTACT_GROUP_DETAIL]: (state, action) => set('groupDetail', action.payload.data, state),
});

import * as reviewService from '../../services/review/review-service';
import { addToast } from '../app/app-actions';
import { fetchRequest, isStatusSuccess, createToastError } from '../../services/helpers';
import { AsyncAction } from '../types';

import {
	reviewCommonEmptyRequest,
	reviewCommonEmptyError,
	fetchReportOrdersSuccess,
	createReportOrderSuccess,
	editReportOrderSuccess,
	deleteReportOrderSuccess,
	getReportOrderDefinitionSuccess,
	getReportOrderDefinitionsNamesSuccess,
	fetchStandingReportOrdersSuccess,
	createStandingReportOrderSuccess,
	editStandingReportOrderSuccess,
	deleteStandingReportOrderSuccess,
} from './review-actions';

import { UUID } from '../../types';
import { omit } from 'lodash';
import {
	CreateReportOrderRequestBody,
	CreateStandingReportOrderRequestBody,
	ReportOrder,
	ReviewDefinition,
	ReportOrdersResponseData,
	StandingReportOrder,
	StandingReportOrdersResponseData,
	ReviewDefinitionNamesResponseData,
} from '../../pages/Review/review-types';
import { FiltersRequestBody2 } from '../../components/Table/types';

export const fetchReportOrders: AsyncAction<FiltersRequestBody2> = (payload) => async (dispatch) => {
	dispatch(reviewCommonEmptyRequest());

	const filterBody = payload;

	const request = reviewService.getFilteredReportOrders(filterBody);

	const { data, error } = await fetchRequest<ReportOrdersResponseData>(request);

	if (data) {
		dispatch(fetchReportOrdersSuccess(data, payload));
	} else {
		dispatch(reviewCommonEmptyError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data, () => dispatch(fetchReportOrders(payload)))));
	}
};

export const fetchReportOrdersNoProgressBar: AsyncAction<void> = () => async (dispatch, getState) => {
	// dispatch(reviewCommonEmptyRequest());

	const { review } = getState();

	const filterBody = {
		limit: review.limit,
		offset: review.offset,
		filtersAndConditions: review.filters,
		orderings: review.orderings,
	};

	const request = reviewService.getFilteredReportOrders(filterBody);

	const { data } = await fetchRequest<ReportOrdersResponseData>(request);

	if (data) {
		dispatch(fetchReportOrdersSuccess(data, filterBody));
	} else {
		// dispatch(reviewCommonEmptyError());
	}

	// if (error) {
	// 	dispatch(addToast(createToastError(error.data, () => dispatch(fetchReportOrders(payload)))));
	// }
};

export const createReportOrder: AsyncAction<CreateReportOrderRequestBody> = (reportOrder) => async (
	dispatch,
	getState,
) => {
	dispatch(reviewCommonEmptyRequest());

	const { review } = getState();
	const request = reviewService.createReportOrder(reportOrder);
	const { data, status, error } = await fetchRequest<ReportOrder>(request);

	if (isStatusSuccess(status) && data) {
		dispatch(createReportOrderSuccess(data));
		dispatch(
			fetchReportOrders({
				limit: review.limit,
				offset: review.offset,
				filtersAndConditions: review.filters,
				orderings: review.orderings,
			}),
		);
	}

	if (error) {
		const tryAgainAction = () => dispatch(createReportOrder(reportOrder));

		dispatch(reviewCommonEmptyError());
		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const editReportOrder: AsyncAction<ReportOrder> = (reportOrder) => async (dispatch) => {
	dispatch(reviewCommonEmptyRequest());

	const body = omit(reportOrder, ['id']);
	const request = reviewService.editReportOrder(body, reportOrder.id);
	const { data, error } = await fetchRequest<ReportOrder>(request);

	if (data) {
		dispatch(editReportOrderSuccess(data));
	} else {
		dispatch(reviewCommonEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(editReportOrder(reportOrder));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const deleteReportOrder: AsyncAction<UUID> = (reportOrderId) => async (dispatch) => {
	dispatch(reviewCommonEmptyRequest());

	const request = reviewService.deleteReportOrder(reportOrderId);
	const { status, error } = await fetchRequest<void>(request);

	if (isStatusSuccess(status) && reportOrderId) {
		dispatch(deleteReportOrderSuccess(reportOrderId));
	} else {
		dispatch(reviewCommonEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(deleteReportOrder(reportOrderId));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const getReportOrderDefinition: AsyncAction<UUID> = (reportOrderDefinitionId) => async (dispatch) => {
	dispatch(reviewCommonEmptyRequest());

	const request = reviewService.getReviewDefinition(reportOrderDefinitionId);

	const { data, error } = await fetchRequest<ReviewDefinition>(request);

	if (data) {
		dispatch(getReportOrderDefinitionSuccess(data));
	} else {
		dispatch(reviewCommonEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(getReportOrderDefinition(reportOrderDefinitionId));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const getReportOrderDefinitionsNames: AsyncAction<void> = () => async (dispatch) => {
	dispatch(reviewCommonEmptyRequest());

	const request = reviewService.getReviewDefinitionNames();

	const { data, status, error } = await fetchRequest<ReviewDefinitionNamesResponseData>(request);

	if (isStatusSuccess(status) && data) {
		dispatch(getReportOrderDefinitionsNamesSuccess(data.data));
	} else {
		dispatch(reviewCommonEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(getReportOrderDefinitionsNames());

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const fetchStandingReportOrders: AsyncAction<FiltersRequestBody2> = (payload) => async (dispatch) => {
	dispatch(reviewCommonEmptyRequest());

	const filterBody = payload;

	const request = reviewService.getFilteredStandingReportOrders(filterBody);

	const { data, error } = await fetchRequest<StandingReportOrdersResponseData>(request);

	if (data) {
		dispatch(fetchStandingReportOrdersSuccess(data, payload));
	} else {
		dispatch(reviewCommonEmptyError());
	}

	if (error) {
		dispatch(addToast(createToastError(error.data, () => dispatch(fetchStandingReportOrders(payload)))));
	}
};

export const createStandingReportOrder: AsyncAction<CreateStandingReportOrderRequestBody> = (reportOrder) => async (
	dispatch,
	getState,
) => {
	dispatch(reviewCommonEmptyRequest());

	const { review } = getState();
	const request = reviewService.createStandingReportOrder(reportOrder);
	const { data, status, error } = await fetchRequest<StandingReportOrder>(request);

	if (isStatusSuccess(status) && data) {
		dispatch(createStandingReportOrderSuccess(data));
		dispatch(
			fetchStandingReportOrders({
				limit: review.limit,
				offset: review.offset,
				filtersAndConditions: review.filters,
				orderings: review.orderings,
			}),
		);
	}

	if (error) {
		const tryAgainAction = () => dispatch(createStandingReportOrder(reportOrder));

		dispatch(reviewCommonEmptyError());
		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const editStandingReportOrder: AsyncAction<StandingReportOrder> = (reportOrder) => async (dispatch) => {
	dispatch(reviewCommonEmptyRequest());

	const body = omit(reportOrder, ['id']);
	const request = reviewService.editStandingReportOrder(body, reportOrder.id);
	const { data, error } = await fetchRequest<StandingReportOrder>(request);

	if (data) {
		dispatch(editStandingReportOrderSuccess(data));
	} else {
		dispatch(reviewCommonEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(editStandingReportOrder(reportOrder));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

export const deleteStandingReportOrder: AsyncAction<UUID> = (reportOrderId) => async (dispatch) => {
	dispatch(reviewCommonEmptyRequest());

	const request = reviewService.deleteStandingReportOrder(reportOrderId);
	const { status, error } = await fetchRequest<void>(request);

	if (isStatusSuccess(status) && reportOrderId) {
		dispatch(deleteStandingReportOrderSuccess(reportOrderId));
	} else {
		dispatch(reviewCommonEmptyError());
	}

	if (error) {
		const tryAgainAction = () => dispatch(deleteStandingReportOrder(reportOrderId));

		dispatch(addToast(createToastError(error.data, tryAgainAction)));
	}
};

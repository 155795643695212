export const FETCH_GROUPS_REQUEST = '[GROUPS_ACTIONS] FETCH_GROUPS_REQUEST';
export const FETCH_GROUPS_SUCCESS = '[GROUPS_ACTIONS] FETCH_GROUPS_SUCCESS';
export const FETCH_GROUPS_ERROR = '[GROUPS_ACTIONS] FETCH_GROUPS_ERROR';

export const CREATE_GROUP_REQUEST = '[GROUPS_ACTIONS] CREATE_GROUP_REQUEST';
export const CREATE_GROUP_SUCCESS = '[GROUPS_ACTIONS] CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_ERROR = '[GROUPS_ACTIONS] CREATE_GROUP_ERROR';

export const UPDATE_GROUP_REQUEST = '[GROUPS_ACTIONS] UPDATE_GROUP_REQUEST';
export const UPDATE_GROUP_SUCCESS = '[GROUPS_ACTIONS] UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_ERROR = '[GROUPS_ACTIONS] UPDATE_GROUP_ERROR';

export const DELETE_GROUP_REQUEST = '[GROUPS_ACTIONS] DELETE_GROUP_REQUEST';
export const DELETE_GROUP_SUCCESS = '[GROUPS_ACTIONS] DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_ERROR = '[GROUPS_ACTIONS] DELETE_GROUP_ERROR';

export const GET_GROUP_DETAIL = '[GROUPS_ACTIONS] GET_GROUP_DETAIL';

import React from 'react';
import { TableCell, TableRow, Tooltip, Divider, CardHeader, IconButton } from '@material-ui/core';
import { Add, CheckRounded, ClearRounded, DeleteForever } from '@material-ui/icons';
import { format, isValid } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FilterOperators, FilterType, TableRowActionIcon } from '../../../components/Table/constants';
import { Table } from '../../../components/Table/Table';
import { TableColumnFilter, TableColumnSort, TableRowRenderProps } from '../../../components/Table/types';
import { RouteEnum } from '../../../router/Routes';
import { addModalDialog, removeModalDialog, setRedirectUrl } from '../../../store/app/app-actions';
import { getGroupDetail } from '../../../store/groups/groups-actions';
import { createGroup, deleteGroup, fetchGroups, updateGroup } from '../../../store/groups/groups-async-actions';
import { COLOR_THEME } from '../../../theme';
import { CreateGroupPayload, Group, UpdateGroupPayload } from '../groups-types';
import { GroupForm, GroupFormValues } from './GroupForm';
import { INPUT_DATE_MASK, PermissionsDotNet } from '../../../helpers/generalConstants';
import { hasPermission } from '../../../helpers/auth';
import { RootState } from '../../../store/types';
import { UserState } from '../../../store/user/user-types';

type Props = {
	data: Group[];
	total: number;
	limit: number;
	offset: number;
	orderings: TableColumnSort[];
	filtersAndConditions: TableColumnFilter[];
};

export const GroupsTable = ({ data, total, offset, limit, orderings, filtersAndConditions }: Props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { permissions } = useSelector<RootState, UserState>((state) => state.user);
	const canEditGroups = hasPermission(PermissionsDotNet.DeviceGroupWrite, permissions);

	const nameOrder = orderings?.find((order) => order.column === 'name');
	const nameFilter = filtersAndConditions?.find((filter) => filter.column === 'name');

	const descriptionOrder = orderings?.find((order) => order.column === 'description');
	const descriptionFilter = filtersAndConditions?.find((filter) => filter.column === 'description');

	const columns = [
		{
			id: 'name',
			label: t('name'),
			filter: nameFilter && {
				column: 'name',
				operator: FilterOperators.contains,
				value: nameFilter.value,
			},
			filterType: FilterType.String,
			sort: nameOrder?.sortOrder && { column: 'name', sortOrder: nameOrder?.sortOrder },
			new: true,
		},
		{
			id: 'description',
			label: t('description'),
			filter: descriptionFilter && {
				column: 'description',
				operator: FilterOperators.contains,
				value: descriptionFilter.value,
			},
			filterType: FilterType.String,
			sort: descriptionOrder?.sortOrder && { column: 'description', sortOrder: descriptionOrder?.sortOrder },
			new: true,
		},
		{ id: 'dateCreated', label: t('dateCreated'), new: true },
		{ id: 'dateUpdated', label: t('dateUpdated'), new: true },
		{ id: 'active', label: t('active'), new: true },
	];

	const handleCloseModalDialog = (id: string) => {
		dispatch(removeModalDialog(id));
	};

	const handleCreateGroup = (data: CreateGroupPayload, modalDialogId: string) => {
		handleCloseModalDialog(modalDialogId);
		dispatch(createGroup(data));
	};

	const handleUpdateGroup = (data: UpdateGroupPayload, modalDialogId: string) => {
		handleCloseModalDialog(modalDialogId);
		dispatch(updateGroup(data));
	};

	const handleDeleteGroup = (groupId: string, modalDialogId: string) => {
		handleCloseModalDialog(modalDialogId);
		dispatch(deleteGroup(groupId));
	};

	const rowActions = [
		...(canEditGroups
			? [
					{
						id: 'btn-edit:group',
						icon: TableRowActionIcon.edit,
						tooltip: t('edit'),
						onClick: (group: Group) => {
							dispatch(
								addModalDialog({
									id: 'editGroupModal',
									title: t('editGroupModal'),
									contentText: '',
									content: (
										<GroupForm
											defaultValues={{
												name: group.name,
												description: group.description,
												active: group.active,
											}}
											onSubmit={(values) =>
												handleUpdateGroup({ ...values, id: group.id }, 'editGroupModal')
											}
											onCancel={() => handleCloseModalDialog('editGroupModal')}
										/>
									),
								}),
							);
						},
					},
					{
						id: 'btn-delete:group',
						icon: TableRowActionIcon.delete,
						tooltip: t('delete'),
						onClick: (group: Group) =>
							dispatch(
								addModalDialog({
									id: 'deleteGroupModal',
									title: t('deleteGroupModalTitle'),
									contentText: t('deleteGroupModalDescription', {
										name: group.name,
									}),
									buttons: [
										{
											id: 'deleteGroup',
											value: t('delete'),
											onClick: () => handleDeleteGroup(group.id, 'deleteGroupModal'),
											startIcon: <DeleteForever />,
										},
										{
											id: 'cancelDeleteGroup',
											value: t('cancel'),
											onClick: () => handleCloseModalDialog('deleteGroupModal'),
											variant: 'text',
										},
									],
								}),
							),
					},
			  ]
			: []),
		{
			id: 'btn-detail:group',
			icon: TableRowActionIcon.detail,
			tooltip: t('groupDetail'),
			onClick: (group: Group) => {
				dispatch(setRedirectUrl(`${RouteEnum.GROUPS}/${group.id}`));
				dispatch(getGroupDetail(group));
			},
		},
	];

	const renderRow = ({ row: group, renderActions }: TableRowRenderProps<Group>) => {
		const dateCreated = new Date(group.dateCreated);
		const dateCreatedFormatted = isValid(dateCreated) ? format(dateCreated, INPUT_DATE_MASK) : '-';

		const dateUpdated = new Date(group.dateUpdated);
		const dateUpdatedFormatted = isValid(dateUpdated) ? format(dateUpdated, INPUT_DATE_MASK) : '-';

		return (
			<TableRow key={group.id} id={`row-${group.id}`}>
				<TableCell>{group.name}</TableCell>
				<TableCell>{group.description}</TableCell>
				<TableCell>{dateCreatedFormatted}</TableCell>
				<TableCell>{dateUpdatedFormatted}</TableCell>
				<TableCell>
					{group.active ? (
						<CheckRounded htmlColor={COLOR_THEME.success} data-cy="check:active" />
					) : (
						<ClearRounded htmlColor={COLOR_THEME.error} data-cy="check:inactive" />
					)}
				</TableCell>
				<TableCell padding="none" sortDirection={false}>
					{renderActions(rowActions, group)}
				</TableCell>
			</TableRow>
		);
	};

	return (
		<>
			<CardHeader
				title={t('groups')}
				action={
					<>
						{canEditGroups && (
							<Tooltip title={String(t('createGroup'))} aria-label={t('createGroup')}>
								<IconButton
									onClick={() =>
										dispatch(
											addModalDialog({
												id: 'createGroupModal',
												title: t('createGroupModalTitle'),
												contentText: '',
												content: (
													<GroupForm
														onSubmit={(values: GroupFormValues) =>
															handleCreateGroup(values, 'createGroupModal')
														}
														onCancel={() => handleCloseModalDialog('createGroupModal')}
													/>
												),
											}),
										)
									}
									data-cy="btn-create:group"
								>
									<Add />
								</IconButton>
							</Tooltip>
						)}
					</>
				}
			/>
			<Divider light />
			<Table
				actions={canEditGroups ? rowActions : undefined}
				columns={columns}
				orderings={orderings}
				filtersAndConditions={filtersAndConditions}
				data={data}
				dataLimit={limit}
				dataOffset={offset}
				renderTableRow={renderRow}
				numberOfRows={total}
				fetchAction2={fetchGroups}
				selectable={false}
			/>
		</>
	);
};

import { createReducer } from 'typesafe-actions';
import { AppActions, AppState } from './app-types';
import {
	SET_LOADING,
	SET_REDIRECT_URL,
	ADD_TOAST,
	REMOVE_TOAST,
	ADD_MODAL_DIALOG,
	REMOVE_MODAL_DIALOG,
} from './app-constants';

export const appInitialState: AppState = {
	loading: 0,
	redirectUrl: '',
	toasts: [],
	modalDialogs: [],
};

export const appReducer = createReducer<AppState, AppActions>(appInitialState, {
	[SET_LOADING]: (state, action) => ({
		...state,
		loading: action.payload.value,
	}),
	[ADD_TOAST]: (state, action) => {
		return {
			...state,
			toasts: [...state.toasts, action.payload.toast],
		};
	},
	[REMOVE_TOAST]: (state, action) => ({
		...state,
		toasts: state.toasts.filter((toast) => toast.id !== action.payload.id),
	}),
	[SET_REDIRECT_URL]: (state, action) => ({
		...state,
		redirectUrl: action.payload.redirectUrl,
	}),
	[ADD_MODAL_DIALOG]: (state, action) => {
		return {
			...state,
			modalDialogs: [...state.modalDialogs, action.payload.modalDialog],
		};
	},
	[REMOVE_MODAL_DIALOG]: (state, action) => ({
		...state,
		modalDialogs: state.modalDialogs.filter((modalDialog) => modalDialog.id !== action.payload.id),
	}),
});

import React, { SyntheticEvent, forwardRef, Ref } from 'react';
import MuiButton, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';

type ButtonBaseProps = {
	onClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
};

export type ButtonProps = MuiButtonProps<'button', ButtonBaseProps>;

// Use forwardRef to fix - Warning: Failed prop type: Invalid prop `children` supplied to `ForwardRef(Tooltip)
// Because we are wrapping Material UI Button in our own, we have to forward the ref (otherwise it's automatic)
export const Button = forwardRef(
	(
		{
			children,
			className,
			color = 'primary',
			disabled,
			fullWidth = false,
			onClick,
			size = 'medium',
			type = 'button',
			variant = 'contained',
			...restProps
		}: ButtonProps,
		ref: Ref<HTMLButtonElement>,
	) => {
		const handleClick = (event: SyntheticEvent<HTMLButtonElement>) => {
			if (typeof onClick === 'function') {
				onClick(event);
			}
		};

		return (
			<MuiButton
				ref={ref}
				className={className}
				color={color}
				component="button"
				disableElevation
				disabled={disabled}
				fullWidth={fullWidth}
				size={size}
				type={type}
				variant={variant}
				onClick={handleClick}
				// e.g. data-attributes
				{...restProps}
			>
				{children}
			</MuiButton>
		);
	},
);

import React from 'react';
import { Table } from '../../../components/Table/Table';
import { useTranslation } from 'react-i18next';
import { TableRowActionIcon } from '../../../components/Table/constants';
import { useDispatch, useSelector } from 'react-redux';
import { addModalDialog, removeModalDialog, setRedirectUrl } from '../../../store/app/app-actions';
import { UUID } from '../../../types';
import { Tooltip, TableRow, TableCell, CardHeader, IconButton, Divider } from '@material-ui/core';
import { WorkflowSimplified } from '../workflows-types';
import { fetchWorkflows, deleteWorkflow } from '../../../store/workflows/workflows-async-actions';
import { RouteEnum } from '../../../router/Routes';
import { TableColumnFilter, TableColumnSort, TableRowRenderProps } from '../../../components/Table/types';
import { setCurrentWorkflow } from '../../../store/workflows/workflows-actions';
import { TableActionsContainer } from '../../../components/Table/TableActionsContainer';
import { CheckRounded, ClearRounded, Add, DeleteForever } from '@material-ui/icons';
import { COLOR_THEME } from '../../../theme';
import { hasPermission } from '../../../helpers/auth';
import { RootState } from '../../../store/types';
import { UserState } from '../../../store/user/user-types';
import { PermissionsDotNet } from '../../../helpers/generalConstants';

type Props = {
	data: WorkflowSimplified[];
	total: number;
	limit: number;
	offset: number;
	orderings: TableColumnSort[];
	filters: TableColumnFilter[];
};

export const WorkflowsTable = ({ data, total, limit, offset, orderings, filters }: Props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { permissions } = useSelector<RootState, UserState>((state) => state.user);
	const canEditWorkflows = hasPermission(PermissionsDotNet.WorkflowWrite, permissions);

	const columns = [
		{ id: 'name', label: t('name') },
		{ id: 'description', label: t('description') },
		{ id: 'active', label: t('active') },
	];

	const handleCloseModal = (id: string) => {
		dispatch(removeModalDialog(id));
	};

	const handleDeleteWorkflow = (workflowId: UUID, modalDialogId: string) => {
		handleCloseModal(modalDialogId);
		dispatch(deleteWorkflow(workflowId));
	};

	const rowActions = [
		...(canEditWorkflows
			? [
					{
						id: 'btn-edit:workflow',
						icon: TableRowActionIcon.edit,
						tooltip: t('edit'),
						onClick: (workflow: WorkflowSimplified) => {
							dispatch(setCurrentWorkflow(workflow.id));
							dispatch(setRedirectUrl(`${RouteEnum.WORKFLOWS}/${workflow.id}`));
						},
					},
					{
						id: 'btn-delete:workflow',
						icon: TableRowActionIcon.delete,
						tooltip: t('delete'),
						onClick: (workflow: WorkflowSimplified) =>
							dispatch(
								addModalDialog({
									id: 'deleteWorkflowModal',
									title: t('deleteWorkflowModalTitle'),
									contentText: t('deleteWorkflowModalDescription', {
										name: workflow.name,
									}),
									buttons: [
										{
											id: 'deleteWorkflow',
											value: t('delete'),
											onClick: () => handleDeleteWorkflow(workflow.id, 'deleteWorkflowModal'),
											startIcon: <DeleteForever />,
										},
										{
											id: 'cancelDeleteWorkflow',
											value: t('cancel'),
											onClick: () => handleCloseModal('deleteWorkflowModal'),
											variant: 'text',
										},
									],
								}),
							),
					},
			  ]
			: []),
		{
			id: 'btn-report:workflow',
			icon: TableRowActionIcon.summary,
			tooltip: t('summary'),
			onClick: (workflow: WorkflowSimplified) => {
				dispatch(setRedirectUrl(`${RouteEnum.REPORT}/${workflow.id}`));
			},
		},
		{
			id: 'btn-notifications:workflow',
			icon: TableRowActionIcon.notification,
			tooltip: t('notifications'),
			onClick: (workflow: WorkflowSimplified) => {
				dispatch(setRedirectUrl(`${RouteEnum.NOTIFICATIONS}/${workflow.id}`));
			},
		},
	];

	const renderTableRow = ({ onClickRow, row: workflow, renderActions }: TableRowRenderProps<WorkflowSimplified>) => {
		return (
			<TableRow key={workflow.id} id={`row-${workflow.id}`} hover onClick={() => onClickRow(workflow.id)}>
				<TableCell component="td" id={workflow.name} scope="row">
					{workflow.name}
				</TableCell>
				<TableCell component="td" id={workflow.description} scope="row">
					{workflow.description}
				</TableCell>
				<TableCell component="td" id={`${workflow.id}-${workflow.active}`} scope="row">
					{workflow.active ? (
						<CheckRounded htmlColor={COLOR_THEME.success} data-cy="check:active" />
					) : (
						<ClearRounded htmlColor={COLOR_THEME.error} data-cy="check:inactive" />
					)}
				</TableCell>
				<TableCell padding="none" sortDirection={false} align="right">
					<TableActionsContainer>{renderActions(rowActions, workflow)}</TableActionsContainer>
				</TableCell>
			</TableRow>
		);
	};

	return (
		<>
			<CardHeader
				title={t('workflows')}
				action={
					<>
						{canEditWorkflows && (
							<Tooltip title={String(t('createWorkflow'))} aria-label={t('createWorkflow')}>
								<IconButton
									onClick={() => dispatch(setRedirectUrl(RouteEnum.WORKFLOW_CREATE))}
									data-cy="btn-create:workflow"
								>
									<Add />
								</IconButton>
							</Tooltip>
						)}
					</>
				}
			/>
			<Divider light />
			<Table
				actions={canEditWorkflows ? rowActions : undefined}
				columns={columns}
				orderings={orderings}
				filtersAndConditions={filters}
				data={data}
				dataLimit={limit}
				dataOffset={offset}
				numberOfRows={total}
				fetchAction2={fetchWorkflows}
				selectable={false}
				renderTableRow={renderTableRow}
			/>
		</>
	);
};
